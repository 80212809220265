import { Grid, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';



import useProfileCommonStyles from './commonProfileSyles';
import HeaderButtons from './HeaderButtons';
import useStyles from './styles';

import { AppDataContext } from '../../context';
import { UserProfileContext } from './UserProfileContext';

import userRoles from '../../helpers/userRoles';

import { getRelatedCourses } from './helpers';



const UserDemographics = () => {
    const classes = useStyles();
    const commonProfileStyles = useProfileCommonStyles();

    const theme = useTheme();

    const { userData } = useContext(AppDataContext);
    const { profileData } = useContext(UserProfileContext)

    const [relatedCourses, setRelatedCourses] = useState(null);

    useEffect(() => {
        /**
         * @description this scans thru user courses and find out which course matches with current logged in learner
         * @returns {Array} array of matched courses 
         */
        if (profileData && userData) {
            let relatedCourses = getRelatedCourses(profileData, userData);
            //;
            setRelatedCourses([...relatedCourses]);
        }
    }, [profileData, userData]);

    return (
        <Grid item xs={12} md={12} lg={12}
            className={commonProfileStyles.alignGridCenterNoGutter}
            sx={{
                marginTop: '15px',
                [theme.breakpoints.down('md')]: {
                    marginTop: "28px"
                }
            }}
        >


            <div className={classes.namePronounWrapper}>
                <div className={`${commonProfileStyles.profileName} ${classes.profileName}`} style={{ fontSize: '25px', lineHeight: '29.35px' }}>

                    <span style={{ marginRight: '6px' }}>{profileData?.preferred_name ? profileData.preferred_name : profileData.first_name}</span>
                    {
                        profileData?.user_name_pronunciation &&
                        <span style={{
                            color: '#525252',
                            fontStyle: 'italic',
                            margin: '0 10px 0 0'
                        }}> ({profileData.user_name_pronunciation})</span>
                    }
                    <span style={{ marginRight: '6px' }}> {profileData?.last_name} </span>
                </div>
                {
                    profileData?.user_preferred_pronouns?.length > 0 &&
                    <span className={`${commonProfileStyles.profilePronouns} ${classes.profilePronouns}`} > {profileData.user_preferred_pronouns} </span>

                }
            </div>



            {
                relatedCourses &&
                <div className={`${commonProfileStyles.profileTitle} ${classes.profileCourses}`}>
                    {
                        relatedCourses.map(item => {
                            return <span key={item.course_id}>{item.course.coursename} {userRoles.find(i => i.id === item.user_role_id).uiName}</span>
                        })
                    }
                </div>
            }
            {profileData?.email && <HeaderButtons />}

        </Grid >
    )
}



export default UserDemographics;