import React from "react";

import useStyles from "./styles";
import { StyledButton } from "../v2/Dashboard";
const JoinNowButton = ({
    setShowParticipantModal
}) => {

    const classes = useStyles();

    return (
        <div className={classes.sessionButtonContainer}
            style={{ gridArea: 'btn', marginBottom: 'auto' }}>
                <StyledButton onClick={() => setShowParticipantModal(true)}>Join Now</StyledButton>
        </div>
    )
}

export default JoinNowButton;