import React from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        flexDirection: "column",
        height: "100%"
    },
    title: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '35px',
        color: '#414141',
        marginBottom: "50px"
    },
    text: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '30px',
        lineHeight: '41px',
        textAlign: 'center',
        color: '#414141',
    }
}))

const Wrap = () => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <span className={classes.title}>That's a wrap!</span>
            <span className={classes.text}>You can edit your MyKenzie profile at any time from your options menu.</span>
        </div>
    )
}
export default Wrap