import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import InfoPane from "./InfoPane";

import { getVideoInfo } from "../../../api";
import { AppDataContext } from "../../../context";

import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { removeDuplicateParticipants } from "./helpers";
import MediaDescription from "./MediaDescription";
import SearchVideo from "./SearchVideo";
import useStyles from "./styles";
import VideoMetaPanel from "./VideoMetaPanel";
import MediaPlayer from "./videoPlayer/MediaPlayer";
export const MediaContext = React.createContext();

const MediaPage = () => {

    const { videoUUId } = useParams();

    const classes = useStyles();

    const videoRef = useRef(null);
    const searchVideoInputRef = useRef(null);

    const location = useLocation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { setIsAllComponentsLoaded } = useContext(AppDataContext);
    const [videoInfo, setVideoInfo] = useState(null);
    const [errorEncountered, setErrorEncountered] = useState(false);
    const [videoMarkers, setVideoMarkers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [transcriptAvailable, setTranscriptAvailable] = useState(null);
    const [captionText, setCaptionText] = useState("")
    const [showClosedCaption, setShowClosedCaption] = useState(true)
    const [hasChatHistory, setHasChatHistory] = useState(null)
    
    useEffect(() => {
        //grab the data for the video per the video id 
        const controller = new AbortController()
        async function fetchData() {
            if (videoUUId) {
                const response = await getVideoInfo({
                    uuid: videoUUId,

                }, controller.signal)
                if (response.length > 0) {
                    //Only one video show be present for the uuid
                    if (response[0].meeting_participants) {

                        const uniqueParticipants = removeDuplicateParticipants(response[0].meeting_participants)
                        setVideoInfo({ ...response[0], meeting_participants: uniqueParticipants });
                    }
                    else setVideoInfo(response[0])
                } else {
                    setErrorEncountered(true);

                }

            }

        }
        fetchData()

        //cleaning up on unmount
        return (() => {
            controller.abort()
            videoRef.current = null;
            setVideoInfo(null);
            setVideoMarkers([]);
            setTranscriptAvailable(null);
        });

    }, []);

    useEffect(() => {
        if (videoInfo !== null || errorEncountered) {
            setIsAllComponentsLoaded(true);
        }
        if (videoRef.current !== null) {
            let searchParams = new URLSearchParams(location.search);
            let timestamp = searchParams.get('timestamp');

            if (timestamp) {
                videoRef.current.currentTime = parseInt(timestamp);
            }

        }
    }, [videoInfo, errorEncountered]);

    useEffect(() => {
        if (videoInfo && videoInfo?.transcripts_details !== null && videoInfo?.transcripts_details !== 'null') {
            setTranscriptAvailable(true)
        }
        else {
            setTranscriptAvailable(false)
        }
    }, [videoInfo?.transcripts_details])

    useEffect(() => {
        if (videoInfo && videoInfo?.chat_cdn_url !== null && videoInfo?.chat_cdn_url !== 'null') {
            setHasChatHistory(true)
        }
        else {
            setHasChatHistory(false)
        }
    }, [videoInfo?.chat_cdn_url])
    return (
        <MediaContext.Provider value={{
            videoInfo: videoInfo,
            videoRef: videoRef,
            searchText: searchText,
            setSearchText: setSearchText,
            videoMarkers: videoMarkers,
            setVideoMarkers: setVideoMarkers,
            setTranscriptAvailable: setTranscriptAvailable,
            transcriptAvailable: transcriptAvailable,
            captionText: captionText,
            setCaptionText: setCaptionText,
            showClosedCaption,
            setShowClosedCaption,
            hasChatHistory,
            setHasChatHistory
        }}>
            <div className={`open-sans ${classes.mediaRoot}`}>
                <div id="media-page-inner-root">
                    {!videoInfo &&
                        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>}
                    {
                        videoInfo !== null ?
                            <>
                                <div id="media-page-infobar-container">
                                    {/* Video Media */}
                                    <div id="media-infobar"><InfoPane /></div>

                                    {
                                        !isSmallScreen && <div id="video-search-container">
                                            <SearchVideo ref={searchVideoInputRef} />
                                        </div>
                                    }

                                </div>

                                <div id="media-page-video-container" style={{ display: (!transcriptAvailable && !hasChatHistory) || isSmallScreen ? 'block' : 'grid' }}>
                                    <div id="media-container">
                                        <MediaPlayer ref={videoRef} />
                                        <MediaDescription />
                                    </div>

                                    {(hasChatHistory || transcriptAvailable) &&
                                        <div id="video-meta-panel-container">
                                            <VideoMetaPanel videoRef={videoRef} />
                                        </div>
                                    }

                                </div>

                            </> :
                            null
                    }
                </div>
            </div>
        </MediaContext.Provider>

    );
};

export default MediaPage;
