export default function getResponseAction(id){
    switch (id){
        case 1:
            return "Responded to Learner";
        case 2:
            return "Sent to Inscribe";
        case 3:
            return "Sent to a Department";
        case 4:
            return "Posted Internal Comment";
        case 5:
            return "Closed Request";  
        case 6:
            return "Reopened  Request";      
        default:
            return
    }   
}