
import styled from "@emotion/styled";
import { ClickAwayListener } from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import LanguageIcon from '@mui/icons-material/Language';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
const ArchivePopup = ({ isShown, handleClose, isArchived, handleClick }) => {
    const [isUpdating, setIsUpdating] = useState(false)
    const [isUpdateComplete, setIsUpdateComplete] = useState(false)
    const titleText = isArchived ? "Make Video Public" : "Archive Video"
    const descriptionText = isArchived ?
        "Displays this video on the learner dashboard."
        : "Hides this video from the learner dashboard. Staff can still access, edit, and re-list video entry from ACP. "
    const buttonText = isArchived ? "Make Public" : "Archive"

    const onClick = async () => {
        const val = isArchived ? false : true
        setIsUpdating(true)
        const res = await handleClick(val)
        setIsUpdating(false)
        setIsUpdateComplete(true)
        return res
    }
    if (!isShown) return null
    if (isUpdateComplete) return (
        <ClickAwayListener onClickAway={handleClose}  >
            <SuccessContainer>
                <CheckCircleIcon sx={{ fill: "#009158", fontSize: '32px' }} />
                <SuccessTitle>{isArchived ? 'Video Archived' : 'Video Made Public'}</SuccessTitle>
            </SuccessContainer>
        </ClickAwayListener>
    )
    return (
        <ClickAwayListener onClickAway={handleClose} >



            <PopupContainer isUpdating={isUpdating}>


                {isUpdating ?
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <CircularProgress />
                    </div>

                    :
                    <>
                        {isArchived ? <LanguageIcon sx={{ gridArea: 'icon', fill: "#009158" }} /> : <InventoryIcon sx={{ gridArea: 'icon', fill: '#363636' }} />}
                        <Title>{titleText}</Title>
                        <Text>
                            {descriptionText}
                        </Text>
                        <Button onClick={onClick}>{buttonText}</Button>
                    </>
                }




            </PopupContainer>
        </ClickAwayListener>
    )


}


const PopupContainer = styled.div`
    width: 281px;
    height: 150px;
    /* position: absolute;
    top: 50%;
    right: 50%; */
    position: absolute;
    right: 50px;
    top: 50px;
    margin:auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 27px 20px 24px 20px;
    display: ${props => props.isUpdating ? 'block' : 'grid'};
    grid-template-areas:
    "icon title"
    "text text"
    "btn btn";

    grid-template-columns: 16px 1fr;
    grid-template-rows: 28px 1fr 31px;
    row-gap: 8px;
    column-gap: 10px;
    `
const SuccessContainer = styled(PopupContainer)`
    display: flex;
    justify-content: center;
    align-items: center;

`
const Title = styled.p`
margin: 0;
margin-left: 10px;
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
color: #000000;
grid-area: title;
`
const SuccessTitle = styled(Title)`
    font-size: 22px;
    margin-left: 2px;
`
const Text = styled.p`
margin: 0;
grid-area: text;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #000000;

grid-area: text;
`

const Button = styled.button`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #363636;
background: #FFFFFF;
border: 1.5px solid #363636;
border-radius: 100px;

grid-area: btn;
cursor: pointer;
`

export default ArchivePopup