import React from "react";

import ClosedCaption from "./ClosedCaption";
import PictureInPicture from "./PictureInPicture";
import FullScreen from "./FullScreen";
import PlaybackSpeeds from "./PlaybackSpeeds";
import ForwardBack10 from "./ForwardBack10";
import VolumeMute from "./VolumeMute";
import PlayPause from "./PlayPause";
import MediaSlider from "./MediaSlider";
import PlayTimeDisplay from "./PlayTimeDisplay";
import MediaMarkers from "./MediaMarkers";

const DesktopMediaControls = ({
    videoRef
}) => {

    return (
        <>
            <div className="video-media-inner-controls-bg"></div>

            <div className="video-media-inner-controls">

                <div className="video-media-controls-container">

                    <MediaMarkers videoRef={videoRef} />

                    <MediaSlider videoRef={videoRef} />

                    <div className="video-media-controls-inner-container">

                        <div className="video-media-controls-left">

                            <PlayPause videoRef={videoRef} />

                            <PlayTimeDisplay videoRef={videoRef} />

                            <VolumeMute videoRef={videoRef} />
                            <ForwardBack10 videoRef={videoRef} />

                        </div>

                        <div className="video-media-controls-right">

                            <PlaybackSpeeds videoRef={videoRef} />
                            <ClosedCaption videoRef={videoRef} />
                            <PictureInPicture videoRef={videoRef} />
                            <FullScreen videoRef={videoRef} />

                        </div>

                    </div>
                </div>

            </div>
        </>


    );
};

export default DesktopMediaControls;
