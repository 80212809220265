import React, { useContext } from 'react';
import {v4 as uuid4} from 'uuid';
import { Checkbox, FormControlLabel } from '@mui/material';

import { WebtoolsDataContext } from '.';

import {checkIfWebtoolExists} from './helpers';


const Webtools = ({
    webtools=null,
    isDisabled=true
}) => {

    const {allWebtools}=useContext(WebtoolsDataContext);

    return (
        <div>
           {
               allWebtools?
               allWebtools.map(item=>{
                   return <div key={uuid4()}>
                       <FormControlLabel
                            control={<Checkbox 
                                disabled={true}
                                value={item.id}
                                name={item.name}
                                checked={checkIfWebtoolExists(webtools,item.id)} />} 
                            label={item.name} />
                    </div>
               }):
               null
           }
        </div>
    )

}

export default Webtools;
