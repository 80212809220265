// import PauseIcon from '@mui/icons-material/Pause';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuid4 } from 'uuid';

import { useMediaQuery, useTheme } from "@mui/material";
import { MediaContext } from "../index";
import Captions from './Captions';
import ForwardBack10 from "./controls/ForwardBack10";
import MediaPlayerControls from "./MediaPlayerControls";
import usePlayerStyles from "./playerStyles";

const MediaPlayer = React.forwardRef((props, ref) => {

    const { videoInfo, captionText, showClosedCaption } = useContext(MediaContext)
    const [transcriptVttFtile, setTranscriptVttFile] = useState(null);
    // const [isPaused, setIsPaused] = useState(true);
    // const [initialPlay, setInitialPlay] = useState(false);
    
    const classes = usePlayerStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        sources: [{
            src: videoInfo.video_cdn_url,
            type: 'video/mp4'
        }],
        textTrackSettings: false
    };

    let mobileScreenControlsTimer = useRef(null);

    useEffect(() => {

        const controller = new AbortController()

        //grab transcripts content 
        //since 90% files are in kbs its ok to fetch via https from cdn
        if (videoInfo.transcripts_details?.cloudfront_uri_txt) {
            fetch(videoInfo.transcripts_details.cloudfront_uri_txt, { signal: controller.signal })
                .then(response => response.blob())
                .then(blob => {
                    setTranscriptVttFile(URL.createObjectURL(blob));
                });
        }
        return () => controller.abort()
    }, []);


    // Future implementation --> ADD THIS FUNCTION AS AN ONCLICK EVENT TO THE VIDEO ELEMENT
    // const playPauseVideo = async () => {

    //     if (!isSmallScreen) {

    //         if (!initialPlay) {
    //             setInitialPlay(true);
    //         };

    //         let button = document.getElementById('jumbo-play-button');
    //         button.classList.add('fade');

    //         button.addEventListener('animationend', function (event) {
    //             button.classList.remove('fade');
    //         });

    //         if (ref.current.paused) {
    //             await ref.current.play();
    //             setIsPaused(false);
    //         } else {
    //             await ref.current.pause();
    //             setIsPaused(true);
    //         }

    //     } else if (isSmallScreen) {
    //         handleTapOnMobileScreen();
    //     }
    // }

    const handleTapOnMobileScreen = () => {
        //if small screen. Tap should bring up the controls 
        //controls will hide after 5 seconds 
        clearTimeout(mobileScreenControlsTimer);

        if (ref.current && !ref.current.paused) {
            let parentNode = document.getElementById('video-media-controls-parent-container');

            parentNode.style.display = "block";

            mobileScreenControlsTimer = setTimeout(() => {//Show controls 
                if (ref.current && !ref.current.paused) parentNode.style.display = "none";
            }, 5000);

        } else {
            clearTimeout(mobileScreenControlsTimer);
        }
    }

    return (
        <div className={classes.videoContainer} id="video-container-parent-tag" style={{ position: 'relative' }}>
            <video
                id="video-player"
                ref={ref}
                preload="auto"
                playsInline
            >
                {
                    videoJsOptions.sources.map((src) => {
                        return <source key={uuid4} src={src.src} type={src.type} />
                    })
                }

            </video>

            {
                (transcriptVttFtile && showClosedCaption) &&
                <Captions text={captionText} />

            }
            {/* <div id="jumbo-play-button" onClick={async () => { await playPauseVideo() }}>
                {
                    !isPaused ?
                        <PlayArrowIcon /> :
                        <PauseIcon />
                }
            </div> */}
            {
                isSmallScreen && <ForwardBack10
                    handleOnSingleClick={handleTapOnMobileScreen}
                    videoRef={ref} />
            }
            <MediaPlayerControls videoRef={ref} />
        </div>
    );
});

export default MediaPlayer;
