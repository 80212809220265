import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { getCohortNamesByCalendars, handleSearch, sortUpdates, sortVideosLeastViews, sortVideosMostViews, sortVideosNewest, sortVideosOldest } from './helpers';
import LeftPane from "./LeftPane";

import { CircularProgress } from "@mui/material";
import moment from "moment";
import EachACPVideoCard from './EachACPVideoCard';
import { useACPDataContextV2 } from "../../context/ACPDataStoreV2";

export default function ACPVideoLibrary() {

    const { videos, acpDataLoading, calendarInfo } = useACPDataContextV2()

    const [displayedVideos, setDisplayedVideos] = useState(videos?.all)
    const [calendarId, setCalendarId] = useState(calendarInfo?.id)
    const [calendars, setCalendars] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [uncuratedFilter, setUncuratedFilter] = useState(false)
    const [curatedFilter, setCuratedFilter] = useState(false)
    const [archivedFilter, setArchivedFilter] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")


    const [sortType, setSortType] = useState('newest')

    const sortFnMappings = {
        'newest': sortVideosNewest,
        'oldest': sortVideosOldest,
        'mostViews': sortVideosMostViews,
        'leastViews': sortVideosLeastViews
    }
    const handleClearAll = () => {
        setUncuratedFilter(false)
        setCuratedFilter(false)
        setArchivedFilter(false)
        setStartDate(null)
        setEndDate(null)
        setSearchTerm("")
        setSortType('newest')
    }


    useEffect(() => {
        //get cohort names from calendar table
        const controller = new AbortController()
        getCohortNamesByCalendars({ controller }).then(cals => {
            setCalendars(cals)
        })
        return () => controller.abort()
    }, [])

    useEffect(() => {
        setIsLoading(true)
        if (videos?.all) {
            setDisplayedVideos(videos?.all)

            setCalendarId(calendarInfo?.google_calendar_id)
            setIsLoading(false)
        }
    }, [acpDataLoading, videos, videos?.all])

    useEffect(() => {
        //logic for filtering from left panel
        if (!videos?.all || !displayedVideos) return
        let filteredVideos = [...videos.all]
        if (archivedFilter) {
            filteredVideos = videos.archived
        }
        if (uncuratedFilter && !curatedFilter) {
            filteredVideos = videos.uncurated
        }
        if (curatedFilter && !uncuratedFilter) {
            filteredVideos = videos.curated
        }
        if (curatedFilter && uncuratedFilter) {
            filteredVideos = [...videos.curated, ...videos.uncurated]
        }

        if (startDate) {
            filteredVideos = filteredVideos.filter(video => moment(video.video_details.recording_start).isAfter(startDate))
        }
        if (endDate) {
            const newEnd = endDate.add(1, 'days')
            filteredVideos = filteredVideos.filter(video => moment(video.video_details.recording_start).isBefore(newEnd))
        }
        if (searchTerm) {
            filteredVideos = handleSearch({ searchTerm, filteredVideos })
        }
        if (sortType) {
            filteredVideos = sortFnMappings[sortType](filteredVideos)
        }


        setDisplayedVideos(filteredVideos)
    }, [archivedFilter, uncuratedFilter, curatedFilter, startDate, endDate, searchTerm, sortType, videos,])


    return (
        <>

            <PageContainer >
                <div></div>
                <LeftPane
                    handleClearAll={handleClearAll}
                    uncuratedFilter={uncuratedFilter}
                    setUncuratedFilter={setUncuratedFilter}
                    setArchivedFilter={setArchivedFilter}
                    curatedFilter={curatedFilter}
                    setCuratedFilter={setCuratedFilter}
                    archivedFilter={archivedFilter}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    sortType={sortType}
                    setSortType={setSortType}
                    uncuratedCount={videos.a}
                />
                {isLoading || acpDataLoading ? (
                    <LoadingContainer>
                        <CircularProgress />
                    </LoadingContainer>)

                    : displayedVideos.length ?

                        <VideoListContainer >
                            {displayedVideos.map((video, i) => (
                                <EachACPVideoCard video={{ ...video, updates: sortUpdates(video.updates) }} key={i} calendarMappings={calendars} calendarId={calendarId} />
                            ))}
                        </VideoListContainer>

                        :

                        <NoVideosText>No Videos Found</NoVideosText>
                }

            </PageContainer>
        </>
    )
}

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-Columns: 245px 1fr;

`
const VideoListContainer = styled.main`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 26px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LoadingContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
padding-top: 10vh;
/* align-items: center */
`

const NoVideosText = styled.p`
    margin: 40px auto
`