//using this file for large piece of constant data throughout the app

export const WEBTOOL_MAPPINGS = [ //Webtool mappings as found in mk_webtool table. if new tools are added, this needs to be updated too
    {
        name: 'Calendar',
        id: 1,
    },
    {
        name: "Classe",
        id: 2,
    },
    {
        name: "Github",
        id: 3,
    },
    {
        name: "Inscribe",
        id: 4,
    }, {
        name: "Slack",
        id: 5,
    },
    {
        name: "Talkspace",
        id: 6,
    },
    {
        name: "Gmail",
        id: 9
    },
    {
        name: "Figma",
        id: 10
    },
    {
        name:
            "Whimsical",
        id: 11
    },
    {
        name: "Handshake",
        id: 12
    },
    {
        name: "HelpU",
        id: 13
    }
]

export const ROLE_MAPPINGS = {
    1: 'Learner',
    2: 'Facilitator',
    3: 'Instructor',
    4: 'Student Services Advisor',
    5: 'Coach',
    6: 'Placement Advisor',
    7: 'Admission Advisor',
    8: 'Lead Facilitator',
    9: 'Admin',
    10: 'UX Instructor',
    11: 'Limited Admin',
    12: 'Cyber Security Instructor',
    13: 'Financial Aid Advisor',
    14: 'SME',
    15: 'Support'
}

const testProgramID = 5
const allProgramId = 8


export const PROGRAMS = [
    { id: 1, title: "Software Engineering Backend Development" },
    { id: 2, title: "UX Design" },
    { id: 3, title: "Java Foundations Program" },
    // { id: 4, title: "Amazon Career Choice Program" }, //Depreciated
    { id: testProgramID, title: "Test Program" },
    { id: 6, title: "Full Stack Web Development" },
    { id: 7, title: "Cybersecurity" },
    { id: allProgramId, title: "All Programs" },
    { id: 9, title: "Front End Software Development" }
]

//some views need test programs and all programs hidden
export const STAFF_FACING_LIVE_PROGRAMS = PROGRAMS.filter(p => ![testProgramID, allProgramId].includes(p.id))