import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { Formik } from 'formik';

import { useStyles } from './styles';

import AnnouncemetEntryFields from './AnnouncemetEntryFields';
import PreviewModal from "./PreviewModal";

import { editAnnouncement } from "../../../api";
import { ACPAnnouncementContext } from '../../context';

const boxStyle = {
    position: 'absolute',
    overflowY: "auto",
    maxHeight: "85vh",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
}

const EditAnnouncementForm = ({
    modalOpen,
    announcement,
    handleCloseCallback
}) => {

    const classes = useStyles();

    const [previewMode, setPreviewMode] = useState(false);

    const { updateAnnouncement, setSubmissionError } = React.useContext(ACPAnnouncementContext);

    const handleOnClose=(event,reason)=>{
        if(reason!=='backdropClick'){
            handleCloseCallback();
        }
    }

    const handleSubmission = async (values, setIsSubmitting) => {

        try {

            ;
            
            await editAnnouncement(values, values.id);

            //Update the announcement in the ACPAnnouncementDataStore and ACPDataStore
            updateAnnouncement(announcement["id"], values);

            handleOnClose();

        } catch (error) {
            console.error(error);
            setSubmissionError(true);
        }

    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleOnClose}
        >
            <Box sx={boxStyle}>
                <Formik
                    initialValues={announcement}
                    onSubmit={(values, { setSubmitting }) => { handleSubmission(values, setSubmitting) }}>
                    {({ handleSubmit, values, isSubmitting }) => (
                        <form id="update-announcement-form-container" >

                            {
                                !previewMode ?
                                    <div id="update-announcement-entry-container"
                                        style={{
                                            display: previewMode ? 'none' : "block"
                                        }}>
                                        <div className={classes.formHeaderContainer}>
                                            <Typography id="update-announcement-modal-title" variant="h6" component="h2">
                                                Update Announcement
                                            </Typography>
                                            <div>
                                                <IconButton onClick={() => { handleOnClose() }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <AnnouncemetEntryFields editView={true}/>
                                    </div> :

                                    <div id="create-new-preview-container"
                                        style={{
                                            display: previewMode ? 'block' : "none"
                                        }}>
                                        <Stack spacing={3}>
                                            <div className={classes.formHeaderContainer}>
                                                <Typography id="create-announcement-preview-title" variant="h6" component="h2">
                                                    Announcement Preview
                                                </Typography>
                                                <div>
                                                    <IconButton onClick={() => { handleOnClose() }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <PreviewModal values={values} />
                                        </Stack>
                                    </div>
                            }

                            <div className={classes.formFooter}>
                                {
                                    !previewMode ?
                                        <>
                                            <div>
                                                <Button
                                                    onClick={() => { handleOnClose() }}
                                                    variant="text"
                                                    id="update-announcement-cancel-button"
                                                    className={classes.textButton}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className={classes.submitButton}>
                                                <Button
                                                    id="create-announcement-preview-button"
                                                    onClick={() => { setPreviewMode(true) }}
                                                    data-testid="update-announcement-preview-button"
                                                    disabled={!(values.body && values.post_as && values.audience)}
                                                > Preview </Button>
                                            </div>
                                        </> :
                                        <>
                                            <div>
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={() => { setPreviewMode(false) }}
                                                    variant="text"
                                                    id="update-announcement-edit-post-button"
                                                    className={classes.textButton}>
                                                    Edit Post
                                                </Button>
                                            </div>
                                            <div className={classes.submitButton} >
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={() => { handleSubmit() }}
                                                    id="update-announcement-save-button"
                                                    data-testid="update-announcement-save-button"
                                                >
                                                    {
                                                        isSubmitting ?
                                                            <CircularProgress color="inherit" size={18} /> :
                                                            <span>Save</span>
                                                    }
                                                </Button>
                                            </div>
                                        </>
                                }

                            </div>


                        </form>
                    )}
                </Formik>

            </Box>

        </Modal>
    )
}

export default EditAnnouncementForm