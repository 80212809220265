import React, { useContext } from 'react';
import useStyles from './styles';
import FreeTimeIcon from '../../icons/profile-free-time.svg'
import { UserProfileContext } from './UserProfileContext';

const UserFreeTime = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    return (
        <>
            {
                profileData?.user_free_time &&
                <div className={classes.topItems}>
                    <img id="user-profile-free-time-icon-image" src={FreeTimeIcon} alt="Free Time Icon" />
                    <p>In my free time, I enjoy <span className={classes.subHeader}>{profileData.user_free_time}</span>
                    </p>
                </div>
            }
        </>

    )
}

export default UserFreeTime;