import styled from "@emotion/styled"
import moment from 'moment'
import { useState } from "react"
import KALogo from '../../../images/KALogo.png'
import { stripLeadingPunctuation } from '../../../learner/pages/watch/utils'
import EachACPVideoModal from "./EachACPVideoModal"
import { removeDuplicateCalendars } from "./helpers"

import { ReactComponent as ExpandIcon } from '../../../icons/video-panel-expand.svg'

import VisibilityText from "./VisibilityText"
export default function EachACPVideoCard({ video, calendarMappings, calendarId }) {
    const [showModal, setShowModal] = useState(false)
    const calendars = removeDuplicateCalendars(video.google_calendar_ids).map((id, i) => (
        calendarMappings[id] || ''
    ))

    const lastUpdated = video.updates && video.updates.length ? video.updates[0] : null
    return (
        <>
            <EachACPVideoModal isOpen={showModal} handleClose={() => setShowModal(false)} video={video} calendars={calendars} calendarId={calendarId} />
            <Card onClick={() => setShowModal(true)} >
                <Content>

                    <ContentInfo>
                        <VisibilityText style={{ marginLeft: '-4px' }} video={video} />
                        <VideoTitle>{stripLeadingPunctuation(video.title)}</VideoTitle>
                        <VideoDescription>{video.description || '[Missing Description]'}</VideoDescription>
                        {video.tags ?
                            <TopicPillsContainer>
                                {video.tags.map((tag, i) => (
                                    <TopicPill key={i}>{tag}</TopicPill>
                                ))}
                            </TopicPillsContainer> : <MissingTopicsText>
                                {'[Missing Associated Topics]'}

                            </MissingTopicsText>
                        }
                    </ContentInfo>
                    {video.thumbnail_cdn_url ?
                        <Thumbnail src={video.thumbnail_cdn_url} /> :
                        <DefaultThumbnailContainer>
                            <DefaultThumbnailIcon src={KALogo} />
                        </DefaultThumbnailContainer>
                    }
                </Content>
                <Bottom>
                    <BottomText>
                        {moment(video?.video_details?.recording_start).format('M/D/YYYY')}
                        {' | '}
                        {calendars.length === 1 ? calendars[0] + ' | ' : 'Kenzie Academy | '}

                    </BottomText>
                    <CreatedByText>
                        {/* Uploaded By: {' '} */}
                        {video.created_by || 'MyKenzie API'}
                    </CreatedByText>

                    <LastModifiedText>
                        Last Modified: {lastUpdated ? moment(lastUpdated.updated_date).format('M/D/YYYY') : 'N/A'}
                        {lastUpdated?.updated_by && <> By <span style={{ fontWeight: 600 }}>{lastUpdated.updated_by}</span></>}
                    </LastModifiedText>
                    <ViewCountText>{video.views ? video.views : 0} View{video.views === 1 || video.views === '1' ? '' : 's'}</ViewCountText>

                    <StyledExpandIcon />
                </Bottom>
            </Card>
        </>

    )
}


const Card = styled.div`
width: 100%;
height: 260px;
max-width: 1400px;
background: #FFFFFF;
border: 2px solid #828282;
border-radius: 10px;
display: grid;
grid-template-rows: 1fr 48px;
grid-template-columns: 100%;
overflow: hidden;
box-sizing: border-box;
margin-top: 10px;
cursor: pointer;
`
const Content = styled.div`
box-sizing: border-box;

display: grid;
grid-template-columns: 2fr 1fr;
column-gap: 42px;
padding: 18px 25px 15px 25px;
overflow: hidden;
`
const ContentInfo = styled.div`
display: grid;
grid-template-rows: 25px 25px 50px 1fr;
row-gap: 10px;
`

const VideoTitle = styled.p`
margin: 0;

`

const VideoDescription = styled.p`
margin: 0;
height: 100%;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 170%;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`

const MissingTopicsText = styled.p`
margin: 0;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 158.9%;
`

export const TopicPillsContainer = styled.div`
display: flex;
align-items: center;
`

export const TopicPill = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;

background: #FFFFFF;
border: 1.5px solid #C7C7C7;
border-radius: 100px;

padding: 3px 20px;
margin-right: 8px;
display: flex;

`

const Thumbnail = styled.img`
width: 284px;
height: 100%;
max-height: 277px;
border-radius: 5px;
border: 1px solid #000000;
display: block;
margin: 0;
padding: 0;
object-fit: cover;

`

const DefaultThumbnailContainer = styled.div`
width: 284px;
height: 100%;
display: flex;
background-color: black;
border-radius: 5px;
`


const DefaultThumbnailIcon = styled.img`
width: 60px;
margin: auto;
`

const Bottom = styled.div`
box-sizing: border-box;
background-color: #E4EEFF;
padding: 10px 23px;
display: flex;
align-items: center;
`


const BottomText = styled.p`
margin: 0;
font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
`

const LastModifiedText = styled.p`
font-family: 'Nunito';
font-style: italic;
font-weight: 500;
font-size: 14px;
line-height: 19px;

margin-left: 20px;
`

const CreatedByText = styled.p`
font-family: 'Nunito';
font-weight: 600;
font-size: 14px;
line-height: 19px;
margin-left: 5px;

`
const ViewCountText = styled.p`
font-family: 'Nunito';
font-weight: 700;
font-size: 14px;
line-height: 19px;
margin-right: auto;
margin-left: 20px;
`
const StyledExpandIcon = styled(ExpandIcon)`
margin-left: 20px;
cursor: pointer;
`