import React from "react"
import { SocialLinks } from "./components"

const SocialMedia = () => {
    return (
        <>
            <p>Outside of Kenzie, you can find me at:</p>
            <SocialLinks isOnboarding/>
        </>
    )
}

export default SocialMedia