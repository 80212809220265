import { makeStyles } from "@mui/styles";

export const requestTypeColorMapping = {
    "other": "#373737",
    "grades": "#7C5A02",
    "personal": "#9D1889",
    "activity": '#9C00E6',
    "assessment": "#392BDA",
    "lesson": "#1A8594"
}

export const requestTypeBgColorMapping = {
    "other": "#F5F5F5",
    "grades": "#FFFCF5",
    "personal": "#FFF6FE",
    "activity": '#FCF7FF',
    "assessment": "#F7F7FF",
    "lesson": "#F9FEFF"
}

export const useStyles = makeStyles(theme => ({

    connectPanelContainerInSupportRequest: {
        padding: "0px"
    },

    requestBlock: {
        borderRadius: "10px",
        marginBottom: "15px",
        border: "1.5px solid",
        position: "relative",
        overflow: 'hidden',
        '& .each-request-top-container': {
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px"
        }
    },

    eachRequestTopInnerContainer: {
        padding: "18px"
    },

    eachRequestTopHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    eachRequestHistoryContainer: {
        padding: "18px"
    },

    eachRequestHistoryInnerContainer: {
        margin: "30px 0px",
        '& .historyHeader': {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        }
    },

    eachRequestLowerBottomContainer: {
        padding: "10px 18px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: 'white',
        overflow: 'hidden'
    },

    requestExpandedModalScrollable: {
        top: 0,
        left: 0,
        right: 0,
        bottom: "10px",
        maxHeight: "80vh",
        overflow: "auto",
        borderRadius: "10px"
    },

    eachRequestBottomContainer: {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    kBotLives: {
        width: "475px",
        height: "auto"
    },

    /**
     * Boders per request type
     */
    assessmentBorder: {
        borderColor: requestTypeColorMapping.assessment
    },

    activityBorder: {
        borderColor: requestTypeColorMapping.activity
    },

    gradesBorder: {
        borderColor: requestTypeColorMapping.grades
    },

    personalBorder: {
        borderColor: requestTypeColorMapping.personal
    },

    otherBorder: {
        borderColor: requestTypeColorMapping.other
    },

    lessonBorder: {
        borderColor: requestTypeColorMapping.lesson
    },

    /**
     * Color per request type
     */
    assessmentColor: {
        color: requestTypeColorMapping.assessment
    },

    activityColor: {
        color: requestTypeColorMapping.activity
    },

    gradesColor: {
        color: requestTypeColorMapping.grades
    },

    personalColor: {
        color: requestTypeColorMapping.personal
    },

    otherColor: {
        color: requestTypeColorMapping.other
    },

    lessonColor: {
        color: requestTypeColorMapping.lesson
    },

    /**
     * Background color peer the type 
     */
    assessmentBgColor: {
        backgroundColor: requestTypeBgColorMapping.assessment
    },

    activityBgColor: {
        backgroundColor: requestTypeBgColorMapping.activity
    },

    gradesBgColor: {
        backgroundColor: requestTypeBgColorMapping.grades
    },

    personalBgColor: {
        backgroundColor: requestTypeBgColorMapping.personal
    },

    otherBgColor: {
        backgroundColor: requestTypeBgColorMapping.other
    },

    lessonBgColor: {
        backgroundColor: requestTypeBgColorMapping.lesson
    },

    requestHeader: {
        fontSize: "16px",
        fontWeight: 700,
        textTransform: 'capitalize',
        '& .each-request-tag': {
            marginRight: "20px"
        }
    },

    requestCourseTags: {
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
        '& .each-request-course-tag': {
            marginRight: "20px"
        }
    },

    redText: {
        color: "red"
    },

    questionContainer: {
        marginTop: "15px"
    },

    greyColor: {
        color: "#474747"
    },

    blackColor: {
        color: "#000000"
    },

    addMdMarginLeft: {
        marginLeft: "20px"
    },

    addSmMarginBottom: {
        marginBottom: "6px"
    },

    addMdMarginBottom: {
        marginLeft: "20px"
    },

    addLgMarginBottom: {
        marginBottom: "30px"
    },

    addMdMarginTop: {
        marginTop: "15px"
    },

    addLgMarginTop: {
        marginTop: "30px"
    },

    formFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        margin: "15px 0px 10px 0px"
    },

    formFooterButtons: {
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center"
    },

    submitButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            ...theme.elements.darkBlueButton
        }
    },
    textButton: {
        ...theme.elements.subtitle,
        color: `${theme.colors.deepBlue} !important`,
        textTransform: "capitalize !important",
        marginRight: "15px !important"
    },

}))