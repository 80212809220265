import React, { useContext, useEffect } from 'react';
import { CohortSettingsContext } from '.';

import { ErrorSnackBar, SuccessSnackBar } from '../../../sharedComponents/snackBars';

const CohortSettingsSnackBars = () => {

    const {
        submissionSuccessMessage,
        setSubmissionSuccessMessage,
        submissionSuccessfull,
        setSubmissionSuccessfull,
        submissionError,
        setSubmissionError,
        submissionErrorMessage,
        setSubmissionErrorMessage
    } = useContext(CohortSettingsContext)

    useEffect(()=>{
        return(()=>{
            setSubmissionSuccessfull(false);
            setSubmissionError(false);
        })
    },[]);

    useEffect(()=>{
        if(!submissionSuccessfull){
            setSubmissionSuccessMessage('Your changes were saved successfully');
        }
    },[submissionSuccessfull]);

    useEffect(()=>{
        if(!submissionError){
            setSubmissionErrorMessage("There was an error submitting info");
        }
    },[submissionError]);

    return (
        <>
            {
                submissionSuccessfull ?
                    <SuccessSnackBar
                        message={submissionSuccessMessage}
                        handleOnClose={() => { setSubmissionSuccessfull(false) }}
                    /> :
                    null
            }
            {
                submissionError ?
                    <ErrorSnackBar
                        message={submissionErrorMessage}
                        handleOnClose={() => { setSubmissionError(false) }}
                    /> :
                    null
            }
        </>

    )
}

export default CohortSettingsSnackBars;