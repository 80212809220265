const requestTypeMapping = require('./typeMapping.json')
const requestStatusMapping = require('./statusMapping.json');

export const getRequestTypeMapping = (request) => {
    return requestTypeMapping.find(i => i.id === request.request_type_id).label;
}

export const getRequestStatusMapping = (request) => {
    return requestStatusMapping.find(i => i.id === request.status).label;
}

export const parseRequestBody=(queryString)=>{
    const text = queryString.description + "&x="
    const re = /(\w+(?==))(=)((.|\n)+?(?=[\?&]\w+=))/g;  //eslint-disable-line  no-useless-escape
    let dict = {}, m;
    while(m = re.exec(text)) { //eslint-disable-line no-cond-assign
        dict[m[1]]=m[3];
    }
    return dict;
}

/**
 * @description - Check if support id exists in url 
 * @returns Boolean
 */
export const checkIfSupportIdExistsInUrl=(path)=>{
    try {
        //Check if path contains the listRequests & digist next to /listRequests/
        let urlMatchRegx=new RegExp(/\/listRequests\/\d+/,'i')
        return (urlMatchRegx).test(path);
    } catch (error) {
        console.error(error);
        return false;
    }

}

/**
 * @description - parses and returns the supportId if  exists
 * @returns Integer or Boolean
 */
export const getSupportIdFromUrl=(path)=>{
    try {
        //;
        let regEx=(/\b(\d+)/);//matches all the digits in the url and gets the last digit in the url
	    let matches=path.match(regEx);
        if(matches){
            let supportId=matches[matches.length-1];//getting last digit in url
            return supportId;
        }else{
            return null;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
    
}

/** 
 * @description - Action form helper functions
 * @param {*} actionId 
 * @returns 
 *  1	Respond to Learner
    2	Send to Inscribe
    3	Send to a Department
    4	Post Internal Comment
    5	Close Request
    6	Reopen Request
 */

export const getInitialMessage = (actionId, request, body) => {
    let message = "";
    if (actionId === 2) {
        message = `<span>${(request.request_type_id === 3) ? `${body.struggling ? body.struggling : ""}<br/>${body.additional ? body.additional : ""}` : `${body.challenge ? body.challenge : ""}<br/>${body.solution ? body.solution : ""}<br/>${body.link ? body.link : ""}`}</span>` 
    }
    return message;
}

export const displayButtonText = (actionId) => {
    switch (actionId) {
        case 4:
            return 'Save'
        case 5:
            return 'Close'
        default:
            return 'Send'
    }
}

export const displayPlaceHolderText = (actionId) => {
    switch (actionId) {
        case 2:
            return ''
        case 4:
            return 'Add your internal comment...'
        case 5:
            return 'Close'
        default:
            return "Type response to learner..."
    }
}

export const displayTextFieldTitleMessage = (actionId, requestActions) => {
    if (actionId === 2) {
        return 'Description or additional context';
    }

    let textFieldTitleMessage = requestActions.find(action => action.id === actionId)?.action_description

    return textFieldTitleMessage
}

export const getSuccessMessagePerActionId=(actionId)=>{
    let message="";
    switch (actionId) {
        case 1:
            message= 'Your response has been sent to learner';
            break;
        case 2:
            message= 'The message has been sent to inscribe';
            break;
        case 4:
            message= 'The message has been posted';
            break;
        case 5:
            message= 'The request has been closed'
            break;
        case 6:
            message= 'The request has been reopened'
            break;
        default:
            message= 'You changes have been saved';
            break;
    }
    return message;
}

/**
 * @description - Check which menu action can be siaplyed per the action id, requests type id and status
 * @param {*} actionId 
 * @param {*} requestTypeId 
 * @param {*} requestStatus 
 * @returns 
 */
export const checkIfMenuItemCanBeDisplayed = (actionId, requestTypeId, requestStatus) => {
    //Logic flow
    //1. If actionId = 2 (Send to inscribe) and requestType is grade, Other or Personal Request. MenuItem CANNOT be displayed
    //2. If actionId = 5 (Close Request) and status = closed, DONT show the menuItem. Because close request cannot be closed again
    //3. If actionId = 6 (Reopen Request) and status = closed, SHOW the menuitem. Since only closed requests can be reopened 
    if (actionId === 2 && (requestTypeId === 4 || requestTypeId === 5 || requestTypeId === 6)) {
        return false;//show
    }
    else if (actionId === 5 && requestStatus === "closed") {
        return false;//dont show
    }
    else if (actionId === 6 && requestStatus === "closed") {
        return false;//dont show

    } else {
        return true; //show
    }
}

/**
 * @description massage the response 
 */
export const parseSupportResponse=(response)=>{
    return response.replace(/\\r/g,'</br>');
}