import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { ACPDataContext } from '.';
import { AppDataContext } from '../../context';
import { useACPDataContextV2 } from "./ACPDataStoreV2";
import { ACPSupportRequestsContext } from "./ACPSupportRequestsContext";
import { filterTrueValues } from "./helpersV2";
import { requestExists, stripHTMLFromSupportRequest, wordFound } from "./helpers";

import { getPastResponses, getSupportRequestActions } from '../../api';

const REQUEST_TYPES = require('../pages/requests/typeMapping.json');

const initialFilters = {
    status: {
        open: true,
        closed: false,
        awaiting_review: true,
        reopened: false,
    },
    request_type_id: {
        ...REQUEST_TYPES.reduce((acc, ci) => {
            acc[ci.id] = false
            return acc;
        }, {})
    }
}

const initialFilterCounts = { ...initialFilters };

//set count for all values to 0
Object.keys(initialFilterCounts).forEach((k => {
    Object.keys(initialFilterCounts[k]).forEach(v => {
        initialFilterCounts[k][v] = 0;
    });
}));




export const ACPSupportRequestsDataStore = ({ children }) => {

    const { supportRequests: acpSupportRequests, acpDataLoading, } = useACPDataContextV2()
    const allCohortSupportRequests = acpSupportRequests.all || []

    const { userCourses, } = useContext(ACPDataContext);
    const { setACPDataLoaded, userRole } = useContext(AppDataContext);

    let supportRequestHistoryRef = useRef([]);

    const [supportRequests, setSupportRequests] = useState(allCohortSupportRequests);

    const [filteredSupportRequests, setFilteredSupportRequests] = useState([]);

    const [sortDirection, setSortDirection] = useState("newest");
    const [noAccess, setNoAccess] = useState(false);
    let [requestActions, setRequestActions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState(() => {
        try {
            if (JSON.parse(localStorage.getItem("supportrequestfilters")) === null) {
                return initialFilters
            } else {
                //backward compatibility check 
                //this will avoid any issues caused due to the previously stored filteres in localstorage
                let storedFilter = JSON.parse(localStorage.getItem("supportrequestfilters"));
                let { request_type_id, status, ...rest } = storedFilter;

                if (rest) storedFilter = {
                    request_type_id: request_type_id,
                    status: status
                };

                return Object.assign(initialFilters, storedFilter);
            }
        } catch {
            return initialFilters
        }
    });

    const [filterCounts, setFilterCounts] = useState({ ...initialFilterCounts });

    const [searchTerms, setSearchTerms] = useState(() => {
        try {
            if (localStorage.getItem("supportRequestSearchTerms") === null || !Array.isArray(JSON.parse(localStorage.getItem("supportRequestSearchTerms")))) {
                return [];
            }

            return JSON.parse(localStorage.getItem("supportRequestSearchTerms"));
        } catch {
            return [];
        }
    });

    const [searchHistory, setSearchHistory] = useState(() => {
        try {
            if (localStorage.getItem("supportRequestSearchHistory") === null || !Array.isArray(JSON.parse(localStorage.getItem("supportRequestSearchHistory")))) {
                return [];
            }

            return JSON.parse(localStorage.getItem("supportRequestSearchHistory"));
        } catch {
            return [];
        }
    });



    // Initial size effect
    /**
     * Logic for user permissions
     * If user have permission to view his own cophort or all cohorts, then only cohort dict will be assigned
     * Otherwise set no access flag
     */
    useEffect(() => {
        if (userCourses) {
            //Check if user has access to view the support Requests at all  
            if (userRole && (userRole?.acpPermissions?.functions.supportRequestsAccessToAssignedCohortRequests
                || userRole?.acpPermissions?.functions.supportRequestsCanSeeAllRequests)) {

                loadSupportRequestActions();

            } else {
                setNoAccess(true);
            }
        }
    }, [userCourses]);

    //If user does not have access tu support requests
    useEffect(() => {
        if (noAccess) {

            setACPDataLoaded(true)
        }
    }, [noAccess]);

    //** Check permission for each individual support request. E,g, coaches are not able to see personal requests */
    const checkUserPermissionPerRequestType = (dataset) => {
        //Check if user can see personal request 
        if (userRole && userRole?.acpPermissions.functions?.supportRequestsCanSeePersonalRequests === false) {
            return dataset.filter(i => i.request_type_id !== 4);
        }

        return dataset
    }

    /** Gets support request on cohort selection change */
    //** This side effect runs everytime cohort is selected/unselected from the drop down */
    // useEffect(() => {
    //     if (selectedCohortId) {

    //         let controller = new AbortController();

    //         fetchSupportRequests().then(response => {

    //             setACPData({...acpData, supportRequests: response})

    //             //If acp data is set to false
    //             //send feedback to App to state acp data loaded, otherwise no need
    //             if (!acpDataLoaded) {

    //                 setACPDataLoaded(true)
    //             }
    //         })

    //         return (() => controller?.abort());
    //     }

    // }, [selectedCohortId]);

    /** Triggered whenever requests are sorted */
    useEffect(() => {
        if (sortDirection.length > 0 && filteredSupportRequests.length > 0) {
            let sortedRequests = sortRequests([...filteredSupportRequests]);
            setFilteredSupportRequests([...sortedRequests]);
        }
    }, [sortDirection]);

    /** Triggered when user clicks on the filter checkboxes */
    useEffect(() => {
        localStorage.setItem("supportrequestfilters", JSON.stringify(filters));

        //Here we are taking data from the supportReuests, which is list of unfiltered items
        let filtered = filterSupportRequests([...supportRequests]);

        //find requests that include searchTerm
        filtered = searchSupportRequests(filtered);

        //sort the request per the selected filters
        let sorted = sortRequests(filtered);

        updateFilterCounts([...supportRequests])
        //insert into the state variable for display
        setFilteredSupportRequests(sorted);

    }, [filters]);

    /** Triggered when user enters a search term and initial mount */
    useEffect(() => {
        localStorage.setItem("supportRequestSearchTerms", JSON.stringify(searchTerms));

        //Prevent unnecessary searches
        if (supportRequests) {
            //get requests based on current searchTerms, or all requests if no searchTerms are set in state
            let requestPool = searchTerms[0] ? searchSupportRequests([...supportRequests]) : [...supportRequests];

            //update count of matching tickets per filter
            updateFilterCounts(requestPool);

            //limit displayed requests to those matching selected filters
            let filtered = filterSupportRequests(requestPool)

            //sort the requests per user's selected sort order
            let sorted = sortRequests(filtered);

            //insert into the state variable for display
            setFilteredSupportRequests(sorted);
        }
    }, [searchTerms, supportRequests, isLoading])

    /** Triggered when user updates searchHistory */
    useEffect(() => {
        localStorage.setItem("supportRequestSearchHistory", JSON.stringify(searchHistory));
    }, [searchHistory])

    useEffect(() => {
        if (!acpDataLoading && acpSupportRequests?.all) {
            setSupportRequestsData(acpSupportRequests.all)

        }
    }, [acpDataLoading, acpSupportRequests?.all])

    /**
     * @description - Perform data manupulation and filtering and store in the state variable
     * Sets the support requests data on cohort id changes and also initial mount
     * @param {*} response 
     */
    const setSupportRequestsData = (response) => {
        try {
            //1. Check if user have access to view the support requests and which type
            response = checkUserPermissionPerRequestType(response);

            //1a. Setting the support requests whatever we got back before being filtered
            setSupportRequests([...response]);

            //2. Filter the support requests 
            let filteredResponse = filterSupportRequests([...response]);

            //3. sort the support requests
            let sortedRequests = sortRequests(filteredResponse);

            //3a. Set state variable for filtered and massge requests 
            /**
            * Updates the supportRequests and filtered support request 
            * the reason we have 2 variables is beacuse one keeps the filtered data and other list of all data 
            * In case user clicks on reset filter, we can easily grab data from supportRequests
            */

            setFilteredSupportRequests([...sortedRequests]);

            //Hiding loader and shoing data under Request List componene t
            setIsLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description Sorts Support requests
     * @param {*} data 
     * @returns 
     */
    const sortRequests = (data) => {
        data.sort((a, b) => {
            return sortDirection === "oldest" ?
                new Date(a.created_at) - new Date(b.created_at) :
                new Date(b.created_at) - new Date(a.created_at);
        });
        return data;
    }

    /**
     * @description Determines if any filter is currently active in state
     * 
     * Used to prevent filter-based side effects if user is searching and has no active filters
     * @returns {Boolean} `true` if any filters are active, else `false`
     */
    const isAnyFilterActive = () => {
        if (!filters) return false;

        let d = { ...filters };
        //check for any active filters
        for (let k of Object.keys(d)) {
            if (Object.values(filters[k]).includes(true)) {
                return true;
            }
        }
        return false;
    }

    /**
     * @description updates the count of `status` and `request_type_id` for all supportRequests
     * 
     * Invoke this any time the total number of available supportRequests changes, 
     * eg, after the user has entered a search term
     * 
     * @param {*} selectedSupportRequests - List of all support requests available to user 
     * based on their current selection
     */
    const updateFilterCounts = (selectedSupportRequests) => {

        const requestCounts = { ...filterCounts };
        const requestCountStatuses = Object.keys(requestCounts?.status)

        const activeFilters = Object.keys(filterTrueValues(filters.status))

        requestCountStatuses.forEach(status => {

            if (status === "open") {
                requestCounts.status.open = selectedSupportRequests.filter(i => i.status !== "closed").length;
            }
            else {
                requestCounts.status[status] = selectedSupportRequests.filter(i => i.status === status).length;
            }
        })
        if (!activeFilters.includes('open')) {

            requestCounts.status.awaiting_review = 0
            requestCounts.status.reopened = 0

        }


        Object.keys(requestCounts.request_type_id).forEach(typeId => {
            if (!activeFilters.includes('open') && !activeFilters.includes('closed')) {
                requestCounts.request_type_id[typeId] = 0

            }
            if ((activeFilters.includes('open') && activeFilters.includes('closed'))) {
                requestCounts.request_type_id[typeId] = selectedSupportRequests.filter(i => i.request_type_id === Number.parseInt(typeId)).length;
            }
            else if (activeFilters.includes('open') && !activeFilters.includes('closed')) {
                requestCounts.request_type_id[typeId] = selectedSupportRequests.filter(i => i.request_type_id === Number.parseInt(typeId) && i.status !== 'closed').length;

            }
            else if (!activeFilters.includes('open') && activeFilters.includes('closed')) {
                requestCounts.request_type_id[typeId] = selectedSupportRequests.filter(i => i.request_type_id === Number.parseInt(typeId) && i.status === 'closed').length;

            }

        })
        setFilterCounts(requestCounts);
    }

    /**
     * @description filters the data per the stored local storage 
     * The filter is stored as {"awaiting_review":true}. String, Boolean [key,value pair]
     * @param {*} supportRequests - List of all requests for selected cohorts with no filter
     * @returns filteredData
     */
    const filterSupportRequests = (supportRequests) => {
        let filteredData = [...supportRequests];

        // if ((filters?.status.awaiting_review || filters?.status?.reopened) && (!filters?.status?.open && !filters?.status?.closed)) {
        //     console.log('here')
        //     filters.status.open = true
        // }
        //If both open and closed are unchecked no results should display
        if (!filters.status.open && !filters.status.closed) return [];

        //Loops thru each 
        Object.keys(filters).forEach((key) => {

            //Get all keys those are true - ignore false
            let trueKeys = Object.keys(filters[key]).filter(k => filters[key][k] === true);
            if (trueKeys.length > 0) {

                filteredData = filteredData.reduce((acc, ci) => {

                    for (const item of trueKeys) {

                        if (key === 'status' && item === 'awaiting_review' && !filters.status.open) {
                            continue
                        }
                        //if both open and closed are checked all items will be added 
                        else if (key === 'status' && item === 'open' && filters.status.closed && !requestExists(acc, ci)) {
                            acc.push(ci);

                        } else if (key === 'status' && item === 'open' && !filters.status.closed && ci.status !== 'closed') {
                            acc.push(ci);

                            //the data returned from the locastorage is going to be stringified
                        } else if (ci[key] && ci[key].toString() === item && !requestExists(acc, ci)) {
                            acc.push(ci);
                        }

                    }
                    return acc;
                }, []);
            }
        });

        //;

        return filteredData;
    }

    /**
     * @description reduces support requests to only those that include the searchTerms set in state
     * searchTerms is a String Array
     * @param {*} requestsToSearch - List of filtered support requests for selected cohort
     * @returns filteredData
     */
    const searchSupportRequests = (requestsToSearch) => {
        //Guard against no searchTerms by returning all requests
        if (!searchTerms?.[0]) return [...requestsToSearch];

        //Move most recent searchTerm to front of searchHistory queue and dedupe searchHistory
        if (!searchHistory[0] || searchHistory[0] !== searchTerms[searchTerms.length - 1]) {
            setSearchHistory(state => [...new Set([searchTerms[searchTerms.length - 1], ...state])]);
        }

        let filteredData = [...requestsToSearch];

        //search requests for each query, as if joined by AND
        searchTerms.forEach(query => {

            //handle multi-word searches
            let currentQuery;

            //allow for exact searches with quotes
            if (query.match(/^\".*\"$/)) { //eslint-disable-line no-useless-escape
                currentQuery = [query.replaceAll('"', "")];
            } else {
                currentQuery = query.split(" ");
            }

            //Loop through each request and check for searchTerms in the request
            filteredData = filteredData.reduce((acc, req) => {

                //the data fields that search will support
                let searchableData = [
                    req.user_lm?.first_name?.toLowerCase(),
                    req.user_lm?.last_name?.toLowerCase(),
                    req.user_lm?.preferred_name?.toLowerCase(),
                    stripHTMLFromSupportRequest(req.description?.toLowerCase()),
                    req.id?.toString()
                ];

                for (let word of currentQuery) {
                    //support for legacy requests with escaped & characters
                    let legacyWord = word.includes("&") ? word.replaceAll("&", "&amp;") : undefined;

                    if (wordFound(searchableData, word, legacyWord) && !requestExists(acc, req)) {
                        acc.push(req);
                    }
                }
                return acc;
            }, []);
        })

        return filteredData;
    }

    //Actions on request
    const loadSupportRequestActions = useCallback(async () => {
        try {
            const requestActions = await getSupportRequestActions()
            setRequestActions(requestActions);
        } catch (err) {
            console.error(err)
        }
    }, [userCourses]);

    //** MANAGE SUPPORT REQUEST  */
    const updateSupportRequestsAndResponses = (dataset) => {

        let { mk_support_request_responses, ...rest } = dataset;

        //Update the parent support request 
        let d = [...supportRequests];
        let indx = d.findIndex(i => i.id === dataset.id);

        if (indx > -1) {
            d[indx] = Object.assign(d[indx], rest);
        }

        setSupportRequests(d);

        //if support responses in the data update the cache
        if (mk_support_request_responses) updateSupportRequestResponses(rest.id, mk_support_request_responses);

    }

    /** MANAGE SUPPORT RESPONSES */
    const sortSupportResponses = (responses) => {
        responses.sort((a, b) => {
            return new Date(b.created_date) - new Date(a.created_date);
        });
        return responses;
    }

    const fetchSupportRequestResponses = async (supportId) => {
        //1. Check if the history is there for the support id. If not fetch from the database
        //2. Add the history into the cache if not available 
        //3. If available return values from cache
        try {
            let exists = supportRequestHistoryRef.current ? supportRequestHistoryRef.current.find(i => i.supportId === supportId) : null;

            if (exists) {
                return exists.responses;
            } else {

                let supportResponses = await getPastResponses(supportId);

                let sortedSoupportResponses = sortSupportResponses(supportResponses)

                supportRequestHistoryRef.current.push({
                    "supportId": supportId,
                    "responses": sortedSoupportResponses
                })

                return sortedSoupportResponses;
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description updates the array of all support responses 
     * @param {Array} responses 
     */
    const updateSupportRequestResponses = (supportId, responses) => {
        try {

            let indx = supportRequestHistoryRef.current.findIndex(i => i.supportId === supportId);

            let sortedSoupportResponses = sortSupportResponses(responses)

            supportRequestHistoryRef.current[indx].responses = sortedSoupportResponses;

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <ACPSupportRequestsContext.Provider value={{
            "filters": filters,
            "setFilters": setFilters,
            "filteredSupportRequests": filteredSupportRequests,
            "sortDirection": sortDirection,
            "setSortDirection": setSortDirection,
            "sortRequests": sortRequests,
            "supportRequests": supportRequests,
            "updateSupportRequestsAndResponses": updateSupportRequestsAndResponses,
            "updateSupportRequestResponses": updateSupportRequestResponses,
            "setFilteredSupportRequests": setFilteredSupportRequests,
            "requestActions": requestActions,
            "fetchSupportRequestResponses": fetchSupportRequestResponses,
            "isLoading": isLoading,
            "noAccess": noAccess,
            "searchTerms": searchTerms,
            "setSearchTerms": setSearchTerms,
            "searchHistory": searchHistory,
            "setSearchHistory": setSearchHistory,
            "isAnyFilterActive": isAnyFilterActive,
            "filterCounts": filterCounts
        }}>
            {children}
        </ACPSupportRequestsContext.Provider>
    )
}