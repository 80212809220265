import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react"
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { Link } from 'react-router-dom';

import { useStyles } from './styles';
import { AppDataContext } from "../../../context";
import { removeCoachSwitchToACPStorage } from '../../../helpers/userRolesHelper'
import { capitalize } from '../../../helpers/utils'

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "lightgrey",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const UserAvatar = () => {

    let classes = useStyles();

    let { user } = useAuth0();

    let [popupState, setPopstate] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    let { userRole, userData } = useContext(AppDataContext);

    const handleClick = (event) => {
        setPopstate(!popupState);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setPopstate(false);
        setAnchorEl(null);
    };

    const handleLogout = () => {
        removeCoachSwitchToACPStorage();//removes the local storage for the coach
        window.dispatchEvent(window.logoutEvent);//app level logout event 
    }

    const handleSwitchToLearnersDashboard = () => {
        removeCoachSwitchToACPStorage();
        window.location.assign('/');
    }

    return (<>
        <Button
            className={classes.userAvatarButton}
            onClick={handleClick}>
            <div className="username raleway">{capitalize(userData.first_name)} {capitalize(userData.last_name)}</div>
            <div>
                {
                    user.picture || userData.user_image_url ?
                        <Avatar sx={{ border: '2px solid #63C29D !important' }} src={userData.user_image_url ? userData.user_image_url : user.picture} /> :
                        <Avatar {...stringAvatar(user.name)} />
                }
            </div>
        </Button>
        <Menu
            sx={{
                top: "40px"
            }}
            id="basic-menu"
            open={popupState}
            onClose={handleClose}
            anchorEl={anchorEl}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{ paper: classes.userAvatarMenuPaper, list: classes.userAvatarMenuList }}
        >

            <Link style={{ textDecoration: "none", color: "black" }} to={`/profile/${user.email}`}>
                <MenuItem onClick={() => { handleClose() }} classes={{ root: classes.userAvatarMenuItem }}> My Profile</MenuItem>
            </Link>
            {
                userRole && userRole.id === 5 ?
                    <MenuItem onClick={() => { handleSwitchToLearnersDashboard() }} classes={{ root: classes.userAvatarMenuItem }}>
                        Switch to MyKenzie
                    </MenuItem> :
                    null
            }
            <MenuItem onClick={() => { handleLogout() }} classes={{ root: classes.userAvatarMenuItem }}>Sign Out</MenuItem>
        </Menu>
    </>)
}

export default UserAvatar