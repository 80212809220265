function calculateAverageSRResponseTime(requests) {
    if (!requests?.length) return null
    const getHourDiff = (startDate, endDate) => {
        const msInHour = 60 * 60 * 1000;

        return Math.round(
            Math.abs(endDate - startDate) / msInHour
        );
    }
    const validClosedRequests = requests.filter(r => r.created_at && r.updated_at)
    const sumInHour = validClosedRequests.reduce(((acc, cur) => {
        if (!cur.created_at || !cur.updated_at) return 0
        const timeInHour = getHourDiff(new Date(cur.created_at), new Date(cur.updated_at))
        return acc + timeInHour
    }), 0)
    return Math.floor(sumInHour / validClosedRequests.length)
}

export function formatRequestResponseTimeStr(requests) {
    const hours = calculateAverageSRResponseTime(requests)
    if (hours === null) return 'N/A'
    if (hours <= 1) return '1 Hour'
    if (hours < 24) return `${hours} Hours`
    if (hours < 48) return '1 Day'
    else {
        return `${Math.floor(hours / 24)} Days`
    }
}
