import React, { useContext, useEffect, useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { Button, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Formik, useFormikContext } from 'formik';
import {
    Prompt
} from "react-router-dom";

import { AppDataContext } from '../../../context';
import useDocumentTitle from '../../../helpers/useDocumentTitle';
import { ErrorSnackBar, SuccessSnackBar } from '../../../sharedComponents/snackBars';

import useProfileCommonStyles from '../../../pages/profilePage/commonProfileSyles';
import useEditProfileStyles from '../../../sharedComponents/editProfileFields/styles/editProfileStyles';

import ProfileSection from '../../../pages/profilePage/SectionHeader';
import UserAvatar from './UserAvatar';

import { SocialLinks } from '../onboarding/components';
import { ErrorContext } from '../onboarding/context';
import LearnerKenzieFocusTime from './LearnerKenzieFocusTime';
import UserAdditionalDemographics from './UserAdditionalDemograhics';

import { updateUser } from '../../../api/userManagement';
import { matchObjectProps } from '../../../helpers/utils';
import SaveIcon from '../../../icons/profile-save.svg';
import ViewProfileIcon from '../../../icons/profile_view_teal.svg';
import { LearnersDataContext } from '../../context';
import Experience from '../onboarding/Experience';
import Location from '../onboarding/Location';
import LearnerWorkSchedule from './LearnerWorkSchedule';

import Identity from '../onboarding/Identity';

import { Link } from "react-router-dom";

import Languages from '../onboarding/Languages';
import growingTextFieldTheme from "./theme";

const USER_FIELDS = {
    "preferred_name": "",
    "user_preferred_pronouns": null,
    "user_name_pronunciation": null,
    "user_location": null,
    "user_show_location": null,
    "user_show_timezone": null,
    "user_timezone": null,
    "user_primary_spoken_language": null,
    "user_additional_spoken_languages": null,
    "user_intro_interest": null,
    'user_fun_fact': null,
    "user_free_time": null,
    "user_goals": null,
    "user_dream_job": null,
    "user_programming_experience_level": null,
    "user_programming_languages": [],
    "user_social_media_links": [],
    "user_focus_time": null,
    "user_work_schedule": [],

}

/**
 * This component checks if any of fields were tocuhed, if yes it prevents users to navigate away. 
 * Confirmation box is displayed for user confirmation
 * @param {*}  setIsBlocking
 */
const IsValueChanged = ({
    setIsBlocking = function () { }
}) => {
    let { touched } = useFormikContext();

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            setIsBlocking(true);
        } else {
            setIsBlocking(false);
        }
    }, [touched])

    return null;
}

/**
 * @description Staff Edit Profile Page
 * @returns 
 */
const LearnerEditProfilePage = () => {

    useDocumentTitle();

    const editProfileClasses = useEditProfileStyles();
    const commonProfileStyles = useProfileCommonStyles();
    const theme = useTheme()
    const { user } = useAuth0();
    const { userData, setIsAllComponentsLoaded, setUserData } = useContext(AppDataContext);
    const { userCourse } = useContext(LearnersDataContext);
    // const { userCourses } = useContext(ACPDataContext);

    let [isBlocked, setIsBlocking] = useState(false);
    let [submissionSuccessfull, setSubmissionSuccessfull] = useState(false);
    let [submissionError, setSubmissionError] = useState(false);
    let [submissionErrorMessage, setSubmissionErrorMessage] = useState("Error while submitting your request");

    //Filters for roles other than student
    let acp_access = userData.mk_user_courses.filter(i => i.user_role_id !== 1)
    const [isValid, setIsValid] = React.useState(true)

    useEffect(() => {
        if (user?.email && userData && userCourse) {
            //;
            setIsAllComponentsLoaded(true);
        }
    }, [user, userData, userCourse]);

    /**
     * @description validate there are no errors before submission
     */
    const validateSubmission = (errors) => {
        if (Object.keys(errors).length > 0) {
            setSubmissionError(true);
            setSubmissionErrorMessage("Please fix the Validation Errors");
            return false;
        }
        return true;
    }

    /**
     * @description sends data to server
     * @param {*} values 
     * @param {*} setSubmitting 
     */
    const handleSubmission = async (values, setSubmitting) => {
        try {
            //;
            //Get only values present the in USER_FIELDS
            let payloadData = matchObjectProps(USER_FIELDS, values);
            payloadData.user_profile_updated_date = new Date();
            //;

            let response = await updateUser(payloadData, userData.username);

            //;
            if (response.status === 200) {
                //Update the userData in Appcontext. Only change the values those were changed
                let d = { ...userData }
                setUserData(Object.assign(d, payloadData));

                setSubmissionSuccessfull(true);
            } else {
                setSubmissionError(true);
            }

        } catch (error) {
            console.error(error);
            setSubmissionError(true);

        } finally {
            setSubmitting(false);
            setIsBlocking(false);
        }
    }
   
    return (
        <>
            {/* EDIT FORM */}
            {
                user?.email && userData?.email && (userCourse || acp_access) && (userCourse?.course || acp_access) ?
                    <div style={{ width: '100%' }} id="learner-edit-profile-parent-container"  //eslint-disable-next-line jsx-a11y/aria-role
                        role="learner edit profile container" >
                        <Formik
                            initialValues={userData}
                            onSubmit={(values, { setSubmitting }) => { handleSubmission(values, setSubmitting) }}>
                            {({ handleSubmit, isSubmitting, errors }) => (
                                <form id="learner-edit-profile-form-container"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (validateSubmission(errors)) {
                                            handleSubmit();
                                        }
                                    }
                                    }>
                                    <Prompt
                                        when={isBlocked}
                                        message={JSON.stringify({
                                            "header": "Careful: You have unsaved changes",
                                            "message": "Press cancel to go back and save these changes, or Okay to lose these changes"
                                        })}
                                    />

                                    <div className={commonProfileStyles.userAvatarContainer} style={{
                                        backgroundColor: 'transparent'
                                    }}>
                                        <div className={commonProfileStyles.container}>
                                            <UserAvatar showEditButton={true} isEditProfilePage />

                                            <Link className={commonProfileStyles.headerButton} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '40px', marginBottom: '-26px' }} to={`/profile/${userData.email}`}>
                                                <img src={ViewProfileIcon} className='button-icon' alt="" />

                                                <span className='button-text' style={{ color: theme.colors.deepAqua }} > View Profile</span>
                                            </Link>

                                        </div>
                                    </div>

                                    <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                                        <ThemeProvider theme={growingTextFieldTheme}>
                                            <ErrorContext.Provider value={{ isValid, setIsValid }}>
                                                <ProfileSection title='Identity' editView>

                                                    <Identity isEditProfilePage />
                                                    <Languages isEditPage />
                                                </ProfileSection>

                                                <ProfileSection title='Location' editView>
                                                    <Location isEditPage />
                                                </ProfileSection>

                                                <ProfileSection title='About Me' editView>
                                                    <UserAdditionalDemographics />
                                                </ProfileSection>

                                                <ProfileSection title='Experience' editView>
                                                    <Experience isEditProfilePage />
                                                </ProfileSection>

                                                {
                                                    // acp_access checks for any other roles that are NOT a student. If 0, do not show Focus Time & Work Schedule
                                                    acp_access.length === 0 && 
                                                    <ProfileSection title='Focus Time & Work Schedule' editView>
                                                        <LearnerKenzieFocusTime isEditProfilePage />
                                                        <LearnerWorkSchedule isEditProfilePage />
                                                    </ProfileSection>
                                                }

                                                <ProfileSection title='Connect' editView containerStyle={{ marginBottom: '15vh' }}>

                                                    <SocialLinks />
                                                </ProfileSection>
                                            </ErrorContext.Provider>
                                        </ThemeProvider>


                                        <div className={editProfileClasses.submitButton} style={{ position: 'fixed', bottom: '2vh', right: '5vw' }}>
                                            <Button type="submit" disabled={isSubmitting || !isValid} sx={{ boxShadow: '5px 10px 10px -8px rgb(0 0 0 / 40%)', }}>
                                                {
                                                    isSubmitting && !submissionSuccessfull ?
                                                        <CircularProgress color="inherit" size={18} /> :
                                                        null
                                                }
                                                <div style={{ display: 'flex' }}>{
                                                    isSubmitting && !submissionSuccessfull ? null :
                                                        (<><img style={{ marginRight: '5px' }} src={SaveIcon} alt=""/><span> Save Changes</span></>)
                                                }</div>
                                            </Button>
                                        </div>
                                    </div>



                                    {
                                        !isSubmitting && submissionSuccessfull ?
                                            <SuccessSnackBar
                                                message='Your changes were saved successfully'
                                                handleOnClose={() => { setSubmissionSuccessfull(false) }}
                                            /> :
                                            null
                                    }
                                    {
                                        !isSubmitting && submissionError ?
                                            <ErrorSnackBar
                                                message={submissionErrorMessage}
                                                handleOnClose={() => { setSubmissionError(false) }}
                                            /> :
                                            null
                                    }

                                    <IsValueChanged setIsBlocking={setIsBlocking} />

                                </form>
                            )}

                        </Formik>

                    </div> :
                    null
            }


        </>
    )

}

export default LearnerEditProfilePage;