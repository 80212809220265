import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    /**
     * Each Webtool
     */
     eachWebtoolContainer:{
        marginTop:"20px",
    },
    eachWebtool:{
        padding:"15px 0px",
        fontSize:"14px",
        fontWeight:400,
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    },
    eachWebtoolName:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    }
    
}))