import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';

import { Button } from '@mui/material';
import { useCohortSettingStyles } from '../styles';
import Calendar from './Calendar';
import CalendarForm from './CalendarForm';

const EachCohortCalendars = ({
    calendars = [],
    cohortId = null
}) => {

    const classes = useCohortSettingStyles();

    const [showAddCalendarForm, setShowAddCalendarForm] = useState(false);

    return (
        <div>
            <div>
                <div className={classes.subheader}>Calendars</div>
                <div className='text-normal'>These are the calendars that will appear in the learner's Classes & Events panel of the dashboard. They will have the ability to add and remove items from their schedule using the calendars linked below.</div>
            </div>
            <div>
                {
                    calendars.map(calendar => {
                        return <div className={`${classes.eachSubSection} ${classes.divider}`}
                            key={calendar.id}>
                            <Calendar
                                calendar={calendar} />
                        </div>
                    })
                }
            </div>

            {
                showAddCalendarForm ?
                    <CalendarForm
                        handleOnClose={() => { setShowAddCalendarForm(false) }}
                        cohortId={cohortId}
                    /> :
                    null
            }
            
            <Button
                onClick={() => { setShowAddCalendarForm(true) }}
                variant="text"
                startIcon={<AddIcon />}
                className={`${classes.addMdMarginTop} ${classes.textButton}`}>
                Add a new Calendar
            </Button>

        </div>
    )

}

export default EachCohortCalendars;