import Modal from "./Modal";
const ConfirmationModal = ({
  handleClose,
  handleConfirm,
  isOpen = false,
  showWarning = true,
  warningMessage1 = "",
  warningMessage2 = "",
  confirmButtonText = "Confirm",
  height = null,
  width = null,
  centerText
}) => {
  if (isOpen)
    return (
      <Modal handleClose={handleClose} height={height} width={width}>
        <div>
          {showWarning && (
            <p
              style={{
                fontFamily: "Railway, sans-serif",
                fontWeight: 700,
                fontSize: "20px",
                marginBottom: "15px",
                color: "#C2573F"
              }}
            >
              Warning!
            </p>
          )}
          <p
            style={{
              fontWeight: 600,
              fontFamily: "Raleway, sans-serif",
              fontSize: "18px",
              color: "#C2573F",

              marginBottom: "15px"
            }}
          >
            {warningMessage1}
          </p>
          <p
            style={{
              fontWeight: 600,
              fontFamily: "Raleway, sans-serif",
              fontSize: "14px",
              color: "#271800",
              marginBottom: "40px",
              textAlign: centerText ? "center" : "left"
            }}
          >
            {warningMessage2}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto"
          }}
        >
          <button
            onClick={handleClose}
            style={{
              background: "transparent",
              border: 0,
              outline: "none",
              color: "#1D3969",
              fontSize: "14px",
              fontWeight: 600,
              cursor: "pointer",
              minWidth: "100px"
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            style={{
              color: "#FFF",
              backgroundColor: "#C2573F",
              fontSize: "14px",
              fontWeight: 500,

              outline: "none",
              border: "0",
              cursor: "pointer",
              borderRadius: "4px",
              minWidth: "180px",
              padding: "10px"
            }}
          >
            {confirmButtonText}
          </button>
        </div>
      </Modal>
    );
  else return null;
};

export default ConfirmationModal;
