import { makeStyles } from "@mui/styles";
import React from "react";
import 'react-quill/dist/quill.snow.css';
import { CustomQuill } from "./CustomQuill";

const QUILL_MODULES = {
    toolbar: [
        [
            { size: ["0.75em", "1em", "1.5em", "2.5em"] },
            // "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "code-block",
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ] 
    ],
    clipboard: {
        matchVisual: false
    }
}

const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image'
    // 'video'
  ];

const useStyles = makeStyles(theme => ({
    richTextEditorContainer: {
        '& .ql-container':{
            minHeight:"100px !important"
        }
    }

}))

const RichTextEditor=({
    name,
    id,
    placeholderText,
    handleOnChange,
    value,
    toolbar
})=>{

    const classes=useStyles();
    let spreadProps = {
        name,
        id,
        placeholder: placeholderText,
    }
    if(value){
        spreadProps["value"] = value
    }
    return (
        <CustomQuill 
            className={classes.richTextEditorContainer}
            onChange={(content)=>{
                handleOnChange(content);
            }}
            style={{backgroundColor:"white"}}
            modules={{
                toolbar: toolbar || QUILL_MODULES["toolbar"],
                clipboard: QUILL_MODULES["clipboard"]
            }}
            formats={formats}
            theme="snow"
            {...spreadProps} />
    )
}

export default RichTextEditor;