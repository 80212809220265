import { memo } from 'react'
const Captions = ({ text }) => {
    // const [fontSize, setFontSize] = useState('28px')
    const fontSize = '28px'
    return (
        <div className='captions-container' style={styles.container}>
            <span className='caption-text' style={{ ...styles.text, fontSize: fontSize }}>{text}</span>
        </div>
    )
}

const styles = {
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,

        display: 'flex',
        alignItems: 'flex-end',
        pointerEvents: 'none',
        padding: '10px',
        boxSizing: 'border-box'
    },
    text: {
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,.6)'
    }
}

export default memo(Captions)