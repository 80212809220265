import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    /**
     * Each Staff member
     */
    eachStaffMemberContainer:{
        marginTop:"20px",
    },
    eachStaffMember:{
        padding:"15px 0px",
        fontSize:"14px",
        fontWeight:400,
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    eachStaffMemberName:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    },
    profileImage: {
        boxSizing: "border-box",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        border: `2.5px solid ${theme.MyKenzie.cardColors.primary}`,
    },
    Image: {
        position: "relative",
        left: "50%",
        top: "50%",
        zIndex: -1,
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
    },

    /**
     * User Search Box
     */
    searchInputBlock: {
        margin: "10px 0",
        width: "350px",
        position:"relative"
    },
    searchInput: {
        fontSize: "14px",
        padding: "10px 10px 10px 30px",
        border: '1px solid #c4c4c4',
        borderRadius: "6px",
        height: "1.4375em",
        display: "block",
        width: "94%",
        outline: "#BFE0FF",
        backgroundPosition: "10px 13px"
    },
    searchedItems: {
        position:"absolute",
        border: '1px solid #c4c4c4',
        width: "100%",
        left:"0px",
        right:"0px",
        top:"40px",
        borderRadius: "6px",
        padding: "8px",
        background: "#ffffff",
        marginTop: "5px",
        fontSize: "14px",
        maxHeight: "185px",
        overflow: "auto",
        zIndex:1
    },
    searchedItem: {
        display:"block !important",
        width: "100%",
        padding: "10px",
        borderRadius: "4px",
    },
    searchedItem_noresult: {
        width: "auto",
        padding: "0",
        fontSize: "13px"
    }
}))