import { getCalendarInfoByCohortId, getVideosByGoogleCalendarId, getAllCalendars } from "../../../api";
import { stripLeadingPunctuation } from '../../../learner/pages/watch/utils'
import { updateVideoData } from "./api";
import moment from "moment";
export function removeDuplicateCalendars(calendars) {
    return calendars.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t === value
        ))
    )
}

export const getVideosByCohortId = async ({ cohortId, cancelSignal = null }) => {
    let availableVideos = []
    let calendarIds = [];
    if (cohortId === null || cohortId === undefined) return []
    let calendars = await getCalendarInfoByCohortId(cohortId, cancelSignal)
    if (calendars.length) {
        for (let calendar of calendars) {
            const calendarId = calendar.google_calendar_id
            calendarIds.push(calendarId)
            const videos = await getVideosByGoogleCalendarId(calendarId, cancelSignal)
            availableVideos = [...availableVideos, ...videos]
        }
    }

    return { availableVideos, calendarIds }
}

export const getCohortNamesByCalendars = async ({ controller }) => {
    let calendars = await getAllCalendars(controller.signal)
    const result = {}
    calendars = calendars.filter(calendar => calendar.calendar_name)

    for (let calendar of calendars) {

        let courseName = calendar.calendar_name
        if (courseName.toLowerCase().endsWith('calendar')) {
            courseName = courseName.slice(0, courseName.toLowerCase().indexOf('calendar'))
        }
        if (courseName.toLowerCase().endsWith('events') && !courseName.toLowerCase().startsWith('speaker')) {
            courseName = courseName.slice(0, courseName.toLowerCase().indexOf('events'))
        }


        result[calendar.google_calendar_id] = courseName.trim()


    }
    return result
}

export function sortVideosNewest(videos) {
    return videos.sort((a, b) => {
        const aDate = new Date(a.video_details.recording_start)
        const bDate = new Date(b.video_details.recording_start)
        if (aDate > bDate) return -1
        else if (aDate < bDate) return 1
        return 0
    })
}

export function sortVideosOldest(videos) {
    return videos.sort((a, b) => {
        const aDate = new Date(a.video_details.recording_start)
        const bDate = new Date(b.video_details.recording_start)
        if (aDate > bDate) return 1
        else if (aDate < bDate) return -1
        return 0
    })
}

export function sortVideosMostViews(videos) {
    return videos.sort((a, b) => {
        const aViews = Number(a.views)
        const bViews = Number(b.views)
        if (aViews > bViews) return -1
        else if (aViews < bViews) return 1
        return 0
    })
}
export function sortVideosLeastViews(videos) {
    return videos.sort((a, b) => {
        const aViews = Number(a.views)
        const bViews = Number(b.views)
        if (aViews > bViews) return 1
        else if (aViews < bViews) return -1
        return 0
    })
}

export function handleSearch({ searchTerm, filteredVideos }) {
    filteredVideos = filteredVideos.filter(video => {
        searchTerm = searchTerm.toLocaleLowerCase()
        const title = stripLeadingPunctuation(video.title).toLowerCase()
        const description = video.description?.toLocaleLowerCase() || ''
        return title.includes(searchTerm) || description.includes(searchTerm) ||
            video.tags?.filter(tag => tag.toLowerCase().includes(searchTerm)).length


    })
    return filteredVideos
}


export function sortUpdates(updates) {
    if (!updates) return null
    return updates.sort((a, b) => {
        const aDate = new Date(a.updated_date)
        const bDate = new Date(b.updated_date)
        if (aDate < bDate) return 1
        else if (aDate > bDate) return -1
        return 0

    })
}

export const formatEventDuration = (startDateTime, endDateTime) => {
    let result = `${moment(startDateTime).format('M/DD/YYYY')} | `
    const start = moment(startDateTime)
    const end = moment(endDateTime)
    if (start.format('h') === end.format('h')) return result + start.format('ha') + ' EST'
    else return result + start.format('h') + '-' + end.format('ha') + ' EST'
}
export function formatEventTextString(item) {
    const start = moment(item.start_date_time).format('M/D/YY')
    let str = start === 'Invalid date' ? 'Unknown Date' : `${start}`
    return `${str} - ${item.summary}`
}


function formatUpdateArray(data, videoUpdates, userName, changes) {
    const update = { changes: changes, updated_by: userName, updated_date: new Date() }
    if (videoUpdates) {
        videoUpdates.push(update)
    }
    else {
        videoUpdates = [update]
    }
    return videoUpdates
}
export async function updateVideoDetails({ data, userName, video, cancelSignal }) {

    if (!userName.trim()) userName = 'Unknown User'
    data = cleanVideoFormData(data)
    const changes = findVideoUpdateChanges(video, data)
    if (!changes.length) return true
    const updates = formatUpdateArray(data, video.updates, userName, changes)
    const updateFields = updates[updates.length - 1].changes.map(c => c.toLowerCase())

    const payload = formatUpdatePayload(data, updates, updateFields)
    const didUpdate = await updateVideoData(payload, video.uuid, cancelSignal)
    if (didUpdate) return payload
    else return null
}
function findVideoUpdateChanges(video, data) {
    let result = []
    if (video.description !== data.description) {
        result.push('Description')
    }
    if (stripLeadingPunctuation(video.title) !== data.title) {
        result.push('Title')
    }
    if (video.host && video.host !== data.host) {
        result.push('Host')
    }
    else if (video.source_details?.meeting_host_email !== data.host) {
        result.push('Host')
    }
    if (video.tags?.length !== data.tags?.length || video.tags?.sort().join(',') !== data.tags?.sort().join(',')) {
        result.push('Tags')
    }

    return result
}

function cleanVideoFormData(formData) {
    for (let key in formData) {
        if (formData[key] === '') formData[key] = null
    }
    if (!formData?.tags?.length) formData.tags = null
    return formData
}
function formatUpdatePayload(data, updates, updateFields) {
    const payload = {}
    for (let key in data) {
        const val = data[key]
        if (updateFields.includes(key)) {
            payload[key] = val
        }
    }
    payload.updates = updates
    return payload
}

export async function toggleVideoArchived(archived, uuid, cancelSignal) {
    const didUpdate = await updateVideoData({ archived }, uuid, cancelSignal)
    return didUpdate
}

export function filterEventsByRecordingDate(recordingDate, events) {
    let eventStartDate = moment(recordingDate).format('MDY')

    events = events.filter((value, index, self) => //Remove duplicates
        index === self.findIndex((t) => (
            t.summary === value.summary && t.start_date_time === value.start_date_time
        ))
    )
    return events.filter(event => {
        let recordingStartDate = moment(event.start_date_time).format('MDY')
        return eventStartDate === recordingStartDate
    })
}


