import ConfirmationModal from "./ConfirmationModal";
export const ClearConfirmationModal = ({
  handleClose,
  handleConfirm,
  isOpen
}) => {
  return (
    <ConfirmationModal
      showWarning={false}
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      warningMessage2={"Discard unsaved changes?"}
      confirmButtonText="Discard"
      height="90px"
      width="275px"
      centerText
    ></ConfirmationModal>
  );
};

export const InactiveConfirmationModal = ({
  handleClose,
  handleConfirm,
  isOpen,

  memberName
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      warningMessage1={
        "You are about to revoke MyKenzie/ACP access to this member for this cohort."
      }
      warningMessage2={
        <>
          Are you sure you want to set{" "}
          <b style={{ color: "#C2573F" }}>{memberName}</b> as Inactive?
        </>
      }
      confirmButtonText="Revoke MyKenzie Access"
    ></ConfirmationModal>
  );
};

export const DeleteConfirmationModal = ({
  handleClose,
  handleConfirm,
  isOpen,
  memberName
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      warningMessage1={
        "You are about to revoke MyKenzie/ACP access to this member and remove them from this cohort."
      }
      warningMessage2={
        <>
          Are you sure you want to remove{" "}
          <b style={{ color: "#C2573F" }}>{memberName}</b> from this cohort?
        </>
      }
      confirmButtonText="Remove From Cohort"
    ></ConfirmationModal>
  );
};
