import React, { useContext } from 'react';
import { ACPAnnouncementContext } from '../../context';
import CircularLoader from '../../../sharedComponents/CircularLoader';

import EachAnnouncement from "./EachAnnouncement";

const AnnouncementHistoryList = () => {

    const { filteredAnnouncements, isLoading } = useContext(ACPAnnouncementContext);

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader /> :
                    <>
                        {filteredAnnouncements && filteredAnnouncements.map((announcement, i) => {
                            return <EachAnnouncement
                                isOddIndex={i % 2 !== 0}
                                key={announcement.id}
                                data-testid={`each-announcement-panel-${announcement.id}`}
                                announcement={announcement} />
                        })}
                    </>
            }

        </div>
    )
};

export default AnnouncementHistoryList;