import Slack from '../../images/slack.png';

import { ReactComponent as Discord } from '../../images/discord.svg';
import { ReactComponent as Facebook } from '../../images/facebook.svg';
import { ReactComponent as Github } from '../../images/github.svg';
import { ReactComponent as Instagram } from '../../images/instagram_icon.svg';
import Image from '../../images/kenzieAvatar.png';
import { ReactComponent as LinkedIn } from '../../images/linkedIn.svg';
import { ReactComponent as Portfolio } from '../../images/portfolio.svg';
import { ReactComponent as Tiktok } from '../../images/tiktok.svg';
import { ReactComponent as Twitch } from '../../images/twitch.svg';
import { ReactComponent as Twitter } from '../../images/twitter.svg';
import { ReactComponent as PersonalWebsite } from '../../images/website_icon.svg';
import { ReactComponent as Youtube } from '../../images/youtube.svg';

import { filterForCourses } from '../../helpers/userCourseHelper';

const getSocialMediaIcon = (platform_name) => {
    switch (platform_name.toLowerCase()) {
        case "slack":
            return Slack
  
        case "instagram":
            return <Instagram />

        case "linkedin":
            return <LinkedIn />
 
        case "github":
            return <Github />
 
        case "facebook":
            return <Facebook />
  
        case "twitter":
            return <Twitter />
  
        case "twitch":
            return <Twitch />

        case "personal website":
            return <PersonalWebsite />
   
        case "youtube":
            return <Youtube />
       
        case "discord":
            return <Discord />
       
        case "portfolio":
            return <Portfolio />
    
        case "tiktok":
            return <Tiktok />
      
        default:
            return <img src={Image} alt="" style={{ width: "30px", height: "30px" }} />
   
    }
}

const getSocialMediaLink = (platform_name, profileData) => {
    if (profileData?.user_social_media_links !== null) {
        let socialMedia = profileData.user_social_media_links.find(platform => platform.name.toLowerCase() === platform_name);

        //this validation is added as backward compatibility for the users who already has the invalid url
        //eslint-disable-next-line no-useless-escape
        if (socialMedia && socialMedia?.link && !(/(https\:\/\/)|(http\:\/\/)/).test(socialMedia.link)) {
            return `https://${socialMedia.link}`;
        } else if (socialMedia && socialMedia?.link) {
            return socialMedia.link;
        }
    }
    return null
}

const getRelatedCourses = (profileData, userData) => {
    let profileCourses = filterForCourses(profileData)
    let userCourses = filterForCourses(userData)
    let relatedCourses = [];

    for (let i = 0; i < userCourses.length; i++) {
        let course = profileCourses.find(j => j?.course_id === userCourses[i]?.course_id);
        if (course) {
            relatedCourses.push(course);
        }
    }
    return relatedCourses
}

export {
    getSocialMediaIcon,
    getSocialMediaLink,
    getRelatedCourses
};

