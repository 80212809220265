import userRoles from './userRoles';

/**
* @description checks if user is admin
* @returns {Boolean} true if admin | otherwise false
*/
export const checkIfUserIsAdmin = (user) => {
    if (user) {
        return user["https://kenzie.academy/roles"].includes("Staff") ||
            user["https://kenzie.academy/roles"].includes("Admin") ||
            user["https://kenzie.academy/roles"].includes("Instructor")
    }
}

/**
* @description checks if user is coach
* @returns {Boolean} true if Coach | otherwise false
*/
export const checkIfUserIsCoach = (userData) => {
    if (userData) {
        //check if user is assigned as coach for any of the courses under mk_user_courses 
        //Coach will be asssigned to multiple courses in the database 
        //Corse 1 = as coach
        //Course 1 = as student 
        //Course 2 = as coach
        //Coach role id = 5
        let coachCourses = getCoursesPerRole(userData, 5);
        return coachCourses.length > 0;
    }
}

/**
 * @description checks if user is student
 * @returns {Boolean} true if Student | otherwise false
 */
export const checkIfUserIsStudent = (userData) => {
    if (userData) {
        let studentCourses = getCoursesPerRole(userData, 1);
        return studentCourses.length > 0;
    }
};

export const getCoachCoursesPerApp = (userData) => {

    //Logic flow: 
    //current view = acp, but no courses assigned with coach role
    //app will check if user has any courses assigned as student
    //if yes switch to learners dashboard 
    //current view != acp, but no courses assigned with student role
    //app will check if user has any courses assigned as coach
    //if yes switch to acp 
    try {
        if (userData) {
            //Get courses as for roleid = 5 
            let coursesRoleId5 = getCoursesPerRole(userData, 5);
            let coursesRoleId1 = getCoursesPerRole(userData, 1);

            let isACP = localStorage.getItem("coachSwitchToACP") === "true";

            if (isACP && coursesRoleId5.length > 0) {


                return coursesRoleId5;

            } else if (!isACP && coursesRoleId1.length > 0) {


                removeCoachSwitchToACPStorage();//We dont want coach to navigate to ACP anymore
                return coursesRoleId1;

            } else if (isACP && coursesRoleId5.length === 0 && coursesRoleId1.length > 0) {


                removeCoachSwitchToACPStorage();//We dont want coach to navigate to ACP anymore
                return coursesRoleId1;

            } else if (!isACP && coursesRoleId5.length > 0 && coursesRoleId1.length === 0) {


                return coursesRoleId5;
            }

            return [];//if nothing matches
        }
    } catch (error) {
        throw error;
    }

}

export const getCoursesPerRole = (userData, roleId) => {
    return userData.mk_user_courses.filter(i => i.user_role_id === roleId)
}

/**
 * @description setLocalStorage for Coach ACP switch 
 */
export function setCoachSwitchToACPStorage() {
    localStorage.setItem("coachSwitchToACP", true);
}

export function removeCoachSwitchToACPStorage() {
    // localStorage.removeItem("coachSwitchToACP");
    localStorage.setItem("coachSwitchToACP", false);

}

/**
 * @description check if user have access to the page 
 * @param {string & object} menuName/page & userRole  
 */
export function checkIfUserHaveAccessToPage(menuName, userRole) {
    try {
        let isPermitted = false;
        if (Array.isArray(menuName)) {
            let checkAll = [];
            menuName.forEach(m => {
                checkAll.push(userRole?.acpPermissions?.menuAccess[m]);
            })
            isPermitted = checkAll.every(i => i === true);
        } else {
            isPermitted = userRole?.acpPermissions?.menuAccess[menuName]
        }
        return isPermitted;

    } catch (error) {
        return false;
    }
}

//Check is user can access the ACP Menu Item
export const checkIfUserHasPermissionForACPMenuItem = (permissionSchemeKey, userRole, options = null) => {
    if (permissionSchemeKey === null && options === null) {
        return true;

    } else if (permissionSchemeKey === null && options !== null && options?.item?.isHeader) {
        //find if user has permission for any one of the items under header
        let items = options.items.filter(i => i.parentId === options.item.id);
        if (items.length === 0) {
            return true;//if there are no children, then assumed user has permission to access the header

        } else {
            let hasPermission = items.reduce((acc, ci) => {
                if (ci.acpPermissionSchemeKey === null) {
                    acc.push(ci)
                }
                if (ci.acpPermissionSchemeKey !== null
                    && userRole?.acpPermissions?.menuAccess
                    && userRole?.acpPermissions?.menuAccess[ci.acpPermissionSchemeKey]) {
                    acc.push(ci)
                }
                return acc;
            }, []);
            return hasPermission.length > 0;
        }

    } else if (permissionSchemeKey !== null
        && userRole?.acpPermissions?.menuAccess
        && userRole?.acpPermissions?.menuAccess[permissionSchemeKey]) {
        return true;
    }

    return false;
}

/**
 * @description Get User Role Information from user role id 
 * @param {Integer} roleId
 */
export const getRoleNameByRoleId = (roleId) => {
    let role = userRoles.find((user) => user.id === roleId);
    return role?.uiName
};