import { Tooltip } from "@mui/material";
import moment from 'moment';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CCIcon } from '../../../icons/cc-icon.svg';
import { ReactComponent as NewBadgeIcon } from '../../../icons/new_badge.svg';
import DefaultThumbnail from './DefaultThumbnail';
import useStyles from "./styles";
import TopicPillsSection from './TopicPillsSection';
import { getVideoPostedDate, getVideoTotalTime, stripLeadingPunctuation } from "./utils";
export default function EachVideoCard({ video }) {

    const daysAgo = useRef(moment().diff(moment(video?.video_details?.recording_start), 'days'))
    const styles = useStyles()

    return (
        <Link to={`/media/${video.uuid}`} style={{ textDecoration: 'none' }} className={`${styles.card} unselectable`}>
            <div className={styles.thumbnailContainer}>
                {(video?.thumbnail_cdn_url && video.thumbnail_cdn_url.endsWith('.png')) ?
                    <img src={video?.thumbnail_cdn_url} className={styles.thumbnail} alt={`video thumbnail for ${stripLeadingPunctuation(video?.title || "")}`} />
                    :
                    <DefaultThumbnail title={stripLeadingPunctuation(video?.title || "")} />
                }
                {video.transcripts_cdn_url && video.transcripts_cdn_url !== 'null' ?
                    <div className={`${styles.visIcon} absolute`}>

                        <Tooltip title='Closed Captioning Available'>
                            <CCIcon className='icon' />
                        </Tooltip>
                    </div>
                    :
                    null
                }

                <div className={`${styles.durationContainer} absolute`}>
                    {getVideoTotalTime(video.video_details?.recording_start, video.video_details?.recording_end,)}
                </div>
                {daysAgo.current < 4 && (
                    <NewBadgeIcon className={styles.newBadge} />
                )}
            </div>
            <div className={styles.bottom}>
                <p className={styles.title}> {stripLeadingPunctuation(video?.title || "")}</p>
                <div className={styles.dateContainer}>
                    <span className={styles.relativeDate}> {getVideoPostedDate(video.video_details.recording_start)}</span>
                    <span className={styles.dateBullet}>•</span>
                    <span className={styles.date}>{moment(video.video_details.recording_start).format('M/DD/yyyy')}</span>
                </div>
                <TopicPillsSection video={video} />
            </div>
        </Link>
    )
}

