
import ManageCohortRow from "./ManageCohortRow";
import { Skeleton } from "@mui/material";
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2";

export default function ManageCohortList({ cohort, members, isLoading, setAlert, setUpdatedMembers, disableActions }) {
  const { userData } = useACPDataContextV2()

  const styles = {
    tableRow: {
      minHeight: "44px",
      display: "grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: userData.hasAdminAccess ? "22% 22%  12% 12% 12% 12% 12%" : "22% 22%  12% 12% 12% 12% ",

      alignItems: "center",
      paddingLeft: "16px"
    }
  };

  const SkeletonLoader = () => (
    [0, 0, 0, 0, 0].map((e, i) => (
      <div key={i} style={styles.tableRow}><Skeleton width={'75%'} /> <Skeleton width={'90%'} /> <Skeleton width={'90%'} /> <Skeleton width={'90%'} /> <Skeleton width={'90%'} /> <Skeleton width={'90%'} /> <Skeleton width={'90%'} /></div>
    ))
  )

  return (
    <div className="manage-cohort-table-container">

      <div
        className="manage-cohort-table-row"
        style={{
          backgroundColor: "#E4EEFF",
          borderRadius: "10px 10px 0 0",
          borderBottom: "1px solid #000000"
        }}
      >
        <span className="manage-cohort-table-cell">Name</span>
        <span className="manage-cohort-table-cell">Kenzie Email Address</span>
        <span className="manage-cohort-table-cell">Status</span>
        <span className="manage-cohort-table-cell">Role</span>
        <span className="manage-cohort-table-cell">LMS ID</span>
        <span className="manage-cohort-table-cell">Connect Visibility</span>
        {userData.hasCurrentCohortAdminAccess ?
          <span className="manage-cohort-table-cell" >Actions</span>
          : null}
      </div>
      {isLoading && <SkeletonLoader />}
      {!isLoading && !members.length ? (
        <p style={{ width: '100%', textAlign: 'center' }}>No Members Found</p>
      ) :
        members.map((memberData, i) => (
          <ManageCohortRow
            setUpdatedMembers={setUpdatedMembers}
            setAlert={setAlert}
            memberData={memberData}
            disableActions={disableActions}
            key={`${memberData?.email}-${i}`}
            showBorder={
              members.length !== 1 && i !== members.length - 1
            }
          />
        ))
      }
    </div>
  );
}


