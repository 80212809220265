import React, { useContext } from 'react'
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { AppDataContext } from '../../../context';

/**
 * Allowed locations where create button must be displayed
 */
const CREATE_BUTTON_LOCATION = [
    '/acp/announcementHistory/',
    '/acp/admin/settings/'
]
const CreateButton = React.forwardRef((props, ref) => {

    const classes = useStyles();

    const { userRole } = useContext(AppDataContext);

    const location = useLocation();

    const checkIfHaveAccess = () => {
        if (location.pathname === '/acp/announcementHistory/' &&
            userRole?.acpPermissions?.functions?.annoucementsCanCreateAnnouncement) {
            return true;
        } else if (location.pathname === '/acp/admin/settings/' &&
            location.hash === "#cohort-management" &&
            userRole?.acpPermissions?.functions?.adminSettingsCreateCohort) {
            return true;
        }
        return false;
    }

    /**
     * @description - The create button must be visible only on certain pages and also should be visible only if user have access to create the item 
     * The below logic looks at the current location and then mounts the button
     * CREATE_BUTTON_LOCATION are the location, buttons should be visible
     */
    return (<>
        {
            CREATE_BUTTON_LOCATION.includes(location.pathname) && checkIfHaveAccess() ?
                <div className={classes.createButton}
                    ref={ref}
                    onClick={props.handleOnClick}
                    style={
                        {
                            background: props.buttonBgColor ? props.buttonBgColor : null,
                            border: props.border ? "2px solid #1D3969" : 0,

                        }
                    }>
                    <div style={{ marginRight: "10px" }}>Create New </div>
                    {!props.hideIcon ? <AddIcon /> : null}
                </div> :
                null
        }

    </>)
})

export default CreateButton;