import { MenuItem, Skeleton } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from 'react';
import { getUsers } from '../../../../../api/userManagement';
import { hideOffFocusEventHandler } from '../../../../../helpers/utils';
import StyledTextInput from '../StyledTextInput';
export const UserSearchInput = ({
    onUserSelection = function () { },
    inputProps,
    hasError,
    currentInputVal,
    selectedEmail
}) => {

    const classes = useStyles();


    const containerRef = useRef(null);


    const [isSearching, setIsSearching] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [hideSearchResultsBox, setHideSearchResultsBox] = useState(true);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    };

    useEffect(() => {

        hideOffFocusEventHandler(containerRef.current, () => {
            setHideSearchResultsBox(true);

        })
        setHideSearchResultsBox(true);

    }, [])

    useEffect(() => {
        if (!currentInputVal || currentInputVal === selectedEmail) {
            setHideSearchResultsBox(true)
        }
    }, [currentInputVal, selectedEmail])
    const handleSearchInput = async (event) => {

        try {
            if (event.target.value.length === 0) {
                setSearchResults([]);
                setHideSearchResultsBox(true)
            } else {
                setIsSearching(true);
                setHideSearchResultsBox(false);

                let response = await getUsers({
                    username: `*${event.target.value}*`,
                    expand: "cohorts",
                    attributes: "username,email,first_name,last_name,user_image_url,user_lms_id",
                    limit: 100
                })

                //Exclude users 
                //See logic explanation in excludeInstructorsFromSearch
                // response = excludeInstructorsFromSearch({
                //     dataset: response,
                //     cohortId: cohortId,
                //     selectedUsers: selectedUsers
                // });

                //;
                setSearchResults(response);
                setIsSearching(false);
            }

        } catch (error) {
            console.error(error)
        }

    };

    const onClickHandler = async (staff) => {
        //;
        let d = [...selectedUsers];

        let userCourseObject = {
            "user_lms_id": staff.user_lms_id,
            "email": staff.username,
            "first_name": staff.first_name,
            "last_name": staff.last_name,
        };

        d.push(userCourseObject);

        setSelectedUsers(d);
        setSearchResults([]);//empty search results 
        setHideSearchResultsBox(true);//Hide the searhc results box 
        setIsSearching(false)
        onUserSelection(userCourseObject, true);//send data to parent component

    }

    return (
        <div ref={containerRef}>

            <StyledTextInput
                hasError={hasError}
                inputProps={{
                    ...inputProps,
                    className: classes.searchInput,

                    autoComplete: "chrome-off",

                    onFocus: (event) => {
                        // setHideSearchResultsBox(false)
                        event.target.setAttribute('autocomplete', 'off');
                    },

                    placeholder: 'Email',
                    onInput: debounce(handleSearchInput, 300)
                }
                } />


            {
                !hideSearchResultsBox ?
                    <div className={classes.searchedItems}>
                        {
                            isSearching ?
                                <div >
                                    <div>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </div>
                                </div> :
                                !isSearching && searchResults.length > 0 ?
                                    <>
                                        {
                                            searchResults.map(item => {
                                                return <div key={item.username}
                                                    style={{
                                                        marginBottom: "10px"
                                                    }}>
                                                    <MenuItem
                                                        className={classes.searchedItem}
                                                        onClick={() => onClickHandler(item)}>
                                                        <div style={{
                                                            fontWeight: "bold"
                                                        }}>{item.first_name} {item.last_name}</div>
                                                        <div>{item.username}</div>
                                                    </MenuItem>
                                                </div>
                                            })
                                        }
                                    </>
                                    :
                                    !isSearching && searchResults.length === 0 ?
                                        <div className={classes.searchedItem_noresult}>No existing user found. A new user will be created.</div> :
                                        null
                        }
                    </div> :
                    null
            }

        </div>
    )
}




export const useStyles = makeStyles((theme) => ({
    /**
     * User Search Box
     */
    searchInputBlock: {
        margin: "10px 0",
        width: "90%",
        position: "relative"
    },
    searchInput: {
        fontSize: "14px",
        padding: "10px 10px 10px 30px",
        border: '1px solid #c4c4c4',
        borderRadius: "6px",
        // height: "1.4375em",
        display: "block",
        width: "94%",
        outline: "#BFE0FF",
        backgroundPosition: "10px 13px"
    },
    searchedItems: {
        position: "absolute",
        border: '1px solid #c4c4c4',
        width: "270px",
        left: "-10px",
        right: "0px",
        top: "40px",
        borderRadius: "6px",
        padding: "8px",
        background: "#ffffff",
        marginTop: "5px",
        fontSize: "14px",
        maxHeight: "185px",
        overflow: "auto",
        zIndex: 99
    },
    searchedItem: {
        display: "block !important",
        width: "100%",
        padding: "10px",
        borderRadius: "4px",
    },
    searchedItem_noresult: {
        width: "auto",
        padding: "0",
        fontSize: "13px"
    }
}))