import React from 'react';
import { ReactComponent as NoEventsTodayImage } from '../../../../images/noEventsToday.svg';
import { useStyles } from './styles';

const NoEventsTodayView = () => {

    const classes = useStyles();

    let date = new Date().toLocaleString('default', {
        weekday: "long",
        month: 'long',
        day: '2-digit',
    });;

    return (
        <div className={classes.noEventsTodayContainer}>
            <div className={classes.dateHeader}>
                <p>{date}</p>
            </div>
            <div className={classes.noEventsTodayContent}>
                <div className={classes.noEventsTodayImage}>
                    <NoEventsTodayImage />
                </div>
                <div className={classes.noEventsTodayContext}>
                    <div className={classes.noEventsText}>
                        <p>
                            There aren’t any classes or events scheduled for today. 
                        </p>
                    </div>
                    <div className={classes.noEventsSubtext}>
                        <p>
                            It’s a great day to focus on your assignments, connect with classmates, or take a break. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoEventsTodayView;