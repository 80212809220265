import { Formik } from 'formik';
import React, { useContext } from 'react';

import { ACPSupportRequestsContext } from '../../context';

import { v4 as uuid4 } from 'uuid';

import MenuCheckbox from '../../components/MenuCheckbox';
import SearchPanel from './search';
import SortRequests from './SortRequests';

const REQUEST_STATUS = [{
    'id': "open",
    "label": "Open Requests"
}, {
    'id': "closed",
    "label": "Closed Requests"
}];

const REQUEST_TYPES = require('./typeMapping.json');
const REQUEST_URGENCY = require('./status.json').filter(i => i.id === "awaiting_review" || i.id === "reopened");

export default function RequestLeftMenu() {

    const { filters,
        setFilters,
        filterCounts,
    } = useContext(ACPSupportRequestsContext);

    //the empty string applied to the color material UI checkbox prevents it from taking on the default material UI color


    const handleChange = (e) => {

        let d = { ...filters };

        d[e.target.name][e.target.value] = e.target.checked;



        setFilters(d);
    };

    const getStatusCount = (statusId) => {
        try {
            return filterCounts.status[statusId];
        } catch (error) {
            return 0;
        }
    }

    const getRequestTypeCount = (typeId) => {
        try {
            return filterCounts.request_type_id[typeId];
        } catch (error) {
            return 0;
        }
    }

    return (
        <div className='menuContainer' id="support-request-left-pane">
            <div className='header'>Sort</div>

            <div className='subItemsContainer'>
                <SortRequests />
            </div>

            <div className='header'>Filters</div>

            <div className='subheader'>Status</div>

            <div className='subItemsContainer' id="support-request-status-filter-container">
                {
                    REQUEST_STATUS.map(requestStatus => {
                        let count = getStatusCount(requestStatus.id);
                        return <div key={uuid4()}>
                            <MenuCheckbox
                                value={requestStatus.id}
                                name="status"
                                isDisabled={count === 0}
                                isChecked={filters.status[requestStatus.id] === true && count > 0}
                                handleOnChange={handleChange}
                                label={`${requestStatus.label} (${count})`}
                            />
                        </div>
                    })
                }
            </div>


            <div className='subheader'>Urgency</div>

            <div className='subItemsContainer' id="support-request-urgency-filter-container">

                {
                    REQUEST_URGENCY.map(requestStatus => {
                        let count = getStatusCount(requestStatus.id);
                        return <div key={uuid4()}>
                            <MenuCheckbox
                                value={requestStatus.id}
                                name="status"
                                isDisabled={count === 0}
                                isChecked={filters.status[requestStatus.id] === true && count > 0}
                                handleOnChange={handleChange}
                                label={`${requestStatus.label} (${count})`}
                            />
                        </div>
                    })
                }
            </div>

            <div className='subheader'>Request Type</div>

            <div className='subItemsContainer' id="support-request-request-type-filter-container">
                {
                    REQUEST_TYPES.map(type => {
                        let count = getRequestTypeCount(type.id);
                        return <div key={uuid4()}>
                            <MenuCheckbox
                                value={type.id}
                                name="request_type_id"
                                isDisabled={count === 0}
                                isChecked={filters.request_type_id[type.id] === true && count > 0}
                                handleOnChange={handleChange}
                                label={`${type.label} (${count})`}
                            />

                        </div>
                    })
                }
            </div>


            <Formik initialValues={{ query: "" }} onSubmit={(values, { resetForm }) => {
                resetForm();
                //remove focus from Autocomplete
                document.querySelectorAll('input, textarea').forEach(el => el === document.activeElement ? el.blur() : null);
            }}>
                {({ values }) => (
                    <SearchPanel />
                )}
            </Formik>

        </div>
    )
}