import styled from "@emotion/styled";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuCheckbox from "../../components/MenuCheckbox";
import DateSelector from '../../components/DateSelector';
import { useACPDataContextV2 } from "../../context/ACPDataStoreV2";

export default function LeftPane({ handleClearAll, setUncuratedFilter, curatedFilter, setCuratedFilter, curatedCount, uncuratedCount, uncuratedFilter, setArchivedFilter, archivedFilter, archivedCount, startDate, endDate, setStartDate, setEndDate, searchTerm, setSearchTerm, sortType, setSortType }) {
    const { videos } = useACPDataContextV2()


    const sortTypeTexts = {
        'newest': <>Newest<ArrowDropDownIcon /> </>,
        'oldest': <>Oldest <ArrowDropDownIcon /> </>,
        'mostViews': <>Most Views<ArrowDropDownIcon /> </>,
        'leastViews': <>Least Views <ArrowDropDownIcon /> </>
    }
    
    const handleSwitchSortType = (sortType) => {
        const types = Object.keys(sortTypeTexts)
        const indx = types.indexOf(sortType)
        setSortType(types[indx + 1] || 'newest')
    }

    return (
        <Container >
            <SortContainer>

                <HeadingText>Sort</HeadingText>
                <SortText onClick={() => handleSwitchSortType(sortType)}>{sortTypeTexts[sortType]}</SortText>
            </SortContainer>


            <FilterContainer>
                <HeadingText>Filter</HeadingText>
                <FilterItem>
                    <MenuCheckbox additionalSx={additionalCheckboxStyles} isChecked={uncuratedFilter} value={uncuratedFilter} handleOnChange={() => { setUncuratedFilter(!uncuratedFilter); setCuratedFilter(false) }} />
                    <FilterItemText>Uncurated ({videos?.uncurated?.length || 0})</FilterItemText>
                </FilterItem>
                <FilterItem>
                    <MenuCheckbox additionalSx={additionalCheckboxStyles} isChecked={curatedFilter} value={curatedFilter} handleOnChange={() => { setCuratedFilter(!curatedFilter); setUncuratedFilter(false) }} />
                    <FilterItemText>Curated ({videos?.curated?.length || 0})</FilterItemText>
                </FilterItem>
                <FilterItem>
                    <MenuCheckbox additionalSx={additionalCheckboxStyles} isChecked={archivedFilter} value={archivedFilter} handleOnChange={() => setArchivedFilter(!archivedFilter)} />
                    <FilterItemText>Archived  ({videos?.archived?.length || 0})</FilterItemText>
                </FilterItem>
            </FilterContainer>

            <DateRangeContainer>
                <SubheadingText>Date Range</SubheadingText>

                <DateSelector value={startDate} handleChange={date => setStartDate(date)} label="Start Date" />

                <DateSelector value={endDate} handleChange={date => setEndDate(date)} label="End Date" />


                {/* {showDateSelector && <DateSelector />} */}
            </DateRangeContainer>

            <SearchContainer>
                <SubheadingText>Search</SubheadingText>
                <SearchInput placeholder='Start Typing to Search...' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
            </SearchContainer>

            <ClearText onClick={handleClearAll}>Clear All</ClearText>
        </Container>
    )
}

const Container = styled.aside`
    box-sizing: border-box;
    background-color: #445879;
    width: 100%;
    height: 100%;
    padding:20px  25px;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 245px ;
    margin-top: 74px;
`
const SortContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    `
const FilterContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`
const FilterItem = styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
`
const FilterItemText = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
`

const HeadingText = styled.p`
    margin: 0;
    font-family: 'Nunito';
    color: #ACFFE1;
    font-size: 16px;
    margin-top: 0px;
    font-weight: 500;

`

const DateRangeContainer = styled.div`


`
const SearchContainer = styled.div`


`

const StyledInput = styled.input`
box-sizing: border-box;
font-family: 'Raleway';
font-style: italic;
font-weight: 500;
font-size: 12px;
padding: 8px 16px 7px 10px;
background: #FFFFFF;
border: 1px solid #818181;
border-radius: 10px;`


const SearchInput = styled(StyledInput)`

width: 196px;
height: 35px;
`
const SubheadingText = styled.p`
font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
margin-bottom: 6px;
`
const SortText = styled(SubheadingText)`
margin-left: 6px;
cursor: pointer;
display: flex;
align-items: center;
margin-bottom: 0;
margin-top: 2px;
`
const ClearText = styled.p`
margin-top: 20px;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
/* text-decoration-line: underline; */
color: #ACFFE1;
text-align: center;
cursor: pointer;
/* margin-top: 21px */
`


const additionalCheckboxStyles = {
    '& .MuiFormControlLabel-root': { marginRight: '-2px' }
}

