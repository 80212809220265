import React, { useCallback, useRef } from "react";

import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";

const ForwardBack10 = ({
    handleOnSingleClick,
    videoRef
}) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const clickCounts = useRef(0);

    const handleOnForwardRewindClick = (isForward = true) => {
        if (isForward) {
            videoRef.current.currentTime += 10;
        } else {
            videoRef.current.currentTime -= 10;
        }
    }

    //** This is for mobile browsers only. check if double tap happened */
    const handleDoubleTap = useCallback((event, isForward) => {
        clickCounts.current += 1;

        //Single Click
        //When user taps and does not tap again within 600 ms its consider single click
        //Single click is triggered after 550ms
        setTimeout(() => {
            if (clickCounts.current % 2 !== 0) {
                handleOnSingleClick()
            }
            clickCounts.current = 0;
        }, 550);

        let node = event.target.closest('.mobile-forward-back-container');
        node.addEventListener('animationend', function () {
            node.classList.remove('fade-07-start');
        });

        //When user taps and taps again within 600 ms its consider double click
        setTimeout(() => {
            if (clickCounts.current !== 0 && clickCounts.current % 2 === 0) {
                clickCounts.current = 0;
                handleOnForwardRewindClick(isForward);
                node.classList.add('fade-07-start');
            }
        }, 500)
    }, [])

    return (
        <>
            {
                !isSmallScreen ?
                    <>
                        <Tooltip title='Skip Back' onClick={() => handleOnForwardRewindClick(false)} style={{ display: 'flex', alignItems: 'center' }}>
                            <Replay10Icon fontSize="medium" />
                        </Tooltip>
                        <Tooltip title='Skip Forward' onClick={() => handleOnForwardRewindClick(true)} style={{ display: 'flex', alignItems: 'center' }}>
                            <Forward10Icon fontSize="medium" />
                        </Tooltip>
                    </> :
                    <div id="mobile-screen-forward-back-10-container">
                        <div id="mobile-screen-back-10-container"
                            className="mobile-forward-back-container"
                            onTouchStart={(event) => { handleDoubleTap(event, false) }}>
                            <div className="forward-back-icon">
                                <Replay10Icon sx={{
                                    fontSize: "5em"
                                }} />
                            </div>
                        </div>
                        <div id="mobile-screen-forward-10-container"
                            className="mobile-forward-back-container"
                            onTouchStart={(event) => { handleDoubleTap(event, true) }}>
                            <div className="forward-back-icon">
                                <Forward10Icon sx={{
                                    fontSize: "5em"
                                }} />
                            </div>
                        </div>
                    </div>
            }

        </>
    );
};

export default ForwardBack10;
