import React from 'react';
import { useStyles } from './styles'
import DoAssignmentIcon from '../../../../icons/doAssignment.png';
import { Button } from "@mui/material";
 
const DoAssignmentButton = ({assignment}) => {

    const classes = useStyles();

    return (
        <a href={assignment.link} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
            <Button className={classes.doAssignmentBtn} variant="contained" sx={{display: "flex"}}>
                <div className={classes.doAssignmentBtnContent}>
                    <img className={classes.btnIcon} src={DoAssignmentIcon} alt="do assignment icon" />
                    <p>Do Assignment</p>
                </div>
            </Button>
        </a>
    )
}
export default DoAssignmentButton;