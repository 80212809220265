import { useContext, useEffect, useState } from "react";
import { v4 as uuid4 } from 'uuid';

import { MediaContext } from "../index";
import useVideoMetaStyles from "./videoMetaStyles";

const ChatPanel = ({
    showTab,

}) => {

    const { videoInfo, setHasChatHistory, hasChatHistory, transcriptAvailable } = useContext(MediaContext);
    const [chats, setChats] = useState([]);
    const classes = useVideoMetaStyles();


    function formatChatObjectFile(data) {
        let result = []
        const split = data.split('\r\n')
        for (let i = 1; i < split.length; i++) {
            const row = split[i]
            if (row) {
                const parsedRow = JSON.parse(row)
                result.push(
                    {
                        time: parsedRow.time_from_recording_start,
                        name: Buffer.from(parsedRow.s_name, 'base64').toString(),
                        text: Buffer.from(parsedRow.content, 'base64').toString(),

                    }
                )

            }
        }
        return result
    }
    useEffect(() => {
        //grab chats content 
        //since 90% files are in kbs its ok to fetch via https from cdn
        const abortController = new AbortController();

        async function fetchData() {
            try {

                if (videoInfo?.chat_cdn_url !== null && videoInfo?.chat_cdn_url !== 'null') {
                    let response = await fetch(videoInfo.chat_cdn_url, { signal: abortController.signal })
                    const data = await response.text()


                    let chatObject = data.split('\r\n').reduce((acc, ci) => {
                        let [time, name, text] = ci.split('\t');
                        acc.push({ time, name, text });
                        return acc;

                    }, []);
                    if (chatObject[0] && (!chatObject[0].name || !chatObject[0].text)) {

                        const newChatObj = formatChatObjectFile(data)
                        setChats(newChatObj)
                        setHasChatHistory(true)

                    }
                    else {

                        setChats(chatObject);
                        setHasChatHistory(true)
                    }

                }
                else setHasChatHistory(false)
            }
            catch (e) {
                if (!e.name.startsWith('AbortError')) {
                    throw new Error(e)
                }
            }

        }
        fetchData()
        return () => abortController.abort()
    }, []);


    if (!transcriptAvailable && !hasChatHistory) return null
    return (
        <div className={`${classes.tabContainer}`} style={{ display: showTab ? "block" : "none" }}>
            <div className={`${classes.chatContainer}`}>
                {chats.length ?
                    chats.map(chat => {
                        return <div key={uuid4()} className='each-chat-row'>
                            <div className="user-name-and-time">
                                <strong className='chat-name'>{chat.name}</strong>
                                <span className='chat-time'>{chat.time}</span>

                            </div>
                            <div className="chat-text">{chat.text}</div>

                        </div>
                    })

                    :
                    <div style={{ padding: "15px 10px 10px 10px", textAlign: "center", fontStyle: "italic" }}>Chat History for this video is unavailable</div>
                }

            </div>
        </div>

    );
};

export default ChatPanel;
