import React from 'react';
import { dateTimeFormatterUtc } from '../../../helpers/utils';
import { ReactComponent as OnlineStatus } from '../../../images/online_indicator_green.svg';
import useStyles from './styles';

const IsOnline = ({
    isOnline=false,
    lastSeen=null
}) => {

    const classes = useStyles();

    return (<div className={classes.cardOnlineStatus}>
        {
            isOnline ?
                <>
                    <OnlineStatus className={classes.onlineStatusIcon} />
                    <p>Online</p>
                </>
                :
                lastSeen !== null ?
                    <>
                        <p>{dateTimeFormatterUtc(lastSeen)}</p>
                    </>
                :
                    null
        }
    </div>)
}

export default IsOnline;