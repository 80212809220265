import React from 'react';
import { useStyles } from './styles';

import {convertUTCDateTimeToUsersTimeZone} from '../../../helpers/utils'

export default function EachRequestDates({
    request
}){

    const classes=useStyles();

    const getUserTimeInZone=(value)=>{
        return convertUTCDateTimeToUsersTimeZone(value)
    }

    return(
        <div className={`text-normal ${classes.greyColor}`}>
            {
                request.updated_at?
                <span className='italics each-request-last-modified'>
                    Last Modified: {getUserTimeInZone(request.updated_at).timeInZone.format(`MMM DD [|] hh:mma`)} {getUserTimeInZone(request.updated_at).timeZoneAbbr}
                </span>:
                null
            }
            
            {
                request.created_at?
                <span className={`italics each-request-last-modified ${classes.addMdMarginLeft}`}>
                    Created: {getUserTimeInZone(request.created_at).timeInZone.format(`MMM DD [|] hh:mma`)} {getUserTimeInZone(request.created_at).timeZoneAbbr}
                </span>:
                null
            }
                        
            
        </div>
    )
}