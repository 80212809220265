import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const skeletonStyles = makeStyles((theme) => ({
    connectPanelSkeleton: {
        height: "285px",
        marginBottom: "23px",
        width: "100%"
    }
}))

const ConnectPanelSkeleton = () => {
    
    const skeletonClass = skeletonStyles();

    return (
        <div className={skeletonClass.connectPanelSkeleton}>
            <Skeleton variant='rectangular' width="100%" height="100%" sx={{borderRadius: "10px"}}/> 
        </div>
    )
}

export default ConnectPanelSkeleton;