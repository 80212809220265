import React, {useState, useRef, useEffect} from 'react';
import styled from "@emotion/styled";
import {Select, MenuItem} from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import { uploadFile, syncCanvasCourses } from '../../../../api';
import { useAlert } from 'react-alert';
import CircularLoader from '../../../../sharedComponents/CircularLoader';
import {ReactComponent as SettingsCog} from "../../../../icons/settings-cog.svg"
import {ReactComponent as CircularX} from "../../../../icons/circular-x.svg"

const Automations = () => {
    const [automation, setAutomation] = useState("Additional Tools")
    const [handshakeFile, setHandshakeFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const hiddenFileInput = useRef(null);
    const {user} = useAuth0()
    const alert = useAlert()

    const handleDropdown = (e) => {
        setAutomation(e.target.value)
    }
    const selectFile = event => {
        hiddenFileInput.current.click();
    };
    const handleFile = event => {
        setHandshakeFile(event.target.files[0])
        console.log(event.target.files)
    }
    const handleUploadFile = async (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append('file', handshakeFile);
        formData.append('destination', "s3")
        formData.append('srcType', "csv")
        formData.append('fileName', handshakeFile.name)
        formData.append('email', user?.email)
        setIsLoading(true)
        try {
            await uploadFile(formData)
            setHandshakeFile(null)
            setIsLoading(false)
            alert.success("Handshake Automation is now running")
        } catch (error) {
            alert.error("Something went wrong while uploading")
        }
    }
    const handleSyncCanvasCourses = async () => {
        try {
            setIsLoading(true)
            let result = await syncCanvasCourses()
            if(result) {
                setIsLoading(false)
                alert.success("Canvas Sync Done")
                return
            }
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
        alert.error("Something went wrong with Canvas Course Sync")
    }

    useEffect(() => {
        console.log(handshakeFile)
    }, [handshakeFile])
    return (
        <AutomationsPanel>
            <PanelBody>
                <TitleText><SettingsCog/><p>Additional Tools</p></TitleText>
                <InputWrapper>
                    <Select
                        id="automation-selection-dropdown"
                        name="automation"
                        size="small"
                        defaultValue={"Additional Tools"}
                        onChange={handleDropdown}
                        sx={{width: "350px" }}
                    >
                        <MenuItem id="default-automations-item" value="Additional Tools" disabled>Additional Tools</MenuItem>
                        <MenuItem id="handshake-item" value="handshake">Upload Handshake .csv to HubSpot</MenuItem>
                        <MenuItem id="sync-canvas-item" value="sync-canvas">Sync Canvas courses to MyKenzie database</MenuItem>
                    </Select>
                    { automation === "handshake" &&
                        (!handshakeFile ?
                            <Button onClick={selectFile}>Select File</Button> :
                            isLoading ?
                            <CircularLoader/> :
                            <Button onClick={handleUploadFile} disabled={isLoading}>Upload File</Button>
                            )
                    }
                    { automation === "sync-canvas" &&
                        (isLoading ?
                        <CircularLoader /> :
                        <Button onClick={handleSyncCanvasCourses}>Sync Courses</Button>)
                    }
                </InputWrapper>
                {handshakeFile?.name &&
                    <FileItem>
                        <p>{handshakeFile.name}</p>
                        <CircularX style={{cursor: "pointer"}} onClick={() => setHandshakeFile(null)}/>
                    </FileItem>                
                }
            </PanelBody>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFile}
                style={{display: 'none'}}
                accept={".csv"}
            />
        </AutomationsPanel>
    )
}
export default Automations;

const AutomationsPanel = styled.div`
width: 100%;
height: 141px;
border: 2px solid #C7C7C7;
border-radius: 10px;
box-sizing: border-box;
overflow: hidden;
background-color: #F3F3F3;
margin-top: 20px;
`

const TitleText = styled.div`
position: absolute;
top: -2px;
left: 21px;
display: flex;
align-items: center;
& p {
    margin-left: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #404040;
}
`

const InputWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
position: absolute;
top: 47px;
padding: 0px 21px;
box-sizing: border-box;
`

const Button = styled.div`
width: 130px;
height: 32px;
background: #FFFFFF;
box-sizing: border-box;
border: 2px solid #404040;
border-radius: 50px;
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
justify-content: center;
color: #404040;
margin-left: auto;
margin-right: 0;
&:hover {
    cursor: pointer;
    box-shadow: 2px 3px 6px #606060;
  }
}
`

const FileItem = styled.div`
display: flex;
position: absolute;
align-items: center;
top: 90px;
left: 21px;
& p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #445879;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}
`

const PanelBody = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
box-sizing: border-box;
height: 100%;
width: 100%;
position: relative;
`
