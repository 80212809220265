import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CardStar } from '../../../images/cardStar.svg';
import Image from '../../../images/kenzieAvatar.png';
import { checkProfileUpdateIndication } from './helpers';
import IsOnline from './IsOnline';
import useStyles from './styles';

const EachLearnerCard = (props) => {

    const classes = useStyles();

    return (
        <div className={`eachCardContainer ${checkProfileUpdateIndication(props.cardData.user_email_mk_user.user_profile_updated_date)?`${classes.cardContentUpdateIndicator}`:""}`}
            data-usertype="learner"
            data-isonlinescreen={props.onlineFilterScreen} 
            data-isonline={props.cardData.user_email_mk_user.is_online}
            data-isfavoritescreen={props.favoritesFilterScreen}
            data-isfavorite={props.isFavorite}
            >
                
            <div className={`${classes.cardStar} ${props.isFavorite?`${classes.cardStarAnimationOn}`:`${classes.cardStarAnimationOff}`}`} 
                data-isfavorite={props.isFavorite}
                onClick={()=>{
                    props.handleUserFavorites(props.cardData.user_email)
                }}>
                <CardStar />
            </div>

            <Link to={`/profile/${props.cardData.user_email}`} className='eachCard' >
                <div>
                    <div className={classes.cardContent}>
                        {
                            checkProfileUpdateIndication(props.cardData.user_email_mk_user.user_profile_updated_date) ?
                                <div className={classes.updateIndicator}>Updated</div> :
                                null
                        }
                        <div className={classes.learnerCardImageBorder}>
                            <div className={classes.cardImage}>
                                <img className={classes.image}
                                    src={props.cardData.user_email_mk_user.user_image_url ? props.cardData.user_email_mk_user.user_image_url : Image} alt={`${props.cardData.user_email_mk_user.first_name} ${props.cardData.user_email_mk_user.last_name}'s profile card`} />
                            </div>
                        </div>

                        <div className={classes.learnerCardName}>
                            <p>{props?.cardData?.user_email_mk_user?.preferred_name?
                                props.cardData.user_email_mk_user.preferred_name:
                                props.cardData.user_email_mk_user.first_name} {props.cardData.user_email_mk_user.last_name}</p>
                        </div>
                        <div className={classes.localeTimezone}>
                            {
                                props.cardData.user_email_mk_user.user_location
                                && props.cardData.user_email_mk_user.user_location.description ?
                                <div className={classes.cardLocation}>
                                    <p>{props.cardData.user_email_mk_user.user_location.description}</p>
                                </div>
                                :
                                <div className={classes.noCardLocation}>
                                    <p>unseen text</p>
                                </div>
                            }
                            <div className={classes.cardTimezone}>
                                <p>{props.cardData.user_email_mk_user.user_timezone
                                    && props.cardData.user_email_mk_user.user_timezone.time_zone_name ?
                                    `${props.cardData.user_email_mk_user.user_timezone.time_zone_name}` : null}</p>
                            </div>
                        </div>
                        {
                            props.cardData?.user_email_mk_user?.email ?
                                <IsOnline
                                    isOnline={props.cardData.user_email_mk_user.is_online}
                                    lastSeen={props.cardData.user_email_mk_user.user_last_seen} /> :
                                null
                        }

                    </div>
                </div>
            </Link>
        </div>
    )
}
export default EachLearnerCard;