import useStyles from "./styles";
import GroupedAvatars from "../../../learner/pages/dashboard/connectPanel/GroupedAvatars";
import JoinNowButton from "./JoinNowButton";
import { HeadingText } from "../v2/Dashboard";
const SessionParticipants = ({
    learnerParticipants = [],
    staffParticipants = [],
    guestParticipants = [],
    cohortInfo,
    setShowParticipantModal
}) => {

    const classes = useStyles();
    return (
        <div className={classes.sessionParticipantsContainer}>

            <div className="participants-inner-container "

            >
                <div style={{ gridArea: 'staff' }}>
                    {
                        staffParticipants.length > 0 &&
                        <>
                            <HeadingText>Staff in Session:</HeadingText>
                            <div className={classes.studySession} >
                                <GroupedAvatars
                                    handleClick={() => setShowParticipantModal(true)}

                                    maxAvatars={3}
                                    sessionParticipants={staffParticipants} />
                            </div>
                        </>
                    }
                </div>

                <div style={{ gridArea: 'guest' }}>
                    {
                        (learnerParticipants.length > 0 || guestParticipants.length > 0) &&
                        <>
                            <HeadingText>Learners in Session:</HeadingText>
                            <div className={classes.studySession} >

                                <GroupedAvatars
                                    handleClick={() => setShowParticipantModal(true)}
                                    maxAvatars={5}
                                    sessionParticipants={learnerParticipants.concat(guestParticipants)} />
                            </div>
                        </>
                    }
                </div>

                <JoinNowButton cohortInfo={cohortInfo} setShowParticipantModal={setShowParticipantModal}/>
            </div>
        </div>
    )
}

export default SessionParticipants;