import { FormControlLabel, Checkbox } from "@mui/material"
import { useTheme } from "@mui/material"
export default function EditProfileExperienceCheckbox({ values, handleSetFieldValue, prevValue }) {
    const theme = useTheme()
    return (

        <FormControlLabel
            sx={{
                marginTop: "12px",
                '& 	.MuiFormControlLabel-label': {
                    fontFamily: 'Nunito !important', fontWeight: '400 !important', fontSize: '16px !important'
                }
            }}
            label={<span style={{ fontFamily: 'Nunito !important', fontWeight: '400 !important', fontSize: '16px !important' }}>Don't show my experience level</span>}
            control={<Checkbox
                sx={{
                    '&.Mui-checked': {
                        color: theme.colors.deepAqua
                    },
                }}
                name="user_programming_experience_level"
                value="Do not prefer"
                checked={values.user_programming_experience_level ?
                    values.user_programming_experience_level === "Do not prefer" :
                    false}
                onChange={(event) => {
                    if (event.target.checked)
                        handleSetFieldValue("user_programming_experience_level", "Do not prefer")
                    else
                        handleSetFieldValue("user_programming_experience_level", 'Beginner')
                }}
            />}
        />

    )
}