import React from 'react';

import { useStyles } from './styles';

export default function NoEventScheduled() {

    const classes = useStyles();

    return (
        <div className={classes.noEventsSchedules}>
            No sessions or events scheduled for this day
        </div>
    )
}