import React, { useContext } from 'react';
import useStyles from './styles';
import FunFactIcon from '../../icons/profile-fun-fact.svg'
import { UserProfileContext } from './UserProfileContext';

const UserFunFact = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    return (
        <>
            {
                profileData?.user_fun_fact &&
                <div className={classes.topItems}>
                    <img id="user-profile-free-time-icon-image" src={FunFactIcon} alt="Free Time Icon" />
                    <p>A fun fact about me is <span className={classes.subHeader}>{profileData.user_fun_fact}</span>
                    </p>
                </div>
            }
        </>

    )
}

export default UserFunFact;