import React from 'react';
import { Skeleton } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';
import { useStyles } from './styles';


export const LoadingEvents = () => {

    const classes = useStyles();

    return (
        <div>
            {
                [3].map((length) => Array.from(new Array(length)).map(() => {
                    return <div className={classes.skeletonLoaderContainer} key={uuidv4()}>
                        <div>
                            <Skeleton varient="rectangular" width={200} height={21} />
                            <Skeleton varient="rectangular" width={300} height={21} />
                            <Skeleton varient="rectangular" width={300} height={21} />
                        </div>
                        <div>
                            <Skeleton classes={{ root: classes.skeletonButton }} variant="circular" />
                        </div>

                    </div>
                })
                )
            }
        </div>
    )
}