import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    mediaRoot: {

        position: "relative",
        background: "linear-gradient(#161A1A, #14606B)",
        width: "100%",
        minHeight: "100vh",
        paddingBottom: "20px",

        "& #media-page-inner-root": {
            margin: "auto",
            [theme.breakpoints.up('md')]: {
                width: "95%",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%"
            },

            '& #media-page-infobar-container': {

                color: theme.colors.white,

                [theme.breakpoints.up('md')]: {
                    display: "grid",
                    gridColumnGap: "1em",
                    gridTemplateColumns: "3fr 1fr",
                    padding: "10px",
                    alignItems: "end"
                },
                [theme.breakpoints.down('md')]: {
                    display: "block",
                    padding: "10px",
                },

                "& a": {
                    textDecoration: "none"
                },

                '# video-search-container': {
                    position: "relative"
                }

            },

            '& #media-page-video-container': {

                padding: "5px",
                backgroundColor: "#161A1B",
                borderRadius: "10px",

                [theme.breakpoints.up('md')]: {
                    display: "grid",
                    gridColumnGap: "1em",
                    gridTemplateColumns: "3fr 1fr",
                    padding: "10px"
                },
                [theme.breakpoints.down('md')]: {
                    display: "block",
                },

                '& #media-description-container': {
                    margin: "10px 0px",
                    fontSize: "14px",

                    [theme.breakpoints.down('md')]: {
                        padding: "0px 10px",
                        margin: "10px 0px 20px 0px",
                    },
                    '& .top-row-container': {
                        display: 'flex'
                    },

                    '& .media-tags': {
                        display: "flex",
                        alignItems: 'center',

                        '& .each-tag': {
                            backgroundColor: 'transparent',
                            border: `1.5px solid ${theme.colors.white}`,
                            textTransform: "capitalize",
                            color: theme.colors.white,
                            borderRadius: "30px",
                            padding: "6px 12px",
                            marginRight: "0.6em",
                            fontSize: "14px",
                            fontWeight: 400,
                            whiteSpace: "no-wrap"
                        }
                    },

                    '& #media-description-tag': {
                        margin: "20px 0px 10px 0px",
                        color: '#FFFFFF',
                        lineHeight: "20px"
                    },

                    '& #media-showmore-button': {
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: '#FFFFFF'
                    }
                },

                '& .share-button-container': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginLeft: 'auto',
                    marginRight: '20px',
                    '& .share-text': {
                        color: '#ffffff',
                        marginLeft: '5px',
                        fontFamily: 'Nunito',
                        fontWeight: 400,
                        fontSize: '16px',
                        fontStyle: 'normal'
                    }
                },

                '& #video-meta-panel-container': {
                    [theme.breakpoints.down('md')]: {
                        padding: "0 10px"
                    }
                }
            },

        },

        '& .highlight-search-txt': {
            backgroundColor: "#FFB800",
            padding: "2px 4px",
            borderRadius: "4px"
        }

    },

    searchVideoInputField: {

        position: "relative",
        borderRadius: "25px",
        border: "1px solid grey",
        padding: "8px 8px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "start",
        flexDirection: "row",
        alignItems: "center",
        height: '25px',
        '&:disabled': {
            opacity: 0.6
        },

        '& .search-icon': {
            color: "#000000"
        },

        '& .clear-text-icon': {
            color: "#000000",
            cursor: "pointer",
            padding: "2px"
        },
        '& input': {
            width: "100%",
            border: "0px",
            fontSize: "14px",

            '&:focus': {
                outlineColor: "transparent"
            },

            '&:focus-visible': {
                outlineColor: "transparent"
            }

        }
    },

    meetingTopic: {
        ...theme.elements.title,
    },
    meetingDateTime: {
        ...theme.elements.subtitle,
    },

    resourceBar: {
        marginTop: "18px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        '& #share-video-button': {
            color: "white",
            padding: "8px",
            cursor: "pointer",
            [theme.breakpoints.up('md')]: {
                marginRight: "1em",
            },
            [theme.breakpoints.down('md')]: {
                marginRight: "5px",

            },
        },
    },
    linkCopied: {
        boxSizing: 'border-box',
        marginLeft: 'auto',
        width: "130px",
        cursor: "pointer",
        padding: "10px",
        borderRadius: "20px",
        border: "2px solid #14606B",
        fontSize: "14px",
        textAlign: "center",
        fontWeight: 600,
        color: "#FFF",
        background: 'transparent',
        flex: 1,
        height: '45px'
    },
    copyLinkButton: {
        boxSizing: 'border-box',
        marginLeft: 'auto',
        width: "130px",
        padding: "10px",
        background: "#14606B",
        borderRadius: "20px",
        border: "0px",
        color: "white",
        cursor: "pointer",
        transition: "box-shadow 0.5s",
        textAlign: "center",
        fontWeight: 600,
        fontSize: "14px",
        height: '45px',

        flex: 1,
        "&:hover": {
            boxShadow: "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;"
        }
    },

    externalZoomLink: {
        [theme.breakpoints.up('md')]: {
            width: "255px",
            height: "22px",
            borderRadius: "15px",
        },
        [theme.breakpoints.down('md')]: {
            marginTop: "10px",
            padding: "8px",
            borderRadius: "5px"
        },
        ...theme.elements.bodyText,
        color: theme.colors.white,
        border: `1px solid ${theme.colors.white}`,
        textAlign: "center",
        cursor: "pointer",
        position: "relative",
        backgroundColor: "transparent",
    },

}));

export default useStyles;
