import { memo } from "react";


import ActiveCohortPanel from "./cohortEntryForm/ActiveCohortPanel";
import InactiveCohortPanel from "./cohortEntryForm/InactiveCohortPanel";
import CreateCohortPanel from "./cohortEntryForm/CohortCreationPanel";
import Automations from "./AutomationsPanel";

import './styles.css';
import { STAFF_FACING_LIVE_PROGRAMS as programs } from '../../../constants'
export default memo(function ManageCohortPage() {
    return (
        <div className="container">
            <div className="manage-cohort-header">
                <h2 className="manage-cohort-title">Manage MyKenzie Cohorts</h2>
                <p className="manage-cohort-subtitle">Adding a new cohort will generate a cohort-specific version of the MyKenzie dashboard.</p>
            </div>
            <div className="cohort-panel-group">
                <ActiveCohortPanel />
                <div className="cohort-creation-section">
                    <InactiveCohortPanel />
                </div>
                <div className="cohort-creation-section">
                    <CreateCohortPanel programs={programs} />
                    <Automations />
                </div>
            </div>
        </div>
    )
})