import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from "react-hook-form";
import CircularLoader from "../../../../../sharedComponents/CircularLoader";

import { addAllNewMembersToCohort } from "../api";
import { trimAddMembersFormData } from "../helpers";
import AddMemberFormRow from "./AddMemberFormRow";
import { useACPDataContextV2 } from "../../../../context/ACPDataStoreV2";
export default function AddMemberTable({ handleClose, setSuccessAlert, setErrorAlert, handleUpdate, handleUpdateMemberCount }) {
  const initialNewMembersState = {
    first_name: null,
    last_name: null,
    email: null,
    active: 'true',
    user_role_id: 1,
    user_lms_id: null,
    is_visible: true
  }

  const { register, control, handleSubmit, watch, unregister, setValue, formState: { errors, } } = useForm();

  const [isLoading, setIsLoading] = useState(false)
  const { userData, selectedCohortId } = useACPDataContextV2()

  useEffect(() => {
    if (fields.length === 0) {

      append({ ...initialNewMembersState })
    }
  }, [])


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addMembers',
    defaultValues: {
      addMembers: []
    }
  });


  if (isLoading) return <CircularLoader />

  return (
    <div>

      <div className="manage-cohort-table-container--modal">
        <div
          className="add-member-table-row"
          style={{
            backgroundColor: "#E4EEFF",
            borderRadius: "10px 10px 0 0",
            borderBottom: "1px solid #000000"
          }}
        >
          <span className="cell">Kenzie Email Address</span>
          <span className="cell">Name</span>

          <span className="cell">Status</span>
          <span className="cell">Role</span>
          <span className="cell">LMS ID*</span>
          <span className="cell">Connect Visibility</span>
        </div>
        {fields.map((field, i) => (
          <AddMemberFormRow
            setValue={setValue}
            register={register}
            unregister={unregister}
            errors={errors?.addMembers ? errors.addMembers[i] : {}}
            value={{ ...field, index: i }}
            handleRemoveRow={() => remove(i)}
            index={i}
            key={field.id}
            watch={watch}
            control={control}
            showBorder={fields.length !== 1 && i !== fields.length - 1}
          />
        ))}
      </div>
      <div
        className="pointer"
        onClick={() => {
          append({ ...initialNewMembersState })

        }
        }
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginTop: "16px",
          width: "fit-content"
        }}
      >
        <PersonAddIcon sx={{ width: '14px', color: '#404040', marginRight: '4px' }} />
        <span style={{ fontSize: "14px" }}>Add Another</span>{" "}
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: "auto" }}>
        <button
          style={{
            outline: "none",
            border: 0,
            width: "108px",
            color: "#1D3969",
            backgroundColor: "#FFF",
            marginLeft: "auto",
            fontSize: "14px",
            fontWeight: 600,
            cursor: "pointer"
          }}
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit(async (data) => {
            data = trimAddMembersFormData(data)

            setIsLoading(true)
            const res = await addAllNewMembersToCohort({ newMembers: Object.values(data.addMembers), cohortId: selectedCohortId, username: userData.username })
            setIsLoading(false)

            const didAdd = res.didAdd.length
            const didNotAdd = res.didNotAdd.length
            if (didNotAdd) {
              setErrorAlert(<><p>There was an error adding Member(s):</p>{res.didNotAdd.map(m => <p key={m.message}>{m.message}</p>)}</>, 'error')

            }
            if (didAdd) {
              setSuccessAlert(<>{res.didAdd.map(m => <p key={m.message}>{m.message}</p>)}</>, 'success')
              handleUpdateMemberCount(res.didAdd.map(entry => entry.member))


            }
            let tempMembers = [...data.addMembers]
            if (didAdd && didNotAdd) {
              for (let entry of res.didAdd) {
                const foundMember = tempMembers.find(member => member.email === entry.member.email)

                const foundIndex = tempMembers.indexOf(foundMember)

                tempMembers = tempMembers.filter(member => member.email !== foundMember.email)
                remove(foundIndex)
              }
            }
            if (didAdd && !didNotAdd) {
              handleClose()
              handleUpdate()
            }


          })}
          style={{
            width: "108px",
            outline: "none",
            border: 0,
            backgroundColor: "#1D3969",
            color: "#FFF",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer"
          }}
        >
          Add Members
        </button>
      </div>
    </div>
  );
}
