import React from 'react';
import { useStyles } from './styles'
import ViewSubmissionIcon from '../../../../icons/viewSubmissionBtn.png';
import { Button } from "@mui/material";
 
const ViewSubmissionButton = ({assignment}) => {

    const classes = useStyles();

    return (
        <a href={assignment.link} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
            <Button className={classes.viewSubmissionBtn} variant="contained" sx={{display: "flex"}}>
                <div className={classes.viewSubmissionBtnContent}>
                    <img className={classes.btnIcon} src={ViewSubmissionIcon} alt="do assignment icon" />
                    <p>View Submission</p>
                </div>
            </Button>
        </a>
    )
}
export default ViewSubmissionButton;