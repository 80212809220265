import React from 'react';
import { useStyles } from './styles';

import { getRequestTypeMapping, parseRequestBody } from './helpers';

import HtmlParser from "../../../sharedComponents/announcement/HtmlParser.js";
import { requestTypeBgColorMapping } from './styles';

const getQnA = require('./requestQuestionsPerType.json');
export default function EachRequestPreviewQuestion({
    request
}) {

    const classes = useStyles();

    const requestType = getRequestTypeMapping(request);

    const body = parseRequestBody(request);

    const previewQuestion = getQnA.find(i => i.type === requestType).previewQuestion;

    //;

    return (
        <div className={`fwt-500 text-normal ${classes.questionContainer}`}>
            <div className={`${classes[requestType + "Color"]}  lineHt-md request-preview-question`}>
                <div>{previewQuestion.question}</div>
            </div>
            <div className='request-preview-answer  lineHt-sm'>
                <div>
                    <HtmlParser isPreview={true} bgColor={requestTypeBgColorMapping[requestType]}>{body[previewQuestion.answerKey]}</HtmlParser>
                </div>
            </div>
        </div>
    )
}