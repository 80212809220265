import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import useStyles from './styles';

import { getFocusTimeImage } from '../../sharedComponents/editProfileFields/focusTime/getImage';
import { UserProfileContext } from './UserProfileContext';

import { v4 as uuid4 } from 'uuid';
import SlideLabel from '../../images/slide-label.svg';
const DAYS = require('../../sharedComponents/editProfileFields/focusTime/days.json');
const TIME_SLOTS = require('../../sharedComponents/editProfileFields/focusTime/timeSlots.json');


/**
 * @description The Object for the focus time is stored in key = day.id and values is an array objects
 * E.g., {sun:[{"id":"morning_lark",
    "header":"Morning Lark",
    "timeSlot":"6am - Noon"},
    {<Another Slot if exists>}]}
 * @returns 
 */
const UserFocusTime = () => {
    const classes = useStyles();
    
    const { profileData } = useContext(UserProfileContext);
    const timeSlotRef = useRef(null);
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        const handleResize = () => {
            if (timeSlotRef?.current?.offsetWidth > 650)
                setWidth(timeSlotRef?.current?.offsetWidth)
            else setWidth(timeSlotRef?.current?.offsetWidth)
        }


        setWidth(timeSlotRef?.current?.offsetWidth)

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [timeSlotRef.current])
    //Width of timeSlot container = 949.3px
    //Width of each timeSlot = 950/4=237.5 => 2.5px for margin
    //ZIndex for all day is set to 0 and everything else is 1. all day must be in the back.
    const getSlotCoordinates = (slotInfo) => {

        let cords = {};

        const WD = width / 4 - 2.5;

        switch (slotInfo.id) {
            case 'night_owl':
                cords.left = 0;
                cords.width = WD + "px";
                cords.zIndex = 1;
                break;

            case 'morning_lark':
                cords.left = 1 * WD + 5 + "px";
                cords.width = WD + "px"
                cords.zIndex = 1;
                break;

            case 'afternoon_loon':
                cords.left = 2 * WD + 8 + "px";
                cords.width = WD + "px";
                cords.zIndex = 1;
                break;

            case 'evening_eagle':
                cords.left = 3 * WD + 10 + "px";
                cords.width = WD + "px";
                cords.zIndex = 1;
                break;

            case 'all_day_albatross':
                cords.left = "0px";
                cords.right = "0px";
                cords.zIndex = 0;
                break;

            default:
                break;
        }

        return cords;
    }

    return (
        <>
            {
                profileData && profileData.user_focus_time &&
                Object.keys(profileData.user_focus_time).length > 0 &&

                // Main Container for the Focus time on Profile Page. Displays only if user_focus_time exists
                <div id="user-profile-kenzie-focus-time-container"
                    className={`${classes.kenzieFocusTimeContainer} `}>

                    <p style={{ marginTop: '10px' }} className={`${classes.subHeader} ${classes.kenzieFocusTimeDesktopLabel}`}>Kenzie Academy Focus Time</p>
                    <img className={classes.kenzieFocusTimeMobileSlideLabel} src={SlideLabel} alt="slide to move horizontally"></img>
                    <div className={`open-sans ${classes.kenzieFocusTimeInnerContainer}`}>
                        {/* For Each Day - Sun, mon .... */}
                        {
                            DAYS.map((day,i) => {

                                return <div className='each-focus-time-day' data-day={day.id} key={i+day.id}>
                                    <div className='day-name'>{day.abbr}</div>
                                    <div className='time-slots' ref={timeSlotRef} >
                                        {
                                            //For Each Forcus time for Each Day 
                                            //A Day can have multiple focus times
                                            profileData?.user_focus_time
                                            && profileData.user_focus_time[day.id]
                                            && profileData.user_focus_time[day.id].length > 0
                                            && profileData.user_focus_time[day.id].filter(slot => Boolean(TIME_SLOTS.find(i => i.id === slot.id)) ).map((slot, slotIndx) => {

                                                let slotInfo = TIME_SLOTS.find(i => i.id === slot.id);
                                                let allDayExists = profileData.user_focus_time[day.id].find(i => i.id === "all_day_albatross");

                                               return (
                                                      <div className='each-time-slot v-align text-sm'
                                                        key={slotIndx}
                                                            style={getSlotCoordinates(slotInfo)}
                                                            data-alldayexists={allDayExists ? true : false}>

                                                            <div className='each-time-slot-icon'>
                                                                <img alt="Focus time" src={getFocusTimeImage(slotInfo)} />
                                                            </div>

                                                            <div className='each-time-slot-name' data-slotid={slotInfo.id}>
                                                                <div>{slotInfo.timeSlot}</div>

                                                                {/* If the Slot Id is all_day_albatross, following logic creates the slant lines design using css */}
                                                                {
                                                                    slotInfo.id === "all_day_albatross"
                                                                    && <div className='time-slot-slant-lines'>
                                                                        {
                                                                            Array.from(Array(90).keys()).map((line, indx) => {
                                                                                return <div key={uuid4()} className='each-time-slot-slant-line' style={{ left: 10 * indx + "px" }}></div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                               
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>

                </div>
            }
        </>

    )
}

export default UserFocusTime;