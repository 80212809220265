import { Avatar } from "@mui/material";
import React from 'react';

import Image from '../../../../images/kenzieAvatar.png';
import useModalStyles from '../../dashboard/connectPanel/modalStyles';

const ParticipantAvatar = ({
    participant,
    inModalView
}) => {

    const classes = useModalStyles();
    return (
        <Avatar
            className={`${inModalView ? classes.staffAvatar : ""}`}
            sx={{
                width: 32, height: 32,
                '& .MuiAvatar-img': {
                    borderRadius: '100vh'
                }
            }}
            alt={participant.name}
            src={participant?.mykenzie_user_info?.user_image_url || Image}
        />
    )
};

export default ParticipantAvatar;