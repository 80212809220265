import useStyles from "./styles";

const StartSession = ({ setShowParticipantModal, onlineNow }) => {
    const classes = useStyles();
    return (
        <div className={classes.sessionContainer}>
            <div className={classes.subtitle}>Kick off a cohort study session?</div>
            <div className={classes.sessionText}>You have {onlineNow} classmates online right now -- start a session and collaborate!</div>
            <button className={`${classes.sessionButton} ${classes.eventButton}`} variant="outlined" onClick={() => setShowParticipantModal(true)}>Start Session</button>
        </div>
    )
}

export default StartSession;