import {makeStyles} from "@mui/styles"

const useProfilePopupStyles = makeStyles((theme) => ({
    centerModal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    wrapper: {
        width: "358px",
        height: "503px",
        border: "2.5px solid #C2573F",
        borderRadius: "10px",
        background: "#FFFFFF",
        padding: "25px",
        outline: "none",
        "& p": {
            margin: 0
        }
    },
    title: {
        fontFamily: 'Raleway',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#14606B"
    },
    image: {
        width: "358px",
        marginTop: "30px",
        marginBottom: "28px",
    },
    subtitle: {
        fontFamily: 'Open Sans',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '33px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#14606B",
        marginBottom: "15px !important"
    },
    action: {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '27px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginBottom: "30px !important",
        "& strong": {
            fontFamily: 'Open Sans',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '27px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: "#14606B"
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    createProfile: {
        ...theme.elements.darkBlueButton,
        fontSize: "14px !important",
        fontWeight: "600 !important",
        width: "100%",
            [theme.breakpoints.down("md")]: {
                width: "100%",
                marginBottom: "42px !important"
            }
    }
})) 

export default useProfilePopupStyles