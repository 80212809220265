import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AppDataContext } from '../../../context';

import UserProfileImage from '../../../sharedComponents/editProfileFields/profileImage/UserProfileImage';
import { LearnersDataContext } from '../../context';

import useProfileCommonStyles from '../../../pages/profilePage/commonProfileSyles';

const UserAvatar = ({
    showEditButton = false, isEditProfilePage
}) => {

    const theme = useTheme();
    const commonProfileStyles = useProfileCommonStyles();

    const { userData } = useContext(AppDataContext);
    const { userCourse } = useContext(LearnersDataContext);
    const AvatarComponent = () => (
        <Grid item xs={12} md={4} lg={4}
            className={commonProfileStyles.alignGridCenterNoGutter} style={isEditProfilePage && {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
            <UserProfileImage showEditButton={showEditButton} isEditProfilePage />
        </Grid>
    )

    if (isEditProfilePage) return <AvatarComponent />
    return (<>
        <Grid container alignItems="center" spacing={4} sx={{
            [theme.breakpoints.down('lg')]: {
                width: "100%",
                marginLeft: "0px"
            }
        }}>


            <AvatarComponent />
            {/* User Information Grid */}
            <Grid item xs={12} md={8} lg={8}
                className={commonProfileStyles.alignGridCenterNoGutter}>
                <div className={commonProfileStyles.profileName}>
                    {/* If preferred_name exists the first_name is replaced by preferred_name, if not display first_name */}
                    <span>{userData?.preferred_name ? userData.preferred_name : userData.first_name}</span>
                    {
                        userData?.user_name_pronunciation ?
                            <span> ({userData.user_name_pronunciation})</span> :
                            null
                    }
                    <span> {userData?.last_name}</span>
                </div>
                {
                    userData?.user_preferred_pronouns ?
                        <div className={commonProfileStyles.profilePronouns}>({userData?.user_preferred_pronouns})</div> :
                        null
                }
                {
                    userCourse ?
                        <div className={commonProfileStyles.profileTitle} style={{ marginTop: "10px", lineHeight: 1.5 }}>
                            <div>{userCourse.course.coursename}</div>
                        </div> :
                        null
                }
                <div className={`lineHt-md ${commonProfileStyles.profileTitle}`}>{userData.email}</div>
            </Grid>
        </Grid>

    </>)
}

export default UserAvatar;