import { createTheme } from "@mui/material";

let theme = createTheme()
theme = createTheme(theme, {
    paragraphFont: {
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '42px',
        [theme.breakpoints.down("800")]: {
            fontSize: '25px',
            lineHeight: '37px',
        },
        letterSpacing: '0em',
    },
    selectFont: {
        fontFamily: 'Nunito !important',
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "22px !important",
    },
    selectTextFont: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: "500",
        lineHeight: '21px',
    }
})

export default theme