import React, { useContext, useState, useEffect } from 'react';
import {
    Route,
    Redirect,
    useLocation,
    Switch
} from "react-router-dom";

import Header from '../pages/baseLayout/Header'
import Home from '../pages/dashboard';
import LearnerEditProfilePage from '../pages/learnerEditProfile/LearnerEditProfilePage';

//Profile Page 
import ProfilePage from '../../pages/profilePage/ProfilePage';
import LearnerStaffDirectoryPage from '../pages/learnerStaffDirectory/LearnerStaffDirectoryPage'

import { AppDataContext } from '../../context';

import WatchPage from '../pages/watch';
import MediaPage from '../pages/media';
import { postUserVideoView } from '../../api';

import Onboarding from "../pages/onboarding";

const LearnersRouter = (props) => {
    const { userData, setIsAllComponentsLoaded, gtmUserId } = useContext(AppDataContext)
    const [isOnboarding, setIsOnboarding] = useState(true)
    const location = useLocation();

    window.dataLayer = window.dataLayer || [];

    useEffect(async() => {
        await window.dataLayer.push({
            "event": "login",
            "method": "Google",
            "user_id": gtmUserId,
        })

    }, [])

    useEffect(async () => {
        
        await window.dataLayer.push({
            "user_id": gtmUserId
        })

        const gtmTag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PSCDHPW');`

        const gtmScript = document.createElement("script");
        gtmScript.innerHTML = gtmTag;
        gtmScript.async = true;
        document.head.append(gtmScript)
        
        if (!location.pathname.includes("/media/")) {
            let userVideoWatchData = JSON.parse(localStorage.getItem('user_video_watch_data'));

            if (userVideoWatchData) {
                await postUserVideoView(userVideoWatchData);
                localStorage.clear();
            }
        }
      
    }, [location.pathname])

    useEffect(() => {
        if (userData["first_login"] && isOnboarding) {
            setIsAllComponentsLoaded(true)
        }
    }, [userData, setIsAllComponentsLoaded, isOnboarding])

    return userData["first_login"] && isOnboarding ?
        (
            <>
                <Route path="/">
                    <Onboarding setIsOnboarding={setIsOnboarding} />
                </Route>
            </>
        )
        :
        (
            <>

                <Route path="/acp">
                    <Redirect render to="/" component={Home} />
                </Route>


                <Header isAllComponentsLoaded={props.isAllComponentsLoaded}>
                    <Switch>

                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/help">
                            <Home help />
                        </Route>
                        {/* Profile page is added to both learner and acp*/}
                        <Route exact path="/profile/:useremail">
                            <ProfilePage />
                        </Route>
                        <Route exact path="/profile/learner/editprofile">
                            <LearnerEditProfilePage />
                        </Route>
                        <Route exact path="/connect">
                            <LearnerStaffDirectoryPage />
                        </Route>
                        <Route exact path="/watch">
                            <WatchPage />
                        </Route>
                        <Route exact path="/media/:videoUUId">
                            <MediaPage />
                        </Route>
                        <Route path='*' render={() => <Redirect to={{ pathname: "/" }} />} />

                    </Switch>
                </Header>
            </>

        )
}

export default LearnersRouter;