import React from "react"
import {LearnerKenzieFocusTime} from "./components"

const Focus = () => {
    return (
        <>
            <p>This is when I plan to focus on my course work:</p>
            <div style={{maxWidth: "750px"}}>
                <LearnerKenzieFocusTime />
            </div>
            <span>*Select all that apply for each applicable day of the week.</span>
        </>
    )
}

export default Focus