import React from 'react';
import VideocamIcon from '@mui/icons-material/Videocam';

import { useStyles } from './styles';

export default function JoinEarlyButton({
    eventState = null,
    noLink=false
}) {

    const classes = useStyles();

    return (
        <>
            {
                !noLink ?
                    <button 
                        data-eventstate={eventState}
                        className={`${classes.joinEarlyButton} ${classes.eventButton}`}
                        title={"Click to join zoom"}
                    >
                        <VideocamIcon fontSize='22' />
                        <div style={{ marginLeft: "5px" }}>Join Early</div>
                    </button> :
                 null
            }
        </>
        
    )
}