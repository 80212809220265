import React, { useEffect, useState } from 'react';

import Image from '../../../../images/kenzieAvatar.png';
import { Card } from "../../../components";

import useStyles from './styles';

import { LearnersDataContext } from '../../../context';
import { useWebtoolsDataHook } from '../../../context/useWebToolsDataHook';
import WebToolsSkeleton from './WebToolPanelSkeleton';

const openWebTool = (url) => {
    window.open(url, '_blank')
}

const WebTools = React.memo(() => {

    const classes = useStyles();

    // const [webTools, setWebTools] = useState(null);
    const [loading, setLoading] = useState(true);

    const { userCourse, checkDashboardDataState, updateLearnerDashboardRefVariable } = React.useContext(LearnersDataContext);

    /**
     * Gathering information from the webToolds data hook
     */
    const webTools = useWebtoolsDataHook(userCourse?.cohort_id);

    //Set the loader true when components mount
    useEffect(() => {
        setLoading(true);
    }, []);

    //set the loader to false when webTools information is fetched
    useEffect(() => {
        if (webTools !== null) {
            setLoading(false);
            updateLearnerDashboardRefVariable("webTools", webTools);
            checkDashboardDataState();
        }
    }, [webTools]);

    return (
        <Card title="Online Tools"
            classes={{ content: classes.contentWrapper }}
            theme="primary">
            {
                !loading && webTools !== null && webTools.length === 0 ?
                    <div>There are no webtools assigned for this course</div> :
                !loading && webTools !== null && webTools.length > 0 ?
                    webTools.map(tool => {
                        let url = tool.url ? tool.url : tool.webtool.url
                        return (
                            <div id="webtool" className={classes.webtool} key={tool.id} onClick={() => openWebTool(url)}>
                                <img className={classes.Image} src={tool.webtool.logo_url === null ? Image : tool.webtool.logo_url} alt={tool.webtool.name ? tool.webtool.name : "Kenzie Icon Replacement"} onError={(e) => e.target.src = Image} />
                            </div>
                        )
                    }) :
                <WebToolsSkeleton />
            }
        </Card>
    )
})
export default WebTools;