import styled from "@emotion/styled";
import { useState, useEffect } from "react";
import { getUsers } from "../../../../api/userManagement";
import { MenuItem, Skeleton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function HostSearch({ currentInput, selectedHost, inputProps, handleSelect, searchFn = null }) {
    const [isSearching, setIsSearching] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [hideSearchResultsBox, setHideSearchResultsBox] = useState(true);

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    };
    useEffect(() => {
        if (!currentInput || currentInput === selectedHost) {
            setHideSearchResultsBox(true)
        }
    }, [currentInput, selectedHost,])


    const handleSearchInput = async (event) => {


        if (event.target.value.length === 0) {
            setSearchResults([]);
            setHideSearchResultsBox(true)
        } else {
            setIsSearching(true);
            setHideSearchResultsBox(false);
            let response;
            const searchVal = event.target.value.split(' ')
            if (searchVal.length > 1) {

                response = await getUsers({
                    first_name: `*${searchVal[0]}*`,
                    last_name: `*${searchVal[1]}*`,

                    attributes: "username,first_name,last_name",
                    limit: 20
                })
            } else {
                response = await getUsers({
                    first_name: `*${event.target.value}*`,

                    attributes: "username,first_name,last_name",
                    limit: 20
                })
            }


            setSearchResults(response);
            setIsSearching(false);


        }
    };

    const onClickHandler = async (item) => {


        setSearchResults([]);//empty search results 
        setHideSearchResultsBox(true);//Hide the searhc results box 
        setIsSearching(false)
        handleSelect(item)

    }
    return (
        <Container>
            <StyledInput onInput={debounce(handleSearchInput, 300)} {...inputProps} />
            <>
                {!hideSearchResultsBox &&
                    <ClickAwayListener onClickAway={() => setHideSearchResultsBox(true)}>
                        <div>

                            {isSearching && <div >
                                <SearchResultBox>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />

                                </SearchResultBox>
                            </div>}
                            {!isSearching && searchResults.length > 0 ?
                                <SearchResultBox>
                                    {
                                        searchResults.map(item => {
                                            return <div key={item.username}
                                                style={{
                                                    marginBottom: "10px"
                                                }}>
                                                <MenuItem
                                                    sx={{
                                                        display: "block !important",
                                                        width: "100%",
                                                        padding: "10px",
                                                        borderRadius: "4px",
                                                    }}
                                                    onClick={() => onClickHandler(item)}>
                                                    <div style={{
                                                        fontWeight: "bold"
                                                    }}>{item.first_name} {item.last_name} <p style={{ marginBottom: 0 }}>{item.username}</p></div>

                                                </MenuItem>
                                            </div>
                                        })
                                    }

                                </SearchResultBox>
                                : null}


                        </div>
                    </ClickAwayListener>
                }

            </>
        </Container>
    )

}
const Container = styled.div`
position: relative;
height: 35px;
width: 225px;

`
const StyledInput = styled.input`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
width: 100%;
height: 100%;
border: 1px solid #767676;
border-radius: 5px;
padding: 5px 8px;

`
const SearchResultBox = styled.div`
width: 100%;

height: 200px;
padding: 8px;
z-index: 99;
background-color: white;
overflow-y: auto;
border: 1px solid #767676;
border-radius: 5px;
`
