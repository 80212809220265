import React from 'react';

import JoinEarlyButton from './JoinEarlyButton';
import JoinNowButton from './JoinNowButton';
import RecordingButton from './RecordingButton';

export default function EachEventButton({
    eventState=null,
    eventItem=null
}) {

    return (
        <div>
            {
                eventState && eventState==="is_live"?
                <>
                    {
                        eventItem?.meeting_url?
                        <a href={eventItem.meeting_url} target="_blank" rel="noreferrer">
                            <JoinNowButton 
                                eventState={eventState} />
                        </a>:
                         <JoinNowButton 
                            eventState={eventState} 
                            noLink={true} />
                    }
                </>
                    :
                eventState && eventState==="is_passed"?
                   <RecordingButton 
                        eventState={eventState}
                        eventItem={eventItem}
                   />:
                eventState && eventState==="is_join_early"?
                <>
                    {
                        eventItem?.meeting_url?
                        <a href={eventItem.meeting_url} target="_blank" rel="noreferrer">
                            <JoinEarlyButton 
                                eventState={eventState}
                            />
                        </a>:
                        <JoinEarlyButton 
                            eventState={eventState} 
                            noLink={true} />
                    }
                </>:
                null
            }
        </div>
    )
}