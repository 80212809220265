import moment from "moment";
import { getUserDisplayName } from "../../../helpers/utils";

const getDateValue=(value,format)=>{
    if(value){
        return moment(value).format(format);
    }
    return "<Unknown Date Value>";
}

const getPostedBy=(announcement)=>{
    if(announcement.post_as==="myself"){
        return getUserFullName(announcement);
    
    } else if(announcement.post_as==="kenzie"){
        return 'Kenzie Academy'
    }
}

const getAbbreviatedPostedBy=(announcement)=>{
    if(announcement.post_as==="myself"){
        return getUserAbbreviatedName(announcement);
    
    } else if(announcement.post_as==="kenzie"){
        return 'Kenzie Academy'
    }
}

const getUserFullName=(announcement)=>{
    return getUserDisplayName(announcement.author_mk_user) + " " + announcement.author_mk_user?.last_name;
}

const getUserAbbreviatedName=(announcement)=>{
    return getUserDisplayName(announcement.author_mk_user) + " " + announcement.author_mk_user?.last_name?.[0] + ".";
}

//Get the cohort information 
const getAudience=(announcement)=>{
    if(announcement.audience===1){
        return "everyone";

    }else if(announcement.audience!==1 && announcement.cohort_id!==null){
        return announcement.cohort.cohort_name;

    }else{
        return "<Unknown>"
    }
}

export {
    getDateValue,
    getPostedBy,
    getAbbreviatedPostedBy,
    getUserFullName,
    getUserAbbreviatedName,
    getAudience
}