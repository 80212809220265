import Carousel from "react-multi-carousel";
import { useMediaQuery, useTheme } from "@mui/material";
import EachVideoCard from "./EachVideoCard";
import useStyles from "./styles";

import "react-multi-carousel/lib/styles.css";

export default function VideoCarousel({ videos }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles();

    return (
        <Carousel
            showDots={isSmallScreen}
            dotListClass='dots'
            responsive={carouselBreakpoints}
            containerClass={classes.carouselContainer}
            itemClass="carousel-item">
            {videos.map((video, i) => (
                <EachVideoCard key={i} video={video} />
            )
            )}

        </Carousel>
    )

}


const carouselBreakpoints = { //breakpoints for carousel
    four: {
        breakpoint: { max: 5000, min: 1301 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    threeHalf: {
        breakpoint: { max: 1300, min: 1166 },
        items: 3.5,
        slidesToSlide: 3 // optional, default to 1.
    },
    three: {
        breakpoint: { max: 1165, min: 1066 },
        items: 3,
        slidesToSlide: 3// optional, default to 1.
    },
    twoAndHalf: {
        breakpoint: { max: 1065, min: 901 },
        items: 2.7,
        slidesToSlide: 2 // optional, default to 1.
    },
    two: {
        breakpoint: { max: 900, min: 801 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    oneAndHalf: {
        breakpoint: { max: 800, min: 660 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    one: {
        breakpoint: { max: 659, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};