import { Container, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { ReactComponent as MyKenzieLogo } from '../../images/my-kenzie-logo-2.svg';

import useProfileCommonStyles from './commonProfileSyles';
import useStyles from './styles';

import { AppDataContext } from '../../context';
import { UserProfileContext } from './UserProfileContext';

import ProfilePgSkeleton from './ProfilePgSkeleton';

import { getUserCourseDetails } from '../../api';

import UserDemographics from './UserDemographics';
import UserImage from './UserImage';

import { useLocation } from 'react-router-dom';
import { getCoursesPerRole } from '../../helpers/userRolesHelper';
import AboutMeIcon from '../../icons/profile-about-me.svg';
import ConnectIcon from '../../icons/profile-connect.svg';
import ExperienceIcon from '../../icons/profile-experience.svg';
import FocusTimeIcon from '../../icons/profile-focus-time.svg';
import LocationIcon from '../../icons/profile-location.svg';
import UserAdditionalDemographics from './UserAdditionalDemographics';
import UserFocusTime from './UserFocusTime';
import UserFreeTime from './UserFreeTime';
import UserFunFact from './UserFunFact';
import UserLanguages from './UserLanguages';
import UserLearnerAdditionalHeaders from './UserLearnerAdditionalHeaders';
import UserLocation from './UserLocation';
import UserProgrammingLanguages from './UserProgrammingLanguages';
import UserSocialMediaLinks from './UserSocialMediaLinks';
import UserWorkSchedule from './UserWorkSchedule';

import ProfileSection from './SectionHeader';
const ProfilePage = () => {

    const classes = useStyles();
    const commonProfileStyles = useProfileCommonStyles();
    const theme = useTheme();
    const location = useLocation();

    let { useremail } = useParams();
    const { setIsAllComponentsLoaded, userData } = useContext(AppDataContext);

    const [profileData, setProfileData] = useState(null);
    const [errorWhileFetchingUserInfo, setErrorWhileFetchingUserInfo] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    //This state is used to determine the background of the profile header
    //if student change the background to cyan
    const [isStudent, setIsStudent] = useState(false);
    const acp_access = userData.mk_user_courses.find(i => i.user_role_id !== 1)

    const profileSections = [
        "About Me",
        "Location",
        "Experience",
        "Connect",
        "Focus Time & Work Schedule",
    ]
    
    useEffect(() => {
        //Clean up
        return (() => {
            setProfileData(null);
            setIsLoading(true);
            setIsStudent(false);
        });
    }, []);

    /**
     * Fetch and set profileData to initial user data -- Will switch to api function "getUsers" in the future
     */
    useEffect(()=>{

        async function fetchProfile() {
            try {
                await loadProfileData();
                setIsAllComponentsLoaded(true);//initial load completed. Sedn feedback to App.js
                
            } catch (error) {
                setIsAllComponentsLoaded(true);
            }
        }
        
       fetchProfile()
    }, []);

    /**
     * Fetch the profile data everytime location is changed
     */
    useEffect(() => {
      async function fetchProfile(){
          if (location.pathname && profileData && profileData.username !== useremail) {
              await loadProfileData();
          }
      }
      fetchProfile()
    }, [location, profileData]);

    useEffect(() => {
        if(!isLoading) {
            profileSections.forEach((section) => {
                let sectionContent = document.getElementById(`${section} Content`);
                if(sectionContent && sectionContent.children.length === 0) {
                    let sectionParent = document.getElementById(section);
                    sectionParent.style.display = "none"
                }
            })
        }
    }, [isLoading])

    /**
     * Loading profile data and setting state variables
     */
    const loadProfileData = async () => {

        try {

            setIsLoading(true);

            //check if the useremail is same as current logged in user
            //;

            if (useremail === userData.username) {
                setStateVariables({ ...userData });

            } else {
                let data = await getUserCourseDetails(useremail);

                //
                if (data?.message && data.message?.users && data.message.users.length > 0) {//if there is no user data throw an error

                    let d = data.message.users[0];

                    setStateVariables(d);

                } else {
                    throw new Error("profile cannot be found");
                }
            }

        } catch (error) {
            console.error(error);
            setErrorWhileFetchingUserInfo(true);
        }
    }

    const setStateVariables = (dataset) => {
        try {
            setProfileData(dataset);

            let getCourses = getCoursesPerRole(dataset, 1);

            setIsStudent(getCourses.length > 0);

            setIsLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {
                !isLoading ?
                    <Container sx={{
                        marginBottom: "100px",
                        [theme.breakpoints.down('md')]: {
                            padding: "0px !important"
                        }
                    }}>
                        <UserProfileContext.Provider value={{
                            "profileData": profileData
                        }}>
                            <div className={commonProfileStyles.userAvatarContainer} style={{
                                backgroundColor: isStudent && 'transparent'
                            }}>
                                <div className={commonProfileStyles.container} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >


                                    {/* User Profile Image Grid */}
                                    <UserImage />

                                    {/* User Information Grid */}
                                    <UserDemographics />

                                </div>
                            </div>

                            <div className={classes.profileGrid}>
                                <div className={classes.profileGridTop}>
                                    <div className={classes.profileGridTopLeft}>
                                        <ProfileSection icon={AboutMeIcon} title='About Me' titleContainerStyle={{ marginTop: 0}}>
                                            <UserLanguages />
                                            <UserFreeTime />
                                            <UserFunFact />
                                            {/* Additional Information Includes. user_intro_interest, user_goals */}
                                            <UserAdditionalDemographics />
                                        </ProfileSection>
                                    </div>

                                    <div className={classes.profileGridTopRight}>
                                        <div>
                                            <ProfileSection icon={LocationIcon} title="Location" >
                                                <UserLocation />
                                            </ProfileSection>
                                        </div>
                                        <div>
                                            <ProfileSection icon={ExperienceIcon} title='Experience' >
                                                {/* Includes Dream job and Programming Experience Level */}

                                                <UserLearnerAdditionalHeaders />
                                                <UserProgrammingLanguages />
                                            </ProfileSection>
                                        </div>
                                        <div>
                                            <ProfileSection icon={ConnectIcon} title='Connect' >
                                                <UserSocialMediaLinks />
                                            </ProfileSection>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.profileGridBottom}>
                                    {
                                        (isStudent && !acp_access) &&
                                        <ProfileSection icon={FocusTimeIcon} title='Focus Time & Work Schedule'  containerClassName={classes.focusTime} >

                                            <UserFocusTime />

                                            <UserWorkSchedule />
                                        </ProfileSection>
                                    }
                                </div>
                            </div>

                        </UserProfileContext.Provider>

                    </Container> :
                    isLoading && !errorWhileFetchingUserInfo ?
                        <ProfilePgSkeleton /> :
                        isLoading && errorWhileFetchingUserInfo ?
                            <div style={{ display: 'flex', justifyContent: 'center', margin: "30px 0px" }}>
                                <div>
                                    <div className={`${classes.mkLogo}`}>
                                        <MyKenzieLogo />
                                    </div>
                                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                                        <div>Error while fetching user (<b>{useremail}</b>) information. </div>
                                        <div style={{ fontSize: "12px", marginTop: "10px" }}>Please check the email or contact MyKenzie R&D </div>
                                    </div>
                                </div>
                            </div> :
                            null
            }
        </>

    )
}

export default ProfilePage;