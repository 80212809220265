import React, { useEffect, useRef, useState } from "react";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import { hideOffFocusEventHandler } from "../../../../helpers/utils";
import useVideoMetaStyles from "./videoMetaStyles";
const TabDropDown = (({
    options = [],
    setCurrentTab,
    currentTab
}) => {

    const classes = useVideoMetaStyles();
    const [showDropDownSelection, setDropDownSelection] = useState(false);

    let dropDownContainerRef = useRef(null)



    const handleOnSelection = (selection) => {
        setDropDownSelection(false);
        setCurrentTab(selection);
    }

    useEffect(() => {
        if (dropDownContainerRef !== null) {
            hideOffFocusEventHandler(dropDownContainerRef.current, () => {
                setDropDownSelection(false);
            })

        }

    }, [dropDownContainerRef])


    return (
        <div className={classes.tabDropDownContainer}>
            <div className="tab-dd-selected-container"
                onClick={() => { setDropDownSelection(true) }}>
                <div>{currentTab.label}</div>
                <div className="tab-dd-down-icon"><ArrowDropDownIcon fontSize="medium" /></div>
            </div>
            <div className="tab-dd-selection-container"
                ref={dropDownContainerRef}
                style={{ display: showDropDownSelection ? 'block' : 'none' }}>
                {
                    options.map(tab => {
                        return <div
                            className="each-tab-selection"
                            key={tab.id}
                            onClick={() => {
                                handleOnSelection(tab)
                            }}
                        >
                            <div className="each-tab-selection-text">{tab.label}</div>
                            {
                                currentTab.id === tab.id
                                && <CheckIcon className="each-tab-selection-checkicon" fontSize="small" />
                            }

                        </div>
                    })
                }
                <div className="tab-dd-up-icon"><ArrowDropUpIcon fontSize="medium" /></div>
            </div>
        </div>
    );
});

export default TabDropDown;
