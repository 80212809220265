import { makeStyles } from "@mui/styles";

// media query to remove the logo from the topnav and hardcoded margin values from bottom nav
export const useStyles = makeStyles((theme) => ({

    /** ACP Default Font is open sans */
    /** Only top level navigation headers (MyKenzie, ACP), and the user's name next to the avatar must be Raleway, otherwise open sans  */
    acpFontFamily: {
        fontFamily: theme.ACP.fontFamily,
        "& .MuiTypography-root": {
            fontFamily: theme.ACP.fontFamily,
        }
    },

    /** LEFT PANE STYLE */
    lBarLeftPane: {
        position: "fixed",
        left: "0px",
        top: "0px",
        bottom: "0px",
        width: "164px",
        backgroundColor: theme.colors.deepBlue,
        zIndex: 97,
        paddingBottom: "20px",
        overflowY: "auto"
    },

    acpTopLogoContainer: {
        padding: "14px",
        textAlign: "center"
    },
    acpLogo: {
        width: "46px",
        height: "46px",
        // border:'2px solid '+theme.colors.turquoise,
        textAlign: "center",
        lineHeight: "46px",
        // borderRadius: "50%",
        color: "white",
        fontWeight: "bold",
        margin: "0px auto 10px auto"
    },

    lBarLeftPaneEachItemContainer: {
        padding: "5px 0px"
    },

    lBarLeftPaneEachItemInnerContainer: {
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: "5px",
        "&:hover": {
            backgroundColor: "#445879",
            borderLeft: '2px solid ' + theme.colors.turquoise
        },
        "& a": {
            color: "#FFFFFF",
            textDecoration: "none"
        }
    },

    lBarLeftPaneEachItemSelectedState: {
        backgroundColor: "#445879",
        borderLeft: '2px solid ' + theme.colors.turquoise
    },

    lBarLeftPaneEachItem: {
        paddingLeft: "25px"
    },

    lBarLeftPaneHeaderMenuItem: {
        fontSize: "16px",
        color: theme.colors.turquoise,
        lineHeight: "19px",
        fontWeight: 700,
        marginBottom: "8px",
        marginTop: "25px"
    },

    lBarLeftPaneMenuItem: {
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "25px",
        fontWeight: 500,
        height: "25px"
    }, /** LEFT PANE STYLE: END*/

    /** TOP PANE STYLE: END*/
    lBarTopPane: {
        position: "fixed",
        left: "0",
        top: "0px",
        right: "0px",
        height: "74px",
        backgroundColor: theme.colors.deepBlue,
        zIndex: 97
    },

    lBarTopContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },

    lBarTopLeftInnerContainer: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },

    cohortDropDownProgramName: {
        padding: '20px 10px 5px 20px',
        fontWeight: 550,
        fontSize: "14px",
        color: theme.colors.deepBlue
    },

    cohortDropDownCohortName: {
        fontFamily: "open sans",
        paddingBottom: "5px",
        paddingTop: "5px",
        fontSize: "14px",
        fontWeight: 525,
        color: "#444444"
    },

    /** RIGHT PANE STYLE: END*/
    lBarRightPane: {
        position: "fixed",
        left: "0px",
        top: "74px",
        right: "0px",
        bottom: "0px",
        padding: '0 51px',
        paddingBottom: '5vh',
        boxSizing: 'border-box',
        backgroundColor: "#F3F3F3",
        zIndex: 888,
        overflowY: "auto"
    },

    lBarRightPaneLevel2Menu: {
        position: "fixed",
        left: "0px",
        top: "74px",
        right: "0px",
        bottom: "0px",
        width: "245px",
        backgroundColor: theme.colors.lighterDeepBlue,
        zIndex: 888,
        overflowY: "auto",
        paddingBottom: "20px",
        color: "white",
        "& .menuContainer": {
            marginTop: "20px"
        },
        "& .header": {
            color: theme.colors.turquoise,
            fontSize: "16px",
            fontWeight: 500,
            marginTop: "20px"
        },
        '& .subheader': {
            fontSize: "14px",
            fontWeight: 500,
            marginTop: "20px",
            marginBottom: "8px",
            color: "#FFFFFF"
        },
        '& .clearAllText': {
            marginTop: '45px',
            fontFamily: 'Nunito',

            fontWeight: 400,
            fontSize: '14px',
            textDecoration: 'underline',
            cursor: 'pointer'

        },
        "& .subItemsContainer": {
            marginLeft: "8px",

            '& .subItems': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#E9E9E9",
                textTransform: "capitalize"
            },

            "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                fontWeight: 400,
                color: "#E9E9E9",
                textTransform: "capitalize"
            },

            '& .MuiCheckbox-root': {
                '& svg': {
                    fontSize: "18px"
                }
            },

            '& .Mui-disabled': {
                opacity: '0.7 !important',
                color: 'white !important'
            }
        }
    },

    lBarRightPaneLevel2MenuPad: {
        paddingLeft: "25px"
    },

    lBarRightPaneLevel2SubMenuOuter: {
        marginBottom: "8px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#ffff",
            color: theme.colors.deepBlue,
            borderLeft: '3px solid ' + theme.colors.turquoise,
            "& a": {
                color: theme.colors.deepBlue,
            }
        },
        "& a": {
            color: "#FFFFFF",
            textDecoration: "none"
        }
    },

    lBarRightPaneLevel2SubMenuSelectedState: {
        cursor: "pointer",
        backgroundColor: "#ffff",
        color: theme.colors.deepBlue,
        borderLeft: '3px solid ' + theme.colors.turquoise,
        "& a": {
            color: theme.colors.deepBlue,
            textDecoration: "none"
        }
    },

    lBarRightPaneLevel2SubMenuInner: {
        padding: "10px 0px 10px 20px",
        fontSize: "14px",
        fontWeight: 500
    },

    lBarRightPaneContentContainer: {
        position: "fixed",
        left: "245px",
        top: "74px",
        right: "0px",
        bottom: "0px",
        backgroundColor: "#FFFFF",
        zIndex: 888,
        overflowY: "auto",
        padding: "38px 10px",
        '& .acp-announcement-page': {
            padding: 0,
            maxWidth: 'initial'
        }
    },

    /**
     * CREATE BUTTON
     */
    createButton: {
        // padding: "0px 15px",
        // marginLeft: "15px",
        marginRight: '20px',
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 600,
        height: "45px",
        borderRadius: "25px",
        backgroundColor: theme.colors.turquoise,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        color: theme.colors.deepBlue,
        cursor: "pointer"
    },

    /**
     * AVATAR BUTTON
     */
    userAvatarButton: {
        display: "flex ",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "25px !important",
        marginRight: "25px !important",
        // border: `2px solid ${theme.colors.turquoise} !important`,
        padding: "3px 3px 3px 10px!important",
        backgroundColor: '#445879 !important',
        '& .username': {
            marginRight: "10px",
            marginLeft: '12px',
            color: '#ffff',
            textTransform: "capitalize",
            flex: 1,
            whiteSpace: 'nowrap',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 500
        },
        '& .MuiAvatar-circular': {
            border: "2px solid white"
        }
    },
    userAvatarMenuPaper: {
        marginTop: "5px",
        minWidth: "125px !important"
    },
    userAvatarMenuList: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    userAvatarMenuItem: {
        paddingLeft: "22px !important",
        paddingRight: "23px !important",
        paddingTop: "13px",
        paddingBottom: "13px",
        fontFamily: 'Raleway !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: "550 !important",
        textAlign: 'center !important'
    },

    pageHeader: {
        color: theme.colors.deepBlue,
        fontWeight: 600,
        fontSize: "18px"
    },

    pageSection: {
        "&:nth-child(n+2)": {
            marginTop: "50px"
        }
    }
}));