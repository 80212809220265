import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import DesktopMediaControls from "./controls/DesktopMediaControls";
import MobileMediaControls from "./controls/MobileMediaControls";

const MediaPlayerControls = ({
    videoRef
}) => {

    const theme=useTheme();
    const isSmallScreen=useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        
        let controller=new AbortController();

        if (videoRef?.current) {

            let videoContainer = document.getElementById('video-container-parent-tag');
            let parentNode = document.getElementById('video-media-controls-parent-container');

            videoContainer.addEventListener('mouseenter', function () {
                if(!isSmallScreen && !videoRef.current?.paused){
                    parentNode.style.display = "block";
                }
            }, { signal: controller.signal });

            videoContainer.addEventListener('mouseleave', function () {
                if (!videoRef.current?.paused) parentNode.style.display = "none";
            }, { signal: controller.signal });

        }

        return(()=>{
            controller.abort();
        })

    }, [videoRef, isSmallScreen])

    return (
        <div className="video-media-controls" id="video-media-controls-parent-container">

                {
                    !isSmallScreen?
                    <DesktopMediaControls videoRef={videoRef} />:
                    <MobileMediaControls videoRef={videoRef} />
                }
          
        </div>
    );
};

export default MediaPlayerControls;
