import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { createCohort } from '../../../../../api';
import { addNewMemberToCohort } from "../../learnersManagement/api";
import { MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from "@mui/system";
import moment from "moment";
import styled from "@emotion/styled";
import { useAlert } from "react-alert";
import CircularLoader from "../../../../../sharedComponents/CircularLoader";

export default memo(function CreateCohortPanel({ programs }) {
    const { register, handleSubmit, } = useForm();
    const [programSelection, setProgramSelection] = useState("Select Program")
    const [dateValue, setDateValue] = useState(moment().toDate().getTime())
    const [isLoading, setIsLoading] = useState(false)
    const alert = useAlert()

    // const registerOptions = {
    //     program_select: { required: "Please select a program for this cohort." },
    //     cohort_name: { required: "Please provide a name for this cohort." },
    //     program_manager: { required: "Please provide an email for the Program Manager." }
    // }

    const handleDropDown = (event) => {
        setProgramSelection(event.target.value)
    }

    const handleDateChange = (dateValue) => {
        setDateValue(dateValue)
    }

    const onFormSubmit = async (payload) => {
        payload.created_by = payload.created_by.toLowerCase();
        setIsLoading(true)
        let data = await createCohort(payload);

        if (data) {
            //Creators of a cohort will be assigned the role of admin
            const response = await addNewMemberToCohort({
                member: {
                    email: payload.created_by,
                    user_role_id: 9,
                    is_visible: true,
                    active: "true"
                },
                cohortId: data.message.cohort.cohort_id
            });
            if (response?.didAdd) {
                alert.success('Successfully Created Cohort')

            }
            else {
                alert.error('Error Unable to add admin to new cohort. Please contact R&D if the issue persists.')
            }

        }
        else {

            alert.error('Error Creating Cohort')
        }
        setIsLoading(false)
    }

    const onErrors = (errors) => console.log(errors)

    return (
        <CohortCreationPanel>
            <PanelTitleContainer>
                <PanelTitle>
                    Create New Cohort
                </PanelTitle>
            </PanelTitleContainer>
            <PanelBody>
                <Box id="acp-program-selection-dropdown-menu" sx={{ marginTop: "30px", height: "100%" }}>
                    <form id="cohort-creation-form" onSubmit={handleSubmit(onFormSubmit, onErrors)}>
                        <FormControl className="cohort-creation-form">
                            <InputLabel id="program-select-label" sx={{ top: "-7px" }}>Select Program*</InputLabel>
                            <Select
                                id="program-selection-dropdown"
                                name="program_id"
                                size="small"
                                label="Select Program"
                                defualtValue={programSelection}
                                onChange={handleDropDown}
                                type="number"
                                required
                                sx={{ marginBottom: "30px" }}
                                {...register("program_id", { required: true })}>
                                <MenuItem id="default-select-program" value="Select Program" disabled>Select Program<span>*</span></MenuItem>
                                {
                                    programs.map((program) => (
                                        <MenuItem key={program.id} id={program.id} value={program.id}>{program.title}</MenuItem>
                                    ))
                                }
                            </Select>
                            <div className={`cohort-name-section cohort-section`}>
                                <TextField
                                    id="cohort-name-field"
                                    className="cohort-name-field"
                                    size="small"
                                    name="cohort_name"
                                    label="Cohort Name*"
                                    type="text"
                                    {...register("cohort_name", { required: true })} />
                                <span>*use standardized cohort naming conventions (e.g SEBD September 2021)</span>
                            </div>
                            <div className={`program-manager-section cohort-section`}>
                                <TextField
                                    size="small"
                                    id="program-manager-field"
                                    name="created_by"
                                    label="Program Manager*"
                                    type="email"
                                    placeholder="someone@kenzie.academy'"
                                    {...register("created_by", { required: true })} />
                                <span>*will be assigned as an admin for this cohort</span>
                            </div>
                            <div className={`term-start-section cohort-section`}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        minDate={new Date()}
                                        label="Term 1 Start Date"
                                        value={dateValue}
                                        onChange={(e) => handleDateChange(e)}
                                        renderInput={(params) => <TextField size="small" {...params} name="startTermField" {...register("startTermField", { required: true })} />}
                                    />
                                </LocalizationProvider>
                                <span>Selections will reflect your current timezone</span>
                            </div>
                            <div className="create-cohort-button">
                                {isLoading ?
                                    <CircularLoader />
                                    :
                                    <button size="small">Create Cohort</button>
                                }
                            </div>
                        </FormControl>
                    </form>
                </Box>
            </PanelBody>
        </CohortCreationPanel>
    )
})

export const CohortCreationPanel = styled.div`
width: 100%;
height: 510px;
border: 2px solid #C7C7C7;
border-radius: 10px;
box-sizing: border-box;
overflow-y: hidden;
background-color: #FFFF;
`

export const PanelTitleContainer = styled.div`
display: flex;
align-items: center;
border-radius: 4px 4px 0 0 ;
background-color:  #445879;
height: 45px;
padding: 0 19px;
`

export const PanelTitle = styled.h2`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #ffffff;
margin: 0;
`

export const PanelBody = styled.div`
height: 100%;
padding:  0 19px 20px 19px;
flex-direction: column;
align-items: flex-start; */
box-sizing: border-box;
`

export const HeadingText = styled.h3`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #1D3969;

margin-bottom: 20px;
margin-top: 35px;
`