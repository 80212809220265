import React, { useContext, useEffect, useState } from "react";
import { MediaContext } from "../..";
import { AppDataContext } from "../../../../../context";

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useIdleTimer } from 'react-idle-timer';
import { getApiBase, HEADERS } from "../../../../../api/helpers";

const PlayPause = ({
    videoRef
}) => {
    const { userData } = useContext(AppDataContext);
    const { videoInfo } = useContext(MediaContext)
    const [initialPlay, setInitialPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(videoRef?.current?.paused || true);//check if video is paused
    const [pageVisibility, setPageVisibility] = useState("visible");
    const [isVideoViewed, setIsVideoViewed] = useState(false);

    const [videoViewTime, setVideoViewTime] = useState({
        start: 0,
        stop: 0,
        duration: 0
    });

    const [views, setViews] = useState([]);

    let videoStopTimeBeforeSkip;
    let videoStartTimeAfterSkip;
    let videoPlayer = document.getElementById("video-player");

    window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === "hidden") {
            setPageVisibility("hidden")
        } else {
            setPageVisibility("visible")
        }
    })

    useEffect(async () => {
        let videoWatchDuration = totalVideoWatchTime(views);

        if (pageVisibility === "hidden" && videoWatchDuration >= 30) {
            await postVideoWatchData();
            localStorage.clear();
            setIsVideoViewed(true);
        }
    }, [pageVisibility])

    useEffect(() => {
        let controller = new AbortController();

        if (videoRef?.current) {

            setIsPaused(videoRef.current?.paused);

            videoRef.current.addEventListener('pause', function () {
                setIsPaused(videoRef.current?.paused);
            }, { signal: controller.signal });

            videoRef.current.addEventListener('play', function () {
                setIsPaused(false);
            }, { signal: controller.signal });

        }

        return (() => {
            controller.abort();
        })

    }, [videoRef]);

    useEffect(() => {
        let parentNode = document.getElementById('video-media-controls-parent-container');
        if (isPaused) {
            if (parentNode) parentNode.style.display = "block";
        } else {
            if (parentNode) parentNode.style.display = "none";
        }
    }, [isPaused]);

    useEffect(() => {
        // Timestamps are only recorded after video is initially played, beginning with the start time after initial play
        if (initialPlay) {

            if (!videoRef.current.paused) {
                setVideoViewTime((videoViewTime) => ({
                    ...videoViewTime,
                    start: videoRef.current.currentTime
                }));
            } else if (videoRef.current.paused) {
                // Stop times complete each view and will total the duration in seconds
                setVideoViewTime((videoViewTime) => ({
                    ...videoViewTime,
                    stop: videoRef.current.currentTime,
                    duration: (Math.floor(videoRef.current.currentTime - videoViewTime.start))
                }));

            }
        }

    }, [videoRef?.current?.paused])

    useEffect(() => {
        if (videoViewTime.duration > 0) {
            setViews([...views, videoViewTime]);
        }
    }, [videoViewTime.duration]);

    useEffect(() => {
        if (initialPlay) {
            let videoWatchDuration = totalVideoWatchTime(views);

            if (videoWatchDuration >= 30) {
                localStorage.setItem('user_video_watch_data', JSON.stringify({
                    "user_email": userData.email,
                    "video_id": videoInfo.id,
                    "watch_details": {
                        "views": [...views]
                    }
                }))
            }
        }
    }, [views])

    const onIdle = async () => {

        let videoWatchDuration = totalVideoWatchTime(views);

        if (isPaused && videoWatchDuration >= 30) {
            postVideoWatchData();
            localStorage.clear();
            setViews([])
        }
    }

    useIdleTimer({
        onIdle,
        timeout: 7000,
        events: [
            'mousemove',
            'keydown',
            'scroll',
            'click'
        ]
    })

    const trackVideoTimeBeforeSkip = () => {

        if (!videoPlayer.seeking) {
            videoStopTimeBeforeSkip = Math.floor(videoPlayer.currentTime);
        } else if (videoPlayer.seeking && videoStopTimeBeforeSkip) {
            setVideoViewTime((videoViewTime) => ({
                ...videoViewTime,
                stop: videoStopTimeBeforeSkip,
                duration: Math.floor(videoStopTimeBeforeSkip - videoViewTime.start)
            }))
        }
    }

    const trackVideoTimeAfterSkip = () => {
        if (videoPlayer.currentTime) {
            videoStartTimeAfterSkip = videoPlayer.currentTime
            setVideoViewTime((videoViewTime) => ({
                ...videoViewTime,
                start: videoStartTimeAfterSkip
            }))
        }
    }

    const totalVideoWatchTime = (views) => {
        let sumOfViewDurations = 0;
        for (let view of views) {
            sumOfViewDurations += view.duration
        }
        return sumOfViewDurations;
    }

    const postVideoWatchData = () => {

        let userVideoWatchData = JSON.parse(localStorage.getItem('user_video_watch_data'));

        if (userVideoWatchData) {

            fetch(`${getApiBase()}/uservideoview`, {
                keepalive: true,
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify(userVideoWatchData)
            }).then(res => res.json())

            setViews([]);
        }
    }

    /* handle pause and play button */
    const handleOnPlayClick = () => {
        if (!initialPlay) {
            setInitialPlay(true);
        };

        if (isPaused) {
            videoRef?.current?.play();
        } else {
            videoRef?.current?.pause();
        }
    }

    if (videoPlayer) {
        videoPlayer.ontimeupdate = () => {
            if (Math.floor(videoPlayer.currentTime) >= 30 && views.length === 0 && isVideoViewed === false) {
                setVideoViewTime((videoViewTime) => ({
                    ...videoViewTime,
                    stop: videoRef.current.currentTime,
                    duration: (Math.floor(videoRef.current.currentTime - videoViewTime.start))
                }));
                setIsVideoViewed(true)
            }
            trackVideoTimeBeforeSkip();
        };

        videoPlayer.onseeking = () => {
            trackVideoTimeBeforeSkip();
        };

        videoPlayer.onseeked = () => {
            trackVideoTimeAfterSkip();
        };
    }


    return (
        <>
            {
                isPaused ?
                    <PlayArrowIcon id="play-arrow-icon" fontSize="medium" onClick={() => handleOnPlayClick()} /> :
                    <PauseIcon id="pause-icon" fontSize="medium" onClick={() => handleOnPlayClick()} />
            }
        </>
    );
};

export default PlayPause;
