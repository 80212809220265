import React from 'react';
import { CircularProgress } from '@mui/material';

const CircularLoader = ({
    styles=null
}) => {

    return (
        <div style={Object.assign({display:"flex",justifyContent:"center",alignItems:"center",flex:1})}>
            <CircularProgress sx={styles}/>
        </div>
    )

}

export default CircularLoader;