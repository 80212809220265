import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CohortSettingsContext } from '.';
import { useStyles } from '../baseLayout/styles';

const MENU_ITEMS = require('../baseLayout/menuWithPermissionScheme.json');

export default function CohortSettingsLeftMenu() {

    const classes = useStyles();

    const location=useLocation();

    const {isLoading:cohortSettingIsLoaded}=useContext(CohortSettingsContext);

    const settingsMenu=MENU_ITEMS.filter(i=>i.parentId==='cohort_settings');

    useEffect(()=>{
        if(!cohortSettingIsLoaded 
            && location.pathname==='/acp/settings/' 
            && location.hash.length>0 
            && settingsMenu.map(x=>x.path).includes(location.hash)){

            try {
                let anchorTarget=document.getElementById(location.hash.split('#')[1]);
                if(anchorTarget){                
                    //;
                    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            } catch (error) {
                console.error(error);

            }
            
        }
    },[location, cohortSettingIsLoaded]);

    return (
        <div className='menuContainer'>

            {
                settingsMenu.map(item=>{
                    return <div 
                            key={item.id}
                            className={`${classes.lBarRightPaneLevel2SubMenuOuter} 
                            ${location.hash===item.path?classes.lBarRightPaneLevel2SubMenuSelectedState:""}`}>
                        <Link to={item.path}>
                            <div className={classes.lBarRightPaneLevel2SubMenuInner}>
                                {item.name}
                            </div>
                        </Link>
                    </div>
                })
            }
            
        </div>
    )
}