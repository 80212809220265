import Modal from "../modals/Modal";
import AddMemberTable from "./AddMemberTable";
export default function AddMemberModal({ handleClose, isOpen, setSuccessAlert, setErrorAlert, handleUpdate, handleUpdateMemberCount }) {

  if (isOpen)
    return (
      <Modal
        handleClose={handleClose}
        width="80vw"
        height='auto'
        minHeight='500px'
        styles={{ display: "flex", flexDirection: "column" }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: 700,
            marginBottom: "10px",
            color: "#1D3969"
          }}
        >
          Add Member(s)
        </p>
        <p
          style={{
            fontFamily: "Open Sans",
            fontWeight: 300,
            fontSize: "14px"
          }}
        >
          Search and add users by their Kenzie email. Users that are not found will be created. After adding, you will need to set the member's status and role below.
        </p>
        <p
          style={{
            fontFamily: "Open Sans",
            fontWeight: 300,
            fontSize: "14px",
            marginTop: '0'
          }}
        >
          <b style={{ fontWeight: 600 }}>Notice: You must have a LMS ID for each learner before adding. </b>You will not be able to add or edit this later. Staff members do not need an LMS ID.
        </p>
        <AddMemberTable handleClose={handleClose} setErrorAlert={setErrorAlert} setSuccessAlert={setSuccessAlert} handleUpdate={handleUpdate} handleUpdateMemberCount={handleUpdateMemberCount} />

      </Modal>
    );
  else return null
}
