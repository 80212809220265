import React from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => {
    return ({
        sizer: {
            position: 'absolute',
            top: 0,
            left: 0,
            visibility: 'hidden',
            height: 0,
            overflow: 'scroll',
            whiteSpace: 'pre',
            ...theme.paragraphFont
        },
        input: {
            ...theme.paragraphFont,
            color: "#14606B",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "2px solid #14606B !important",
            textAlign: "left",
            "&:focus": {
                outline: "none"
            },
            "&::placeholder": {
                ...theme.paragraphFont,
                opacity: 0.5,
                color: "#14606B",
            },

        }

    })
})

export const GrowingInput = (props) => {
    const classes = useStyles()
    const [width, setWidth] = React.useState(40)
    const sizer = React.useRef()
    const placeholderSizer = React.useRef()

    const updateWidth = () => {
        if (!sizer || !sizer.current || typeof sizer.current.scrollWidth === 'undefined') {
            return;
        }

        let newWidth = props.placeholder
            ? Math.max(sizer.current.scrollWidth, (!props.value ? placeholderSizer.current?.scrollWidth : placeholderSizer.current?.scrollWidth / 4)) + 2
            : sizer.current.scrollWidth + 2

        setWidth(newWidth)

    }
    React.useEffect(() => { updateWidth() }, [props.value, sizer, placeholderSizer])

    return (
        <span style={{ whiteSpace: "nowrap" }}>
            <input
                type="text"
                className={`${classes.input} ${props.className || ''}`}
                name={props.name}
                value={props.value || ""}
                id={props.id}
                onChange={props.onChange}
                placeholder={props.placeholder}
                style={{ width: width, maxWidth: "100%" }}
                data-lpignore="true"

            />.
            <span className={`${classes.sizer} ${props.className || ''}`} ref={sizer}>{props.value}</span>
            {props.placeholder
                ? <span className={`${classes.sizer} ${props.className || ''}`} ref={placeholderSizer}>{props.placeholder}</span>
                : null
            }
        </span>
    )
}
