import axios from 'axios';
import { getApiBase, HEADERS } from './helpers';
const moment = require('moment');

/**
 * @section  Support Requests 
 * @param {*} param 
 * @returns 
 */
const getSupportRequests = async (cohortId, params = {
    'expand': 'coursestructure,users',
    'limit': 400
}) => {
    try {

        const getSupportRequestsEndpoint = `${getApiBase()}/v1/cohort/${cohortId}/supportrequests`
        let { data } = await axios.get(getSupportRequestsEndpoint, {
            params: params,
            headers: HEADERS
        });

        return data && data?.message && data?.message?.support_requests ? data.message.support_requests : [];

    } catch (error) {
        console.error(error);
    }
}

/**
 * 
 * @param {*} params = {id, other params}
 * @returns 
 */
const getSupportRequestById = async (params) => {
    try {

        const getSupportRequestsEndpoint = `${getApiBase()}/v1/supportrequests`
        let { data } = await axios.get(getSupportRequestsEndpoint, {
            params: params,
            headers: HEADERS
        });

        return data && data?.message && data?.message?.support_requests ? data.message.support_requests : [];

    } catch (error) {
        console.error(error);
    }
}

const updateStudentRequest = async (updateRequestObject) => {
    try {
        const getSupportRequestsEndpoint = `${getApiBase()}/updateSupportRequest`;

        const res = await axios.post(getSupportRequestsEndpoint, updateRequestObject, {
            headers: HEADERS
        });

        const responseData = res.data && res.data.message && res.data.message.message

        return responseData;

    } catch (error) {
        throw new Error(error);
    }
}

const getSupportRequestActions = async () => {
    try {
        const getSupportRequestsEndpoint = `${getApiBase()}/getSupportRequestActions`;
        const res = await axios.get(getSupportRequestsEndpoint, {
            headers: HEADERS
        })
        const requestActions = res.data && res.data.message && res.data.message.actions
        return requestActions
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }

}

const getPastResponses = async (requestId) => {
    try {
        const endpoint = `${getApiBase()}/getRequestResponses/${requestId}`
        const res = await axios.get(endpoint, {
            headers: HEADERS
        })

        const data = res.data && res.data.message && res.data.message.responses
        return data

    } catch (error) {
        throw new Error(error);
    }

}

const submitRequestSupport = async (requestBody) => {
    try {
        const requestSupportEndpoint = `${getApiBase()}/requestSupport`;
        await axios.post(requestSupportEndpoint, JSON.stringify(requestBody), {
            headers: HEADERS
        });
    } catch (error) {
        throw new Error(error);
    }
}

/**
 * @description - Announcements
 * @param {*} postRequestBody 
 * @returns 
 */
const postAnnouncement = async postRequestBody => {
    
    try {
        let response = await axios.post(`${getApiBase()}/announcement`,
            postRequestBody,
            {
                headers: HEADERS
            }
        )

        return response?.data?.message?.announcement;

    } catch (error) {
        console.error("Error Posting Announcement: ", error);
        throw new Error(error);
    }
}

/**
 * @description Gets announcements - ACP or Leaners depends on the query passed
 * @param {*} cohortId 
 * @param {*} query 
 * @returns 
 */
const getAnnouncements = async (query = null) => {
    try {
        
        if (query === null) throw new Error("query param is required for announcements api request");

        let response = await axios.get(`${getApiBase()}/announcements`, {
            params: query,
            headers: HEADERS
        });

        return response?.data?.message ? response.data.message : [];

    } catch (error) {
        console.error("Error -- Could not GET announcements")
        throw error;
    }
}

const editAnnouncement = async (reqBody, announcementId) => {
    try {
        let response = await axios.put(`${getApiBase()}/announcement/update/${announcementId}`,
            reqBody,
            {
                headers: HEADERS
            }
        )
        return response;
    } catch (error) {
        return "failed"
    }
}

const deleteAnnouncement = async (announcementId) => {
    try {
        await axios.put(`${getApiBase()}/announcement/update/${announcementId}`,
            {
                "is_deleted": true
            },
            {
                headers: HEADERS
            }
        )
    } catch (error) {
        return "failed"
    }
    return "success"
}

/**** WEBTOOLS - MK_WEBTOOLS_COURSE ***
 * @description get webtools per the cohort
 * @param {*} cohortId 
 * @returns {object} message:{cohorts:[{
 *  "mk_webtools_courses":[Array of webtools]
 * }]}
 */
const getWebtoolsByCohortId = async (cohortId, params) => {
    try {
        const { data } = await axios.get(`${getApiBase()}/v1/cohort/${cohortId}/webtools`, {
            "params": params,
            "headers": HEADERS
        })

        //;

        return data.message?.webtools ? data.message.webtools : [];

    } catch (error) {
        console.error(error)
        throw error;
    }
}

/**
 * @description - Get list of webtools available for selection from mk_webtools table
 * @returns {Array} "message": {
        "webtools": []
 }
 */
const getAllWebtools = async () => {
    try {
        const { data } = await axios.get(`${getApiBase()}/v1/webtools`, {
            headers: HEADERS
        });

        return data?.message?.webtools ? data.message.webtools : [];
    }
    catch (error) {
        console.error(error)
    }
}

/**
 * @description updates or creates webtools for the cohort in bulk
 * @param {*}  
 * @returns [array] of update values
 */
const manageCohortWebtools = async (cohortId, payload) => {

    try {
        const { data } = await axios.post(`${getApiBase()}/v1/cohort/${cohortId}/webtools`, payload, {
            headers: HEADERS
        });
        return data?.message?.webtools ? data.message.webtools : false;

    } catch (error) {
        console.error(error);
        return false
    }
}


/** CANVAS MANAGEMENT **** 
 * @param {*} course_id 
 * @returns 
 */
const getCanvasDataMK = async (course_id) => {
    try {
        const { data } = await axios.get(`${getApiBase()}/home/getCanvasData?courseId=${course_id}`, {
            headers: HEADERS
        })
        return data
    } catch (error) {
        console.error(error)
    }
}
const getCanvasAssignmentsMK = async (course_id, userEmail) => {
    try {
        const { data } = await axios.get(`${getApiBase()}/canvasassignments?courseId=${course_id}&userEmail=${userEmail}&upcomingMaxDays=8`, {
            headers: HEADERS
        })
        return data
    } catch (error) {
        console.error(error)
    }
}

/** CALENDAR MANAGEMENT  SH_CALENDAR *** 
 * @description - Cohort Calendar Management
 * @param {*} cohortId 
 * @returns 
 */
const getCohortCalendars = async (cohortId) => {

    try {
        let { data } = await axios.get(`${getApiBase()}/v1/cohorts?id=${cohortId}&expand=calendars`, {
            headers: HEADERS
        });
        return data?.message?.cohorts.length > 0 && data?.message?.cohorts[0]?.sh_calendars ? data.message.cohorts[0].sh_calendars : [];

    } catch (error) {
        console.error("Error -- Could not GET calendars");
        throw error;
    }
}

const postCalendar = async (cohortId, payload) => {

    try {
        let response = await axios.post(`${getApiBase()}/v1/cohort/${cohortId}/calendar`,
            payload,
            {
                headers: HEADERS
            }
        )
            ;
        return response;

    } catch (error) {
        console.error("Error Posting Calendar: ", error);
        return false
    }
}

const editCalendar = async (cohortId, calendarId, payload) => {
    // 
    try {
        let response = await axios.put(`${getApiBase()}/v1/cohort/${cohortId}/calendar/${calendarId}`,
            payload,
            {
                headers: HEADERS
            }
        )

        return response;

    } catch (error) {
        console.error("Error Updating Calendar: ", error);
        return false
    }
}

/** COHORT EVENTS ** CALENDAR_EVENTS TABLE
 * @description - Gets calendar events per the cohort id. The method gets all events where startDateTime is more if equal to current dateTime
 * @param {*} cohortId 
 * @returns 
 */
let getCohortCalendarEvents = async (cohortId) => {
    try {
        let sd = moment().startOf('day').subtract(7, 'days').toDate();//Go back 7 days from todays date
        let ed = moment().startOf('day').add(7, 'days').toDate();//Add 7 days from todays date

        const { data } = await axios.get(`${getApiBase()}/v1/cohorts`, {
            params: {
                "id": cohortId,
                "expand": 'calendars,events',
                "events/start_date_time": sd,//Filters calendar_events.start_date_time gte current Datetime
                "events/end_date_time": ed,//Filters calendar_events.start_date_time gte current Datetime
                "calendars/active": true//Get events for active calendars only
            },
            headers: HEADERS
        })

        // 

        return data?.message?.cohorts && data.message.cohorts.length > 0 ? data.message.cohorts[0].sh_calendars : [];
    }
    catch (error) {
        console.error(error);
        return [];
    }
}

/** Get zoom meeting details per the meeting id of the google event */
/** MeetingIds is string. Multiple meetingIds can be passed seprated by comma*/
let getCalendarEventRecordings = async (meetingIds = null) => {
    try {
        if (meetingIds === null || meetingIds.length === 0) throw new Error("meeting ids undefined");

        // let uri = `${getApiBase()}/v1/zoommeetingdetails`;
        let uri = `${getApiBase()}/v1/videos`;//Once we decide to use the video hosted in mykenzie to be linked to events you uncomment this line and comment/remove one above

        const { data } = await axios.get(uri, {
            params: {
                "meeting_id": meetingIds
            },
            headers: HEADERS
        })

        return data?.message?.videos || [];//Once we decide to use the video hosted in mykenzie to be linked to events you uncomment this line and comment/remove one above
    }
    catch (error) {
        console.error(error);
        return [];
    }
}


/** MK_USER_COURSE MANAGEMENT ***
 * 
 * @param {*} courseId 
 * @returns 
 */
async function getInstructorsByCohortId(cohortId = NaN) {

    try {

        const ATTR = [
            "id",
            "cohort_name",
            "users/active",
            "users/user_role_id",
            "users/cohort_id",
            "users/course_id",
            "users/id",
            "users/user_email",
            "userdetails/email",
            "userdetails/username",
            "userdetails/is_online",
            "userdetails/first_name",
            "userdetails/last_name",
            "userdetails/preferred_name",
            "userdetails/user_image_url"
        ]

        let url = `${getApiBase()}/v1/cohorts`;

        let { data } = await axios.get(url, {
            params: {
                "id": cohortId,
                "users/active": true,
                "expand": 'users,userdetails',
                "attributes": ATTR.join(','),
                "exclude": '(users/user_role_id=1)'
            },
            headers: HEADERS
        });

        // ;
        return data.message?.cohorts && data.message.cohorts.length > 0 ? data.message.cohorts[0].mk_user_courses : [];

    } catch (error) {
        console.error(error);
        throw error;
    }
}

async function getLearnersByCohortId(cohortId = NaN) {

    try {

        const ATTR = [
            "id",
            "cohort_name",
            "users/active",
            "users/user_role_id",
            "users/cohort_id",
            "users/course_id",
            "users/id",
            "users/user_email",
            "userdetails/email",
            "userdetails/username",
            "userdetails/is_online",
            "userdetails/first_name",
            "userdetails/last_name",
            "userdetails/preferred_name",
            "userdetails/user_image_url"
        ]

        let url = `${getApiBase()}/v1/cohorts`;

        let { data } = await axios.get(url, {
            params: {
                "id": cohortId,
                "users/active": true,
                "expand": 'users,userdetails',
                "attributes": ATTR.join(','),
                "exclude": '(users/user_role_id=1)'
            },
            headers: HEADERS
        });

        // ;
        return data.message?.cohorts && data.message.cohorts.length > 0 ? data.message.cohorts[0].mk_user_courses : [];

    } catch (error) {
        console.error(error);
        throw error;
    }
}


/**
 * @description - This enpoints creates or updates the staff members in the cohort in bulk
 * @param {*} payload 
 * @returns {Array} response
 */
async function updateCohortStaffMembers(cohortId, payload) {

    try {
        let url = `${getApiBase()}/v1/cohort/${cohortId}/users`;

        let response = await axios.post(url, payload, {
            headers: HEADERS
        });

        //console.log(response);

        return response.data?.message?.users ? response.data.message.users : [];

    } catch (error) {
        throw error;
    }
}

/**
 * @description - This enpoints update the staff member in the cohort individually
 * @param {*} payload 
 * @returns 
 */
async function updateCohortStaffMember(cohortId, payload) {

    try {
        let url = `${getApiBase()}/v1/cohort/${cohortId}/user/${payload.id}`;

        let response = await axios.put(url, payload, {
            headers: HEADERS
        });

        //console.log(response);

        return response.data?.message;

    } catch (error) {
        throw error;
    }
}


/**
 * @description Get cohort users 
 * @param {*} formData 
 * @returns 
 */
const getCohortConnectionData = async (cohortId, includeAdmin = false) => {
    try {

        //Get data for the cohort connection 
        let params = {
            "id": cohortId,
            "users/active": true,
            "expand": "users,userdetails",
        };

        if (!includeAdmin) { params.exclude = "(users/user_role_id=9)" }

        let { data } = await axios.get(`${getApiBase()}/v1/cohorts`, {
            params: params,
            headers: HEADERS
        });

        return data?.message?.cohorts || [];

    } catch (error) {
        console.log("Error getting cohort connection data: ", error);
        return [];
    }
}
/**
 * @description Get info on guest users 
 * @param {*} formData 
 * @returns 
 */
const getGuestUserData = async (guestUsers) => {
    try {
        //Get data for the cohort connection 
        let { data } = await axios.get(`${getApiBase()}/v1/users`, {
            params: {
                "username": guestUsers,
                "users/active": true,
                "expand": "users,userdetails",
                "exclude": "(users/user_role_id=9)"
            },
            headers: HEADERS
        });

        return data;

    } catch (error) {
        console.log("Error getting cohort connection data: ", error);
        return [];
    }
}

/**
 * @description - gets the total number of users per the cohort id passed
 * @param {*} formData 
 * @returns 
 */
const getTotalNumberOfActiveCohortUsers = async (query = {
    expand: "countusers"
}) => {
    try {
        //Get data for the cohort connection 
        let { data } = await axios.get(`${getApiBase()}/v1/cohorts`, {
            params: query,
            headers: HEADERS
        });

        return data;

    } catch (error) {
        ;
        throw new Error(error);
    }
}

const getTotalNumberOfActiveCourseUsers = async (query = {
    expand: "countusers"
}) => {
    try {
        //Get data for the cohort connection 
        let { data } = await axios.get(`${getApiBase()}/v1/courses`, {
            params: query,
            headers: HEADERS
        });

        return data;

    } catch (error) {
        ;
        throw new Error(error);
    }
}

/**
 * @description get user course details. Includes user course expansion
 * @param {*} formData 
 * @returns 
 */
const getUserCourseDetails = async (useremail) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/users`, {
            params: {
                "username": useremail,
                "expand": "courses"
            },
            headers: HEADERS
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
}

/**
 * @description upload file to server
 * @param {FormData} 
 * @returns {Object} response from server
 */
const uploadFile = async (formData) => {
    let uri = getApiBase();
    try {

        let requestHeaders = Object.assign(HEADERS, {
            "Content-Type": "multipart/form-data",
        })

        let response = await axios({
            method: "post",
            url: `${uri}/file`,
            data: formData,
            headers: requestHeaders
        });

        return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const uploadHandhake = async (formData) => {
    let uri = getApiBase();
    try {

        let requestHeaders = Object.assign(HEADERS, {
            "Content-Type": "multipart/form-data",
        })

        let response = await axios({
            method: "post",
            url: `${uri}/handshake`,
            data: formData,
            headers: requestHeaders
        });

        return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * @description Creating a new user search through the connect page
 * @param {*} payload 
 * @returns 
 */
const createUserSearch = async (payload) => {
    try {
        let response = await axios.post(`${getApiBase()}/v1/usersearch`,
            payload, { headers: HEADERS }
        )
        //;
        return response;

    } catch (error) {
        ;
        throw error;
    }
}


/**
 * Get All Programs from DB from Admin settings. Irrespective if connected to cohort
 */
const getPrograms = async (query = { limit: 200 }) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/programs`, {
            params: query,
            headers: HEADERS
        });

        return data?.message ? data.message.programs : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}

/**
 * Get All Cohorts from DB from Admin settings
 */
const getCohorts = async (query = {
    "expand": "programs",
    "limit": 200
}) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/cohorts`, {
            params: query,
            headers: HEADERS
        });

        return data?.message ? data.message.cohorts : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}

/**
 * Get All Cohorts from DB from Admin settings
 */
const createCohort = async (payload) => {
    try {
        let { data } = await axios.post(`${getApiBase()}/v1/cohort`,
            payload,
            { headers: HEADERS }
        )

        //console.log(response);
        return data;

    } catch (error) {
        console.log("Error Creating the cohort information: ", error);
        return false
    }
}

/**
 * @description - Updates Cohort Information
 * @param {*} values 
 * @param {*} cohortId 
 * @returns 
 */
const updateCohort = async (values, cohortId) => {
    try {
        let { data } = await axios.put(`${getApiBase()}/v1/cohort/${cohortId}`, values, {
            headers: HEADERS
        }
        )

        //console.log(response);
        return data;

    } catch (error) {
        console.error("Error updating the cohort information: ", error);
        return false
    }
}

/**
 * Get All Courses from DB from Admin settings
 */
const getAllCourses = async () => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/courses`, {
            params: {
                "limit": 200
            },
            headers: HEADERS
        });

        //console.log(data.message);

        return data?.message ? data.message.courses : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}

/**
 * Get All Courses from DB from Admin settings. Irrespective if connected to cohort
 */
const getCoursesFromCanvas = async (query) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/canvascourses`, {
            params: {
                ...query
            },
            headers: HEADERS
        });

        return data?.message ? data.message : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}

const syncCanvasCourses = async () => {
    try {
        let { data } = await axios.get(`${getApiBase()}/syncCanvasCourses`)
        return data?.message ? true : false
    } catch (error) {
        console.error(error)
        return false
    }
}

/**
 * create course in db. Single course
 */
const createCourse = async (cohortId, payload) => {
    try {
        let response = await axios.post(`${getApiBase()}/v1/cohort/${cohortId}/course`,
            payload,
            { headers: HEADERS }
        )

        //;
        return response;

    } catch (error) {
        console.log("Error Creating the cohort information: ", error);
        throw error;
    }
}

/**
 * create courses in bulk in db
 */
const createCourses = async (payload) => {
    try {
        let response = await axios.post(`${getApiBase()}/v1/courses`,
            payload,
            { headers: HEADERS }
        )

        //console.log(response);
        return response;

    } catch (error) {
        console.log("Error Creating the cohort information: ", error);
        throw error;
    }
}


/**
 * @description - Updates Course Information
 * @param {*} values 
 * @param {*} cohortId 
 * @returns 
 */
const updateCourse = async (values, courseLmsId) => {
    try {
        let { data } = await axios.put(`${getApiBase()}/v1/course/${courseLmsId}`, values, {
            headers: HEADERS
        }
        )
        return data;

    } catch (error) {
        console.log("Error updating the course information: ", error);
        throw error;
    }
}

/**
 * @description - Get the Zoom Study Session Dataset
 * @param {*} values 
 * @param {*} cohortId 
 * @returns 
 */
const getStudySessionData = async (meetingId) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/zoomstudysessiondetails`, {
            params: {
                "meeting_id": meetingId
            },
            headers: HEADERS
        });

        return data?.message || {};

    } catch (error) {
        console.error("Error updating the course information: ", error);
        return {}
    }
}

const addStudySessionTopic = async (meetingId, topicObj) => {
    try {
        const { data } = await axios.post(`${getApiBase()}/v1/studysessiontopic`, 
        {
            host_id: meetingId,
            topicObj
        }, 
        {
            headers: HEADERS
        });
        return data
    } catch (error) {
        console.log(error)
        return false
    }
}

const getAdvisorsByStudentEmail = async (student_email) => {
    try {

        let { data } = await axios.get(`${getApiBase()}/v1/studentadvisors`, {
            params: {
                "username": student_email,
            },
            headers: HEADERS
        });

        return data;

    } catch (error) {
        console.log("Error getting cohort connection data: ", error);
        return [];
    }
}

const getVideoInfo = async (query, cancelSignal) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/videos/`, {
            params: query,
            headers: HEADERS,
            signal: cancelSignal
        })

        return data?.message?.videos || [];

    } catch (error) {
        if (!error?.message === 'canceled') {
            console.error("Error in fetching videos: ", error);

        }
        return [];
    }
}
const getCalendarInfoByCohortId = async (cohortId, cancelSignal = null) => {


    try {
        let { data } = await axios.get(`${getApiBase()}/calendars/?cohort_id=${cohortId}`, {
            headers: HEADERS,
            signal: cancelSignal
        })

        return data?.message.calendars || [];

    } catch (error) {
        if (error) {
            // if (error?.message !== 'canceled')
            console.error("Error in fetching calendars: ", cohortId, error);
        }
        return [];
    }
}
const getAllCalendars = async (cancelSignal) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/calendars/`, {
            headers: HEADERS,
            signal: cancelSignal || null
        })

        return data?.message.calendars || [];

    } catch (error) {
        if (!error?.name?.startsWith('AbortError')) {
            console.error("Error in fetching calendars: ", error);
        }
        return [];
    }
}

const getVideosByGoogleCalendarId = async (calendarId, cancelSignal) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/videos/?google_calendar_ids=${calendarId}&limit=10000`, {
            headers: HEADERS,
            signal: cancelSignal || null,

        })

        return data?.message?.videos || [];

    } catch (error) {
        if (!error.name.startsWith('AbortError')) {
            console.error("Error in fetching calendars: ", calendarId, error);
        }
        return [];
    }
}

const getEventByGoogleID = async (googleEventId, calendarId, cancelSignal) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/calendarevents?google_event_id=${googleEventId}&google_calendar_id=${calendarId}`, {
            headers: HEADERS,
            signal: cancelSignal || null
        })

        return data?.message?.calendar_events || [];

    } catch (error) {
        if (!error.name.startsWith('AbortError')) {
            console.error("Error in fetching calendars: ", error);
        }
        return [];
    }
}

const postUserVideoView = async (payload) => {
    try {
        let response = await axios.post(`${getApiBase()}/uservideoview`,
            payload,
            { headers: HEADERS }
        )
        return response;

    } catch (error) {
        throw new Error(error);
    }
}

const getUserVideoViews = async (user_email) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/uservideoview/${user_email}`, {
            headers: HEADERS
        })

        return data;

    } catch (error) {
        throw error;
    }
}

export {
    getSupportRequests,
    getSupportRequestById,
    getPastResponses,
    updateStudentRequest,
    getSupportRequestActions,
    submitRequestSupport,
    getCohortCalendarEvents,
    getCalendarEventRecordings,
    postAnnouncement,
    getAnnouncements,
    editAnnouncement,
    deleteAnnouncement,
    getWebtoolsByCohortId,
    getAllWebtools,
    manageCohortWebtools,
    getCohortCalendars,
    postCalendar,
    editCalendar,
    getCanvasDataMK,
    getCanvasAssignmentsMK,
    getInstructorsByCohortId,
    updateCohortStaffMembers,
    updateCohortStaffMember,
    getCohortConnectionData,
    getGuestUserData,
    getTotalNumberOfActiveCohortUsers,
    getTotalNumberOfActiveCourseUsers,
    getUserCourseDetails,
    uploadFile,
    uploadHandhake,
    createUserSearch,
    getCohorts,
    createCohort,
    updateCohort,
    getPrograms,
    getAllCourses,
    createCourse,
    createCourses,
    getCoursesFromCanvas,
    updateCourse,
    getAdvisorsByStudentEmail,
    getStudySessionData,
    addStudySessionTopic,
    getVideoInfo,
    getLearnersByCohortId,
    getCalendarInfoByCohortId,
    getVideosByGoogleCalendarId,
    getAllCalendars,
    getEventByGoogleID,
    postUserVideoView,
    getUserVideoViews,
    syncCanvasCourses
};

