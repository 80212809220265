import { useContext, useState, useRef } from 'react';
import { Chip, Autocomplete, TextField, createFilterOptions, Button } from '@mui/material'
import { useFormikContext } from 'formik'

import { useStyles } from './styles'
import { ACPSupportRequestsContext } from '../../../context';

import CustomPopper from './CustomPopper';
import SearchHistoryMenu from './SearchHistoryMenu';

import "./searchReset.css";

// constants
const HINT = "Start typing to add filter..."
const OPTIONS_LIMIT = 10;
const FILTER_OPTIONS = createFilterOptions({ limit: OPTIONS_LIMIT })
const AUTOCOMPLETE_OPTIONS = {
    multiple: true,
    freeSolo: true,
    disableClearable: true,
    filterSelectedOptions: true,
    handleHomeEndKeys: true,
    autoSelect: true,
    disableCloseOnSelect: true,
    filterOptions: FILTER_OPTIONS
};

const SearchPanel = () => {
    const { values, handleChange, handleSubmit, setValues } = useFormikContext();
    const { searchTerms, setSearchTerms, searchHistory, filters, setFilters } = useContext(ACPSupportRequestsContext);
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const inputRef = useRef();

    const handleAutocompleteChange = (event, chipValues) => {
        //Prevent form submission when user deletes query to start over
        if (event.type !== "click" && event.type !== "keydown") return;

        setSearchTerms(chipValues);
        setValues({ ...values, query: "" })
    }

    const handleClose = (_, reason) => {
        //prevent popper from closing if user clicks inside input while popper is already open
        if (reason === "toggleInput") {
            setOpen(true);
            return;
        }
        setOpen(false);
    }

    const handleChipDelete = (option) => {
        setSearchTerms(state => state.filter(query => query !== option));
        inputRef.current.focus();
    }

    const resetAll = () => {
        let d = { ...filters };

        //set all filters to false
        Object.keys(d).forEach((k => {
            Object.keys(d[k]).forEach(v => {
                d[k][v] = false;
            });
        }));

        setSearchTerms([]);
        setFilters(d);
    }

    return (
        <div className={classes.searchPanel}>
            <form className={classes.searchFormContainer} onSubmit={handleSubmit}>
                <div className='subheader'>Search</div>
                <Autocomplete
                    {...AUTOCOMPLETE_OPTIONS}
                    className={classes.searchField}
                    open={open}
                    value={searchTerms}
                    inputValue={values.query}
                    onChange={handleAutocompleteChange}
                    onInputChange={handleChange}
                    onOpen={() => setOpen(true)}
                    onClose={handleClose}
                    PaperComponent={(params) => SearchHistoryMenu(params, values)}
                    PopperComponent={CustomPopper}
                    options={[...searchHistory]}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                            <Chip label={option} color="turquoise" {...getTagProps({ index })} onDelete={() => handleChipDelete(option)} />
                        )))}
                    renderInput={
                        (params) => (
                            <TextField
                                {...params}
                                fullWidth
                                name="query"
                                variant="standard"
                                size="small"
                                value={values.query}
                                onChange={handleChange}
                                // logic to prevent placeholder from displaying when there are chips in the input element
                                placeholder={values.query || searchTerms[0] ? "" : HINT}
                                inputRef={input => { inputRef.current = input }}
                                InputProps={{
                                    ...params.InputProps, type: "search", disableUnderline: true,
                                    sx: {
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        top: "12px",
                                        background: "white",
                                        marginBottom: "20px",
                                        borderRadius: "10px"
                                    }
                                }}
                            />
                        )
                    }
                />
            </form>
            <Button variant="text" color="turquoise" sx={{ display: "block" }} onClick={resetAll}>Clear All</Button>
        </div>
    )
}

export default SearchPanel