export const groupUserCohortsByPrograms = (userCourses) => {
    try {

        let dataset = [...userCourses];
        let groupedData = groupCohortsByPrograms(dataset.map(x => x.cohort));

        return groupedData.filter(d => d.cohorts.length);

    } catch (error) {
        console.error(error);
        return [];//return empty array if error encountered
    }

}

export const groupCohortsByPrograms = (cohorts) => {
    try {

        let groupedData = [...cohorts].reduce((acc, ci) => {

            //extract program 
            let programs = ci.program_mappings.map(x => x.program);

            programs.forEach(program => {
                let exists = acc.find(i => i.id === program.id);
                if (!exists) {
                    let setCohorts = cohorts.filter(j => j.program_id === program.id);

                    //remove dups if exists 
                    //a cohort can be linked to differentr courses and vise versa, so there is possibility of dup cohorts
                    setCohorts = setCohorts.reduce((acc2, ci2) => {
                        if (!acc2.find(j => j.id === ci2.id)) {
                            acc2.push(ci2);
                        }
                        return acc2;
                    }, [])

                    let pg = Object.assign(program, {
                        cohorts: sortCohortsForDropDown(setCohorts)
                    });

                    acc.push(pg);
                }
            });

            return acc;

        }, []);

        return groupedData;

    } catch (error) {
        console.error(error);
        return [];//return empty array if error encountered
    }
}

function sortCohortsForDropDown(cohorts) {


    return cohorts.sort((a, b) => {
        const dateCompare = new Date(a.created_date) - new Date(b.created_date);
        if (dateCompare === 0) {
            return a.id - b.id;
        }
        return dateCompare;
    });
}


