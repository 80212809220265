import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    assistance: {
        height: "100%",
        width: "100%",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 25px 0px 25px"
    },
    internalResources: {
        boxSizing: "border-box",
        height: "90%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: "30px",
        '& a': {
            display: "block",
            color: "black",
            textDecoration: "none"
        }
    },
    resource: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        height: "80%",
        width: "80%",
        border: "1.5px solid #404040",
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
        textAlign: "center",
        marginRight: "15px",
        transition: "all .300s",
        '&:hover': {
            cursor: "pointer",
            boxShadow: "2px 3px 6px #606060"
        },
        '& svg': {
            height: "30%",
            marginBottom: "8px"
        },
        '& span': {
            boxSizing: "border-box",
            width: "100%",
            fontFamily: "Nunito",
            fontSize: ".7vw",
            padding:"0px 10px "
        }
    }
}))