import { FormControl, Menu, MenuItem, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';


import CohortDropDownTextField from './CohortDropDownTextField';
import { groupUserCohortsByPrograms } from './helpers';
import { useStyles } from './styles';
import { useACPDataContextV2 } from '../../context/ACPDataStoreV2';
import { ReactComponent as SelectedCohortIcon } from '../../../icons/selected-cohort-indicator.svg';

const CohortDropDownSingleSelect = () => {
    const acpDataStoreV2 = useACPDataContextV2()
    const { selectedCohortId, setSelectedCohortId, userCourses } = acpDataStoreV2
    const classes = useStyles();

    const textFieldRef = useRef(null);


    const [groupedByProgram, setGroupedByProgram] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOnChange = (val) => {
        if (val) {
            setSelectedCohortId(val)
        }
    }
    //Grouping the cohorts with program
    useEffect(() => {
        if (userCourses) {
            setGroupedByProgram(groupUserCohortsByPrograms(userCourses));
        }
    }, [userCourses]);

    const handleClickTextField = (event) => {
        setAnchorEl(event.currentTarget);
        event.target.blur();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const checkIfSelected = (cohortId) => {
        return selectedCohortId === Number(cohortId);
    }

    const getSelectedCohortName = () => {
        let cohortInfo = userCourses?.find(i => i.cohort.id === selectedCohortId)

        return cohortInfo?.cohort?.cohort_name
    }
    // if () return <div style={{ minWidth: '351px' }} />
    const shouldDisable = window.location.pathname.includes('acp/admin/settings')
    return (
        <div>
            <FormControl sx={{
                minWidth: "351px",
                pointerEvents: shouldDisable ? 'none' : 'initial',
            }}>

                <CohortDropDownTextField
                    shouldDisable={shouldDisable}
                    ref={textFieldRef}
                    menuVisible={open}
                    handleOnClick={handleClickTextField}
                    getSelectedCohortName={getSelectedCohortName}
                />

                <Paper>
                    <Menu
                        sx={{
                            fontFamily: "open sans",
                            '& .MuiPaper-root': {
                                width: "400px",
                                height: "400px",
                                overflow: "auto"
                            }
                        }}
                        id="acp-header-single-select-cohort-dropdown-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {
                            userCourses && groupedByProgram.length > 0 ?
                                groupedByProgram.map(program => {
                                    return <div
                                        key={`program-id-${program.id}`}
                                        id={`program-id-${program.id}`}>
                                        <div className={classes.cohortDropDownProgramName}>{program.program_name}</div>
                                        {
                                            program.cohorts.map(cohort => {
                                                const isSelected = checkIfSelected(cohort.id)
                                                return <MenuItem
                                                    id={`cohort-drop-down-single-select=${cohort.id}`}
                                                    className={`${isSelected ? "Mui-selected" : ""}`}
                                                    onClick={() => {
                                                        handleOnChange(parseInt(cohort.id));
                                                        handleClose();
                                                    }}
                                                    value={cohort.id}
                                                    key={`cohort-id-${cohort.id}`}>
                                                    <div
                                                        className={`${classes.cohortDropDownCohortName}`}
                                                        style={{ marginLeft: "10px", width: '100%', display: 'flex', alignItems: 'center' }}
                                                    >

                                                        {cohort.cohort_name}
                                                        {isSelected ? <SelectedCohortIcon style={{ marginLeft: 'auto', display: 'block' }} /> : null}</div>
                                                </MenuItem>
                                            })
                                        }

                                    </div>

                                }) :
                                null
                        }

                    </Menu>
                </Paper>
            </FormControl>

        </div>
    )
}

export default CohortDropDownSingleSelect