import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    profileImage: {
        borderRadius: "50%",
        height: "164px",
        width: "164px",
        border: `10px solid #FFFFFF`,
        marginBottom: '28px',
        [theme.breakpoints.down('md')]: {
            height: "182px",
            width: "182px",
            marginBottom: '28px',
        }
    },
    profileName: {
        fontSize: '30px',
        display: 'flex',
        justifyContent: 'center',
        // flexDirection: 'column',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            fontSize: '25px'
        }
    },
    profileCourses: {

        fontWeight: 600, fontSize: '18px',
        lineHeight: '21.13px',
        margin: 'auto',
        textAlign: 'center',
        marginTop: '22px',
        [theme.breakpoints.down('md')]: {
            marginTop: '15px'
        }
    },
    namePronounWrapper: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }

    },
    // profileGrid: {
    //     display: 'grid',
    //     columnGap: '18px',
    //     rowGap: '20px',
    //     gridTemplateAreas: `
    //     "am loc"
    //     "am exp"
    //     "am con"
    //     "foc foc"
    //     `,
    //     gridTemplateColumns: '1fr 1fr',
    //     marginTop: '4px',
    //     [theme.breakpoints.down('md')]: {
    //         display: 'block',
    //         marginTop: '16px'
    //     }
    // },
    profileGridTop: {
        display: "flex",
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            marginTop: '16px'
        }
    },
    profileGridTopLeft: {
        width: "567px",
        display: "flex",
        marginRight: "18px",
        [theme.breakpoints.down('md')]: {
           width: "100%"
        }
    },
    profileGridTopRight: {
        width: "567px",
        display: "flex",
        flexDirection: "column",
        flex: "auto",
        boxSizing: "border-box",
        paddingRight: "4px",
        [theme.breakpoints.down('md')]: {
            width: "100%"
         }
    },
    profileGridBottom: {
        boxSizing: "border-box",
        paddingRight: "4px"
    },
    profileSection: {
        width: "100%",
        border: '2px solid #ECECEC',
        borderRadius: '10px',
        paddingBottom: '20px',
        [theme.breakpoints.down('md')]: {

            border: 0
        },
        backgroundColor: "#FFFFFF",
        marginBottom: "20px"
    },
    profileSectionHeader: {
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: theme.colors.profileLightBlue,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '25px',
        marginBottom: '20px',
        '& .profile-section-title': {
            color: theme.colors.deepAqua,
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '23.48px',
            marginLeft: '10px'
        },

        [theme.breakpoints.down('md')]: {
            marginTop: '20px',

        }

    },
    profileSectionContent: {
        margin: "0px 25px",
    },
    focusTime: {

        [theme.breakpoints.down('md')]: {
            marginTop: '0px',
        }
    },
    profileSectionParagraph: {
        fontFamily: 'Nunito',
        fontWeight: 500,
        fontSize: '16px',
    },
    profilePronouns: {
        lineHeight: '18.78px',
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        }
    },

    headerButtons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40px",
        width: '100%',
        // "& .each-icon": {
        //     marginRight: "20px"
        // },
        // "& img": {
        //     width: "20px",
        //     height: "20px",
        // },
        [theme.breakpoints.down('md')]: {

            justifyContent: "center",
            flexDirection: 'column',

        }
    },
    headerButton: {
        backgroundColor: "#FFFFFF",
        textAlign: 'center',
        boxSizing: 'border-box',
        height: '50px',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '180px',
        border: `1.5px solid ${theme.colors.deepAqua}`,
        textDecoration: 'none',
        '&.slack-btn': {
            marginRight: '15px'
        },
        '& .button-text': {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Nunito',
        },
        '& .button-icon': {
            marginRight: '10px',
            width: '18px', height: '18px',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            '&.email-btn': {
                marginTop: '16px',
                marginLeft: 0,

            },

        },

    },
    topItems: {
        display: "flex",
        alignItems: "baseline",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: 'Nunito',
        lineHeight: "1em",
        marginBottom: '6px',
        "& p": {
            margin: 0,

            // marginBottom: '15px'
        },
        "& img": {
            marginRight: "15px",
            width: "18px",
            height: "18px",
            position: 'relative',
            top: '2.5px',
            '& svg': {
                width: "18px",
                height: "18px",
                fontSize: '16px'
            }
        },

        [theme.breakpoints.down('md')]: {
            lineHeight: "1.5em"
        }
    },

    subHeader: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '21.82px',
        color: theme.colors.deepAqua,
        fontFamily: 'Nunito'
    },
    sectionMargins: {
        marginTop: "40px",
    },
    programmingLanguages: {
        width: "100%",
    },
    pillGroup: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: "16px",
    },
    pills: {
        height: "34px",
        // width: "93px",
        fontSize: "14px",
        fontWeight: 500,
        border: "1px solid #5F5F5F",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 8px 8px 0px",
        boxSizing: "border-box",
        padding: "12px",
        [theme.breakpoints.down('md')]: {
            margin: "0px 12px 12px 0px"
        }
    },
    socialMediaLinksContainer: {
        [theme.breakpoints.up('md')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: '4px'
        },
        [theme.breakpoints.down('md')]: {
            display: "grid",
            gridTemplateColumns: 'repeat(5, 19% [col-start])'
        }
    },
    // socialMediaSection: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     margin: '12px 0',
    //     [theme.breakpoints.down('md')]: {
    //         display: "grid",

    //     }
    // },

    contactIcons: {
        marginRight: "13px",
        marginBottom: '5px',
        "& svg": {
            fill: theme.colors.deepAqua,
            height: "34px",
            width: "34px",

            [theme.breakpoints.down('md')]: {
                height: "40.33px",
                width: "40.33px",
                marginTop: "8px"
            }
        }
    },

    /**
     * WorkLife Balance Styles
     * Focus Time & Work Schedule
     */
    kenzieFocusTimeDesktopLabel: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    kenzieFocusTimeMobileSlideLabel: {
        display: 'none',
        margin: '0 auto 25px auto',
        [theme.breakpoints.down('md')]: {
            display: "block"
        }
    },
    kenzieFocusTimeContainer: {
        overflowY: "auto",
    },
    kenzieFocusTimeInnerContainer: {
        borderRadius: "10px",
        overflowY: "auto",

        '& .each-focus-time-day': {
            minWidth: '650px',
            padding: "5px 15px",
            display: 'grid',
            gridTemplateColumns: '1fr 8fr',
            gridAutoRows: "44px",
            alignItems: "center",


            '&:nth-child(2n+1)': {
                backgroundColor: "#EFEFEF"
            },
            '& .day-name': {
                textTransform: "capitalize",
                fontWeight: 500
            },
            '& .time-slots': {

                position: "relative",
                // width: "100%",
                height: "100%"
            },
            '& .each-time-slot': {
                color: "white",
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                backgroundColor: theme.colors.deepAqua,
                borderRadius: "25px",
                '& .each-time-slot-icon': {
                    padding: "5px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "24px",
                    width: "24px",
                    margin: "1px",
                    '& img': {
                        width: "100%"
                    }
                },
                '& .each-time-slot-name': {
                    padding: "8px 0px",
                    marginLeft: "5px",
                    overflow: 'hidden'
                },
                '& .time-slot-slant-lines': {
                    position: "absolute",
                    left: "100px",
                    top: "0px",
                    right: "0px",
                    height: "100%",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    overflow: "hidden",
                    '& .each-time-slot-slant-line': {
                        '&:first-child': {
                            display: 'none'
                        },
                        position: "absolute",
                        top: "0px",
                        bottom: "0px",
                        borderRight: `1.5px solid #63C29D`,
                        transform: "skewX(-35deg)"
                    }
                }
            },
            '& .each-time-slot[data-alldayexists="true"]': {
                border: '1px solid #63C29D',
            }
        }
    }
}))

export default useStyles