import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';

import { editCalendar, postCalendar } from '../../../../api';

import { CohortCalendarDataContext } from './CalendarSettingsPage';

import { useCohortSettingStyles } from '../styles';

import { CohortSettingsContext } from '..';
import { checkIfValidLink, getUrlSearchParam } from '../../../../helpers/utils';

/** Get calendar source id/ google calendar id. Also decodeURIComponent to have it in decoded format */
const getGCalendarSourceId = (calendarUrl) => {
    if (calendarUrl.includes("?src=")) {
        return decodeURIComponent(getUrlSearchParam(calendarUrl, "src"));

    } else if (calendarUrl.includes("?cid=")) {
        return decodeURIComponent(getUrlSearchParam(calendarUrl, "cid"));

    } else {
        return null;
    }
}

const CalendarForm = ({ 
    handleOnClose,
    calendar={},
    cohortId 
}) => {
    
    const classes = useCohortSettingStyles();

    const { calendar_name, cohort_id, calendar_url, google_calendar_id, id } = calendar;

    const { 
        setSubmissionSuccessfull,
        setSubmissionSuccessMessage,
        setSubmissionError
    }=useContext(CohortSettingsContext);

    let [isSubmitting, setIsSubmitting] = useState(false);
    let [isDeleting, setIsDeleting] = useState(false);

    let [inValidUrl,setInValidUrl]=useState(false);

    const {updateCalendarDataset, addCalendarIntoDataset}=useContext(CohortCalendarDataContext)

    let dataRef = useRef({
        calendar_name: calendar_name,
        calendar_url: calendar_url,
        google_calendar_id:google_calendar_id
    })

    const handleChange = event => {
        dataRef.current[event.target.name] = event.target.value;

        if(event.target.name==="calendar_url" 
            && !checkIfValidLink(event.target.value) 
            && getGCalendarSourceId(event.target.value)===null){
                setInValidUrl(true);
        }else{
            setInValidUrl(false);
        }
    }

    /**
     * Deleting Calendar
     */
    const handleDelete = async () => {

        try {
            setIsDeleting(true);

            await editCalendar(cohort_id,id,{
                id: id,
                active: false
            });

            //Update the Calendar value 
            updateCalendarDataset({
                cohort_id: cohort_id,
                id: id
            }, true);

            handleOnClose();

            setSubmissionSuccessMessage("Calendar has been deleted!")
            setSubmissionSuccessfull(true);
    
        } catch (error) {
            console.error(error);
            setIsDeleting(false);
            setSubmissionError(true);
        }
    }

    /**
     * 
     * @param {Event Object} e
     */
    const handleSubmit = async (e) => {

        try {

            e.preventDefault();

            if(inValidUrl) throw new Error("invalid url");

            setIsSubmitting(true);

            let response=null;

            //If id exists then its edit mode otherwise its create mode
            if (id) {
                //Update Calendar
                let payload={
                    calendar_name: dataRef.current.calendar_name,
                    calendar_url: dataRef.current.calendar_url,
                    google_calendar_id:getGCalendarSourceId(dataRef.current.calendar_url),
                    id: id
                }
                response = await editCalendar(cohort_id, id, payload);

                //Update the Calendar value 
                updateCalendarDataset(payload);

            } else {
                //Create Calendar
                let payload={
                    calendar_name:dataRef.current.calendar_name,
                    calendar_url: dataRef.current.calendar_url,
                    cohort_id: cohortId,
                    active:true,
                    google_calendar_id:getGCalendarSourceId(dataRef.current.calendar_url),
                }

                response = await postCalendar(cohortId,payload);

                //Add new the Calendar value 
                if(response.status!==200) throw new Error('error while submitting');

                addCalendarIntoDataset(response.data.message.calendar);

            }

            setSubmissionSuccessfull(true);

            handleOnClose();

        } catch (error) {
            console.error(error);
            setSubmissionError(true);

        }finally{
            setIsSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.sectionContainer}>
                <div className={classes.label}>Calendar Name</div>
                <div style={{display:"flex",alignItems:"center"}}>
                    <TextField
                        sx={{
                            width:"100%",
                            flexGrow:2
                        }}
                        name="calendar_name"
                        onChange={handleChange}
                        defaultValue={calendar_name}
                    />
                    <div style={{marginLeft:"8px"}}>
                        {
                            isDeleting?
                            <CircularProgress color="primary" size={18} />:
                            <IconButton onClick={()=>{handleDelete()}}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </div>
                </div>
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.label}>Calendar URL</div>
                <TextField
                    error={inValidUrl}
                    helperText={inValidUrl?"Please enter valid url. Starting with http:// or https://. and must contain the google calendar src or cid in url":""}
                    sx={{
                        width:"100%"
                    }}
                    name="calendar_url"
                    onChange={handleChange}
                    defaultValue={calendar_url}
                />
            </div>
            <div className={classes.formFooter}>
                <div>
                    <Button 
                        onClick={()=>{handleOnClose()}} 
                        variant="text" 
                        className={classes.textButton}>
                        Cancel
                    </Button>
                </div>
                <div className={classes.submitButton}
                    id="update-calendar-submit-button"
                    data-testid="update-calendar-submit-button" >
                    <Button type="submit" disabled={isSubmitting}>
                        {
                            isSubmitting  ?
                            <CircularProgress color="inherit" size={18} /> :
                            <div>Save Changes</div>
                        }
                    </Button>
                </div>
            </div>
        </form>

    )
};

export default CalendarForm;

