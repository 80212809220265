import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { useFormikContext } from 'formik';
import useEditProfileStyles from '../../../../sharedComponents/editProfileFields/styles/editProfileStyles';
import LearnerKenzieFocusTimeSlots from '../../learnerEditProfile/LearnerKenzieFocusTimeSlots';
import useWorkLifeStyles from '../../learnerEditProfile/workLifeStyle.js';

const TIME_SLOTS=require('../../../../sharedComponents/editProfileFields/focusTime/timeSlots.json');
const DAYS = require('../../../../sharedComponents/editProfileFields/focusTime/days.json');

export const LearnerKenzieFocusTime = () => {

    const editProfileClasses = useEditProfileStyles();
    const workLifeStyles=useWorkLifeStyles();

    const [currentSelectedDay, setCurrentSelectedDay]=useState("sun");

    const {
        setFieldValue,
        values
    }=useFormikContext();

    /** HANDLE TIME SLOT CLICK EVENT */
    const handleOnTimeSlotClick=(slot)=>{
        try {
            let focusTime=values.user_focus_time?{...values.user_focus_time}:{};
            
            if((currentSelectedDay in focusTime) && focusTime[currentSelectedDay].find(i=>i.id===slot.id)){
                let indx=focusTime[currentSelectedDay].findIndex(i=>i.id===slot.id);
                focusTime[currentSelectedDay].splice(indx,1);
                
            }else if((currentSelectedDay in focusTime) && !focusTime[currentSelectedDay].find(i=>i.id===slot.id)){
                focusTime[currentSelectedDay].push(slot);
                
            }else{
                focusTime[currentSelectedDay]=[];
                focusTime[currentSelectedDay].push(slot);
            }

            setFieldValue('user_focus_time',focusTime);

        } catch (error) {
            console.error(error);
        }
    }

    return (<div className={editProfileClasses.formSection} style={{marginTop: 0}}>
        <Grid className={editProfileClasses.control} item xs={12} md={12}>
            <div className='mt-3'>
                <div className={workLifeStyles.focusTimeDays}>
                    {
                        DAYS.map(day => {
                            return <div className={`each-day ${currentSelectedDay===day.id?"each-day-selected":undefined}`} 
                                    key={uuid4()} 
                                    onClick={()=>{setCurrentSelectedDay(day.id)}}
                                    data-day={day.id}>
                                <div className='open-sans'>{day.abbr}</div>
                                {
                                    values.user_focus_time && values.user_focus_time[day.id] && values.user_focus_time[day.id].length>0?
                                    <div className='entry-indicator'></div>:
                                    null
                                }
                            </div>
                        })
                    }
                </div>
            </div>

            <div className='mt-3'>
                <div className={workLifeStyles.focusTimeContainer}>

                    {
                        currentSelectedDay.length > 0 ?
                            <div className='focus-time-outer-container' 
                                key={uuid4()}
                                data-day={currentSelectedDay}>
                                {
                                    TIME_SLOTS.map(slot => {
                                        return <div key={uuid4()} className='each-focus-time-container' data-slotid={slot.id}>
                                            <LearnerKenzieFocusTimeSlots
                                                    currentSelectedDay={currentSelectedDay}
                                                    handleOnClick={handleOnTimeSlotClick}
                                                    slot={slot} />
                                        </div>
                                    })
                                }

                            </div> :
                            null
                    }
                    
                </div>
            </div>

        </Grid>
    </div>)
}