import { makeStyles } from "@mui/styles";
import { useFormikContext } from 'formik';
import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QUILL_MODULES = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
    ]
}

const useStyles = makeStyles(theme => ({
    richTextEditorContainer: {
        '& .ql-toolbar':{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px"
        },
        '& .ql-container':{
            minHeight:"100px !important",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px"
        }
    }

}))

const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image'
    // 'video'
  ];

const AnnouncementRichText=({
    name,
    id,
    placeholderText,
})=>{

    const classes=useStyles();
    const {
        values, 
        setFieldValue,
    }=useFormikContext();
    return (
        <div data-text-editor="form-editor" draggable={false} onDrop={(e) => {e.preventDefault()}}>
            <ReactQuill 
                className={classes.richTextEditorContainer}
                onChange={(content, delta, source, editor)=>{
                    setFieldValue("body", content)
                }}
                style={{backgroundColor:"white"}}
                modules={QUILL_MODULES}
                theme="snow"
                name={name}
                id={id}
                placeholder={placeholderText}
                defaultValue={values.body}
                preserveWhitespace
                bounds={`[data-text-editor="form-editor"]`}
                formats={formats}
                />
        </div>
    )
}

export default AnnouncementRichText;