import { useLocation } from "react-router-dom";
import GroupedAvatars from "./GroupedAvatars";
import useStyles from "./styles";
const JoinSession = ({
    learnerParticipants = [],
    staffParticipants = [],
    guestParticipants = [],
    cohortInfo,
    setShowParticipantModal
}) => {

    const classes = useStyles();

    const location = useLocation();

    return (
        <div className={classes.sessionContainer}>
            {location.pathname === "/connect" && <div className={classes.connectText}>
                Some of your classmates are in a study session. Join in to collaborate on the assignments for this week.</div>
            }
            <div >
                {staffParticipants.length > 0 &&
                    <div>
                        <div className={classes.subtitle}>Staff in Session:</div>
                        <div onClick={() => setShowParticipantModal(true)}
                            className={classes.studySession}>
                            <GroupedAvatars
                                sessionParticipants={staffParticipants} />
                        </div>
                    </div>
                }
                {(learnerParticipants.length > 0 || guestParticipants.length > 0) &&
                    <div>
                        <div className={classes.subtitle}>Classmates in Session:</div>
                        <div onClick={() => setShowParticipantModal(true)} className={classes.studySession}>
                            <GroupedAvatars
                                sessionParticipants={learnerParticipants.concat(guestParticipants)} />
                        </div>
                    </div>
                }
            </div>

            <button className={`${classes.sessionButton} ${classes.eventButton}`} variant="outlined" onClick={() => setShowParticipantModal(true)}>Join Now</button>

        </div>
    )
}

export default JoinSession;