import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useContext } from 'react';
import { ACPSupportRequestsContext } from '../../context';

export default function SortRequests() {

	const {
        sortDirection,
        setSortDirection
	} = useContext(ACPSupportRequestsContext);

    const handleOnClick=()=>{
        if(sortDirection === "oldest" ){
            setSortDirection("newest")
        }else{
            setSortDirection("oldest")
        }
    }

	return (
		<div className='subItems' 
            style={{
                display:'flex',
                alignItems:"center",
                cursor:"pointer"
            }}
            onClick={()=>{handleOnClick()}}>
            {
                sortDirection==='newest'?
                <>
                    <span>Most Recent</span>
                    <ArrowDropUpIcon />
                </>
                :
                <>
                    <span>Oldest</span>
                    <ArrowDropDownIcon />
                </>
            }
        </div>
	)
}