import ClickAwayListener from "@mui/material/ClickAwayListener";


const Modal = ({ children, width, height, minHeight, handleClose, styles }) => {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        minWidth: "100vw",
        minHeight: "100vh",
        overflow: "hidden",
        backgroundColor: "rgba(0,0,0, .59)",
        position: "fixed",
        display: 'flex',
        top: 0,
        left: 0,
        zIndex: 97
      }}
    >
      <ClickAwayListener
        onClickAway={handleClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart">
        <div
          style={{
            overflow: 'hidden',
            width: width || "650px",
            height: height || "200px",
            minHeight: minHeight || undefined,
            backgroundColor: "white",
            padding: "30px",
            zIndex: 98,
            margin: 'auto',
            // position: "absolute",
            // top: height ? `calc(50vh - ${height}/2)` : "calc(50vh - 250px/2)",
            // right: width ? `calc(50vw - ${width}/2  )` : "calc(50vw - 650px/2)",
            ...styles
          }}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
