import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal } from "@mui/material";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppDataContext } from '../../../context';
import profileIllistartion from "../../../images/profileIllistration.png";
import useProfilePopupStyles from './profilePopupStyle';

const ProfilePopup = (props) => {
    const classes = useProfilePopupStyles()
    const [isOpen, setIsOpen] = React.useState(false)
    const {userData,isAllComponentsLoaded} = React.useContext(AppDataContext)

    React.useEffect(() => {
        if(userData){
            if(!userData.user_profile_updated_date && localStorage.getItem("learnerProfileCompletionDismissed") === "false") {
                setIsOpen(true);
            }
        }
    }, [userData]);

    const handleClose = () => {
        localStorage.setItem("learnerProfileCompletionDismissed",true)
        setIsOpen(false)
    }
    //;
    return (
        <Modal
            open={isAllComponentsLoaded && isOpen}
            onClose={handleClose}
            classes={{root: classes.centerModal}}
        >
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <p className={classes.title}>Create a MyKenzie Profile</p>
                    <IconButton onClick={() => {handleClose()}}>
                        <CloseIcon sx={{fontSize:"20px"}} />
                    </IconButton>
                </div>
                <img src={profileIllistartion} className={classes.image} alt="Connect Illistration"/>
                <p className={classes.subtitle}>Your cohort wants to know more about you!</p>
                <p className={classes.action}>Fill out your profile and get the most out of the new <strong>Kenzie Connect</strong></p>
                <Link to="/profile/learner/editprofile">
                    <Button variant="contained" classes={{ root: classes.createProfile }} 
                    onClick={() => {handleClose()}}>Create Profile</Button>
                </Link>
            </div>
        </Modal>
    )
}

export default ProfilePopup