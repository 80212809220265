import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    /**
     * Course color code styles (courses panel)
     */
    courseColorCode: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px"
    },
    courseColorBubble: {
        width: "10px",
        height: "10px",
        border: "2px solid #FFFFFF",
        borderRadius: "50%",
    },
    courseName: {
        ...theme.typography.fontFamily,
        ...theme.typography.body1,
        fontWeight: 500,
        marginLeft: "6px"
    },
    inactiveCourse: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        opacity: .4
    },
    
    /**
     * Assignment Card
     */
    assignmentCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "1.5px solid #E6E6E6",
        borderRadius: "10px",
        boxSizing: "border-box",
        padding: "10px 20px 10px 20px",
        backgroundColor: "#F9F9F9",
        marginBottom: "15px",
        '&:hover': {
            cursor: "pointer"
        },
        transition: 'height .5s ease-in-out',
    },
    assignmentLinks: {
        marginBottom: "0px",
    },
    assignmentContent: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('md')]: {
            marginTop: "5px",
            flexDirection: "column"
        }
    },

    dueDate: {
        ...theme.elements.bodyText,
        color: theme.colors.onyx,
        height: '20px',
        marginTop: "5px",
        marginBottom: "0px"
    },

    doAssignmentBtn: {
        width: "145px",
        height: "40px",
        backgroundColor: `${theme.MyKenzie.actionColors.blueButton} !important`,
        borderRadius: "50px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 18px 20px 22px !important",
        '& MuiButton-root': {
            width: "100%"
        },
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
        boxShadow: "1px 3px 6px rgb(160,160,160) !important"
    },
    viewSubmissionBtn: {
        width: "160px",
        height: "40px",
        backgroundColor: "transparent !important",
        border: "2px solid #1D3969 !important",
        borderRadius: "50px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 18px 20px 22px !important",
        '& MuiButton-root': {
            width: "100%"
        },
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
        boxShadow: "1px 3px 6px rgb(160,160,160) !important"
    },
    doAssignmentCta: {
        [theme.breakpoints.down('md')]: {
            marginTop: "15px",
            marginBottom: "10px"
        }
    },
    doAssignmentBtnContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& p': {
            display: "flex",
            alignItems: "center",
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Nunito",
            height: "34px",
            textTransform: "none !important"
        },
    },
    viewSubmissionBtnContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& p': {
            display: "flex",
            alignItems: "center",
            color: `${theme.colors.deepBlue} !important`,
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Nunito",
            height: "34px",
            textTransform: "none !important"
        }
    },
    btnIcon: {
        height: "14px",
        width: "14px",
        marginRight: "5px"
    },
    assignmentStatus: {
        display: "flex",
        alignItems: "center"
    },
    statusCompleted: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "15px",
        width: "15px",
        border: "1px solid #1D3969",
        borderRadius: "50%",
        marginRight: "10px",
        backgroundColor: "#14CC3C"
    },
    statusIncomplete: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "15px",
        width: "15px",
        border: "1px solid #1D3969",
        borderRadius: "50%",
        marginRight: "10px",
    },
    statusPastDue: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "15px",
        width: "15px",
        border: "1px solid #1D3969",
        borderRadius: "50%",
        marginRight: "10px",
        backgroundColor: "#C2573F",
    },
    statusTransition: {
        animationName: "$statusExpanded",
        animationDuration: "210ms",
        animationFillMode: "forwards",
        animationIterationCount: 1
    },
    '@keyframes statusExpanded': {
        '0%': {
            height: "0px",
        },
        '100%':{
            height: "15px",
        }
    },
    statusLabelGroup: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "1px",
        '& p': {
            color: "#1D3969",
            fontFamily: "Nunito",
            fontSize: "12px",
            fontWeight: 600
        }
    },
    statusLabelTransition: {
        animationName: "$statusLabelTransition",
        animationDuration: "250ms",
        animationFillMode: "forwards",
        animationIterationCount: 1
    },
    '@keyframes statusLabelTransition': {
        '0%': {
            height: "0px",
        },
        '100%':{
            height: "15px",
        }
    },


    /**
     * Courses Panel
     */
     contentWrapper: {
        alignItems: "center",
        // backgroundColor: "#E4EEFF",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px"
    },
    header: {
        background: theme.MyKenzie.cardColors.secondaryHighlight,
        padding: "20px 50px 0px 23px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        fontFamily: "Nunito",
        [theme.breakpoints.down("900")]: {
            padding: "20px 23px 0px 23px",
        },
    },
    topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // width: "100%",
        marginBottom: "8px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "10px"
        }
    },
    courseTitle: {
        ...theme.elements.title,
        margin: 0,
        height: "30px"
    },
    courseSubTitle: {
        ...theme.elements.subtitle,
        margin: 0,
    },
    viewCourse: {
        ...theme.elements.darkBlueButton,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginBottom: "42px !important",
        },
        padding: "20px 18px 20px 22px !important",
        boxShadow: "1px 3px 6px rgb(160,160,160) !important"
    },
    canvasLogo: {
        paddingRight: "10px"
    },
    sectionTitle: {
        ...theme.elements.subtitle,
        margin: 0,
        height: "23px",
        marginBottom: "10px",
        color: theme.colors.onyx
    },
    
    selectorWrap: {
        display: "flex",
        margin: "-8px 0px",
        backgroundColor: "#E4EEFF"
    },
    selector: {
        ...theme.elements.subtitle,
        width: "373.5px",
        height: "47px",
        lineHeight: '47px',
        textAlign: 'center',
        color: theme.MyKenzie.actionColors.blueButton,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selected: {
        background: theme.colors.white,
        border: "0px solid",
        borderRadius: "4px 4px 0px 0px"
    },
    selectorDisabled: {
        color: "rgba(0, 0, 0, 0.12)"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: "30px 25px 5px 25px",
        backgroundColor: "#FFFFFF",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        fontFamily: "Nunito"
    },
    upcomingItem: {
        ...theme.elements.subtitle,
        lineHeight: "20px",
        height: '20px',
        color: "#007E86",
        fontFamily: "Nunito"
        // marginBottom: "25px"
    },
    skeletonUpcomingItem: {
        marginBottom: "18px"
    },
    pastDueItem: {
        marginBottom: 0
    },
    itemTitle: {
        marginTop: 0
    },
    noUpcomingItem: {
        height: "40px",
        lineHeight: "40px"
    },
    completedOn: {
        ...theme.elements.bodyText,
        color: theme.colors.onyx,
        height: '20px',
        margin: 0
    },
    completedOnBlue: {
        color: theme.MyKenzie.actionColors.blueButton,
    },
    grade: {
        ...theme.elements.bodyText,
        color: theme.colors.onyx,
        height: '20px',
        margin: '0px 0px 25px 0px'
    },
    pastDueWrap: {
        marginBottom: "25px"
    },
    courseColorKey: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        height: "66px",
        columnGap: "20px"
    },

}))