import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        alignItems: "center",
        ...theme.elements.subtitle,
        fontWeight: 600,
        lineHeight: "21px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px 8px 8px 8px",
        paddingBottom: "5px"
    },
    topRow: {
        padding: "15px 0 15px 25px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        color: "inherit",
        backgroundColor: "#E0FBFF",
        borderRadius: "4px",
    },
    sessionContainer: {
        padding: "20px 15px 10px 15px",

    },
    subtitle: {
        ...theme.elements.subtitle,
        fontWeight: "600",
        color: theme.colors.deepAqua
    },
    studySession: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0 20px 0",
        "&:hover": {
            cursor: "pointer"
        }
    },
    sessionText: {
        ...theme.elements.bodyText,
        color: "#5B5B5B",
        lineHeight: "18px",
        fontWeight: "600",
        padding: "15px 0"
    },
    avatarCollection: {
        backgroundColor: "red"
    },
    horizontalContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    connectText: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "25px"
    },
    /** Event Buttons */
    eventButton: {
        textTransform: "none",
        borderRadius: "34px",
        height: "35px",
        minWidth: "128px",
        fontSize: "12px",
        fontWeight: 700,
        padding: "0px 10px",
        cursor: "pointer",
        width: "100%",
        margin: "0 10px 20px 0px",
        [theme.breakpoints.up("sm")]: {
            minWidth: "128px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        '& .button-title': {
            marginLeft: "5px"
        }
    },
    sessionButton: {
        backgroundColor: "white",
        color: theme.colors.deepAqua,
        border: "2.5px solid " + theme.colors.deepAqua,
        '&:disabled': {
            opacity: 0.5,
            cursor: "not-allowed",
        },
        boxShadow: "1px 3px 6px rgb(180,180,180)"
    },
    row: {
        padding: "11px",
        display: "flex",
        justifyContent: "space-around",
        color: "#5B5B5B",
        backgroundColor: "#E0FBFF"
    },
    block: {
        backgroundColor: "white",
        height: "58px",
        width: "131px",
        textAlign: "center"
    },
    counts: {
        color: theme.colors.onyx,
        fontFamily: "Open Sans",
        fontSize: "18px",
        lineHeight: "27px"
    },
    logo: {
        height: "15px",
        width: "15px",
        marginRight: "10px"
    },
    subcontainer: {
        padding: "0 12px"
    },
    link: {
        color: "inherit",
    },
    Image: {
        position: "relative",
        zIndex: -1,
        left: "50%",
        transform: "translateX(-50%)",
        height: "auto",
        width: "35px",
        borderRadius: "50%"
    },
    profile: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        paddingLeft: "15px",
        height: "54px",
        width: "100%",
        borderRadius: "4px",
        '&:hover': {
            position: "relative",
            zIndex: 0,
            backgroundColor: "#E0FBFF",
            cursor: "pointer"
        },
    },
    profileImage: {
        boxSizing: "border-box",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        border: `2.5px solid #63C29D`,
    },
    profileContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "200px",
    },
    namePtag: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "0px 0px 0px 15px",
        fontSize: '16px',
        height: "20px",
        lineHeight: "16px",
        ...theme.MyKenzie.text.contentTitle
    },
    timezonePtag: {
        width: "100%",
        margin: "0px 0px 0px 15px",
        height: "20px",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: "300",
        ...theme.MyKenzie.text.contentSubtitle

    },
    studentAvatar: {
        border: "2.5px solid " + theme.colors.deepAqua + "!important",
    },
    staffAvatar: {
        border: "2.5px solid " + theme.colors.deepBlue + "!important",
    }
}));
export default useStyles;