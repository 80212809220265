import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';

import { AssociatedCoursesDataContext } from '.';
import { ACPDataContext } from '../../../context';
import CourseDeletionWarning from './CourseDeletionWarning';
import { useStyles } from './styles';

const EachCourse = ({
    editMode = false,
    course
}) => {

    const courseManagementClasses = useStyles();

    let { courses, setCourses } = useContext(AssociatedCoursesDataContext);
    const { selectedACPSettingsCohortId } = useContext(ACPDataContext);

    const [showDeleteConfirmaton, setShowDeletionConfirmaton] = useState(false);

    const handleDelete = (course) => {
        try {
            if (course?.isNew) {
                //remove without confirmation
                let d = [...courses];
                let index = d.findIndex(i => i.lms_course_id === course.lms_course_id);
                d.splice(index, 1);
                setCourses(d);
            } else {
                //Show the confirmation box
                setShowDeletionConfirmaton(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div key={course.id} className={courseManagementClasses.eachCourseContainer}>
                <div className='course-name'>[{course.subscribed_canvas ? "New LMS:" : "Old LMS:"}{course.lms_course_id}] {course.coursename}</div>
                {/* Delete Button */}
                <div style={{ marginLeft: "8px" }}>
                    {
                        editMode && <IconButton
                            className='delete-cohort_course'
                            data-testid={`delete-cohort_course-${course.lms_course_id}`}
                            onClick={() => { handleDelete(course) }}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </div>
            </div>
            {
                showDeleteConfirmaton && <CourseDeletionWarning
                    modalOpen={showDeleteConfirmaton}
                    courseInfo={course}
                    cohortId={selectedACPSettingsCohortId[0]}
                    handleCloseCallback={() => { setShowDeletionConfirmaton(false) }}
                />
            }
        </>

    )

}

export default EachCourse;