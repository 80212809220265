import React from 'react';
import useStyles from './styles';
import { Button, Container, useTheme } from "@mui/material"
import { ReactComponent as MykenzieLogo } from '../../images/my-kenzie-logo.svg'
import { useAuth0 } from '@auth0/auth0-react';

const LandingPage = () => {

    const classes = useStyles();
    const theme = useTheme();

    const { loginWithRedirect } = useAuth0();

    const login = async () => {
        const pathSplit = window.location.pathname.split("/")
        const options = window.location.pathname.toLowerCase().includes("listrequests") ? { appState: { targetCourse: pathSplit[pathSplit.length - 1] } } : { appState: { targetUrl: window.location.pathname } }
        await loginWithRedirect(options);
    }

    return (
        <Container sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                height: "auto"
            }
        }}>
            <div className={classes.landingPageContent}>
                <div className={`${classes.landingPageCTA} ${classes.leftSlide}`}>
                    <div className={classes.mkLogo}>
                        <MykenzieLogo />
                    </div>
                    <Button variant="contained" className={classes.loginBtn} onClick={() => login()} >Log In</Button>
                    <div className={classes.itSupportText}>
                        <p>Having trouble? Kenzie IT Support can help!</p>
                        <p><a href="mailto:helpdesk@kenzie.snhu.edu">helpdesk@kenzie.snhu.edu</a></p>
                    </div>
                </div>
                <div className={`${classes.landingPageVideo} ${classes.rightSlide}`}>
                    <video autoPlay loop muted>
                        <source src="https://mykenzie-api-191117102745-uploads-production.s3.us-east-2.amazonaws.com/uploads/assets/Kenzie+Landing+Page+Video.mp4" type='video/mp4' />
                    </video>
                </div>
            </div>
        </Container>
    )
}

export default LandingPage;