import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, IconButton, MenuItem, Select } from '@mui/material';
import React, { useContext, useState } from 'react';

import { CohortStaffDataContext } from '.';

import { useCohortSettingStyles } from '../styles';
import StaffMemberProfile from './StaffMemberProfile';
import { useStyles } from './styles';

import userRoles from '../../../../helpers/userRoles';
import { UserSearchField } from './UserSearchField';

import { v4 as uuid4 } from 'uuid';
import { CohortSettingsContext } from '..';
import { getInstructorsByCohortId, updateCohortStaffMember, updateCohortStaffMembers } from '../../../../api';

const EditStaffMembersForm = ({
    staff = [],
    handleOnClose = function () { },
    cohortId = null,
    courseId = null
}) => {

    const classes = useStyles();
    const cohortSettingClasses=useCohortSettingStyles();

    const { 
        updateCohortStaffMembersState, 
        removeStaffFromCohort, 
        massageStaffData
    } = useContext(CohortStaffDataContext);

    const { 
        setSubmissionSuccessfull,
        setSubmissionSuccessMessage,
        setSubmissionError,
        setSubmissionErrorMessage
    }=useContext(CohortSettingsContext)

    const [staffValues, setStaffValues] = useState([...staff]);

    let [isSubmitting, setIsSubmitting] = useState(false);

    let [isDeleting, setIsDeleting] = useState(false);
    let [memberIdBeingDeleted, setMemberIdBeingDeleted]=useState(null);

    //On Role change
    const handleOnChange = (data, isNew = false) => {
        let d = [...staffValues];
        if (!isNew) {
            let indx = d.findIndex(i => i.user_email === data.user_email);
            d[indx] = data
        } else {
            d.push(data);
        }
        //;
        setStaffValues(d);
    }

    //Triggered when cave button is clicked
    const handleSubmission = async (e) => {
        try {
            e.preventDefault();

            //validate that all userRoles are selected 
            let checkRolesEntered = staffValues.filter(i => i.user_role_id === 0);

            if (checkRolesEntered.length > 0) throw new Error("roles not entered");

            setIsSubmitting(true);

            let payload = staffValues.reduce((acc, ci) => {
                //deconsturt and remove user_email_mk_user
                let { user_email_mk_user, ...rest } = ci;
                acc.push(Object.assign(rest, {
                    "first_name": user_email_mk_user.first_name,
                    "last_name": user_email_mk_user.last_name,
                    "email": user_email_mk_user.email,
                    "username": user_email_mk_user.username,
                }));
                return acc;
            }, []);

            //;

            //Send updates to server 
            await updateCohortStaffMembers(cohortId, payload);

            //Get the information from server again
            //This is done to invalidate the cache in front and store new info
            //also multiple staff members can be updates simultaneouly, it makes it little easier to grab entire payload in this case
            let response = await getInstructorsByCohortId(cohortId);

            //filters out all the users who are not supposed to be displayed in ACP
            response = massageStaffData(response);

            updateCohortStaffMembersState(response);

            handleOnClose();

            setIsSubmitting(false);
            setSubmissionSuccessfull(true);

        } catch (error) {
            console.error(error);
            if (error.message === "roles not entered") {
                setSubmissionErrorMessage("Please select all roles");
            } else {
                setSubmissionErrorMessage("There was an error submitting info");
            }
            setSubmissionError(true);

        }finally{
            setIsSubmitting(false);
        }
    }

    //Deletion deactivates user in the database 
    //If they needed to be added in the system 
    const handleDelete=async (member)=>{
        try {

            setMemberIdBeingDeleted(member.id);
            setIsDeleting(true);

            if('id'  in member){
                //deactivate in server 
                let v={
                    "active":false,
                    "user_email":member.user_email,
                    "cohort_id":member.cohort_id,
                    "course_id":member.course_id,
                    "id":member.id
                }

                await updateCohortStaffMember(cohortId,v);

                setStaffValuesOnDeletion(member);
                removeStaffFromCohort(v);    
                
                setIsDeleting(false);

                setSubmissionSuccessMessage("The staff member was deleted successfully");
                setSubmissionSuccessfull(true);
                    
            }else{
                setStaffValuesOnDeletion(member);
            }
            
        } catch (error) {
            console.error(error);

            setIsDeleting(false);

            setSubmissionErrorMessage("Error in deleting the staff");
            setSubmissionError(true);

        }finally{
            setMemberIdBeingDeleted(null);
        }
    }

    const setStaffValuesOnDeletion = (member) => {
        let d = [...staffValues];
        let indx = d.findIndex(i => i.user_email === member.user_email);
        if (indx > -1) {
            d.splice(indx, 1);

            //Remove the item from the state variable 
            setStaffValues(d);
        }
    }

    return (
        <div>
            <div className='lineHt-md'>
                <UserSearchField
                    onUserSelection={handleOnChange}
                    cohortId={cohortId}
                    courseId={courseId}
                    staff={staffValues} />
            </div>
            {
                staffValues.map(member => {
                    return <div
                        className={`${cohortSettingClasses.divider}`}
                        key={uuid4()}>
                        <div className={classes.eachStaffMember}>
                            <StaffMemberProfile member={member} />

                            {/* Role Drop Down Field */}
                            <div style={{
                                display:"flex",
                                alignItems:"center"
                            }}>
                                <div>
                                    <Select
                                        onChange={(e) => {
                                            handleOnChange(Object.assign({ ...member }, {
                                                "user_role_id": parseInt(e.target.value)
                                            }))
                                        }}
                                        name="user_role_id"
                                        value={member && member?.user_role_id ? member.user_role_id : "0"}
                                        variant="outlined">
                                        <MenuItem
                                            value="0"
                                            disabled>
                                            -- Select the Role --
                                        </MenuItem>
                                        {
                                            userRoles.filter(i => i.isVisibleInACP === true).sort((a, b) => { return a.uiName > b.uiName ? 1 : -1 }).map(item =>
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.uiName}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </div>

                                {/* Delete Button */}
                                <div style={{ marginLeft: "8px" }}>
                                    {
                                        isDeleting && memberIdBeingDeleted && memberIdBeingDeleted===member.id?
                                        <CircularProgress color="primary" size={18} /> :
                                        <IconButton 
                                            className='delete-staff-member'
                                            data-testid={`delete-staff-member-${member.id}`}
                                            onClick={() => { handleDelete(member) }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                })
            }

            {/* Form Buttons */}
            <div className={cohortSettingClasses.formFooter}>
                <div>
                    <Button
                        onClick={() => { handleOnClose() }}
                        variant="text"
                        className={cohortSettingClasses.textButton}>
                        Cancel
                    </Button>
                </div>
                <div className={cohortSettingClasses.submitButton}
                    id={`update-staff-member-submit-button-${cohortId}`}
                    data-testid={`update-staff-member-submit-button-${cohortId}`} >
                    <Button onClick={(e) => {
                        handleSubmission(e)
                    }} disabled={isSubmitting}>
                        {
                            isSubmitting?
                            <CircularProgress color="inherit" size={18} /> :
                            "Save Changes"
                        }
                    </Button>
                </div>

            </div>

        </div>)

}

export default EditStaffMembersForm;