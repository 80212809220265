import React, { useEffect, useState } from "react";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Tooltip } from "@mui/material";

const FullScreen = ({
    videoRef
}) => {

    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        let controller = new AbortController();

        //the state is set per the event handler
        document.addEventListener('fullscreenchange', function () {
            if (document.fullscreenElement) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        }, { signal: controller.signal });

        return (() => {
            controller.abort();
        });

    }, [])

    /** Full Screen Handlers */
    const handleExitFullScreenMode = () => {

        if (document.exitFullscreen) {
            document.exitFullscreen();

        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();

        } else if (document.webkitExitFullscreen) {/* Safari */
            document.webkitExitFullscreen();

        } else if (document.webkitExitFullScreen) {/* Safari */
            document.webkitExitFullScreen();

        } else if (document.msExitFullscreen) {/* IE11 */
            document.msExitFullscreen();
        }
    };

    /** Handle full screen  */
    const handleRequestFullScreenMode = (event) => {
        //Fillscreen the parent element, NOT the video
        let parentNode = event.target.closest('#video-container-parent-tag');
        let videoEl = parentNode.querySelector('video') || videoRef.current;

        if (parentNode.requestFullscreen) {
            parentNode.requestFullscreen();//others 

        } else if (parentNode.webkitRequestFullscreen) { /* Safari */
            parentNode.webkitRequestFullscreen();

        } else if (parentNode.webkitRequestFullScreen) { /* Safari */
            parentNode.webkitRequestFullScreen();

        } else if (parentNode.webkitEnterFullScreen) {/* Safari */
            parentNode.webkitEnterFullScreen();

        } else if (parentNode.mozRequestFullScreen) {
            parentNode.mozRequestFullScreen();

        } else if (parentNode.msRequestFullscreen) { /* IE11 */
            parentNode.msRequestFullscreen();

        } else {
            //If none of the above methods works
            //call the fulle screen on the video element
            //This will open video in full screen, but will have the browser default controls not our custom video controls
            let fullScreenFunc = videoEl.webkitEnterFullScreen
                || videoEl.webkitRequestFullscreen
                || videoEl.webkitRequestFullScreen
                || videoEl.requestFullscreen
                || videoEl.mozRequestFullScreen;

            fullScreenFunc.call(videoEl);
        }
    };

    return (
        <Tooltip title='Full Screen'>
            {
                isFullScreen ?
                    <FullscreenExitIcon onClick={handleExitFullScreenMode} fontSize="medium" /> :
                    <FullscreenIcon onClick={handleRequestFullScreenMode} fontSize="medium" />
            }
        </Tooltip>
    );
};

export default FullScreen;
