import axios from 'axios';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { LocationTextField } from './LocationTextField';

//This import must be changed to more common style
import useLoadGooglePlaceServicesApi from '../../../../sharedComponents/editProfileFields/location/useLoadGooglePlaceServicesApi';

export function TimeZone({
    location = null,
    controlId,
    controlName,
    locationFieldControlId
}) {

    let {
        values,
        setFieldValue
    }=useFormikContext();

    const placesService=useLoadGooglePlaceServicesApi(controlId);

    let [timeZone, setTimeZone]=useState(values[controlId]?values[controlId].time_zone_name:null);
    let [utcOffset, setUtcOffset]=useState(values[controlId]?values[controlId].utc_offset_minutes:null);

    useEffect(()=>{

        if(location!==null && window.google && placesService){
     
            placesService.getDetails({
                "placeId":location.place_id
            }, async (response)=>{
              
                let lat=response.geometry.location.lat();
                let lng=response.geometry.location.lng();

                let zone=await axios({
                    method:"get",
                    url:`https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=1331161200&key=AIzaSyC9wJ8WRK_f-zIQjKiBFkXR4zf6khyvuPk`,
                    headers: {}
                });

                if(zone.data){
                    let d={
                        "time_zone_id":zone.data.timeZoneId,
                        "time_zone_name": zone.data.timeZoneName,
                        "utc_offset_minutes":response.utc_offset_minutes
                    }

                    setUtcOffset(response.utc_offset_minutes);
                    setTimeZone(zone.data.timeZoneName);

                    setFieldValue(controlId,{...d});//setting TimeZone field in 
                    
                    //Setting lat and lng in location field 
                    setFieldValue(locationFieldControlId,
                        Object.assign(values[locationFieldControlId],{
                            "lat":lat,
                            "lng":lng
                    }))

                }else{
                    setFieldValue(controlId,null);
                }
            })
        }else if (location===null && window.google && placesService){
            setTimeZone(null);
            setUtcOffset(null);
        }
    },[location]);

    return (
            <LocationTextField
                disabled
                name={controlName}
                id={controlId}
                onChange={()=>{return null}}
                value={timeZone && utcOffset?`${timeZone} GMT ${utcOffset/60}`:""}
                placeholder='Select location for the timezone'
                variant="standard" InputProps={{ disableUnderline: true }}
                data-lpignore="true"/>
    );
}