import React, { useContext } from 'react';
import LanguageIcon from '../../icons/profile-languages.png'

import useStyles from './styles';

import { UserProfileContext } from './UserProfileContext';

const UserLanguages = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    /** 
     * Format the sentence structure based on the primary and (if any) additional languages spoken
     * If both primary language and secondary languages are not entered. User should not see languages
     * Assumption: If no primary language is entered, or if only primary is selected, its assumed to be english and no languages are displayed
     */
    const languageListFormat = (primaryLanguage, additionalLanguages) => {
        if (primaryLanguage && additionalLanguages && additionalLanguages.length > 1) {
            return <p>I speak multiple languages: <span className={classes.subHeader}>{primaryLanguage} (Primary){additionalLanguages.map((language, index, arr) =>
                arr.length - 1 === index ? `, and ${language}` : `, ${language}`)}</span></p>
        } else if (primaryLanguage && additionalLanguages && additionalLanguages.length === 1) {
            return <p>I speak multiple languages: <span className={classes.subHeader}>{primaryLanguage} (Primary){additionalLanguages.map((language) =>
                ` and ${language}`)}</span></p>
        }else if (!primaryLanguage && additionalLanguages && additionalLanguages.length > 0) {
            if(additionalLanguages.length > 1) {
                return <p>I speak multiple languages: <span className={classes.subHeader}>{additionalLanguages.map((language, index, arr) =>
                    arr.length - 1 === index ? `and ${language}` : `${language}, `)}</span></p>
            }
            return <p>I speak: <span className={classes.subHeader}>{additionalLanguages[0]}</span></p>
        }
        return null
    };


    return (
        <>
            {
                profileData?.user_additional_spoken_languages?.length > 0 &&
                <div className={classes.topItems}>
                    <img id="user-profile-language-icon-image" src={LanguageIcon} alt="language icon" />
                    {languageListFormat(profileData.user_primary_spoken_language, profileData.user_additional_spoken_languages)}
                </div>
            }
            {
                !profileData?.user_additional_spoken_languages?.length && profileData.user_primary_spoken_language &&
                <div className={classes.topItems}>
                    <img id="user-profile-language-icon-image" src={LanguageIcon} alt="language icon" />
                    <p>I speak: <span className={classes.subHeader}>{profileData.user_primary_spoken_language}</span></p>
                </div>
            }
        </>

    )
}

export default UserLanguages;