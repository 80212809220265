import { Box, Button, CircularProgress, Menu } from '@mui/material';
import * as moment from 'moment';
import 'moment-duration-format'
import * as React from "react";
import { deleteAnnouncement } from "../../../api";
import { AppDataContext } from "../../../context";
import EditImg from "../../../images/edit.png";
import TrashImg from "../../../images/trash.png";
import HtmlParser from "../../../sharedComponents/announcement/HtmlParser";
import { ACPAnnouncementContext } from "../../context";
import { useStyles } from './styles';

import EditAnnouncementForm from "./EditAnnouncementForm";
import { getAbbreviatedPostedBy, getAudience, getDateValue, getPostedBy, getUserFullName } from './helpers';

const EachAnnouncement = ({ announcement, isOddIndex }) => {

    const classes = useStyles();

    const { userRole } = React.useContext(AppDataContext);
    const { removeAnnouncement } = React.useContext(ACPAnnouncementContext)

    const [announcementState, setAnnouncementState] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const [isDeleting, setIsDeleting] = React.useState(false)

    React.useEffect(async () => {
        getAnnouncementState()
    }, [])

    //** Delete Handler. Triggers when user clicks on delete button and confirms to delete */
    const handleDelete = async (e) => {
        handleClose()
        setIsDeleting(true);
        const res = await deleteAnnouncement(announcement["id"])
        if (res === "success") {
            removeAnnouncement(announcement.id);
        }
    }

    const getAnnouncementState = () => {

        let td = moment();

        let scheduled = moment(announcement.post_schedule);
        let expired = moment(announcement.post_expiration);

        let scheduledDiff = {
            days: td.diff(scheduled, "days"),
            hours: td.diff(scheduled, "hours"),
            minutes: td.diff(scheduled, "minutes")
        }

        let expiredDiff = {
            days: td.diff(expired, "days"),
            hours: td.diff(expired, "hours"),
            minutes: td.diff(expired, "minutes")
        }

        //;
        if (expiredDiff.hours > 0) {//Schedule date has passed 
            setAnnouncementState("Expired")

        } else if ((scheduledDiff.hours > 0 || scheduledDiff.minutes > 0) && !(scheduledDiff.days > 0)) {
            setAnnouncementState("Active")

        } else if ((scheduledDiff.days > 0 || scheduledDiff.hours <= 0)) {
            setAnnouncementState("Queued")
        }


    }

    return (
        <div className={classes.announcementBlock} style={{ backgroundColor: isOddIndex ? 'white' : '#F3F3F3' }}>

            <Box className={`raleway ${classes.announcement}`}>
                <HtmlParser announcementHistoryList={true}>{announcement.body}</HtmlParser>
                <div className={classes.secondaryInfo}>Posted by {getAbbreviatedPostedBy(announcement)} to {getAudience(announcement)} on {getDateValue(announcement.post_schedule, "MM/DD/YYYY")} at {getDateValue(announcement.post_schedule, "hh:mm a")}</div>
            </Box>

            <div className={`${classes.details} ${classes.announcementInfo}`}>
                <div className={`${classes.scheduled}`}>
                    Scheduled to post: <b>{getDateValue(announcement.post_schedule, "MMMM DD, hh:mm a")}</b>
                </div>
                <div className={`${classes.expires}`}>
                    Expires: <b>{getDateValue(announcement.post_expiration, "MMMM DD, hh:mm a")}</b>
                </div>

                <div className={classes.actions}>
                    {
                        announcementState !== "Expired" &&
                        <>
                            {
                                userRole?.acpPermissions?.functions?.annoucementsCanEditAnnoucements ?
                                    <img className={classes.icon} src={EditImg} alt="edit icon" onClick={handleOpen} /> :
                                    null
                            }

                            {!isDeleting && userRole?.acpPermissions?.functions?.annoucementsCanDeleteAnnoucements ?
                                <img className={classes.icon} style={{ opacity: .75 }} src={TrashImg} alt="delete icon" onClick={handleClick} /> :
                                null
                            }
                            {isDeleting && <CircularProgress style={{ width: "16px", height: "16px" }} />}
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                classes={{ paper: classes.menuPaper }}
                            >
                                <div className={classes.menu}>
                                    Are you sure?
                                    <Button sx={{
                                        textTransform: "capitalize",
                                        marginTop: "10px",
                                        width: "108px"
                                    }} size="medium" variant="contained" onClick={handleDelete} color="error">Delete</Button>
                                </div>
                            </Menu>
                        </>
                    }
                </div>
                <div className={` ${classes.announcementInfo} ${classes.created}`}>
                    Authored by: <b>{getUserFullName(announcement)}</b> posting as <b>{getPostedBy(announcement)}</b>
                </div>
                <div className={` ${classes.audience}`}>
                    Audience: <b>{getAudience(announcement)}</b> ({announcement.active_users} Learners)
                </div>
                <div className={`${classes.announcementInfo} ${classes.status}`}>
                    Status: <strong className={announcementState === "Queued" ? classes.queued : announcementState === "Active" ? classes.active : ""}>{announcementState}</strong>
                </div>
            </div>

            {
                modalOpen ?
                    <EditAnnouncementForm
                        modalOpen={modalOpen}
                        announcement={announcement}
                        handleCloseCallback={handleModalClose}
                    /> :
                    null
            }

        </div>
    )
};

export default EachAnnouncement;