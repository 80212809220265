import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    supportContainer: {
        width: "100%",
        borderRadius: "10px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "7.5px",
            marginBottom: "7.5px"
        },
        marginBottom: "20px",
        boxShadow: "3px 3px 6px rgb(160,160,160)"
    },
    titleBar: {
        width: "100%",
        height: "30px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        display: "flex",
        alignItems: "center",
        font: "14px Raleway, arial, sans-serif",
        fontWeight: 600,
        color: theme.MyKenzie.textColors.cardTitle
    },
        primaryTitleBar: {
            backgroundColor: theme.MyKenzie.cardColors.primary,
            fontFamily: "Nunito"
        },
        secondaryTitleBar: {
            backgroundColor: theme.MyKenzie.cardColors.secondary,
            fontFamily: "Nunito",
        },
        tertiaryTitleBar: {
            backgroundColor: theme.MyKenzie.cardColors.tertiary,
            fontFamily: "Nunito"
        },
        errorTitle: {
            backgroundColor: theme.MyKenzie.error.main
        },
    titleText: {
        marginLeft: "22px"
    },
    body: {
        borderRadius: "0px 0px 10px 10px",
        borderWidth: "0px 2px 2px 2px",
        borderColor: "#39857C",
        borderStyle: "solid",
    },
        primaryBody: {
            borderColor: theme.MyKenzie.cardColors.primary,
            backgroundColor: "#14606B"
        },
        secondaryBody: {
            borderColor: theme.MyKenzie.cardColors.secondary,
            backgroundColor: "#1D3969"
        },
        tertiaryBody: {
            borderColor: theme.MyKenzie.cardColors.tertiary,
            backgroundColor: "#C2573F"
        },
        errorBody: {
            borderColor: theme.MyKenzie.error.main
        },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export const Card = ({children, title, classes, error, theme}) => {
    const MUIclasses = useStyles();
    
    let titleBarClass;
    let bodyClass;
    switch (theme) {
        case "primary":
            titleBarClass = MUIclasses.primaryTitleBar
            bodyClass = MUIclasses.primaryBody
            break;
        case "secondary":
            titleBarClass = MUIclasses.secondaryTitleBar
            bodyClass = MUIclasses.secondaryBody
            break;
        case "tertiary":
            titleBarClass = MUIclasses.tertiaryTitleBar
            bodyClass = MUIclasses.tertiaryBody
            break;
        default:
            titleBarClass = MUIclasses.primaryTitleBar
    }

    return (
        <div className={MUIclasses.supportContainer}>
            <div className={`${MUIclasses.titleBar} ${error ? MUIclasses.errorTitle : titleBarClass}`}>
                <div className={MUIclasses.titleText}>
                    <p>{title}</p>
                </div>
            </div>
            <div className={`${MUIclasses.body} ${error ? MUIclasses.errorBody : bodyClass}`}>
                <div className={(classes && classes.content) ? classes.content : MUIclasses.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}