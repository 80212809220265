import { Avatar, AvatarGroup } from "@mui/material";
import useStyles from "./styles";

import { getUserDisplayName } from "../../../../helpers/utils";
import Image from '../../../../images/kenzieAvatar.png';

const GroupedAvatars = ({
    sessionParticipants = [],
    maxAvatars = 6,
    style,
    handleClick
}) => {

    const classes = useStyles();

    return (
        <AvatarGroup
            onClick={handleClick}
            sx={{
                ...style,
                marginTop: '8px',
                '& .MuiAvatar-root': { width: 32, height: 32, fontSize: 12, color: "#14606B", border: "2.5px solid #14606B" },
            }} max={maxAvatars}>
            {
                sessionParticipants.map(learner => (
                    <Avatar
                        key={learner.username}
                        sx={{ width: 32, height: 32, cursor: 'pointer' }}
                        className={Number(learner.user_role_id) === 1 ? classes.studentAvatar : classes.staffAvatar}
                        alt={getUserDisplayName(learner)}
                        src={learner?.user_image_url || Image} />
                ))
            }
        </AvatarGroup>
    )
}

export default GroupedAvatars;