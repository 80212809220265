import React from 'react';
import { useStyles } from './styles';

import {useGetUserCohortName} from '../../../sharedComponents/hooks/useGetUserCohortInfo'
import { getUserDisplayName } from '../../../helpers/utils';

export default function EachRequestAuthorAndSrc({
    request
}){

    const classes=useStyles();

    const cohortInfo=useGetUserCohortName(request.cohort_id);

    return(
        <div className={`text-normal ${classes.greyColor}`}>
            <span className='each-request-id-label'>ID - {request.id}</span>
            {
                request?.user_lm?
                <span> | {getUserDisplayName(request.user_lm)} {request.user_lm.last_name}</span>:
                null
            }
            {
                request?.source?
                <>
                <span> | </span>
                <span>
                    {
                        request.source==="support_hub"?
                            <a href={request.source_url} target="_blank" rel="noopener noreferrer">Canvas Support Hub</a>:
                        request.source==="my_kenzie"?
                            "MyKenzie":
                    ""
                    }
                </span>
                </>
                :
                null
            }
            {
                cohortInfo?
                <span> | {cohortInfo.cohort_name}</span>:
                null
            }
            
        </div>
    )
}