import React from 'react';

import { useStyles } from './styles';
import userRoles from '../../../../helpers/userRoles';
import StaffMemberProfile from './StaffMemberProfile';

const StaffMember = ({
    member=null
}) => {

    const classes = useStyles();

    return (
        <div>
           {
               member?
               <div className={classes.eachStaffMember}>
                        <StaffMemberProfile member={member}/>
                        <div>{userRoles.find(i=>i.id===member.user_role_id).uiName}</div>
                   </div>:
               null
           }
        </div>
    )

}

export default StaffMember;
