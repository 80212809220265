const filterForCourses = (userData) => {
    const enrollments = userData?.mk_user_courses
    const filteredEnrollments = [...new Map(enrollments.map(e => [e.cohort_id, e])).values()]
    return filteredEnrollments.map(user_course =>
        user_course?.cohort?.program_mappings
            ?.filter((mapping) => mapping?.is_active && mapping?.course?.is_active)
            .map(program_mapping => (
                {
                    course_id: program_mapping?.course?.lms_course_id,
                    cohort_id: program_mapping?.cohort_id,
                    course: program_mapping?.course,
                    user_role_id: user_course?.user_role_id
                }
            ))
    ).flat().sort((a, b) => a.course_id - b.course_id)
}

export {
    filterForCourses
}
