import React from 'react';
import { useStyles } from './styles';
import { courseColor } from './CourseColorPallete';

const CourseColorCode = ({ course, index, colorKey, hasAssignments }) => {
    
    const classes = useStyles();

    const findCourseName = (courseId) => {

        for(let key of colorKey) {
            if(key.courseId === courseId) {
                return key.courseName;
            }
        }
    }

    return (

        <div className={hasAssignments === true ? classes.courseColorCode : classes.inactiveCourse}>
            <div className={classes.courseColorBubble} style={{backgroundColor: courseColor(index)}}>

            </div>
            <div className={classes.courseName}>
                { findCourseName(course.course_id) }
            </div>
        </div>
    )
    
}

export default CourseColorCode;
