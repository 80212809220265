import { useFormikContext } from 'formik';
import React from 'react';

import { getFocusTimeImage } from '../../../sharedComponents/editProfileFields/focusTime/getImage';

const LearnerKenzieFocusTimeSlots = ({
    currentSelectedDay,
    handleOnClick,
    slot
}) => {
    
    const {
        values
    } = useFormikContext();
    
    const checkIfSlotSelected = () => {
        return values.user_focus_time && 
                values.user_focus_time[currentSelectedDay] && 
                values.user_focus_time[currentSelectedDay].find(i=>i.id===slot.id);
    }

    return (
        <div
            className={`each-focus-time open-sans ${checkIfSlotSelected() ? 'each-focus-time-selected' : ''}`}
            onClick={() => {
                handleOnClick(slot);
            }}
            data-slotid={slot.id}>
            <div className='each-focus-time-img-container'> <img src={getFocusTimeImage(slot)} alt="focus-time" /> </div>
            <div className='each-focus-time-header'>{slot.header}</div>
            <div className='each-focus-time-slot-name'>{slot.timeSlot}</div>
        </div>
    )


}

export default LearnerKenzieFocusTimeSlots;