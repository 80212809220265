import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    profileWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    profileImage: {
        display: "flex",
        justifyContent: "center",
        margin: "45px 0px 25px 0px"
    },
    profileGrid: {
        display: "flex",
        justifyContent: "center",
        marginTop: "54px",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            flexDirection: "column",
        }
    },
    profileGridLeft: {
        marginRight: "18px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "40px",
            marginRight: "0px",
        }
    },
    profileGridRight: {
        display: "flex",
        flexDirection: "column",
    },
    gridBoxRight: {
        marginBottom: "20px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "45px",
        }
    },
    demographics: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "-10px",
        [theme.breakpoints.down('md')]: {
            marginTop: "-5px",
        }
    },
    editProfilePill: {
        display: "flex",
        justifyContent: "center",
        marginTop: "42px",
        [theme.breakpoints.down('md')]: {
            marginTop: "30px"
        },
    },
    focusTime: {
        marginBottom: "120px"
    }
}))

export default useStyles