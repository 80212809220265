import React, { useEffect, useState } from "react";

import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { Tooltip } from "@mui/material";
import { browserDetector } from "../../../../../helpers/utils";
const PictureInPicture = ({
    videoRef
}) => {

    const [isSupported, setIsSupported] = useState(false);

    useEffect(() => {
        //Detects the browser user is using. 
        //PIP is not supported on any FireFox_* and Chrome_Andriod Browsers
        const browser = browserDetector();
        if ((/firefox/).test(browser) || browser === "chrome_android") {
            setIsSupported(false);
        } else {
            setIsSupported(true);
        }
    }, []);

    /** Picture in Picture */
    const handlePictureInPicture = () => {
        if (videoRef.current.requestPictureInPicture) {
            videoRef.current.requestPictureInPicture();
        }
    };

    return (
        <>
            {
                isSupported &&
                <Tooltip title="Picture in Picture Mode">
                    <PictureInPictureAltIcon fontSize="medium" onClick={handlePictureInPicture} />
                </Tooltip>

            }
        </>

    );
};

export default PictureInPicture;
