import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    '& .tabContainer-recenlty-viewed-videos': {
        '&.MuiTabPanel-root': {
            padding: 0
        },
        
    },
    '& .tabContainer-recenlty-added-videos': {
        '&.MuiTabPanel-root': {
            padding: 0
        },
        
    },
    tabContainerHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& p': {
            fontFamily: "Nunito",
            color: '#161A1B !important',
            fontWeight: 600,
            padding: "0px 0px 15px 12px",
            marginBottom: "0px"
        },
        padding: "0px 12px 0px 0px",
    },
    pageRootContainer: {
        // minHeight: '100vh',
        width: '100%',

        boxSizing: 'border-box',
        backgroundColor: '#F3F3F3',
        padding: '44px 5vw',
        paddingBottom: '5vh',

        '& .content': {
            maxWidth: '1200px',
            margin: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 3vw'
        }

    },
    carouselContainer: {
        padding: '0px 10px',

        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: '0',
            paddingBottom: '8px',
        },
        '& button': {
            display: "none"
        }
    },

    cardsContainer: {
        margin: '26px auto 0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gridTemplateRows: '1fr',
        columnGap: '15px',
        justifyItems: 'center',


    },
    card: {
        boxSizing: 'border-box',
        width: '280px',
        height: '350px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px',
        transition: 'box-shadow .2s',
        backgroundColor: 'white',

        [theme.breakpoints.up('md')]: {
            '&:hover': {
                boxShadow: '-2px 6px 12px rgba(0, 0, 0, 0.25)',
            },

        },
        [theme.breakpoints.down('sm')]: {
            width: '390px',
            height: '400px',
            margin: 'auto',
            marginBottom: '30px',
        },
        [theme.breakpoints.down('md')]: {

            width: '350px',
            height: '380px',
            margin: 'auto',
            marginBottom: '30px',
        }
    },
    thumbnailContainer: {
        boxSizing: 'border-box',

        height: '160px',
        width: '100%',
        position: 'relative',
        padding: '5px 5px 0 5px',
        borderRadius: '5px',
        color: 'white',

        [theme.breakpoints.down('md')]: {
            width: '100%',
            minHeight: '200px',
            boxSizing: 'border-box',
            padding: '10px'
        }
    },
    thumbnail: {
        width: '100%',
        height: '160px',
        objectFit: 'cover',
        borderRadius: '5px'
    },
    visIcon: {
        // padding: '0 4px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        marginTop: 'auto',
        '& .icon': {
            height: '22px',
            width: '30px'
        },
        '&.panel': {
            marginRight: '10px'


        },
        '&.absolute': {
            position: 'absolute',
            right: '72px',
            bottom: '11px',
            height: '17px',

            [theme.breakpoints.down('sm')]: {
                bottom: '15px',

            }
        },

    },
    durationContainer: {
        height: '17px',
        width: '50px',
        fontSize: '14px',
        padding: '2.5px 6px',
        backgroundColor: 'black',
        marginTop: 'auto',
        fontWeight: 800,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontFamily: 'Nunito',
        textAlign: 'center',
        '&.panel': {
            marginRight: '0px',
            fontSize: '14px',
            padding: '0 15px',
            height: '24px'
        },
        '&.absolute': {
            position: 'absolute',
            right: '10px',
            bottom: '8.5px',
            [theme.breakpoints.down('sm')]: {
                bottom: '15px',

            }
        },

    },
    newBadge: {
        position: 'absolute',
        top: '-5px',
        left: '10px',
        width: '45px',
        height: '45px'
    },
    title: {
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        fontSize: '16px',
        fontWeight: 600,
        color: '#161A1B',
        marginTop: 0,
        marginBottom: 0,
        wordWrap: "break-word",
        height: "318px",
        overflowY: "hidden",
        [theme.breakpoints.down('md')]: {
            height: "245px",
            fontSize: '18px',
        }
    },
    bottom: {
        flex: 1,
        boxSizing: 'border-box',

        padding: '15px 15px 20px 15px',
        display: 'flex',
        flexDirection: 'column',
        height: '0px',
        width: '100%'
    },
    dateContainer: {
        fontFamily: 'Nunito',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'baseline'

    },
    relativeDate: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#161A1B',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',

        }
    },
    date: {
        fontSize: '14px',
        fontWeight: 300,
        color: '#161A1B',
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    dateBullet: {
        fontSize: '12px',
        color: '#161A1B',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    topicContainer: {
        // height: '25px',
        height: '172px',
        // display: 'flex',
        // flexWrap: 'wrap',
        marginTop: '10px',
        marginBottom: '15px',

        position: 'relative',
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        overflow: 'hidden',
        '&.panel': {

            margin: 0,



        },
    },
    topicPills: {
        flex: '20%',
        border: '1.5px solid #D9D9D9',
        borderRadius: '100px',
        fontFamily: 'Nunito',
        fontWeight: 500,
        fontSize: '14px',
        boxSizing: 'border-box',
        padding: '4px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#161A1B',
        whiteSpace: 'nowrap',
        marginRight: '5px',
        marginTop: '5px',
        textOverflow: 'clip',

        '&.panel': {

        flex: '0 0 20%',

        },
        '&.additional': {
            fontWeight: 600,

            '& + .additional-container': {
                // display: 'none',
                display: 'flex',
                flexDirection: 'column',
                opacity: 0,
                position: 'absolute',
                bottom: '15px',
                right: 0,
                background: 'white',
                zIndex: 4,
                padding: '2px 20px 10px 20px',
                border: '1.5px solid #D9D9D9',
                borderRadius: '5px',
                fontFamily: 'Nunito',
                color: 'initial',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
                transition: 'opacity .15s',

                '& .text': {
                    marginTop: '6px'
                }
            },
            '&:hover + .additional-container': {
                opacity: 1
            }
        }
    },
    defaultThumbnailContainer: {
        width: '100%',
        borderRadius: '5px',
        height: '100%',
        backgroundColor: '#161A1B',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%'
        }
    },

    recordingDatePanel: {
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        // margin: '0 7.5vw ',
        marginTop: '5px',
        minHeight: '405px',
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
            height: '100%'
        },
        '& .topBar': {
            height: '60px',
            display: 'flex',
            width: '100%',
            borderBottom: '1.5px solid #D9D9D9',
        },
        '& .nav-btns': {
            width: '358px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px',
            [theme.breakpoints.down('md')]: {
                margin: 'auto',
                padding: '0 4px'
            },
        },
        '& .panel-bottom': {
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            minHeight: '350px',
            marginBottom: '20px',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                marginTop: '40px',
            }

        },
        '& .month-name': {
            fontFamily: 'Nunito',

            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '125%',
        }
    },
    calendarView: {
        marginTop: '30px',
        marginLeft: '25px',
        [theme.breakpoints.down('md')]: {
            margin: '30px auto 0 auto'
        },
        '& .tile-content-dot-container': {
            position: 'absolute',
            bottom: '-1px',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'

        },
        '& .tile-content-dot': {
            color: '#14606B',
            fontSize: '13px'
        }
    },

    panelRecordingsCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '35px',
        [theme.breakpoints.down('md')]: {
            boxSizing: 'border-box',

            marginLeft: 0,
            padding: '0 2vw',
            marginTop: '25px'

        },
    },
    panelRecordingCard: {
        display: 'grid',
        gridTemplateColumns: '60% 1fr',
        columnGap: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        border: '1.5px solid #D9D9D9',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        width: '100%',
        minHeight: '196px',
        marginRight: '35px',
        marginBottom: '15px',
        marginTop: '15px',
        padding: '15px 15px 15px 15px',
        position: 'relative',
        transition: 'box-shadow .2s',
        '&:hover': {
            boxShadow: '-2px 6px 12px rgba(0, 0, 0, 0.25)',

        },
        [theme.breakpoints.down('md')]: {
            height: '185px',
            marginRight: 0,
            marginTop: 0
        },
        '& .thumbnail-container': {
            position: 'relative',
            '& .new-badge': {
                position: 'absolute',
                top: '-4px',
                left: '5px'
            }
        },
        '& .text-content': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .bottom-row': {
            display: 'flex',
            marginTop: 'auto'
        },
        '& .title-row': {
            display: 'flex',
            alignItems: 'center',

        },

        '& .title': {
            fontFamily: 'Nunito',

            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '16px',
            marginBottom: '10px',

        },
        '& .description': {
            fontFamily: 'Nunito',
            maxHeight: '50px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '16px',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '640px',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical'
        }
    },
    noVideos: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        boxSizing: 'border-box',
        padding: '0 5vw',
        textAlign: 'center',
        alignSelf: 'center',

        [theme.breakpoints.down('sm')]: {

            width: '100%',
            marginBottom: '40px'
        },
        '& .title': {
            width: '70%',
            fontFamily: 'Nunito',

            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '125%',
            marginBottom: '10px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                width: '100%',

            },
        },
        '& .subtitle': {
            width: '70%',

            fontFamily: 'Nunito',
            marginBottom: '20px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '125%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                width: '100%',

            },

        },
        '& img': {
            width: '220px',

            [theme.breakpoints.down('sm')]: {
                width: '312px'

            },
        }
    },
    customArrowLeft: {
        '&:hover': {
            cursor: "pointer"
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "18.83px",
        width: "18.83px",
        backgroundColor: "#000000",
        borderRadius: "50%",
        padding: "2px",
        opacity: .4,
    },
    customArrowRight: {
        '&:hover': {
            cursor: "pointer"
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "18.83px",
        width: "18.83px",
        backgroundColor: "#000000",
        borderRadius: "50%",
        padding: "2px",
    },
    tabScrollArrows: {
        width: "70px",
        display: "flex",
        justifyContent: "space-between"
    },
    arrowButtonActive: {
        animationName: "$arrowButtonActive",
        animationDuration: "1.5s",
        animationFillMode: "forwards",
        animationIterationCount: 1
    },
    '@keyframes arrowButtonActive': {
        '0%': {
            opacity: .6,
        },
        '100%':{
            opacity: 1,
        }
    },
    arrowButtonInactive: {
        animationName: "$arrowButtonInactive",
        animationDuration: "1.5s",
        animationFillMode: "forwards",
        animationIterationCount: 1
    },
    '@keyframes arrowButtonInactive': {
        '0%': {
            opacity: 1,
        },
        '100%':{
            opacity: .6,
        }
    },
}));

export default useStyles;
