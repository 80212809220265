import { useAuth0 } from "@auth0/auth0-react";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Avatar, Popover, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import Image from '../../../images/kenzieAvatar.png';
import ProfileImageUploader from './ProfileImageUploader';

import { AppDataContext } from '../../../context';
import { useProfileImage } from './profileImageStyles';


const UserProfileImage = ({
    popupMessage = "Please contact MyKenzie R&D team to update your profile picture",
    showEditButton = false,
    isEditProfilePage,
    isOnboarding
}) => {

    const { user } = useAuth0();
    const { userData } = useContext(AppDataContext);

    const classes = useProfileImage();

    const [anchorEl, setAnchorEl] = useState(null);

    const [showImageUploader, setShowImageUploader] = useState(false);

    const handleClickToShowPopMsg = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopMsg = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'staff-edit-update-image-pop-out' : undefined;

    return (<div style={{ position: 'relative' }}>
        <div
            className={showEditButton ? classes.avatarContainer : ""}
            onClick={!showEditButton ? () => {
                handleClickToShowPopMsg()
            } : () => {
                setShowImageUploader(true);
            }}
        >
            <Avatar
                alt={`${user.name}`}
                imgProps={isEditProfilePage && {
                    style: {
                        borderRadius: '100vh'
                    }
                }}
                //If user_image_url exists in our database, show that. 
                //If not, check if user image exists in the google profile image 
                //if not, default to Kenzie Avatar image
                src={userData.user_image_url ? userData.user_image_url :
                    user.picture ? user.picture :
                        Image
                }
                sx={{
                    width: isOnboarding ? 225 : 164,
                    height: isOnboarding ? 225 : 164,
                    padding: '15px',
                    border: isEditProfilePage && '5px solid #63C29D'
                }}
            />
            {
                showEditButton ?
                    <div className={classes.editButtonAlignment}>
                        <ModeEditIcon sx={{
                            fontSize: "2em"
                        }} />
                    </div> :
                    null
            }

        </div>

        {
            !showEditButton ?
                <Popover
                    open={open}
                    id={id}
                    PaperProps={{
                        sx: {
                            width: "300px"
                        }
                    }}
                    onClose={handleClickClosePopMsg}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {
                        popupMessage.length > 0 ?
                            <Typography
                                sx={{ p: 2, fontSize: "15px", fontFamily: "Raleway !important" }}
                            >
                                {popupMessage}
                            </Typography> :
                            null
                    }

                </Popover> :
                null
        }

        {
            showImageUploader ?
                <ProfileImageUploader handleOnCancel={() => { setShowImageUploader(false) }} /> :
                null
        }

    </div>)
}

export default UserProfileImage;