import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { ROLE_MAPPINGS } from "../../../constants";
import ManageCohortRowForm from "./ManageCohortRowForm";
import ToggleSwitch from "./StyledSwitch";
import { memo } from 'react'
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2";

export default memo(function ManageCohortRow({ memberData, showBorder, setAlert, setUpdatedMembers }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [member, setMember] = useState(memberData)
  const { hasCurrentCohortAdminAccess } = useACPDataContextV2()

  const styles = {
    tableRow: {
      minHeight: "44px",
      display: "grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: hasCurrentCohortAdminAccess ? "22% 22%  12% 12% 12% 12% 12%" : "22% 22%  12% 12% 12% 12% ",

      backgroundColor: 'white',
      alignItems: "center",
      paddingLeft: "16px"
    }
  };


  const EditCell = memo(() => (
    <div className="manage-cohort-table-cell">
      <Tooltip title="Edit Member">
        <EditIcon
          className="pointer"
          sx={{ opacity: 0.8, width: "20px" }}
          onClick={() => setIsEditMode(true)}
        />
      </Tooltip>
    </div>
  ))

  const ToggleCell = memo(() => (
    <div className="manage-cohort-table-cell">
      <ToggleSwitch disabled checked={member.is_visible} />
    </div>
  ))
  if (isEditMode)
    return (
      <ManageCohortRowForm
        setUpdatedMembers={setUpdatedMembers}
        member={member}
        setMember={setMember}
        showBorder={showBorder}
        setIsEditMode={setIsEditMode}
        setAlert={setAlert}
      />
    );
  else
    return (
      <>


        <div
          style={{
            ...styles.tableRow,
            borderBottom: showBorder ? "1px solid #D9D9D9" : 0
          }}
        >
          <div
            className={`manage-cohort-table-cell truncate`}
            style={{
              color:
                member.active ? "#000000" : "rgba(155,155,155, .65)"
            }}
          >
            {(member.first_name || '') + " " + (member.last_name || '')}
          </div>
          <div
            className={`manage-cohort-table-cell truncate`}
            style={{
              color:
                member.active ? "#000000" : "rgba(155,155,155, .65)"
            }}
          >
            {member.email}
          </div>
          <div
            className="manage-cohort-table-cell"
            style={{
              fontSize: "12px",
              color: member.active ? "#1A8400" : "#C2573F"
            }}
          >
            {member.active ? 'Active' : 'Inactive'}
          </div>
          <div
            className="manage-cohort-table-cell"
            style={{
              fontSize: "12px",
              color: member.user_role_id === 1 ? "#000000" : "#013DA4"
            }}
          >
            {ROLE_MAPPINGS[member.user_role_id]}
          </div>
          <div className="manage-cohort-table-cell">{member.user_lms_id}</div>
          <ToggleCell />
          {hasCurrentCohortAdminAccess ?
            <EditCell />
            : null}
        </div>
      </>
    );
})



