import React from "react"
import UserProfileImage from "../../../sharedComponents/editProfileFields/profileImage/UserProfileImage"
const Picture = () => {
    return (
        <>
            <p>Here is a picture that represents me.</p>
            <UserProfileImage showEditButton={true} />
        </>
    )
}

export default Picture