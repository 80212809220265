import { memo } from "react";
import styled from "@emotion/styled";
import { useACPDataContextV2 } from "../../../../context/ACPDataStoreV2";
import { PROGRAMS } from "../../../../constants";
import CircularLoader from "../../../../../sharedComponents/CircularLoader";
export default memo(function ActiveCohortPanel() {
    const { adminPrograms } = useACPDataContextV2()
    return (
        <CohortPanel>
            <PanelTitleContainer>
                <PanelTitle>
                    Active Cohorts
                </PanelTitle>
            </PanelTitleContainer>
            <PanelBody>
                {
                    Object.values(adminPrograms).length ?
                        <>

                            <div className="panel-subtext">
                                <p>These cohorts are currently enrolled in a program with Kenzie Academy.</p>
                            </div>
                            {
                                adminPrograms?.active?.sort((a, b) => {
                                    return PROGRAMS.find(p => p.id === a.id).title > PROGRAMS.find(p => p.id === b.id).title ? 1 : -1
                                }).map((program) => (
                                    <div key={program.id}>
                                        <HeadingText>{PROGRAMS.find(p => p.id === program.id).title}</HeadingText>
                                        <div className="active-cohort-list">
                                            {
                                                program?.cohorts?.active?.length ?
                                                    program.cohorts.active.map((c, i) => (
                                                        <p key={i}>{c.cohort_name}</p>

                                                    ))
                                                    :

                                                    <p className="cohort-text">No cohorts found</p>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                        :

                        <CircularLoader styles={{ marginTop: '24px' }} />

                }
            </PanelBody>
        </CohortPanel>
    )
})

export const CohortPanel = styled.div`
width: 32.4%;
max-height: 671px;
border: 2px solid #C7C7C7;
border-radius: 10px;
box-sizing: border-box;
background-color: #FFFF;
overflow-x: hidden;
`

export const PanelTitleContainer = styled.div`
display: flex;
align-items: center;
border-radius: 4px 4px 0 0 ;
background-color:  #445879;
height: 45px;
padding: 0 19px;
`

export const PanelTitle = styled.h2`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #ffffff;
margin: 0;
`

export const PanelBody = styled.div`
max-height: 620px;
padding:  0 19px 20px 19px;
overflow-y: scroll;
/* display: flex;
flex-direction: column;
align-items: flex-start; */
box-sizing: border-box;
/* height: calc(100% - 45px); */
`

export const HeadingText = styled.h3`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #1D3969;

margin-bottom: 20px;
margin-top: 35px;
`