window.googlePlaceServices = null;

const useLoadGooglePlaceServicesApi=(controlId)=>{

    if (window.googlePlaceServices===null && window.google) {
        
        const input = document.getElementById(controlId);
        if (!input) return
        window.googlePlaceServices = new window.google.maps.places.PlacesService(input);
    }

    return window.googlePlaceServices;
    
}

export default useLoadGooglePlaceServicesApi;