import React, { useContext } from 'react';

import useStyles from './styles';

import { UserProfileContext } from './UserProfileContext';

const UserAdditionalDemographics = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);


    return (
        <>
            {
                profileData && profileData.user_intro_interest
                    && profileData.user_intro_interest.length > 0 ?
                    <div>

                        <p id="aboutContext" className={`lineHt-sm-1 ${classes.profileSectionParagraph}`}>{profileData.user_intro_interest}</p>
                    </div> :
                    null
            }
            {
                profileData && profileData.user_goals
                    && profileData.user_goals.length > 0 ?
                    <div>

                        <p id="user-goals" className={`lineHt-sm-1 ${classes.profileSectionParagraph}`}>{profileData.user_goals}</p>
                    </div> :
                    null
            }
        </>

    )
}

export default UserAdditionalDemographics;