import React, { useContext, useEffect, useState } from 'react';
import { ACPSupportRequestsContext } from '../../context/ACPSupportRequestsContext';

import RequestLeftMenu from './RequestLeftMenu';

import { useStyles as useBaselayoutClass } from '../baseLayout/styles';
import { useStyles } from './styles';

import { useLocation } from 'react-router-dom';
import { getSupportRequestById } from '../../../api';
import CircularLoader from '../../../sharedComponents/CircularLoader';
import EachRequest from './EachRequest';
import EachRequestExpansionModal from './EachRequestExpansionModal';

import { checkIfSupportIdExistsInUrl, getSupportIdFromUrl } from './helpers';

import KBotNoOpen from '../../../images/no_open_requests.png';
import KBotOpen from '../../../images/open_requests.png'

import { useACPDataContextV2 } from '../../context/ACPDataStoreV2';
export default function SupportRequests() {

	const classes = useBaselayoutClass();
	const requestStyleClasses = useStyles()

	const location = useLocation();
	const { acpDataLoading } = useACPDataContextV2()

	const {
		filteredSupportRequests,
		isAnyFilterActive,
		supportRequests
	} = useContext(ACPSupportRequestsContext);

	//State variable stores the informatin of clicked request. Its an object and not Boolean flag 
	const [expandedRequest, setExpandedRequest] = useState(null);

	//Logic to show pop up per the support id in the url 
	//Checks the location and checks if support id exists in the url 
	//If id exists display the pop up with support information
	useEffect(() => {

		try {
			if (checkIfSupportIdExistsInUrl(location.pathname)) {
				let supportId = getSupportIdFromUrl(location.pathname);
				getSupportRequestById({
					'id': supportId,
					'expand': 'coursestructure,users'
				}).then(response => {
					if (response.length > 0) setExpandedRequest(response[0]);
				}).catch(err => console.error(err));
			}
		} catch (error) {
			console.error(error);
		}
	}, []);
	return (
		<div>
			{
				filteredSupportRequests ?
					<>
						<div className={classes.lBarRightPaneLevel2Menu}>
							<div className={classes.lBarRightPaneLevel2MenuPad}>
								<RequestLeftMenu />
							</div>
						</div>

						<div className={classes.lBarRightPaneContentContainer}
							style={{
								padding: "0px"
							}}>
							{
								acpDataLoading ?
									<CircularLoader styles={{ marginTop: "15px" }} /> :
									<>
										<div className={classes.connectPanelContainerInSupportRequest}
											id="connect-panel-container-in-support-requests">
											{/* <ConnectPanel /> */}
										</div>
										<div style={{
											padding: "38px 10px"
										}}>
											{
												filteredSupportRequests.length > 0 && isAnyFilterActive() ?
													filteredSupportRequests.map(request =>
														<EachRequest
															handleOnExpand={setExpandedRequest}
															key={request.id}
															request={request}
														/>
													) :
													<div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}

													>
														{supportRequests?.filter(s => s.status !== 'closed').length ?
															<img src={KBotOpen} className={requestStyleClasses.kBotLives} alt="No Filtered Requests to show" />
															:
															<img src={KBotNoOpen} className={requestStyleClasses.kBotLives} alt="No Filtered Requests to show" />

														}
													</div>
											}
										</div>

									</>

							}
						</div>
					</> :
					null
			}
			{
				expandedRequest ?
					<EachRequestExpansionModal
						request={expandedRequest}
						open={expandedRequest ? true : false}
						handleOnClose={() => { setExpandedRequest(null) }}
					/> :
					null
			}

		</div>
	)
}