import { createTheme } from "@mui/material";

let theme = createTheme()
theme = createTheme(theme, {
    paragraphFont: {
        fontFamily: 'Nunito !important',
        fontWeight: 700,
        fontSize: '20px !important',

    },
    selectFont: {
        fontFamily: 'Nunito !important',
        fontWeight: 600,
        fontSize: "14px !important",
        lineHeight: "22px !important",
    },
    selectTextFont: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: "500",
        lineHeight: '21px',
    }
})

export default theme