import axios from 'axios';
import moment from 'moment';

import { getApiBase, HEADERS } from '../../../api/helpers';

/**
 * @description - Gets the online status of all users within the cohort 
 * @param {*} userEmail 
 * @returns 
 */
 export const fetchCohortUsersForOnlineStatus = async (cohortId) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/cohorts`,{
            params:{
                "id":cohortId,
                "users/active":true,
                "expand":"users,userdetails",
                "attributes":"users/user_email,userdetails/is_online,userdetails/user_last_seen"
            },
            headers:HEADERS
        });

        return data.message.cohorts[0];

    } catch (error) {
        return false
    }

};

/**
 * @description - Checks if the updated date is less than 2 weeks.
 * @param {Date String} value 
 * @returns 
 */
export const checkProfileUpdateIndication=(value)=>{
    try {
        if(!value) throw new Error("invalid");
        let diff=moment().diff(moment(value),'weeks');
        return diff<2;
    } catch (error) {
        //console.error(error);
        return false;
    }
}

/** 
 * 1. Get all users who are NOT an admin.
 * 2. Get al users where "is_visible" flag is true. 
 * 3. Get all users which are NOT test accounts. BUT Test account should show up if current user is test user
 * */
export const filterUserDataSet=(cohortUsers,currentUser)=>{
    let dataset=cohortUsers.reduce((acc,user)=>{
        if(((user.user_role_id !== 9 && user.user_role_id !== 11)
            && user.is_visible===true 
            && ((/test\.+\w*/).test(user.user_email)===false)) || 
            ((/test\.+\w*/).test(user.user_email)===true && user.user_email===currentUser.username && user.is_visible===true)){
                let indx = acc.findIndex(a => a.user_email === user.user_email)
                if(indx === -1) {
                    acc.push(user)
                }
            }
        return acc;
    },[]);
    //
    return dataset;
}