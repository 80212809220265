import useEditProfileStyles from '../../../sharedComponents/editProfileFields/styles/editProfileStyles';
import { GrowingInput } from "../onboarding/components";

export default function GrowingInputContainer(props) {
    const classes = useEditProfileStyles()

    return (
        <div className={classes.growingInputText} style={{ marginTop: '1em', marginBottom: '12px' }}>
            <span className={classes.growingInputText}>{props.label}</span>
            <GrowingInput {...props} />
        </div>
    )
}
