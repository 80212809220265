import { useEffect, useState } from "react";
import ToggleSwitch from "./StyledSwitch";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Tooltip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from "react-hook-form";

import { useACPDataContextV2 } from '../../../context/ACPDataStoreV2'
import { updateCohortMember } from './api';
import { determineTablesToUpdate, formatFormDataTypes, trimFormData } from './helpers';
import { ROLE_MAPPINGS } from "../../../constants";
import {
  ClearConfirmationModal,
  InactiveConfirmationModal
} from "./modals";

import StyledSelect from "./StyledSelect";
import StyleTextInput from "./StyledTextInput";

function ManageCohortRow({
  member = {
    first_name: null,
    last_name: null,
    email: null,
    active: null,
    user_role_id: null,
    user_lms_id: null,
    is_visible: null
  },
  showBorder,
  setIsEditMode,
  isAddMode = false,

  setMember,
  setAlert,
  setUpdatedMembers

}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  const [switchState, setSwitchState] = useState(member?.is_visible || false);
  const { selectedCohortId, updateSingleMember, userData } = useACPDataContextV2()

  const [data, setData] = useState("");

  const [showInactiveModal, setShowInactiveModal] = useState(false);

  const [showClearModal, setShowClearModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleEditFormSubmit = async (data) => {
    data = trimFormData(data)
    if (data.user_role_id === null) data.user_role_id = member.user_role_id
    if (data.user_lms_id === null) data.user_lms_id = member.user_lms_id
    if (!data.email) data.email = member.email
    setIsSubmitting(true)

    const { mkUserFields, mkUserCourseFields } = determineTablesToUpdate(member, data)
    if (!mkUserFields.length && !mkUserCourseFields.length) {
      setMember(data)

      setIsEditMode(false)
    }
    else {

      const { didUpdate, message } = await updateCohortMember({ mkUserFields, mkUserCourseFields, data, cohortId: selectedCohortId, member, rowId: member.courseRowId, username: userData.username })
      if (!didUpdate) {
        setAlert(message)
      }
      const updatedMember = { ...data, courseRowId: member.courseRowId }

      setMember(updatedMember)
      setIsSubmitting(false)
      setUpdatedMembers(updatedMember)

      updateSingleMember(updatedMember)
      setIsEditMode(false)
    }
  }



  const handleInactiveClose = () => {
    setShowInactiveModal(false);
  };
  const handleInactiveConfirm = (data) => {
    setShowInactiveModal(false);
    handleEditFormSubmit(data)
  };



  const handleClearConfirm = () => {
    setShowClearModal(false);
    setIsEditMode(false);
  };
  const handleClearClose = () => {
    setShowClearModal(false);
  };
  useEffect(() => {
    //toggle off connect switch
    const subscription = watch((value, { name, type }) => {

      if (name === 'active' && value.active === 'false') {
        setSwitchState(false)
        setValue('is_visible', 'false')
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]
  )

  if (isSubmitting) return (

    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: "1px solid #D9D9D9" }}>
      <CircularProgress size={25} sx={{ margin: '10px' }} />
    </div>
  );


  return (
    <>

      <InactiveConfirmationModal
        isOpen={showInactiveModal}
        handleClose={handleInactiveClose}
        handleConfirm={() => handleInactiveConfirm(data)}
        memberName={`${member.first_name} ${member.last_name}`}
      />

      <ClearConfirmationModal
        isOpen={showClearModal}
        handleConfirm={handleClearConfirm}
        handleClose={handleClearClose}
      />

      <form
        style={{
          ...styles.tableRow,
          borderBottom: showBorder ? "1px solid #D9D9D9" : 0,
          gridTemplateColumns: isAddMode
            ? "20% 20% 15% 15% 15% 15%"
            : "22% 22%  12% 12% 12% 12% 12%"
        }}
      >

        <div className="manage-cohort-table-cell">
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyleTextInput
              style={{ marginRight: "2px", width: "50%" }}
              hasError={errors.first_name}
              inputProps={{
                placeholder: 'First Name',

                name: "first-name",
                defaultValue: member.first_name,
                ...register("first_name", {
                  required: true
                })
              }}
            />
            <StyleTextInput
              style={{ width: "50%" }}
              hasError={errors.last_name}
              inputProps={{
                placeholder: 'Last Name',
                name: "last-name",
                defaultValue: member.last_name,
                ...register("last_name", {
                  required: true
                })
              }}
            />
          </div>
        </div>
        <div className="manage-cohort-table-cell truncate">
          {isAddMode ?
            <StyleTextInput
              hasError={errors.email}
              inputProps={{
                name: "email",
                defaultValue: member.email,
                placeholder: 'Email',

                ...register("email", {
                  required: true,
                  pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                })
              }}
            />
            : <span >{member.email}</span>}
        </div>
        <div
          className="manage-cohort-table-cell"
          style={{
            fontSize: "12px",
            color: member.active ? "#1A8400" : "#C2573F"
          }}
        >
          <StyledSelect
            name="active"
            value={String(member.active)}
            options={[{ title: "Active", value: 'true' }, { title: "Inactive", value: 'false' }]}
            inputProps={{
              ...register("active")
            }}
          />
        </div>
        <div
          className="manage-cohort-table-cell"
          style={{
            fontSize: "12px",
            color: member.user_role_id === 1 ? "#000000" : "#013DA4"
          }}
        >
          {member.user_role_id !== 9 ? (
            <StyledSelect
              name="role"
              value={member.user_role_id}
              options={[{ title: 'Learner', value: 1 }, { title: "Coach", value: 5 }, { title: "Facilitator", value: 2 }, { title: 'Admin', value: 9 }, { title: "SME", value: 14 }]}
              inputProps={{
                ...register("user_role_id")
              }}
            />
          ) : (
            ROLE_MAPPINGS[member.user_role_id]
          )}
        </div>
        <div className="manage-cohort-table-cell">
          {isAddMode ?
            <StyleTextInput
              inputProps={{ defaultValue: member.user_lms_id || '', ...register("user_lms_id") }}
            />
            : member.user_lms_id}
        </div>
        <div className="manage-cohort-table-cell">
          <ToggleSwitch
            checked={switchState}
            onClick={() => setSwitchState(!switchState)}
            inputProps={{ ...register("is_visible") }}
          />
        </div>
        {!isAddMode && (
          <div className="manage-cohort-table-cell">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexFlow: 'row wrap'
              }}
            >
              <Tooltip title="Save Changes">
                <CheckCircleIcon
                  onClick={handleSubmit(async (data) => {
                    data = formatFormDataTypes(data)
                    setData(data);

                    if (
                      (data.active === "false" || data.active === false) &&
                      member.active
                    ) {
                      setShowInactiveModal(true);
                      return;
                    }


                    await handleEditFormSubmit(data)

                  })}
                  className="pointer"
                  sx={{ color: "#1A8400", marginRight: "2px" }}
                />
              </Tooltip>
              <Tooltip title="Discard Unsaved Changes">
                <DoNotDisturbIcon
                  onClick={() => setShowClearModal(true)}
                  className="pointer"
                  sx={{ color: "#5C5C5C", marginRight: "2px" }}
                />
              </Tooltip>

            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default ManageCohortRow;

const styles = {
  tableRow: {
    minHeight: "44px",
    display: "grid",
    gridTemplateRows: "1fr",

    alignItems: "center",
    paddingLeft: "16px"
  }
};
