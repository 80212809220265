import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import NoVideosImg from '../../../../images/no-videos.png';
import useStyles from '../styles';
import './calendar.css';
import PanelRecordingCard from './PanelRecordingCard';
export default function RecordingCalendar({ recordings }) {
    const styles = useStyles()
    const [value, setValue] = useState(new Date());
    const [activeVal, setActiveVal] = useState(new Date())
    const [panelRecordings, setPanelRecordings] = useState([])
    const [isViewingCurrentMonth, setIsViewingCurrentMonth] = useState(true)
    const calendarPanelRef = useRef(null)
    const now = new Date()
    useEffect(() => {
        if (moment(now).format('MMYY') === moment(activeVal).format('MMYY')) {
            setIsViewingCurrentMonth(true)
        }
        else {
            setIsViewingCurrentMonth(false)
        }
    }, [activeVal])

    useEffect(() => {
        if (panelRecordings?.length) {
            calendarPanelRef.current.scrollIntoView()
        }
    }, [panelRecordings])
    const handleTileClick = (value) => {
        setValue(value)
        const recordingsForDate = recordings.filter(recording => recording.recordingDate === moment(value).format('MMDDYY'))
        setPanelRecordings(recordingsForDate)

    }
    const handleClickForward = (activeVal) => {
        if (isViewingCurrentMonth) return
        const nextMonth = moment(activeVal).add(1, 'months').calendar()

        if (nextMonth.startsWith('Today'))
            setActiveVal(new Date())
        else
            setActiveVal(new Date(nextMonth))

    }
    const handleClickBack = (activeVal) => {

        const lastMonth = moment(activeVal).subtract(1, 'months').calendar()
        if (lastMonth.startsWith('Today'))
            setActiveVal(new Date())
        else
            setActiveVal(new Date(lastMonth))
    }

    const handlePopulateTiles = ({ activeStartDate, date, view }) => {

        let recs = []
        for (let recording of recordings) {
            if (recording.recordingDate === moment(date).format('MMDDYY')) {

                recs.push(recording)
            }
        }
        return (
            <div className='tile-content-dot-container'>
                {recs.map(rec => (
                    <div className='tile-content-dot' >•</div>
                ))}
            </div>
        )

    }
    const [currentView] = useState('month')
    return (
        <div className={styles.recordingDatePanel} ref={calendarPanelRef}>
            <div className='topBar'>
                <div className='nav-btns'>
                    <ArrowBackIosIcon onClick={() => handleClickBack(activeVal)} className='pointer' />
                    <span className='month-name'>{moment(activeVal).format('MMMM YYYY')}</span>

                    <ArrowForwardIosIcon onClick={() => handleClickForward(activeVal)} sx={{ cursor: isViewingCurrentMonth ? 'default' : 'pointer', opacity: isViewingCurrentMonth ? '.3' : 1 }} />
                </div>
            </div>

            <div className='panel-bottom'>

                <div className={styles.calendarView}>

                    <Calendar onChange={handleTileClick}
                        value={value}
                        view={currentView}
                        activeStartDate={activeVal}
                        showNavigation={false}
                        tileContent={handlePopulateTiles}
                        formatShortWeekday={(locale, date) => moment(date).format('dd')[0]}
                    />
                </div>


                {panelRecordings.length ? (
                    <div className={styles.panelRecordingsCardContainer}>
                        {panelRecordings.map((recording, i) => (
                            <PanelRecordingCard video={recording} key={i} />
                        ))}
                    </div>
                ) :
                    <div className={styles.noVideos}>
                        <p className='title'>No videos have been uploaded for {moment(value).format('dddd, MMMM D')}.</p>
                        <p className='subtitle'><span style={{ fontWeight: 600 }}>Not what you expected?</span> If you're waiting on a recent video upload, it could take up to 30 minutes to appear in this list after the session has ended.</p>
                        <img alt="" src={NoVideosImg} />
                    </div>

                }
            </div>
        </div>
    );
}

