import userRoles from '../../../../helpers/userRoles';

/**
 * @description - excludes users per the userRoles array
 * @param {*} dataset 
 * @param {*} isVisibleProp: Helps if we want to exclude on ACP or learners dashboard 
 * @returns 
 */
export const excludeInstructorsFromDisplay=(dataset, isVisibleProp)=>{
    try {
        let data= dataset.reduce((acc,ci)=>{
            let indx=userRoles.findIndex(i=>i.id===ci.user_role_id && i[isVisibleProp]===true);
            if(indx>-1){
                acc.push(Object.assign(ci, {"display_order":userRoles[indx].displayOrder}))
            }
            return acc;
        },[]);

        return data;
        
    } catch (error) {
        throw error.message;
    }
}

/**
 * @description Excludes user from search
 * @param {*} dataset 
 * @param {user: Current User, courseId: currentCohort selected} courseId : this will check if there is an admin in the same course id, they cannot be added to the list 
 * @returns 
 */
export const excludeInstructorsFromSearch=({
    dataset=[],//All Instructors 
    selectedUsers=[],//Newly added users
    cohortId=null,
})=>{
    try {
        //

        return dataset.reduce((acc,ci)=>{
            //Exclude all 

            //Excluding users who are already selected



            //Check if user is already selected to be added
            let alreadySelected=selectedUsers.map(x=>x.user_email).includes(ci.username);

            //Exclude user who are only students
            let isNotStudent=ci.mk_user_courses.filter(i=>i.user_role_id!==1).length>0;

            //If no courses are assigned, check if user has email of @mykenzie.academy
            //eslint-disable-next-line no-useless-escape
            let userIsKenzieEmployee=ci.mk_user_courses.length===0 && (/\@kenzie.academy/).test(ci.username);

            if( !alreadySelected && (isNotStudent || userIsKenzieEmployee)){
                acc.push(ci); 
            }

            return acc;
        },[]); 

    } catch (error) {
        throw error;
    }
    
}

/**
 * @description updates all instructor after user saves/updates the info in the db
 * @param {*} allInstructors 
 * @param {*} currentCourseInstructors 
 * @returns 
 */
export const updateAllInstructorsArray=(allInstructors, currentCourseInstructors)=>{
    //;
    currentCourseInstructors.forEach(item => {
        let indx=allInstructors.findIndex(i=>i.user_email===item.user_email);
        if(indx>-1){
            allInstructors[indx]={...item};//assign the same value as all Instructors 
        }
    });
    ;
    return allInstructors;
}