import React, { useContext, useEffect, useState } from 'react';
import { CohortSettingsContext } from '..';

import { AppDataContext } from '../../../../context';
import { ACPDataContext } from '../../../context';
import EachCohortCalendars from './EachCohortCalendars';

import { useCohortSettingStyles } from '../styles';

export const CohortCalendarDataContext = React.createContext();

const CalendarSettingsPage = () => {

    const classes = useCohortSettingStyles();

    const { selectedACPSettingsCohortId, fetchACPSettingsData, updateCohortData } = useContext(ACPDataContext);
    const { setComponentLoaded } = useContext(CohortSettingsContext);
    const { setIsAllComponentsLoaded } = useContext(AppDataContext);

    let [calendars, setCalendars] = useState(null);

    useEffect(() => {
        try {
            if (selectedACPSettingsCohortId) {
                fetchACPSettingsData('calendars').then(response => {
                    // (response);
                    setCalendars(response);
                    setIsAllComponentsLoaded(true); //removes parent kenzie loader 

                    

                }).catch(err => console.error(err));
            }
        } catch (error) {
            console.error(error);
        }
    }, [selectedACPSettingsCohortId]);

    useEffect(()=>{
        if(calendars){
            setComponentLoaded("calendars");
        }
    },[calendars]);

    //** Updates/ Reomoves the state variable */
    const updateCalendarDataset = (data, remove = false) => {
        try {
            // (data)
            let d = [...calendars];
            let indx = d.findIndex(i => i.id === data.id);

            if (remove) {
                d.splice(indx, 1);
            } else {
                d[indx] = Object.assign(d[indx], data);
            }

            setCalendars(d);

            updateCohortData({
                "dataset": data,
                "cohortId": data.cohort_id,
                "itemId": data.id,
                "remove": remove,
                "key": "calendars"
            });

        } catch (error) {

        }
    }

    //** Add new Item into state variable */
    const addCalendarIntoDataset = (data) => {
        try {
            let d = [...calendars];
            d.push(data);
            setCalendars(d);

            updateCohortData({
                "isNew": true,
                "dataset": data,
                "cohortId": data.cohort_id,
                "key": "calendars"
            });

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <CohortCalendarDataContext.Provider value={{
            "calendars": calendars,
            "updateCalendarDataset": updateCalendarDataset,
            "addCalendarIntoDataset": addCalendarIntoDataset
        }}>
            {
                selectedACPSettingsCohortId && calendars ?
                    <>
                        {
                            selectedACPSettingsCohortId.map(cohortId => {
                                return <div id="calendar-settings" 
                                        className={`${classes.eachCohortSection}`}
                                        key={`cohort-calendars-${cohortId}`}>
                                    <EachCohortCalendars
                                        calendars={calendars.filter(i => i.cohort_id === cohortId)}
                                        cohortId={cohortId} />
                                </div>
                            })
                        }
                    </> :
                    null
            }
        </CohortCalendarDataContext.Provider>
    )

}

export default CalendarSettingsPage;