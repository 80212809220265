export const courseColorPallete = {
    course1: "#009AA4",
    course2: "#D30098",
    course3: "#769F00",
    course4: "#8F56D7",
    course5: "#0F6B9E",
    course6: "#CCAB00"
};

export const courseColor = (index) => {
    
    switch(index) {
        case 0:
            return courseColorPallete.course1;
        case 1:
            return courseColorPallete.course2;
        case 2:
            return courseColorPallete.course3;
        case 3:
            return courseColorPallete.course4;
        case 4:
            return courseColorPallete.course5;
        case 5:
            return courseColorPallete.course6;
        default:
    }
    
}