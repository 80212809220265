const StyledSelect = ({ inputProps, name, value, options, hasError }) => {
  return (
    <>
      {/* <label htmlFor={name}>{value}</label> */}
      <select
        defaultValue={value || 1}
        name={name}
        {...inputProps}
        style={{
          border: hasError ? "1px solid red" : "1px solid #989898",
          // border: hasError ? "1px solid red" : "0",
          borderRadius: "6px",
          padding: "2px 0",
          marginLeft: "-4px",
          width: "100%",
          fontSize: "12px",
          fontFamily: "Open Sans",
          cursor: "pointer"
        }}
      >


        {options.map((option, i) => (
          <option value={option.value} name={option.title} key={i} >
            {option.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default StyledSelect;
