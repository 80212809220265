import React, { useContext, useEffect, useState } from 'react';

import { Card } from "../../../components";

import useStyles from './styles';

import { LearnersDataContext } from '../../../context';

import JoinSession from './JoinSession';
import SessionModal from './SessionModal';
import PrestartConnectPanel from './PrestartConnectPanel';
import StartSession from './StartSession';

import { useCohortConnectionDataHook } from '../../../context/useCohortConnectionDataHook';


import { getStudySessionData } from '../../../../api';
import { AppDataContext } from '../../../../context';
import { filterClassmates, getGuestLearnerAndStaff } from './helpers';

const TIME_INTERVAL = 15000;//set to 15 seconds
var fetchStudySessionDataTimerId = null;

const ConnectPanel = (props) => {

    const classes = useStyles();

    const [cohortInfo, setCohortInfo] = useState([]);
    const [studySessionData, setStudySessionData] = useState(null);
    const [isPrestart, setIsPrestart] = useState(false);

    const [onlineNowCount, setOnlineNowCount] = useState(0);

    const [sessionParticipants, setSessionParticipants] = useState({});
    const [staffParticipants, setStaffParticipants] = useState([]);
    const [learnerParticipants, setLearnerParticipants] = useState([]);
    const [guestParticipants, setGuestParticipants] = useState([]);
    const [mainTopic, setMainTopic] = useState({})
    const [topics, setTopics] = useState([])

    const [loading, setLoading] = useState(true);

    const [showParticipantModal, setShowParticipantModal] = useState(false);

    const { userCohort, userCourse } = React.useContext(LearnersDataContext);
    const { userData } = useContext(AppDataContext);

    const cohortConnectionData = useCohortConnectionDataHook(userCohort?.cohort_id, props.cohortConnectionData);

    useEffect(() => {


        if (userCohort?.cohort_id) {
            setCohortInfo(userCohort?.cohort);
            setIsPrestart(userCourse?.course?.prestart);
        }

    }, [userCohort, userCourse]);

    useEffect(() => {

        setLoading(false);

        return (() => {
            setStudySessionData(null);
            clearInterval(fetchStudySessionDataTimerId);
            fetchStudySessionDataTimerId = null;
        })

    }, []);

    useEffect(() => {

        if (cohortConnectionData) {

            let dataset = cohortConnectionData[0];

            setOnlineNowCount(filterClassmates(dataset.mk_user_courses, userData).length);

            //Grab the study session data if the study session in progress
            if (dataset.study_session_in_progress && dataset.zoom_host_id && fetchStudySessionDataTimerId === null) {

                //Fetch the
                const fetchStudySessionData = async () => {
                    let data = await getStudySessionData(dataset.zoom_host_id);
                    setStudySessionData(data);
                }

                fetchStudySessionData();//on initial mount

                fetchStudySessionDataTimerId = setInterval(() => {
                    fetchStudySessionData();
                }, TIME_INTERVAL)

            } else if (!dataset.study_session_in_progress && fetchStudySessionDataTimerId !== null) {
                setStudySessionData(null);
                clearInterval(fetchStudySessionDataTimerId);
                fetchStudySessionDataTimerId = null;
            }
        }
    }, [cohortConnectionData]);

    //Triggered when session participants are set to empty array
    useEffect(() => {
        //Clean up when meeting is ended
        if (studySessionData === null) {
            setSessionParticipants({});
            setStaffParticipants([]);
            setLearnerParticipants([]);
            setGuestParticipants([]);

        } else {
            setSessionParticipants(studySessionData?.participants || {});
        }

        if(studySessionData?.topics) {
            const _topics = Object.values(studySessionData.topics)
            setMainTopic(_topics.find(topic => topic.main === true))
            setTopics(_topics.filter(topic => !topic.main))
        }
    },  [studySessionData]);

    //Triggered when session participants are set to empty array
    useEffect(() => {
        //Clean up when meeting is ended
        if (Object.keys(sessionParticipants).length > 0) {

            let guestLearnerAndStaff = getGuestLearnerAndStaff(sessionParticipants || {});

            setGuestParticipants(guestLearnerAndStaff.guests);
            setLearnerParticipants(guestLearnerAndStaff.learners);
            setStaffParticipants(guestLearnerAndStaff.staff);

        }  else  {
            setStaffParticipants([]);
            setLearnerParticipants([]);
            setGuestParticipants([]);
        }
    }, [sessionParticipants]);

    return (
        <div>
            <Card title={isPrestart ? "Classmates" : "Study Session"}
                classes={{ content: classes.contentWrapper }}
                theme="primary">
                {
                    !loading && cohortInfo !== null && cohortInfo.length === 0 ?
                        <div>Kenzie Connect info could not be loaded</div> :
                        isPrestart && cohortConnectionData ? <PrestartConnectPanel cohortInfo={cohortConnectionData} /> :
                            <>
                                <div className={classes.subcontainer}>
                                    {
                                        //check if particiapnts exists 
                                        Object.keys(sessionParticipants).length === 0 ?
                                            <StartSession onlineNow={onlineNowCount} setShowParticipantModal={setShowParticipantModal} /> :
                                            <JoinSession
                                                cohortInfo={cohortInfo}
                                                learnerParticipants={learnerParticipants}
                                                guestParticipants={guestParticipants}
                                                staffParticipants={staffParticipants}
                                                setShowParticipantModal={setShowParticipantModal} />
                                    }
                                </div>
                            </>
                }
            </Card>
            <SessionModal 
                modalOpen={showParticipantModal} 
                handleModalClose={() => setShowParticipantModal(false)} 
                active={Object.keys(sessionParticipants).length > 0}
                learnerParticipants={learnerParticipants}
                guestParticipants={guestParticipants}
                staffParticipants={staffParticipants}
                cohortInfo={cohortInfo}
                userData={userData}
                mainTopic={mainTopic}
                topics={topics}
                />
        </div>
    )
}
export default ConnectPanel;