import React, { useContext } from 'react';
import { v4 as uuid4 } from 'uuid';

import useStyles from './styles';
import { UserProfileContext } from './UserProfileContext';

const UserProgrammingLanguages = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    return (
        <>
            {
                profileData?.user_programming_languages?.length > 0 &&
                <div className={`${classes.programmingLanguages} `}>
                    <p className={classes.subHeader} style={{ marginTop: '16px', marginBottom: '-6px' }}>Programming Languages Known</p>
                    <div className={classes.pillGroup}>
                        {
                            profileData.user_programming_languages.map((pLanguage) => (
                                <div key={uuid4()} className={classes.pills}>
                                    {pLanguage}
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </>

    )
}

export default UserProgrammingLanguages;