import axios from 'axios';
const dotenv = require('dotenv');
dotenv.config();

//** Defined base url for the api */
export const localApiUrl = process.env.LOCAL_API_URL || 'http://localhost:8000/develop';
export const getApiBase = () => process.env.REACT_APP_API_URL || localApiUrl;
//export const getApiBase = () => 'http://localhost:8000/develop';

/**
 * @description - setting global headers for the api calls
 */
export const HEADERS = {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_AWS_X_API_KEY
}


export async function getCalendarEvents(values) {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/calendarevents`, {
            headers: HEADERS,
            params: values,


        })

        return data?.message?.calendar_events || [];

    } catch (error) {

        console.error("Error in fetching calendars: ", error);

        return [];
    }
}

export async function updateVideoData(payload, uuid, cancelSignal) {
    try {
        let { data } = await axios.put(`${getApiBase()}/videos?uuid=${uuid}`,
            payload,
            { headers: HEADERS, signal: cancelSignal || null })

        return Boolean(data?.message?.response);

    } catch (error) {

        console.error("Error in fetching calendars: ", error);
        return null

    }
}
