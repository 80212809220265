import React, { useContext, useRef } from 'react';

import {
    Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio,
    RadioGroup, Select, TextField
} from '@mui/material';

import { v4 as uuid4 } from 'uuid';

import { Formik } from 'formik';
import { getSupportRequests, updateStudentRequest } from '../../../api';
import { AppDataContext } from '../../../context';
import { ACPSupportRequestsContext } from '../../context';
import { getSuccessMessagePerActionId, parseRequestBody } from './helpers';
import { requestTypeBgColorMapping, requestTypeColorMapping, useStyles } from './styles';

import {
    checkIfMenuItemCanBeDisplayed, displayButtonText, displayTextFieldTitleMessage,
    getInitialMessage, getRequestTypeMapping
} from './helpers';

import RichTextEditor from '../../../sharedComponents/RichTextEditor';

const CODE_BLOCK_STYLE = 'style="background-color:#23241f;color: #f8f8f2;overflow: visible;padding-left: 8px;border-radius: 3px"' // This is a hack that feels wrong but Quill is horrible so this is the only way I see

const EachRequestActionForm = ({
    request,
    setRefreshHistory,
    setSubmissionSuccessfull,
    setSubmissionMessage,
    setSubmissionError,
    setSubmissionErrorMessage
}) => {

    const classes = useStyles();

    const { userData } = useContext(AppDataContext);

    const { requestActions,
        updateSupportRequestsAndResponses
    } = useContext(ACPSupportRequestsContext);

    const requestType = getRequestTypeMapping(request);

    const body = parseRequestBody(request);

    //Payload set on mount and passed to formiks initial value
    //;
    let dataRef = useRef({
        "requestId": request.id,
        "message": null,
        "email": request.user_lm.email,
        "user_slack_id": request.user_lm.user_slack_id || null,
        "workspace": 'kenzietown',
        "staffEmail": userData.email,
        "actionId": null,
        "inscribeBody": null,
        "inscribeTitle": null, 
        "inscribeSenderId": null,
        "inscribeSender": "staff",//default to staff
        "inscribeSenderEmail": userData.email
    });

    /**
     * Triggers when user clicks on save button
     * @param {*} values 
     */
    const handleSubmission = async (values, resetForm, setSubmitting) => {
        try {
            let dataset = { ...values };

            //Assign inscribe values if send to inscribe is selected
            if (dataset.actionId === 2) {
                if(!dataset.inscribeTitle) {
                    setSubmissionError(true);
                    setSubmissionErrorMessage("You must provide a title");
                    setSubmitting(false);
                    return
                }
                dataset.inscribeSenderId = (dataset.inscribeSender === "learner" ? request.user_lm.user_inscribe_id : null)
                dataset.inscribeSenderEmail = (dataset.inscribeSender === "learner" ? request.user_lm.email : userData.username)
                dataset.inscribeBody = dataset.message.replaceAll('class="ql-syntax"', CODE_BLOCK_STYLE); // Quill hack
            }

            if(!dataset.message) {
                setSubmissionError(true);
                setSubmissionErrorMessage("You must provide an action body");
                setSubmitting(false);
                return
            }
          

           await updateStudentRequest(dataset);

            const getResponseForRequest = await getSupportRequests(request.cohort_id, {
                id: request.id,
                expand: 'users,responses'
            });

           

            updateSupportRequestsAndResponses(getResponseForRequest[0]);

            setRefreshHistory(true);

            setSubmissionSuccessfull(true);
            setSubmissionMessage(getSuccessMessagePerActionId(dataset.actionId));

            resetForm();//resets form values to origin values passed

        } catch (error) {
            console.error(error);
            setSubmissionError(true);
            setSubmissionErrorMessage("There has been issue while submitting you request");

            setSubmitting(false);

        }finally{

        }
    }

    

    return (
        <div>
            <Formik
                initialValues={Object.assign({ ...dataRef.current }, {
                    author: userData.username
                })}
                onSubmit={(values, { resetForm,setSubmitting }) => { handleSubmission(values, resetForm, setSubmitting) }}>

                {({ handleSubmit, handleBlur, handleChange, values, isSubmitting, setFieldValue, resetForm }) => (

                    <form id="update-support-request-form" >

                        {
                            requestActions.length > 0 ?
                                <div className={`${classes.addLgMarginTop} ${classes.addLgMarginBottom}`}>
                                    <FormControl>
                                        <InputLabel sx={{
                                            fontFamily: "open sans",
                                            backgroundColor: requestTypeBgColorMapping[requestType],
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            marginBottom: "5px",
                                            color: `${requestTypeColorMapping[requestType]} !important`
                                        }} id="support-request-select-an-action-dropdown-label">Select an action</InputLabel>
                                        <Select
                                            id="support-request-select-an-action-dropdown"
                                            sx={{
                                                fontFamily: "open sans",
                                                border: `1px solid ${requestTypeColorMapping[requestType]}`,
                                                width: "215px"
                                            }}
                                            name="actionId"
                                            labelId="support-request-select-an-action-dropdown-label"
                                            value={values.actionId ? values.actionId : ""}
                                            onChange={(e)=>{
                                                setFieldValue("actionId",e.target.value);
                                                setFieldValue("message",getInitialMessage(e.target.value, request,body));
                                            }}
                                        >
                                            {
                                                requestActions && requestActions.length > 0 ?
                                                    requestActions.filter(action => checkIfMenuItemCanBeDisplayed(action.id, request.request_type_id, request.status)).map(action => (
                                                        <MenuItem key={action.id} value={action.id}> {action.action} </MenuItem>
                                                     
                                                    )) :
                                                    null
                                            }
                                        </Select>
                                    </FormControl>

                                </div> :
                                null
                        }

                        {
                            values.actionId !== null ?
                                <>
                                    {
                                        // Send to inscribe
                                        values.actionId === 2 ?
                                            <TextField
                                                sx={{ 
                                                    width: "100%",
                                                    fontFamily: "open sans",
                                                    '& .MuiOutlinedInput-root':{
                                                        border: `1px solid ${requestTypeColorMapping[requestType]}`
                                                    }
                                                }}
                                                placeholder="Title of the issue or concern the learner is having"
                                                name='inscribeTitle'
                                                value={values.inscribeTitle ? values.inscribeTitle : ""}
                                                onChange={(e) => {
                                                    setFieldValue("inscribeTitle", e.target.value)
                                                }}
                                            /> :
                                            null
                                    }

                                    {/* Common Body/Message Fields */}
                                    <div className={`text-normal ${classes.addMdMarginTop}`}>
                                        <div style={{ 
                                            margin:  "8px 0px",
                                            color: `${requestTypeColorMapping[requestType]}`,
                                        }}>
                                            <span>{displayTextFieldTitleMessage(values.actionId, requestActions)}</span>
                                            {
                                                // Internal comment
                                                values.actionId === 4 ?
                                                    <span> (Will be saved to request history)</span> :
                                                    null
                                            }
                                        </div>
                                        <RichTextEditor 
                                            name="message"
                                            id={uuid4()}
                                            handleOnChange={(val)=>{
                                                setFieldValue("message",val);
                                            }}
                                            value={values.message} 
                                        />
                                    </div>

                                    {/* form Buttons */}
                                    <div className={classes.formFooter} style={{
                                        justifyContent: values.actionId === 2 ? "space-between" : "flex-end"
                                    }}>
                                        {
                                            // For Send to inscribe user
                                            values.actionId === 2 ?
                                                <div>
                                                    <div>Send as: </div>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-label="sender"
                                                            value={values.inscribeSender ? values.inscribeSender : ""}
                                                            name="inscribeSender"
                                                            onChange={handleChange}
                                                        >
                                                            <FormControlLabel
                                                                value="staff"
                                                                label={userData.first_name + " " + userData.last_name}
                                                                control={<Radio />}
                                                            />
                                                            {
                                                                request.user_lm && request.user_lm.first_name ?
                                                                    <FormControlLabel
                                                                        value="learner"
                                                                        label={
                                                                            request.user_lm.last_name ? `${request.user_lm.first_name} ${request.user_lm.last_name[0]}` : request.user_lm.first_name
                                                                        }
                                                                        control={<Radio />}
                                                                    /> :
                                                                    null
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div> :
                                                null
                                        }
                                        {/* Buttons */}
                                        <div className={classes.formFooterButtons}>
                                            <div>
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={()=>{
                                                        resetForm();
                                                    }}
                                                    variant="text"
                                                    id="update-support-request-cancel-button"
                                                    className={classes.textButton}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className={classes.submitButton} >
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={() => { handleSubmit() }}
                                                    id="update-support-request-submit-button"
                                                    data-testid="update-support-request-submit-button"
                                                >
                                                    {
                                                        isSubmitting ?
                                                            <CircularProgress color="inherit" size={18} /> :
                                                            <span>{displayButtonText(values.actionId)}</span>
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                null
                        }

                    </form>
                )}

            </Formik>

        </div>
    )
}

export default EachRequestActionForm;