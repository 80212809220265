import { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuid4 } from 'uuid';
import { MediaContext } from "./index";
import ShareVideo from "./ShareVideo";
const MediaDescription = () => {


    const { videoInfo } = useContext(MediaContext);
    const [displaShowMoreButton, setDisplayShowMoreButton] = useState(false);
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    const descriptionTagRef = useRef(null)
    useEffect(() => {
        let scrollHeight = descriptionTagRef.current.scrollHeight;
        let innerHeight = descriptionTagRef.current.offsetHeight;
        if (scrollHeight > innerHeight) setDisplayShowMoreButton(true);
    }, [descriptionTagRef?.current?.scrollHeight, descriptionTagRef?.current?.offsetHeight]);

    useEffect(() => {
        let container = document.getElementById('media-description-tag');
        if (!showMoreClicked) {
            container.classList.add('text-clamp');
        } else {
            container.classList.remove('text-clamp')
        }
    }, [showMoreClicked])

    return (
        <div id='media-description-container'>

            <div className="top-row-container">
                <div className="media-tags">
                    {
                        videoInfo?.tags?.map(tag => {
                            return <div key={uuid4()} className="each-tag">{tag}</div>
                        })
                    }
                </div>
                <ShareVideo />


            </div>

            <div id="media-description-container">
                <span id="media-description-tag" className="media-description text-clamp" ref={descriptionTagRef}>
                    {videoInfo?.description}
                </span>

                {
                    displaShowMoreButton && <span
                        onClick={() => { setShowMoreClicked(!showMoreClicked) }}
                        id="media-showmore-button">
                        {showMoreClicked ? <span style={{ marginLeft: "10px" }}>Show Less</span> : "Show More"}
                    </span>
                }
            </div>


        </div>
    );
};

export default MediaDescription;
