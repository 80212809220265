import React from 'react'
 const errorIcon = () => {
 return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <circle cx="22.5" cy="22.5" r="20.5" stroke="#E46FA0" stroke-width="4"/>
                <path d="M13 13L32.5 32.5" stroke="#E46FA0" stroke-width="4" stroke-linecap="round"/>
                <path d="M32.5 13L13 32.5" stroke="#E46FA0" stroke-width="4" stroke-linecap="round"/>
        </svg>
    </>
)}
export default errorIcon;