import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuid4 } from 'uuid';

import { MediaContext } from '..';
import { getTotalSecondsFromTimeStamp } from "../../watch/utils";
import useVideoMetaStyles from "./videoMetaStyles";

var transcriptAutoScrollTimer = null;
var transcriptHighlightTimer = null;
const TranscriptDetails = React.memo(({
    videoRef,
    autoScrollFlagFromParent = null,
    tracks = []
}) => {

    const { captionText, setCaptionText } = useContext(MediaContext)
    const classes = useVideoMetaStyles();

    let nodesRef = useRef([]);//Sets all the nodes 
    let highlightedNodeRef = useRef(null);

    const [transcriptAutoScroll, setTranscriptAutoScroll] = useState(true);

    useEffect(() => {

        let controller = new AbortController();

        let container = document.getElementById('transcripts-container');
        let button = document.getElementById('transcript-auto-scroll-button-tag');

        //Triggers when user uses the mouse wheel or laptop trackpad
        container.onwheel = function (event) {
            if (transcriptAutoScroll) setTranscriptAutoScroll(false);
            button.style.visibility = "visible";
        }

        //Triggers when touch move on mobile browsers
        container.addEventListener('touchmove', function () {
            if (transcriptAutoScroll) setTranscriptAutoScroll(false);
            button.style.visibility = "visible";
        }, { signal: controller.signal })

        // document.body.addEventListener('mouseleave', () => {
        //     button.style.visibility = "hidden";
        // }, { signal: controller.signal });

        setIntervalToHighLightTranscript();

        return (() => {
            clearInterval(transcriptAutoScrollTimer);
            transcriptAutoScrollTimer = null;

            clearInterval(transcriptHighlightTimer);
            transcriptHighlightTimer = null;

            controller.abort();
        })

    }, []);

    useEffect(() => {
        if (autoScrollFlagFromParent !== null) {
            setTranscriptAutoScroll(autoScrollFlagFromParent);
        }
    }, [autoScrollFlagFromParent])

    useEffect(() => {
        if (videoRef?.current && Object.keys(tracks).length > 0) {
            nodesRef.current = document.querySelectorAll('#transcripts-container .each-transcript-row');
            if (transcriptAutoScroll) resumeAutoScroll();//Auto Scroll Enabled By Default
        }
    }, [videoRef, tracks]);

    /** Triggers when autoscroll state changes */
    useEffect(() => {
        if (transcriptAutoScroll) {
            resumeAutoScroll();
        } else {
            stopAutoScroll();
        }
    }, [transcriptAutoScroll]);

    const setIntervalToHighLightTranscript = () => {

        if (transcriptHighlightTimer === null) {

            transcriptHighlightTimer = setInterval(() => {

                if (videoRef.current && !videoRef.current.paused) {

                    //remove highlights
                    nodesRef.current.forEach(node => node.classList.remove('transcript-row-selected'));
                    let curr = videoRef.current.currentTime;

                    for (let i = 0; i < nodesRef.current.length; i++) {
                        let st = parseInt(nodesRef.current[i].getAttribute('starttime'));
                        let et = parseInt(nodesRef.current[i].getAttribute('endtime'));

                        if (curr >= st && curr <= et) {
                            //Scroll to that node
                            nodesRef.current[i].classList.add('transcript-row-selected');
                            highlightedNodeRef.current = nodesRef.current[i]
                            if (nodesRef.current[i].innerText !== captionText) {
                                setCaptionText(nodesRef.current[i].innerText)
                            }
                            break;//breaks the loop
                        }
                    }
                }

            }, 1000);
        }
    }

    /** Auto Scroll Enabled */
    const resumeAutoScroll = () => {

        if (transcriptAutoScrollTimer === null) {

            let parentElToScroll = document.getElementById('transcripts-container');

            transcriptAutoScrollTimer = setInterval(() => {

                if (videoRef.current && !videoRef.current.paused && highlightedNodeRef.current !== null) {

                    //Get top pos of the element
                    let topPos = highlightedNodeRef.current.offsetTop;

                    parentElToScroll.scroll({
                        top: topPos,
                        behavior: "smooth"
                    });
                }

            }, 1000);
        }
    }

    const stopAutoScroll = () => {
        clearInterval(transcriptAutoScrollTimer);
        transcriptAutoScrollTimer = null;
    }

    return (
        <div className={`${classes.transcriptsParentContainer}`} id="transcripts-parent-container">

            <EachTranscriptRow
                videoRef={videoRef}
                nodesRef={nodesRef}
                tracks={tracks} />


            <div className="transcript-auto-scroll-button" id="transcript-auto-scroll-button-tag">
                {
                    !transcriptAutoScroll &&
                    <Button
                        onClick={() => { setTranscriptAutoScroll(!transcriptAutoScroll) }}
                        variant="text"
                        size="small"
                        startIcon={<ArrowUpwardIcon fontSize="sm" />}>
                        <span>Resume Transcript Auto-Scroll</span>
                    </Button>
                }
            </div>

        </div>

    );
});

const EachTranscriptRow = React.memo(({
    tracks = [],
    videoRef,
    nodesRef
}) => {

    const classes = useVideoMetaStyles();

    const handleOnRowClick = (event, transcript) => {
        try {
            nodesRef.current.forEach(node => node.classList.remove('transcript-row-selected'));

            event.target.parentNode.classList.add('transcript-row-selected');

            videoRef.current.currentTime = getTotalSecondsFromTimeStamp(transcript.start);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div id="transcripts-container" className={classes.transcriptContainer}>
            {
                Object.keys(tracks).map(key => {
                    return <div key={uuid4()}
                        trackkey={key}
                        starttime={getTotalSecondsFromTimeStamp(tracks[key].start)}
                        endtime={getTotalSecondsFromTimeStamp(tracks[key].end)}
                        onClick={(event) => { handleOnRowClick(event, tracks[key]) }}
                        className='each-transcript-row'>
                        <div className="transcript-text" trackkey={key}>{tracks[key].text}</div>
                    </div>
                })
            }

        </div>
    )
})

export default TranscriptDetails;
