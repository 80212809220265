import { useEffect, useState } from "react";
import { getWebtoolsByCohortId } from "../../api";

//TODO: Think about how I can save this in indexDB, instead of memory
var cohortWebtoolsDataset=null;

/**
 * @description Get the webTools per the cohortId of the user and store the information in ref the variable
 * @returns WebTools Array
 */
export const useWebtoolsDataHook=(cohortId=null,refresh=false)=>{

    const [dataset,setDataset]=useState(null);

    //fetch data from databse 
    const fetchData=()=>{
        getWebtoolsByCohortId(cohortId, {
            "is_active": true
        }).then(data=>{
            cohortWebtoolsDataset=data;
            setDataset(data);
        }).catch(err=>{
            console.error(err)
        })
    }

    //check the values and get information 
    useEffect(()=>{

        if(refresh && cohortId!==null){ 
            fetchData();

        }else if(cohortWebtoolsDataset!==null){ 
            setDataset(cohortWebtoolsDataset);

        }else if(cohortId!==null){
            fetchData();
        }

    },[cohortId]);

    return dataset;
}