import Night_owl_Image from '../../../images/full_moon.png';
import Afternoon_Loon from '../../../images/full_sun.png';
import Morning_Lark from '../../../images/sunrise.png';
import Evening_Eagle from '../../../images/sunset.png';
import All_Day_Albatross from '../../../images/spit_sun_moon.png';

export const getFocusTimeImage = (slot) => {
    let imageSrc = null;

    switch (slot.id) {
        case "night_owl":
            imageSrc = Night_owl_Image
            break;

        case "morning_lark":
            imageSrc = Morning_Lark;
            break;

        case "afternoon_loon":
            imageSrc = Afternoon_Loon;
            break;

        case "evening_eagle":
            imageSrc = Evening_Eagle;
            break;

        case "all_day_albatross":
            imageSrc = All_Day_Albatross;
            break;

        default:
            break;
    }

    return imageSrc;
}