import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Button } from "@mui/material"
import CanvasLogo from '../../../../images/canvas_white.png';
import { useStyles } from './styles';

const SkeletonMyCourse = () => {
    const classes = useStyles()

    return (
        <div data-testid="skeleton">
            <div className={classes.header}>
                <div className={classes.topRow}>
                    <div>
                        <Skeleton height={30} width={160} className={classes.courseTitle} />
                    </div>
                    <Button variant="contained" classes={{ root: classes.viewCourse }} disabled><img className={classes.canvasLogo} src={CanvasLogo} alt="Canvas Logo" />View Courses</Button>
                </div>
                <Skeleton height={23} width={150} className={classes.sectionTitle} />
                <div className={classes.selectorWrap}>
                    <div className={`${classes.selector} ${classes.selected} ${classes.selectorDisabled}`}>
                        This Week
                    </div>
                    <div className={`${classes.selector} ${classes.selectorDisabled}`}>
                        Past Due
                    </div>
                </div>
            </div>
            <div className={classes.content}>
                {
                    Array.from(new Array(4)).map((value, index) =>
                        <Skeleton key={index} varient="rectangular" width={400} height={25} className={`${classes.upcomingItem} ${classes.skeletonUpcomingItem}`} />
                    )
                }
            </div>
        </div>
    )
}
export default SkeletonMyCourse;