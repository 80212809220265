import { makeStyles } from "@mui/styles"
import React from "react"

const useStyles = makeStyles(theme => ({
    sizer: {
        position: 'absolute',
        top: 0,
        left: 0,
        visibility: 'hidden',
        height: 0,
        overflow: 'scroll',
        whiteSpace: 'pre',
        ...theme.paragraphFont
    },
    input: {
        ...theme.paragraphFont,
        color: "#14606B",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid #14606B !important",
        height: "35px",
        textAlign: "left",
        "&:focus": {
            outline: "none"
        },
        "&::placeholder": {
            ...theme.paragraphFont,
            opacity: 0.5,
            color: "#14606B",
        },

    }
}))

export const LocationTextField = (params) => {
    const classes = useStyles()
    const [width, setWidth] = React.useState(40)
    const sizer = React.useRef()
    const placeholderSizer = React.useRef()

    const updateWidth = () => {
        if (!sizer || !sizer.current || typeof sizer.current.scrollWidth === 'undefined') {
            return;
        }
        let newWidth = params.placeholder
            ? Math.max(sizer.current.scrollWidth, placeholderSizer.current?.scrollWidth) + 2
            : sizer.current.scrollWidth + 2
        if (newWidth !== width) {
            setWidth(newWidth)
        }
    }
    React.useEffect(() => { updateWidth() }, [params.value, sizer, placeholderSizer])

    return (
        <div style={{ whiteSpace: "nowrap" }} ref={params.InputProps.ref}>
            <input
                type="text"
                name={params.name}
                value={params.value ? params.value : ""}
                id={params.id}
                onChange={params.onChange}
                placeholder={params.placeholder}
                data-lpignore="true"
                {...params.inputProps}
                style={{ width: width, maxWidth: "100%" }}

                className={`${classes.input} ${params.className ? params.className : undefined}`}
                disabled={params.disabled}
            />
            <span className={`${classes.sizer} ${params.className || ""}`} ref={sizer}>{params.value}</span>
            {params.placeholder
                ? <span className={`${classes.sizer} ${params.className || ""}`} ref={placeholderSizer}>{params.placeholder}</span>
                : null
            }
        </div>
    )



}