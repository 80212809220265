import React, { useCallback, useEffect, useState } from "react";

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Tooltip } from "@mui/material";
const VolumeMute = ({
    videoRef
}) => {

    const [isMuted, setIsMuted] = useState(false);

    useEffect(() => {
        let controller = new AbortController();

        if (videoRef?.current) {

            videoRef.current.addEventListener('loadedmetadata', function () {
                setIsMuted(videoRef.current?.muted);
            }, { signal: controller.signal });

            videoRef.current.addEventListener('volumechange', function () {
                setIsMuted(videoRef.current?.muted);
            }, { signal: controller.signal });

        }

        return (() => {
            controller.abort();
        })

    }, [videoRef]);

    /** Mute Button Handler */
    const handleVolumeMute = useCallback(() => {
        if (isMuted) {
            videoRef.current.muted = false;
        } else {
            videoRef.current.muted = true;
        }
    }, [videoRef, isMuted])


    return (
        <Tooltip style={{ display: 'flex', alignItems: 'center' }} title={isMuted ? 'UnMute' : 'Mute'} onClick={() => handleVolumeMute()}>
            {
                isMuted ?
                    <VolumeOffIcon fontSize="medium" /> :
                    <VolumeUpIcon fontSize="medium" />
            }
        </Tooltip>
    );
};

export default VolumeMute;
