import { Container } from '@mui/material';
import React, { useContext, useEffect } from 'react';

import { ACPAnnouncementContext, ACPDataContext } from '../../context';
import { useStyles } from '../baseLayout/styles';
import AnnouncementHistoryList from './AnnouncementHistoryList';
import AnnouncementLeftMenu from './AnnouncementLeftMenu';
import CreateNewAnnouncementForm from './CreateNewAnnouncementForm';

const Announcement = () => {

    const classes = useStyles();

    const { createButtonRef } = useContext(ACPDataContext);
    const { showCreateAnnouncementModal, setShowCreateAnnouncementModal } = useContext(ACPAnnouncementContext);

    useEffect(() => {

        //controller to abort the execution of the function and remove listner
        const controller = new AbortController();

        //Create Button Ref. EventHandler is added to add new Appoinments
        if (createButtonRef?.current) {
            createButtonRef.current.addEventListener('click', function () {
                setShowCreateAnnouncementModal(true);
            }, { signal: controller.signal })
        }

        return (() => {
            controller.abort(); // remove listener onunmount
        });

    }, []);

    return (
        <>
            <div className={classes.lBarRightPaneLevel2Menu}>
                <div className={classes.lBarRightPaneLevel2MenuPad}>
                    <AnnouncementLeftMenu />
                </div>
            </div>
            <div className={`${classes.lBarRightPaneContentContainer} `} style={{ padding: 0, '& .MuiContainer-root': { padding: 0 } }}>
                <Container className='acp-announcement-page'>
                    <AnnouncementHistoryList />
                </Container>
            </div>
            {
                showCreateAnnouncementModal ?
                    <CreateNewAnnouncementForm
                        modalOpen={showCreateAnnouncementModal}
                        handleCloseCallback={() => { setShowCreateAnnouncementModal(false) }}
                    /> :
                    null
            }

        </>
    )

}


export default Announcement;