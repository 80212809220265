import { makeStyles } from "@mui/styles";

const useModalStyles = makeStyles((theme) => ({
    centerModal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    wrapper: {
        width: "358px",
        maxHeight: "80vh",
        overflowX: "hidden",
        overflowY: "auto",
        borderRadius: "10px",
        background: "#FFFFFF",
        padding: "25px",
        outline: "none",
        "& p": {
            margin: 0
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "flex-start"
    },
    subheader: {
        display: "flex",
        justifyContent: "space-between",
    },
    title: {
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: "#14606B"
    },
    image: {
        width: "358px",
        marginTop: "30px",
        marginBottom: "28px",
    },
    subtitle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
    },
    action: {
        fontFamily: "Nunito",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        margin: "20px 0 10px 0 !important",
        "& strong": {
            color: "#14606B"
        }
    },
    joinButton: {
        ...theme.elements.tealButton,
        fontSize: "14px !important",
        fontWeight: "600 !important",
        width: "100%",
        textDecoration: "none",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginBottom: "42px !important"
        }
    },
    nameCard: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "500",
        marginBottom: "0px",
        color: "black",
        padding: "5px",
        borderRadius: "5px",
    },
    profileNameCard: {
        '&:hover': {
            position: "relative",
            zIndex: 0,
            backgroundColor: "#E0FBFF",
            cursor: "pointer"
        },
    },
    studentAvatar: {
        border: "2.5px solid " + theme.colors.deepAqua + "!important",
        marginRight: "15px"
    },
    staffAvatar: {
        border: "2.5px solid " + theme.colors.deepBlue + "!important",
        marginRight: "15px"
    },
    link: {
        textDecoration: "none"
    }
}))

export default useModalStyles;