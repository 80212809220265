import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { v4 as uuid4 } from 'uuid';

import Image from '../../../../images/kenzieAvatar.png';
import OnlineNow from '../../../../images/online_now_icon.png';
import People from '../../../../images/people_icon.png';
import RecentlyUpdated from '../../../../images/recently_updated_icon.png';

import useStyles from './prestartPanelStyles';

import { AppDataContext } from '../../../../context';
import { LearnersDataContext } from '../../../context';
import { checkProfileUpdateIndication } from '../../learnerStaffDirectory/helpers';
import { filterClassmates } from './helpers';

const PrestartConnectPanel = React.memo(({ cohortInfo = [] }) => {

    const classes = useStyles();

    const [cohortSize, setCohortSize] = useState(0);
    const [onlineNow, setOnlineNow] = useState(0);
    const [recentlyUpdated, setRecentlyUpdated] = useState(0);
    const [showcaseLearner, setShowcaseLearner] = useState(false)

    const { userCourse } = React.useContext(LearnersDataContext);
    const { userData } = React.useContext(AppDataContext)

    useEffect(() => {
        if (userCourse?.course_id && cohortInfo.length > 0) {
            //Aborts the async call if props change
            let controller = new AbortController();

  

            let cohorts = cohortInfo;

            /**
                * Filter cohort user dataset. 
            */
            let dataset = filterClassmates(cohorts[0]["mk_user_courses"], userData);

            setCohortSize(dataset.length);

            // Remove logged in user from Classmates
            const classmates = dataset.filter(classmate => classmate.user_email !== userData.email);

            // Count online and updated
            let countOnline = 0;
            let countUpdated = 0;

            classmates.forEach(student => {
                student["user_email_mk_user"].is_online && countOnline++;
                checkProfileUpdateIndication(student.user_email_mk_user.user_profile_updated_date) && countUpdated++;
            });

            setOnlineNow(countOnline);
            setRecentlyUpdated(countUpdated);

            let cohortWithoutFavorites = [];
            // Cover edge case of No favorites - both null and empty array []
            if (userData.user_favorite_profiles && userData.user_favorite_profiles.length > 0) {
                // Remove Favorites 
                cohortWithoutFavorites = classmates.reduce((acc, ci) => {
                    let exists = userData.user_favorite_profiles.find(i => i.user_email === ci.user_email);
                    if (!exists) {
                        acc.push(ci);
                    }
                    return acc;
                }, []);
            } else {
                cohortWithoutFavorites = classmates;
            }
            // Pick a random learner to showcase
            if (cohortWithoutFavorites.length) {
                const randomLearnerIndex = Math.floor(Math.random() * cohortWithoutFavorites.length);
                const randomLearner = cohortWithoutFavorites[randomLearnerIndex].user_email_mk_user;
                setShowcaseLearner(randomLearner);
            } else {
                // Edge case for if learner has favorited every member of their cohort
                setShowcaseLearner(false);
            }


            return () => controller?.abort();//Aborts the async call if props change 
        }

    }, [userCourse]);

    // Stuff to compute in this component: onlineNow, recentlyUpdated, showcaseLearner, 

    const profileURL = "/profile/";

    return (
        <>
            <Link className={classes.link} to="/connect">
                <div className={classes.topRow}>
                    <img style={{ height: "18px", width: "18px" }} className={classes.logo} id="connect-panel-user-image" src={People} alt="people icon" />
                    <span className={classes.counts}>{cohortSize}</span>
                    {cohortInfo[0]?.cohort_name}
                </div>
            </Link>
            <div className={classes.row}>
                <img style={{ height: "15px", width: "15px", margin: "0 12.5px 0 2.5px" }} className={classes.logo} id="online-now-icon" src={OnlineNow} alt="online now icon" />
                <span className={classes.counts}>{onlineNow}</span>Online Now</div>
            <div className={classes.row}>
                <img style={{ height: "20px", width: "20px" }} className={classes.logo} id="recently-updated-icon" src={RecentlyUpdated} alt="recently updated icon" />
                <span className={classes.counts}>{recentlyUpdated}</span>Recently Updated</div>
            <div>
                {showcaseLearner && (
                    <div className={classes.showcase}>
                        <div className={classes.subtitle}>Have you met...</div>
                        <Link key={uuid4()} to={`${profileURL}${showcaseLearner?.email}`}>
                            <div className={classes.profile} key={showcaseLearner?.email}>
                                <div className={classes.profileImage}>
                                    <img id="profileImage"
                                        className={classes.Image}
                                        src={showcaseLearner?.user_image_url === null ? Image : showcaseLearner?.user_image_url}
                                        alt={Image}
                                        onError={(e) => e.target.src = Image} />
                                </div>
                                <div className={classes.profileContent}>
                                    <p className={classes.namePtag}>
                                        {showcaseLearner?.first_name ? showcaseLearner?.first_name : "Unknown"}
                                        {showcaseLearner?.last_name ? ` ${showcaseLearner?.last_name}` : " Unknown"}
                                    </p>
                                    {showcaseLearner?.user_timezone && <p className={classes.timezonePtag}>{showcaseLearner?.user_timezone.time_zone_name}</p>}
                                </div>
                            </div>
                        </Link>
                    </div>)}
            </div>
        </>
    )
})
export default PrestartConnectPanel;
