import React, { useEffect, useRef, useState } from 'react';

import { MenuItem, Skeleton, TextField } from '@mui/material';

import { useStyles } from "./styles";

import { useACPDataContextV2 } from '../../../context/ACPDataStoreV2';
import { getCoursesFromCanvas } from '../../../../api';
import { hideOffFocusEventHandler } from '../../../../helpers/utils';

export const SearchCourseField = () => {

    const classes = useStyles();

    const { courseAssociations, setCourseAssociations, selectedCohortId } = useACPDataContextV2()

    const containerRef = useRef(null);
    const inputFieldRef = useRef(null);

    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [hideSearchResultsBox, setHideSearchResultsBox] = useState(true);

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    };

    useEffect(() => {
        if (containerRef !== null) {
            hideOffFocusEventHandler(containerRef.current, () => {
                setHideSearchResultsBox(true);
            })
        }
    }, [])

    useEffect(() => {
        if (hideSearchResultsBox) {
            inputFieldRef.current.value = "";
        }
    }, [hideSearchResultsBox]);

    //** When user searches in the searchbox */
    const handleSearchInput = async (event) => {

        try {
            if (event.target.value.length === 0) {
                setSearchResults([]);

            } else {
                setIsSearching(true);
                setHideSearchResultsBox(false);

                //Grab all courses from canvas
                //Everytime user enters name it searches canvas via an api and send back the results
                let courseSearchResponse = await getCoursesFromCanvas({
                    "search": event.target.value
                });

                let searches = courseSearchResponse.reduce((acc, ci) => {
                    //Checks if course already linked to cohort. If not show in search results
                    let alreadyExists = courseAssociations["active"].find(i => parseInt(i.lms_course_id) === ci.id);

                    //performing regex search for the entered input text
                    if (!alreadyExists) {
                        acc.push({
                            "subscribed_canvas": ci.subscribed_canvas,
                            "coursename": ci.name,
                            "lms_course_id": ci.course_id,
                            "cohort_id": selectedCohortId
                        })
                    }

                    return acc;
                }, [])
                //search thru dataset 
                setSearchResults(searches);
                setIsSearching(false);
            }

        } catch (error) {
            console.error(error)
        }

    };

    //** Triggers when user selects the course. Note course is saved once user hits save button which mounts on the EachCohortCourse component */
    const handleOnSelection = (courseInfo) => {
        let d = [...courseAssociations["active"]];
        d.push(Object.assign(courseInfo, {
            isNew: true
        }));
        setCourseAssociations({...courseAssociations, active: d});
        setHideSearchResultsBox(true);
    }

    return (
        <div className={classes.searchInputBlock} ref={containerRef}>
            <div>
                <TextField
                    autoComplete="off"
                    inputRef={inputFieldRef}
                    onFocus={() => { setHideSearchResultsBox(false) }}
                    type="text"
                    placeholder='Search and add courses by ID...'
                    onInput={debounce(handleSearchInput, 300)}
                    sx={{
                        width: "100%",
                        '& .MuiOutlinedInput-input': {
                            padding: '8px',
                            fontSize: 14
                        }
                    }}
                />
            </div>

            {
                !hideSearchResultsBox ?
                    <div className={classes.searchedItems}>
                        {
                            isSearching ?
                                <div >
                                    <div>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </div>
                                </div> :
                                !isSearching && searchResults.length > 0 ?
                                    <>
                                        {
                                            searchResults.map(item => {
                                                return <div key={`${item.subscribed_canvas ? "New LMS: " : "Old LMS: "}${item.lms_course_id}`}
                                                    style={{
                                                        marginBottom: "10px"
                                                    }}>
                                                    <MenuItem
                                                        className='open-sans search-item-container'
                                                        onClick={() => handleOnSelection(item)}>
                                                            <div style={{marginRight:"2px"}}>[{item.subscribed_canvas ? "New LMS: " : "Old LMS: "}{item.lms_course_id}]</div>
                                                            <div className='search-result-text'>{item.coursename}</div>
                                                    </MenuItem>
                                                </div>
                                            })
                                        }
                                    </>
                                    :
                                    !isSearching && searchResults.length === 0 ?
                                        <div className={classes.searchedItem_noresult}>No results to display search with course id</div> :
                                        null
                        }
                    </div> :
                    null
            }
        </div>
    )
}