import React, { useContext, useEffect, useState } from 'react';

import { CohortSettingsContext } from '..';
import userRoles from '../../../..//helpers/userRoles';
import { AppDataContext } from '../../../../context';
import { ACPDataContext } from '../../../context';
import { useCohortSettingStyles } from '../styles';
import EachCohortStaff from './EachCohortStaff';

export const CohortStaffDataContext = React.createContext();

const CohortStaffSettingsPage = () => {

    const cohortSettingClasses = useCohortSettingStyles();

    const { selectedACPSettingsCohortId, fetchACPSettingsData, updateCohortData } = useContext(ACPDataContext);
    const { setComponentLoaded } = useContext(CohortSettingsContext);
    const { setIsAllComponentsLoaded } = useContext(AppDataContext);

    let [staff, setStaff] = useState(null);

    useEffect(() => {
        try {
            if (selectedACPSettingsCohortId) {

                fetchACPSettingsData('staff').then(response => {
                    //;

                    response = massageStaffData(response);

                    setStaff(response);
                    setIsAllComponentsLoaded(true); //removes parent kenzie loader
                    

                }).catch(err => console.error(err));
            }
        } catch (error) {
            console.error(error);
        }
    }, [selectedACPSettingsCohortId]);

    useEffect(()=>{
        if(staff){
            setComponentLoaded("supportStaff");
        }
    },[staff]);

    //Massages the data to show only user which are suppose to be show in UI
    const massageStaffData = (dataset) => {

        //Remove all the users who are not supposed to be displayed in ACP
        let visibleRoles = userRoles.filter(i => i.isVisibleInACP === true).map(x => x.id);
        return dataset.filter(i => visibleRoles.includes(i.user_role_id));

    }

    const removeStaffFromCohort = (staffMember) => {
        try {
            // 
            let d = [...staff];

            let indx = d.findIndex(j => j.id === staffMember.id && j.cohort_id === staffMember.cohort_id);
            ;
            if (indx > -1) {
                d.splice(indx, 1);

                updateCohortData({
                    "dataset": staffMember,
                    "cohortId": staffMember.cohort_id,
                    "itemId": staffMember.id,
                    "remove": true,
                    "key": "staff"
                });

                setStaff(d);
            }

        } catch (error) {
            console.error(error);
        }
    }

    //** Updates the staff members for entire cohort */
    //** Updates the state variable and also cache information in ACP Datastore */
    const updateCohortStaffMembersState = (data) => {
        try {
            let d = [...staff];

            
            for (let i = 0; i < data.length; i++) {

                let indx = d.findIndex(j => j.id === data[i].id && j.cohort_id === data[i].cohort_id);

                if (indx > -1) {
                    d[indx] = data[i]
                } else {
                    d.push(data[i]);
                }

                updateCohortData({
                    "dataset": data[i],
                    "cohortId": data[i].cohort_id,
                    "itemId": data[i].id,
                    "remove": false,
                    "assumeNew":true,
                    "key": "staff"
                });
            }

            setStaff(d);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <CohortStaffDataContext.Provider value={{
            "staff": staff,
            "updateCohortStaffMembersState": updateCohortStaffMembersState,
            "removeStaffFromCohort": removeStaffFromCohort,
            "massageStaffData": massageStaffData
        }}>
            {
                selectedACPSettingsCohortId && staff ?
                    <>
                        {
                            selectedACPSettingsCohortId.map(cohortId => {
                                return <div 
                                        id="supportstaff-settings"
                                        className={`${cohortSettingClasses.eachCohortSection}`}
                                        key={`cohort-staff-${cohortId}`}>
                                    <EachCohortStaff
                                        staff={staff.filter(i => i.cohort_id === cohortId)}
                                        cohortId={cohortId} />
                                </div>
                            })
                        }
                    </> :
                    null
            }
        </CohortStaffDataContext.Provider>
    )

}

export default CohortStaffSettingsPage;