import React, { useContext } from 'react';

import { getSocialMediaIcon } from './helpers';
import useStyles from './styles';
import { UserProfileContext } from './UserProfileContext';

const UserSocialMediaLinks = () => {

    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext)

    /**
     * Render a list of external social media links excluding 
     * slack because slack is an internal contact resource (and only in the header)
     */
    const mapSocialMediaContacts = () => {
        return profileData.user_social_media_links.filter(i => i.name.toLowerCase() !== "slack" && i.name.length > 0 && i.link.length > 0);
    }

    return (
        <>
            {
                profileData && profileData.user_social_media_links
                    && profileData.user_social_media_links.length > 0
                    && mapSocialMediaContacts().length > 0 ?



                    <div className={classes.socialMediaLinksContainer}>
                        {
                            mapSocialMediaContacts().filter(item => item.name.length > 0 && item.link.length > 0).map(item => (
                          
                                   <div key={item.id}
                                        title={item.name}
                                        className={classes.contactIcons} data-type={item.name}>
                                        <a href={item.link}
                                        rel="noreferrer"
                                            target="_blank">
                                            {
                                                getSocialMediaIcon(item.name)
                                            }
                                        </a>
                                    </div>
                               
                            ))
                        }


                    </div> :
                    null
            }
        </>

    )
}

export default UserSocialMediaLinks;