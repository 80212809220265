import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import ToggleSwitch from "../StyledSwitch";

import StyledSelect from "../StyledSelect";
import StyleTextInput from "../StyledTextInput";
import { UserSearchInput } from './UserSearchInput';
export default function AddMemberFormRow({
    member = {
        first_name: null,
        last_name: null,
        email: null,
        active: null,
        user_role_id: null,
        user_lms_id: null,
        is_visible: null,

    },
    showBorder,
    errors,
    value,
    index,
    handleRemoveRow,
    setValue,
    watch,
    control,
    register,



}) {

    const [switchState, setSwitchState] = useState(true);
    const [firstNameDisabled, setFirstNameDisabled] = useState(false)
    const [lastNameDisabled, setLastNameDisabled] = useState(false)
    const [lmsDisabled, setLmsDisabled] = useState(false)
    const [selectedEmail, setSelectedEmail] = useState('')
    const [fieldsAreDisabled, setFieldsAreDisabled] = useState(false)
    const toggleDisabledFields = (val) => {
        setFirstNameDisabled(val)
        setLastNameDisabled(val)
        setLmsDisabled(val)
        setFieldsAreDisabled(val)
    }
    const watchEmail = watch(`addMembers[${index}].email`)
    const watchRole = watch(`addMembers[${index}].user_role_id`)


    useEffect(() => {


        if (fieldsAreDisabled && watchEmail !== selectedEmail) {
            toggleDisabledFields(false)
        }
    }, [fieldsAreDisabled, watchEmail])


    return (
        <>


            <form
                autoComplete="off"

                className="add-member-table-row"
                style={{
                    borderBottom: showBorder ? "1px solid #D9D9D9" : 0,
                    gridTemplateColumns: "20% 20% 15% 15% 15% 10% 5%"

                }}
            >
                <input autoComplete="off" name="hidden" type="text" style={{ display: 'none' }}></input>
                <div className="manage-cohort-table-cell">
                    <UserSearchInput
                        hasError={errors?.email}
                        currentInputVal={watchEmail}
                        selectedEmail={selectedEmail}
                        inputProps={{


                            ...register(`addMembers[${index}].email`, {
                                required: true,
                                pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                            })
                        }
                        } onUserSelection={data => {
                            const { email, first_name, last_name, user_lms_id } = data
                            setValue(`addMembers[${index}].first_name`, first_name)
                            setValue(`addMembers[${index}].last_name`, last_name)
                            setValue(`addMembers[${index}].email`, email)
                            setValue(`addMembers[${index}].user_lms_id`, user_lms_id)
                            setSelectedEmail(email)
                            toggleDisabledFields(true)
                        }

                        } />


                </div>
                <div className="manage-cohort-table-cell">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyleTextInput
                            style={{ marginRight: "2px", width: "50%" }}
                            hasError={errors?.first_name}
                            inputProps={{
                                placeholder: 'First Name',
                                disabled: firstNameDisabled,
                                name: "first-name",

                                ...register(`addMembers[${index}].first_name`, {
                                    required: true
                                })
                            }}
                        />
                        <StyleTextInput
                            style={{ width: "50%" }}
                            hasError={errors?.last_name}
                            inputProps={{
                                placeholder: 'Last Name',
                                name: "last-name",
                                disabled: lastNameDisabled,


                                ...register(`addMembers[${index}].last_name`, {
                                    required: true
                                })
                            }}
                        />
                    </div>
                </div>

                <div
                    className="manage-cohort-table-cell"
                    style={{
                        fontSize: "12px",
                    }}
                >
                    <StyledSelect
                        name="active"
                        value={'true'}
                        options={[{ title: "Active", value: 'true' }, { title: "Inactive", value: 'false' }]}
                        inputProps={{
                            ...register(`addMembers[${index}].active`)
                        }}
                    />
                </div>
                <div
                    className="manage-cohort-table-cell"
                    style={{
                        fontSize: "12px",
                        color: member.user_role_id === 1 ? "#000000" : "#013DA4"
                    }}
                >

                    <StyledSelect
                        name="role"
                        value={1}
                        options={[{ title: 'Learner', value: 1 }, { title: "Coach", value: 5 }, { title: "Facilitator", value: 2 }, { title: 'Admin', value: 9 }, { title: 'SME', value: 14 }, { title: 'Support', value: 15 }]}
                        inputProps={{
                            ...register(`addMembers[${index}].user_role_id`)
                        }}
                    />

                </div>
                <div className="manage-cohort-table-cell">

                    <StyleTextInput
                        style={{ width: '60%' }}
                        hasError={errors?.user_lms_id}
                        inputProps={{ placeholder: 'LMS ID', disabled: lmsDisabled, ...register(`addMembers[${index}].user_lms_id`, { required: watchRole === 1 }) }}
                    />

                </div>
                <div className="manage-cohort-table-cell" style={{ marginLeft: '2px' }}>
                    <ToggleSwitch
                        checked={switchState}
                        onClick={() => setSwitchState(!switchState)}
                        inputProps={{ ...register(`addMembers[${index}].is_visible`) }}
                    />
                </div>
                {index !== 0 &&
                    <DeleteIcon sx={{ color: '#C2573F' }} className='pointer' onClick={() => handleRemoveRow(index)} />
                }
            </form>
        </>
    );
}


