import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';


import useEditProfileStyles from '../../../sharedComponents/editProfileFields/styles/editProfileStyles';
import GrowingInputContainer from './GrowingInputContainer';

const UserAdditionalDemographics = () => {

    const editProfileClasses = useEditProfileStyles();


    let {
        values,
        handleBlur,
        handleChange,
    } = useFormikContext();



    return (<>


        <GrowingInputContainer
            label="In my free time, I enjoy "
            className={editProfileClasses.growingInputText}
            id="user_free_time"
            name="user_free_time"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='playing with my son and pets'
            value={values.user_free_time || ""}

        />

        <GrowingInputContainer
            label="A fun fact about me is  "
            className={editProfileClasses.growingInputText}
            id="user_fun_fact"
            name="user_fun_fact"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='I competed in a national singing competition'
            value={values.user_fun_fact || ""}

        />

        <GrowingInputContainer
            label="My dream job is "
            className={editProfileClasses.growingInputText}
            id="user_dream_job"
            name="user_dream_job"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='System architect at Google...'
            value={values.user_dream_job ? values.user_dream_job : ""}
        />

        <p className={editProfileClasses.fieldLabelText} style={{ margin: '30px 0 15px 0' }}>Introduction</p>
        <TextField
            InputProps={{ disableUnderline: true }}
            className={editProfileClasses.defaultFont}
            multiline
            variant="standard"
            placeholder='I am excited to be here!'
            minRows={4}
            sx={textAreaStyles}
            id="user_intro_interest"
            name="user_intro_interest"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.user_intro_interest ? values.user_intro_interest : ""}
        />


        <p className={editProfileClasses.fieldLabelText} style={{ margin: '30px 0 15px 0' }}>Goals</p>
        <TextField
            InputProps={{ disableUnderline: true }}
            multiline
            variant="standard"
            placeholder='In future I would love to..'
            minRows={4}
            sx={textAreaStyles}
            id="user_goals"
            name="user_goals"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.user_goals ? values.user_goals : ""}
        />



    </>)
}

const textAreaStyles = {
    width: "100% !important",
    fontFamily: "Raleway !important",
    border: '1.5px solid #14606B',
    borderRadius: '5px',
    padding: '10px',
    boxSizing: 'border-box',
    "& textarea": {
        fontSize: '14px !important',
        lineHeight: '150%'
    },
    '&::before': {
        borderBottom: 0
    },
    '&::after': {
        borderBottom: 0
    }

}

export default UserAdditionalDemographics;