import React, { useContext, useEffect, useState } from "react";

import { MediaContext } from "../../index";

import { getPlayTimeFormat, getVideoTotalTime } from "../../../watch/utils";

const PlayTimeDisplay = ({
    videoRef
}) => {

    const { videoInfo } = useContext(MediaContext);

    const [videoPosition, setVideoPosition] = useState(0);//keeps track of current position of video being played in seconds

    useEffect(() => {

        let controller = new AbortController();

        if (videoRef?.current) {
            videoRef.current.addEventListener('timeupdate', function () {
                setVideoPosition(videoRef.current?.currentTime);
            }, { signal: controller.signal });

        }

        return (() => {
            controller.abort();
        });

    }, [videoRef]);

    return (
        <div className="video-playback-position-time">
            <span>{getPlayTimeFormat(videoPosition)}</span>
            <span> / </span>
            <span>{getVideoTotalTime(videoInfo?.video_details.recording_start, videoInfo?.video_details.recording_end)}</span>
        </div>
    );
};

export default PlayTimeDisplay;
