import React,{useEffect} from 'react';
import {Snackbar, Alert} from '@mui/material';
import {v4 as uuid4} from 'uuid';

/**
 * @description: OnSuccess snakbar
 * @param {*} param0 
 * @returns 
 */
export const SuccessSnackBar=({
    alignment={vertical:'bottom',horizontal:'right'},
    message="Success",
    handleOnClose=function(){}
})=>{
    
    useEffect(()=>{

        //The snackbar should disappera after 8 secs
        let timeOut = setTimeout(function(){
            handleOnClose();
        },8000);

        return () => clearTimeout(timeOut);

    },[])

    return (<Snackbar
        sx={{
            fontFamily:"Raleway",
            fontSize:"14px",
            zIndex:1500
        }}
        anchorOrigin={alignment}
        open={true}
        onClose={handleOnClose}
        key={uuid4()}
        severity="success"
    >
        <Alert onClose={handleOnClose} severity="success" sx={{ width: '100%' }}>
            {message}
        </Alert>

    </Snackbar>)
}

/**
 * @description - On Error snackbar
 * @param {*} param0 
 * @returns 
 */
export const ErrorSnackBar=({
    alignment={vertical:'bottom',horizontal:'right'},
    message="Error",
    handleOnClose=function(){}
})=>{

    useEffect(()=>{
        //The snackbar should disappera after 8 secs
        let timeOut = setTimeout(function(){
            handleOnClose();
        },8000);

        return () => clearTimeout(timeOut);
        
    },[])

    return (<Snackbar
        sx={{
            fontFamily:"Raleway",
            fontSize:"14px",
            zIndex:1
        }}
        anchorOrigin={alignment}
        open={true}
        onClose={handleOnClose}
        message={message}
        key={uuid4()}
        severity="error"
    >
        <Alert onClose={handleOnClose} severity="error" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>)
}