import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    landingPageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    landingPageContent: {
        display: "flex",
        transform: "scale(.9)",
        [theme.breakpoints.down('lg')]: {
            transform: "scale(.8)"
        },
        [theme.breakpoints.down('md')]: {
            transform: "scale(.7)"
        },
        [theme.breakpoints.down(800)]: {
            transform: "scale(.6)"
        },
        [theme.breakpoints.down(700)]: {
            transform: "scale(.55)"
        },
        [theme.breakpoints.down('sm')]: {
            transform: "scale(1)",
            width: "90vw",
            flexDirection: "column",
            marginBottom: "20px"
        }
    },
    landingPageCTA: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "113px",
        [theme.breakpoints.down('sm')]: {
            height: "100vh",
            marginRight: "0px",
            marginBottom: "70px"
        },
        [theme.breakpoints.down(380)]: {
           transform: "scale(.8)"
        },
        [theme.breakpoints.down(330)]: {
            transform: "scale(.75)"
         }
    },
    leftSlide: {
        animationName: "$leftSlide",
        animationDuration: "1s",
        animationIterationCount: 1, 
        animationFillMode: "forwards"
    },
    '@keyframes leftSlide': {
        "0%": {
            opacity: 0,
            transform: "translate(-200px, 0px)"
        },
        "100%": {
            opacity: 1,
            transform: "translate(0px, 0px)"
        }
    },
    mkLogo: {
        marginBottom: "40px",
       '& svg': {
           height: "111px",
           width: "319px"
       }
    },
    loginBtn: {
        ...theme.elements.darkBlueButton,
        borderRadius: "50px !important",
        width: "190px",
        height: "42px",
    },
    itSupportText: {
        position: "absolute",
        bottom: "15px",
        textAlign: "center",
        fontSize: "14px",
        '& p': {
            margin: "0px"
        },
        '& a': {
            textDecoration: "underline"
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: "50px"
        }
    },
    landingPageVideo: {
        position: "relative",
        height: "718px",
        width: "550px",
        backgroundColor: "#E4EEFF",
        borderRadius: "50px",
        '& video': {
            position: "absolute",
            objectFit: "cover",
            zIndex: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50px",
            border: "1.5px solid #1D3969",
        },
        [theme.breakpoints.down('sm')]: {
            // width: "90vw"
            display: "none"
        }
    },
    rightSlide: {
        animationName: "$rightSlide",
        animationDuration: "1.5s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
    },
    '@keyframes rightSlide': {
        "0%": {
            opacity: 0,
            transform: "translate(0px, 3000px)"
        },
        "100%": {
            opacity: 1,
            transform: "translate(0px, 0px)"
        }
    }
}))

export default useStyles;