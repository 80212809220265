
import { Button, capitalize } from '@mui/material';
import React, {useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { v4 as uuid4 } from 'uuid';
import userRoles from '../../../../helpers/userRoles';
import { getUserDisplayName } from "../../../../helpers/utils";
import Image from '../../../../images/kenzieAvatar.png';
import useStyles from './styles';
import { LearnersDataContext } from '../../../context';

const SupportTeamList = ({
    supportTeam,
}) => {

    const {setHelpOpen} = useContext(LearnersDataContext)
    const classes = useStyles()
    const history = useHistory()
    const profileURL = "/profile/"
    
    let supportTeamList = [];
  
    for(let member of supportTeam) {
        supportTeamList.push({
            "first_name": member.first_name || member.user_email_mk_user.first_name,
            "last_name": member.last_name || member.user_email_mk_user.last_name,
            "email": member.username || member.user_email,
            "user_image_url": member?.user_image_url || member?.user_email_mk_user?.user_image_url,  
            "user_role_id": member.user_role_id,
            "preferred_name": member?.preferred_name || member?.user_email_mk_user?.preferred_name
        })
    }

    return (<>
        {

            supportTeamList.map(member => {
                return (
                    <Link key={uuid4()} to={`${profileURL}${member.email}`}>
                        <div className={classes.profile} key={member.email}>
                            <div className={classes.profileImage}>
                                <img id="profileImage"
                                    className={classes.Image}
                                    src={member.user_image_url !== null && member.user_image_url !== undefined ? member.user_image_url : Image}
                                    alt={`${getUserDisplayName(member)} ${member?.last_name !== null ? ` ${member.last_name}` : " Unknown"} - ${capitalize(userRoles.find(i => i.id === member.user_role_id).uiName)}`}
                                    onError={(e) => e.target.src = Image} />
                            </div>
                            <div className={classes.profileContent}>
                                <p className={classes.namePtag}>
                                    {getUserDisplayName(member)}
                                    {member?.last_name !== null ? ` ${member.last_name}` : " Unknown"}
                                </p>
                                <p className={classes.positionPtag}>{capitalize(userRoles.find(i => i.id === member.user_role_id).uiName)}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
            
        }
        <div className={classes.button}>
            <Button
                className={classes.requestSupport}
                onClick={(e) => {history.push("/help"); setHelpOpen(true)}}
                variant="contained" color="primary">Request Support</Button>
        </div>
    </>)
}

export default SupportTeamList;