import {useEffect, useState} from "react"
import styled from "@emotion/styled"
import Modal from "@mui/material/Modal"
import useModalStyles from "./modalStyles"
import EachParticipant from "./EachParticipant"
import img from "../../../../images/study-session-group.png"
import X from "../../../../icons/x"
import CircularLoader from "../../../../sharedComponents/CircularLoader"
import { addStudySessionTopic } from "../../../../api"

const SessionModal = ({
    modalOpen, 
    handleModalClose, 
    active, 
    learnerParticipants, 
    guestParticipants, 
    staffParticipants,
    cohortInfo,
    userData,
    mainTopic,
    topics}) => {
    
    const helperText = active ? 
                        "What would you like to accomplish in this session?" :
                        "Let your classmates know what you're working on...*"
    const classes = useModalStyles()

    const [ loading, setLoading ] = useState(false)
    const [ topic, setTopic ] = useState("")

    useEffect(() => {
        console.log(topics)
    }, [topics])

    const addTopic = async (e) => {
        if(cohortInfo && cohortInfo.zoom_host_id && userData && topic && topic !== "") {
            setLoading(true)
            await addStudySessionTopic(cohortInfo.zoom_host_id, {userEmail: userData.email, topic: topic})
            setTopic("")
        }
        setLoading(false)
        handleModalClose()
        window.open(cohortInfo.zoom_url, "_blank")
    }
    return (
        <Modal open={modalOpen} onClose={handleModalClose} disableAutoFocus={true} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <ModalDiv>
                <CircleX onClick={handleModalClose}>
                    <XSpan><X /></XSpan>
                </CircleX>
                <img src={img} alt="Group of learners collaborating" width={355}></img>
                <Title>Cohort Study Session</Title>
                <TextField placeholder={helperText} active={active} value={topic} onChange={(e) => setTopic(e.target.value)}/>
                {loading && <CircularLoader styles={{height: "20px !important", width: "20px !important", marginLeft: "225px", marginTop: "20px", marginBottom: "5px"}}/>}
                {!loading && <StyledButton 
                // This ID is associated with GA4. If changed, update Google Tag Manager event tags associated with study session buttons. This ID differentiates between multiple study session buttons.
                    id={active ? "join_now" : "start_session"}
                    floatLeft 
                    floatDown 
                    marginRight="30px" 
                    onClick={addTopic}
                    disabled={(!active && topic === "") ? true : false}
                    >
                        {active ? "Join Now" : "Start Session"}
                    </StyledButton>}
                {active && mainTopic?.topic &&
                    <LeftDiv>
                        <p className={classes.action}>Topics:</p>
                        <TopicElement>{mainTopic.topic}</TopicElement>
                        {(topics && topics.length > 0) &&
                            topics.map((eachTopic, i) => (
                                <TopicElement key={i}>{eachTopic.topic}</TopicElement>
                            ))
                        }
                    </LeftDiv>
                }
                {active &&
                        staffParticipants.length > 0 &&
                        <LeftDiv>
                            <p className={classes.action}>Staff in session: <strong>{staffParticipants.length}</strong></p>
                            {
                                staffParticipants.map(learner => {
                                    return <EachParticipant
                                        key={learner.user_id}
                                        isStaff={true}
                                        participant={learner} />
                                })
                            }
                        </LeftDiv>
                }
                {active &&
                        (learnerParticipants.length > 0 || guestParticipants.length > 0) &&
                        <LeftDiv>
                            <p className={classes.action}>Classmates in session: <strong>{learnerParticipants.length + guestParticipants.length}</strong></p>
                            {
                                learnerParticipants.map(learner => {
                                    return <EachParticipant
                                        key={learner.user_id}
                                        participant={learner}
                                    />
                                })
                            }
                            {
                                guestParticipants.map(guest => {
                                    return <EachParticipant
                                        key={guest.user_id}
                                        isGuest={true}
                                        participant={guest} />
                                })
                            }
                        </LeftDiv>
                }
            </ModalDiv>
        </Modal>
    )
}

const TextField = (props) => {
    return (
        <div style={{"display": "flex", "flexDirection": "column", "alignItems": "flex-end"}}>
            <FullRowInputField {...props} />
            {!props.active && 
                <HelperText>*Required</HelperText>
            }
        </div>
    )
}

const TopicElement = styled.p`
    font-family: Nunito;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #424242;
    &:before {
        content: '';
        border-radius: 0.375rem;
        height: 0.5rem;
        width: 0.5rem;
        margin-right: 0.5rem;
        background-color: #14606B;
        display: inline-block;
    }
`

const LeftDiv = styled.div`
    align-self: flex-start;
    margin-left: 30px;
`

const ModalDiv = styled.div`
    width: 420px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
`

const Title = styled.p`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
color: #000000;
align-self: flex-start;
margin-left: 30px;
`

const FullRowInputField = styled.input`
width: 359px;
height: 40px;
box-sizing: border-box;
border: 1px solid #424242;
border-radius: 5px;
padding-left: 10px;
`

const HelperText = styled.p`
font-family: 'Open Sans';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 14px;
color: #414141;
float: right;
`

const StyledButton = styled.button`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
width: 150px;
box-sizing: border-box;
text-decoration: none;
background: #14606B;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
padding: 9px;
color: #FFFFFF;
cursor: pointer;
border-width: 0px;
grid-area: ${props => props.gridArea ? props.gridArea : null};
margin-left: ${props => props.floatLeft ? 'auto' : null};
margin-right: ${props => props.marginRight || "0px"};
margin-top: 15px;
/* align-self: ${props => props.gridArea ? 'center' : null}; */
transition: all .300s;
&:hover{
    box-shadow: 2px 3px 6px #606060;
};
&:disabled{
    background: rgba(20, 96, 107, 0.5);
    &:hover{
        box-shadow: none;
    };
}
`

const CircleX = styled.p`
display: flex;
justify-content: center;
align-items: center;
border: 2px solid rgba(104, 104, 104, 1);
border-radius: 50%;
height: 15px;
width: 15px;
&:hover: {
    background-color: rgb(240,240,240);
    cursor: pointer;
}
position: absolute;
top: 0;
right: 20px; 
`
const XSpan = styled.span`
display: flex;
justify-content: center;
align-items: center;
`
export default SessionModal