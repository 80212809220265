import { memo, useState } from "react";

import { ReactComponent as RightArrowIcon } from '../../../../../icons/carousel_right_arrow.svg'
import { Collapse } from '@mui/material';
import styled from "@emotion/styled";
import { PROGRAMS } from "../../../../constants";
import { useACPDataContextV2 } from "../../../../context/ACPDataStoreV2";
import CircularLoader from "../../../../../sharedComponents/CircularLoader";
export default memo(function ActiveCohortPanel() {
    const [expand, setExpand] = useState({})
    const { adminPrograms } = useACPDataContextV2()

    const handleExpansion = (program) => {
        setExpand((prev) => ({ ...prev, [program.id]: !prev[program.id] }))
    }


    return (
        <CohortPanel>
            <PanelTitleContainer>
                <PanelTitle>
                    Inactive Cohorts
                </PanelTitle>
            </PanelTitleContainer>
            <PanelBody>
                {
                    Object.values(adminPrograms).length ?
                        <>
                            <div className="panel-subtext">
                                <p>These cohorts were previously enrolled in a program with Kenzie Academy.</p>

                            </div>
                            <div className="inactive-cohort-program-list">
                                {
                                    adminPrograms?.active?.sort((a, b) => {
                                        return PROGRAMS.find(p => p.id === a.id).title > PROGRAMS.find(p => p.id === b.id).title ? 1 : -1
                                    }).map((program) => (
                                        <div key={program.id}>
                                            <div className="program-expansion" >
                                                <HeadingText style={{ marginBottom: "5px" }}>{PROGRAMS.find(p => p.id === program.id).title}</HeadingText>
                                                <RightArrowIcon className={`expansion-arrow ${expand[program.id] ? 'expand-animation' : 'collapse-animation'}`} onClick={() => handleExpansion(program)} />
                                            </div>
                                            <div className="inactive-cohort-list">
                                                <Collapse in={expand[program.id]} key={`${program.title}-${program.id}`}>
                                                    {

                                                        program?.cohorts?.inactive?.length ?
                                                            program.cohorts.inactive.map(c => (
                                                                <p>{c.cohort_name}</p>

                                                            ))
                                                            :

                                                            <p className="cohort-text">No cohorts found</p>
                                                    }
                                                </Collapse>

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                        :


                        <CircularLoader styles={{ marginTop: '24px' }} />

                }
            </PanelBody>
        </CohortPanel>

    )
})

export const CohortPanel = styled.div`
width:100%;
height: 671px;
border: 2px solid #C7C7C7;
border-radius: 10px;
box-sizing: border-box;
overflow: hidden;
background-color: #FFFF;
`

export const PanelTitleContainer = styled.div`
display: flex;
align-items: center;
border-radius: 4px 4px 0 0 ;
background-color:  #445879;
height: 45px;
padding: 0 19px;
`

export const PanelTitle = styled.h2`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #ffffff;
margin: 0;
`

export const PanelBody = styled.div`
max-height: 620px;
overflow-y: scroll;
padding:  0 19px 20px 19px;
flex-direction: column;
box-sizing: border-box;
`

export const HeadingText = styled.h3`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #1D3969;

margin-bottom: 20px;
margin-top: 35px;
`