import React, { useContext, useEffect, useRef, useState } from "react";

import { AppDataContext } from "../../context";
import { ACPDataContext } from './ACPDataContext';

import {
    getAllCourses, getAllWebtools, getAnnouncements,
    getCohortCalendars, getCohorts, getInstructorsByCohortId, getPrograms, getSupportRequests, getWebtoolsByCohortId
} from '../../api';
import { getAllMembersByCohortId } from "../pages/adminSettings/learnersManagement/api";
//Default Object to set when user selects a cohort
//This will allow us to reuse the object in the ref variable inside the componenet
const DEFAULT_COHORT_DATASET = {
    supportRequests: null,
    announcements: null,
    calendars: null,
    webtools: null,
    staff: null,
    courses: null,
    cohortcourses: null,
    zoomstudysession: null
};

const ADMIN_SETTINGS_DATASET = {
    cohorts: null,
    programs: null,
    courses: null,
    learners: null,
    members: null
}

/**
 * @description - Verifies if the ids stored in localstorage, user still have access to those courses, if not remove them
 * @param {*} key 
 * @param {*} courses 
 * @returns 
 */


/** DATA STORE **/
export const ACPDataStore = ({ children }) => {

    const { userData, setIsAllComponentsLoaded } = useContext(AppDataContext);

    let [userCourses, setUserCourses] = useState([]);

    //Cohort Ids used for support, announcements pages 
    let [selectedCohortIds, setSelectedCohortIds] = useState(null);

    //Cohort Settings and Admin pages 
    //Only One Cohort can be selected for Cohort Settings and admin
    //Thats the reason we have 2 seperate cohortId states 
    //This is an array even though only one element is going to be stored in array. this makes implementation little easier.
    let [selectedACPSettingsCohortId, setSelectedACPSettingsCohortId] = useState(null);

    let createButtonRef = React.createRef(null);

    /**
     * Ref Variable storing the acp data
     * This ref variable stores the data set for all the cohorts selected 
     * this ref variable is updated everytime there is change to the cohort drop down value 
     */
    let acpDataRef = useRef([]);
    let acpInitialLoad = useRef(false);

    //Stores the data for all webtools and announcements for everyone, since those are by default fetched for announcements and webtools settings page
    let allWebToolsRef = useRef([]);
    let annoucementForEveryoneRef = useRef(null);

    //Stores the data for admin settings 
    let adminSettingsRef = useRef({ ...ADMIN_SETTINGS_DATASET });

    /** UseEffect on UserData change */
    useEffect(() => {

        if (userData && acpInitialLoad.current === false) {

            //sets the initial load 
            //acpInitialLoad avoid to relad the data or reset the default cohort value if userData is updated
            acpInitialLoad.current = true;

            //Feddback to App.js to remove the kenzie loader
            setIsAllComponentsLoaded(true);

        }

        //** Resets the user courses when userData is set. This will refresh the data for userCourses automatically***/
        if (userData?.mk_user_courses) {
            //userCohorts/Courses stores all the courses linked to user and are active
            setUserCourses(userData.mk_user_courses ? userData.mk_user_courses : []);

            // //By default the first cohort is selected for the user for loading the data 
            // let firstCohort = userData.mk_user_courses[0]?.cohort;
            // //Auto Sets the first cohort by default on login 
            // let cohortIdsFromStorage = cleanLocalStorageForCohortIds('selectedCohortIds', userData.mk_user_courses);
            // setSelectedCohortIds(cohortIdsFromStorage?.length > 0 ? cohortIdsFromStorage : [firstCohort.id]);

            // //Auto Sets the first cohort by default on login for ACP Settings
            // let acpSettingsCohortIdsFromStorage = cleanLocalStorageForCohortIds('selectedACPSettingsCohortIds', userData.mk_user_courses);
            // setSelectedACPSettingsCohortId(acpSettingsCohortIdsFromStorage.length > 0 ? acpSettingsCohortIdsFromStorage : [firstCohort.id]);

        }

    }, [userData]);

    /**
     * Side effect is triggered every time sleected cohort values is changes
     */
    useEffect(() => {
        if (selectedCohortIds && selectedCohortIds.length > 0) {

            //check if all selected cohorts exists in the ref variable
            //If not adds the deault dataset  
            selectedCohortIds.forEach(id => {
                addCohortDatasetInRef(id);
            });
        }

    }, [selectedCohortIds]);

    /**
     * Side effect is triggered every time selected cohort values is changed for cohort settings or admin page
     */
    useEffect(() => {
        if (selectedACPSettingsCohortId && selectedACPSettingsCohortId.length > 0) {

            //check if all selected cohorts exists in the ref variable
            //If not adds the deault dataset  
            selectedACPSettingsCohortId.forEach(id => {
                addCohortDatasetInRef(id);
            });
        }

    }, [selectedACPSettingsCohortId]);

    //*** checks and insert Cohort Dataset in acpDataRef Ref *** 
    const addCohortDatasetInRef = (cohortId) => {
        let info = checkIfCohortDataExistsInRef(cohortId);
        //;
        if (!info) {
            acpDataRef.current.push(Object.assign({ ...DEFAULT_COHORT_DATASET }, {
                "id": cohortId
            }));
        }
    }

    /**
     * @description- Checks if the data ref has the cohort data set
     * @param {*} cohortId 
     * @returns 
     */
    const checkIfCohortDataExistsInRef = (cohortId) => {
        return acpDataRef.current.find(i => parseInt(i.id) === parseInt(cohortId));
    }

    /**
     * @description - Fetches all webtools from MK_WEBTOOLS table, NOT from MK_WEBTOOLS_COURSE
     * @returns webtools array
     */
    const fetchAllWebtools = async () => {
        try {
            if (allWebToolsRef.current.length === 0) {
                allWebToolsRef.current = await getAllWebtools();
            }
            return allWebToolsRef.current;

        } catch (error) {
            throw error;
        }
    }

    /**
     * @description - Fetch Announcements for all
     * @param {*} key 
     * @returns 
     */
    const fetchAnnoucementsForEveryone = async () => {
        try {
            if (annoucementForEveryoneRef.current === null) {
                annoucementForEveryoneRef.current = await getAnnouncements({
                    'audience': 1,
                    'expand': 'cohorts,countusers,learnersOnly',
                });
            }

            return annoucementForEveryoneRef.current;

        } catch (error) {
            throw error;
        }
    }

    /**
     * @description - Update Annoucements for everyone
     * @param {*} key 
     * @param {*} options 
     * @returns 
     */
    const updateAnnoucementsForEveryone = async (dataset, remove = false) => {

        try {

            let d = [...annoucementForEveryoneRef.current];

            let indx = d.findIndex(i => i.id === dataset.id);

            if (indx > -1 && !remove) {
                d[indx] = Object.assign(d[indx], dataset)
            } else if (indx > -1 && remove) {
                d.splice(indx, 1);
            } else {
                d.push(d)
            }

            annoucementForEveryoneRef.current = d;

            return annoucementForEveryoneRef.current;

        } catch (error) {
            throw error;
        }
    }

    /**
     * @description - fetches the cohort information fromt he ref
     * @param {*} cohortId 
     * @param {*} key - defines the page/component the user is viewing. The key passed ftom the component itself
     *            options - defines additional data to be passed for fetching
     */
    const fetchCohortData = async (key, options) => {

        let response = [];

        if (selectedCohortIds) {
            //Loops thru the selected cohorts and gets the data from cache or fetches from the database

            for await (let cohortId of selectedCohortIds) {

                //Check if cohort data already exists in cache
                let cohortInfo = checkIfCohortDataExistsInRef(cohortId);

                //If cohort Data exists, check if cohortData exists for the specific key 
                //e.g., supportRequests is key. When support Requests component 
                //if mounted, the component can request the data from the data store by passing in supportRequests key
                //If data doesnt exists, get the data from database
                if (cohortInfo && cohortInfo[key]) {
                    response = response.concat(cohortInfo[key]);

                } else {
                    //Get the data from
                    let indx = acpDataRef.current.findIndex(i => i.id === cohortId);

                    switch (key) {
                        case "supportRequests":
                            acpDataRef.current[indx][key] = await getSupportRequests(cohortId);
                            break;

                        case "announcements":
                            //announcement api request requires query to be passed
                            acpDataRef.current[indx][key] = await getAnnouncements({
                                "cohort_id": cohortId,
                                'expand': 'cohorts,countusers,learnersOnly'
                            });
                            break;



                        default:
                            break;
                    }

                    response = response.concat(acpDataRef.current[indx][key]);
                }

            }

        }

        return response;

    }

    /**
     * @description - fetches the cohort information from the ref for cohort settings and admin page
     * @param {*} cohortId 
     * @param {*} key - defines the page/component the user is viewing. The key passed ftom the component itself
     *            options - defines additional data to be passed for fetching
     */
    const fetchACPSettingsData = async (key, options) => {

        let response = [];

        //SelectedACP SettingsCohort ID i sset when user selects the cohortId from the drop down under cohort settings page
        if (selectedACPSettingsCohortId) {

            //Loops thru the selected cohorts and gets the data from cache or fetches from the database
            for await (let cohortId of selectedACPSettingsCohortId) {

                //Check if cohort data already exists in cache
                let cohortInfo = checkIfCohortDataExistsInRef(cohortId);

                //If cohort Data exists, check if cohortData exists for the specific key 
                //e.g., supportRequests is key. When support Requests component 
                //if mounted, the component can request the data from the data store by passing in supportRequests key
                //If data doesnt exists, get the data from database
                if (cohortInfo && cohortInfo[key]) {
                    response = response.concat(cohortInfo[key]);

                } else {
                    //Data is stored in array object format in the ref variable
                    /**
                     * [
                     *   {
                     *      cohortId:<cohortId#1>,
                     *      calendars:[],
                     *      webtools:[],
                     *      staff:[],
                     *      courseS:[]
                     *   }
                     * ]
                     */
                    //Indx for the cohortId passed
                    let indx = acpDataRef.current.findIndex(i => i.id === cohortId);

                    switch (key) {
                        case "cohortcourses":
                            let res = await getCohorts({
                                "id": cohortId,
                                "expand": "courses"
                            });

                            acpDataRef.current[indx][key] = res.length > 0 && res[0]?.program_mappings ? res[0].program_mappings : [];

                            break;

                        case "calendars":

                            acpDataRef.current[indx][key] = await getCohortCalendars(cohortId);
                            break;

                        case "webtools":
                            acpDataRef.current[indx][key] = await getWebtoolsByCohortId(cohortId, {
                                "limit": 100
                            });
                            break;

                        case "staff":
                            acpDataRef.current[indx][key] = await getInstructorsByCohortId(cohortId);
                            break;


                        default:
                            break;
                    }

                    response = response.concat(acpDataRef.current[indx][key]);
                }

            }

        }

        return response;

    }

    /**
    * @description update the cohort cached dataset  
    * @param {Object} { 
    *  key:String,//Can be what is stored in the ref variable see the initialization of cohort ref variable
    *  cohortId:Integer, 
    *  itemId:Integer, 
    *  remove:Boolean, 
    *  dataset:Object,//values to be inserted 
    *  isNew:Boolean,
    *  assumeNew:Boolean //if isNew is not defined explicitely, assumeNew means if data is not there consider it to be new and push it to the dataset
    * }
    */
    const updateCohortData = (params = null) => {

        if (params !== null) {

            let cohortDataIndx = acpDataRef.current.findIndex(i => i.id === params.cohortId);

            //does cohort information exists
            //its assumed that cohort information is in the ref variable before updating the cohort data
            if (cohortDataIndx > -1) {

                //check if items exists for the cohort related data 
                //If the value of of the item is null, push the new value if isNew is true
                //If items exists get the item index and remove or update the dataset 
                let items = acpDataRef.current[cohortDataIndx][params.key];

                if (items) {
                    let itemIndx = items.findIndex(i => i.id === params.itemId);

                    //item exists in the cache ref variable
                    if (itemIndx > -1) {
                        if (params.remove) {//if remove is false, remove is true when item is deleted
                            acpDataRef.current[cohortDataIndx][params.key].splice(itemIndx, 1)
                        } else {
                            acpDataRef.current[cohortDataIndx][params.key][itemIndx] = Object.assign(acpDataRef.current[cohortDataIndx][params.key][itemIndx], params.dataset);
                        }
                        //If the values already exists in the param.key and item with itemId doesnt exists 
                        //push the new item in the cache
                    } else if (params.isNew || params.assumeNew) {
                        acpDataRef.current[cohortDataIndx][params.key].push(params.dataset);
                    }

                } else if (params.isNew) {
                    acpDataRef.current[cohortDataIndx][params.key] = [];
                    acpDataRef.current[cohortDataIndx][params.key].push(params.dataset);
                }
            }
        }

    }

    const fetchCohortMemberData = async (key, options) => {

        const cohortId = selectedACPSettingsCohortId[0]

        const result = await getAllMembersByCohortId(cohortId);
        return result
    }

    /**
     * @description: Fetch Data for Admin Settings. 
     *  Key is object name.  Allowed values `cohorts | programs`
     * @param {*} params 
     */
    const fetchAdminSettingsData = async (key, options) => {


        try {
            if (adminSettingsRef.current[key] === null) {
                switch (key) {
                    case "cohorts":
                        adminSettingsRef.current[key] = await getCohorts({
                            limit: 200
                        });
                        break;

                    case "programs":
                        adminSettingsRef.current[key] = await getPrograms({
                            "expand": "cohorts,courses"
                        });
                        break;

                    case "courses":
                        adminSettingsRef.current[key] = await getAllCourses();
                        break;




                    default:
                        break;
                }

                return adminSettingsRef.current[key];

            } else {
                return adminSettingsRef.current[key];
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * @description updates the ref variable per the params passed
     * @param {*} 
     * key:String,//Can be what is stored in the ref variable see the initialization of adminsettings ref variable
     * itemId:Integer,
     * itemIdKey:String //value to identify the item
     * remove:Boolean,
     * isNew:Boolean,
     * dataset:Object,//values to be inserted
     * assumeNew:Boolean //if isNew is not defined explicitely, assumeNew means if data is not there consider it to be new and push it to the dataset
     */
    const updateAdminSettings = (params = null) => {
        try {
            if (params && adminSettingsRef.current[params.key]) {

                if (params.isNew || params.assumeNew) {
                    adminSettingsRef.current[params.key].push(params.dataset);

                } else if (params.itemId) {
                    let itemIndx = adminSettingsRef.current[params.key].findIndex(i => parseInt(i[params.itemIdKey]) === parseInt(params.itemId));
                    if (itemIndx > -1) {
                        //update the value by merging the dataset the entire 
                        adminSettingsRef.current[params.key][itemIndx] = Object.assign(adminSettingsRef.current[params.key][itemIndx], params.dataset);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    /** Set Provider Values */
    return (
        <ACPDataContext.Provider value={{
            "userCourses": userCourses,
            "setSelectedCohortIds": setSelectedCohortIds,
            "selectedCohortIds": selectedCohortIds,
            "selectedACPSettingsCohortId": selectedACPSettingsCohortId,
            "setSelectedACPSettingsCohortId": setSelectedACPSettingsCohortId,
            "fetchCohortData": fetchCohortData,
            "fetchACPSettingsData": fetchACPSettingsData,
            "fetchAdminSettingsData": fetchAdminSettingsData,
            "updateAdminSettings": updateAdminSettings,
            "fetchAllWebtools": fetchAllWebtools,
            'fetchAnnoucementsForEveryone': fetchAnnoucementsForEveryone,
            "updateAnnoucementsForEveryone": updateAnnoucementsForEveryone,
            "updateCohortData": updateCohortData,
            "createButtonRef": createButtonRef,
            "fetchCohortMemberData": fetchCohortMemberData,
        }}>
            {children}
        </ACPDataContext.Provider>
    )
}