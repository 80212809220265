import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    acpStudySessionParentContainer: {

        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'start',
        alignItems: "center",
        backgroundColor: "#EDEDED",
        height: '100%',
        boxSizing: 'border-box',
        '& .each-connect-panel-container': {
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1.5px solid #14606B",
            padding: "20px 15px 10px 15px",
            margin: "0 10px 15px 10px",
            height: "200px",
            position: "relative",
            width: "350px",

            '& .session-title-text': {
                fontWeight: 500,
                color: "#616161",
                marginTop: "15px 0px"
            },

            '& .cohort-name': {
                color: "#1D3969",
                fontSize: "14px",
                margin: "15px 0px",
                fontWeight: 700
            },

            '& .participants-section-title': {
                margin: "10px 0px",
                fontSize: "14px",
                fontWeight: 700,
                color: "#14606B"
            }

        }
    },

    sessionButtonContainer: {
        marginTop: 'auto',
        bottom: "15px",
        left: "0px",
        right: "0px",
        textTransform: "none",
        padding: "10px",

        '& .session-button': {
            borderRadius: "34px",
            height: "35px",
            minWidth: "128px",
            fontSize: "12px",
            fontWeight: 700,
            padding: "0px 10px",
            cursor: "pointer",
            width: "100%",
            '&:disabled': {
                opacity: 0.5,
                cursor: "not-allowed",
            },
        },

        '& .session-start': {
            backgroundColor: "white",
            color: theme.colors.deepAqua,
            border: "2.5px solid " + theme.colors.deepAqua,
        },

        '& .join-session': {
            backgroundColor: theme.colors.deepAqua,
            color: "white",
            border: "2.5px solid " + theme.colors.deepAqua,
        }
    },

    sessionParticipantsContainer: {
        height: '100%',
        boxSizing: 'border-box',

        // paddingTop: '20px',

        '& .participants-inner-container': {
            // display: "grid",
            // gridTemplateColumns: "1fr 2fr",
            // columnGap: "15px",
            display: 'grid',
            gridTemplateColumns: '3fr 1fr',
            gridTemplateRows: '1fr 1fr',
            gridTemplateAreas: '"staff btn" "guest guest"',
            '& .MuiAvatarGroup-root': {
                justifyContent: "start"
            }
        }
    },

    /** Modal Styles. Might need to merge with learner dashboard styles */
    centerModal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    modalWrapper: {
        [theme.breakpoints.up("md")]: {
            width: "70%",
            padding: "20px 40px",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
            padding: "25px",
        },
        overflowX: "hidden",
        borderRadius: "10px",
        background: "#FFFFFF",

        '& .modal-header': {
            display: "flex",
            justifyContent: "space-between",
        },

        '& #participants-modal-participants-list': {
            margin: "50px 10px 50px 0",
            overflowY: "auto",
            overflowX: 'hidden',
            maxHeight: '600px',
            '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: '#F5f5f5'
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                backgroundColor: '#F5f5f5'

            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                backgroundColor: theme.colors.deepAqua
            },

            '& #participant-modal-learners-in-session': {
                marginTop: "35px"
            },

            '& .participant-grid-layout': {
                marginTop: "20px",
                display: "grid",
                rowGap: "12px",
                columnGap: "12px",
                [theme.breakpoints.up("md")]: {
                    gridTemplateColumns: 'repeat(5, 1fr [col-start])',
                },
                [theme.breakpoints.down("md")]: {
                    gridTemplateColumns: 'repeat(3, 1fr [col-start])',
                },
            },

            '& .participant-name-avatar': {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                columnGap: "5px",
                padding: "8px",
                '& .user-name': {
                    fontSize: "14px",
                    fontWeight: "500"
                }
            },

            '& .profile-name-card': {
                '&:hover': {
                    backgroundColor: "#E0FBFF",
                    cursor: "pointer"
                }
            }

        }

    },

    profileNameCard: {
        '&:hover': {
            position: "relative",
            zIndex: 0,
            backgroundColor: "#E0FBFF",
            cursor: "pointer"
        },
    },

    studentAvatar: {
        border: "2.5px solid " + theme.colors.deepAqua + "!important",
        marginRight: "15px"
    },
    staffAvatar: {
        border: "2.5px solid " + theme.colors.deepBlue + "!important",
        marginRight: "15px"
    }
}));
export default useStyles;