import { DashboardSection, SectionTitleContainer, SectionTitle, SectionBody, FullWidthRow, HeadingText, StyledButton, } from "../Dashboard"
import EachACPVideoModal from '../../videoLibrary/EachACPVideoModal'
import { stripLeadingPunctuation } from "../../../../learner/pages/watch/utils"
import { useState } from "react"
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2"
import styled from "@emotion/styled"
import moment from "moment-timezone"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Link } from "react-router-dom";

export default function LatestVideoPanel() {
    const {
        videos,
        userIsCohortCoach
    } = useACPDataContextV2()
    const [showVideoModal, setShowVideoModal] = useState(false)

    const formatDuration = () => {
        const start = videos.mostRecent.video_details.recording_start
        const end = videos.mostRecent.video_details.recording_end
        const seconds = moment(end).diff(moment(start), 'seconds')
        return (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
    }
    const formatUploadedTime = () => {
        return moment(videos.mostRecent.created_date).format('MM/DD/YY h:MMA')

    }

    return (
        <DashboardSection gridArea='vid'>
            <SectionTitleContainer>
                <SectionTitle>
                    Latest Video Upload
                </SectionTitle>
            </SectionTitleContainer>
            <SectionBody>
                {videos?.mostRecent ?

                    <>
                        <FullWidthRow topRow>

                            <HeadingText>
                                {stripLeadingPunctuation(videos?.mostRecent.title)}
                            </HeadingText>
                            {!userIsCohortCoach ?
                                <StyledButton floatLeft onClick={() => setShowVideoModal(true)}>Curate Video</StyledButton>
                                : null}
                            {showVideoModal ? <EachACPVideoModal video={videos.mostRecent} isOpen={showVideoModal} handleClose={() => setShowVideoModal(false)} /> : null}
                        </FullWidthRow>

                        <div style={{ gridArea: 'content', display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            <Thumbnail to={'/media/' + videos.mostRecent.uuid}>
                                {videos.mostRecent?.thumbnail_cdn_url ?

                                    <ThumbnailImage src={videos.mostRecent.thumbnail_cdn_url} />
                                    : null}
                            </Thumbnail>
                            <VideoMetaDataContainer>
                                <div className="row">
                                    <p>Uploaded:</p>
                                    <p className="duration">{formatUploadedTime()}</p>
                                </div>
                                <div className="row">
                                    <p>Duration:</p>
                                    <p className="duration">{formatDuration()}</p>
                                </div>
                                <div className="row">
                                    <p>Transcript File:</p>
                                    {videos?.mostRecent?.transcripts_cdn_url ? <CheckCircleIcon sx={checkIconStyles} /> : <HighlightOffIcon sx={clearIconStyles} />}
                                </div>
                                <div className="row">
                                    <p>Chat File:</p>
                                    {videos?.mostRecent?.chat_cdn_url ? <CheckCircleIcon sx={checkIconStyles} /> : <HighlightOffIcon sx={clearIconStyles} />}

                                </div>
                            </VideoMetaDataContainer>
                        </div>

                    </>
                    :
                    <HeadingText style={{ marginTop: '25px' }}>
                        No recordings have been uploaded for this cohort
                    </HeadingText>
                }



            </SectionBody>




        </DashboardSection>
    )
}

const checkIconStyles = {
    width: '18px',
    height: '18px',
    color: '#1A8400',
    marginLeft: 'auto'
}
const clearIconStyles = {
    width: '18px',
    height: '18px',
    marginLeft: 'auto',
    color: '#C2573F'
}

const Thumbnail = styled(Link)`
    text-decoration: none;
    width: calc(1vw * 16);
    height: calc(1vw * 9);
    max-width: 307px;
    max-height: 173px;
    background-color: #000;
    display: flex;
    border: 1px solid #C7C7C7;
border-radius: 5px;
overflow: hidden;
`

const ThumbnailImage = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
z-index: 9;

`

const VideoMetaDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-left: 1vw;
    width: 50%;
& .row{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    & p{
        margin:0;
    }
    & .duration{
        margin-left:auto;
    }
    & .checkmark{
        margin-left: auto;
        margin-right: 2vw;
    }
}

`