import { makeStyles } from '@mui/styles';
const SCALED_IMAGE_SIZE = 260;

/** Image Uploaded Styles */
const useProfileImageUploader = makeStyles((theme) => ({
    title: {
        ...theme.elements.title,
        color: theme.colors.deepAqua
    },
    subtitle: { ...theme.elements.subtitle, lineHeight: 1.2 },
    imageUploaderContainer: {
        margin: "10px 0px",
        position: 'relative'
    },
    imageUploaderButtonContainer: {
        border: "1px dashed",
        height: "230px",
        borderRadius: "5px",
    },
    submitButton: {
        ...theme.elements.darkBlueButton,
        backgroundColor: `${theme.colors.deepAqua} !important`
    },
    cancelButton: {
        ...theme.elements.subtitle,
        color: `${theme.colors.deepAqua} !important`,
        textTransform: "capitalize !important",
        marginRight: "15px !important"
    },
    removeButton: {
        ...theme.elements.subtitle,
        color: `${theme.colors.rust} !important`,
        textTransform: "capitalize !important"
    },
    fileUploadInput: {
        opacity: 0,
        position: "absolute",
        width: "100%",
        left: 0,
        height: "100%",
        cursor: "pointer"
    },
    displayUploadedImageContainer: {
        width: `${SCALED_IMAGE_SIZE}px`,
        height: `${SCALED_IMAGE_SIZE}px`,
        margin: "auto",
        textAlign: "center",
        border: "1px solid #1D3969",
        position: "relative",
        overflow: 'hidden',
        "&:hover": {
            cursor: "grabbing"
        },
        "& img": {
            transformOrigin: "130px 130px",
            position: "absolute"
        },
        //Creates the shadow around the image for opacity look
        "&::before": {
            content: '""',
            top: "-65px",
            left: "-65px",
            right: "-65px",
            bottom: "-65px",
            position: "absolute",
            borderColor: "gray",
            border: "66px solid white",
            borderRadius: "50%",
            opacity: "0.6",
            zIndex: 1
        }
    },
    gridLines: {
        position: "absolute",
        border: "0.5px solid #FFFFFF",
        opacity: ' 0.3'
    },
    horizontalGridLine: {
        left: 0,
        right: 0
    },
    verticalGridLine: {
        top: 0,
        bottom: 0
    },
    rVertical: {
        left: "70%"
    },
    lVertical: {
        left: "30%"
    },
    tHorizontal: {
        top: "30%"
    },
    bHorizontal: {
        bottom: "30%"
    }
}));

/** Profile Image Styles */
const useProfileImage = makeStyles((theme) => ({
    avatarContainer: {
        "& img": {
            opacity: 0.8,
            borderRadius: "100%"
        },
        "& svg": {
            opacity: 0.5
        },
        "&:hover": {
            cursor: 'pointer',
            "& img": {
                opacity: 0.5
            },
            "& svg": {
                opacity: 0.9
            },
        }
    },
    editButtonAlignment: {
        "zIndex": 1,
        "position": "absolute",
        "top": "40%",
        "transform": 'translate(-50%, 0%)',
        "textAlign": "center",
        "left": "50% ",
        [theme.breakpoints.down('md')]: {
            "left": "50% !important",
        }
    }
}))

/** Export Modules */
export {
    useProfileImageUploader,
    useProfileImage
};