import React, { useContext, useEffect } from 'react';
import {
    Redirect, Route, useHistory,
    useLocation
} from "react-router-dom";


import Announcement from '../pages/announcements';
import SupportRequests from '../pages/requests';

import CohortSettings from '../pages/cohortSettings';

// import StaffEditProfilePage from '../pages/staffEditProfile/StaffEditProfilePage';
import { ACPAnnouncementStore, ACPSupportRequestsDataStore } from '../context';

import { AppDataContext } from '../../context';
import { checkIfUserHaveAccessToPage } from '../../helpers/userRolesHelper';

import useDocumentTitle from "../../helpers/useDocumentTitle";

//Profile Page 
import ProfilePage from '../../pages/profilePage/ProfilePage';

//ACP L Bar
import ACPLBar from '../pages/baseLayout/ACPLBar';

import LearnerEditProfilePage from '../../learner/pages/learnerEditProfile/LearnerEditProfilePage';
import ACPVideoLibrary from '../pages/videoLibrary';
import DashboardV2 from '../pages/v2/Dashboard';
import PeoplePage from '../pages/adminSettings/learnersManagement/index'
import LearnerFacingIndividualVideoPage from '../../learner/pages/media/index'
import ManageCohortPage from '../../acp/pages/adminSettings/cohortManagement/indexV2'
import { useACPDataContextV2 } from '../context/ACPDataStoreV2';
const ACPRouter = () => {

    const { userRole, gtmUserId } = useContext(AppDataContext);
    const { hasCurrentCohortAccess } = useACPDataContextV2()
    useDocumentTitle();

    let history = useHistory();
    let location = useLocation();

    window.dataLayer = window.dataLayer || [];

    useEffect(async() => {
        await window.dataLayer.push({
            "event": "login",
            "method": "Google",
            "user_id": gtmUserId,
        })

    }, [])

    useEffect(async() => {
        
        await window.dataLayer.push({
            "user_id": gtmUserId
        })

        const gtmTag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PSCDHPW');`

        const gtmScript = document.createElement("script");
        gtmScript.innerHTML = gtmTag;
        gtmScript.async = true;
        document.head.append(gtmScript)
    }, [location.pathname])

    //reroutes the settings to calendar hashed element on load
    useEffect(() => {
        if (location.pathname === '/acp/settings/' && location.hash.length === 0) {
            history.push("/acp/settings/#associated-courses");
        }
    }, [location]);

    //reroutes the settings to admin settings to hashed element on load
    useEffect(() => {
        if (location.pathname === '/acp/admin/settings/' && location.hash.length === 0) {
            history.push("/acp/admin/settings/#cohort-management");
        }
    }, [location]);

    return (
        <>
            <ACPLBar>
                <Route exact path="/acp">
                    {
                        checkIfUserHaveAccessToPage("supportRequests", userRole) ?
                            <>
                                {hasCurrentCohortAccess ?
                                    <Redirect render to="/acp/listRequests/" />
                                    :
                                    <Redirect render to="/acp/listRequests/" />

                                }
                            </>
                            :
                            userRole.id === 11 ?
                                <Redirect render to="/acp/announcementHistory/" />
                                :
                                <Redirect to='/unauthorized' />

                    }
                </Route>
                <Route path="/acp/people/:roleId?">
                    {
                        checkIfUserHaveAccessToPage("supportRequests", userRole) ?
                            <>
                                {hasCurrentCohortAccess ?
                                    <PeoplePage />
                                    :
                                    <Redirect render to="/" />

                                }
                            </>

                            :
                            <Redirect to='/unauthorized' />

                    }
                </Route>
                <Route path="/acp/listRequests/">
                    {
                        checkIfUserHaveAccessToPage("supportRequests", userRole) ?
                            <>
                                {hasCurrentCohortAccess ?
                                    <ACPSupportRequestsDataStore>
                                        <SupportRequests />
                                    </ACPSupportRequestsDataStore>
                                    :
                                    <Redirect render to="/" />

                                }
                            </>

                            :
                            userRole.id === 11 ?
                                <Redirect render to="/acp/announcementHistory/" />
                                :
                                <Redirect to='/unauthorized' />

                    }
                </Route>
                <Route exact path="/media/:videoUUId">
                    {
                        <LearnerFacingIndividualVideoPage />

                    }
                </Route>
                <Route exact path="/">
                    {/* {
                        checkIfUserHaveAccessToPage("supportRequests", userRole) ?
                            <Redirect render to="/acp/listRequests/" />
                            :
                            userRole.id === 11 ?
                                <Redirect render to="/acp/announcementHistory/" />
                                :
                                <Redirect to='/unauthorized' />
                    } */}
                    <DashboardV2 />

                </Route>

                <Route exact path="/acp/videoLibrary">
                    <>
                        {hasCurrentCohortAccess ?
                            <ACPVideoLibrary />

                            :
                            <Redirect render to="/" />

                        }
                    </>
                </Route>

                {/* Redirecting old routes in case user bookmarked them  */}
                <Route exact path="/acp/settings/calendars">
                    <Redirect to='/acp/settings' />
                </Route>

                <Route path="/acp/announcementHistory">
                    {
                        checkIfUserHaveAccessToPage("announcements", userRole) ?
                            <>
                                {hasCurrentCohortAccess ?
                                    <ACPAnnouncementStore>
                                        <Announcement />
                                    </ACPAnnouncementStore>
                                    :
                                    <Redirect render to="/" />

                                }
                            </>
                            :
                            <Redirect to='/unauthorized' />
                    }
                </Route>

                {/* Redirecting old setting routes in case user bookmarked them */}
                <Route exact path="/acp/settings/calendars">
                    <Redirect to='/acp/settings' />
                </Route>
                <Route exact path="/acp/settings/webtools">
                    <Redirect to='/acp/settings' />
                </Route>
                <Route exact path="/acp/settings/supportstaff">
                    <Redirect to='/acp/settings' />
                </Route>{/* END: Redirecting old setting routes in case user bookmarked them */}

                {/* Cohort settings */}
                <Route exact path="/acp/settings/">
                    <CohortSettings />
                </Route>

                {/* Admin settings */}
                <Route exact path="/acp/admin/settings/">
                    {/* <AdminSettings /> */}
                    <ManageCohortPage />
                </Route>

                <Route exact path="/acp/staff/editprofile">
                    <LearnerEditProfilePage />
                </Route>

                {/* Profile page is added to both learner and acp*/}
                <Route exact path="/profile/:useremail">
                    <ProfilePage />
                </Route>

            </ACPLBar>

        </>
    )
}

export default ACPRouter;