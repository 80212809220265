import AboutMeIcon from '../../icons/profile-about-me.svg';
import ConnectIcon from '../../icons/profile-connect.svg';
import ExperienceIcon from '../../icons/profile-experience.svg';
import FocusTimeIcon from '../../icons/profile-focus-time.svg';
import IdentityIcon from '../../icons/profile-identity.svg';
import LocationIcon from '../../icons/profile-location.svg';
import useStyles from './styles';



export default function SectionHeader({ children, icon, title, gridArea, containerClassName, containerStyle, editView = false, titleContainerStyle}) {
    const classes = useStyles();
    const iconTitleDictionary = {
        'Identity': IdentityIcon,
        'About Me': AboutMeIcon,
        'Location': LocationIcon,
        'Experience': ExperienceIcon,
        'Focus Time & Work Schedule': FocusTimeIcon,
        'Connect': ConnectIcon
    }

    return (
        <div style={{ gridArea: gridArea, border: editView && 0, marginTop: editView && '40px', ...containerStyle }} className={`${containerClassName ? containerClassName :  ""} ${classes.profileSection}`} id={title}>
            <div className={classes.profileSectionHeader} style={editView ? { ...titleContainerStyle, marginBottom: '0px' } : titleContainerStyle}>
                <img alt="" className='profile-section-icon' src={icon || iconTitleDictionary[title] || null}></img>
                <h3 className='profile-section-title'>{title}</h3>
            </div>

            <div className={classes.profileSectionContent} id={`${title} Content`} style={{paddingRight: title === "About Me" ? "5px" : null}}>
                {children}
            </div>
        </div >
    )
}

