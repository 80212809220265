import React from 'react';
 const successIcon = () => {
 return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <circle cx="22.5" cy="22.5" r="20.5" stroke="#18CF1F" strokeWidth="4"/>
            <path d="M11 23.0556L18.2785 30L34 15" stroke="#18CF1F" strokeWidth="4" strokeLinecap="round"/>
        </svg>
    </>
)}
export default successIcon;