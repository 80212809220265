import moment from "moment";
import { useContext } from "react";

import { stripLeadingPunctuation } from "../watch/utils";
import { MediaContext } from "./index";
import Participants from "./participants";
import useStyles from "./styles";

const InfoPane = () => {

    const { videoInfo } = useContext(MediaContext)

    const classes = useStyles();
    const startDate = moment(videoInfo.video_details.recording_start);

    return (
        <div id='media-info-pane'>
            <h1 className={classes.meetingTopic}>
                {stripLeadingPunctuation(videoInfo.title)}
            </h1>
            <div className={classes.meetingDateTime}>
                {startDate.format('dddd, MMMM D | h:mm a z')}
            </div>

            <div className={classes.resourceBar}>
                <Participants />

            </div>

        </div>
    );
};

export default InfoPane;
