/** 
 * 1. Get all users who are students.
 * 2. Get all users where "is_visible" flag is true. 
 * 3. Get all users which are NOT test accounts. BUT Test account should show up if current user is test user
 * */
export const filterClassmates = (cohortUsers, currentUser) => {
    let map = {};

    let dataset = cohortUsers.reduce((acc, user) => {
        if (Number(user.user_role_id) === 1
            && user.is_visible === true
            && ((/test\.+\w*/).test(user.user_email) === false || (user.user_email === currentUser.username)) 
            && !(user.user_email in map) 
            && (user.user_email_mk_user.is_online === true)) {
                map[user.user_email]=1;
                acc.push(user);
        }
        return acc;
    }, []);
    return dataset;
}


export const getGuestLearnerAndStaff = (dataset) => {

    return Object.keys(dataset).reduce((acc, key) => {

        if (dataset[key].is_guest && dataset[key].user_left === false) {
            acc.guests.push(dataset[key]);

        } else if (!dataset[key].is_guest
            && dataset[key].user_role_id === 1 && dataset[key].user_left === false) {
            acc.learners.push(dataset[key]);

        } else if (!dataset[key].is_guest
            && dataset[key].user_role_id !== null
            && dataset[key].user_role_id !== 1 && dataset[key].user_left === false) {
            acc.staff.push(dataset[key]);
        }

        return acc;

    }, {
        guests: [],
        learners: [],
        staff: []
    });
}
