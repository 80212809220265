import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import { AppDataContext } from "../../../context";
import AnnouncementCorousal from "../../../sharedComponents/announcement/AnnouncementCorousal";

import { getTotalNumberOfActiveCohortUsers, getAnnouncements } from "../../../api";

import { getPostedBy } from './helpers';
import { PROGRAMS } from "../../constants";

import CircularLoader from "../../../sharedComponents/CircularLoader";
import { useStyles } from './styles';

const PreviewModal = ({
    values
}) => {

    const { userData } = useContext(AppDataContext);

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [cohortInfo, setCohortInfo] = useState(null);
    const [activeProgramUsers, setActiveProgramUsers] = useState(null);
    const [announcement, setAnnoucement] = useState({
        "body": values?.body,
        "post_as": values?.post_as,
        "post_schedule": values?.post_schedule,
        "post_expiration": values?.post_expiration,
        "author": userData?.username,
        "author_mk_user": {
            "preferred_name": userData?.preferred_name,
            "first_name": userData?.first_name,
            "last_name": userData?.last_name
        },
        "cohort": {},
    });

    useEffect(async() => {
        //Get total number of cohort user 
        getTotalNumberOfActiveCohortUsers({
            "id":values.cohort_id,
            "expand": "countusers",
            "countusers/user_role_id": 1
        }).then(response => {
            setCohortInfo(response?.message?.cohorts ? response.message.cohorts[0] : null);
        }).catch(err => console.error(err));

        if(values?.program_id && values?.program_id !== null && values?.cohort_id === null) {
            let programCohorts = await getAnnouncements({
                "program_id": values.program_id,
                'expand': 'cohorts,countusers,learnersOnly',
            });

            let activeUserTotal = 0;
            let cohortsChecked = []

            for(let cohort of programCohorts) {
                if(!cohortsChecked.includes(cohort.cohort_id)) {
                    activeUserTotal += cohort.active_users
                    cohortsChecked.push(cohort.cohort_id)
                } else {
                    continue
                }
            }
    
            setActiveProgramUsers(activeUserTotal)
        }

    }, []);

    useEffect(()=>{
        if(cohortInfo){
            let d={...announcement}
            d.cohort=cohortInfo;
            setAnnoucement(d);//setting cohort information in the announcement state varible
            setIsLoading(false);//Unmounts the loader, once  info is fetched from db
        }
        if(activeProgramUsers) setIsLoading(false);//Unmounts the loader, once  info is fetched from db
    },[cohortInfo]);

    const audienceDisplay = (values) => {
        if(values?.program_id && values?.program_id !== null && values?.cohort_id === null) {
            let programTitleDisplay = PROGRAMS.find((program) => program.id === values.program_id)
            return programTitleDisplay.title
        } else {
            return cohortInfo ? cohortInfo.cohort_name : ""
        }
    }

    const activeUserDisplay = (values) => {
        if(values.program_id && values.program_id !== null) {
            return `(${activeProgramUsers} Learners)`
        }
        return cohortInfo ? `(${cohortInfo.active_users} Learners)` : ""
    }

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader /> :
                announcement && !isLoading ?
                    <>
                        <AnnouncementCorousal
                            announcements={[
                                announcement
                            ]}
                            formValues={values}
                        />
                        <p className={`open-sans ${classes.descriptionText}`}>
                            Announcement scheduled to post {moment(announcement.post_schedule).format('MMMM Do, YYYY [@] hh:mm a')} and will expire {moment(announcement.post_expiration).format('MMMM Do, YYYY [@] hh:mm a')}
                        </p>
                        <p className={`open-sans ${classes.descriptionText}`}>
                            Will be posted by {getPostedBy(announcement)} and
                            seen by {audienceDisplay(values)} {activeUserDisplay(values)}
                        </p>
                    </> :
                    null
            }

        </div>
    )
}

export default PreviewModal