import { Slider as MuiSlider, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

const PROGRAMMING_EXP_LEVELS=[{
    "value":0,
    "label":'Beginner',
},{
    "value":33,
    "label":'Some Knowledge',
},{
    "value":66,
    "label":'Hobbyist',
},{
    "value":100,
    "label":'Professional',
}];

const MOBILE_PROGRAMMING_EXP_LEVELS=[{
    "value":0,
    "label":'Beginner',
},{
    "value":33,
},{
    "value":66,
},{
    "value":100,
    "label":'Professional',
}];

const Slider = styled(MuiSlider)(({theme}) => ({
    '& .MuiSlider-markLabel[data-index="3"]': {
        [theme.breakpoints.down("600")]: {
            width: "80px !important",
            transform: "translateX(-70%)"
        }
    }
  }));

export const CustomSlider = () => {
    const {
        values,
        setFieldValue
    } = useFormikContext()

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("500"));

    const [sliderValueNow,setSliderValueNow]=React.useState(values.user_programming_experience_level===null 
        || values.user_programming_experience_level==="Do not prefer"
        || values.user_programming_experience_level.length === 0?
        null
        :PROGRAMMING_EXP_LEVELS.find(i=>i.label===values.user_programming_experience_level).value);

    return (
        <Slider
            sx={{
                // The logic includes to left align the thumb scroller
                color:"#14606B",
                "& .MuiSlider-thumb":{
                    "backgroundColor": "#E0FBFF",
                    "border": "5px #14606B solid",
                    "marginLeft":sliderValueNow===0 || sliderValueNow===null?"10px":
                        sliderValueNow===100? "-8px":
                        "0px"
                },
                "& .MuiSlider-markLabel[data-index='0']":{
                    "left":"30px !important"
                },
                "& .MuiSlider-markLabel[data-index='1']":{
                    "textAlign":"center"
                },
                "& .MuiSlider-markLabel[data-index='3']":{
                    "left": "94% !important",
                    "width": "auto"
                },
                "& .MuiSlider-markLabel":{
                    [theme.breakpoints.down('sm')]: {//to avoid text overlap
                        "width": "60px !important",
                        "whiteSpace": "nowrap",
                        "overflow": "hidden",
                        "textOverflow": "ellipsis"
                    }
                },
                [theme.breakpoints.down('md')]: {
                    width: "99%",
                }
            }}
            aria-label="programming language experience"
            step={null}
            name="user_programming_experience_level"
            valueLabelDisplay="auto"
            valueLabelFormat={(value)=>{
                return PROGRAMMING_EXP_LEVELS.find(i=>i.value===value).label;
            }}
            value={values.user_programming_experience_level 
                    && values.user_programming_experience_level!=="Do not prefer"?
                PROGRAMMING_EXP_LEVELS.find(i=>i.label===values.user_programming_experience_level)?.value:
                0}
            onChange={(event,value)=>{
                setSliderValueNow(value);
                //;
                setFieldValue("user_programming_experience_level",PROGRAMMING_EXP_LEVELS.find(i=>i.value===value).label, false)
            }}
            marks={isSmallScreen ? MOBILE_PROGRAMMING_EXP_LEVELS : PROGRAMMING_EXP_LEVELS}
        />
    )
}