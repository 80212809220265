import { makeStyles } from '@mui/styles';

/**
 * @description This profile styles are used in profile page and also on the edit profile screen for the learners
 */
const useProfileCommonStyles = makeStyles((theme) => ({
    // ** Common Styles **
    container: {
        [theme.breakpoints.up("md")]: {
            width: "650px",
            margin: 'auto',
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    },
    containerGutter: {
        [theme.breakpoints.down("md")]: {
            padding: "0px 20px"
        }
    },
    alignGridCenterNoGutter: {
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column !important",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingLeft: "0px !important"
        },
    },
    userAvatarContainer: {
        marginTop: "20px",
        marginBottom: '25px',
        boxSizing: "border-box",

        padding: "26px",
        display: "flex",
        borderRadius: "10px",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            padding: "24px"
        }
    },
    profileName: {
        fontSize: "24px",
        fontWeight: 500
    },
    profilePronouns: {
        fontSize: "12px",
        fontWeight: 500,
        marginTop: "8px"
    },
    profileTitle: {
        fontSize: "14px",
        fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            marginTop: "16px"
        }
    },
    headerButton: {
        textAlign: 'center',
        boxSizing: 'border-box',


        height: '50px',
        borderRadius: '50px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '180px',
        border: `1.5px solid ${theme.colors.deepAqua}`,
        textDecoration: 'none',

        '&.email-btn': {
            marginLeft: '15px'
        },

        '& .button-text': {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '22px',
            fontFamily: 'Nunito',
        },
        '& .button-icon': {
            marginRight: '10px',
            width: '18px', height: '18px',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            '&.email-btn': {
                marginTop: '16px',
                marginLeft: 0,

            },

        },


    },
}))

export default useProfileCommonStyles