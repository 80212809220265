import { v4 as uuidv4 } from 'uuid';
import { Collapse } from '@mui/material';
import { dateTimeFormatter, getTimezoneName } from "../../../../helpers/utils";
import DoAssignmentButton from './DoAssignmentButton';
import ViewSubmissionButton from './ViewSubmissionButton';
import { ReactComponent as CheckMark } from '../../../../icons/check.svg';
import { AppDataContext } from '../../../../context';
import { useStyles } from "./styles";
import { useState, useContext } from 'react';

const timezoneString = getTimezoneName().split(/-| /)[0].toLocaleLowerCase()

const AssignmentCard = ({ assignment, colorKey, pastDue, index }) => {

    const classes = useStyles();
    const {userData} = useContext(AppDataContext)

    let dueDate = assignment.dueAt
    let overrideKeys = assignment.overrides ? Object.keys(assignment.overrides) : []
    if(assignment?.overrides && overrideKeys.length > 0) {
        if(assignment.overrides?.custom && userData?.user_lms_id && assignment.overrides.custom[userData.user_lms_id]) {
            dueDate = assignment.overrides.custom[userData.user_lms_id]
        } else if (timezoneString) {
            for(let override of overrideKeys) {
                if(override.toLowerCase().includes(timezoneString)) {
                    dueDate = assignment.overrides[override]
                    break
                }
            }
        }
    }

    const [expand, setExpand] = useState(index === 0 ? true : false)

    const formatCompletedOn = (completedOn) => {
        const date = new Date(completedOn)
        return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`
    };

    const assignmentColorKey = (assignmentLink) => {

        for(let key of colorKey) {
            if(assignmentLink.includes(key.courseId)) {
                return key.courseColorId;
            }
        }

    };
    
    const getScore = (assignment) => {
        return assignment?.score && assignment?.pointsPossible ? 
                `${assignment.score}/${assignment.pointsPossible}` : 
                assignment.completedOn !== null ? 
                    "Not yet graded" : 
                    "N/A"
    }

    return (
        <div className={classes.assignmentCard} onClick={() => setExpand(!expand)}>
            {

                expand === true && 

                <div className={classes.statusLabelGroup}>
                    <div className={`${classes.statusLabelTransition} ${pastDue === true ? classes.statusPastDue : assignment.completedOn !== null ? classes.statusCompleted : classes.statusIncomplete}`}>
                        { assignment.completedOn !== null && <CheckMark /> }
                    </div>
                    <div className={classes.statusLabel}>
                        {
                            assignment.completedOn === null && assignment.score === null && 
                            <p>To-Do</p>
                        }
                        {
                            assignment.completedOn !== null && assignment.score === null &&
                            <p>Submitted</p>
                        }
                        {
                            assignment.completedOn !== null && assignment.score !== null &&
                            <p>Graded</p>
                        }
                        {
                            pastDue === true &&
                            <p style={{color: "#C2573F"}}>Past Due | Not Submitted</p>
                        }
                    </div>
                </div>

            }
            <div  key={uuidv4()}>
                <div className={classes.assignmentStatus}>

                    {

                        expand === false && 

                        <div className={ `${classes.statusTransition} ${pastDue === true ? classes.statusPastDue : assignment.completedOn !== null ? classes.statusCompleted : classes.statusIncomplete}`}>
                            { assignment.completedOn !== null && <CheckMark /> }
                        </div>

                    }

                    <div className={`${classes.assignmentLinks} ${classes.upcomingItem}`} style={{color: assignmentColorKey(assignment.link)}}>{assignment.title}
                    </div>

                </div>
            </div>

            <Collapse in={expand} key={assignment.id} >

                <div>
                    <div className={classes.assignmentContent} onClick={() => setExpand(!expand)}>
                        <div className={classes.assignmentInfo}>
                            <p className={classes.dueDate}>Due: <span className={classes.completedOnBlue}>{dateTimeFormatter(dueDate)}</span></p>
                            <p className={`${classes.completedOn}`}>Completed:<span className={`${assignment?.completedOn ? classes.completedOnBlue : ""}`}> {assignment?.completedOn ? formatCompletedOn(assignment.completedOn) : "N/A"}</span></p>
                            <p className={classes.grade}>Points:<span className={assignment?.score || assignment.completedOn !== null ? classes.completedOnBlue : null }> {getScore(assignment)}</span></p>
                        </div>
                        <div className={classes.doAssignmentCta}>
                            {
                                
                                assignment.completedOn !== null ?

                                <ViewSubmissionButton assignment={assignment}/>
                                :
                                <DoAssignmentButton assignment={assignment}/>
                                
                            }
                        </div>
                    </div>
                </div>

            </Collapse>

        </div>
    )
};

export default AssignmentCard;