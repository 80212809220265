import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { AssociatedCoursesDataContext } from '.';
import { CohortSettingsContext } from '..';
import { getTotalNumberOfActiveCourseUsers, updateCourse } from '../../../../api';
import { AppDataContext } from '../../../../context';
import CircularLoader from '../../../../sharedComponents/CircularLoader';
import { ACPDataContext } from '../../../context';
import { boxStyle, useFormStyles } from '../../baseLayout/formStyles';
import { useStyles } from './styles';

const CourseDeletionWarning = ({
    courseInfo,
    cohortId,
    modalOpen,
    handleCloseCallback
}) => {

    const {userData}=useContext(AppDataContext);
    const {userCourses, updateCohortData, fetchACPSettingsData}=useContext(ACPDataContext);
    const {courses,setCourses}=useContext(AssociatedCoursesDataContext);
    const {setSubmissionSuccessMessage, setSubmissionSuccessfull, setSubmissionError,}=useContext(CohortSettingsContext);

    const classes = useStyles();
    const formClasses = useFormStyles();
    
    const [numOfUsers, setNumOfUsers] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [cohortInfo,setCohortInfo]=useState(null);

    useEffect(()=>{
        if(cohortId){
            let info=userCourses.find(i=>i.cohort_id===cohortId)?.cohort;
            setCohortInfo(info);
        }
    },[cohortId])

    const handleOnClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleCloseCallback();
        }
    }

    //Grabs the total number of users in the selected cohort
    useEffect(() => {
        getTotalNumberOfActiveCourseUsers({
            "lms_course_id": courseInfo.lms_course_id,
            "countusers/cohort_id": cohortId,
            "expand": "countusers"
        }).then(response => {
            // ;
            let users = response.message.courses[0]?.active_users;
            setNumOfUsers(isNaN(users) ?"Invalid (Error Occured)": users );

        }).catch(error => console.error(error));
    }, []);

    //Removes the loader once total users is fetched and set
    useEffect(() => {
        if (numOfUsers !== null) {
            setIsLoading(false);
        }
    }, [numOfUsers]);

    /** Deletes (set inactive flag) Cohort */
    const deleteCourse = async () => {
        try {
            setIsSubmitting(true);
            
            //Updates the is_active flag in db in mapping table
             await updateCourse({
                "is_active": false,
                "cohort_id":cohortId,
                "updated_by":userData.username
            }, courseInfo.lms_course_id);

         
            
            //update the courses and also the cached data 
            let d=[...courses];
            let index=d.findIndex(i=>i.lms_course_id===courseInfo.lms_course_id);

            d.splice(index,1);

            //get the mapping id to update that in cache
            let mappings=await fetchACPSettingsData("cohortcourses");

            let mappingId=mappings.find(i=>i.cohort_id===cohortId && i.course_id===courseInfo.lms_course_id)

            //updating Cache at ACPDataStore level
            updateCohortData({
                key:"cohortcourses",
                itemId:mappingId.id,
                cohortId:cohortId,
                dataset:{
                    "is_active": false
                }
            });

            setCourses(d);

            //removes loader on button
            setIsSubmitting(false);

            //set the snackbars
            setSubmissionSuccessMessage("Course has been removed from cohort successfully");
            setSubmissionSuccessfull(true);


        } catch (error) {
            console.error(error);
            setSubmissionError(true);
        }
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleOnClose}
        >
            <Box sx={boxStyle}>
                <div className={formClasses.formHeaderContainer}>
                    <Typography id="cohort-deletion-warning-header-line1" variant="h6" component="h2">
                        <b className={classes.textRust}>Warning!</b>
                    </Typography>
                    <div>
                        <IconButton onClick={() => { handleOnClose() }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                {
                    isLoading ?
                        <CircularLoader /> :
                        <>
                            <Typography id="cohort-deletion-warning-header-line2" variant="h6" component="h2">
                                <p className={classes.textRust}>
                                    You are about to revoke MyKenzie access to entire course linked to a cohort
                                </p>
                            </Typography>
                            <div className={formClasses.formControlSection}>
                                Are you sure you want to remove <b className={classes.textRust}>{courseInfo?.coursename}</b>, linked to cohort <b className={classes.textRust}>{cohortInfo?.cohort_name}</b>, affecting <b className={classes.textRust}>{numOfUsers}</b> users
                            </div>

                            <div className={formClasses.formFooter}>
                                <div>
                                    <Button
                                        onClick={() => { handleOnClose() }}
                                        variant="text"
                                        id="delete-cohort-cancel-button"
                                        className={formClasses.textButton}>
                                        Cancel
                                    </Button>
                                </div>
                                <div className={formClasses.removeButton} >
                                    <Button
                                        disabled={isSubmitting}
                                        onClick={() => { deleteCourse() }}
                                        id="delete-cohort-button"
                                        data-testid="delete-cohort-button"
                                    >
                                        {
                                            isSubmitting ?
                                                <CircularProgress color="inherit" size={18} /> :
                                                <span>Remove</span>
                                        }
                                    </Button>
                                </div>

                            </div>
                        </>
                }
            </Box>

        </Modal>

    )

}

export default CourseDeletionWarning;