import React, { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useStyles } from './styles';

import CircularLoader from '../../../sharedComponents/CircularLoader';

import getResponseAction from '../../../helpers/getAction';
import { convertUTCDateTimeToUsersTimeZone, getUserDisplayName } from '../../../helpers/utils';
import { ACPSupportRequestsContext } from '../../context';

function createMarkup(markup) {
    markup=markup.replace(/\\r/g,'</br>');//replacing linebreaks with <br.> for backward compatibility
    return {__html: markup};
}

export default function EachRequestHistory({
    request,
    refreshHistory,
    setRefreshHistory
}){

    const classes=useStyles();

    const [isLoading,setIsLoading]=useState(true);
    const [requestHistory,setRequestHistory]=useState(null);

    const {fetchSupportRequestResponses}=useContext(ACPSupportRequestsContext);

    useEffect(()=>{
        //Refresh History state variable is set at Modal level
        //the history must be refreshed when new action is performed
        //the ActionForm updates the action and sets the cached data and refresh history state variable set to true fetches the data again from cached data
        if(refreshHistory){
            fetchSupportRequestResponses(request.id).then(response=>{
                setRequestHistory(response);
                setRefreshHistory(false);//resets refresh history
            }).catch(err=>console.error(err));
        }
    },[refreshHistory]);

    useEffect(()=>{
        if(requestHistory){
            setIsLoading(false);
        }
    },[requestHistory]);

    return(
        <div className={`text-normal ${classes.greyColor}`}>
            {
                isLoading?
                <CircularLoader />:
                requestHistory?
                requestHistory.map(item=>{
                    return <div key={item.id} className={classes.eachRequestHistoryInnerContainer}>
                        <div className={`historyHeader lineHt-md ${classes.greyColor}`}>
                            <div>{getUserDisplayName(item?.staff_email_mk_user)} {item?.staff_email_mk_user?.last_name}</div>
                            <div className={classes.addMdMarginLeft}>{getResponseAction(item.action_id)}</div>
                            <div className={`italics ${classes.addMdMarginLeft}`}>{convertUTCDateTimeToUsersTimeZone(item.created_date).timeInZone.format(`MMM DD [|] hh:mma`)} {convertUTCDateTimeToUsersTimeZone(item.created_date).timeZoneAbbr}</div>
                        </div>
                        <div className='ql-snow'>
                            <div className='ql-editor' style={{padding:"8px 0px"}}>
                                <div className={classes.blackColor} dangerouslySetInnerHTML={createMarkup(item.response)}></div>
                            </div>
                        </div>
                    </div>
                }):
                null
            }
            
        </div>
    )
}