export default function StyledTextInput({ style, inputProps, hasError }) {
  const styles = {
    input: {
      borderRadius: "5px",
      outline: "none",
      border: hasError ? "2px solid #C2573F" : "1px solid #989898",
      padding: "5px",
      width: "90%"
    }
  };

  return <input style={{ ...styles.input, ...style }} {...inputProps} />;
}
