import React from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import useStyles from './skeletonStyles';

const ProfilePgSkeleton=()=>{
    const classes = useStyles()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <div className={classes.profileWrapper}>
            <div className={classes.profileContainer}>
                <div className={classes.profileImage}>
                    <Skeleton animation="wave" variant="circular" height={204} width={204} display="flex" justify-content="center"/>
                </div>
                <section className={classes.profileHeader}>
                    <div className={classes.demographics}>
                        <Skeleton animation="wave" variant="text" height={isSmallScreen ? 50 : "40px"} width="30%"/>
                        {
                            isSmallScreen && <Skeleton animation="wave" variant='text' height={30} width="10%" sx={{marginTop: "-5px", marginBottom: "5px"}}/>
                        }
                        <Skeleton animation="wave" variant="text" height="30px" width="30%"/>
                    </div>
                    <div className={classes.editProfilePill}>
                        <Skeleton animation="wave" variant="rounded" height={50} width={isSmallScreen ? 319: 180} sx={{borderRadius: "50px"}}/>
                    </div>
                </section>
                <section className={classes.profileGrid}>
                    <div className={classes.profileGridLeft}>
                        <Skeleton animation="wave" variant="rectangular" height={isSmallScreen ? 303 : 497} width={isSmallScreen ? "100vw" : 567} sx={{borderRadius: "5px"}}/>
                    </div>
                    <div className={classes.profileGridRight}>
                        {
                            Array.from(new Array(3)).map(() => (
                                <div className={classes.gridBoxRight}>
                                    <Skeleton animation="wave" variant="rectangular" width={isSmallScreen ? "100vw" : 567} height={isSmallScreen ? 155 : 152.3} sx={{borderRadius: "5px"}}/>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <section className={classes.focusTime}>
                    <div className={classes.focusTimeSkeleton}>
                        <Skeleton animation="wave" variant="rectangular" height={669} width="100%" sx={{borderRadius: "5px"}}/>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ProfilePgSkeleton;