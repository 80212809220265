import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    searchFormContainer: {
        display: "flex",
        flexDirection: "column"
    },
    searchField: {
        backgroundColor: theme.colors.white,
        borderRadius: "10px",
        minHeight: "48.25px",
        "&.MuiFormControl-root": {
            marginBottom: "12px",
        },
    },
    searchPanel: {
        width: "196px",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    historyMenu: {
        display: "flex",
        flexDirection: "column",
        //arbitrary maxHeight value selected to limit trouble viewing autocomplete menu
        maxHeight: "186.5px",
        overflowY: "scroll"
    }

}))