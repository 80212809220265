import useStyles from "./styles";

export default function TopicPillsSection({ video, isCalendarPanel = false }) {
    const styles = useStyles()

    return (
        <div className={`${styles.topicContainer} ${isCalendarPanel ? 'panel' : ''}`} >
            {video?.tags?.slice(0, 3).sort(sortFn).map(topic => (
                <span key={topic} className={`${styles.topicPills} ${isCalendarPanel ? 'panel' : ''}`} >{topic}</span>
            ))


            }

        </div>
    )
}

const sortFn = (a,b) =>{
    if (a.length < b.length) return -1
    if (a.length > b.length) return 1
    return 0
}