import React from 'react';
import { v4 as uuid4 } from 'uuid';
import { useStyles } from './styles';

import HtmlParser from '../../../sharedComponents/announcement/HtmlParser';
import { getRequestTypeMapping, parseRequestBody } from './helpers';
const getQnA=require('./requestQuestionsPerType.json');

export default function EachRequestQuestionAnswers({
    request
}){

    const classes=useStyles();

    const requestType=getRequestTypeMapping(request);

    const body=parseRequestBody(request);

    const allQuestions=getQnA.find(i=>i.type===requestType).allQuestions;

    //;

    return(
        <div className={`fwt-500 text-normal ${classes.questionContainer}`}>
            {
                allQuestions.map(item=>{
                    return <div key={uuid4()} className={classes.questionContainer}>
                         <div className={`${classes[requestType+"Color"]}  lineHt-md request-question`}>
                            <div>{item.question}</div>
                        </div>
                        <div className='request-answer  lineHt-sm'>
                            <HtmlParser>{body[item.answerKey]}</HtmlParser>
                        </div>
                    </div>
                })
            }

            {
                request.upload_image_url ?
                    <div className='request-uploaded-image-container'>
                        <div className={`${classes[requestType + "Color"]}  lineHt-md request-question`}>
                            Images
                        </div>
                        <div className='lineHt-md request-uploaded-image-url'>
                            <a href={request.upload_image_url} target="_blank" rel="noreferrer">{request.upload_image_url}</a>
                        </div>
                    </div> :
                    null
            }

        </div>
    )
}