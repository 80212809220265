import React from "react"
import { useFormikContext } from 'formik';
import {v4 as uuid4} from 'uuid';
import { makeStyles } from "@mui/styles";
const WORK_SCHEDULES = require("./workSchedules.json")

const useStyles = makeStyles((theme) => ({
    workScheduleContainer:{
        marginTop: "10px",
        width: "60vw",
        [theme.breakpoints.down("540")]: {
            width: "100%"
        },
        '& div': {
            display: "flex",
            justifyContent: "center",
            alignContent:"center",
            alignItems: "center",
            flexDirection:"row",
            flexWrap:"wrap",
            boxSizing: "border-box",
        },
        '& .each-work-schedule-button': {
            cursor:"pointer",
            margin: "0px 12px 15px 0px",
            height: "43px",
            width: "149px",
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: 400,
            border: "1px solid #000000",
            textAlign:"center",
            lineHeight:"35px",
            borderRadius: "100px",
            backgroundColor: "white",
        },
        '& .not-working-button': {
            [theme.breakpoints.down("540")]: {
                width: "100%",
                margin: "0px 0px 15px 0px"
            }
        },
        '& .each-work-schedule-button-selected':{
            backgroundColor:theme.colors.lilac,
        }
    },
    instructions: {
        fontFamily: "Nunito",
        fontWight: 500,
        fontSize: "12px"
    }
}))

export const WorkScheduler = () => {
    const workLifeStyles = useStyles()

    const {
        values,
        setFieldValue
    } = useFormikContext()

    const handleOnClick = (schedule) => {

        let d = values.user_work_schedule ? values.user_work_schedule : []

        if (d.includes(schedule.id)) {
            d.splice(d.indexOf(schedule.id), 1)

        } else {
            d.push(schedule.id);
        }

        setFieldValue('user_work_schedule', d);
    }

    const checkIfExists=(schedule)=>{
        return values.user_work_schedule && values.user_work_schedule.includes(schedule.id)
    }

    return (
        <>
            <div className={workLifeStyles.workScheduleContainer}>
                <div>
                    {
                        WORK_SCHEDULES.map((schedule, index) => {
                            return <div
                                key={uuid4()}
                                className={`each-work-schedule-button ${index === 0 ? 'not-working-button' : ''} open-sans ${checkIfExists(schedule) ? 'each-work-schedule-button-selected' : ''}`}
                                onClick={() => {
                                    handleOnClick(schedule);
                                }}> {schedule.label} </div>
                        })
                    }
                </div>
                </div>
                <span className={workLifeStyles.instructions}>*Select all that apply</span>
        </>
    )
}