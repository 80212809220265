import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    eachCourseContainer:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"15px",
        '& .course-name':{
            fontWeight:400,
            fontSize:"14px"
        }
    },
     /**
     * Search Box
     */
      searchInputBlock: {
        margin: "10px 0",
        width: "350px",
        position:"relative"
    },
    searchInput: {
        fontSize: "14px",
        padding: "10px 10px 10px 30px",
        border: '1px solid #c4c4c4',
        borderRadius: "6px",
        height: "1.4375em",
        display: "block",
        width: "100%",
        outline: "#BFE0FF",
        backgroundPosition: "10px 13px"
    },
    searchedItem: {
        display:"block !important",
        width: "100%",
        padding: "10px",
        borderRadius: "4px",
        fontFamily:"open sans !important",
    },
    searchedItems: {
        position:"absolute",
        border: '1px solid #c4c4c4',
        width: "100%",
        left:"0px",
        right:"0px",
        top:"40px",
        borderRadius: "6px",
        padding: "8px",
        background: "#ffffff",
        marginTop: "5px",
        fontSize: "14px",
        maxHeight: "185px",
        overflow: "auto",
        '& .search-item-container':{
            display:"flex",
            justifyContent:"flex-start",
            alignItems:"center",
            '& .search-result-text':{
                width:"85%",
                overflow:"hidden",
                textOverflow:"ellipsis"
            }
        },
    },
    searchedItem_noresult: {
        width: "auto",
        padding: "0",
        fontSize: "13px"
    },
    textRust:{
        color:`${theme.colors.rust} !important`,
    }

}))