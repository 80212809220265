import React from 'react';
import { ReactComponent as MyKenzieLogo } from '../images/my-kenzie-logo-2.svg';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles(theme => ({
    errorMessageContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
    },
    mkLogo: {
        width: "429px",
        height: "149px",
        [theme.breakpoints.down('sm')]: {
            width: "251px"
        },
    },
    logoutBtn: {
        marginTop: '42px',

        color: '#14606B',
        border: '2px solid #14606B',
        borderRadius: '100px',
        boxSizing: 'border-box',
        padding: '8px 26px',
        fontFamily: 'Raleway',

        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '150%',
        cursor: 'pointer',
        background: '#FFF'
    },
    errorGroup: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(400)]: {
            transform: "scale(.85)"
        },
        [theme.breakpoints.down(330)]: {
            transform: "scale(.75)"
        },
        [theme.breakpoints.down(320)]: {
            transform: "scale(.7)"
        },
        [theme.breakpoints.down(300)]: {
            transform: "scale(.6)"
        },
    },
    errorMessageGroup: {
        marginTop: "35px",
        fontFamily: 'Raleway, arial, sans-serif',
        textAlign: "center",
        fontWeight: 450,
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px",
        },
    },
    errorMessage: {
        width: "481px",
        textAlign: "center",
        fontSize: "20px",
        fontFamily: 'Raleway, arial, sans-serif',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            width: "311px"
        }
    },
    errorSubtextGroup: {
        width: "481px",
        marginTop: "38px",
        [theme.breakpoints.down('sm')]: {
            width: "311px",
            fontSize: "14px",
            marginTop: "38px"
        },
        [theme.breakpoints.down(400)]: {
            transform: "scale(1.1)"
        },
        [theme.breakpoints.down(330)]: {
            transform: "scale(1.2)"
        },
        [theme.breakpoints.down(300)]: {
            transform: "scale(1.2)"
        },
    },
    errorSubtext1: {
        fontSize: "12px",
        fontWeight: 700,
        marginBottom: "-10px"
    },
    errorSubtext2: {
        fontSize: "12px",
        fontWeight: 500,
        '& p': {
            marginBottom: "-10px"
        }
    },
    logoAnimation: {
        animationName: "$mkLogoFade",
        animationDuration: "1s",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    "@keyframes mkLogoFade": {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
    },
}))


const AccessDeniedAfterAuth = ({user}) => {
    const classes = useStyles()
    const { logout } = useAuth0()
    return (
        <div className={classes.errorMessageContainer}>
            <div className={classes.errorGroup}>
                <div className={`${classes.mkLogo} ${classes.logoAnimation}`} >
                    <MyKenzieLogo />
                </div>
                <div className={classes.errorMessageGroup}>
                    <div className={classes.errorMessage}>
                        <p>Hmm, {user.email} does not have access to this page.</p>
                    </div>
                    <div className={classes.errorSubtextGroup}>
                        <div className={classes.errorSubtext1}>
                            <p>Are you newly enrolled with Kenzie Academy?</p>
                        </div>
                        <div className={classes.errorSubtext2}>
                            <p>You will get access to MyKenzie very soon!</p>
                            <p>Please reach out to your Learner Success Advisor if you have any questions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <button className={classes.logoutBtn} onClick={() => { logout({ returnTo: window.location.origin }) }}>Sign Out</button>
        </div>
    )
}

export default AccessDeniedAfterAuth;