import * as React from 'react';
import { Link } from "react-router-dom";
import { v4 as uuid4 } from 'uuid';

import { getUserDisplayName } from "../../../../helpers/utils";

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal } from "@mui/material";
import modalGraphic from "../../../../images/participant_modal.svg";

import { MediaContext } from '..';
import useModalStyles from "../../dashboard/connectPanel/modalStyles";
import ParticipantAvatar from './ParticipantAvatar';

const ParticipantsModal = ({
    onCloseCallback
}) => {

    const classes = useModalStyles();
    const profileURL = "/profile/";

    const { videoInfo } = React.useContext(MediaContext);

    return (
        <Modal
            className="open-sans"
            open={true}
            onClose={() => { onCloseCallback(false) }}
            classes={{ root: classes.centerModal }}
        >
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <img src={modalGraphic} className={classes.image} alt="Participant Graphic" />
                    <IconButton onClick={() => { onCloseCallback(false) }}>
                        <CloseIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                </div>
                {
                    videoInfo.meeting_participants.length > 0 &&
                    <>
                        <p>Participants: <strong>{videoInfo.meeting_participants.length}</strong></p>
                        <div style={{ marginTop: "20px" }}>
                            {
                                videoInfo.meeting_participants.map(participant => {

                                    return <div key={uuid4()}>
                                        {
                                            participant?.mykenzie_user_info ?
                                                <Link style={{ textDecoration: "none" }}
                                                    key={uuid4()}
                                                    to={`${profileURL}${participant.mykenzie_user_info.email}`}>

                                                    <div className={`${classes.nameCard} ${classes.profileNameCard}`}>
                                                        <ParticipantAvatar participant={participant} inModalView={true} />
                                                        {
                                                            (getUserDisplayName(participant?.mykenzie_user_info) && participant?.mykenzie_user_info.last_name) ?

                                                                <span>{`${getUserDisplayName(participant?.mykenzie_user_info)}  ${participant?.mykenzie_user_info.last_name}`}</span>
                                                                :
                                                                <span>{participant?.mykenzie_user_info.email || ''}</span>
                                                        }
                                                    </div>

                                                </Link> :
                                                <div className={`${classes.nameCard}`}>
                                                    <ParticipantAvatar participant={participant} inModalView={true} />
                                                    <span>{participant.name}</span>
                                                </div>
                                        }

                                    </div>
                                })}
                        </div>

                    </>
                }
            </div>
        </Modal>
    )
}

export default ParticipantsModal;