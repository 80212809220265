import React from 'react';
import { TextField as TextRaw, Autocomplete, styled, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import languages from '../../../../sharedComponents/editProfileFields/lists/languages.json';
import programminglanguages from "../../../../sharedComponents/editProfileFields/lists/programmingLanguages.json"

const TextField = styled(TextRaw)((props) => ({
    width: "100%",
    border: "1.5px solid #14606B",
    borderRadius: "5px",
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingTop: "3px",
    paddingBottom: "3px",
    background: "white",
    '& .MuiInputBase-root': {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '27px',
        color: '#14606B',
        [props.theme.breakpoints.down("800")]: {
            fontSize: "18px",
            lineHeight: "23px"
        }
    }
}))


export const PrimarySpokenLanguagesField = ({
    controlName,
    controlId,
    isEditProfilePage
}) => {
    const theme = useTheme()

    let {
        values,
        setFieldValue,
        setFieldTouched
    } = useFormikContext();

    return (<Autocomplete
        id={controlId}
        name={controlName}
        options={languages.map(v => v.title)}
        value={values[controlId]}
        onChange={(event, value) => {
            setFieldValue(controlId, value);
            setFieldTouched(controlId, true)
        }}
        freeSolo
        ChipProps={{
            sx: isEditProfilePage && {
                backgroundColor: 'white',
                color: theme.colors.deepAqua,
                border: '1.5px solid #6B6B6B'
            }
        }}
        renderInput={(params) =>
            <TextField
                placeholder="Start typing to add languages..."
                variant="standard"
                {...{
                    ...params,
                    InputProps: {
                        ...params.InputProps,
                        disableUnderline: true,

                    }
                }}
            />}
    />)
}

export const AdditionalSpokenLanguages = ({
    controlId,
    controlName,
    isEditProfilePage
}) => {
    const theme = useTheme()

    let {
        values,
        setFieldValue,
        setFieldTouched
    } = useFormikContext();

    return (<Autocomplete
        multiple
        id={controlId}
        name={controlName}
        options={languages}
        clearIcon={null}
        filterSelectedOptions
        getOptionLabel={(option) => option.title}
        value={values[controlId] ? values[controlId].reduce((acc, ci) => {
            let language = languages.find(i => i.title === ci);
            if (language) {
                acc.push(language);
            }
            return acc;
        }, []) : []}
        onChange={(event, newValue) => {
            setFieldValue(controlId, newValue.map(v => v.title));
            setFieldTouched(controlId, true);
        }}
        placeholder="Additional Spoken Languages"
        freeSolo
        ChipProps={{
            sx: isEditProfilePage && {
                backgroundColor: 'white',
                color: theme.colors.deepAqua,
                border: '1.5px solid #6B6B6B'
            }
        }}
        renderInput={(params) => (
            <TextField
                placeholder={(!values[controlId] || values[controlId].length === 0) ? "Start typing to add languages..." : ""}
                variant="standard"
                {...{
                    ...params,
                    InputProps: {
                        ...params.InputProps,
                        disableUnderline: true
                    }
                }}
            />
        )}
    />)
}

export const ProgrammingLanguagesField = ({
    controlId,
    controlName,
    isEditProfilePage
}) => {
    const theme = useTheme()

    let {
        values,
        setFieldValue,
        setFieldTouched
    } = useFormikContext();

    return (<Autocomplete
        multiple
        id={controlId}
        name={controlName}
        clearIcon={null}
        value={values[controlId] ? values[controlId].reduce((acc, ci) => {
            let language = programminglanguages.find(i => i.title === ci)
            if (language) {
                acc.push(language)
            }
            return acc;
        }, []) : []}
        options={programminglanguages}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => {
            setFieldValue(controlId, newValue.map(v => v.title));
            setFieldTouched(controlId, true);
        }}
        filterSelectedOptions
        freeSolo
        sx={
            {
                '& .MuiFormControl-root': {
                    boxSizing: 'border-box'
                }
            }
        }
        ChipProps={{
            sx: isEditProfilePage && {
                backgroundColor: 'white',
                color: theme.colors.deepAqua,
                border: '1.5px solid #6B6B6B'
            }
        }
        }
        renderInput={(params) => (
            <TextField
                sx={{
                    backgroundColor: isEditProfilePage ? '#F2F2F2' : 'transparent',



                }}
                variant="standard"
                placeholder={(!values[controlId] || values[controlId].length === 0) ? "Start typing to add programming languages..." : ""}
                {...{
                    ...params,
                    InputProps: {
                        ...params.InputProps,
                        disableUnderline: true,

                    }
                }}
            />
        )}
    />)
}