import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Image from '../../images/kenzieAvatar.png';

import { UserProfileContext } from './UserProfileContext';

const UserImage = () => {
    const theme = useTheme()

    let { user } = useAuth0();
    let { profileData } = useContext(UserProfileContext);

    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        //Setting profile image
        if (profileData && profileData.user_image_url) {
            setProfileImage(profileData.user_image_url);

        } else if (user && user.profile) {
            setProfileImage(user.profile);

        } else {
            setProfileImage(Image);
        }
    }, [profileData]);

    return (

        <img
            alt="profile"
            src={profileImage}
            style={{
                display: 'block',
                width: 164,
                height: 164,
                borderRadius: '100vh',
                padding: '15px',

                border: `5px solid ${theme.colors.learnerGreen}`
            }}
            onError={(e) => e.target.src = Image}
        />

    )
}

export default UserImage;

