import Autocomplete from '@mui/material/Autocomplete';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import useLoadGoogleAPI from '../../../../sharedComponents/editProfileFields/location/useLoadGoogleAPI';
import { LocationTextField } from './LocationTextField';

export function PlacesSearchField({
	controlId,
	controlName,
	textClassName
}) {

	let {
		values,
		setFieldValue,
		setFieldTouched
	} = useFormikContext();
	const [value, setValue] = React.useState((values[controlId] && JSON.stringify(values[controlId]) !== "{}") ? values[controlId] : "");
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [isLoading, setIsLoading] = useState(false);

	let autoCompleteService = useLoadGoogleAPI(controlId);

	useEffect(() => {

		let active = true;
		setIsLoading(true);

		if (inputValue === '' || !window.google || !autoCompleteService) {
			setOptions(value ? [value] : []);
			return "";
		}

		autoCompleteService.getPlacePredictions({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}

			setIsLoading(false);

		});

		return () => {
			active = false;
		};
	}, [value, inputValue]);

	return (
		<Autocomplete
			id={controlId}
			name={controlName}
			sx={{ fontSize: '14px' }}
			getOptionLabel={(option) =>
				typeof option === 'string' ? option : option.description
			}
			loading={isLoading}
			loadingText="Search for places"
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			popupIcon={null}
			value={value}
			onChange={(event, newValue) => {
				setFieldValue(controlId, newValue?.description ? {
					"description": newValue.description,
					"place_id": newValue.place_id,
					"types": newValue.types
				} : null);
				setFieldTouched(controlId, true);
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<LocationTextField {...params} className={textClassName} value={inputValue} placeholder="Select a location" />
			)}
		/>
	);
}