import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { Box, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ErrorSnackBar, SuccessSnackBar } from '../../../sharedComponents/snackBars';

import EachRequestActionForm from './EachRequestActionForm';
import EachRequestAuthorAndSrc from './EachRequestAuthorAndSrc';
import EachRequestDates from './EachRequestDates';
import EachRequestHeaderCourseTags from './EachRequestHeaderCourseTags';
import EachRequestHeaderTag from './EachRequestHeaderRequestType';
import EachRequestHistory from './EachRequestHistory';
import EachRequestQuestionAnswers from './EachRequestQuestionAnswers';
import EachRequestStatus from './EachRequestStatus';
import { checkIfSupportIdExistsInUrl, getRequestTypeMapping, getSupportIdFromUrl } from './helpers';
import { requestTypeColorMapping, useStyles } from './styles';

const boxStyle = {
    position: 'absolute',
    minHeight: "30vh",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24
}

export default function EachRequestExpansionModal({
    request,
    open,
    handleOnClose
}) {

    const classes=useStyles();

    //SnackBars state variables
    let [submissionSuccessfull, setSubmissionSuccessfull] = useState(false);
    let [submissionMessage, setSubmissionMessage] = useState("Your changes were saved successfully");
    let [submissionError, setSubmissionError] = useState(false); 
    let [submissionErrorMessage, setSubmissionErrorMessage] = useState("There was an error in saving announcement"); 
    
    const requestType = getRequestTypeMapping(request);


    const [refreshHistory,setRefreshHistory]=useState(true);

    useEffect(()=>{

        let url = new URL(window.location);

        //Check if id already exists in url
        //dont add the id in url, otherwise add 
        if(!checkIfSupportIdExistsInUrl(url.pathname)){
            //Pushing the request id in url when user opens the popup
            let constructUrl=url.href;
            if(!(/\/$/).test(constructUrl)) constructUrl+='/';
            constructUrl+=request.id;

            window.history.pushState({"id":request.id},"",constructUrl);
        }

        return(()=>{
            handleOnClose();

            if(checkIfSupportIdExistsInUrl(url.pathname)){                //Remove the id from url 
                let supportId=getSupportIdFromUrl(url.pathname);
                let regX=new RegExp(supportId+".*",'g');
                url=url.href.replace(regX,"");
            }

            //remove the request id from url when user closes the popup
            //May be we should also remove the state from history instead pushing new state, but keeping it as is for now
            window.history.pushState({},"",`${url}`);

        })
    },[])

    return (
        <Modal
            sx={{
                borderColor: requestTypeColorMapping[requestType]
            }}
            open={open}
            onClose={handleOnClose}
        >
            <Box sx={boxStyle}>

                <div className='open-sans each-request-expansion-parent-container'
                    data-testid={`each-request-expansion-parent-container-${request.id}`}
                    data-requestid={request.id}
                    id={`each-request-expansion-parent-container-${request.id}`}>
                    
                    <div className={`each-request-expansion-scrollable-content ${classes.requestExpandedModalScrollable}`}>

                        <div className={`each-request-expansion-top-container ${classes[requestType + "BgColor"]}`} >
                            <div className={classes.eachRequestTopInnerContainer}>
                                
                                <div className={`${classes.eachRequestTopHeader} ${classes.addSmMarginBottom}`}>
                                    <EachRequestHeaderTag request={request} /> 
                                    <CloseFullscreenIcon  className='pointer' fontSize='18'
                                        onClick={handleOnClose}
                                    />
                                </div>

                                <div className='lineHt-md'>
                                    <EachRequestHeaderCourseTags request={request} />
                                </div>

                                <div className='lineHt-md'>
                                    <EachRequestStatus request={request} />
                                </div>

                                <div>
                                    <EachRequestQuestionAnswers request={request} />
                                </div>

                                <div className={classes.addMdMarginTop}>
                                    <EachRequestActionForm
                                        setSubmissionSuccessfull={setSubmissionSuccessfull}
                                        setSubmissionMessage={setSubmissionMessage}
                                        setSubmissionError={setSubmissionError}
                                        setSubmissionErrorMessage={setSubmissionErrorMessage}
                                        setRefreshHistory={setRefreshHistory}
                                        request={request}

                                    />
                                </div> 

                            </div>
                        </div>

                        <div className={`each-request-history-container ${classes.eachRequestHistoryContainer} ${classes.addLgMarginBottom}`}>
                            <div className={classes[requestType+"Color"]}> History </div>
                            <EachRequestHistory 
                                refreshHistory={refreshHistory}
                                setRefreshHistory={setRefreshHistory}
                                request={request}/>
                        </div>

                    </div>

                    <div className='border-top each-request-expansion-bottom-container'>

                        <div className={classes.eachRequestLowerBottomContainer}>                            

                            <div className={`each-request-expansion-bottom-left-container ${classes.eachRequestBottomContainer}`}>
                                <div className='each-request-author-src'>
                                    <EachRequestAuthorAndSrc request={request} />
                                </div>
                            </div>
                           
                            <div className={classes.addMdMarginLeft}>
                                <EachRequestDates request={request} />
                            </div>

                        </div>
                    </div>

                </div>

                {/* Snackbars for the forms */}
                {
                    submissionSuccessfull ?
                        <SuccessSnackBar
                            message={submissionMessage}
                            handleOnClose={() => { setSubmissionSuccessfull(false) }}
                        /> :
                        null
                }
                {
                    submissionError ?
                        <ErrorSnackBar
                            message={submissionErrorMessage}
                            handleOnClose={() => { setSubmissionError(false) }}
                        /> :
                        null
                }

            </Box>
        </Modal>
    )
}