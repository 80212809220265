export const theme = {
  fontFamily: 'Raleway, arial, sans-serif !important',
  typography: {
    fontFamily: "Open Sans,Raleway",
    body1: {
      fontSize: 12
    }
  },
  palette: {
    turquoise: {
      main: "#ACFFE1",
      contrastText: "#1D3969"
    }
  },
  colors: {
    peach: "#FFE8E2",
    gray: "#545454",
    slateGrey: "#404040",
    rust: "#C2573F",
    deepBlue: "#1D3969",
    lighterDeepBlue: "#445879",
    deepAqua: "#14606B",
    white: "#FFFFFF",
    onyx: "#414042",
    stone: "#E2DDDB",
    lilac: "#E4EEFF",
    greenCyan: "#C3FFE8",
    turquoise: "#ACFFE1",
    learnerGreen: "#63C29D",
    profileLightBlue: '#E0FBFF'
  },
  elements: {
    title: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '23px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    subtitle: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0em',
      textAlign: 'left'
    },
    bodyText: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    darkBlueButton: {
      height: '35px',
      minWidth: "125px !important",
      borderRadius: '50px !important',
      fontFamily: 'Nunito !important',
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: "700 !important",
      lineHeight: '14px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: "#FFFFFF !important",
      backgroundColor: "#1D3969 !important",
      textTransform: "none !important",
      textDecoration: "none !important",
      borderBottom: "none",
      border: "0px",
      '&:hover': {
        transform: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        color: "white !important",
        fontWeight: 700,
        backgroundColor: "#1D3969",
      },
      '&:disabled': {
        opacity: 0.7
      }
    },
    tealButton: {
      height: '35px',
      minWidth: "125px !important",
      borderRadius: '50px !important',
      fontFamily: 'Raleway !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: "700 !important",
      lineHeight: '14px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: "#FFFFFF !important",
      backgroundColor: "#14606B !important",
      textTransform: "none !important",
      textDecoration: "none !important",
      borderBottom: "none",
      border: "0px",
      '&:hover': {
        transform: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        color: "white !important",
        fontWeight: 700,
        backgroundColor: "#1D3969",
      },
      '&:disabled': {
        opacity: 0.7
      }
    }
  },
  MyKenzie: {
    cardColors: {
      primary: "#14606B",
      primaryHighlight: "#E2F0EE",
      secondary: "#1D3969",
      secondaryHighlight: "#E4EEFF",
      tertiary: "#C2573F"
    },
    actionColors: {
      lightButton: "#FFFFFF",
      darkButton: "#05529A",
      joinButton: "#C2573F",
      blueButton: "#1D3969"
    },
    error: {
      // main: "#C84114"
      main: "#ff0033"
    },
    textColors: {
      cardTitle: "#FFFFFF",
      darkButton: "#FFFFFF",
      lightButton: "#1D3969"
    },
    text: {
      contentTitle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        color: "#545454"
      },
      contentSubtitle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 300,
        color: "#818181"
      },
      contentSubtitlePop: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: "#05529A"
      }
    }
  },
  ACP: {
    fontFamily: "open sans !important",
    announcementBorder: "#C2573F",
    activeColor: "green",
    text: {
      contentTitle: {
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 600,
        color: "#545454"
      },
      contentSubtitle: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: "#818181"
      },
      contentSubtitlePop: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: "#05529A"
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}