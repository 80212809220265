import React, { useContext, useEffect, useState } from 'react';
import { CohortSettingsContext } from '..';

import { AppDataContext } from '../../../../context';
import { ACPDataContext } from '../../../context';
import { useCohortSettingStyles } from '../styles';
import EachCohortWebtools from './EachCohortWebtools';

export const WebtoolsDataContext = React.createContext();

const WebtoolsSettingsPage = () => {

    const cohortSettingClasses=useCohortSettingStyles();

    const { selectedACPSettingsCohortId, fetchACPSettingsData, fetchAllWebtools, updateCohortData } = useContext(ACPDataContext);
    const { setComponentLoaded } = useContext(CohortSettingsContext);
    const { setIsAllComponentsLoaded } = useContext(AppDataContext);

    let [allWebtools, setAllWebtools] = useState([]);
    let [webtools, setWebtools] = useState(null);

    useEffect(() => {
        try {
            if (selectedACPSettingsCohortId) {

                Promise.all([
                    fetchAllWebtools(),
                    fetchACPSettingsData('webtools')

                ]).then(responses => {

                    setAllWebtools(responses[0]);
                    setWebtools(responses[1]);

                    setIsAllComponentsLoaded(true); //removes kenzie loader 

                }).catch(err => console.error(err));
            }
        } catch (error) {
            console.error(error);
        }
    }, [selectedACPSettingsCohortId]);

    useEffect(()=>{
        if(webtools){
            setComponentLoaded("webtools");
        }
    },[webtools]);

    //** Updates the webtools members for entire cohort */
    //** Updates the state variable and also cache information in ACP Datastore */
    const updateCohortWebtoolsState = (data) => {
        try {
            let d = [...webtools];

            
            for (let i = 0; i < data.length; i++) {

                let indx = d.findIndex(j => j.id === data[i].id && j.cohort_id === data[i].cohort_id);

                if (indx > -1) {
                    d[indx] = data[i];

                } else {
                    d.push(data[i]);
                }

                updateCohortData({
                    "dataset": data[i],
                    "cohortId": data[i].cohort_id,
                    "itemId": data[i].id,
                    "remove": false,
                    "key": "webtools",
                    "assumeNew": true
                });
            }

            setWebtools(d);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <WebtoolsDataContext.Provider value={{
            "webtools": webtools,
            "allWebtools": allWebtools,
            "updateCohortWebtoolsState": updateCohortWebtoolsState
        }}>
            {
                selectedACPSettingsCohortId && webtools ?
                    <>
                        {
                            selectedACPSettingsCohortId.map(cohortId => {
                                return <div id="webtools-settings"
                                    className={`${cohortSettingClasses.eachCohortSection}`}
                                    key={`cohort-webtools-${cohortId}`}>
                                    <EachCohortWebtools
                                        webtools={webtools.filter(i => i.cohort_id === cohortId)}
                                        cohortId={cohortId} />
                                </div>
                            })
                        }
                    </> :
                    null
            }
        </WebtoolsDataContext.Provider>
    )

}

export default WebtoolsSettingsPage;