import React from "react"
import { WorkScheduler } from "./components"
const WorkSchedule = () => {
    return (
        <>
            <p>This is my work schedule outside of Kenzie Academy:</p>
            <WorkScheduler />
        </>
    )
}
export default WorkSchedule