import React, { useContext, useState } from 'react';


import MenuCheckbox from '../../components/MenuCheckbox';
import { ACPAnnouncementContext } from '../../context';
import SortRequests from './SortRequests';
import { ACPDataContext } from '../../context';
import CreateButton from '../baseLayout/CreateButton';
import DateSelector from '../../components/DateSelector';
import { useEffect } from 'react';
const AUTHORS = [
    {
        id: "kenzie",
        label: "Kenzie Academy"
    },
    {
        id: "myself",
        label: "Myself"
    }
]

export default function AnnouncementLeftMenu() {
    let { filters,
        setFilters,
        filteredStartDate,
        setFilteredStartDate,
        filteredEndDate,
        setFilteredEndDate,
        announcementsList,
        setSortDirection,
        initialFilters
    } = useContext(ACPAnnouncementContext);
    const { createButtonRef } = useContext(ACPDataContext)

    const [endDateError, setEndDateError] = useState(false)

    useEffect(() => {
        const hasError = (filteredEndDate && filteredStartDate && filteredEndDate.isBefore(filteredStartDate))
        setEndDateError(hasError)
    }, [filteredStartDate, filteredEndDate])

    const handleAuthorChange = (e) => {

        setFilters(filters => ({
            ...filters,
            [e.target.name]: !filters[e.target.name]
        }))

    };

    const handleClearFilters = () => {
        setFilters(initialFilters)
        setFilteredStartDate(null)
        setFilteredEndDate(null)
        setSortDirection('newest')
    }





    return (
        <div className='menuContainer'>
            <CreateButton ref={createButtonRef} buttonBgColor='white' hideIcon border />
            <div className='header'>Sort</div>

            <div className='subItemsContainer'>
                <SortRequests />
            </div>

            <div className='header'>Filter</div>

            <div className='subheader'>Author</div>

            <div className='subItemsContainer'>

                {
                    AUTHORS.map(author => {
                        let count = announcementsList.filter(i => i.post_as === author.id).length;
                        return <MenuCheckbox
                            color='white'
                            key={author.id}
                            value={author.id}
                            name={author.id}
                            isDisabled={count === 0}
                            isChecked={filters[author.id]}
                            handleOnChange={handleAuthorChange}
                            label={`${author.label} (${count})`}
                        />
                    })
                }
            </div>



            <div className='subheader'>Date Range</div>


            <div style={{ marginTop: '10px' }}>
                <DateSelector style={{ marginTop: '8px' }} value={filteredStartDate} handleChange={date => setFilteredStartDate(date)} label="Start Date" />
                <DateSelector style={{ marginTop: '10px' }} hasError={endDateError} value={filteredEndDate} handleChange={date => setFilteredEndDate(date)} label="End Date" />

            </div>
            <div className='clearAllText' onClick={handleClearFilters}>
                Clear All
            </div>
        </div>
    )
}