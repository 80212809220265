import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const skeletonStyles = makeStyles((theme) => ({
    
}))

const SupportTeamPanelSkeleton = () => {
    
    const skeletonClass = skeletonStyles();

    return (
        <div className={skeletonClass.supportTeamPanelSkeleton}>
            <Skeleton variant='rectangular' width="100%" height={472} sx={{borderRadius: "10px"}}/>
        </div>
    )
}

export default SupportTeamPanelSkeleton;