import React from 'react';

import { useStyles } from './styles';
import Image from '../../../../images/kenzieAvatar.png';
import { getUserDisplayName } from '../../../../helpers/utils';

const StaffMemberProfile = ({
    member = null
}) => {

    const classes = useStyles();

    return (
        <>
            {
                member ?
                    <div className={classes.eachStaffMemberName}>
                        <div className={classes.profileImage}>
                            <img id={`profile-image-${member.user_email}`}
                                className={classes.Image}
                                src={member.user_email_mk_user.user_image_url === null ? Image : member.user_email_mk_user.user_image_url}
                                alt={Image}
                                onError={(e) => e.target.src = Image} />
                        </div>
                        <div className='lineHt-sm-1' style={{ marginLeft: "10px" }}>
                            <div>{getUserDisplayName(member.user_email_mk_user)} {member.user_email_mk_user.last_name}</div>
                            <div >{member.user_email}</div>
                        </div>
                    </div> :
                    null
            }
        </>

    )

}

export default StaffMemberProfile;
