import React, { useContext, useEffect, useState } from 'react';

import { ACPDataContext } from '../../../context';
import { useCohortSettingStyles } from '../styles';
import EachCohortCourses from './EachCohortCourses';

export const AssociatedCoursesDataContext = React.createContext();

const CourseManagement = () => {

    const cohortSettingClasses = useCohortSettingStyles();

    const { selectedACPSettingsCohortId, fetchACPSettingsData } = useContext(ACPDataContext);

    let [courses, setCourses] = useState(null);

    useEffect(() => {
        try {
            if (selectedACPSettingsCohortId) {
                fetchACPSettingsData('cohortcourses').then(response => {
                    let mappings=response.filter(i=>i.is_active===true);
                    let setCoursesInfo=mappings.map(item=>item.course);
                    setCourses(setCoursesInfo);
                }).catch(err => console.error(err));
            }
        } catch (error) {
            console.error(error);
        }
    }, [selectedACPSettingsCohortId]);

    return (
        <AssociatedCoursesDataContext.Provider value={{
            "courses":courses,
            "setCourses":setCourses
        }}>
            {
                selectedACPSettingsCohortId && courses ?
                    <>
                        {
                            selectedACPSettingsCohortId.map(cohortId => {
                                return <div 
                                            id="associated-courses" 
                                            className={`${cohortSettingClasses.eachCohortSection}`}
                                            key={`associated-courses-${cohortId}`}>
                                       {/* The reason we have EachCohortCourses Component so we can easily implement multi cohort selection in future if we have to */}
                                       <EachCohortCourses 
                                            cohortId={cohortId}
                                        />
                                </div>
                            })
                        }
                    </> :
                    null
            }
        </AssociatedCoursesDataContext.Provider>
    )

}

export default CourseManagement;