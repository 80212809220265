import React, { useEffect, useState } from "react";

import CheckIcon from '@mui/icons-material/Check';
import { MenuItem, Select, useMediaQuery, useTheme } from "@mui/material";

const PLAYBACKSPEEDS = [
    0.5, 0.75, 1, 1.25, 1.5, 2
]

const PlaybackSpeeds = ({
    videoRef
}) => {
    const [playBackSpeed, setPlayBackSpeed] = useState(1);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (videoRef?.current) {
            setPlayBackSpeed(videoRef.current.playbackRate);
        }
    }, [videoRef])

    /** Handle Playback speed  */
    const handlePlayBackSpeed = (speed) => {
        videoRef.current.playbackRate = speed;
        setPlayBackSpeed(speed);
    };

    return (
        <div className="playback-speed-container">
            {
                !isSmallScreen ?
                    <>
                        <span className='pointer'>Speed</span>
                        <div className="playback-speed-popup" >
                            {
                                PLAYBACKSPEEDS.map(speed => {
                                    return <div className="playback-speeds"
                                        key={speed}
                                        onClick={() => { handlePlayBackSpeed(speed) }}>
                                        {`${speed}x`}
                                        {playBackSpeed === speed ? <CheckIcon fontSize="sm" color="green" /> : null}
                                    </div>
                                })
                            }
                        </div>
                    </> :
                    <div style={{ marginTop: "15px" }}>
                        <div style={{ marginBottom: "15px" }}>Playback Speed</div>
                        <Select
                            fullWidth
                            defaultValue={1}
                            onChange={(event) => { handlePlayBackSpeed(event.target.value) }}
                            value={playBackSpeed}>
                            {
                                PLAYBACKSPEEDS.map(speed => {
                                    return <MenuItem key={speed} value={speed}>{`${speed}x`}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
            }

        </div>
    );
};

export default PlaybackSpeeds;
