import * as React from 'react'


/*
    Custom hook returns a Boolean value and determines if the ref element has children elements that are overflowing the parent container
    initially implemented to easily tell if text previews require a 'read more' feature
    usage docs: https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
*/
export const useIsOverflow = (ref, callback) => {
    const [isOverflow, setIsOverflow] = React.useState(undefined);

    React.useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;

            setIsOverflow(hasOverflow);

            if (callback) callback(hasOverflow);
        };

        if (current) {
            trigger();
        }
    }, [callback, ref]);

    return isOverflow;
};

