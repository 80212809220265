import { v4 as uuid4 } from "uuid";
import * as FullStory from "@fullstory/browser";
import { getUserDisplayName } from "./utils";
import { getRoleNameByRoleId } from "./userRolesHelper";

/**
 * @description Starts a FullStory session for the current user
 * @param {Object} userData The current user
 */
const startFullStorySession = (userData = { }) => {
    if (userData !== null) {
        // Get data that will be sent to FullStory
        // Guard against potential missing / malformed data
        const {
            email = "",
            mk_user_courses: [
                {
                    course_id: courseId = "",
                    cohort_id: cohortId = "",
                    user_role_id: userRoleId = "",
                    cohort: {
                        cohort_name: cohortName = "",
                        program_id: programId = "",
                        program_mappings: [
                            {
                                program: {
                                    program_name: programName = ""
                                } = { },
                            } = { },
                        ] = [ ],
                    } = { },
                } = { },
            ] = [ ],
        } = userData;
        
        const displayName = `${getUserDisplayName(userData)}${userData.last_name? " " + userData.last_name : ""}`;
        const userRole = userRoleId ? getRoleNameByRoleId(userRoleId) : "";

        FullStory.init({ orgId: "o-1AKB5X-na1" });
        FullStory.identify(uuid4(), {
            displayName,
            email,
            courseId,
            cohortId,
            cohortName,
            programId,
            programName,
            userRoleId,
            userRole,
        });
    }
};

export default startFullStorySession;
