import axios from 'axios'
const dotenv = require('dotenv');
dotenv.config();
//** Defined base url for the api */
export const localApiUrl = process.env.LOCAL_API_URL || 'http://localhost:8000/develop';
export const getApiBase = () => process.env.REACT_APP_API_URL || localApiUrl;
//export const getApiBase = () => 'http://localhost:8000/develop';

/**
 * @description - setting global headers for the api calls
 */
export const HEADERS = {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_AWS_X_API_KEY
}

export async function getCohortInfo(cohortId = NaN, additionalParams = {}) {

    try {

        const ATTR = [
            "id",
            "zoom_host_id",
            "live_session_count",
            "study_session_in_progress",
            "zoom_guests",
            "cohort_name",
            "zoom_url",
            "inscribe_community_id",
            "slack_channel_id",
            "users/active",
            "users/user_role_id",
            "users/cohort_id",
            "users/course_id",
            "users/id",
            "users/is_visible",
            "users/user_email",
            "userdetails/email",
            "userdetails/username",
            "userdetails/is_online",
            "userdetails/first_name",
            "userdetails/last_name",
            "userdetails/preferred_name",
            "userdetails/user_image_url",
            "userdetails/user_lms_id",
        ]

        let url = `${getApiBase()}/v1/cohorts`;

        let { data } = await axios.get(url, {
            params: {
                id: cohortId,

                expand: 'users,userdetails,courses',
                attributes: ATTR.join(','),
                ...additionalParams,
            },
            headers: HEADERS
        });

        // ;
        return data.message?.cohorts && data.message.cohorts.length > 0 ? data.message.cohorts[0] : [];

    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getCoursesById = async (cohortId) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/courses`, {
            params: {
                "cohort_id": cohortId,
                "expand": 'courses',
                "limit": 200
            },
            headers: HEADERS
        });


        return data?.message ? data.message.courses : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}


/**
 * Get All Programs from DB from Admin settings. Irrespective if connected to cohort
 */
export const getPrograms = async (query = { limit: 200 }) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/programs`, {
            params: query,
            headers: HEADERS
        });

        return data?.message ? data.message.programs : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}

/**
* Get All Cohorts from DB from Admin settings
*/
export const getCohorts = async (query = {
    "expand": "programs",
    "limit": 200
}) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/cohorts`, {
            params: query,
            headers: HEADERS
        });

        return data?.message ? data.message.cohorts : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}


/**
* Get All Courses from DB from Admin settings
*/
export const getAllCourses = async () => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/courses`, {
            params: {
                "limit": 200
            },
            headers: HEADERS
        });


        return data?.message ? data.message.courses : [];

    } catch (error) {
        console.error(error);
        return [];//returning empty array to error gracefully
    }
}