import React, { useContext } from "react";

import ClosedCaptionOnIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import { Switch, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import { MediaContext } from "../..";

const ClosedCaption = ({
    videoRef
}) => {

    const { transcriptAvailable, showClosedCaption, setShowClosedCaption } = useContext(MediaContext);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));



    /** Hanlde on closed caption button */
    // const handleOnClosedCaption = useCallback(() => {
    //     for (var i = 0; i < videoRef.current.textTracks.length; i++) {
    //         if (!hideClosedCaption) {
    //             videoRef.current.textTracks[i].mode = 'hidden';
    //             setHideClosedCaption(true);
    //         } else {
    //             videoRef.current.textTracks[i].mode = 'showing';
    //             setHideClosedCaption(false);
    //         }
    //     }
    // }, [videoRef, hideClosedCaption]);



    return (
        <>
            {
                transcriptAvailable ?
                    <div onClick={() => setShowClosedCaption(!showClosedCaption)}>
                        {
                            !isSmallScreen ?

                                // Desktop Screens
                                <div className="closed-caption-container" style={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        showClosedCaption ?
                                            <ClosedCaptionOnIcon fontSize="medium" /> :
                                            <ClosedCaptionOffIcon fontSize="medium" />

                                    }
                                </div> :

                                // Mobile Screens
                                <div className="closed-caption-container" style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 3fr 1fr",
                                    columnGap: "5px",
                                    alignItems: "center"
                                }}>
                                    {
                                        showClosedCaption ?
                                            <ClosedCaptionOnIcon fontSize="medium" /> :
                                            <ClosedCaptionOffIcon fontSize="medium" />

                                    }
                                    <div>
                                        {
                                            showClosedCaption ?
                                                "Closed Caption On" :
                                                "Closed Caption Off"

                                        }
                                    </div>
                                    <div>
                                        <Switch checked={showClosedCaption} />
                                    </div>

                                </div>
                        }

                    </div> :
                    <Tooltip title='Closed Captions are not available'>
                        <ClosedCaptionDisabledIcon sx={{ opacity: .3, cursor: 'default !important' }} />
                    </Tooltip>
            }
        </>

    );
};

export default ClosedCaption;
