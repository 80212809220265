import * as React from "react"
import { Avatar } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import useHeaderBaseStyles from "./headerStyle"
import { AppDataContext } from "../../../context";

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "lightgrey",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const UserAvatar = () => {

    const { user } = useAuth0();
    const classes = useHeaderBaseStyles();
    const {userData } = React.useContext(AppDataContext);

    return (<>
        {
            user.picture || userData.user_image_url ?
                <Avatar src={userData.user_image_url ? userData.user_image_url : user.picture} classes={{ root: classes.avatar }} /> :
                <Avatar {...stringAvatar(user.name)} classes={{ root: classes.avatar }} />
        }

    </>)
}

export default UserAvatar;