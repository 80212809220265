import { DashboardSection, SectionTitleContainer, SectionTitle, SectionBody, FullWidthRow, HeadingText, StyledButton, BodyText } from "../Dashboard"
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2"
import { filterMembers } from "../../../context/helpersV2"
import { useState, useRef, useEffect } from 'react'
import { getStudySessionData } from "../../../../api"
import { getGuestLearnerAndStaff } from "../../../../learner/pages/dashboard/connectPanel/helpers"
import SessionParticipants from "../../connectPanel/SessionParticipants"
import SessionModal from "../../../../learner/pages/dashboard/connectPanel/SessionModal"
const TIME_INTERVAL = 15000;//set to 15 seconds

export default function StudySessionPanel() {
    const {
        cohort,
        members,
        userData
    } = useACPDataContextV2()

    let fetchStudySessionDataTimerIdRef = useRef(null);
    const [studySessionData, setStudySessionData] = useState(null);

    const [sessionParticipants, setSessionParticipants] = useState({});
    const [staffParticipants, setStaffParticipants] = useState([]);
    const [learnerParticipants, setLearnerParticipants] = useState([]);
    const [guestParticipants, setGuestParticipants] = useState([]);
    const [showParticipantModal, setShowParticipantModal] = useState(false);

    const [mainTopic, setMainTopic] = useState({})
    const [topics, setTopics] = useState([])

    useEffect(() => {

        return (() => {
            clearInterval(fetchStudySessionDataTimerIdRef.current);
        });

    }, [])

    useEffect(() => {

        if (cohort && fetchStudySessionDataTimerIdRef.current === null && cohort?.zoom_host_id) {

            //Grab the study session
            const fetchStudySessionData = async () => {
                let zoomHostId = cohort?.zoom_host_id
                let data = await getStudySessionData(zoomHostId);

                setStudySessionData(Object.keys(data).length > 0 ? data : null);
            }

            fetchStudySessionData();//on initial mount

            fetchStudySessionDataTimerIdRef.current = setInterval(() => {
                fetchStudySessionData();
            }, TIME_INTERVAL);

        }
    }, [cohort]);

    //Triggered when session data is set
    useEffect(() => {
        //Clean up when meeting is ended 
        if (studySessionData === null) {
            setSessionParticipants({});

            setStaffParticipants([]);
            setLearnerParticipants([]);
            setGuestParticipants([]);

        } else {
            setSessionParticipants(studySessionData?.participants || {});
        }

        if(studySessionData?.topics) {
            const _topics = Object.values(studySessionData.topics)
            setMainTopic(_topics.find(topic => topic.main === true))
            setTopics(_topics.filter(topic => !topic.main))
        }
    }, [studySessionData]);

    //triggered when session participants are set
    useEffect(() => {
        if (Object.keys(sessionParticipants).length > 0) {
            let guestLearnerAndStaff = getGuestLearnerAndStaff(sessionParticipants);
            setGuestParticipants(guestLearnerAndStaff.guests);
            setLearnerParticipants(guestLearnerAndStaff.learners);
            setStaffParticipants(guestLearnerAndStaff.staff);
        } else {

            setStaffParticipants([]);
            setLearnerParticipants([]);
            setGuestParticipants([]);
        }

    }, [sessionParticipants]);



    return (
        <DashboardSection gridArea='ss'>
            <SectionTitleContainer>
                <SectionTitle>
                    Study Session
                </SectionTitle>
            </SectionTitleContainer>

            <SectionBody style={cohort.zoom_url ? { height: 'calc(100% - 45px)' } : {}}>
                {Object.keys(sessionParticipants).length === 0 ?
                    <>
                        {cohort.zoom_url ?
                            <>
                                <FullWidthRow topRow>

                                    <HeadingText>
                                        Want to kick off a cohort study session?
                                    </HeadingText>
                                    <StyledButton floatLeft as='a' target="_blank" rel="noreferrer" onClick={() => setShowParticipantModal(true)}>Start Session</StyledButton>
                                </FullWidthRow>
                                <div style={{ marginTop: '15px' }} >

                                    <BodyText style={{ marginLeft: 0 }}>
                                        There are <b>{filterMembers(members, 'onlineLearners').length}</b> learners online in MyKenzie.
                                    </BodyText>
                                    <BodyText style={{ marginLeft: 0 }}>
                                        Start a study session to assist your learners with assignment and lesson questions.
                                    </BodyText>
                                </div>
                            </> :
                            <>
                                <HeadingText>No Study Session URL Set for this cohort.</HeadingText>

                                <HeadingText>You can add the Zoom URL and Personal Meeting ID in the settings panel.</HeadingText>
                            </>
                        }
                    </>

                    :
                    <>
                        <SessionParticipants cohortInfo={cohort}
                            learnerParticipants={learnerParticipants}
                            guestParticipants={guestParticipants}
                            staffParticipants={staffParticipants}
                            setShowParticipantModal={setShowParticipantModal} />
                    </>
                }
            </SectionBody>
            <SessionModal 
                modalOpen={showParticipantModal} 
                handleModalClose={() => setShowParticipantModal(false)} 
                active={Object.keys(sessionParticipants).length > 0}
                learnerParticipants={learnerParticipants}
                guestParticipants={guestParticipants}
                staffParticipants={staffParticipants}
                cohortInfo={cohort}
                userData={userData}
                mainTopic={mainTopic}
                topics={topics}
            />
        </DashboardSection>

    )
}