import React, { useState, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import { ACPDataContext } from '../../../context';
import { useStyles } from './styles';
import { useCohortSettingStyles } from '../styles';
import Webtools from './Webtools';
import EditWebtoolsForm from './EditWebtoolsForm';

const EachCohortWebtools = ({
    webtools = [],
    cohortId = null
}) => {

    const classes = useStyles();
    const cohortSettingClasses=useCohortSettingStyles();

    const { userCourses } = useContext(ACPDataContext);

    const cohortInfo = userCourses.find(i => i.cohort.id === cohortId);

    const [edit, setEdit] = useState(false);

    return (
        <div>
            <div>
                <div className={`${cohortSettingClasses.flexNameHeader} ${cohortSettingClasses.subheader}`}>
                    <div>Online Tools</div>
                    <div>
                        {
                            !edit ?
                                <IconButton 
                                    title='Edit Webtools'
                                    data-testid='cohort-settings-webtools-edit-button'
                                    onClick={() => {
                                        setEdit(true);
                                    }}
                                    sx={{ marginLeft: "8px" }}
                                    id='cohort-settings-webtools-edit-button'
                                    aria-label="edit webtools" 
                                    size="small">
                                    <EditIcon
                                        sx={{
                                            fontSize: "18px"
                                        }}
                                    />
                                </IconButton> :
                                null
                        }
                        
                    </div>
                </div>
                <div className='text-normal'>
                    These online tools will appear as quick links on the learner’s dashboard. These items are stored in the database. Please reach out to the R&D team if you need to add a new online tool to this list.
                </div>
            </div>
            <div>
                {
                    !edit ?
                        <>
                            <div className={`${classes.eachStaffSection} ${cohortSettingClasses.divider}`}>
                                <Webtools webtools={webtools} />
                            </div>
                        </> :
                        <EditWebtoolsForm 
                            cohortId={cohortId}
                            courseId={cohortInfo.course_id}
                            handleOnClose={()=>{setEdit(false)}}
                            webtools={webtools}/>
                }

            </div>

        </div>
    )

}

export default EachCohortWebtools;