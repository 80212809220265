import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles"
import { ReactComponent as MyKenzieLogo } from '../images/my-kenzie-logo-2.svg';
import { getUserDisplayName } from '../helpers/utils';


const useStyles = makeStyles((theme) => ({
    mkLoadContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    mkLogo: {
        height: "150px",
        marginBottom: "20px"
    },
    mkLoader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            transform: "scale(.7)"
        },
        [theme.breakpoints.down(380)]: {
            transform: "scale(.6)"
        },
        [theme.breakpoints.down(300)]: {
            transform: "scale(.5)"
        },
    },
    mkLoadGreeting: {
        fontSize: "22px",
        fontFamily: 'Nunito',
        textAlign: "center",
        width: "239px",
        fontWeight: 600
    },
    mkProgressBar: {
        border: "2px solid #14606B",
        borderRadius: "10px",
        marginTop: "-10px",
        display: "flex",
        width: "240px",
        height: "35px",
    },
    progress: {
        height: "35px",
        width: "0px",
        backgroundColor: "#14606B",
        borderRadius: "5px",
    },
    mkLoadMessage: {
        textAlign: "center",
        fontSize: "16px",
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
    },
    progressAnimation: {
        animationName: "$progressBar",
        animationDuration: "10s",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    logoAnimation: {
        animationName: "$mkLogoFade",
        animationDuration: "1s",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    "@keyframes progressBar": {
        from: {
            width: "0%"
        },
        to: {
            width: "100%"
        },
    },
    "@keyframes mkLogoFade": {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
    },
    mkLoadGroupHidden: {
        visibility: "hidden !important"
    }
}))

const MyKenzieLoader = (props) => {
    const classes = useStyles(props);
    const [loadMessage, setLoadMessage] = useState("Getting things ready for you...");

    useEffect(() => {
        setTimeout(() => setLoadMessage("Let's go!"), 6000);
    }, []);

    return (
        <div className={classes.mkLoadContainer}>
            <div className={classes.mkLoader}>
                <div className={`${classes.mkLogo} ${classes.logoAnimation}`}>
                    <MyKenzieLogo />
                </div>
                <div className={`${!props.user ? classes.mkLoadGroupHidden : ''}`}>
                    <div className={classes.mkLoadGreeting}>
                        <p>Welcome, {getUserDisplayName(props.user)}!</p>
                    </div>
                    <div className={classes.mkProgressBar}>
                        <div className={`${classes.progress} ${classes.progressAnimation}`} id="progress"> </div>
                    </div>
                    <div className={classes.mkLoadMessage}>
                        <p id="mkLoadMessage">{loadMessage}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyKenzieLoader;
