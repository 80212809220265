import React from 'react';
import {
    TextField, InputAdornment, IconButton
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const CohortDropDownTextField = React.forwardRef(({
    menuVisible,
    handleOnClick,
    getSelectedCohortName,
    shouldDisable
}, ref) => {

    return (
        <TextField
            ref={ref}
            onClick={handleOnClick}
            onChange={() => {
                return false;
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                        id="cohort-drop-down-icon-button"
                        aria-label="toggle cohort drop down menu"
                        edge="end"
                    >
                        {
                            menuVisible ?
                                <ArrowDropUpIcon fontSize='sm' /> :
                                <ArrowDropDownIcon fontSize='sm' />
                        }
                    </IconButton>
                </InputAdornment>
            }}
            value={shouldDisable ? 'Unavailable' : getSelectedCohortName() || ''}
            sx={{
                backgroundColor: 'white',
                opacity: shouldDisable ? .2 : 1,
                borderRadius: "8px",
                "& input": {
                    cursor: "pointer",
                    borderRadius: "8px",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "open sans"
                }
            }}
        ></TextField>
    )
})

export default CohortDropDownTextField;