import React, { useContext, useEffect, useState } from 'react';
import { CalendarEventsContext } from '.';
// import { ReactComponent as MissedVideoCallIcon } from '../../../../images/film_white.svg';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import moment from 'moment';
import { checkIfRecordingExists } from './helpers';
import { useStyles } from './styles';

/**
 * @important - Once we decide to use the video hosted in mykenzie to be linked, follow the changes in the MR below for RecordingButton.js
 * @MR Link - https://gitlab.com/kenzie-academy/studio/mykenzie/mykenzie/-/merge_requests/1112/diffs#520f89dbf1407061c9ebfbe112d635a326847afc
 */
export default function RecordingButton({
    eventState = null,
    eventItem = null
}) {

    const classes = useStyles();

    const { eventRecordings } = useContext(CalendarEventsContext);

    const [buttonState, setButtonState] = useState(null);
    const [recordingDetails, setRecordingDetails] = useState(null);
    const [playUrl, setPlayUrl] = useState(null);

    /**
     * @description - Checks if the event has recording and sets in the state variables
     * Once set state variable triggers useEffect to find the appropriate play url
     */
    useEffect(() => {
        if (eventRecordings) {

            let res = checkIfRecordingExists(eventItem, eventRecordings);//checks the recordign details and sets the state variables

            if (res !== null && res?.state) {
                setButtonState(res.state);

                if (res.recordingDetails !== null) {
                    setRecordingDetails(res.recordingDetails);
                }
            }

        }

    }, [eventRecordings]);


    useEffect(() => {
        if (recordingDetails !== null && recordingDetails.length > 0) {
            let playUrl = getPlayUrlForRecording()
            if (!playUrl) setButtonState("recording_unavailable")
            setPlayUrl(getPlayUrlForRecording());
        }
    }, [recordingDetails]);

    /**
     * @description this logic scans thru the recordings and gets the longest recording
     * @returns play_url for longest video 
     */
    const getPlayUrlForRecording = () => {
        let play_url = null;

        if (recordingDetails?.length === 1 && recordingDetails[0].video_details?.generatedUUID) {
            play_url = `/media/${recordingDetails[0].video_details.generatedUUID}`
        } else if (recordingDetails?.length > 1) { // if for some reason there are multple recordings associated with one meeting id, get longest one
            //filter out recordings not on same date as event
            let eventStartDate = moment(eventItem.start_date_time).format('MDY')
            let sameDayRecordings = recordingDetails.filter(recording => {
                let recordingStartDate = moment(recording.source_details?.recording_details?.start_time).format('MDY')
                return eventStartDate === recordingStartDate
            })

            if (sameDayRecordings.length === 1) {
                play_url = `/media/${sameDayRecordings[0].video_details.generatedUUID}`
            }
            else if (sameDayRecordings.length > 1) {


                let longestDuration = 0;
                for (let recording of recordingDetails) {
                    let recording_start = moment(recording?.video_details?.recording_start);
                    let recording_end = moment(recording?.video_details?.recording_end);
                    let diff = recording_end.diff(recording_start);

                    if (diff > longestDuration && recording.video_details?.generatedUUID) {
                        longestDuration = diff;
                        play_url = `/media/${recording.video_details.generatedUUID}`
                    }
                }
            }
        }
        return play_url


    }

    return (
        <div data-eventstate={eventState}
            data-buttonstate={buttonState}>

            {
                buttonState === "has_recording" ?
                    <a href={playUrl} >
                        <button className={`${classes.recordingButton} ${classes.eventButton}`}>
                            <PlayCircleFilledWhiteIcon style={{
                                fontSize: '1.2rem'
                            }} />
                            <div className='button-title'>Recording</div>
                        </button>
                    </a> :
                    buttonState === "recording_unavailable" || buttonState === "meetingId_unknown" ?
                        null :
                        null
            }
        </div>
    )
}