import React, { useContext, useEffect, useRef, useState } from 'react';

import { ReactComponent as OnlineStatus } from '../../../images/online_indicator_green.svg';

import { CircularProgress, Container } from '@mui/material';
import { v4 as uuid4 } from 'uuid';
import { AppDataContext } from '../../../context';
import { ReactComponent as Star } from '../../../images/star.svg';
import { LearnersDataContext } from '../../context/LearnersDataContext';
import EachLearnerCard from './EachLearnerCard';
import EachStaffCard from './EachStaffCard';
import useStyles from './styles';

import { filterUserDataSet } from './helpers';

import { updateUser } from '../../../api/userManagement';
import ConnectPanel from '../dashboard/connectPanel';
import SearchCohortUsers from './SearchCohortUsers';

import KBotLives from '../../../images/lost_illustration.svg';
import { useCohortConnectionDataHook } from '../../context/useCohortConnectionDataHook';

var updateUserSetTimeOut = null;

const LearnerStaffDirectoryPage = () => {

    const classes = useStyles();
    const [cohortName, setCohortName] = useState(null);

    let userFavoritesRef = useRef(null);

    const [numberOfUsersInCohort, setNumberOfUsersInCohort] = useState(Number);

    const [profileCards, setProfileCards] = useState([]);
    const [filteredProfileCards, setFilteredProfileCards] = useState([]);

    const [onlineFilterBtn, setOnlineFilterBtn] = useState(false);
    const [starredFilterBtn, setStarredFilterBtn] = useState(false);

    /** Search Cohort Users states */
    /** state variable has 3 states, empty array, null and array values. Null = user cleared the search */
    const [cohortUserSearchResults, setCohortUserSearchResults] = useState(null);
    const [cohortUserSearchTerm, setCohortUserSearchTerm] = useState(null);

    const [userFavorites, setUserFavorites] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    //let profileCardIntialRef=useRef([]);//inital value got from the database
    let profileCardDataRef = useRef([]);//Updated everytime online state is updated 

    const { userCohort, } = useContext(LearnersDataContext);
    const { setIsAllComponentsLoaded, userData, setUserData } = useContext(AppDataContext);

    const cohortConnectionData = useCohortConnectionDataHook(userCohort?.cohort_id);

    useEffect(() => {

        if (userCohort && userCohort?.cohort) {

            try {
                let cohortName = userCohort.cohort.cohort_name;

                setCohortName(cohortName);

                setIsAllComponentsLoaded(true);

            } catch (error) {
                console.error(error);
            }
        }

    }, [userCohort]);

    useEffect(() => {
        if (cohortConnectionData !== null) {

            /**
             * Filter cohort user dataset. 
             */
            let dataset = filterUserDataSet(cohortConnectionData[0].mk_user_courses, userData);
            
            /**
            *  Profile cards are sorted by role. All staff roles will show first and then student profile cards will follow
            * */
            let usersExcludingAdmin = sortProfileCards(dataset);
            profileCardDataRef.current = usersExcludingAdmin;

            setProfileCards(usersExcludingAdmin);
            setFilteredProfileCards(usersExcludingAdmin);

            setIsLoading(false);

        }
    }, [cohortConnectionData]);


    useEffect(() => {
        if (userData) {
            setUserFavorites(userData.user_favorite_profiles ? userData.user_favorite_profiles : []);
        }
    }, [userData]);

    useEffect(() => {
        setNumberOfUsersInCohort(filteredProfileCards.length);
    }, [filteredProfileCards]);

    /** 
     * Side effects triggers whenever there is search performed
     * This resets the online and starred filter 
     */
    useEffect(() => {
        if (cohortUserSearchResults && cohortUserSearchResults.length > 0) {
            setOnlineFilterBtn(false);
            setStarredFilterBtn(false);
        }
    }, [cohortUserSearchResults]);

    /**
     * Side effect is performed when user clicks on online button
     */
    useEffect(() => {
        let btn = document.getElementById("online_filter_button");

        if (onlineFilterBtn && btn) {
            btn.classList.remove(classes.filterAnimationOff);
            btn.classList.add(classes.filterAnimationOn);

        } else if (!onlineFilterBtn && btn) {
            btn.classList.remove(classes.filterAnimationOn);
            btn.classList.add(classes.filterAnimationOff);
        }

    }, [onlineFilterBtn]);

    /**
     * Side effect is performed when user clicks on starred button
     */
    useEffect(() => {

        let btn = document.getElementById("starredFilterBtn");

        if (starredFilterBtn && btn) {
            btn.classList.remove(classes.filterAnimationOff);
            btn.classList.add(classes.filterAnimationOn);

        } else if (!starredFilterBtn && btn) {
            btn.classList.remove(classes.filterAnimationOn);
            btn.classList.add(classes.filterAnimationOff);
        }

    }, [starredFilterBtn]);

    //Sorts the profile cards
    const sortProfileCards = (profileCards) => {
        let sortedProfileCards = profileCards.sort((a, b) => b.user_role_id - a.user_role_id);
        return sortedProfileCards;
    };

    /**
     * @description The user information is updated every 3 seconds. the information is batched and send to database.
     * @param {*} dataset 
     */
    const updateUserData = async (dataset) => {
        try {
            userFavoritesRef.current = dataset;//using ref variable to store the information so it can be used when update is send in setInterval

            let d = { ...userData };
            d.user_favorite_profiles = [...dataset];

            setUserData(d);//cache the data set in the userData AppContext variable in App.js

            //User information is updated after every 3seconds after user clicks on the star
            //this helps in reducing number of database calls when user click unclick the star multiple times
            if (updateUserSetTimeOut === null) {

                //we can potentially use settimeout but using setInterval for now
                updateUserSetTimeOut = setInterval(async () => {
                    await updateUser({
                        "user_favorite_profiles": userFavoritesRef.current
                    }, userData.email);

                    clearInterval(updateUserSetTimeOut);
                    updateUserSetTimeOut = null;

                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const addToFavorites = (userEmail) => {
        let userFavoriteList = [...userFavorites];
        userFavoriteList.push({
            user_email: userEmail,
            id: uuid4()
        });
        setUserFavorites(userFavoriteList);
        updateUserData(userFavoriteList);
    };

    const removeFromFavorites = (index) => {
        let userFavoriteList = [...userFavorites];
        userFavoriteList.splice(index, 1);
        updateUserData(userFavoriteList);
        setUserFavorites(userFavoriteList);
    };

    const handleUserFavorites = (userEmail) => {
        let d = [...userFavorites];
        let indx = d.findIndex(i => i.user_email === userEmail);

        if (indx === -1) {
            addToFavorites(userEmail);
        } else {
            removeFromFavorites(indx);
        }
    }

    //Logic: 
    //This method checks if there are any cards to display
    //profileCard array length can be more than zero incase user is filtering for online and starred users, since we hide and show the cards instead of mounting and unmounting
    //Following logic 
    // 1. Profile cards array len is more than 0, and online filter and userFavoriteUsers not selected
    // 2. checks if online filter is selected, if yes checks if there are any users online
    // 3. checks if starred filter is selected, if yes checks if there are any users favorites
    // 4. If nothing matches the criteria, show kbot gif
    const checkProfileCardsExists = () => {
        let profileCardsArray = cohortUserSearchResults ? cohortUserSearchResults : filteredProfileCards
        let usersOnline = profileCardsArray.filter(i => i.user_email_mk_user.is_online === true);
        let userFavoriteUsers = profileCardsArray.filter(i => typeof userFavorites.find(j => j.user_email === i.user_email) !== 'undefined')

        if (profileCardsArray.length > 0 && !onlineFilterBtn && !starredFilterBtn) {
            return true
        }

        if (!onlineFilterBtn && (starredFilterBtn && userFavoriteUsers.length > 0)) {
            return true

        } else if (!starredFilterBtn && (onlineFilterBtn && usersOnline.length > 0)) {
            return true

        } else if (starredFilterBtn && userFavoriteUsers.length > 0 && onlineFilterBtn && usersOnline.length > 0) {
            return true

        }

        return false;
    }

    return (
        <div className={classes.directoryWrapper}>
            {
                isLoading ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh", position: "relative", top: "0px" }}>
                        <CircularProgress />
                    </div> :
                    <Container sx={{
                        marginBottom: "80px",
                        width: "100vw"
                    }}>
                        <div className={classes.directoryCohort}>
                            {
                                cohortUserSearchResults ?
                                    <p>Results For: <span id="search-queries">"{cohortUserSearchTerm ? cohortUserSearchTerm : ""}"</span>
                                        <span id="search-quantity" style={{ margin: "0px 5px" }}>{`(${cohortUserSearchResults.length})`}</span>
                                        <span><span id="clear-results" className="clearResults" onClick={() => setCohortUserSearchResults(null)}>Clear Results</span></span>
                                    </p>
                                    :
                                    <p>Your Cohort | <span id="cohort-connection-total-cohort-users">{cohortName}</span>
                                        <span style={{ margin: "0px 5px" }} id="cohortQuantity">{`(${numberOfUsersInCohort})`}</span>
                                    </p>
                            }
                        </div>

                        <div style={{ "maxWidth": "400px" }}>
                            <ConnectPanel
                                cohortConnectionData={cohortConnectionData}
                            />
                        </div>

                        <div className={classes.directoryStatus}>

                            <div className={classes.directoryCohort}>
                                {
                                    cohortUserSearchResults ?
                                        <p>Results For: <span id="search-queries">"{cohortUserSearchTerm ? cohortUserSearchTerm : ""}"</span>
                                            <span id="search-quantity" style={{ margin: "0px 5px" }}>{`(${cohortUserSearchResults.length})`}</span>
                                            <span><span id="clear-results" className="clearResults" onClick={() => setCohortUserSearchResults(null)}>Clear Results</span></span>
                                        </p>
                                        :
                                        <p>Your Cohort | <span id="cohort-connection-total-cohort-users">{cohortName}</span>
                                            <span style={{ margin: "0px 5px" }} id="cohortQuantity">{`(${numberOfUsersInCohort})`}</span>
                                        </p>
                                }
                            </div>
                            <div className={classes.filterSearch}>
                                <div className={classes.filters}>
                                    <div className={`${classes.onlineFilterBtn} `}
                                        id="online_filter_button"
                                        data-onlinetoggle={onlineFilterBtn}
                                        onClick={() => (setOnlineFilterBtn(!onlineFilterBtn))}>
                                        <OnlineStatus className={classes.onlineStatusIcon} />
                                        <p>Online Now</p>
                                    </div>
                                    <div className={classes.starredBtn} id="starredFilterBtn"
                                        onClick={() => (setStarredFilterBtn(!starredFilterBtn))}>
                                        <Star className={`${classes.starredIcon} ${starredFilterBtn === true ? `${classes.activeStarIcon} ${classes.starFilterAnimation}` : ""}`} id="starredIcon" />
                                        <p>Starred</p>
                                    </div>
                                </div>
                                <SearchCohortUsers
                                    setCohortUserSearchTerm={setCohortUserSearchTerm}
                                    setCohortUserSearchResults={setCohortUserSearchResults}
                                    profileCards={[...profileCards]}
                                />
                            </div>
                        </div>
                        {

                            checkProfileCardsExists() ?
                                <div className={classes.directoryCardGallery} id="directory-card-gallery">
                                    <MapProfileCards
                                        profileCards={cohortUserSearchResults ? cohortUserSearchResults : filteredProfileCards}
                                        onlineFilterScreen={onlineFilterBtn}
                                        favoritesFilterScreen={starredFilterBtn}
                                        handleUserFavorites={handleUserFavorites}
                                        userFavorites={userFavorites}
                                    />
                                </div>
                                :
                                <div className={classes.kBotContainer} >
                                    <img src={KBotLives} className={classes.kBotLives} alt="" />
                                    <div className={classes.noResultsText}>
                                        <p id="no-results-header">No results were found...</p>
                                        <p id="no-results-message">Not what you expected? Try changing your filters or search criteria</p>
                                    </div>
                                    {
                                        starredFilterBtn === true || onlineFilterBtn === true || (starredFilterBtn && onlineFilterBtn === true) ? null :
                                            <span><span className={"clearResults"} onClick={() => setCohortUserSearchResults(null)}>Clear Results</span></span>
                                    }
                                </div>
                        }

                    </Container>
            }
        </div>
    )

}
export default LearnerStaffDirectoryPage;


const MapProfileCards = ({
    profileCards,
    onlineFilterScreen,
    handleUserFavorites,
    userFavorites,
    favoritesFilterScreen,
}) => {


    return (
        <>
            {
                profileCards.map((card) => (
                    card.user_role_id !== 1 && card.user_role_id !== 9 ?
                        <EachStaffCard
                            key={card.user_email}
                            cardData={card}
                            onlineFilterScreen={onlineFilterScreen}
                            handleUserFavorites={handleUserFavorites}
                            favoritesFilterScreen={favoritesFilterScreen}
                            isFavorite={userFavorites.find(i => i.user_email === card.user_email) ? true : false}
                        />
                        :
                        <EachLearnerCard
                            key={card.user_email}
                            cardData={card}
                            onlineFilterScreen={onlineFilterScreen}
                            handleUserFavorites={handleUserFavorites}
                            favoritesFilterScreen={favoritesFilterScreen}
                            isFavorite={userFavorites.find(i => i.user_email === card.user_email) ? true : false}
                        />
                ))
            }
        </>

    )
}
