import React from 'react';

import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';

const skeletonStyles = makeStyles((theme) => ({
    eventPanelSkeleton: {
        height:"350px",
        width: "100%"
    }
}));

const EventPanelSkeleton = () => {
    const skeletonClass = skeletonStyles();

    return (
        <div className={skeletonClass.eventPanelSkeleton}>
            <Skeleton variant='rectangular' width="100%" height="100%" sx={{borderRadius: "10px"}}/>
        </div>
    )
}

export default EventPanelSkeleton;