import { Container, Stack } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { useStyles } from '../baseLayout/styles';
import { useCohortSettingStyles } from './styles';

import CohortSettingsLeftMenu from './CohortSettingsLeftMenu';

import CircularLoader from '../../../sharedComponents/CircularLoader';
import { ACPDataContext } from '../../context';
import CalendarSettingsPage from './calendar/CalendarSettingsPage';
import CohortSettingsSnackBars from './CohortSettingsSnackBars';
import CourseManagement from './courseManagement';
import CohortStaffSettingsPage from './staff';
import WebtoolSettings from "./webtools";

export const CohortSettingsContext = React.createContext();

const CohortSettings = ({ children }) => {

    const cohortSettingClasses = useCohortSettingStyles();
    const classes=useStyles();

    const componentsLoadedRef = useRef({
        "webtools": false,
        "calendars": false,
        "supportStaff": false
    })


    const { userCourses, selectedACPSettingsCohortId } = useContext(ACPDataContext);

    let [isLoading, setIsLoading] = useState(true);

    let [submissionSuccessMessage, setSubmissionSuccessMessage] = useState('Your changes were saved successfully');
    let [submissionSuccessfull, setSubmissionSuccessfull] = useState(false);

    let [submissionError, setSubmissionError] = useState(false);
    let [submissionErrorMessage, setSubmissionErrorMessage] = useState("There was an error submitting info");

    const cohortInfo = userCourses.find(i => i.cohort.id === selectedACPSettingsCohortId[0]);

    useEffect(()=>{
        if(selectedACPSettingsCohortId){
            resetRef();
            setIsLoading(true);
        }
    },[selectedACPSettingsCohortId]);

    const resetRef=()=>{
        Object.keys(componentsLoadedRef.current).forEach(key => {
            componentsLoadedRef.current[key]=null;
        });
    }

    const setComponentLoaded = (key) => {
        try {
            componentsLoadedRef.current[key] = true;
            if (Object.values(componentsLoadedRef.current).every(i => i === true)) {
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <CohortSettingsContext.Provider value={{
            "setComponentLoaded": setComponentLoaded,
            "isLoading":isLoading,

            "submissionSuccessMessage":submissionSuccessMessage,
            "setSubmissionSuccessMessage":setSubmissionSuccessMessage,

            "submissionSuccessfull":submissionSuccessfull,
            "setSubmissionSuccessfull":setSubmissionSuccessfull,

            "submissionError":submissionError,
            "setSubmissionError":setSubmissionError,

            "submissionErrorMessage":submissionErrorMessage,
            "setSubmissionErrorMessage":setSubmissionErrorMessage

        }}>
            <div className={classes.lBarRightPaneLevel2Menu}>
                <CohortSettingsLeftMenu />
            </div>
            <div className={classes.lBarRightPaneContentContainer}>
                <Container>
                    
                    {
                        //This will unmount loader instead of hiding it
                        isLoading?
                        <CircularLoader />:
                        null
                    }

                    <div style={{
                        display: isLoading ? "none" : "inherit"
                    }}>
                        <div className={cohortSettingClasses.cohortNameHeader}>
                            MyKenzie Settings | {cohortInfo?.cohort?.cohort_name}
                        </div>
                        <div className='lineHt-md text-normal'>Modifying these settings will change what is shown to learners on their MyKenzie dashboard</div>

                        <Stack spacing={3}>
                            <CourseManagement />
                            <CalendarSettingsPage />
                            <CohortStaffSettingsPage />
                            <WebtoolSettings />
                        </Stack>

                    </div>

                </Container>

            </div>

            <CohortSettingsSnackBars />

        </CohortSettingsContext.Provider>
    )

}


export default CohortSettings;