import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        alignItems: "center",
        padding: '20px 10px 10px 15px',
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px 8px 8px 8px"
    },
    webtoolContent: {
        display: "flex",
        justifyContent: "center",
        width: "160px",
    },
    webtool: {
        display: "inline-block",
        minWidth: "70px",
        maxWidth: "70px",
        height: "65px",
        margin: "0px 3px 12px 3px",
        position: "relative",
        "&:hover": {
            cursor: "pointer",
        }
    },
    Image: {
        position: "relative",
        zIndex: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "auto",
        width: "63px",
        borderRadius: "8px",
        transition: "box-shadow 600ms",
        paddingBottom: "3px",
        "&:hover": {
            cursor: "pointer",
            boxShadow: "0px 1px 12px rgba(165,165,165, .70)",
        }
    },
    webtoolName: {
        width: "100%",
        height: "20px",
        fontSize: "13px",
        lineHeight: "16px",
        marginTop: "5px",
        marginBottom: "10px",
        position: "relative",
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        ...theme.MyKenzie.text.contentSubtitle,
        fontWeight: 600
    },
    skeletonWebTools: {
        width: "90% !important",
        maxWidth: "279px !important",
        height: "35px !important",
        borderRadius: "50px !important",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "301px !important"
        }
    },
    skeletonMargin: {
        marginLeft: 15
    },
    
}));
export default useStyles;