import * as React from 'react';
import useModalStyles from "./modalStyles";

import { getUserDisplayName } from "../../../../helpers/utils";
import Image from '../../../../images/kenzieAvatar.png';

import { Avatar} from "@mui/material";
import { Link } from "react-router-dom";

const EachParticipant = ({
    participant,
    isStaff = false,
    isGuest = false
}) => {

    const profileURL = "/profile/";

    return <>
        {
            isGuest && participant.is_mykenzie_user === false ?
                <UserNameWithAvatar
                    isGuest={true}
                    myKenzieUser={participant.is_mykenzie_user}
                    participant={participant} /> :
                participant.is_mykenzie_user ?
                    <Link
                        style={{ textDecoration: "none" }}
                        to={`${profileURL}${participant.user_email}`}>
                        <UserNameWithAvatar
                            isStaff={isStaff}
                            isGuest={false}
                            participant={participant} />
                    </Link> :
                    null
        }
    </>
}

const UserNameWithAvatar = ({
    isGuest = false,
    isStaff = false,
    participant
}) => {
    const classes = useModalStyles();
    return <div className={`${classes.nameCard} ${isGuest ? "" : classes.profileNameCard}`}>
        <Avatar
            sx={{ width: 32, height: 32 }}
            className={isStaff ? classes.staffAvatar : classes.studentAvatar}
            alt={getUserDisplayName(participant)}
            src={participant?.user_image_url || Image} />
        <span>{getUserDisplayName(participant)} {participant.last_name}</span>
    </div>
}

export default EachParticipant;