import styled from "@emotion/styled";
import { Stack } from '@mui/material';
import React from "react";
import useStyles from './styles';

import RichTextEditor from "../../../../sharedComponents/RichTextEditor";

const RenderRequestQuestions = ({ inputs, handleChange, request }) => {

    const classes = useStyles();
    const quill_toolbar = [
        ['bold', 'italic', 'underline', 'code-block', 'link'],
        [{'list':'bullet'}]
    ]
    switch (request) {
        // stacked cases will handle the same logic. assessment and activity share the same questions
        case "assessment":
        case "activity":
            return (
                <Stack className={classes.formContainer} direction="column" spacing={6} >
                <Title>What challenge are you facing?</Title>
                    <RichTextEditor 
                        name="challenge"
                        id="challenge"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "challenge",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="I'm struggling with part 2 of the activity. I am getting unexpected output and my test is failing..."
                        toolbar={quill_toolbar}
                    />
                <Title>What have you tried to solve this?</Title>
                    <RichTextEditor 
                        name="solution"
                        id="solution"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "solution",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="read through the readings, checked Inscribe, and reached out to a few classmates. I also checked the last Q&A session..."
                        toolbar={quill_toolbar}
                    />
                <Title>Do you have any helpful links to share?</Title>

                    <RichTextEditor 
                        name="link"
                        id="link"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "link",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="Here is a link to my project file..."
                        toolbar={quill_toolbar}
                    />
                </Stack>
            );
        case "lesson":
            return (
                <Stack className={classes.formContainer} direction="column" spacing={6}>
                    <Title>What part of the lesson are you struggling with?</Title>
                    <RichTextEditor 
                        name="struggling"
                        id="struggling"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "struggling",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="I'm having a hard time grasping advanced loops, and I'm not sure where I'm going wrong..."
                        toolbar={quill_toolbar}
                    />
<Title>Any additional info you would like to share?</Title>
                    <RichTextEditor 
                        name="additional"
                        id="additional"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "additional",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="Here are the additional resources I used..."
                        toolbar={quill_toolbar}
                    />
                
                </Stack>
            );
        case "grades":
            return (
                <Stack className={classes.formContainer} direction="column" spacing={6}>
                    <Title>How can we assist you with your grades?</Title>
                    <RichTextEditor 
                        name="grades"
                        id="grades"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "grades",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="I would like to know where I missed points in my previous assignment..."
                        toolbar={quill_toolbar}
                    />

                </Stack>
            );
        case "personal":
            return (
                <Stack className={classes.formContainer} direction="column" spacing={6}>
<Title> Is there anything you'd like to share with your support staff?</Title>
                    <RichTextEditor 
                        name="personal"
                        id="personal"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "personal",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="Share what you are comfortable with..."
                        toolbar={quill_toolbar}
                    />
                <HelperText>*This will be shared with your academic team and learner success advisor</HelperText>
                </Stack>
            );
        case "other":
            return (
                <Stack className={classes.formContainer} direction="column" spacing={6}>
<Title>What would you like to share?</Title>
                    <RichTextEditor 
                        name="other"
                        id="other"
                        handleOnChange={(content) => {
                            handleChange({
                                target: {
                                    name: "other",
                                    value: content
                                }
                            })
                        }}
                        placeholderText="I had a great time during the AMA yesterday..."
                        toolbar={quill_toolbar}
                    />
                    
                </Stack>
            );
        default:
            break;
    };
};

export default RenderRequestQuestions;

const Title = styled.p`
margin: 0 !important;
margin-bottom: -38px !important;
margin-top: 24px !important;

`
const HelperText = styled.span`
font-style: italic;
font-size: 12px;
`