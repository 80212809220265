import { makeStyles } from "@mui/styles";

export const boxStyle={
    fontFamily:"open sans",
    position: 'absolute',
    overflowY:"auto",
    maxHeight:"85vh",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
}

export const useFormStyles = makeStyles(theme => ({

    formHeaderContainer:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },

    formControlSection: {
        margin: "35px 0px",
        '& input':{
            color:"black",
            fontFamily:'open sans'
        },
        '& .MuiInputLabel-formControl':{
            color:"#484848 !important",
            fontWeight:"500"
        }
    },

    formFooter:{
        display:"flex",
        justifyContent:"flex-end",
        alignContent:"center",
        alignItems:"center",
        margin: "15px 0px 10px 0px"
    },

    submitButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            ...theme.elements.darkBlueButton
        }
    },
    removeButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            ...theme.elements.darkBlueButton,
            backgroundColor:`${theme.colors.rust} !important`
        }
    },
    textButton:{
        ...theme.elements.subtitle,
        color:`${theme.colors.deepBlue} !important`,
        textTransform:"capitalize !important",
        marginRight:"15px !important",
        fontWeight:"650 !important"
    },
    descriptionText:{
        fontWeight:'400',
        fontSize:"12px",
        marginTop:"5px",
        color:"grey"
    }
}))
