import KALogo from '../../../images/KALogo.png';
import useStyles from "./styles";

const DefaultThumbnail = () => {
    const styles = useStyles()


    return (

        <div className={styles.defaultThumbnailContainer}>
            <img src={KALogo} alt='' style={{ width: '60px', margin: 'auto' }} />

        </div>
    )
}

export default DefaultThumbnail