import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useState } from "react";
import { MediaContext } from ".";
import useStyles from "./styles";

//Important - The search logic happens in the TranscriptPanel Component
//Here we just set the context variable of what was searched
const SearchVideo = React.forwardRef((props, ref) => {

    const classes = useStyles();

    const { setSearchText, transcriptAvailable, hasChatHistory } = useContext(MediaContext);
    const [showClearIcon, setShowClearIcon] = useState(false)

    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchText(event.target.value);
        }
    }

    const handleCleanSearchesIfEmpty = (event) => {
        if (event.target.value.length === 0) {
            setSearchText(event.target.value);
            setShowClearIcon(false)
        } else setShowClearIcon(true)
    }

    const handleOnClearSearch = () => {
        setSearchText("");
        setShowClearIcon(false)

        document.getElementById("search-video-input-field").value = "";
    }


    if (!transcriptAvailable && !hasChatHistory) return null;
    return (
        <>
            <div className={`${classes.searchVideoInputField} 
                ${transcriptAvailable !== null && !transcriptAvailable ? "mark-disabled" : ""}`}
                disabled={transcriptAvailable !== null && !transcriptAvailable ? "disabled" : ""}>
                <SearchIcon className="search-icon" fontSize="small" />
                <input
                    disabled={transcriptAvailable !== null && !transcriptAvailable ? "disabled" : ""}
                    id="search-video-input-field"
                    className="open-sans"
                    ref={ref}
                    placeholder={transcriptAvailable ? 'Search this video...' : 'Search Unavailable'}
                    type="text"
                    onChange={handleCleanSearchesIfEmpty}
                    onKeyDown={handleOnKeyDown} />
                {
                    (transcriptAvailable !== null
                        && transcriptAvailable
                        && showClearIcon) && <CloseIcon
                        onClick={() => { handleOnClearSearch() }}
                        className="clear-text-icon" fontSize="small" />
                }

            </div>

        </>

    );
});

export default SearchVideo;
