import { useState } from 'react'
import styled from "@emotion/styled"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getVideoTotalTime } from '../../../learner/pages/watch/utils'

export default function PlayableThumbnail({ video }) {
    const [loadVideo, setLoadVideo] = useState(false)
    return (
        <>
            {!loadVideo ?
                <Thumbnail isDefault={video.thumbnail_cdn_url ? false : true} image={video.thumbnail_cdn_url}>
                    <PlayButton onClick={() => setLoadVideo(true)}>
                        <PlayArrowIcon sx={{ color: '#1D3969', fontSize: '30px' }} />
                        <PlayText>Play</PlayText>
                    </PlayButton>
                    <ViewContainer>{video.views ? video.views : 0} View{video.views === 1 || video.views === '1' ? '' : 's'}</ViewContainer>
                    <DurationContainer>{getVideoTotalTime(video.video_details.recording_start, video.video_details.recording_end,)}</DurationContainer>
                </Thumbnail>

                :
                <Video src={video.video_cdn_url} type="video/mp4" autoPlay={true} controls={true} />
            }
        </>
    )
}

const Thumbnail = styled.div`
    width: 100%;
    height: 0;
   padding-top:  56.25%;

    position: relative;
    display: flex;
    background-color: ${props => props.isDefault ? '#000' : 'initial'};
    border-radius: 5px;
    background-image: ${props => props.isDefault ? undefined : `url(${props.image})`};
    background-size: cover;
   border: 1px solid #000000;

`
const Video = styled.video`
    background: #000;
    border-radius: 5px;
    width: 100%;
    height: 100%;
`
const PlayButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 162.7px / 2);
    background-color: #FFF;
    padding: 10px 45px;
border: 1px solid  rgba(0,0,0,.1);

    border-radius: 100vh;
    cursor: pointer;
    box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.2);

`
const PlayText = styled.span`

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #1D3969;
    margin-left: 6px;

`
const DurationContainer = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #000000;
background-color:#FFF;
position: absolute;
bottom: 15px;
right:15px;
padding: 8px;
border: 1px solid  rgba(0,0,0,.1);
border-radius: 3px;
`

const ViewContainer = styled(DurationContainer)`

right: 100px;
min-width: 53px;
text-align: center;

`