import { useContext } from 'react';
import { AppDataContext } from '../../context';

export function useGetUserCohortName(cohortId){

    const {userData}=useContext(AppDataContext);

    let cohortInfo=userData.mk_user_courses.find(i=>i.cohort.id===cohortId);

    return cohortInfo?cohortInfo.cohort:null;
}