import { makeStyles } from "@mui/styles";

const useVideoMetaStyles = makeStyles((theme) => ({

    /** Video Meta Panel */
    videoMetaPanel: {
        backgroundColor: theme.colors.white,
        borderRadius: "0.6em",
        position: "relative",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            height: "100%"
        },
        [theme.breakpoints.down('md')]: {
            height: "600px",
            marginBottom: "20px",
            marginTop: "10px"
        },
    },

    tabDropDownContainer: {
        position: "relative",
        padding: "8px",

        '& .tab-dd-selected-container': {
            padding: "8px",
            backgroundColor: "#E0E0E0",
            fontSize: "14px",
            borderRadius: "5px",
            position: "relative",
            cursor: "pointer",

            '& .tab-dd-down-icon': {
                position: "absolute",
                right: "8px",
                top: "5px"
            }
        },

        '& .tab-dd-selection-container': {
            display: "none",
            position: "absolute",
            top: 8,
            left: 8,
            right: 8,
            padding: "10px",
            border: "1px solid gray",
            borderRadius: "5px",
            backgroundColor: "#E0E0E0",
            transition: 'display 1s',
            zIndex: 1,

            '& .tab-dd-up-icon': {
                position: "absolute",
                right: "8px",
                top: "5px"
            },

            '& .each-tab-selection': {
                padding: "8px 0px",
                fontSize: "14px",
                cursor: "pointer",
                position: "relative",

                '& .each-tab-selection-text': {
                    marginLeft: "30px"
                },

                '& .each-tab-selection-checkicon': {
                    position: "absolute",
                    left: "3px",
                    top: "8px",
                    color: "green"
                }
            }
        }
    },

    tabContainer: {
        padding: "10px",
    },

    searchResultsCountContainer: {
        fontSize: "14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& .search-result-counts,.search-arrow-icons': {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        '& .search-result-counts': {
            columnGap: "10px"
        },
        '& .search-arrow-icons': {
            columnGap: "20px",
            marginRight: "10px"
        }
    },

    transcriptsParentContainer: {
        position: "absolute",
        top: "40px",
        bottom: 0,
        left: 0,
        right: 0,

        "& .transcript-auto-scroll-button": {
            visibility: "hidden",
            position: "absolute",
            bottom: "10px",
            left: "0px",
            right: "0px",
            textAlign: "center",

            '& .MuiButton-root': {
                backgroundColor: "#14606B",
                color: "white",
                textTransform: "capitalize",

                '&:hover': {
                    backgroundColor: "#14606B !important",
                }
            }
        }
    },

    transcriptContainer: {
        overflow: "auto",
        position: "absolute",
        top: "15px",
        bottom: "20px",
        left: 0,
        right: 0,
        paddingLeft: "5px",
        paddingRight: "10px",

        "& .transcript-list": {
            maxHeight: "100%",
        },

        '& .transcript-row-selected': {
            backgroundColor: "#E4EEFF"
        },

        '& .transcript-row-selected-on-arrow-select': {
            backgroundColor: "#E4EEFF"
        },

        '& .highlight-search-box': {
            border: "2px solid #FFB800"
        },

        "& .each-transcript-row": {
            display: "grid",
            gridColumnGap: "normal",
            gridTemplateColumns: "1fr",
            marginTop: "5px",
            fontSize: "14px",
            padding: "8px",
            '&:hover': {
                cursor: "pointer",
                backgroundColor: "#E4EEFF",
            }
        },

    },

    chatContainer: {
        overflow: "auto",
        position: "absolute",
        bottom: "0px",
        top: "48px",
        left: 0,
        right: 0,


        '& .each-chat-row': {
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 12px 10px 12px',
            '&:nth-child(odd)': {
                backgroundColor: "whitesmoke",
            },
            '& .chat-text': {
                marginTop: "5px",

                margin: '0 2px',
                borderRadius: "2px",
                wordBreak: "break-word",
                width: "100%",
                fontSize: '14px',
                fontWeight: 400

            },
            '& .user-name-and-time': {
                // marginRight: "12px",
                flexGrow: 2,
                display: 'flex',
                '& .chat-name': {
                    fontSize: '14px'
                },
                '& .chat-time': {
                    marginLeft: 'auto',
                    fontSize: '12px'
                }
            }
        },

    }

}));

export default useVideoMetaStyles;

