export function filterMembers(allMembers, type) {
    if (!allMembers) return
    switch (type) {
        case 'learners':
            return allMembers.filter(m => m.user_role_id === 1);

        case 'staff':
            return allMembers.filter(m => m.user_role_id !== 1 && m.user_role_id !== 5 && m.user_role_id !== 9)

        case 'coaches':
            return allMembers.filter(m => m.user_role_id === 5)
        case 'admins':
            return allMembers.filter(m => m.user_role_id === 9)
        case 'activeLearners':
            return allMembers.filter(m => m.user_role_id === 1 && m.active)
        case 'activeCoaches':
            return allMembers.filter(m => m.user_role_id === 5 && m.active)
        case 'activeStaff':
            return allMembers.filter(m => m.user_role_id !== 1 && m.user_role_id !== 5 && m.user_role_id !== 9 && m.active)
        case 'sortedActiveStaff':
            return allMembers.filter(m => m.user_role_id !== 1 && m.user_role_id !== 5 && m.user_role_id !== 9 && m.active)
                .sort((a, b) => {
                    const aRole = Number(a.user_role_id)
                    const bRole = Number(b.user_role_id)
                    if (aRole < bRole) return -1
                    if (aRole > bRole) return 1
                    return 0
                })
        case 'activeAdmins':
            return allMembers.filter(m => m.user_role_id === 9 && m.active)

        case 'onlineLearners':
            return allMembers.filter(m => m.user_role_id === 1 && m.active && m.is_online)

        case 'onlineCoaches':
            return allMembers.filter(m => m.user_role_id === 5 && m.active && m.is_online)

        case 'onlineStaff':
            return allMembers.filter(m => m.user_role_id !== 1 && m.user_role_id !== 5 && m.user_role_id !== 9 && m.active && m.is_online)

        case 'onlineAdmins':
            return allMembers.filter(m => m.user_role_id === 9 && m.active && m.is_online)
        default:
            return allMembers
    }
}


export const getActiveCohorts = (program) => {

    return program.program_mappings.reduce((acc, ci) => {
        let exists = acc.find(i => i.id === ci.id);
        if (!exists && ci.is_active === true) {
            acc.push(ci.cohort);
        }
        return acc;
    }, []);
}


export const getInActiveCohorts = (program) => {
    return program.program_mappings.reduce((acc, ci) => {
        let exists = acc.find(i => i.id === ci.id);
        if (!exists && !ci.is_active) {
            acc.push(ci.cohort);
        }
        return acc;
    }, []);
}

export function filterTrueValues(obj) {
    const filteredObj = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value) {
            filteredObj[key] = value;
        }
    }
    return filteredObj;
}