import React from 'react';

import { loadScript } from '../../../helpers/utils';

//Google API key
const GOOGLE_MAPS_API_KEY = "AIzaSyAYgNX3HqJh_F6mOgqjFeMi75wX0lgtaX8";

window.autocompleteService = null;

const useLoadGoogleAPI=(controlId)=>{

    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector('head'),
				'google-maps',
			);
		}
		loaded.current = true;
	}

    if (window.autocompleteService===null && window.google) {
        
        const input = document.getElementById(controlId);

        window.autocompleteService =
            new window.google.maps.places.AutocompleteService(input, {
                componentRestrictions: { country: "us" },
                fields: ["place_id","description","address_components", "geometry", "icon", "name"],
                types: ["locality", "geocode", "political","postal_code"]
            });
    }

    return window.autocompleteService;

    
}

export default useLoadGoogleAPI;