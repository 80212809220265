import styled from "@emotion/styled";
import Modal from '@mui/material/Modal';
import { stripLeadingPunctuation } from '../../../learner/pages/watch/utils';

import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { getEventByGoogleID } from '../../../api';
import { ReactComponent as CollapseIcon } from '../../../icons/video-panel-collapse.svg';
import { TopicPill, TopicPillsContainer } from './EachACPVideoCard';
import HostSearch from "./formComponents/HostSearch";
import TopicPillInput from "./formComponents/TopicPillInput";
import { filterEventsByRecordingDate, formatEventDuration, toggleVideoArchived, updateVideoDetails } from "./helpers";
import PlayableThumbnail from './PlayableThumbnail';
import VisibilityText from './VisibilityText';


import CircularProgress from '@mui/material/CircularProgress';
import { AppDataContext } from "../../../context";
import Skeleton from '@mui/material/Skeleton'; import { useACPDataContextV2 } from "../../context/ACPDataStoreV2";
import ArchivePopup from "./formComponents/ArchivePopup";
export default function EachACPVideoModal({ video, handleClose, isOpen, calendarId }) {
    const [initialFormTags, setInitialFormTags] = useState([])
    const [eventNames, setEventNames] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [formTopics, setFormTopics] = useState([])
    const [showArchivePopup, setShowArchivePopup] = useState(false)
    const { register, setValue, handleSubmit } = useForm();
    const { userData } = useContext(AppDataContext);
    const [isUpdating, setIsUpdating] = useState(false)
    const [displayedVideoData, setDisplayedVideoData] = useState({ ...video })
    const [loadingEvents, setLoadingEvents] = useState(false)
    const { updateSingleVideo } = useACPDataContextV2()
    const controller = new AbortController()
    useEffect(() => {
        setDisplayedVideoData(video)
        setInitialFormTags(video?.tags)
        setFormTopics(video?.tags)
        return () => {

            setIsEditing(false)
            setShowArchivePopup(false)
        }
    }, [video])
    const fetchData = async (controller) => {
        let events = []
        for (let id of displayedVideoData.google_event_ids) {
            const event = await getEventByGoogleID(id, calendarId, controller.signal)
            events.push(...event)
        }

        setEventNames(filterEventsByRecordingDate(displayedVideoData.video_details.recording_end, events))

    }
    useEffect(() => {
        if (isOpen) {
            setLoadingEvents(true)


            if (!displayedVideoData.google_event_ids || !displayedVideoData.google_event_ids.length) return

            fetchData(controller).then(data => setLoadingEvents(false))
        }
    }, [isOpen])

    const handleAddNewTopic = (topic) => {
        if (formTopics?.length)
            setFormTopics([...formTopics, topic])
        else setFormTopics([topic])
    }
    const handleDeleteTopic = (indx) => {
        const newTopics = [...formTopics]
        newTopics.splice(indx, 1)
        setFormTopics(newTopics)
    }
    const handleCancel = () => {
        setFormTopics(initialFormTags)
        setIsEditing(false)
    }
    const onSubmit = async data => {
        setIsUpdating(true)
        const response = await updateVideoDetails({ data: { ...data, tags: formTopics }, userName: `${userData?.first_name || ''} ${userData?.last_name || ''}`, video: displayedVideoData, cancelSignal: controller.signal })
        setIsUpdating(false)
        if (!response) return alert('An error ocurred updating the video.')
        setIsEditing(false)
        setDisplayedVideoData({ ...video, ...response })
        updateSingleVideo({ ...video, ...response })
    }

    const handleArchiveModalClick = async (val) => {
        const didUpdate = await toggleVideoArchived(val, video.uuid, controller.signal)
        if (didUpdate) setDisplayedVideoData({ ...displayedVideoData, archived: val })
    }

    return (

        <Modal
            sx={{ '&:focus': { outline: 'none' } }}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby={`Modal${displayedVideoData.title}`}
            aria-describedby={`Modal${displayedVideoData.title}`}
        >
            <Container  >
                <VisibilityContainer >
                    <VisibilityText video={displayedVideoData} />
                    {isEditing ?
                        <>
                            <ArchiveButton outline archived={displayedVideoData.archived} sx={{ cursor: 'pointer' }} onClick={() => setShowArchivePopup(!showArchivePopup)} >
                                {displayedVideoData.archived ? 'Make Public' : 'Archive Video'}
                            </ArchiveButton>
                            <ArchivePopup isShown={showArchivePopup} handleClose={() => setShowArchivePopup(false)} isArchived={displayedVideoData.archived} handleClick={handleArchiveModalClick} />

                        </>
                        : null}

                </VisibilityContainer>
                <Collapse onClick={handleClose} />

                <ContentContainer>
                    <ContentBlock>
                        <VideoURLText>{`${window.location.origin}/media/${video.uuid}`}</VideoURLText>
                        <TagInfoText as={'p'} style={{ textAlign: 'right', marginBottom: 0 }}>*learner-facing link, cannot be modified</TagInfoText>

                    </ContentBlock>
                    <ContentBlock>
                        <ContentHeading>
                            Video Title
                        </ContentHeading>

                        {!isEditing ?
                            <VideoTitle>
                                {stripLeadingPunctuation(displayedVideoData.title) || '[Missing Title]'}
                            </VideoTitle>
                            :
                            <StyledTextInput {...register('title', { required: true })} defaultValue={stripLeadingPunctuation(displayedVideoData.title) || '[Missing Title]'} />
                        }
                    </ContentBlock>

                    <ContentBlock>
                        <ContentHeading>
                            Host Information
                        </ContentHeading>
                        {!isEditing ?
                            <ContentText>
                                {displayedVideoData?.host ? displayedVideoData.host : displayedVideoData?.source_details?.meeting_host_email ? displayedVideoData?.source_details?.meeting_host_email : '[Missing Host]'}
                            </ContentText>
                            :
                            <HostSearch inputProps={{ ...register('host'), defaultValue: displayedVideoData?.host || displayedVideoData?.source_details?.meeting_host_email }} handleSelect={(item) => setValue('host', `${item.username}`)} />
                        }
                    </ContentBlock>

                    <ContentBlock>
                        <ContentHeading>
                            Associated Topics & Tags
                        </ContentHeading>
                        {!isEditing ?
                            <TopicPillsContainer>
                                {formTopics?.length ?
                                    <>
                                        {formTopics?.slice(0, 3).map(topic => (
                                            <TopicPill key={topic}>{topic}</TopicPill>
                                        ))}
                                    </>
                                    :
                                    <ContentText>{'[Missing Tags]'}</ContentText>
                                }
                            </TopicPillsContainer>
                            :
                            <TopicPillInput topics={formTopics} handleAddNewTopic={handleAddNewTopic} handleDeleteTopic={handleDeleteTopic} />
                        }
                    </ContentBlock>
                    <ContentBlock>



                        {formTopics?.length > 3 ?
                            <TopicPillsContainer>
                                {formTopics.map((topic, i) => {
                                    if (i < 3) return null
                                    return (

                                        <TopicPill key={i}>{topic}
                                            {isEditing &&
                                                <HighlightOffIcon
                                                    sx={{ fontSize: '14px', marginLeft: '2px', marginTop: '2px', cursor: 'pointer' }}
                                                    onClick={() => handleDeleteTopic(i)}
                                                />}</TopicPill>
                                    )
                                })}
                            </TopicPillsContainer>
                            :
                            null
                        }
                        {isEditing &&

                            <TagInfoText>*First three entries become displayed Topics, additional entries become hidden Tags that are still searchable</TagInfoText>
                        }
                    </ContentBlock>
                    <DescriptionContentBlock isEditing>
                        <ContentHeading>
                            Description
                        </ContentHeading>

                        {!isEditing ?
                            <ContentText>
                                {displayedVideoData.description || '[Missing Description]'}
                            </ContentText>
                            :
                            <StyledTextArea  {...register('description')} defaultValue={displayedVideoData.description} />
                        }

                    </DescriptionContentBlock>

                    <EventContentBlock isEditing={isEditing} >

                        <ContentHeading>
                            Associated Calendar Event (if Applicable)
                        </ContentHeading>

                        {loadingEvents ?
                            <>
                                <Skeleton animation='wave' width='50%' />
                                <Skeleton animation='wave' width='50%' />
                            </>
                            :

                            (displayedVideoData.google_event_ids && eventNames.length) ?
                                eventNames.map((event, i) => (
                                    <React.Fragment key={i}>
                                        <ContentText>
                                            {event.summary}
                                        </ContentText>
                                        <ContentText>
                                            {formatEventDuration(event.start_date_time, event.end_date_time)}
                                        </ContentText>
                                    </React.Fragment>
                                ))

                                :
                                <ContentText>{'[No Associated Event]'}  </ContentText>}


                    </EventContentBlock>


                </ContentContainer>

                <RightSide>
                    <VideoDataContainer>
                        <ThumbnailContainer>
                            <PlayableThumbnail video={displayedVideoData} />
                        </ThumbnailContainer>


                        <MetaDataHeading>Metadata</MetaDataHeading>
                        <MetaDataRow>
                            <MetaDataText>Video File</MetaDataText>
                            <MetaDataText className="truncate">{displayedVideoData.video_cdn_url || 'Not Available'}</MetaDataText>

                        </MetaDataRow>
                        <MetaDataRow>
                            <MetaDataText>Transcript File</MetaDataText>
                            <MetaDataText className="truncate">{displayedVideoData.transcripts_cdn_url && displayedVideoData.transcripts_cdn_url !== 'null' ? displayedVideoData.transcripts_cdn_url : 'Not Available'}</MetaDataText>
                        </MetaDataRow>

                        <MetaDataRow>
                            <MetaDataText>Chat File</MetaDataText>
                            <MetaDataText className="truncate">{displayedVideoData.chat_cdn_url && displayedVideoData.chat_cdn_url !== 'null' ? displayedVideoData.chat_cdn_url : 'Not Available'}</MetaDataText>
                        </MetaDataRow>

                    </VideoDataContainer>
                    {!isEditing ?
                        <EditButton onClick={() => setIsEditing(true)}>
                            <EditIcon sx={{ marginRight: '6px', fontSize: '16px' }} />
                            Edit Entry
                        </EditButton>
                        :
                        <ButtonRow>
                            {isUpdating ? <CircularProgress />
                                : (
                                    <>
                                        <StyledButton outline onClick={handleCancel}>Cancel</StyledButton>
                                        <SaveButton onClick={handleSubmit(onSubmit)}><SaveIcon sx={{ marginRight: '6px', fontSize: '16px' }} /> Save Changes</SaveButton>
                                    </>
                                )
                            }
                        </ButtonRow>
                    }
                </RightSide>
                <HistoryContainer>

                    <HistoryTitle>History</HistoryTitle>
                    <HistoryUpdatesGrid>

                        {displayedVideoData?.updates?.map((update, i) => (
                            <HistoryBlock source={update.updated_by} date={update.updated_date} changes={update.changes} key={i} />
                        ))}

                        <HistoryBlock source={displayedVideoData.created_by || 'MyKenzie API'} date={displayedVideoData.created_date || displayedVideoData.video_details.recording_end} isUpdate={false} />
                    </HistoryUpdatesGrid>
                </HistoryContainer>
            </Container>
        </Modal >
    )
}

const HistoryBlock = ({ source = 'MyKenzie API', date, changes = [], isUpdate = true }) => (
    <HistoryBlockContainer>
        <HistoryHeading>
            {source}
            <span className='date'>{moment(date).tz('America/New_York').format('MMM DD, YYYY | h:mma') + ' EST'}</span>
        </HistoryHeading>
        <HistoryText>• {isUpdate ? 'Updated' : 'Video Entry Created'} <span style={{ fontWeight: 600 }}>{changes.map((change, i) => <span key={i}>{change}{i !== changes.length - 1 ? ', ' : ''}</span>)}</span></HistoryText>
    </HistoryBlockContainer>
)



const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    /* height: 90vh; */
    min-height: 915px;
    max-width: 1600px;
    max-height: 99vh;
    background-color: #FFF;
    border: 1px solid #828282;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px min-max(4fr,800px)  1fr;
    grid-template-columns: 50% 50%;
    grid-template-areas:
    "vis col" 
    "con vid" 
    "his his";
    /* grid-column-gap: 67px; */
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    outline: none;

`

const ContentContainer = styled.div`
    box-sizing: border-box;
    grid-area: con;
    display: flex;
    flex-direction: column;
    margin: 20px 0 48px 27px;
    margin-right: 67px;
`
const VideoDataContainer = styled.div`
    box-sizing: border-box;
    grid-area: vid;
    display: flex;
    flex-direction: column;
    padding-right: 25px;
    padding-bottom: 25px;
`
const VisibilityContainer = styled.div`
    display: flex;
    align-items: center;
    grid-area: vis;
    margin-left: 25px;
    margin-top: 25px;
    position: relative;
`
const Collapse = styled(CollapseIcon)`
    grid-area: col;
    margin: auto 28px auto auto;
    cursor: pointer;
`
const ContentBlock = styled.div`
    margin-bottom: 20px;

`
const VideoURLText = styled.p`
    width: 100%;
    background: #E4EEFF;
    border-radius: 5px;
    margin: 0;
    padding: 3px 15px;
`
const TagInfoText = styled.span`
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-top: 10px;
`
const DescriptionContentBlock = styled(ContentBlock)`
    max-height: 370px;
    overflow-y: auto;
    overflow-y: hidden;
    box-sizing: border-box;
`

const EventContentBlock = styled(ContentBlock)`
    max-height: ${props => props.isEditing ? '175px' : '150px'};
    overflow-y: auto;
`
const ContentHeading = styled.p`
    margin: 0;
    margin-bottom: 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #1D3969;
`
const ThumbnailContainer = styled.div`
box-sizing: border-box;
   height: 100%;
`
const VideoTitle = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
`
const ContentText = styled.p`
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 158.9%;
    color: #000000;
`
const MetaDataHeading = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #0D0D0D;
    margin-top: 25px;
`
const MetaDataRow = styled.div`
    box-sizing: border-box;
    width: 100%;
    background: #E9E9E9;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    padding: 8px 10px ;
    &:nth-of-type(3){
    background: #FFFFFF;

    }
`
const MetaDataText = styled.span`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    margin-right: 5px;

    &.truncate{
    margin-left: auto;
    margin-right: 0;

    }   
`

const HistoryBlockContainer = styled.div`
    margin-top : 15px;
    margin-right: 15px;
    box-sizing: border-box;
    width: 25%;
`
const HistoryContainer = styled.div`
    box-sizing: border-box;

    grid-area:his;
    background-color: #E4EEFF;
    padding: 20px 28px;
    overflow-y: auto;

`
const HistoryUpdatesGrid = styled.div`
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    max-height: 300px;

`
const HistoryTitle = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 158.9%;
    color: #1D3969;
    margin-bottom: 15px;
`
const HistoryHeading = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    & .date{
        margin-left: 10px;
        font-weight: 600;

    }
`

const HistoryText = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 158.9%;

    margin: 8px 0 0 5px;
`
const RightSide = styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction: column;
`
const StyledButton = styled.button`
    cursor: pointer;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: ${props => props.outline ? '#1D3969' : '#FFFFFF'};
    box-sizing: border-box;
    padding: 9px 22px;
    border: ${props => props.outline ? 'border: 1.5px solid #1D3969' : null};
    background: ${props => props.outline ? "#FFF" : '#1D3969'} ;
    border-radius: 100px;

    display: flex;
    align-items: center;

`
const EditButton = styled(StyledButton)`
    margin-left: auto;
    margin-right: 27px;
    margin-top: auto;
    margin-bottom: 35px;
`
const SaveButton = styled(StyledButton)`
    margin-left: 15px;
`
const ArchiveButton = styled(StyledButton)`
--color: #414141;
margin-left: 15px;
color: var(--color);
border-color: var(--color);
`
const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 27px;
    margin-top: auto;
    margin-bottom: 35px;

`

export const StyledTextInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #767676;
    border-radius: 5px;
    padding: 5px 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
`
const StyledTextArea = styled.textarea`
    width: 100%;
    height: 185px;

    border: 1px solid #767676;
    border-radius: 5px;
    padding: 5px 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    resize: none;
    box-sizing: border-box;
    overflow-y: auto;
`

