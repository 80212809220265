import React from 'react';
import {v4 as uuid4} from 'uuid';
import { useStyles } from './styles';

export default function EachRequestHeaderCourseTags({
    request
}){

    const classes=useStyles();

    const getCourseSectionInfo=()=>{
        let tags=[];
        if(request.section){
            tags.push(request.section.submodule_name)
            tags.push(request.section.section_name)
        }
        return tags;
    }
   
    return(
        <div className={`request-header-course-tag ${classes.requestHeader} ${classes.requestCourseTags}`}>
            {
                getCourseSectionInfo().map(tag=>{
                    return <div key={uuid4()} className='each-request-course-tag'>
                        <span>{tag}</span>
                    </div>
                })
            }
        </div>
    )
}