import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as CCIcon } from '../../../../icons/cc-icon.svg';
import { ReactComponent as NewBadgeIcon } from '../../../../icons/new_badge.svg';
import DefaultThumbnail from '../DefaultThumbnail';
import useStyles from "../styles";
import TopicPillsSection from '../TopicPillsSection';
import { getVideoTotalTime, stripLeadingPunctuation } from "../utils";

import moment from 'moment';
export default function PanelRecordingCard({ video }) {
    const styles = useStyles()
    const daysAgo = moment().diff(moment(video?.video_details?.recording_start), 'days')

    return (
        <Link to={`/media/${video.uuid}`} style={{ textDecoration: 'none', color: 'initial' }} className={`${styles.panelRecordingCard}`} >
            <div className='text-content'>


                <p className="title">{stripLeadingPunctuation(video?.title || "")}</p>
                <p className="description">{video.description || ''}</p>
                <div className='bottom-row'>

                    <div className={`${styles.durationContainer} panel`}>
                        {getVideoTotalTime(video.video_details?.recording_start, video.video_details?.recording_end,)}
                    </div>
                    <div className={`${styles.visIcon} panel`}>
                        {video.transcripts_cdn_url && video.transcripts_cdn_url !== 'null' ?
                            <Tooltip title='Closed Captions Available'>
                                <CCIcon style={{ width: '40px', height: '24px' }} />
                            </Tooltip>
                            :
                            null

                        }

                    </div>
                    <TopicPillsSection video={video} isCalendarPanel={true} />

                </div>
            </div>
            <div className='thumbnail-container'>
                {(video?.thumbnail_cdn_url && video.thumbnail_cdn_url.endsWith('.png')) ?
                    <img src={video?.thumbnail_cdn_url} className={styles.thumbnail} alt={`video thumbnail for ${stripLeadingPunctuation(video?.title || "")}`} />
                    :
                    <DefaultThumbnail title={stripLeadingPunctuation(video?.title || "")} />
                }
                {daysAgo < 4 && (
                    <NewBadgeIcon className='new-badge' />
                )}
            </div>
        </Link>
    )
}