//  ADMIN COHORT MANAGEMENT PAGE
import axios from 'axios';
import { getApiBase, HEADERS } from '../../../../api/helpers';
import moment from 'moment';

// 
async function getAllMembersByCohortId(cohortId = NaN) {


    try {

        const ATTR = [
            "id",
            "cohort_name",
            "users/active",
            "users/user_role_id",
            "users/cohort_id",
            "users/course_id",
            "users/id",
            "users/user_email",
            "users/is_visible",
            "userdetails/user_lms_id",
            "userdetails/email",
            "userdetails/username",
            "userdetails/first_name",
            "userdetails/last_name",
            "usercourse/id"

        ]

        let url = `${getApiBase()}/v1/cohorts`;

        let { data } = await axios.get(url, {
            params: {
                "id": cohortId,
                "expand": 'users,userdetails,usercourse',
                "attributes": ATTR.join(','),

            },
            headers: HEADERS
        });


        return data.message?.cohorts && data.message.cohorts.length > 0 ? data.message.cohorts[0].mk_user_courses : [];

    } catch (error) {
        console.error(error);

    }
}

async function updateCohortMember({ mkUserFields, mkUserCourseFields, data, cohortId, member, rowId, username }) {

    const { email } = member
    const payloads = { mkUsers: {}, mkUserCourse: {} }
    let url;
    try {

        if (mkUserCourseFields.length) {
            if (mkUserCourseFields.includes('active') && data.active === true) {
                url = `${getApiBase()}/usercourse?id=${rowId}`;

            }

            else {
                url = `${getApiBase()}/usercourse?user_email=${email}&cohort_id=${cohortId}`;

            }
            //if active true, update only one
            // if(data.active)


            mkUserCourseFields.forEach(field => {
                payloads.mkUserCourse[field] = data[field]
            })

            await axios.put(url,
                {

                    ...payloads.mkUserCourse,
                    updated_by: username,
                    updated_date: moment(new Date()).format('YYYY-MM-DD')
                },
                {
                    headers: HEADERS
                });



        }
        if (mkUserFields.length) {


            let url = `${getApiBase()}/user/${email}`;

            mkUserFields.forEach(field => {
                payloads.mkUsers[field] = data[field]
            })

            await axios.put(url,
                payloads.mkUsers,
                { headers: HEADERS }
            );

        }

        return { didUpdate: true, message: `Successfully Updated ${mkUserFields.length + mkUserCourseFields.length} properties ` }


    } catch (error) {
        console.error(error);
        return { didUpdate: false, message: 'An Error occurred while updating the cohort member. If this problem persists, please contact the support team.' }

    }
}
async function addNewMKUser(member, username) {
    const { email, first_name, last_name, user_role_id, user_lms_id } = member
    try {
        let url = `${getApiBase()}/v1/user/`;
        const payload = {
            username: email,
            email,
            first_name,
            last_name,
            user_lms_id,
            status: true,
            is_online: false,
            first_login: true,
            user_role: user_role_id === "1" ? 'student' : null,
            subscribed_canvas: true,
            created_by: username

        }
        const response = await axios.post(url, payload, { headers: HEADERS })

        if (response.status === 200) {
            return { didAdd: true, message: 'Added User to MK User Table' }
        }
        else {
            return { didAdd: false, message: `There was an error adding ${email} To the Users Table.` }
        }
    } catch (error) {
        console.warn(error)
        return { didAdd: false, message: `An error occurred for user ${email}. LMS ID ${user_lms_id} is already in use.` }

    }
}

async function addNewMKUserCourse(member, cohortId, username) {
    const { email, active, user_role_id, is_visible } = member
    try {
        let url = `${getApiBase()}/usercourse`;
        const payload = {
            user_email: email,
            user_role_id,
            cohort_id: cohortId,
            is_visible,
            active,
            created_by: username

        }
        const response = await axios.post(url, payload, { headers: HEADERS })
        if (response.status === 200) {
            return { didAdd: true, message: 'Added User to MK Course Table' }
        }
        else {
            return { didAdd: false, message: "There was an error adding the User To the cohort" }
        }

    } catch (error) {
        console.warn(error)
        return { didAdd: false, message: "There was an error adding the User To the cohort" }
    }
}
async function getMKUserDetails(email) {
    try {

        let url = `${getApiBase()}/v1/users/?username=${email}`;
        const response = await axios.get(url, { headers: HEADERS })

        return response?.data?.message?.users
    } catch (e) {
        console.error(e)
    }
}
async function getMKUserCourseDetails(email, cohortId) {
    try {
        let url = `${getApiBase()}/usercourse?user_email=${email}&cohort_id=${cohortId}`;
        const response = await axios.get(url, { headers: HEADERS })

        return response?.data?.message?.userCourse
    } catch (e) {
        console.error(e)
    }
}
async function addNewMemberToCohort({ member, cohortId, username }) {
    //check if in cohort in mkusercourse
    if (typeof member.active === 'string') {
        member.active = member.active === 'true' ? true : member.active === 'false' ? false : true
    }
    const userCohortDetails = await getMKUserCourseDetails(member.email, cohortId)
    if (userCohortDetails.length) {
        return { didAdd: false, message: `User ${member.email} already exists in this cohort.` }
    }
    const userDetails = await getMKUserDetails(member.email)

    if (!userDetails.length) {
        const res = await (addNewMKUser(member, username))
        if (!res.didAdd)
            return { didAdd: false, message: res.message }
    }

    await addNewMKUserCourse(member, cohortId, username)
    return { didAdd: true, message: `Successfully Added User ${member.email}.`, member }


}

async function addAllNewMembersToCohort({ newMembers, cohortId, username }) {
    let responses = []
    for (let member of newMembers) {
        const res = await addNewMemberToCohort({ member, cohortId, username })
        responses.push(res)
    }
    const didNotAdd = responses.filter(response => !response.didAdd)
    const didAdd = responses.filter(response => response.didAdd)
    return { didAdd, didNotAdd }
}

export {
    getAllMembersByCohortId,
    updateCohortMember,
    addAllNewMembersToCohort,
    addNewMemberToCohort
};

