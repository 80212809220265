import React from 'react';

import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { v4 as uuid4 } from 'uuid';

import useEditProfileStyles from '../../../sharedComponents/editProfileFields/styles/editProfileStyles';
import useWorkLifeStyles from './workLifeStyle';

const WORK_SCHEDULES = require('../../../sharedComponents/editProfileFields/workSchedule/workSchedules.json');

const LearnerWorkSchedule = ({ isEditProfilePage }) => {

    const {
        values,
        setFieldValue
    } = useFormikContext();

    const editProfileClasses = useEditProfileStyles();
    const workLifeStyles = useWorkLifeStyles();

    const handleOnClick = (schedule) => {

        let d = values.user_work_schedule ? values.user_work_schedule : []

        if (d.includes(schedule.id)) {
            d.splice(d.indexOf(schedule.id), 1)

        } else {
            d.push(schedule.id);
        }

        setFieldValue('user_work_schedule', d);
    }

    const checkIfExists = (schedule) => {
        return values.user_work_schedule && values.user_work_schedule.includes(schedule.id)
    }

    return (
        <div className={!isEditProfilePage ? editProfileClasses.formSection : undefined}>
            <Grid className={editProfileClasses.control} item xs={12} md={12}>

                <p className={isEditProfilePage ? editProfileClasses.focusTimeText : `text-lg ${editProfileClasses.formSectionHeader}`}>
                    Work Schedule {isEditProfilePage && <span className='note-text'>*Select all that apply</span>}
                </p>
                {!isEditProfilePage &&
                    <span className='mt-2 text-sm'>
                        What is your typical weekly work schedule? (Choose all that apply)
                    </span>
                }

                <div className={`mt-3 ${workLifeStyles.workScheduleContainer}`}>
                    {
                        WORK_SCHEDULES.map(schedule => {
                            return <div
                                key={uuid4()}
                                className={`each-work-schedule-button open-sans ${checkIfExists(schedule) ? 'each-work-schedule-button-selected' : ''}`}
                                onClick={() => {
                                    handleOnClick(schedule);
                                }}> {schedule.label} </div>
                        })
                    }
                </div>

            </Grid>
        </div>
    )

}

export default LearnerWorkSchedule;