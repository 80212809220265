import CloseIcon from '@mui/icons-material/Close';
import { Grid, MenuItem, Select, styled, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { useFormikContext } from 'formik';

import socialmedia from '../../../../sharedComponents/editProfileFields/lists/socialmedia.json';

import { checkIfValidLink } from "../../../../helpers/utils";

const StyledSelect = styled(Select)((props) => ({
    minWidth: "135px",
    height: "46px",
    ...props.theme.selectFont,
    backgroundColor: "white"
}))


const StyledTextField = styled(TextField)((props) => ({
    height: "43px",
    width: "500px",
    "& .MuiInput-root": {
        ...props.theme.selectTextFont,
        color: "#14606B",
        borderBottom: props.error ? "2px solid #d32f2f !important" : "2px solid #14606B !important",
        marginTop: "-5px",
    },
    "& .MuiFormHelperText-root": {
        fontFamily: "Nunito",
        fontWight: 500,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#d32f2f"
    }
}))

const EachSocialMediaLinks = ({
    id,
    isValid,
    setValid,
    controlId,
    storedValue = null,
    removeTotalLinks,
    isEditProfilePage,
    isOnboarding
}) => {
    let {
        values,
        setFieldValue
    } = useFormikContext();

    useEffect(() => {
        if (isValid === undefined) {
            setValid(id, (storedValue && storedValue.link) ? checkIfValidLink(storedValue.link) : true)
        }

    }, [])

    let selectedMediaInfo = useRef(storedValue);

    // ;
    const [showDeleteBtn, setShowDeleteBtn] = useState(selectedMediaInfo.current.newItem ? false : true);

    const theme = useTheme();
    //Checks if the screen size is small or mobile screen and renders the menu accordingly 
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    //This is for Backward Compatibility. If we remove this clause, user will see the url with no social media type selected
    //In case someone added a slack link before requirements got changed to not have slack link in the drop down option.
    //This clause will just return null if its slack link added by user previously
    if (storedValue.name === "Slack") { return null };

    //;
    const updateSocialMediaLinks = (key, value) => {

        try {

            //Check if link is entered and is valid link 
            //If not propogate the error to parent component 
            if (value === "" && !isValid) {
                setValid(id, true)
            } else if (key === "link" && !checkIfValidLink(value) && isValid !== false) {
                setValid(id, false)
            } else if (key === "link" && checkIfValidLink(value) && isValid !== true) {
                setValid(id, true);
            }

            //Deleting the newItem, when user selected or enters the url. 
            //NewItem prop is used to show and hide the delete button. 
            //Delete button is hidden id its newItem and if there is no entry done
            if ('newItem' in selectedMediaInfo.current) { delete selectedMediaInfo.current.newItem };

            setShowDeleteBtn(true);//Show Delete button

            let d = values.user_social_media_links ? [...values.user_social_media_links] : [];
            let indx = d.findIndex(i => i.id === selectedMediaInfo.current.id);

            if (indx > -1) {
                d[indx][key] = value;
            } else {
                selectedMediaInfo.current[key] = value;
                d.push({ ...selectedMediaInfo.current });
            }

            setFieldValue(controlId, d);

        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Removes the social media link from 
     */
    const removeSocialMediaLink = () => {

        let d = [...values.user_social_media_links];
        let indx = d.findIndex(i => i.id === selectedMediaInfo.current.id);

        d.splice(indx, 1);
        removeTotalLinks(id)
        setFieldValue(controlId, d);
    }

    return (<Grid container spacing={3}
        sx={{
            // marginTop: "10px",
            // alignItems: "flex-end",
            // width: "100vw",
            justifyContent: isOnboarding && !isSmallScreen ? "center" : "flex-start",
            // position: "relative"
            marginTop: "10px",
            alignItems: "center"
        }}>
        <Grid item xs={11} md={2} lg={2}>
            <StyledSelect
                sx={{
                    width: "100% !important",
                    fontFamily: "Raleway !important"
                }}
                value={storedValue !== null && storedValue?.name ? storedValue.name : 0}
                placeholder="Social Media"
                onChange={(e) => {
                    selectedMediaInfo.current.name = e.target.value;
                    updateSocialMediaLinks("name", e.target.value)
                }}
                variant={isEditProfilePage ? 'standard' : "outlined"}
            >
                <MenuItem value={0} disabled>Select One</MenuItem>
                {
                    socialmedia.map((s) => {
                        return <MenuItem
                            key={uuid4()}
                            value={s}>{s}</MenuItem>
                    })
                }
            </StyledSelect>
        </Grid>
        {
            isSmallScreen && showDeleteBtn ?
                <Grid item xs={1}>
                    <div style={{
                        // cursor: "pointer", position: "relative", top: 3
                    }} onClick={() => {
                        removeSocialMediaLink()
                    }}>
                        <CloseIcon fontSize='18' />
                    </div>
                </Grid> :
                null
        }
        <Grid item xs={12} md={7} lg={7}>
            <StyledTextField
                error={isValid !== undefined && !isValid}
                sx={{
                    width: "100% !important",
                    fontFamily: "Raleway !important",
                    fontSize: "14px !important",
                    marginLeft: isOnboarding && !isSmallScreen ? "24px" : 0
                }}
                onChange={(e) => {
                    selectedMediaInfo.current.link = e.target.value;
                    updateSocialMediaLinks("link", e.target.value)
                }}
                onBlur={(e) => {
                    selectedMediaInfo.current.link = e.target.value;
                    updateSocialMediaLinks("link", e.target.value);
                }}
                value={storedValue !== null && storedValue?.link ? storedValue.link : ""}
                disabled={!(storedValue && storedValue.name)}
                helperText={(isValid !== undefined && !isValid) && "Please enter valid url. Starting with http:// or https://."}
                placeholder="https://linkedin.com/username"
                variant="standard"
                InputProps={{ disableUnderline: true }} />
        </Grid>

        {
            !isSmallScreen && showDeleteBtn ?
                <Grid item xs={1}>
                    <div style={{
                        cursor: "pointer",
                    }} onClick={() => {
                        removeSocialMediaLink()
                    }}>
                        <CloseIcon fontSize='18' />
                    </div>
                </Grid> :
                null
        }

    </Grid >)
}

export default EachSocialMediaLinks;