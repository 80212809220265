import React from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    wrapper: {
        
    },
    title: {
        fontFamily: "Nunito",
        fontWeight: "600",
        fontSize: "40px",
        height: "54px",
        lineHeight: "54px",
        color: "#14606B",
        margin: "0px 0px 5px 0px",
        [theme.breakpoints.down("700")]: {
            fontSize: "28px",
            lineHeight: "30px",
            height: "30px"
        }
    },
    subtitle: {
        fontFamily: "Nunito",
        fontWeight: "500",
        fontSize: "30px",
        height: "40px",
        lineHeight: "40px",
        margin: "0px 0px 20px",
        color: "#414141",
        [theme.breakpoints.down("700")]: {
            fontSize: "25px",
            lineHeight: "30px",
            marginBottom: "50px"
        }
    },
    text: {
        textAlign: "center",
        fontFamily: "Nunito",
        fontWeight: "400 !important",
        fontSize: "24px",
        lineHeight: "32px",
        margin: "0px",
        color: "#414141",
        [theme.breakpoints.down("700")]: {
            fontSize: "20px",
            lineHeight: "25px"
        }
    },
    textWrap: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}))

const Welcome = () => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <h1 className={classes.title}>Welcome to MyKenzie!</h1>
            <h3 className={classes.subtitle}>Your hub for all things Kenzie Academy</h3>
            <div className={classes.textWrap}>
                <span className={classes.text}>One huge benefit of your Kenzie program is starting or growing your professional network.</span>
                <br />
                <span className={classes.text} style={{marginBottom: "50px"}}>Establishing new connections starts with a solid personal profile.</span>
                <br />
                <span className={classes.text}>Let’s begin by getting to know you a bit better…</span>
            </div>
        </div>
    )
}

export default Welcome