import { Grid } from "@mui/material"
import React from "react"
import useEditProfileStyles from "../../../sharedComponents/editProfileFields/styles/editProfileStyles"
import { AdditionalSpokenLanguages, PrimarySpokenLanguagesField } from "./components"
const Languages = ({ isEditPage = false }) => {
    const classes = useEditProfileStyles()
    return (
        <Grid container direction="column" spacing={0} rowSpacing={5} wrap="nowrap">
            <Grid item xs={5} style={{ display: "flex", flexDirection: "column", alignItems: !isEditPage && "center" }}>
                <p className={isEditPage ? classes.growingInputText :undefined}>I mainly speak:</p>
                <div style={{ width: "100%", maxWidth: "730px" }}>
                    <PrimarySpokenLanguagesField isEditProfilePage={isEditPage} controlName="user_primary_spoken_language" controlId="user_primary_spoken_language" />
                </div>
            </Grid>
            <Grid item xs={5} style={{ display: "flex", flexDirection: "column", alignItems: !isEditPage && "center", paddingTop: isEditPage && '20px' }}>
                <p className={isEditPage ?  classes.growingInputText : undefined}>{isEditPage ? 'I can also speak:' : 'I also speak the following languages:'}</p>
                <div style={{ width: "100%", maxWidth: "730px" }}>
                    <AdditionalSpokenLanguages isEditProfilePage={isEditPage} controlName="user_additional_spoken_languages" controlId="user_additional_spoken_languages" />
                </div>
            </Grid>
        </Grid>
    )
}

export default Languages