import React from "react"
import { makeStyles } from "@mui/styles"

import {ReactComponent as IdentityIcon} from "../assets/identity_icon.svg"
import {ReactComponent as PictureIcon} from "../assets/avatar_icon.svg"
import {ReactComponent as LocationIcon} from "../assets/location_icon.svg"
import {ReactComponent as LanguagesIcon} from "../assets/spoken_language_icon.svg"
import {ReactComponent as ExperienceIcon} from "../assets/experience_icon.svg"
import {ReactComponent as PersonalIcon} from "../assets/personal_info_icon.svg"
import {ReactComponent as FocusIcon} from "../assets/focus_time_icon.svg"
import {ReactComponent as WorkScheduleIcon} from "../assets/work_schedule_icon.svg"
import {ReactComponent as SocialMediaIcon} from "../assets/social_media_icon.svg"

const labels = [
    "Identity",
    "Picture",
    "Location",
    "Spoken Languages",
    "Experience",
    "Personal Info",
    "Focus Time",
    "Work Schedule",
    "Social Media"
]

const useStyles = makeStyles((theme) => ({
    navigation: {
        height: "45px",
        marginTop: "35px",
        display: "flex",
        width: "424px",
        [theme.breakpoints.down("600")]: {
            width: "375px"
        },
        [theme.breakpoints.down("500")]: {
            marginTop: "15px"
        },
        [theme.breakpoints.down("400")]: {
            width: "300px"
        },
        justifyContent: "space-between",
        alignItems: "center"
    },
    icon: {
        width: "30px",
        height: "30px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.down("600")]: {
            width: "25px",
            height: "25px",
        },
        [theme.breakpoints.down("400")]: {
            width: "20px",
            height: "20px",
        }
    },
    label: {
        position: "absolute",
        top: 50,
        [theme.breakpoints.down("600")]: {
            top: 38
        },
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#1D3969',
        whiteSpace: "nowrap",
        [theme.breakpoints.down("400")]: {
            fontSize: "12px",
            top: 30
        }
    },
    pastIcon: {
        backgroundColor: "#1D3969",
        borderColor: "#1D3969"
    },
    futureIcon: {
        backgroundColor: "#FFFFFF",
        borderColor: "#686868"
    },
    currentIcon: {
        backgroundColor: "#FFFFFF",
        borderColor: "#1D3969",
        borderWidth: "2.5px",
        width: "42px",
        height: "42px",
        [theme.breakpoints.down("600")]: {
            width: "35px",
            height: "35px",
        },
        [theme.breakpoints.down("400")]: {
            width: "30px",
            height: "30px",
        }
    }
}))

export const Navigation = (props) => {
    const classes = useStyles()

    const getClasses = (index) => {
        return index === props.index ? `${classes.icon} ${classes.currentIcon}` :
            index < props.index ? `${classes.icon} ${classes.pastIcon}` :
            `${classes.icon} : ${classes.futureIcon}`
    }

    const getProps = (index) => {
        let color;
        if (index < props.index) {
            color = index !== 8 ? {fill: "#FFFFFF"} : {fill: "#1D3969", stroke: "#FFFFFF"}
        } else if (index === props.index) {
            color = index !== 8 ? {fill: "#1D3969"} : {fill: "#FFFFFF", stroke: "#1D3969"}
        } else {
            color = index !== 8 ? {fill: "#686868"} : {fill: "#FFFFFF", stroke: "#686868"}
        }
        return {...color, width: "70%", height: "70%"}
    }

    const getLabel = (index) => {
        if(index === props.index) {
            return <span className={classes.label}>{labels[index]}</span>
        }
    }

    return(
        <div className={classes.navigation}>
            <div className={getClasses(0)}>
                <IdentityIcon {...getProps(0)} />
                {getLabel(0)}
            </div>
            <div className={getClasses(1)}>
                <PictureIcon {...getProps(1)} />
                {getLabel(1)}
            </div>
            <div className={getClasses(2)}>
                <LocationIcon {...getProps(2)} />
                {getLabel(2)}
            </div>
            <div className={getClasses(3)}>
                <LanguagesIcon {...getProps(3)} />
                {getLabel(3)}
            </div>
            <div className={getClasses(4)}>
                <ExperienceIcon {...getProps(4)} />
                {getLabel(4)}
            </div>
            <div className={getClasses(5)}>
                <PersonalIcon {...getProps(5)} />
                {getLabel(5)}
            </div>
            <div className={getClasses(6)}>
                <FocusIcon {...getProps(6)} />
                {getLabel(6)}
            </div>
            <div className={getClasses(7)}>
                <WorkScheduleIcon {...getProps(7)} />
                {getLabel(7)}
            </div>
            <div className={getClasses(8)}>
                <SocialMediaIcon {...getProps(8)} />
                {getLabel(8)}
            </div>
        </div>
    )
}