import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import EachDayEvents from './EachDayEvents';
import { useStyles } from './styles';

export default function EventTabContainer({
    tabName,
    eventTabDateArray
}) {

    const classes=useStyles();

    const [expandAll, setExpandAll] = useState(null);
    const [expandCollapseButtonText,setExpandCollapseButtonText]=useState("Expand All");

    let expandedOrCollapsedRef=useRef(new Array(eventTabDateArray.length).fill(""));

    //This logic helps in setting the button text and click event
    //Per requirement, if all the child event container is collapsed, the button must change to expand all automatically
    //If all the child events are expanded, the button must be changed to collapsed
    const handleExpandCollapseClickEvent=()=>{
        ;

        if(expandCollapseButtonText==="Expand All"){
            setExpandAll(true);

        }else if(expandCollapseButtonText==="Collapse All"){
            setExpandAll(false);
        }
    }

    //Triggers when expandAll variable changes
    useEffect(()=>{
        if(expandAll){
            setExpandCollapseButtonText("Collapse All");

        }else if(expandAll!==null && expandAll===false){
            setExpandCollapseButtonText("Expand All");
        }

    },[expandAll]);

    //This method is triggered when user clicks on individual expand and collapse
    const propogateExpansionChange=(indx,val)=>{
        expandedOrCollapsedRef.current[indx]=val;        
        if(expandedOrCollapsedRef.current.every(i=>i==="expand" || i==='empty')) {
            setExpandCollapseButtonText("Collapse All");
            setExpandAll(null);
        };
        if(expandedOrCollapsedRef.current.every(i=>i==="collapse" || i==='empty')){
            setExpandCollapseButtonText("Expand All");
            setExpandAll(null);
        };
    }

    return (
        <div>
            {/* If tab is recent or upcoming, show ExapndAll/Collpase All button. Otherwise dont show */}
            {
                tabName === 'Recent' || tabName === 'Upcoming' ? 
                <div 
                    data-isexpandall={expandAll}
                    onClick={handleExpandCollapseClickEvent}
                    className={classes.expandCollapseAllButton}
                >
                    <Button 
                        className={`open-sans ${classes.textButton}`}
                        id="recent-events-expand-collapse-all" 
                        variant="text" >
                            {expandCollapseButtonText}
                    </Button> 
                </div>:
                null
            }
            {
                eventTabDateArray.map((sd,indx) => 
                    <EachDayEvents
                        key={sd.format()}
                        indx={indx}
                        tabName={tabName}
                        isExpandAll={expandAll}
                        eventDate={sd}
                        propogateExpansionChange={propogateExpansionChange} />
                )
            }
        </div>
    )
}