import styled from "@emotion/styled";
import * as React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
export default function DateSelector({ value, handleChange, label, hasError, style = {} }) {

    return (

        <div style={style}>
            {label ? <DateRangeText>{label}</DateRangeText> : null}
            <LocalizationProvider dateAdapter={AdapterMoment} >
                <DatePicker
                    label={label}
                    value={value}
                    onChange={(newValue) => {
                        handleChange(newValue)
                    }}

                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DateInput ref={inputRef} {...inputProps} hasError={hasError} />

                            {InputProps?.endAdornment}

                        </div>
                    )}
                />
            </LocalizationProvider>
            {hasError ? <ErrorText>* Invalid Date Range</ErrorText> : null}
        </div>
    );

}


const StyledInput = styled.input`
box-sizing: border-box;
font-family: 'Raleway';
font-style: italic;
font-weight: 500;
font-size: 12px;
padding: 8px 16px 7px 10px;
background: #FFFFFF;
outline: none;
border: ${props => props.hasError ? '2px solid red' : '1px solid #818181'} ;
border-radius: 10px;`

const DateInput = styled(StyledInput)`
height: 35px;
width: 155px;
    &+div svg{
        color: white !important
    }
`
const DateRangeText = styled.p`
font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 19px;
margin-left: 6px;
margin-bottom: 6px;
`

const ErrorText = styled.p`
    font-size: 12px;
    color: red;
    margin: 0;
    margin-top: 4px;
`