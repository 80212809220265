import { makeStyles } from "@mui/styles";

export const useCohortSettingStyles = makeStyles((theme) => ({
    pgSection:{
        "&:nth-child(n+2)":{
            marginTop:"50px"   
        }
    },
    subheader:{
        fontSize:"18px",
        fontWeight:600,
        color:theme.colors.deepBlue,
        margin:"15px 0px"
    },
    eachCohortSection:{
        paddingTop:"50px"
    },
    eachSubSection:{
        paddingBottom:"20px",
        paddingTop:"20px"
    },
    cohortNameHeader:{
        color:theme.colors.deepBlue,
        fontWeight:600,
        fontSize:"18px"
    },
    divider:{
        '&:nth-child(n+2)':{
            borderTop:'1.5px solid #E5E5E5'  
        }
    },
    label: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "12px",
        padding: "8px 0"
    },
    flexNameHeader:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    },
    addIcon: {
        height: "35px",
        width: "35px",
        color: "rgba(0, 0, 0, 0.4)",
        alignSelf: "center",
        marginTop: "40px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    formFooter:{
        display:"flex",
        justifyContent:"flex-end",
        alignContent:"center",
        alignItems:"center",
        margin: "15px 0px 10px 0px"
    },
    submitButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            ...theme.elements.darkBlueButton
        }
    },
    textButton:{
        ...theme.elements.subtitle,
        color:`${theme.colors.deepBlue} !important`,
        textTransform:"capitalize !important",
        marginRight:"15px !important"
    },

    addMdMarginTop:{
        marginTop:"10px !important"
    }
}))