import { getApiBase, HEADERS } from '../api/helpers';
import { updateUser } from "../api/userManagement";
import { getUTCDate } from '../helpers/utils';

//The logout EventHandler. Triggered when user is logged out of the app
//Global variable can be triggered from anywhere in the app
window.logoutEvent=null;

/**
 * @description - triggers offline event. Sets the is_online flag in database for the user to false
 * @param {*} user 
 * @returns {Object}
 */
const triggerUserOfflineEvent=async (user)=>{
    return await updateUser({
        "is_online": false,
        "user_last_seen": getUTCDate()
    }, user.email);
}

/**
 * @description - triggers online event 
 * @param {*} user 
 * @returns {Promise}
 */
const triggerUserOnlineEvent=async (user)=>{
    return await updateUser({ is_online: true }, user.email);
}

//although, its recommended we shouldnt be using the sendBeacon with beforeunload. SendBeacon must be used with visibilitychange. This implementation works for us in case of multiple tabs/windows are open
//We will be thinking about better solution in future
//https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
const triggerSendOfflineBeacon=(user)=>{
    try {
        const blob = new Blob([
            JSON.stringify({
                "is_online": false,
                "user_last_seen": getUTCDate()
            })
        ], {
            "X-API-KEY":HEADERS["X-Api-Key"],
            "type": 'text/plain',
        }); 
        navigator.sendBeacon(`${getApiBase()}/user/${user.email}`,blob);

    } catch (error) {
        console.error(error)
    }
}

/**
 * @description - logic to count number windows opened for the same domain
 * The window counter is used to set the offline flag for the user, the offline flag will be set only if user closes the last window
 * @param {*} increment 
 */
const getWindowCount=()=>{
    return localStorage.getItem('windowCount')?parseInt(localStorage.getItem('windowCount')):null;
}

export const windowCounter=(increment=true)=>{
    try {

        let count=getWindowCount()?getWindowCount():0;

        let inc=()=>{
      
            localStorage.setItem('windowCount',count+1);
        }

        let dec=()=>{
  
            if(count>0){
                localStorage.setItem('windowCount',count-1);
            }
        }

        if(increment){
            inc();
        }else{
            dec();
        }

    } catch (error) {
        console.error(error)
    }
}

export const addNavigatorOnlineEventHandlers = async(user) => {
    /**
     * After successful login, check user's online status with navigator.onLine
     * if there is a connection, update their online status (the 'is_online' column in Db) to true
     */
    try {
        if (navigator.onLine) {
            await triggerUserOnlineEvent(user);
                        ;
        } else {
           await triggerUserOfflineEvent(user);
            ;
        };
    
        /**
         * listen for changes in online events through the duration of app usage
         */
        window.addEventListener('online', async () => {
            await triggerUserOnlineEvent(user);
            ;
        }, false);
        
        /**
         * User diconnected from internet
         */
        window.addEventListener('offline', async () => {
            await triggerUserOfflineEvent(user);
            
        }, false);

        /**
         * On window closure
         * Decrement the open window counter
         * If it's last window, set user online state to offline
         */
        window.addEventListener('beforeunload', function (e) {
            // ;
            let count=getWindowCount();
            if(count && (count-1 === 0)){
                triggerSendOfflineBeacon(user);
            }
            windowCounter(false);//decrement window counter
        });

    } catch (error) {
        console.error(error);
    }
    
}

/**
 * Events triggered on logout
 */
export const attachLogoutEventHandler = (user, logout) => {
    /**
    * On Logout event
    */
   localStorage.setItem("learnerProfileCompletionDismissed",false)
    window.logoutEvent = new Event('onLogoutEventHandler');

    window.addEventListener('onLogoutEventHandler', async function (e) {
        try {
            //Update user to be online status
            triggerUserOfflineEvent(user);
            logout({ returnTo: window.location.origin});

        } catch (error) {
            console.error(error);
            alert("Error encountered while logging out. Please retry")
        }
        
    }, false);

}