import { useCallback, useEffect, useRef, useState, memo } from "react";
import styled from "@emotion/styled";
import AddMemberModal from "./addMembers/AddMemberModal";
import ManageCohortList from "./ManageCohortList";
import { filterMembers } from '../../../context/helpersV2';
import Alert from '@mui/material/Alert';

import './styles.css';
import { ROLE_MAPPINGS } from "../../../constants";
import { useACPDataContextV2 } from "../../../context/ACPDataStoreV2";
import CircularLoader from "../../../../sharedComponents/CircularLoader";
import {
  useParams
} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { StyledButton } from '../../v2/Dashboard';
import { ReactComponent as CheckMark } from '../../../../icons/check-mark.svg';
export default memo(function ManageCohortPage() {
  const { cohort, members: acpMembers, acpDataLoading, selectedCohortId, fetchAndSetMembers, hasCurrentCohortAdminAccess } = useACPDataContextV2()
  const { roleId } = useParams()
  const selectedCohortRef = useRef(selectedCohortId)
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [members, setMembers] = useState(acpMembers)
  const [allMembers, setAllMembers] = useState(acpMembers)
  const [isLoading, setIsLoading] = useState(false)
  const [courseName, setCourseName] = useState(cohort?.cohort_name)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [successAlertMsg, setSuccessAlertMsg] = useState('')
  const [errorAlertMsg, setErrorAlertMsg] = useState('')
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [filterRoleId, setFilterRoleId] = useState(roleId ? Number(roleId) : 0)

  useEffect(() => {
    //set default filtering from url param
    if (roleId) setFilterRoleId(Number(roleId))
    return () => setFilterRoleId(0)
  }, [roleId])
  useEffect(() => {

    if (showSuccessAlert) {
      setTimeout(() => {
        setShowSuccessAlert(false)
      }, 10000);
    }
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(false)
      }, 10000);
    }
  }, [showSuccessAlert, showErrorAlert])

  useEffect(() => {

    if (filterRoleId && allMembers.length) {
      setMembers(allMembers.filter(m => m.user_role_id === filterRoleId))
    } else {
      setMembers(allMembers)
    }

  }, [filterRoleId, allMembers])



  const handleLoading = useCallback(() => {
    setIsLoading(true)
    setCourseName(cohort?.cohort_name)
    selectedCohortRef.current = selectedCohortId
    fetchAndSetMembers().then(res => {
      setMembers(res.members)
      setAllMembers(res.members)
      setCourseName(res?.cohort_name)
      setIsLoading(false)
      setShouldUpdate(false)

    })
  })

  useEffect(() => {
    if (shouldUpdate) {
      handleLoading()
    }
  }, [shouldUpdate])

  useEffect(() => {
    if (cohort?.cohort_name && acpMembers) {

      setAllMembers(acpMembers)

      setCourseName(cohort?.cohort_name)
    }
  }, [selectedCohortId, acpMembers])

  const handleSetSuccessAlert = (message) => {
    setShowSuccessAlert(true);
    setSuccessAlertMsg(message)
  }
  const handleSetErrorAlert = (message) => {
    setShowErrorAlert(true);
    setErrorAlertMsg(message)
  }
  const handleUpdatedMembers = member => {
    const updatedMembers = [...members.filter(m => member.email !== m.email), member]
    if (!updatedMembers.find(m => m.user_role_id === filterRoleId)) {//clear filter if role no longer exists in cohort
      setFilterRoleId(0)
    }
    setAllMembers(updatedMembers)
  }
  const handleAddMembers = newMembers => {
    setAllMembers([...members, ...newMembers])
    setFilterRoleId(0)

  }
  const handleChangeSelect = e => {
    setFilterRoleId(Number(e.target.value))
  }
  const handleSearch = (e) => {
    const val = e.target.value.toLowerCase()
    const result = allMembers.filter(m => (filterRoleId ? m.user_role_id === filterRoleId : m) && (m?.first_name?.toLowerCase().includes(val) || m?.last_name?.toLowerCase().includes(val) || m?.email?.split('@')[0].toLowerCase().includes(val)))
    setMembers(result)
  }


  if (acpDataLoading || isLoading) return <div style={{ height: '100%', display: 'flex' }}><CircularLoader /></div>
  return (
    <>
      <div style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 99, display: 'flex', flexDirection: 'column' }}>

        {showSuccessAlert && <Alert severity={'success'} sx={{ width: '400px' }}>
          {successAlertMsg}
        </Alert>}
        {showErrorAlert && <Alert severity={'error'} sx={{ width: '400px', marginTop: '20px' }}>
          {errorAlertMsg}
        </Alert>}
      </div>
      <AddMemberModal
        handleClose={() => setShowAddMemberModal(false)}
        isOpen={showAddMemberModal}
        setSuccessAlert={handleSetSuccessAlert}
        setErrorAlert={handleSetErrorAlert}
        handleUpdate={() => setShouldUpdate(true)}
        handleUpdateMemberCount={handleAddMembers}
      />
      <div >
        <div style={{ marginBottom: 0 }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
            <PageTitle>{courseName}</PageTitle>
            <PageHeaderContainer>
              <div className="role-container">
                <p className="float-right">Learners</p>
                <div className="role-count">{filterMembers(members, 'activeLearners').length}</div>
              </div>
              <div className="role-container">
                <p className="float-right">Admins</p>
                <div className="role-count">{filterMembers(members, 'activeAdmins').length}</div>
              </div>
            </PageHeaderContainer>
          </div>
          {
            hasCurrentCohortAdminAccess &&
            <>
              <p style={{ marginTop: 0 }}>
                Adding members to a cohort will grant MyKenzie access to learners
                and Academic Control Panel access to staff.
              </p>
            </>
          }

            <>
              <div>
                <h3 className="permission-overview-title" style={{marginTop: !hasCurrentCohortAdminAccess ? "0px" : null}}>
                  ACP Roles & Permissions
                </h3>
                <div className="permission-overview-table-container">
                  <div
                    className="permission-overview-table-row"
                    style={{
                      backgroundColor: "#E7E7E7",
                      borderRadius: "10px 10px 0 0",
                      borderBottom: "1px solid #000000"
                    }}
                  >
                    <span>ACP Role</span>
                    <span>Team Role</span>
                    <span>Permission Level</span>
                    <span>Reflected on Learner Dashboard</span>
                  </div>
                  <div className="permission-overview-table-row">
                    <span>Admin</span>
                    <span>Program Managers & ITS</span>
                    <span>Full ACP Access & Cohort Management</span>
                  </div>
                  <div className="permission-overview-table-row">
                    <span>SME</span>
                    <span>Subject Matter Expert</span>
                    <span>Full ACP Access</span>
                    <span><CheckMark/></span>
                  </div>
                  <div className="permission-overview-table-row">
                    <span>Support</span>
                    <span>Non-academic Support Staff</span>
                    <span>Triage & Announcements ACP Access</span>
                    <span>*Advising staff are learner-specific and added through automation</span>
                  </div>
                  <div className="permission-overview-table-row">
                    <span>Facilitator</span>
                    <span>Facilitators</span>
                    <span>Triage & Announcements ACP Access</span>
                    <span><CheckMark/></span>
                  </div>
                  <div className="permission-overview-table-row">
                    <span>Coach</span>
                    <span>Coaches</span>
                    <span>Triage ACP Access & MyKenzie Access</span>
                    <span><CheckMark/></span>
                  </div>
                  <div
                    className="permission-overview-table-row"
                    style={{ border: 0 }}
                  >
                    <span>Learner</span>
                    <span>Learners</span>
                    <span>MyKenzie Access</span>
                  </div>
                </div>
              </div>
            </>
        </div>
        <Row>
          <SearchBox >
            <SearchIcon sx={{ marginLeft: '15px' }} />
            <input placeholder="Search People..." onChange={handleSearch} />
          </SearchBox>
          <RoleDropdown as={'select'} onChange={handleChangeSelect} value={filterRoleId}>
            <StyledOption value={0} >All Roles</StyledOption>
            {allMembers.length ?
              getAvailableRolesFromMembers(acpMembers).map((roleId) => (
                <StyledOption key={roleId} value={roleId} >{ROLE_MAPPINGS[roleId] === 'Coach' ? 'Coaches' : ROLE_MAPPINGS[roleId] === 'SME' ? 'Subject Matter Experts' : ROLE_MAPPINGS[roleId] === 'Support' ? 'Support' : ROLE_MAPPINGS[roleId] + 's'} ({allMembers.filter(m => m.user_role_id === roleId).length})</StyledOption>
              ))
              : null}
          </RoleDropdown>

          {
            hasCurrentCohortAdminAccess ?
              <StyledButton
                onClick={() => setShowAddMemberModal(true)}
              // className="add-members-button"
              // style={{ textDecoration: 'none' }}
              >
                Add Members{" "}
              </StyledButton>
              :
              null
          }
        </Row>
        <ManageCohortList members={members} isLoading={isLoading} setAlert={handleSetErrorAlert} setUpdatedMembers={handleUpdatedMembers}
        />

      </div>
    </>
  );
})

const Row = styled.div`
display: flex;
align-items: center;
margin-bottom: 30px;
`
const StyledInput = styled.div`
background: #FFFFFF;
border: 1px solid #000000;
border-radius: 5px;
height: 36px;
box-sizing: border-box;
margin-right: 15px;
`

const SearchBox = styled(StyledInput)`
width: 329px;
display: flex;
align-items: center;
overflow: hidden;
& input{
  padding-left: 10px;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
}
`

export const PageHeaderContainer = styled.div`
margin: -10px 0px 20px 0;
display: flex;
align-items: center;
& .role-container{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 31px;
}
& .float-right{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}
& .role-count{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 3px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 31px;
}
`

export const PageTitle = styled.h1`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 22px;
margin-top: 35px;
`
const RoleDropdown = styled(StyledInput)`
width: 329px;
`
const StyledOption = styled.option``

function getAvailableRolesFromMembers(members) {
  return [...new Set(members.map(m => m.user_role_id))]
}