import React, { useContext, useEffect, useState } from 'react';

import { Card } from '../../../components';
import { LearnersDataContext } from '../../../context';

import moment from 'moment';

import { useStyles } from './styles';

import { LoadingEvents } from './LoadingEvents';

import EventTabContainer from './EventTabContainer';
import { createDateArray } from './helpers';
import NoEventsTodayView from './NoEventsTodayView';

export const CalendarEventsContext = React.createContext();

// const DAY_TICK_TIME_INTERVAL=10000;//10 seconds 
// var eventPanelDayTickInterval=null;

export default function Events() {

    const classes = useStyles();

    const [loading, setLoading] = useState(null);
    const today = moment().startOf('day');

    const [events, setEvents] = useState(null);
    const [eventRecordings,setEventRecordings]=useState(null);

    const [currentTab, setCurrentTab] = useState("Today");

    /** Create date array starting from monday of current week to sunday of current week */
    const [dateArray,setDateArray]=useState(null);

    const { userCohort, fetchCalendarEvents, fetchCalendarEventRecordings, checkDashboardDataState } = useContext(LearnersDataContext);



    const [recentEvents, setRecentEvents] = useState([]);
    const [todaysEvents, setTodaysEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    //Cleanup if component unounts
    useEffect(() => {

        return (() => {
            setEvents(null);
            setEventRecordings(null);
            setLoading(null);
        });

    }, []);

    /** Loads the events when userCourse/cohortId Information is changed */
    useEffect(()=>{
        if(userCohort?.cohort_id){
            
            let controller = new AbortController();

            refreshPanel();//Refreshes the data for entire panel

            return () => controller?.abort();//Aborts the async call if props change to avoid memory leak, i,.e. userData

        }
    },[userCohort])

    useEffect(() => {
        if (events !== null) {
            ;
            
            setLoading(false);
            checkDashboardDataState("calendarEvents");

            const recentEventsData = events.filter(i => today.isBefore(i.start_date_time,'days') === true);

            const todaysEventsData = events.filter(i => today.isSame(i.start_date_time,'days') === true);

            const upcomingEventsData = events.filter(i => today.isBefore(i.start_date_time,'days') === true);

            setRecentEvents(recentEventsData)
            setTodaysEvents(todaysEventsData);
            setUpcomingEvents(upcomingEventsData);
            
        }
    }, [events]);


    //Refresh Panel Data 
    const refreshPanel=(refresh=false)=>{
        try {
            
            setLoading(true);

            //Sets the date array from monday to sunday 
            setDateArray(createDateArray());

            //fetches related cohort calendar and related calendar events
            fetchCalendarEvents(refresh).then(data => {
                setEvents(data);

                //Gets the recordings for the events
                return fetchCalendarEventRecordings();

            }).then(recordings=>{
                setEventRecordings(recordings);

            }).catch(error => {
                console.error(error);
                setLoading(false);
            });

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    return (
        <CalendarEventsContext.Provider value={{
            "events":events,
            "eventRecordings":eventRecordings,
            "setEventRecordings":setEventRecordings,
            "dateArray":dateArray,
            "today":today,
            "eventStateRefreshRate":5000//5 seconds. Used in EachEvent and CountDowntime Components
        }}>
            <Card title="Classes & Events" classes={{ content: classes.contentWrapper }} theme="secondary">
                {
                    !loading ?
                    <div className={classes.outterTabContainer}>
                        <div className={classes.tabContainer}>
                        {
                            events && recentEvents.length > 0 ?

                            <div className={`${classes.eachTab} ${(currentTab === "Recent") ? classes.selected : ''}`} onClick={() => {
                                if (currentTab !== "Recent") {
                                    setCurrentTab("Recent")
                                }
                            }} id="recent-tab">
                                <p>Recent</p>
                            </div>

                            :

                            <div className={`${classes.eachTab} ${classes.tabDisabled}`} id="recent-tab">
                                <p>Recent</p>
                            </div>
                        }

                            <div className={`${classes.eachTab} ${(currentTab === "Today") ? classes.selected : ''}`} onClick={() => {
                                if (currentTab !== "Today") {
                                    setCurrentTab("Today")
                                }
                            }} id="today-tab">
                                <p>Today</p>
                            </div>

                        {
                            events && upcomingEvents.length > 0 ?

                            <div className={`${classes.eachTab} ${(currentTab === "Upcoming") ? classes.selected : ''}`} onClick={() => {
                                if (currentTab !== "Upcoming") {
                                    setCurrentTab("Upcoming")
                                }
                            }} id="upcoming-tab">
                                <p>Upcoming</p>
                            </div>

                            :

                            <div className={`${classes.eachTab} ${classes.tabDisabled}`} id="upcoming-tab">
                                <p>Upcoming</p>
                            </div>
                        }
                        </div>
                    </div>
                    :

                    loading && <LoadingEvents />

                }
                {
                    !loading && dateArray && events &&
                    // <div className={classes.outterTabContainer}>
                        <div className='open-sans'>
                            <div id="calendar-recent-events" className={classes.tabContentContainer} style={{display: currentTab === 'Recent' ? 'block':'none'}}>
                                <EventTabContainer 
                                        tabName="Recent"
                                        eventTabDateArray={dateArray.filter(i=>i.isBefore(today,'days')===true).sort((a,b)=>{
                                            return a.isSameOrAfter(b,'days')?-1:1
                                        })}
                                    />
                            </div>
                            <div id="calendar-todays-events" className={classes.tabContentContainer} style={{display: currentTab === 'Today' ? 'block':'none'}}>
                                {
                                    events && todaysEvents.length > 0 ?

                                    <EventTabContainer 
                                        tabName="Today"
                                        eventTabDateArray={dateArray.filter(i=>today.isSame(i,'days')===true)}
                                    />

                                    :

                                    <NoEventsTodayView />
                                }
                            </div>
                            <div id="calendar-upcoming-events" className={classes.tabContentContainer} style={{display: currentTab === 'Upcoming' ? 'block':'none'}}>
                                <EventTabContainer 
                                    tabName="Upcoming"
                                    eventTabDateArray={dateArray.filter(i=>today.isBefore(i,'days')===true)}
                                />
                            </div>
                        </div>
                    // </div>
                }
            </Card>
        </CalendarEventsContext.Provider>
       
    )
}