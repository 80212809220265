import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { v4 as uuid4 } from 'uuid';

import { uploadFile } from '../../../api';
import { updateUser } from '../../../api/userManagement';
import { AppDataContext } from '../../../context';
import { useProfileImageUploader } from './profileImageStyles';

const SCALED_IMAGE_SIZE=260;

const ProfileImageUploader = ({
    handleOnCancel
}) => {

    const classes=useProfileImageUploader();

    let isDraggableRef=useRef(false);
    let storeImageDimensions=useRef(null);

    const { user } = useAuth0();
    const {userData, setUserData}=useContext(AppDataContext);

    const [imageSelected,setImageSelected]=useState(null);
    const [imgSrc,setImageSrc]=useState(null);
    const [imageDimensions,setImageDimensions]=useState(null);
    const [imageUploadInProgress,setImageUploadInProgress]=useState(false);
    const [imageUploadError, setImageUploadError]=useState(false);

    useEffect(()=>{
        return(()=>{
            setImageSelected(null);
            setImageSrc(null);
            setImageDimensions(null);
        })
    },[])

    useEffect(() => {
        if (imageSelected) {

            let reader = new FileReader();

            reader.readAsDataURL(imageSelected);

            reader.onload = function () {

                setImageSrc(reader.result);

                let img=new Image();
                img.src=reader.result;
                img.alt ="profile image"
                img.onload=function(){

                    let dimensions={};
                    //;

                    if (img.height < img.width) {
                        dimensions=Object.assign({ ...imageDimensions }, {
                            height: "275px",
                            transformOrigin: "130px 130px",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0
                        })
                    } else {
                        dimensions={
                            width: "275px",
                            transformOrigin: "130px 130px",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0
                        }
                    }

                    //Stores image original width and height. 
                    //This is used for cropping later 
                    storeImageDimensions.current={
                        originalWidth:img.width, 
                        originalHeight:img.height
                    };

                    setImageDimensions({...dimensions});
                }
            }
        }
    }, [imageSelected]);

    //Triggers when file is dropped or uploaded from input file
    const handleOnFileUpload=(files)=>{
        if(files.length>0){
            files[0].uuid=uuid4();
            setImageSelected(files[0]);//setting state
        }  
    }
    
    //Triggers when remove button is clicked
    const handleOnRemove=()=>{
        try {
            setImageSelected(null);
            setImageDimensions(null);
            isDraggableRef.current=false;
            storeImageDimensions.current=null;
        } catch (error) {
            console.error(error);
        }
    }

    //Triggered when user is adjusting the image 
    const handleOnMouseMove=(event)=>{
        try {
            if(isDraggableRef.current){
                
                let x=event.movementX;
                let y=event.movementY;
                let d={...imageDimensions}
                
                d.left=d.left+x;
                d.top=d.top+y;

                setImageDimensions(d);

            }
        } catch (error) {
            console.error(error);
        }
    }

    //Triggered when user clicks on save button 
    //Logic Flow: Crops the original image, scale the image and then stores gets the image Url to store in the databse
    //https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    const handleSubmission=async()=>{
        try {

            setImageUploadInProgress(true);

            let img=document.getElementById('uploaded-user-image-img-element');
            //;
            
            let canvas=document.getElementById('uploaded-image-canvas');

            canvas.width=SCALED_IMAGE_SIZE;
            canvas.height=SCALED_IMAGE_SIZE;

            const ctx = canvas.getContext('2d');

            let orgWd=storeImageDimensions.current.originalWidth;
            let orgHt=storeImageDimensions.current.originalHeight;

            let widthRatio=orgWd/img.width;
            let heightRatio=orgHt/img.height;

            let sx=0,sy=0,sWidth=0,sHeight=0,dx=0,dy=0;
            let dWidth=SCALED_IMAGE_SIZE,dHeight=SCALED_IMAGE_SIZE

            //Calc how much to crop the height per the top dimensions scrolled
            if(imageDimensions.top>0){//if Positive
                sy=0;
                sHeight=(orgHt)-(heightRatio*imageDimensions.top);
            }else{//If negative
                sy=(heightRatio)*(Math.abs(imageDimensions.top));
                sHeight=heightRatio*SCALED_IMAGE_SIZE;
            }

            //Calc how much to crop from sides per left dimensions 
            if(imageDimensions.left>0){
                sx=0;
                sWidth=(orgWd)-(widthRatio*imageDimensions.left)
            }else{
                sx=(widthRatio)*(Math.abs(imageDimensions.left));
                sWidth=widthRatio*SCALED_IMAGE_SIZE
            }

            ;
            //Cropping and scaling the image per the set dimensions. 
            //Read the documentation for canvas drawImage for more details
            ctx.drawImage(img, sx, sy,sWidth, sHeight,dx,dy,dWidth,dHeight);

            //Getting the cropped image URL and then send it to server for upload. 
            //This will be in  imgBase64 format
            let dataURL=canvas.toDataURL("image/jpeg",0.8);

            // ;
            //Sending the data as formData 
            let formData=new FormData();
            formData.append('srcType',"profileimage")
            formData.append('destination','s3');
            formData.append('acl','public-read');
            formData.append('file',dataURL);
            formData.append('fileName',`${uuid4()}.jpeg`);
            formData.append('email',user.email);
            formData.append('fileContentEncoding','base64');
            formData.append('fileContentType','image/jpeg');
            
            //Upload image to s3 Bucket 
            let response=await uploadFile(formData);

            //Update mk_user table with user_image_url returned form the previous response
           await updateUser({
                "user_image_url":response.data.message.response.s3FileUri,
            },user.email);

            ;

            let _d={...userData};
            _d.user_image_url=response.data.message.response.s3FileUri;

            setUserData(_d);

            setImageUploadInProgress(false);

            //Close/Unmount ProfileImageUploader
            handleOnCancel();

        } catch (error) {
            console.error(error);
            setImageUploadError(true);
            setImageUploadInProgress(false);
        }
    }

    return (<Dialog
        fullWidth={true}
        maxWidth='sm'
        sx={{
            paddingBottom:"20px"
        }}
        id="upload-user-image-dialog-container"
        open={true}
        onClose={handleOnCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="upload-user-image-title" >
            <div className={classes.title}>Upload a new avatar</div>
            <div className={classes.subtitle}>This will only be used for Kenzie Connect profile</div>
        </DialogTitle>
        <DialogContent id="upload-user-image-dialog-description">
            <div className={classes.imageUploaderContainer} id="upload-user-image-drag-drop-container">
                {
                    // Triggered when image is dropped or added via computer
                    imageSelected?
                    <div id='uploaded-user-image-container' 
                        className={classes.displayUploadedImageContainer}
                        onMouseLeave={()=>{
                            isDraggableRef.current=false;
                        }}
                        onMouseMove={(event)=>{
                            handleOnMouseMove(event);
                        }}
                        onMouseDown={()=>{
                            isDraggableRef.current=true;
                        }}
                        onMouseUp={()=>{
                            isDraggableRef.current=false;
                        }}
                        >
                        <img id="uploaded-user-image-img-element" src={imgSrc}
                            style={{...imageDimensions}} alt="Uploaded User"
                        />
                        <div className={`${classes.gridLines} ${classes.horizontalGridLine} ${classes.tHorizontal}`}></div>
                        <div className={`${classes.gridLines} ${classes.horizontalGridLine} ${classes.bHorizontal}`}></div>
                        <div className={`${classes.gridLines} ${classes.verticalGridLine} ${classes.lVertical}`}></div>
                        <div className={`${classes.gridLines} ${classes.verticalGridLine} ${classes.rVertical}`}></div>
                    </div>:
                    //If image is removed or not dropped or selected yet
                    <div className={classes.imageUploaderButtonContainer}>
                        <div className='v-align'
                            style={{ left: "0px", right: "0px", textAlign: "center", padding: "10px" }}
                            onDrop={(event) => {
                                handleOnFileUpload(event.target.files);
                            }}
                        >
                            <input type="file"
                                id="upload-user-img-input-control"
                                onChange={(event) => {
                                    handleOnFileUpload(event.target.files);
                                }}
                                className={classes.fileUploadInput}></input>
                            <ArrowCircleUpIcon sx={{
                                fontSize: "6em"
                            }} />
                            <div className={classes.subtitle} style={{ textAlign: "center" }}>Click to upload -or- drag your file here</div>
                        </div>
                    </div>
                }
                <canvas id="uploaded-image-canvas" style={{display:'none'}}></canvas>
            </div>
        </DialogContent>
        <DialogActions sx={{
            justifyContent:"space-between"
        }}>
            <div>
                <Button onClick={handleOnRemove} 
                    disabled={imageUploadInProgress}
                    variant="text" className={classes.removeButton}>Remove</Button>
            </div>
            {
                imageUploadError ?
                    <Alert severity="error">Error while uploading. Try again!</Alert> :
                    null
            }
            <div>
                
                <Button onClick={handleOnCancel} 
                    disabled={imageUploadInProgress}
                    variant="text" className={classes.cancelButton}>Cancel</Button>
               
                <Button className={classes.submitButton} 
                    disabled={!imageSelected || imageUploadInProgress}
                    onClick={()=>{
                        handleSubmission();
                    }}> 
                    {
                        imageUploadInProgress?
                        <CircularProgress color="inherit" size={18}/>:
                        "Save"
                    }
                </Button>
            </div>
            
        </DialogActions>
    </Dialog>)

}

export default ProfileImageUploader;