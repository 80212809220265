import moment from "moment";

const stripLeadingPunctuation = (text) => {
    if (text) {
        const regex = /\w.+/;
        return text.match(regex)[0];
    }
    return "";
};

const formatAudioTranscript = (text) => {
    const regex = /^(?:(?!\d|WEBVTT).+)/gm;
    return text.match(regex);
};

/** Grabs the total duration of the video from start and end time */
const getVideoTotalTime = (start, end) => {
    try {
        if (start && end) {

            let totalseconds = moment(end).diff(moment(start), 'seconds');

            return getPlayTimeFormat(totalseconds);

        } else {
            return "00:00:00";
        }

    } catch (error) {
        return "";
    }
}

//** Converts the seconds into the string in 00:00:00 format  */
const getPlayTimeFormat = (totalseconds) => {

    const getNumFormat = (num) => {
        if (num === 0) return "00";
        if (num > 0 && num <= 9) return "0" + num;
        return num;
    }

    if (!isNaN(totalseconds)) {

        let hrs = Math.floor(totalseconds / 3600);

        let remainingSeconds = totalseconds - (hrs * 3600);

        let mins = Math.floor(remainingSeconds / 60);

        remainingSeconds = remainingSeconds - (mins * 60);

        let seconds = remainingSeconds >= 0 ? Math.floor(remainingSeconds) : "00";


        return `${getNumFormat(hrs)}:${getNumFormat(mins)}:${getNumFormat(seconds)}`;

    } else {

        return "00:00:00";
    }


}

//** Gets the post date of video which is rec start date in the dataset */
const getVideoPostedDate = (startDateTime) => {
    try {
        return moment(startDateTime).fromNow();

    } catch (error) {
        return "";
    }
}

/** Grab the total seconds from the time  */
const getTotalSecondsFromTimeStamp = (timeStamp) => {
    try {

        //check format 00:00:00.000
        if (!(/\d+\:\d+\:\d+\.\d+/).test(timeStamp)) throw new Error("invalid timstamp format");//eslint-disable-line  no-useless-escape

        let splitDotValue = timeStamp.split('.')[0];

        let splitTime = splitDotValue.split(":");
        let totalSeconds = parseInt(splitTime[2]) + (parseInt(splitTime[1]) * 60) + (parseInt(splitTime[0]) * 3600)

        return totalSeconds;

    } catch (error){
        return 0;
    }

}

export {
    stripLeadingPunctuation,
    formatAudioTranscript,
    getVideoTotalTime,
    getPlayTimeFormat,
    getVideoPostedDate,
    getTotalSecondsFromTimeStamp
};

