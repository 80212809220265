import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarEventsContext } from '.';

/** Decoupled the countdown time from the each event component
 * This apporach prevents re redering the entire component every 5 seconds
 */
export default function CountDownTimer({
    eventItem=null
}) {
    
    const {eventStateRefreshRate} = useContext(CalendarEventsContext);

    const getFromNow=()=>{
        return moment(eventItem.start_date_time).fromNow();
    }
    
    const [timer,setTimer]=useState(getFromNow());

    const updateTimerState=()=>{
        setTimer(getFromNow());
    }

    useEffect(()=>{
        const interval = setInterval(updateTimerState, eventStateRefreshRate);

        return(()=>{
            clearInterval(interval);
        })

    },[])
    
    return (
        <span>{timer}</span>
    )
}