import React from 'react';
import { useStyles } from './styles';

import { getRequestTypeMapping } from './helpers';

export default function EachRequestHeaderTag({
    request
}){

    const classes=useStyles();

    const requestType=getRequestTypeMapping(request);

    return(
        <div className={`request-header-request-type ${classes.requestHeader}`}>
            <div className={`${classes[requestType+"Color"]} each-request-tag`} 
                data-requesttype={requestType}>
                {requestType}
            </div>
        </div>
    )
}