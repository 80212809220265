import { makeStyles } from "@mui/styles";

export const useAdminSettingStyles = makeStyles((theme) => ({
    subSections:{
        margin:"20px 0px"
    },
    subheader:{
        fontSize:"14px",
        fontWeight:600,
        color:theme.colors.deepAqua,
        margin:"8px 0px"
    },
    pl2:{
        padding:"0px 10px"
    },

    dataRowContainer:{
        padding:"5px 0px",
        "&:hover":{
            backgroundColor:theme.colors.lilac,
            '& .show-button-on-hover':{
                display:"flex"
            }
        }
    },

    dataRowInnerContainer:{
        display:"flex",
        justifyContent:"space-between"
    },

    actionButtons:{
        display:"none",
        justifyContent:"start",
        alignItems:"center"
    },

    textButton:{
        ...theme.elements.subtitle,
        textTransform:"capitalize !important",
        marginRight:"15px !important",
        cursor:"pointer"
    },

    textButtonDeepBlue:{
        color:`${theme.colors.deepBlue} !important`,
    },

    textRust:{
        color:`${theme.colors.rust} !important`,
    },

    previewHighlightedText:{
        color:theme.colors.deepAqua,
        margin:"0px 5px"
    },

    italicsDescriptionText:{
        fontStyle:'italic',
        color:"grey"
    }

}))