import Alert from '@mui/material/Alert';
// https://www.npmjs.com/package/react-alert

export default function CustomAlertTemplate({ style, options, message, close }) {

    return (
        <Alert severity={options.type || 'info'} sx={{ width: '400px', marginTop: '20px', zIndex: 999 }}>
            {message}
        </Alert>
    )
}