import React, { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowDownIcon } from '../../../../images/transcript_arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../images/transcript_arrow_up.svg';
import CircularLoader from "../../../../sharedComponents/CircularLoader";

import { MediaContext } from "../index";
import TranscriptDetails from "./TranscriptDetails";
import useVideoMetaStyles from "./videoMetaStyles";

const TranscriptPanel = React.memo(({
    videoRef,
    showTab,

}) => {

    const { videoInfo, setVideoMarkers, searchText, setTranscriptAvailable, transcriptAvailable, hasChatHistory } = useContext(MediaContext);

    const [tracks, setTracks] = useState([]);
    const [loader, setLoader] = useState(true);
    const [totalSearchedItems, setTotalSearchedItems] = useState(null);
    const [currentSearchItemCount, setCurrentSearchItemCount] = useState(0);
    const [autoScrollFlag, setAutoScrolFlag] = useState(true);

    let searchItemNodesRef = useRef([]);
    let currentSearchItemRef = useRef(0);

    const classes = useVideoMetaStyles();

    useEffect(() => {
        //grab transcripts content 
        //since 90% files are in kbs its ok to fetch via https from cdn
        if (videoInfo?.transcripts_details !== null && videoInfo?.transcripts_details !== 'null') {
            fetch(videoInfo.transcripts_cdn_url)
                .then(response => response.json())
                .then(data => {
                    if (!/\d\d:\d\d:\d\d\.\d\d\d/.test(Object.values(data)[0]?.start)) {//temporary fix for invalid transcript files making their way into db
                        setTranscriptAvailable(false)
                    }
                    else {

                        setTracks(data);
                    }

                    setLoader(false);
                });

        } else {

            setLoader(false);
        }

        //Cleaning up
        return (() => {
            searchItemNodesRef.current = [];
            currentSearchItemRef.current = 0;
            setTranscriptAvailable(false);
            setLoader(false);
            setCurrentSearchItemCount(0);
            setTracks([]);
            setAutoScrolFlag(true);
        })

    }, []);

    useEffect(() => {
        let panel = document.getElementById('transcripts-parent-container');
        if (totalSearchedItems !== null && totalSearchedItems >= 0) {
            if (panel) panel.style.top = "95px";
        } else {
            if (panel) panel.style.top = "40px";
        }
    }, [totalSearchedItems])

    const cleanTranscriptTags = () => {

        //search is done within the dom instead of searching thru the entire transcript  again
        //This implementation might not be the optimal, but is fastest and also easier for autoscroll
        let spans = document.querySelectorAll('#transcripts-container .each-transcript-row .transcript-text .highlight-search-txt');
        let selectedRow = document.querySelectorAll('#transcripts-container .highlight-search-box');

        selectedRow.forEach(node => node.classList.remove('highlight-search-box', 'transcript-row-selected-on-arrow-select'));

        for (let j = 0; j < spans.length; j++) {
            spans[j].classList.remove('highlight-search-txt');
        }

    }

    const cleanUpStatesOnNoSearchResults = () => {
        setTotalSearchedItems(null);
        setCurrentSearchItemCount(0);
        setVideoMarkers([]);
    }

    const scrollNodeIntoView = (node) => {
        let parentElToScroll = document.getElementById('transcripts-container');
        let parentNode = node.closest('.each-transcript-row');
        parentNode.classList.add('transcript-row-selected-on-arrow-select');

        let topPos = parentNode.offsetTop;

        //Scroll to that node 
        parentElToScroll.scroll({
            top: topPos,
            behavior: "smooth"
        });
    }

    useEffect(() => {

        if (searchText !== null && searchText.length > 0) {

            let text = searchText.toLowerCase();

            let textNodes = document.querySelectorAll('#transcripts-container .each-transcript-row .transcript-text');

            cleanTranscriptTags();

            searchItemNodesRef.current = [];

            let setTrackInfo = [];

            if (text.length > 0) {

                for (let i = 0; i < textNodes.length; i++) {
                    let innerTxt = textNodes[i].innerText.toLowerCase();
                    let indx = innerTxt.indexOf(text);


                    if (indx >= 0) {
                        let innerHtmlTxt = innerTxt.substring(0, indx) + "<span class='highlight-search-txt'>" + innerTxt.substring(indx, indx + text.length) + "</span>" + innerTxt.substring(indx + text.length);

                        textNodes[i].innerHTML = innerHtmlTxt;

                        let parentNode = textNodes[i].closest('.each-transcript-row');
                        parentNode.classList.add('highlight-search-box');

                        searchItemNodesRef.current.push(textNodes[i]);//push all the nodes in the array as ref

                        setTrackInfo.push(Object.assign(tracks[textNodes[i].getAttribute('trackkey')], {
                            node: innerHtmlTxt
                        }));

                    }
                }

                //set the total count and current count 
                setTotalSearchedItems(searchItemNodesRef.current.length)
                setCurrentSearchItemCount(1);
                currentSearchItemRef.current = 0;
                setVideoMarkers(setTrackInfo);
            }

        } else {
            cleanTranscriptTags();
            cleanUpStatesOnNoSearchResults();
        }
    }, [searchText]);

    /** Handler for search results scroller */
    const handleArrowClick = (direction) => {

        let scrollToNode = null;

        //Set the autoscroll to false when user clicks on the arrow 
        setAutoScrolFlag(false);

        //clean background highlight tag 
        let highlightedNodes = document.querySelectorAll('#transcripts-container .transcript-row-selected-on-arrow-select');
        highlightedNodes.forEach(node => node.classList.remove('transcript-row-selected-on-arrow-select'))

        //The currentRef is set for next click
        function setForNextClick() {
            //The currentRef is set for next increment
            currentSearchItemRef.current =
                currentSearchItemRef.current + 1 > searchItemNodesRef.current.length ?
                    currentSearchItemRef.current : currentSearchItemRef.current + 1;
        }

        if (direction === 'up') {
            currentSearchItemRef.current = currentSearchItemRef.current > 1 ?
                currentSearchItemRef.current - 2 : 0;

            if (currentSearchItemRef.current >= 0) {
                scrollToNode = searchItemNodesRef.current[currentSearchItemRef.current];
                setCurrentSearchItemCount(currentSearchItemRef.current + 1);
                setForNextClick();
            }
        } else {

            if (currentSearchItemRef.current < searchItemNodesRef.current.length) {
                scrollToNode = searchItemNodesRef.current[currentSearchItemRef.current];
                setCurrentSearchItemCount(currentSearchItemRef.current + 1);
                setForNextClick();
            }
        }

        if (scrollToNode !== null) {
            scrollNodeIntoView(scrollToNode);
        }
    }
    if (!transcriptAvailable && !hasChatHistory) return null
    return (
        <div className={`${classes.tabContainer}`} style={{ display: showTab ? "block" : "none" }}>
            {
                loader ?
                    <CircularLoader /> :
                    <>
                        {
                            videoInfo.transcripts_details === null ?
                                <div style={{ padding: "15px 10px 10px 10px", textAlign: "center", fontStyle: "italic" }}>Audio transcription for this video is unavailable</div> :
                                <>
                                    {
                                        totalSearchedItems !== null ?
                                            <div className={classes.searchResultsCountContainer}>

                                                <div className="search-result-counts">
                                                    <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#FFB800" }}></div>
                                                    <div>
                                                        {
                                                            totalSearchedItems > 0 ?
                                                                <>
                                                                    <span>{currentSearchItemCount}</span>
                                                                    <span>/</span>
                                                                    <span>{totalSearchedItems}</span>
                                                                    <span> results</span>
                                                                </> :
                                                                <div className="italics">No results found</div>
                                                        }

                                                    </div>
                                                </div>

                                                {
                                                    totalSearchedItems > 0 ?
                                                        <div className="search-arrow-icons">
                                                            <div className="pointer" onClick={() => { handleArrowClick('down') }}><ArrowDownIcon style={{
                                                                width: "30px",
                                                                height: "30px"
                                                            }} /></div>
                                                            <div className="pointer" onClick={() => { handleArrowClick('up') }}><ArrowUpIcon style={{
                                                                width: "30px",
                                                                height: "30px"
                                                            }}
                                                            /></div>
                                                        </div> :
                                                        null
                                                }

                                            </div>
                                            :
                                            null
                                    }

                                    <TranscriptDetails
                                        autoScrollFlagFromParent={autoScrollFlag}
                                        videoRef={videoRef}
                                        tracks={tracks} />
                                </>
                        }
                    </>
            }

        </div>

    );
});

export default TranscriptPanel;
