import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function RadioButtons({ groupProps, buttons }) {
    return (
        <RadioGroup


            {...groupProps}
        >
            {buttons.map(btn => (
                <FormControlLabel {...btn} control={
                    <Radio
                        sx={{
                            color: '#FFF',
                            '&.Mui-checked': {
                                color: '#FFF',
                            },
                        }}
                    />
                }
                />
            ))}
        </RadioGroup>
    )
}

