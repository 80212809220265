import React, { useContext } from 'react';
import LocationIcon from '../../icons/profile-location-pin.png';

import moment from 'moment';
import useStyles from './styles';

import { UserProfileContext } from './UserProfileContext';

const UserLocation = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext)

    /**
     * Calculate utc offset and render appropriate time difference
     */
    const timeZoneDifference = (timezoneId) => {
        /**
         * retrieve local time (viewer's time) and then find the local UTC offset 
         */
        let now = moment();
        let localOffset = now.utcOffset();

        /**
         * profileOffset uses timezone id from db and moment.js calculates UTC offset based on timezone name/id
         */
        let profileOffset = moment.tz(timezoneId).utcOffset();

        let timeDifference = (profileOffset - localOffset) / 60;

        return timeDifference < 0 && Math.abs(timeDifference) === 1 ? `${Math.abs(timeDifference)} hour behind you`
            :
            timeDifference < 0 && Math.abs(timeDifference) > 1 ? `${Math.abs(timeDifference)} hours behind you`
                :
                timeDifference > 0 && timeDifference === 1 ? `${timeDifference} hour ahead of you`
                    :
                    timeDifference > 0 && timeDifference > 1 ? `${timeDifference} hours ahead of you`
                        :
                        `In your timezone`
    }

    return (
        <>
            {
                (profileData?.user_location && Object.keys(profileData?.user_location).length > 0) &&
                <>
                    {profileData?.user_show_location &&
                        <div className={classes.topItems}>
                            <img id="user-profile-location-image" src={LocationIcon} alt="" />
                            <p>I live in <span className={classes.subHeader}>
                                {profileData.user_location.description}
                            </span></p>
                        </div>
                    }
                    {profileData.user_show_timezone &&
                        <div className={classes.topItems} >
                            {/* this topItem doesn't have an icon, so using a hidden one for consistent spacing */}
                            <img alt="" style={{ opacity: 0 }} />

                            <p style={{ marginTop: '0' }}>My timezone is {profileData.user_timezone.time_zone_name &&
                                <span className={classes.subHeader}> {profileData.user_timezone.time_zone_name} </span>}
                                {profileData.user_timezone.time_zone_id && <span id="user-profile-timezone" style={{ marginLeft: "2px" }}><i>({timeZoneDifference(profileData.user_timezone.time_zone_id)})</i></span>}</p>
                        </div>
                    }
                </>
            }
        </>

    )
}

export default UserLocation;