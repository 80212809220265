import React from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    announcementSkeleton: {
        marginBottom: "23px",
        height: "150px",
        width: "100%"
    }
}))

const AnnouncementSkeleton = () => {
    const classes=useStyles()

    return (
        <div className={classes.announcementSkeleton}>
            <Skeleton variant="rectangular" height="100%" width="100%" sx={{borderRadius: "10px"}}/>
        </div>
    )
}
export default AnnouncementSkeleton;