
export function findSettingsTablesToUpdate(initialWebtools, data, cohortId, courses, courseAssociations) {

    let tables = {};
    // const newWebtoolsPayload = findNewWebtools(initialWebtools, updatedWebtools)
    const { webtools, updatedWebtools } = formatWebtoolsForComparison(initialWebtools, data.webtools)
    const webtoolUpdates = findWebtoolsToUpdate(webtools, updatedWebtools, cohortId)
    if (webtoolUpdates) tables.webtools = webtoolUpdates
    if (Object.values(data?.calendar).filter(d => d !== '').length) tables.calendar = data.calendar
    if (Object.values(data?.cohort).filter(d => d !== '').length) tables.cohort = data.cohort
    let { courseAdditions, courseDeletions } = formatCourseUpdates(courses, courseAssociations)
    if (courseAdditions.length > 0) tables.courseAdditions = courseAdditions
    if (courseDeletions.length > 0) tables.courseDeletions = courseDeletions
    return Object.values(tables).length ? tables : {}

}

// function findNewWebtools(initialWebtools, updatedWebtools, cohortId) {
//     const initialNames = initialWebtools.map(t => t.webtool.name)
//     const newWebtools = WEBTOOL_MAPPINGS.filter(t => !initialNames.includes(t.name))
//     let result = newWebtools.map(t => {
//         return ({ name: t.name, webtools_id: t.id, is_active: false, cohort_id: cohortId })
//     })
//     for (let key in updatedWebtools) {
//         const existingIndex = result.findIndex(t => t.name === key)
//         if (existingIndex !== -1) {
//             result[existingIndex].is_active = updatedWebtools[key]
//         }
//     }
//     return result
// }
function findWebtoolsToUpdate(webtools, updatedWebtools) {
    const result = []
    for (let key in updatedWebtools) {
        const [name, id] = key.split('-')
        if (updatedWebtools[key] !== webtools[name])
            result.push({ name: name, webtools_id: id, is_active: updatedWebtools[key] || false })
    }

    return result.length ? result : null
}


function formatWebtoolsForComparison(initialWebtools, updatedWebtools) {
    const webtools = {}
    for (let t of initialWebtools) {
        webtools[t.webtool.name] = t.is_active
    }

    //some checkboxes come back as array with string 'off' or 'on'
    //not sure why
    //convert those arrays to boolean
    for (let toolName in updatedWebtools) {
        let tool = updatedWebtools[toolName]
        if (typeof tool === 'object') {
            updatedWebtools[toolName] = tool[0] === 'on' ? true : false
        }
        if (tool === 'true') updatedWebtools[toolName] = true
        if (tool === 'false') updatedWebtools[toolName] = false
    }
    return { webtools, updatedWebtools }
}

function formatCourseUpdates(courses, courseAssociations) {
    let activeCourses = [...courses["active"]]
    let activeCourseAssociations = [...courseAssociations["active"]]
    let courseAdditions = []

    for (let courseAssociation of activeCourseAssociations) {
        let index = activeCourses.findIndex(i => i.lms_course_id === courseAssociation.lms_course_id);
        if (index === -1) courseAdditions.push(courseAssociation)
        else activeCourses.splice(index, 1)
    }
    // If a course association is not in the current courses state, it is a course addition
    // If a course association is in the current course state, no change and it is removed from the array
    // Afterwards, activeCourses is an array of courses that were not in courseAssociations, these are course deletions
    return { courseAdditions, courseDeletions: activeCourses }
}