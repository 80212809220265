import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import React from 'react';
import EachRequestAuthorAndSrc from './EachRequestAuthorAndSrc';

import EachRequestHeaderCourseTags from './EachRequestHeaderCourseTags';
import EachRequestPreviewQuestion from './EachRequestPreviewQuestion';
import EachRequestStatus from './EachRequestStatus';

import { getRequestTypeMapping } from './helpers';

import EachRequestDates from './EachRequestDates';
import EachRequestHeaderTag from './EachRequestHeaderRequestType';
import { useStyles } from './styles';

export default function EachRequest({
    handleOnExpand,
    request
}){

    const classes = useStyles();

    const requestType=getRequestTypeMapping(request);

    return(
        <div className='pointer open-sans each-request-parent-container'
            data-testid={`each-request-parent-container-${request.id}`}
            data-requestid={request.id}
            id={`each-request-parent-container-${request.id}`}
            onClick={()=>{handleOnExpand(request)}} 
        >

            <div className={`${classes.requestBlock} ${classes[requestType+"Border"]}`}
                data-requesttype={requestType}>
                
                <div className={`each-request-top-container ${classes[requestType+"BgColor"]}`}  >
                    
                    <div className={classes.eachRequestTopInnerContainer}>
                        <div className={`${classes.eachRequestTopHeader} ${classes.addSmMarginBottom}`}>
                            <EachRequestHeaderTag request={request} /> 
                            <OpenInFullIcon className='pointer' fontSize='18' />
                        </div>
                        <div className='lineHt-md'>
                            <EachRequestHeaderCourseTags request={request} /> 
                        </div>
                        <div className='lineHt-md'>
                            <EachRequestStatus request={request} />
                        </div>
                        <div>
                            <EachRequestPreviewQuestion request={request} />
                        </div>
                    </div>
                </div>

                <div className='each-request-bottom-container'>
                    <div className={classes.eachRequestLowerBottomContainer}>

                        <div className={`each-request-expansion-bottom-left-container ${classes.eachRequestBottomContainer}`}>
                            <div className='each-request-author-src'>
                                <EachRequestAuthorAndSrc request={request} />
                            </div>
                        </div>

                        <div className={classes.addMdMarginLeft}>
                            <EachRequestDates request={request} />
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}