import { useTheme } from '@mui/material';
import React, { useContext } from 'react';

import Image from '../../images/kenzieAvatar.png';

import EmailIcon from '../../icons/email.svg';
import SlackIcon from '../../icons/slack-white.svg';

import { Link } from "react-router-dom";
import EditIcon from '../../icons/edit_pencil_teal.svg';
import useStyles from './styles';

import { AppDataContext } from '../../context';
import { UserProfileContext } from './UserProfileContext';


const constructSlackLink = (slackId) => {
    //let kenzieTownWorkspaceId="TCNHBFEG3"; = TeamId
    //Do not delete follwoing urls. keeping it for reference after research :). 
    //https://kenzietown.slack.com/team/${slackId}; - Takes to users profile. But user needs to be right channel. Once profile opens user needs to click on message icon for direct message.
    //slack://user?team=<workspaceID | TCNHBFEG3 for Kenzietown>&id={userID} - Works the best. But need app installed.
    return `https://kenzietown.slack.com/team/${slackId}`;

}

export default function HeaderButtons() {
    const classes = useStyles();
    const theme = useTheme();


    const { userData } = useContext(AppDataContext);
    const { profileData } = useContext(UserProfileContext)
    const isUserProfile = userData.username === profileData.username
    return (
        <div className={classes.headerButtons}>

            {isUserProfile &&

                <Link className={`${classes.headerButton}`} to={userData.hasAdminAccess ? "/acp/staff/editprofile":"/profile/learner/editprofile"} >
                    <img id="emailIcon" src={EditIcon} className='button-icon' alt=""/>

                    <span className='button-text' style={{ color: theme.colors.deepAqua }} > Edit Profile</span>

                </Link>

            }
            {
                // Displays the slack link only if user_slack_id is present in the database
                (!isUserProfile && profileData?.user_slack_id) &&
                <a className={`${classes.headerButton} slack-btn`} href={constructSlackLink(profileData.user_slack_id)} target="_blank" rel="noreferrer" style={{ background: theme.colors.deepAqua, }}>

                    <img id="slackIcon"
                        className='button-icon'
                        style={{ fontColor: 'white' }}
                        src={SlackIcon}
                        alt="slack icon"
                        onError={(e) => e.target.src = Image} />

                    <span className='button-text' style={{ color: 'white' }} > Message on Slack</span>

                </a>
            }
            {!isUserProfile &&
                <a className={`${classes.headerButton} email-btn`} href={`mailto:${profileData.email}`} target="blank">
                    <img id="emailIcon" src={EmailIcon} alt="email icon" className='button-icon' />

                    <span className='button-text' style={{ color: theme.colors.deepAqua }} > Send an Email</span>

                </a>
            }
        </div>
    )
}