import React from "react";
import ReactQuill, {Quill} from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "./CustomQuillStyles.css"
//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

// Don't forget corresponding css
const Size = Quill.import("attributors/style/size");
export const textSizes = ["0.75em", "1em", "1.5em", "2.5em"];
Size.whitelist = textSizes
Quill.register(Size, true);

//Text indent
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});

Quill.register(IndentStyle, true);

export const CustomQuill = (props) => {
    return (
        <div data-text-editor="form-editor" draggable={false} onDrop={(e) => {e.preventDefault()}}>
          <ReactQuill {...props} bounds={`[data-text-editor="form-editor"]`}/>
        </div>
    )
}