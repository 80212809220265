import React, { useEffect } from "react";

import AnnouncementCorousal from "../../../sharedComponents/announcement/AnnouncementCorousal";
import { LearnersDataContext } from '../../context';

export default function Announcements() {

    const {userCourse, fetchAnnouncements, checkDashboardDataState}=React.useContext(LearnersDataContext);

    const [announcements, setAnnouncements] = React.useState([])
    
    useEffect(() => {
        
        if (userCourse && userCourse?.course_id) {
            
            let controller = new AbortController();
            
            const loadAnnouncements = () => fetchAnnouncements(true).then((res) => {
                let sortedAnnouncements = res.reverse();
                setAnnouncements(() => {
                    return sortedAnnouncements;
                })
            });

            loadAnnouncements() // Initial request

            const token = setInterval(loadAnnouncements, 120000) // Every two minutes

            //feedback to App.js when announcements are loaded 
            ;
            checkDashboardDataState("announcements")

            return () => { // Cleanup interval when this effect is cleaned up
                clearInterval(token);
                controller?.abort();//Aborts the async call if props "userData" changes
            }
        }
        
    }, [userCourse]);

    return (
        <div id="annBox">
            <AnnouncementCorousal announcements={announcements}/>
        </div>
    )
}