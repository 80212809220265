import React, { useState } from "react";

import PictureInPicture from "./PictureInPicture";
import FullScreen from "./FullScreen";
import VolumeMute from "./VolumeMute";
import PlayPause from "./PlayPause";
import MediaSlider from "./MediaSlider";
import PlayTimeDisplay from "./PlayTimeDisplay";
import MediaMarkers from "./MediaMarkers";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ClosedCaption from "./ClosedCaption";
import PlaybackSpeeds from "./PlaybackSpeeds";

const MobileMediaControls = ({
    videoRef
}) => {

    const [showDialogForAdditionalControls,setShowDialogForAdditionalControls]=useState(false);

    return (
        <>
            <div className="video-media-inner-controls-bg"></div>

            <div className="video-media-inner-controls">

                <div className="video-media-controls-container">

                    <MediaMarkers videoRef={videoRef} />

                    <MediaSlider videoRef={videoRef} />

                    <div className="video-media-controls-inner-container">

                        <div className="video-media-controls-left">

                            <PlayPause videoRef={videoRef} />
                            <PlayTimeDisplay videoRef={videoRef} />
                            <VolumeMute videoRef={videoRef} />

                        </div>

                        <div className="video-media-controls-right">

                            <PictureInPicture videoRef={videoRef} />
                            <FullScreen videoRef={videoRef} />
                            <MoreVertIcon 
                                onClick={()=>{setShowDialogForAdditionalControls(true)}}
                            />
                            
                        </div>

                    </div>
                </div>

            </div>

            {
                showDialogForAdditionalControls 
                    && <MobileDialogForAdditionalControls 
                        onCloseHandler={setShowDialogForAdditionalControls}
                        videoRef={videoRef}/>
            }

        </>


    );
};

const MobileDialogForAdditionalControls=({
    videoRef,
    onCloseHandler
})=>{
    return (<Dialog open={true} 
            onClose={() => { onCloseHandler(false) }} 
            fullWidth >
        <DialogTitle>Additional Controls</DialogTitle>
        <DialogContent >
            <ClosedCaption videoRef={videoRef} />
            <hr />
            <PlaybackSpeeds videoRef={videoRef} />
        </DialogContent>
    </Dialog> );
}

export default MobileMediaControls;
