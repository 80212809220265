import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    wrapper: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#1D3868",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontFamily: 'Roboto Slab',
        fontSize: '45px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '53px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: "white",
        margin: '15px 0'
    },
    subtitle: {
        fontFamily: 'Raleway',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: "white",
        margin: '8px 0'
    },
    text: {
        fontFamily: 'Raleway',
        fontSize: '13px',
        fontStyle: 'italic',
        fontWeight: '500',
        lineHeight: '14px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: 'white',
        margin: '5px 0'
    }
}))

function AccessDeniedAuthError() {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <div>
            <h1 className={classes.title}>Sorry about that!</h1>
            <h3 className={classes.subtitle}>We are unable to log you in at this time.</h3>
            <p className={classes.text}>If you believe this is a mistake, please<br/>contact your administrator.</p>
            </div>
        </div>
    )
}
export default AccessDeniedAuthError;