import React, { useEffect, useState } from "react";

import { Slider } from "@mui/material";

const MediaSlider = ({
    videoRef
}) => {

    const [videoPosition, setVideoPosition] = useState(0);//keeps track of current position of video being played in seconds
    const [duration, setDuration] = useState(videoRef?.current?.duration || 0);//get sthe total duration of the video

    useEffect(() => {

        let controller = new AbortController();

        if (videoRef?.current) {

            videoRef.current.addEventListener('loadedmetadata', function () {
                setDuration(videoRef.current?.duration);
            }, { signal: controller.signal });

            videoRef.current.addEventListener('timeupdate', function () {
                setVideoPosition(videoRef.current?.currentTime);
            }, { signal: controller.signal });
        }

        return (() => {
            controller.abort();
        });

    }, [videoRef]);


    /** Slider Changes */
    const handleSliderChange = (event, newValue) => {
        videoRef.current.currentTime = newValue;
        setVideoPosition(videoRef.current?.currentTime);
    }

    return (
        <div className="video-media-progress-bar">
            <Slider
                onChange={handleSliderChange}
                sx={{
                    '& .MuiSlider-thumb': {
                        width: '8px',
                        height: '8px'
                    },
                }}
                step={1}
                value={Math.floor(videoPosition)}
                min={0}
                max={duration}
            />
        </div>
    );
};

export default MediaSlider;
