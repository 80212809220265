import React, { useContext, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, IconButton, Modal, Box, Typography } from '@mui/material';

import { createCourses } from '../../../../api';
import { AppDataContext } from '../../../../context';
import { ACPDataContext } from '../../../context';
import EachCourse from './EachCourse';
import { SearchCourseField } from './SearchCourseField';
import { boxStyle } from '../../baseLayout/formStyles';
import { syncCanvasCourses } from '../../../../api';
import { useCohortSettingStyles } from '../styles';
import { useFormStyles } from '../../baseLayout/formStyles';
import { useAdminSettingStyles } from '../../adminSettings/styles';

import { AssociatedCoursesDataContext } from '.';
import { CohortSettingsContext } from '..';

const EachCohortCourses = ({
    cohortId
}) => {

    
    const cohortSettingClasses = useCohortSettingStyles();
    const formClasses = useFormStyles();
    const classes = useAdminSettingStyles()

    const {userData}=useContext(AppDataContext);
    const {userCourses,updateCohortData}=useContext(ACPDataContext);
    const {courses,setCourses}=useContext(AssociatedCoursesDataContext);
    const {setSubmissionSuccessMessage, setSubmissionSuccessfull, setSubmissionError}=useContext(CohortSettingsContext);

    const [edit, setEdit] = useState(false);
    const [canvasSyncLoading, setCanvasSyncLoading] = useState(false)
    const [canvasSyncSuccess, setCanvasSyncSuccess] = useState(false)
    const [canvasSyncFail, setCanvasSyncFail] = useState(false)
    const [isSubmitting,setIsSubmitting]=useState(false);

    //** when user cancels, we should remove newly added courses, which were not saved/linked  */
    const handleCancel=()=>{
        try {
            let d=[...courses];
            setCourses(d.filter(i=>!('isNew' in i) || !(i.isNew)));
            setEdit(false);
        } catch (error) {
            console.error(error);
        }
    }

    const startCanvasSync = async (e) => {
        setCanvasSyncLoading(true)
        let result = await syncCanvasCourses()
        if(result) {
            setCanvasSyncSuccess(true)
        } else {
            setCanvasSyncFail(true)
        }
        setCanvasSyncLoading(false)
    }

    /** Submits the newly added courses and stores in db */
    const handleSubmission = async() => {

        try {

            setIsSubmitting(true);

            // Create Courses (Bulk). 
            // While creating courses the mappings is also inserted in program_mapping table
            let courseCreationPayload = courses.filter(i=>i.isNew===true).map(v => {
                return {
                    "lms_course_id": v.lms_course_id,
                    "coursename": v.coursename,
                    "program_id": userCourses.find(i=>i.cohort_id===v.cohort_id)?.cohort?.program_mappings[0].program_id,
                    "cohort_id": v.cohort_id,
                    "subscribed_canvas": v.subscribed_canvas,
                    "created_by":userData.username
                }
            });

            let coursesCreationPayload = await createCourses(courseCreationPayload);

            //;

            //remove isNew prop from the courses before saving it in the state variable
            //So that when user tries to delete it warning message will show up
            //bec: if object has isNew user is not displayed with warning since, its not saved to db
            let d=[...courses].map(v=>{
                let {isNew,...dataset}=v;
                return dataset;
            });

            //Adding the newly added cohort info in the acp datastore cache
            //Trying to add it without doing another api request. dataset in same format whats comes as response from api
            let responseDataset= coursesCreationPayload.data.message.courses;
            responseDataset.forEach(course => {
                let {mappingId,program_id,cohort_id,...courseInfo}=course;
                updateCohortData({
                    key:"cohortcourses",
                    cohortId:course.cohort_id,
                    isNew:true,
                    dataset:{
                        "id":mappingId,
                        "program_id":program_id,
                        "cohort_id":cohort_id,
                        "course_id":courseInfo.lms_course_id,
                        "course":courseInfo,
                        "is_active":true
                    }
                });
            });

            setCourses(d);

            setIsSubmitting(false);

            setSubmissionSuccessfull(true);
            setSubmissionSuccessMessage("Course(s) added to cohort successfully!");

            setEdit(false);
            
        } catch (error) {
            console.error(error);
            setSubmissionError(true);
            setIsSubmitting(false);
        }
       
    }

    return (
        <div>
            <div className={`${cohortSettingClasses.flexNameHeader} ${cohortSettingClasses.subheader}`}>
                <div>Associated Courses</div>
                <div>
                    {
                        !edit ?
                            <IconButton
                                title='Edit Course Assocation'
                                data-testid='cohort-setting-associated-course-button'
                                onClick={() => {
                                    setEdit(true);
                                }}
                                sx={{ marginLeft: "8px" }}
                                id='cohort-setting-associated-course-button'
                                aria-label="edit cohort courses"
                                size="small">
                                <EditIcon
                                    sx={{
                                        fontSize: "18px"
                                    }}
                                />
                            </IconButton> :
                            null
                    }

                </div>
            </div>

            <div className='text-normal'>Select the LMS courses that are associated to this cohort. These courses, and thier assignments will populated to the learner dashboard.</div>
            <div className='text-normal'>Canvas courses are synced to our database daily around 7am. Don't see what you're looking for? {canvasSyncLoading ? <CircularProgress size={15}/> : <span><span style={{color:"blue", cursor:"pointer"}} onClick={startCanvasSync}>Click here</span> to sync</span>}</div>
            <Modal  
                open={canvasSyncSuccess}
                onClose={() => setCanvasSyncSuccess(false)}
            >
                <Box sx={boxStyle}>
                    <div className={formClasses.formHeaderContainer}>
                        <Typography id="cohort-sync-success" variant="h6" component="h2">
                            <b>Success</b>
                        </Typography>
                        <div>
                            <IconButton onClick={() => setCanvasSyncSuccess(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={formClasses.formControlSection}>
                        Syncing Canvas courses was successful, try searching by course_id again.
                    </div>
                </Box>
            </Modal>
            <Modal
                open={canvasSyncFail}
                onClose={() => setCanvasSyncFail(false)}
            >
                <Box sx={boxStyle}>
                    <div className={formClasses.formHeaderContainer}>
                        <Typography id="cohort-sync-fail" variant="h6" component="h2">
                            <b className={classes.textRust}>Something went wrong...</b>
                        </Typography>
                        <div>
                            <IconButton onClick={() => setCanvasSyncFail(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={formClasses.formControlSection}>
                        Something went wrong while attempting to sync. Try again, or contact the R&D team.
                    </div>
                </Box>
            </Modal>
            <div>
                {
                    edit && <div style={{ margin: "25px 0px 50px 0px" }}>
                        <SearchCourseField />
                    </div>
                }
                <div style={{ margin: "20px 0px" }} className='text-sm'>Courses</div>
                {
                    courses.length > 0 ?
                        courses.map(course => {
                            return <EachCourse
                                key={course.lms_course_id}
                                editMode={edit}
                                course={course}
                            />
                        }) :
                        <div style={{color:'grey',margin:"10px 0px",fontSize:"12px"}}>
                            No courses assigned to the cohort
                        </div>

                }
                {/* Form Buttons on edit mode */}
                {
                    edit && <div className={cohortSettingClasses.formFooter}>
                        <div>
                            <Button
                                onClick={() => { handleCancel() }}
                                variant="text"
                                className={cohortSettingClasses.textButton}>
                                Cancel
                            </Button>
                        </div>
                        <div className={cohortSettingClasses.submitButton}
                            id={`update-cohort-courses-submit-button-${cohortId}`}
                            data-testid={`update-cohort-courses-submit-button-${cohortId}`} >
                            <Button onClick={(e) => {
                                handleSubmission(e)
                            }} disabled={isSubmitting}>
                                {
                                    isSubmitting ?
                                        <CircularProgress color="inherit" size={18} /> :
                                        "Save Changes"
                                }
                            </Button>
                        </div>

                    </div>
                }
            </div>

        </div>
    )

}

export default EachCohortCourses;