import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function UserConfirmationOnLeave(message, callback, open, setOpen) {

    let msg = JSON.parse(message);

    const container=document.createElement('div');
    container.setAttribute("user-confirmation-on-leave","");
    
    const handleOnOkay = (callbackstate) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(callbackstate);
        setOpen(false);
    };

    const handleOnCancel = () => {
        ReactDOM.unmountComponentAtNode(container);
        callback();
        setOpen(false);
    };

    document.body.appendChild(container);

    ReactDOM.render(<Dialog
        sx={{
            "fontFamily":"Raleway !important"
        }}
        open={open}
        onClose={handleOnCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="user-confirmation-on-leave" 
        sx={{
            "fontFamily":"Raleway !important"
        }}>
            {msg.header}
        </DialogTitle>
        <DialogContent>
            <DialogContentText 
                sx={{
                    "fontFamily":"Raleway !important"
                }}
                id="alert-dialog-description">
                {msg.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button sx={{
                "textTransform":'capitalize',
                "fontFamily":"Raleway !important"
            }} onClick={handleOnCancel} autoFocus>Cancel</Button>
            <Button sx={{
                textTransform:'capitalize',
                width:"100px",
                fontWeight:'bold',
                "fontFamily":"Raleway !important"
            }} 
            onClick={handleOnOkay} color="error" variant="contained"> Okay </Button>
        </DialogActions>
    </Dialog>, container)

}