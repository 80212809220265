import React from "react"
import { useFormikContext } from "formik"
import { GrowingInput } from "./components"
import { Grid } from "@mui/material"

const Personal = () => {
    const {
        values,
        handleChange
    } = useFormikContext()
    return (
        <Grid container direction="column" spacing={0} rowSpacing={2} wrap="nowrap">
            <Grid item xs={5} ><p>My Dream Job is <GrowingInput id="user_dream_job" name="user_dream_job" value={values?.user_dream_job?values.user_dream_job:""} onChange={handleChange} variant="standard" placeholder="AI Engineer at Google" minwidth={355} maxwidth={1000}/></p></Grid>
            <Grid item xs={5} ><p>In my free time I enjoy <GrowingInput id="user_free_time" name="user_free_time" value={values?.user_free_time?values.user_free_time:""} onChange={handleChange} variant="standard" placeholder="creating short films" minwidth={320} maxwidth={1000}/></p></Grid>
            <Grid item xs={5} ><p>A fun fact about me is <GrowingInput id="user_fun_fact" name="user_fun_fact" value={values?.user_fun_fact?values.user_fun_fact:""} onChange={handleChange} variant="standard" placeholder="I climbed Mount Everest" minwidth={395} maxwidth={1000}/></p></Grid>
        </Grid>
    )
}

export default Personal