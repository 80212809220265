import React, { useContext, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import { ACPDataContext } from '../../../context';
import { useCohortSettingStyles } from '../styles';

import EditStaffMembersForm from './EditStaffMembersForm';
import StaffMember from './StaffMember';

const EachCohortStaff = ({
    staff = [],
    cohortId = null
}) => {


    const cohortSettingClasses=useCohortSettingStyles();

    const { userCourses } = useContext(ACPDataContext);
    const cohortInfo = userCourses.find(i => i.cohort.id === cohortId);

    const [edit, setEdit] = useState(false);

    return (
        <div>
            <div>
                <div className={`${cohortSettingClasses.flexNameHeader} ${cohortSettingClasses.subheader}`}>
                    <div>Support Team</div>
                    <div>
                        {
                            !edit ?
                                <IconButton 
                                    title='Edit Staff'
                                    data-testid='cohort-setting-staff-edit-button'
                                    onClick={() => {
                                        setEdit(true);
                                    }}
                                    sx={{ marginLeft: "8px" }}
                                    id='cohort-setting-staff-edit-button'
                                    aria-label="edit staff" 
                                    size="small">
                                    <EditIcon
                                        sx={{
                                            fontSize: "18px"
                                        }}
                                    />
                                </IconButton> :
                                null
                        }
                        
                    </div>
                </div>
                <div className='text-normal'>
                    These team members will display in the Support Team panel for learners belonging to this cohort.
                    Associated Kenzie email addresses are used to populate the staff member’s name and profile image.
                    Direct email addresses are not accessible to learners through this panel.
                </div>
            </div>
            <div>
                {
                    !edit ?
                        <>
                            {
                                staff.map(member => {
                                    return <div
                                        className={`${cohortSettingClasses.divider}`}
                                        key={member.id}>
                                        <StaffMember
                                            member={member} />
                                    </div>
                                })
                            }
                        </> :
                        <EditStaffMembersForm 
                            cohortId={cohortId}
                            courseId={cohortInfo.course_id}
                            handleOnClose={()=>{setEdit(false)}}
                            staff={staff}/>
                }

            </div>

        </div>
    )

}

export default EachCohortStaff;