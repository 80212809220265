/**
 * @description searches for word or phrase in Support Requests
 * @param {Array<Object>} dataset - unsearched Support Requests
 * @param {String} searchTerm - the search query
 * @param {String} [legacySearchTerm=undefined] - the search query, modified to support legacy support requests with different formatting
 */
export const wordFound = (dataset, searchTerm, legacySearchTerm = undefined) => {
    return dataset.find(data => {
        let found = data?.includes(searchTerm.toLowerCase());
        if (!found && legacySearchTerm) {
            found = data?.includes(legacySearchTerm.toLowerCase())
        }
        return found;
    });
}

/** Checks the request exists in accumulator */
export const requestExists = (acc, req) => {
    if (acc.find(i => i.id === req.id)) {
        return true;
    }
    return false
}

/**
 * @description Removes HTML tags added from rich text. Preserves tags that were entered in code blocks
 * @param {String} text - rich text containing HTML tags
 */
export const stripHTMLFromSupportRequest = (text) => {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        return doc.documentElement.textContent.replaceAll("\n", " ").replace("&challenge=", "").replace("&solution=", "");
    } catch (error) {
        console.error(error);
        return text
    }
}