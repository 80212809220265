import React from 'react'
import { useStyles } from './styles';
import TopPane from './TopPane';

const ACPLBar = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.acpFontFamily}>

        <div className={classes.lBarTopPane}>
            <TopPane />
        </div>
        <div className={classes.lBarRightPane}>
            {children}
        </div>
    </div>
}

export default ACPLBar;