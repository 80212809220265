import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme, useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "center",
        marginTop: "7px",
        [theme.breakpoints.down("500")]: {
            width: "100%",
            textAlign: "left"
        }
    },
    options: {
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        width: "42px",
        height: "24px",
        marginRight: "3px",
        display: "inline-block",
        cursor: "pointer",
        textAlign: "center",
        [theme.breakpoints.down("500")]: {
            marginTop: "5px"
        }
    },
    active: {
        backgroundColor: "#14606B",
        borderRadius: "5px",
        color: "white"
    }
}))

export const BooleanSelect = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("500"));
    return (
        <span className={classes.text}>
            {props.children}
            {isSmallScreen && <br/>}
            <span 
                className={`${classes.options} ${props.value === true ? classes.active : ""}`} 
                style={{marginLeft: !isSmallScreen ? "30px" : "10px"}}
                onClick={() => {
                    if(props.onChange) {
                        props.onChange({
                            target: {
                                name: props.name,
                                id: props.id,
                                value: true
                            }
                        })
                    }
                }}
            >
                    Yes
            </span>
            <span 
                className={`${classes.options} ${props.value === false ? classes.active : ""}`}
                onClick={() => {
                    if(props.onChange) {
                        props.onChange({
                            target: {
                                name: props.name,
                                id: props.id,
                                value: false
                            }
                        })
                    }
                }}
            >
                No
            </span>
        </span>
    )
}