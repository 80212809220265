import { Button, useMediaQuery, useTheme } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CanvasLogo from '../../../images/canvas_white.png';
import { Card } from "../../components";
import { LearnersDataContext } from '../../context';
import AssignmentCard from './myCoursesPanel/AssignmentCard';
import CourseColorCode from './myCoursesPanel/CourseColorCode';
import { courseColor } from './myCoursesPanel/CourseColorPallete';
import SkeletonMyCourse from './myCoursesPanel/CoursesPanelSkeleton';
import { useStyles } from './myCoursesPanel/styles';

const MyCourse = () => {
    const classes = useStyles();
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const [assignmentsData, setAssignmentsData] = useState(null);
    const [tab, setTab] = useState('This Week')
    const [loading, setLoading] = useState(null);
    const { fetchCanvasData, checkDashboardDataState, userCourses } = React.useContext(LearnersDataContext);
    const [assignments, setAssignments] = useState([]);
    const [pastDueAssignments, setPastDueAssignments] = useState([])
    const [colorKey, setColorKey] = useState([])


    useEffect(() => {
        //Clean up 
        return (() => {
            setAssignmentsData(null);
        })
    }, [])

    useEffect(() => {
        if (userCourses && userCourses.length > 0) {

            setLoading(true);

            let promises = [];

            //fetch data from the data store
            for (let i = 0; i < userCourses.length; i++) {
                promises.push(fetchCanvasData(userCourses[i].course_id));
            }

            Promise.all(promises).then(values => {
                setAssignmentsData(values);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            })
        }
    }, [userCourses]);

    /**
     * This side effect tells the myKenzie main loader that mycourse panel data is loaded
     */
    useEffect(() => {

        if (assignmentsData !== null) {

            setLoading(false);

            /**
             * Separate "due" assignments from "past due" assignments
             */

            let assignments = [];
            let pastDueAssignments = [];

            assignmentsData.forEach(course => {
                assignments = [...assignments, ...course.canvasData.assignments]
                pastDueAssignments = [...pastDueAssignments, ...course.canvasData.pastDueAssignments]
            })

            let sortedAssignments = assignments.sort((a, b) => moment(a.dueAt) - moment(b.dueAt));
            let sortedPastDueAssignments = pastDueAssignments.sort((a, b) => moment(a.dueAt) - moment(b.dueAt));

            setAssignments(sortedAssignments);
            setPastDueAssignments(sortedPastDueAssignments);

            checkDashboardDataState("canvasData")
        }

    }, [assignmentsData]);

    useEffect(() => {

        /**
         * Assign courses to a color key with additional information to match correlated assignments
         */
        let courseColors = [];

        for (let i = 0; i < userCourses.length; i++) {
            courseColors.push({
                courseId: userCourses[i].course_id,
                courseName: userCourses[i].course?.coursename,
                courseColorId: courseColor(i)
            })
        }

        setColorKey(courseColors)

    }, [assignments])

    return (
        <Card title="My Courses"
            classes={{ content: classes.contentWrapper }}
            theme="secondary">
            {
                !loading && assignmentsData ?
                    <div>
                        <div className={classes.header}>
                            <div className={classes.topRow}>
                                <div className={classes.courseColorKey}>
                                    {
                                        assignmentsData.map((course, index) =>
                                            course.canvasData.assignments.length > 0 || course.canvasData.pastDueAssignments.length > 0 ?
                                                <CourseColorCode
                                                    course={course}
                                                    index={index}
                                                    colorKey={colorKey}
                                                    key={uuidv4()}
                                                    hasAssignments={true} />
                                                :
                                                <CourseColorCode
                                                    course={course}
                                                    index={index}
                                                    colorKey={colorKey}
                                                    key={uuidv4()}
                                                    hasAssignments={false} />
                                        )
                                    }
                                </div>
                                {!matches &&
                                    <>
                                        {
                                            assignmentsData[0].canvasData.canvasCourseLink ?

                                                <a style={{ textDecoration: "none" }} href={assignmentsData[0].canvasData.canvasCourseLink} target="_blank" rel="noreferrer">
                                                    <Button variant="contained" classes={{ root: classes.viewCourse }}><img className={classes.canvasLogo} src={CanvasLogo} alt="Canvas Logo" />View Courses</Button>
                                                </a>

                                                :

                                                <Button variant="contained" classes={{ root: classes.viewCourse }} disabled><img className={classes.canvasLogo} src={CanvasLogo} alt="Canvas Logo" />View Courses</Button>
                                        }
                                    </>
                                }
                            </div>
                            {matches &&
                                <>
                                    {
                                        assignmentsData[0].canvasData.canvasCourseLink ?

                                            <a style={{ textDecoration: "none" }} href={assignmentsData[0].canvasData.canvasCourseLink} target="_blank" rel="noreferrer">
                                                <Button variant="contained" classes={{ root: classes.viewCourse }}><img className={classes.canvasLogo} src={CanvasLogo} alt="Canvas Logo" />View Courses</Button>
                                            </a>

                                            :

                                            <Button variant="contained" classes={{ root: classes.viewCourse }} disabled><img className={classes.canvasLogo} src={CanvasLogo} alt="Canvas Logo" />View Courses</Button>
                                    }
                                </>
                            }
                        </div>
                        <div className={classes.selectorWrap}>
                            <div className={`${classes.selector} ${(tab === "This Week") ? classes.selected : ''}`} onClick={() => {
                                if (tab !== "This Week") {
                                    setTab("This Week")
                                }
                            }} >
                                <p>This Week</p>
                            </div>
                            {
                                pastDueAssignments.length < 1 &&
                                <div className={`${classes.selector} ${classes.selectorDisabled}`}>
                                    Past Due (0)
                                </div>
                            }
                            {
                                pastDueAssignments.length > 0 &&
                                <div className={`${classes.selector} ${(tab === "Past Due") ? classes.selected : ''}`} onClick={() => {
                                    if (tab !== "Past Due") {
                                        setTab("Past Due")
                                    }
                                }}>
                                    <p>Past Due ({pastDueAssignments.length})</p>
                                </div>
                            }
                        </div>
                        <div className={classes.content}>
                            {
                                tab === "This Week" ?
                                    assignments && assignments.length > 0 ?
                                        <>
                                            {/* <p className={`${classes.upcomingItem} ${classes.itemTitle}`}>Next 7 days</p> */}
                                            {assignments.map((assignment, index) =>
                                                <AssignmentCard
                                                    assignment={assignment}
                                                    colorKey={colorKey}
                                                    pastDue={false}
                                                    index={index}
                                                    key={uuidv4()}
                                                />
                                            )}
                                        </>
                                        :
                                        <p className={`${classes.upcomingItem} ${classes.noUpcomingItem}`}>No assignments to show</p>
                                    :
                                    pastDueAssignments && pastDueAssignments.length > 0 ?
                                        pastDueAssignments.map((assignment, index) =>
                                            // TODO: Work AssignmentCard into this as well
                                            <AssignmentCard
                                                assignment={assignment}
                                                colorKey={colorKey}
                                                pastDue={true}
                                                index={index} key={uuidv4()}
                                            />
                                        )
                                        :
                                        <p className={`${classes.upcomingItem} ${classes.noUpcomingItem}`}>No past due assignments to show</p>
                            }
                        </div>
                    </div> :
                    <SkeletonMyCourse />
            }
        </Card>

    )
}
export default MyCourse; 