import { Checkbox, useMediaQuery, useTheme } from '@mui/material'
import { useFormikContext } from "formik"
import React from "react"
import { AppDataContext } from "../../../context"
import useEditProfileStyles from "../../../sharedComponents/editProfileFields/styles/editProfileStyles"
import { BooleanSelect, GrowingInput } from "./components"

const Identity = ({ isEditProfilePage }) => {
    let {
        values,
        setFieldValue,
        handleChange,
    } = useFormikContext();
    const [displayPronouns, setDisplayPronouns] = React.useState(null)
    const { userData } = React.useContext(AppDataContext)

    const handleDisplayPronouns = (e) => {
        setDisplayPronouns(e.target.value)
        if (e.target.value === false) {
            setFieldValue("user_preferred_pronouns", null)
        }
    }
    const handleDisplayPronounsCheckbox = (e) => {
        
        setDisplayPronouns(e.target.checked)
        if (e.target.checked === true) {
            setFieldValue("user_preferred_pronouns", null)
        }
    }
    const theme = useTheme()
    const editProfileClasses = useEditProfileStyles()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("500"));
    return (
        <>
            <p className={isEditProfilePage ? editProfileClasses.locationText : undefined}><span style={{ marginRight: '5px' }}>I prefer to be called</span>{isSmallScreen && !isEditProfilePage && <br />}<GrowingInput id="preferred_name" name="preferred_name" value={values?.preferred_name || ""} onChange={handleChange} placeholder={userData["preferred_name"] || ""} /></p>
            <p style={isEditProfilePage ? { marginRight: '5px' } : null} className={isEditProfilePage ? editProfileClasses.locationText : undefined}><span style={{ marginRight: '5px' }}>My name is pronounced</span>{isSmallScreen && !isEditProfilePage && <br />}<GrowingInput id="user_name_pronunciation" name="user_name_pronunciation" value={values?.user_name_pronunciation || ""} onChange={handleChange} placeholder={userData["preferred_name"] || userData["first_name"] || ""} /></p>
            <p style={isEditProfilePage ? { marginRight: '5px' } : null} className={isEditProfilePage ? editProfileClasses.locationText : undefined}><span style={{ marginRight: '5px' }}>My preferred pronouns are</span>{isSmallScreen && !isEditProfilePage && <br />}< GrowingInput disabled={displayPronouns === false} id="user_preferred_pronouns" name="user_preferred_pronouns" value={values?.user_preferred_pronouns ? values.user_preferred_pronouns : ""} onChange={handleChange} placeholder="he/him/his" /></p>
            {isEditProfilePage ? <> <Checkbox name={"user_preferred_pronouns"} id="user_preferred_pronouns" onChange={handleDisplayPronounsCheckbox} sx={{
                '&.Mui-checked': {
                    color: theme.colors.deepAqua
                },
            }} /> <span style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: '16px' }}>Don't show my pronouns</span></>
                :
                <BooleanSelect id="" name="" value={displayPronouns} onChange={handleDisplayPronouns}>Display your pronouns publicly?</BooleanSelect>
            }
        </>
    )
}

export default Identity