import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid4 } from 'uuid';

import EachSocialMediaLink from './EachSocialMediaLink';
import { ErrorContext } from '../context';

export const SocialLinks = ({isOnboarding}) => {

    let {
        values,
    } = useFormikContext();

    const [totalLinks, setTotalLinks] = useState(values?.user_social_media_links?.length || 1);
    const [validations, setValidations] = useState({})
    const {setIsValid} = React.useContext(ErrorContext)

    useEffect(() => {
        if (values.user_social_media_links
            && values.user_social_media_links.length > 0
            && values.user_social_media_links[values.user_social_media_links.length - 1].name.length > 0
            && values.user_social_media_links[values.user_social_media_links.length - 1].link.length > 0
            && values.user_social_media_links.length === totalLinks) {

            setTotalLinks(totalLinks + 1);
        }
    }, [values]);

    const getStroredValue = (indx) => {
        return values?.user_social_media_links
            && values.user_social_media_links
            && values.user_social_media_links.length > 0
            && values.user_social_media_links[indx] ?
            values.user_social_media_links[indx] :
            {
                name: "",
                link: "",
                id: uuid4(),
                newItem: true
            }
    }

    const removeLinkCount = (id) => {
        setValidations({})
        if(totalLinks > 1) {
            setTotalLinks(totalLinks - 1)
        }
    }

    const setValid = (key, isValid) => {
        setValidations({...validations, [key]: isValid})
    }

    useEffect(() => {
        for (let isValid of Object.values(validations)) {
            if(!isValid) {
                setIsValid(false)
                return
            }
        }
        setIsValid(true)
    }, [validations])

    return (
        <div style={{ width: "100%", maxWidth: "1000px" }}>
            {
                Array.from(new Array(totalLinks)).map((item, indx) => {
                    return <EachSocialMediaLink
                        key={getStroredValue(indx).id}
                        id={indx}
                        isValid={validations[indx]}
                        setValid={setValid}
                        setTotalLinks={setTotalLinks}
                        controlId={"user_social_media_links"}
                        storedValue={getStroredValue(indx)} 
                        removeTotalLinks={removeLinkCount}
                        isOnboarding={isOnboarding}
                    />
                })
            }
        </div>
    )
}