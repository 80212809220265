import React from 'react';
import { Stack, MenuItem } from "@mui/material";
import useStyles from './styles';

const RequestSupportMenu = ({
    setQuestionType=function(){}
}) => {

    const classes = useStyles();

    return (<Stack direction="column" spacing={2} style={{marginTop:"10px"}}>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("assessment")}>Stuck on an&nbsp;<b>assessment</b></MenuItem>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("activity")}>Stuck on an&nbsp;<b>activity</b></MenuItem>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("lesson")}>Don’t understand the&nbsp;<b>lesson</b></MenuItem>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("grades")}>It's about my&nbsp;<b>grades</b></MenuItem>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("personal")}>It’s&nbsp;<b>personal</b></MenuItem>
        <MenuItem  className={classes.option} onClick={() => setQuestionType("other")}>It's&nbsp;<b>something else</b></MenuItem>
    </Stack>)
}


export default RequestSupportMenu;