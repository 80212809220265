import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./helpers/history"
import { theme as themeObj } from "./theme";

require('dotenv').config({ path: "../.env" });

const theme = createTheme(themeObj);

const DOMAIN = process.env.REACT_APP_DOMAIN
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

// onRedirect appState solution found here https://github.com/sorenharfagri/serverless-aws-auction/blob/1e8f59f891531b677d2b0f8a8a162e07c44da305/frontend/src/index.js
const onRedirectCallback = (appState) => {

  history.push(
    appState && appState.targetCourse
      ? `/acp/listRequests/${appState.targetCourse}`
      :
      appState && appState.targetUrl ?
        appState.targetUrl
        : window.location.pathname
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();