import React,{useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CalendarForm from './CalendarForm';
import { useCohortSettingStyles } from '../styles';

const Calendar = ({ calendar }) => {

    const classes = useCohortSettingStyles();

    const [edit, setEdit] = useState(false);

    const { calendar_name, calendar_url, id } = calendar;

    return (
        <div>
            {
                !edit ?
                    <>
                        <div>
                            <div className={classes.label}>Calendar Name</div>
                            <div className={`lineHt-md text-normal ${classes.flexNameHeader}`}>
                                <div>{calendar_name}</div>
                                <IconButton data-testid={`calendar-edit-button-${id}`}
                                    onClick={()=>{
                                        setEdit(true);
                                    }}
                                    sx={{ marginLeft: "8px" }}
                                    id={`calendar-edit-button-${id}`}
                                    aria-label="edit" size="small">
                                    <EditIcon
                                        sx={{
                                            fontSize: "18px"
                                        }}
                                    />
                                </IconButton>

                            </div>
                        </div>
                        <div>
                            <div className={classes.label}>Calendar URL</div>
                            <div className='text-normal'>{calendar_url}</div>
                        </div>
                    </> :
                    <CalendarForm 
                        calendar={calendar}
                        handleOnClose={()=>{setEdit(false)}}/>
            }

        </div>
    )
};

export default Calendar;