import React, { useContext, useEffect, useState } from 'react';

import { Card } from "../../../components";

import useStyles from './styles';
import SkeletonSupportTeam from './SupportTeamSkeleton';

import { LearnersDataContext } from '../../../context';
import RequestSupportForm from './RequestSupportForm';
import SupportTeamList from './SupportTeamList';

//React Memo - prevents unneccessary rendering. 
//It memorizes the renders and will change only if the any of props
const SupportTeams = React.memo(() => {
    
    const classes = useStyles();
    const {userCourse,fetchInstructionalTeam, checkDashboardDataState} = useContext(LearnersDataContext);
    const [supportTeam, setSupportTeam] = useState(null);
    
    useEffect(()=>{
        return(()=>{
            setSupportTeam(null);
        })
    },[])

    useEffect(async()=>{
        
        if(userCourse && userCourse?.course_id){
            //Aborts the async call if props change
            let controller= new AbortController();

            await fetchInstructionalTeam().then(data=>{
                setSupportTeam(data);

            }).catch(error=>{
                console.error(error);
            });

            return () => controller?.abort();//Aborts the async call if props change
            
        }      

    },[userCourse]);

    useEffect(()=>{
        if(supportTeam!==null) {
            checkDashboardDataState("supportTeam");
        }
    },[supportTeam])

    return (
        <Card title="Support" 
            classes={{content: classes.contentWrapper}} 
            theme="primary">
            {
              supportTeam && supportTeam.length===0 ?
                <div style={{padding:"10px"}}>There are no support team assigned for this course</div>:
              supportTeam && supportTeam.length>0 ?  
                <>
                    <SupportTeamList 
                        supportTeam={supportTeam} 
                    />
                    <RequestSupportForm />
                </>
                : 
                <SkeletonSupportTeam />
            }
        </Card>
    )
})
export default SupportTeams; 