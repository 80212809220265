import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({

    /** EACH ANNOUNCEMENT */
    announcementBlock: {
        padding: '25px 39px 9px 39px ',
        display: 'flex',
        alignItems: 'flex-start'
    },
    announcement: {
        border: `2px solid ${theme.ACP.announcementBorder}`,
        borderRadius: "10px",
        marginBottom: "15px",
        maxWidth: "1800px",
        padding: "15px",
        lineHeight: "150%",
        backgroundColor: 'white',

        flex: '50%',
        '& b': {
            fontWeight: 500
        }
    },
    details: {
        display: "grid",
        gridTemplateRows: "repeat(5, minmax(40px, auto))",
        gridTemplateColumns: "10fr 1fr",

        flex: '50%',
        marginLeft: '40px'

    },
    scheduled: {
        gridRow: "1",
        gridColumn: '1',

    },
    expires: {
        gridRow: "2",
        gridColumn: '1/2',


    },
    audience: {
        gridRow: '4',
        gridColumn: '1/2',



    },
    actions: {
        width: "100%",
        gridRow: "1",
        gridColumn: '2',
        display: "flex",
        justifyContent: "space-between"
    },
    created: {
        gridRow: "3",
        gridColumn: '1/2',



    },
    status: {
        gridRow: "5",
        gridColumn: '1/2'

    },
    secondaryInfo: {
        fontSize: "10px",
        paddingTop: "10px",
    },
    announcementInfo: {
        fontSize: "14px",
        color: '#2D2D2D'
    },
    queued: {
        color: theme.ACP.announcementBorder
    },
    active: {
        color: theme.ACP.activeColor
    },
    icon: {
        height: "16px",
        width: "16px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    menuPaper: {
        padding: "9px 13px",
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    program: {
        color: "#1D3969",
        fontSize: "14.5px",
        fontWeight: 600,
        fontFamily: "Nunito",
        margin: "10px 0px 5px 16px"
    },
    button: {
        height: '40px',
        width: '125px',
        borderRadius: '4px',
        backgroundColor: "#CF3B0C",
        color: "white",
        marginTop: "9px",
        '&:hover': {
            backgroundColor: "#CF3B0C",
        }
    },

    /** Announcement Create New Form */
    formHeaderContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    formHeader: {
        color: "#1D3969",
        fontFamily: "Nunito",
        fontWeight: 600,
        fontSize: "18px",
        marginBottom: "16px"
    },
    formHeaderSubtext: {
        fontFamily: "Nunito",
        color: "#414141",
        fontSize: "14px"
    },
    formHeaderText: {
        display: "flex",
        flexDirection: "column"
    },
    closeIcon: {
        marginTop: "-5px"
    },
    formControlSection: {
        margin: "25px 0px",
        '&#post_expiration': {
            marginTop: "-6px",
            display: "flex",
            flexDirection: "column",
            width: "315px",
        },
        '&#announcement-author-label': {
            marginTop: "-6px"
        },
        '&#when_to_post_radio': {
            marginBottom: "25px"
        },
        '&#schedule_post': {
            marginTop: "-6px",
            display: "flex",
            flexDirection: "column",
            width: "315px",
            marginBottom: "35px"
        },
    },
    formFooter: {
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
        margin: "35px 0px 10px 0px"
    },
    submitButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            ...theme.elements.darkBlueButton,
            borderRadius: "4px"
        }
    },
    textButton: {
        ...theme.elements.subtitle,
        color: `${theme.colors.deepBlue} !important`,
        textTransform: "capitalize !important",
        marginRight: "15px !important"
    },
    descriptionText: {
        fontWeight: '400',
        fontSize: "14px"
    }
}))
