import moment from 'moment-timezone';

export const capitalize = (str) => {
    if (!str) return null
    if (str.includes(" ")) {
        let splitStr = str.split(" ");
        let caps = [];
        for (let word of splitStr) {
            let cappedWord = word.charAt(0).toUpperCase() + word.slice(1);
            caps.push(cappedWord);
        }
        let role = caps.join(" ");
        return role
    }
    let toUpper = str.charAt(0).toUpperCase() + str.slice(1);
    return toUpper;
};


/**
 * @description - Loads script into the dom dynamically
 * @param {*} src 
 * @param {*} position 
 * @param {*} id 
 * @returns 
 */
export function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    // script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

/**
 * @descript compare the objects and just get the values of the props which exists in both objects
 * @param {Object, Object} Template Object defines the keys and src object supplies values for those keys
 */
export function matchObjectProps(templateObject, srcObject) {
    return Object.keys(templateObject).reduce((acc, key) => {
        if (Object.keys(srcObject).includes(key)) {
            acc[key] = srcObject[key]
        }
        return acc;
    }, {})
}

//Merges the courseInfo object from the response
export const mergeCourseDetails = (courses) => {
    return courses.reduce((acc, ci) => {
        let { courseInfo, ...course } = ci;
        let { id, created_date, ...rest } = courseInfo;
        acc.push(Object.assign(course, rest));
        return (acc);
    }, [])
}

/**
 * @description check is link is valid
 * @param {*} link 
 * @returns {Boolean}
 */
export const checkIfValidLink = (link) => {
    try {
        return (/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/).test(link); //eslint-disable-line  no-useless-escape
    } catch (error) {
        return false;
    }
}

/**
 * @description Gets UTC date for the current user
 * @returns UTC Date
 */
export const getUTCDate = () => {
    return new Date().toLocaleString('default', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: "numeric",
        minute: 'numeric',
    });
}

export const dateTimeFormatterUtc = (dateTimeStamp) => {
    let date = new Date(dateTimeStamp).toLocaleString('default', {
        month: 'short',
        day: '2-digit',
        // year: 'numeric',
        hour: 'numeric',
        minute: "numeric",
        timeZone: "UTC"
    });
    let formattedDate = date.replace(",", " |")

    return formattedDate === "Invalid Date" ? 'loading activity' : formattedDate;
};

export const dateTimeFormatter = (dateTimeStamp) => {
    let tz = moment.tz.guess();
    let date = new Date(dateTimeStamp).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        // year: 'numeric',
        hour: 'numeric',
        minute: "numeric",
        timeZone: tz
    });
    let formattedDate = date.replace(",", " |")

    return formattedDate === "Invalid Date" ? 'loading activity' : formattedDate;
};

export const dateTimeFormatDefault = (dateTimeStamp) => {
    let date = new Date(dateTimeStamp).toLocaleString();

    return date;
};

export const convertUTCDateTimeToUsersTimeZone = (datevalue) => {
    //1. Get users time zone using Intl.DateTimeFormat Or you can use (moment.tz.guess()) which will be converted to EST | PST etc 
    //2. Get UTC Offset from getTimezoneOffset()
    //3. Add UTC Offset to datevalue, gives us current time of the user 
    //4. Get the timezone abbr from moment tz

    let userTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzAbbr = moment.tz.zone(userTimeZoneName).abbr(moment(datevalue).format("X"));
    //let userTimeInZone=moment(datevalue).add(td.getTimezoneOffset(),'minutes');
    let userTimeInZone = moment(datevalue);

    return {
        "timeZoneAbbr": tzAbbr,
        "timeZoneName": userTimeZoneName,
        "timeInZone": userTimeInZone,
        "timeInZoneInString": userTimeInZone.format()
    }

}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
 * @param {*} urlString 
 * @param {*} searchParam 
 * @returns 
 */
export const getUrlSearchParam = (urlString = "", searchParam = "") => {
    try {

        let url = new URL(urlString);

        let urlSearchParam = new URLSearchParams(url.search);

        return urlSearchParam.get(searchParam);

    } catch (error) {
        throw error;
    }

}

/**
 * 
 * @param {*} parentEl 
 * @param {*} callback 
 * @returns 
 */
export function hideOffFocusEventHandler(parentEl, callback) {

    try {

        document.addEventListener('mousedown', function (e) {

            let childNodes = parentEl.children;
            let clickedNode = e.target;

            let nodeExists = false;

            const compareNode = (nodes) => {

                for (let i = 0; i < nodes.length; i++) {

                    if (nodeExists) break;

                    nodeExists = nodes[i].isEqualNode(clickedNode);

                    if (nodeExists) { break; }
                    else if (nodes[i].children) {
                        compareNode(nodes[i].children);
                    }
                }

            }

            compareNode(childNodes);
            //;
            if (!nodeExists) {
                callback();
            }
        })

    } catch (error) {
        console.error(error)
        return false;
    }
}

/**
 * @description gets display name based on user preferences
 * @param {*} user 
 * @returns {String} User's preferred display name
 */
export function getUserDisplayName(user) {

    if (!user || !user?.first_name) return "Unknown"

    return user.preferred_name ? user.preferred_name
        : user.given_name ? user.given_name
            : user.first_name
}

/** Detects which browser user is using */
export function browserDetector() {

    let userAgent = navigator.userAgent;
    let browserName = null;

    try {
        if (userAgent.match(/android/i) && userAgent.match(/chrome/i)) {
            browserName = "chrome_android";

        } else if (userAgent.match(/chrome|chromium/i)) {
            browserName = "chrome_desktop";

        } else if (userAgent.match(/crios/i)) {
            browserName = "chrome_ios";

        } else if (userAgent.match(/android/i) && userAgent.match(/firefox/i)) {
            browserName = "firefox_andriod";

        } else if (userAgent.match(/fxios/i)) {
            browserName = "firefox_ios";

        } else if (userAgent.match(/mozilla/i) && userAgent.match(/firefox/i)) {
            browserName = "firefox_desktop";

        } else if (userAgent.match(/safari/i)) {
            browserName = "safari";

        } else if (userAgent.match(/opr\//i)) {
            browserName = "opera";

        } else if (userAgent.match(/android/i) && userAgent.match(/edga/i)) {
            browserName = "edge_andriod";

        } else if (userAgent.match(/edgios/i)) {
            browserName = "edge_ios";

        } else if (userAgent.match(/edg/i)) {
            browserName = "edge_desktop";

        } else {
            throw new Error("No browser detection");
        }
        return browserName;
    } catch (error) {
        console.error(error);
        return browserName;
    }
}

// Found from this Stack Overflow answer https://stackoverflow.com/a/56490104
// Returns string of timezone name from the browser
// There are several ways to do this, others return the closest city and we need the name of the timezone to match the canvas implementation
export function getTimezoneName() {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });
  
    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      
      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
}