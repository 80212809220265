
import React, { useContext } from 'react';
import { ACPAnnouncementContext } from '../../context';

import RadioButtons from '../../components/RadioButtons';



export default function SortRequests() {
    const {
        setSortDirection
    } = useContext(ACPAnnouncementContext);

    const handleOnChange = (e) => {
        if (e.target.value === "newest") {
            setSortDirection("newest")
        } else {
            setSortDirection("oldest")
        }
    }

    return (
        <div className='subItems'>
            <RadioButtons groupProps={{
                'aria-labelledby': "sort by",
                defaultValue: "newest",
                name: "radio-buttons-group",
                onChange: (e) => handleOnChange(e)
            }}

                buttons={[{ value: 'newest', label: 'Most Recent' }, { value: 'oldest', label: 'Oldest' }]}

            />

        </div>
    )
}

