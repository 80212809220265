import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";

import { AppDataContext } from "../../context";
import { filterForCourses } from "../../helpers/userCourseHelper";
import { LearnersDataContext } from './LearnersDataContext';

import { getAdvisorsByStudentEmail, getAnnouncements, getCalendarEventRecordings, getCalendarInfoByCohortId, getCanvasAssignmentsMK, getCohortCalendarEvents, getCohortConnectionData, getInstructorsByCohortId, getVideoInfo } from '../../api';

import { excludeInstructorsFromDisplay } from '../../acp/pages/cohortSettings/staff/helpers';

import ProfilePopup from "../pages/baseLayout/ProfilePopup";
import { getMeetingIdFromUrl } from "../pages/dashboard/events/helpers";

export const LearnersDataStore = ({ children }) => {

    const { userData, setIsAllComponentsLoaded } = useContext(AppDataContext);

    const { user } = useAuth0();

    const [userCourses, setUserCourses] = useState([]);
    const [userCourse, setUserCourse] = useState({});
    const [userCohorts, setUserCohorts] = useState([])
    const [userCohort, setUserCohort] = useState({})
    const [helpOpen, setHelpOpen] = useState(false)

    /**
     * Ref Variable storing the dashabord data
     */
    let learnerDashboardDataRef = useRef({
        webTools: null,
        supportTeam: null,
        canvasData: null,
        courseLaunchPadData: null,
        calendarEvents: null,
        calendarEventRecordings: null,
    });

    let cohortConnectionData = useRef(null);
    let cohortVideos = useRef(null);

    useEffect(() => {
        const cohorts = userData?.mk_user_courses.sort((a, b) => a.cohort_id - b.cohort_id)
        const courses = filterForCourses(userData)
        setUserCohorts(cohorts)
        setUserCohort(cohorts[0])

        setUserCourses(courses)
        setUserCourse(courses[0])

        // setUserCourse(userCourses[0])
    }, [userData]);
    const checkDashboardDataState = (key) => {

        if (userCourse && userCourse?.course && userCourse.course?.prestart) {
            delete learnerDashboardDataRef.current.canvasData;
        } else {
            delete learnerDashboardDataRef.current.courseLaunchPadData;
        }

        if (Object.values(learnerDashboardDataRef.current).every(i => i !== null)) {

            setIsAllComponentsLoaded(true);
        }
    }

    const updateLearnerDashboardRefVariable = (key, value) => {
        learnerDashboardDataRef.current[key] = value;
    }

    /**
     * @description - Get Announcements from database. filtered = true.
     * @param {*} refresh - refresh = true, get Announcements again from database.
     * @returns 
     */
    const fetchAnnouncements = async (refresh = false) => {
        try {

            let cohortId = userCourse.cohort_id;

            if (learnerDashboardDataRef.current.announcements === null || refresh) {
                let res = await getAnnouncements({
                    "filtered": true,
                    "expand": 'cohorts',
                    "audience": 1,
                    "cohort_id": cohortId
                });
                learnerDashboardDataRef.current.announcements = res ? res : false;

                return learnerDashboardDataRef.current.announcements;

            } else {
                return learnerDashboardDataRef.current.announcements;
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * @description Get the SupportTeam per the courseId of the user and store the information in ref the variable
     * @returns SupportTeam Array
     */
    const fetchInstructionalTeam = async (refresh = false) => {

        try {

            //Checks if supportTeam exists. If yes, return the array
            if (learnerDashboardDataRef.current.supportTeam === null || refresh) {

                let cohortId = userCourse.cohort_id;
                let instructionalTeamData = await getInstructorsByCohortId(cohortId);
                let instructionalTeam = null;

                if (instructionalTeamData) {

                    //Exclude instructors which are not suppose to be seen on the screen
                    //display order is added a prop to an object from helpers/userRoles.js for sorting
                    instructionalTeamData = excludeInstructorsFromDisplay(instructionalTeamData, "isVisibleInSupportPanel");
                    instructionalTeamData.sort((a, b) => {
                        return a.display_order > b.display_order ? 1 : -1
                    });

                    learnerDashboardDataRef.current.supportTeam = instructionalTeamData ? [...instructionalTeamData] : [];
                    instructionalTeam = instructionalTeamData;

                }

                let student_email = userData.username;
                let studentAdvisorsData = await fetchStudentAdvisors(student_email);
                let studentAdvisors = null;

                if (studentAdvisorsData && studentAdvisorsData?.message?.advisors.length > 0) {
                    studentAdvisors = studentAdvisorsData.message.advisors;
                    // console.log("student_advisors", studentAdvisors);
                }

                if (studentAdvisors?.length > 0 && instructionalTeam.length > 0) {
                    let supportTeam = studentAdvisors.concat(instructionalTeam);

                    learnerDashboardDataRef.current.supportTeam = supportTeam ? [...supportTeam] : [];
                    // console.log("entire_support_team: ",learnerDashboardDataRef.current.supportTeam)
                } else if (studentAdvisors?.length > 0) {
                    learnerDashboardDataRef.current.supportTeam = studentAdvisors
                }
                return learnerDashboardDataRef.current.supportTeam
            } else {
                return learnerDashboardDataRef.current.supportTeam;
            }

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * @description Get the CanvasData per the courseId of the user and store the information in ref the variable
     * @returns CanvasData Array
     */
    const fetchCanvasData = async (courseId, refresh = false) => {

        try {

            //Checks if supportTeam exists. If yes, return the array
            let checkIfExists = learnerDashboardDataRef.current.canvasData?.find(i => i.course_id === courseId);

            if (learnerDashboardDataRef.current.canvasData === null || refresh || !checkIfExists) {

                learnerDashboardDataRef.current.canvasData = learnerDashboardDataRef.current.canvasData === null ? [] : learnerDashboardDataRef.current.canvasData;

                let data = await getCanvasAssignmentsMK(courseId, user.email);

                if (data) {
                    let dataset = {
                        course_id: courseId,
                        canvasData: data?.message ? data.message : []
                    };

                    learnerDashboardDataRef.current.canvasData.push(dataset);

                    return learnerDashboardDataRef.current.canvasData.find(i => i.course_id === courseId);
                }

            } else {
                return checkIfExists;
            }
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }

    }

    /**
     * @description: This implementation will change in near future
     * @returns course Object
     */
    const fetchLaunchpadData = () => {
        learnerDashboardDataRef.current.courseLaunchPadData = userCourse.course;
        return learnerDashboardDataRef.current.courseLaunchPadData;
    }

    /**
     * @description Get the Calendar Events per the user and store the information in ref the variable
     * @returns Calendar Events Array
     */
    const fetchCalendarEvents = async (refresh = false) => {
        try {

            //Checks if supportTeam exists. If yes, return the array
            if (learnerDashboardDataRef.current.calendarEvents === null || refresh) {

                let cohortId = userCourse?.cohort_id;

                let data = await getCohortCalendarEvents(cohortId);

                if (data) {
                    //Merging all the events from multiple calendars for the cohort
                    //Merging calendar name and id in each event
                    learnerDashboardDataRef.current.calendarEvents = data.reduce((acc, ci) => {
                        if (ci.calendar_events) {
                            acc = acc.concat(ci.calendar_events.map(v => {
                                return Object.assign(v, {
                                    "calendar_id": ci.calendar_id,
                                    "calendar_url": ci.calendar_url,
                                    "calendar_name": ci.calendar_name
                                })
                            }));
                        }
                        return acc;
                    }, []);

                    //fetch recording details 
                    await fetchCalendarEventRecordings();

                    return learnerDashboardDataRef.current.calendarEvents
                }

            } else {
                return learnerDashboardDataRef.current.calendarEvents
            }

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    //** Fetching recording information for the event */
    //** We are sending multiple meetingIds, i.e., last digits in meeting_url in the google calendar events table  */
    const fetchCalendarEventRecordings = async (refresh = false) => {
        try {

            //Checks if supportTeam exists. If yes, return the array
            if (learnerDashboardDataRef.current.calendarEventRecordings === null || refresh) {

                //Grab meeting Ids from the events and send to server to get the recordings attached to these ids
                let meetingIds = learnerDashboardDataRef.current.calendarEvents.reduce((acc, ci) => {
                    if (ci.meeting_url !== null) {
                        let meetingId = getMeetingIdFromUrl(ci.meeting_url);
                        if (meetingId && !acc.includes(meetingId) && meetingId.length > 0) acc.push(meetingId)
                    }
                    return acc;
                }, []);

                let recordings = [];

                //If there are meetingIds grab recordings attached to them
                //We can send multiple meeting ids in the request
                if (meetingIds.length > 0) {
                    recordings = await getCalendarEventRecordings(meetingIds.join(','));
                }

                //load the recordings in the ref variable after filteration
                learnerDashboardDataRef.current.calendarEventRecordings = recordings;//Once we decide to use the video hosted in mykenzie to be linked to events you uncomment this line and comment/remove one below
                // learnerDashboardDataRef.current.calendarEventRecordings = await filterRecordingsByDate(learnerDashboardDataRef.current.calendarEvents, recordings);

                return learnerDashboardDataRef.current.calendarEventRecordings

            } else {
                return learnerDashboardDataRef.current.calendarEventRecordings
            }

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }




    /**
     * @description - fetches and saves the data in cache for Cohort connection page
     * @param {*} refresh 
     * @returns {Array} cohort users
     */
    const fetchCohortConnectionData = async (refresh = false, includeAdmin = false) => {
        try {
            let cohort_id = userCourse.cohort_id;

            //Checks if supportTeam exists. If yes, return the array
            if (cohortConnectionData.current === null || refresh) {

                cohortConnectionData.current = await getCohortConnectionData(cohort_id, includeAdmin);
                return cohortConnectionData.current;

            } else {
                return cohortConnectionData.current;
            }

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    const fetchGoogleCalendarIdsByCohortId = async (cancelSignal = null) => {


        //mk_videos table holds google cal IDs for each recording
        //find which google cals learner has access to to determine recordings to show
        let calendars = []

        if (!userCohorts || !userCohorts.length) return []
        for (let cohort of userCohorts) {
            const cal = await getCalendarInfoByCohortId(cohort.cohort_id, cancelSignal)
            calendars = [...calendars, ...cal]
        }

        return calendars.map(({ cohort_id, google_calendar_id }) => { return ({ cohort_id, google_calendar_id }) })

    }

    const fetchCohortVideos = async (refresh = false, query = null, cancelSignal = null) => {

        try {

            //Checks if supportTeam exists. If yes, return the array
            if (cohortVideos.current === null || refresh) {

                cohortVideos.current = await getVideoInfo({
                    ...query,
                    limit: 10000,
                }, cancelSignal);

                return cohortVideos.current;

            } else {
                return cohortVideos.current;
            }

        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    const fetchStudentAdvisors = async (student_email, refresh = false) => {
        try {

            let data = await getAdvisorsByStudentEmail(student_email);

            if (data) {
                return data
            }

        } catch (error) {
            throw new Error(error);
        }
    }

    return (
        <LearnersDataContext.Provider value={{
            "userCourses": userCourses,
            "userCourse": userCourse,
            "userCohorts": userCohorts,
            "userCohort": userCohort,
            "helpOpen": helpOpen,
            "setHelpOpen": setHelpOpen,
            "updateLearnerDashboardRefVariable": updateLearnerDashboardRefVariable,
            "fetchInstructionalTeam": fetchInstructionalTeam,
            "fetchCanvasData": fetchCanvasData,
            "fetchCalendarEvents": fetchCalendarEvents,
            "fetchCalendarEventRecordings": fetchCalendarEventRecordings,
            "fetchLaunchpadData": fetchLaunchpadData,
            "fetchAnnouncements": fetchAnnouncements,
            "checkDashboardDataState": checkDashboardDataState,
            "fetchCohortConnectionData": fetchCohortConnectionData,
            "fetchCohortVideos": fetchCohortVideos,
            "fetchGoogleCalendarIdsByCohortId": fetchGoogleCalendarIdsByCohortId
        }}>
            {children}
            <ProfilePopup />
        </LearnersDataContext.Provider>
    )
}