import { makeStyles } from "@mui/styles";

const usePlayerStyles = makeStyles((theme) => ({

    /** Container After Info Pane on Left */
    videoContainer: {

        position: "relative",

        [theme.breakpoints.up('md')]: {
            width: "100%",
            height: "70vh"
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
            height: "100%"
        },

        '&:fullscreen': {
            height: "100%",
            width: "100%"
        },

        "& video": {
            borderRadius: "10px",
            height: "100%",
            width: '100%',
            bottom: 0,
            backgroundColor: 'black'
        },


        "& .video-media-controls": {
            // display: "none",
            backgroundColor: "black",
            position: "absolute",
            bottom: "10px",
            left: 0,
            right: 0,
            margin: "5px 10px",
            borderRadius: "5px",
            zIndex: 1,
            fontSize: "12px !important",
            transition: 'display 0.5s',

            "& .video-media-inner-controls-bg": {
                opacity: "0.3",
                borderRadius: "5px",
                backgroundColor: "gray",
                width: "100%",
                height: "75px"
            },

            "& .video-media-inner-controls": {
                position: "absolute",
                backgroundColor: "transparent",
                top: 0,
                left: "0px",
                right: "0px",
                height: "75px",

                "& .video-media-controls-container": {

                    color: "white",

                    '& .video-media-progress-bar': {

                        padding: "0 14px",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: "15px",

                        '& .MuiSlider-root': {
                            padding: "10px 0px 8px 0px",
                            height: "3px !important"
                        }
                    },

                    '& .video-marker-container': {

                        top: "5px",
                        left: "14px",
                        right: "14px",
                        position: "absolute",

                        '& .video-marker': {
                            width: "5px",
                            height: "12px",
                            borderRadius: "100px",
                            backgroundColor: "#FFE299",
                            position: "absolute",
                            top: "0px",
                            cursor: "pointer",
                            zIndex: 1,
                            '&:hover .video-marker-hover-popup': {
                                [theme.breakpoints.up('md')]: {
                                    display: "block"
                                }
                            },

                            '& .video-marker-hover-popup': {
                                bottom: "20px",
                                left: "-8px",
                                padding: "8px",
                                color: "black",
                                width: "300px",
                                minHeight: "50px",
                                display: "none",
                                position: "absolute",
                                borderRadius: "5px",
                                // textAlign: "center",
                                lineHeight: '25px',
                                fontWeight: 700,
                                border: "2px solid #FFB800",
                                backgroundColor: "white"
                            }
                        },
                    },

                    '& .video-media-controls-inner-container': {
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        padding: "5px 10px 5px 5px",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: "35px",

                        '& .MuiSvgIcon-root': {
                            cursor: "pointer"
                        },

                        '& .video-media-controls-left': {
                            display: "flex",
                            gridColumnGap: "1em",
                            flexDirection: 'row',
                            alignItems: "center"

                        },

                        '& .video-media-controls-right': {

                            display: "flex",
                            gridColumnGap: "1em",
                            flexDirection: 'row',
                            alignItems: "center",

                            '& .playback-speed-container': {
                                position: 'relative',

                                '& .playback-speed-popup': {
                                    display: 'none'
                                },

                                '&:hover .playback-speed-popup': {
                                    display: "block",
                                    position: "absolute",
                                    bottom: "10px",
                                    backgroundColor: "white",
                                    width: "150px",
                                    minHeight: "50px",
                                    right: "0px",
                                    maxHeight: "150px",
                                    overflowY: "auto",
                                    borderRadius: "5px",
                                    border: "1px solid gray",

                                    '& .playback-speeds': {
                                        padding: "5px 10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        color: "black",
                                        cursor: "pointer",
                                        '&:hover': {
                                            backgroundColor: "whitesmoke"
                                        }
                                    },

                                }
                            }

                        }

                    }
                }
            }
        },

        "& #jumbo-play-button": {
            position: 'absolute',
            cursor: "pointer",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            backgroundColor: "white",
            transform: 'translate(-50%,-50%)',
            transition: "opacity 0.5s",
            textAlign: "center",
            [theme.breakpoints.up('md')]: {
                width: "100px",
                height: "100px",
                lineHeight: "170px",
                opacity: 0
            },
            [theme.breakpoints.down('md')]: {
                width: "50px",
                height: "50px",
                lineHeight: "70px",
                display: "none"
            },
            '& svg': {
                color: "#14606B !important",
                [theme.breakpoints.up('md')]: {
                    fontSize: "5.5em !important"
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: "2em !important"
                },

            }
        },

        '& #mobile-screen-forward-back-10-container': {

            '& .mobile-forward-back-container': {
                position: "absolute",
                width: "30%",
                bottom: 0,
                opacity: 0,
                top: 0,
                backgroundColor: "black",
                transition: "opacity 1s",
                color: "white"
            },

            '& #mobile-screen-back-10-container': {
                left: 0,
                // borderTopRightRadius: "80%",
                // borderBottomRightRadius: "80%"
            },
            '& #mobile-screen-forward-10-container': {
                right: 0,
                // borderTopLeftRadius: "80%",
                // borderBottomLeftRadius: "80%"
            },

            '& .forward-back-icon': {
                left: "50%",
                top: "50%",
                position: "absolute",
                transform: "translate(-50%,-50%)"
            }
        }

    },


}));

export default usePlayerStyles;