import React, { useContext } from 'react';

import DreamJobIcon from '../../icons/profile-dream-job.png';
import ProgrammingExperienceIcon from '../../icons/profile-programming-experience.png';
import useStyles from './styles';

import { UserProfileContext } from './UserProfileContext';

const UserLearnerAdditionalHeaders = () => {

    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    return (
        <>
            {
                (profileData?.user_programming_experience_level?.length > 0 &&
                    profileData.user_programming_experience_level !== 'Do not prefer') &&
                <div className={classes.topItems}>

                    <img id="user-profile-experience-level-icon-image" src={ProgrammingExperienceIcon} alt="" />
                    <p>My programming experience level is <span className={classes.subHeader}>{profileData.user_programming_experience_level}</span>
                    </p>
                </div>
            }
            {
                profileData?.user_dream_job?.length > 0 &&
                <div className={classes.topItems}>
                    <img id="user-profile-dream-job-icon-image" src={DreamJobIcon} alt="" />
                    <p>My dream job is <span className={classes.subHeader}>{profileData.user_dream_job}</span>
                    </p>
                </div>
            }

        </>

    )
}

export default UserLearnerAdditionalHeaders;