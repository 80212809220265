import * as React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import UserAvatar from "./UserAvatar"
import useHeaderStyles from "./headerStyle";

const MobileStudentInfo = () => {
    const classes = useHeaderStyles();
    const {user} = useAuth0()
    return (
        <div className={classes.avatarWrapper}>
            <UserAvatar />
            <div>
                {user &&
                    <>
                        <p className={classes.userName}>{`${user.given_name} ${user.family_name}`}</p>
                        <p className={classes.userEmail}>{user.email}</p>
                    </>
                }
            </div>
        </div>
    )
}

export default MobileStudentInfo