import React, { useContext, useState } from 'react';
import { AvatarGroup } from "@mui/material";
import { v4 as uuid4 } from 'uuid';

import { MediaContext } from "..";
import ParticipantAvatar from './ParticipantAvatar';
import ParticipantsModal from './ParticipantsModal';

const Participants = () => {

    const {videoInfo}=useContext(MediaContext);
    const [showModal,setShowModal]=useState(false);

    return (
        <div id="meeting-participants-container" >
            <div 
                className='pointer'
                onClick={()=>{
                    setShowModal(true);
                }}>
                <AvatarGroup sx={{
                    '& .MuiAvatar-root': { width: 32, height: 32, fontSize: 12, color: "#14606B", border: "2.5px solid #14606B" },
                }} max={8}>
                    {
                        videoInfo.meeting_participants && videoInfo.meeting_participants.length>0 
                            && videoInfo.meeting_participants.map(participant => (
                            <ParticipantAvatar key={uuid4()} participant={participant} />
                        ))
                    }
                </AvatarGroup>
            </div>
            
            {
                showModal ?
                <ParticipantsModal 
                    onCloseCallback={setShowModal} />:
                null
            }
            
        </div>
    )
};

export default Participants;