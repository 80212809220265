import styled from "@emotion/styled";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from "react";

export default function TopicPillInput({ topics, handleAddNewTopic, handleDeleteTopic }) {
    const [inputVal, setInputVal] = useState('')
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputVal !== '') {
            // setShownTopics([...shownTopics, inputVal])
            handleAddNewTopic(inputVal.toLowerCase())
            setInputVal('')
        }
    }
    const handleInputChange = e => {
        setInputVal(e.target.value)
    }

    return (
        <Container>
            {topics?.slice(0, 3).map((topic, i) => (
                <TopicPill key={i}>{topic}
                    <HighlightOffIcon
                        sx={{ fontSize: '14px', marginLeft: '2px', marginTop: '2px', cursor: 'pointer' }}
                        onClick={() => handleDeleteTopic(i)}
                    /></TopicPill>
            ))}
            <Input
                onKeyDown={handleKeyDown}
                value={inputVal}
                onChange={handleInputChange}

                placeholder='Start typing and press Enter to create...'>
            </Input>
        </Container>
    )
}
const Container = styled.div`
border: 1px solid #767676;
border-radius: 5px;
box-sizing: border-box;
padding: 5px 0 10px 5px;
display: flex;
align-items: center;
justify-content: flex-start;
`
const Input = styled.input`
box-sizing: border-box;
margin-top: 2px;
border: 0;
outline: none;
height: 100%;
width: 100%;
margin-left: 2px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 158.9%;
&::placeholder{
    font-family: 'Nunito';
font-style: italic;
font-weight: 400;
font-size: 14px;
}
`

const TopicPill = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
background: #FFFFFF;
border: 1px solid #C7C7C7;
border-radius: 125px;

padding: 4px 12px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex: 2;
margin-right: 5px;
`