import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';

import { CalendarEventsContext } from '.';
import EachEvent from './EachEvent';
import { fetchRecordingsForTodaysEndedSessions, getEventsByDateValue } from './helpers';
import NoEventScheduled from './NoEventScheduled';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import moment from 'moment';

const TODAYS_ENDED_SESSION_RECORDING_SET_TIME = 60000;//Every 1 mins.
let todaysEndedSessionRecordingsTimer = null;

/** Component displays Events for Each Day */
export default function EachDayEvents({
    indx,
    tabName,
    isExpandAll,
    eventDate,
    propogateExpansionChange
}) {

    const classes = useStyles();

    const { events, eventRecordings, setEventRecordings } = useContext(CalendarEventsContext);

    const [dayEvents, setDayEvents] = useState(null);

    const dayEventsRef=useRef(null);
    const eventRecordingsRef=useRef(null);

    //For todays tab all events are expanded by default 
    //For Todays tab no expand collapse button available 
    const [expand, setExpand] = useState(indx === 0 || tabName === "Today" ? true : false);

    //On Mount
    useEffect(() => {
        //If its todays event and if event has ended check for recording state. 
        //Only todays session will have delay in obtaining recordings
        //Every TODAYS_ENDED_SESSION_RECORDING_SET_TIME mins the setTimeout will run and grab the meetingIds for the ended today session and grab the recording state only for the events which dont have recordings available 
        if(tabName === "Today"){
            todaysEndedSessionRecordingsTimer = setInterval(getTodaysEventRecordings, TODAYS_ENDED_SESSION_RECORDING_SET_TIME);
        }

        return (() => {
            clearInterval(todaysEndedSessionRecordingsTimer);
        });

    }, []);

    useEffect(()=>{
        eventRecordingsRef.current=eventRecordings;
    },[eventRecordings])

    //Set the dayEvents for the each day
    useEffect(() => {
        //Get events by eventDate
       
        let dEvents=getEventsByDateValue(eventDate, events);

        //Sort events in asc order
        dEvents.sort((a,b)=>{
            return moment(a.start_date_time).isSameOrAfter(moment(b.start_date_time))?1:-1
        })

        dayEventsRef.current=dEvents;

        setDayEvents(dEvents);

    }, [eventDate, events]);

    //IF expand all
    useEffect(() => {
        if (isExpandAll) {
            setExpand(true);

        } else if (isExpandAll !== null && isExpandAll === false) {
            setExpand(false);
        }
    }, [isExpandAll]);

    //Only trigger the propogation if the tabname is not today
    useEffect(() => {
        if (tabName !== "Today" && dayEvents !== null) {
            //If the expanded option is changed 
            //1. If expand = true, set "expand", value in propogation 
            //2. If expand = false, check if there are any dayEvents, if no dayEvents, that container is consider to be expanded by defaul so send expansion
            propogateExpansionChange(indx, dayEvents.length === 0 ? "empty" : expand || expand === null ? "expand" : "collapse");
        }
    }, [expand, dayEvents]);

    //Handler  expansionfor click event
    const handleExpansion = () => {
        if (dayEvents.length > 0 && tabName !== "Today") {
            setExpand(!expand);
        }
    }

    //Get the recordings for todays ended sessions which are ended and dont have recordings
    const getTodaysEventRecordings = async () => {
        try {

            if(dayEventsRef.current.length>0 && eventRecordingsRef.current !==null){
                
                //;
                let recordings = await fetchRecordingsForTodaysEndedSessions(dayEventsRef.current, eventRecordingsRef.current);
            
                //set the state only if anythign returned
                if(recordings!==null){

                    let d=[...eventRecordingsRef.current];
                    d=d.concat(recordings);
        
                    //;
                    setEventRecordings(d);//updating the eventRecording content valriable at index.js level
        
                }
            }
            
        } catch (error) {
            console.error(error);
            throw new Error();
        }

    }

    return (
        <>
            {
                dayEvents && dayEvents.length > 0 ?
                    <div data-testid={eventDate.format('YYYY-MM-DD')}
                        data-sqldate={eventDate.format('YYYY-MM-DD')}
                        className='event-grouped-date-container'>

                        <div
                            className={`event-grouped-date-container-header ${dayEvents.length > 0 && tabName !== "Today" ? 'pointer' : ''}`}
                            onClick={() => { handleExpansion() }}
                        >
                            <div className={classes.dateHeader}>{eventDate.format('dddd, MMM DD')}</div>
                            {
                                tabName !== "Today" &&
                                <div style={{ display: dayEvents.length > 0 ? 'block' : 'none' }}>
                                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                            }

                        </div>
                        <div className="day-events-outer-container" >
                            {
                                dayEvents.length > 0 ?
                                    dayEvents.map(eventItem => {
                                        return <Collapse in={expand} key={eventItem.id}>
                                            <EachEvent
                                                eventItem={eventItem}
                                            />
                                        </Collapse>

                                    }) :
                                    <NoEventScheduled />
                            }
                        </div>

                    </div> :
                    null
            }
        </>

    )
}