import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import React, { useContext, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { useCohortSettingStyles } from '../styles';

import { WebtoolsDataContext } from '.';
import { getWebtoolsByCohortId, manageCohortWebtools } from '../../../../api';

import { CohortSettingsContext } from '..';
import { checkIfWebtoolExists } from './helpers';

const WEBTOOLS_FIELDS = {
    "webtools_id": null,
    "is_active": true,
    "cohort_id": null,
    "course_id": null
}

const EditWebtoolsForm = ({
    webtools = [],
    handleOnClose = function () { },
    cohortId = null,
    courseId = null
}) => {

    const cohortSettingClasses=useCohortSettingStyles();

    const { allWebtools, updateCohortWebtoolsState } = useContext(WebtoolsDataContext);

    let [isSubmitting, setIsSubmitting] = useState(false);

    const { 
        setSubmissionSuccessfull,
        setSubmissionError,
        setSubmissionErrorMessage
    }=useContext(CohortSettingsContext)

    const [webtoolsValues, setWebtoolValues] = useState(webtools);

    //On Role change
    const handleOnCheckBoxChange = (event, isChecked) => {

        let d = [...webtoolsValues];

        let value = parseInt(event.target.value);

        let indx = d.findIndex(i => i.webtools_id === value);

        if (isChecked) {
            if (indx > -1) {//the webtool exist and is inactive state 
                d[indx].is_active = true;

            } else {
                d.push(Object.assign({ ...WEBTOOLS_FIELDS }, {
                    webtools_id: value,
                    is_active: true,
                    cohort_id: cohortId,
                    course_id: courseId
                }));
            }
        } else {

            //If data is not already stored in database, remove it from the state variable so new entry is not entered in db for new inactive webtools
            if (d[indx].id) {
                d[indx].is_active = false;

            } else {
                d.splice(indx, 1)
            }

        }

        //;
        setWebtoolValues(d);

    }

    //Triggered when cave button is clicked
    const handleSubmission = async () => {
        try {

            setIsSubmitting(true);

            let d=[...webtoolsValues];
            
            //remove webtools prop on post request
            d=d.map(v=>{
                let {webtool,...rest}=v;
                return rest;
            });

            //;

            //Send updates to server 
            await manageCohortWebtools(cohortId, d);

            //Get all the saved information from server again
            let response=await getWebtoolsByCohortId(cohortId);

            updateCohortWebtoolsState(response);
            setWebtoolValues(response);

            handleOnClose();

            setIsSubmitting(false);
            setSubmissionSuccessfull(true);

        } catch (error) {
            console.error(error);
            if (error.message === "roles not entered") {
                setSubmissionErrorMessage("Please select all roles");
            } else {
                setSubmissionErrorMessage("There was an error submitting info");
            }
            setIsSubmitting(false);
            setSubmissionError(true);
        }
    }

    return (
        <div>
            {
                allWebtools ?
                    allWebtools.map(item => {
                        return <div key={uuid4()}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleOnCheckBoxChange(e, e.target.checked)
                                    }}
                                    value={item.id}
                                    name="is_active"
                                    checked={checkIfWebtoolExists(webtoolsValues,item.id)} />}
                                label={item.name} />
                        </div>
                    }) :
                    null
            }

            {/* Form Buttons */}
            <div className={cohortSettingClasses.formFooter}>
                <div>
                    <Button
                        onClick={() => { handleOnClose() }}
                        variant="text"
                        className={cohortSettingClasses.textButton}>
                        Cancel
                    </Button>
                </div>
                <div className={cohortSettingClasses.submitButton}
                    id={`update-webtool-submit-button-${cohortId}`}
                    data-testid={`update-webtool-submit-button-${cohortId}`} >
                    <Button onClick={(e) => {
                        handleSubmission(e)
                    }} disabled={isSubmitting}>
                        {
                            isSubmitting?
                                <CircularProgress color="inherit" size={18} /> :
                                "Save Changes"
                        }
                    </Button>
                </div>

            </div>

        </div>)

}

export default EditWebtoolsForm;