import React, { createContext, useContext, useEffect, useState } from "react";

import { ACPDataContext } from "./ACPDataContext";
import useLocalStorage from "../../sharedComponents/hooks/useLocalStorage";
import { ErrorSnackBar, SuccessSnackBar } from '../../sharedComponents/snackBars';
import { useACPDataContextV2 } from "./ACPDataStoreV2";
import moment from "moment";
const initialData = []

export const ACPAnnouncementContext = createContext(initialData);



export const ACPAnnouncementStore = ({ children }) => {
    const initialFilters = {
        kenzie: true,
        myself: true
    }

    const { updateCohortData } = useContext(ACPDataContext);

    const { selectedCohortId, fetchEveryoneAnnouncements, fetchCohortAnnouncements } = useACPDataContextV2()
    const [announcementsList, setAnnouncementList] = useState(initialData);

    const [filteredAnnouncements, setFilteredAnnouncements] = useState(initialData);
    const [sortDirection, setSortDirection] = useState("newest");//show newest by default
    // const [filteredStartDate, setFilteredStartDate] = useLocalStorage('mykenzie-acp-announcement-startDate', null)
    // const [filteredEndDate, setFilteredEndDate] = useState('mykenzie-acp-announcement-endDate', null)
    const [filteredStartDate, setFilteredStartDate] = useState(null)
    const [filteredEndDate, setFilteredEndDate] = useState(null)

    //SnackBar States
    let [submissionSuccessfull, setSubmissionSuccessfull] = useState(false);
    let [submissionMessage, setSubmissionMessage] = useState("Your changes were saved successfully");
    let [submissionError, setSubmissionError] = useState(false);

    let [showCreateAnnouncementModal, setShowCreateAnnouncementModal] = useState(false);

    const [isLoading, setIsLoadging] = useState(true);
    const [filters, setFilters] = useLocalStorage('announcementfilters', initialFilters)



    /** Gets support request on cohort selection change */
    //** This side effect runs everytime cohort is selected/unselected from the drop down */
    useEffect(() => {
        const fetchAndSetAnnouncementsData = async () => {
            let result = []
            const [cohortAnnouncements, everyoneAnnouncements] = await Promise.all([fetchCohortAnnouncements(), fetchEveryoneAnnouncements()])
            if (cohortAnnouncements?.length) result = [...cohortAnnouncements]
            if (everyoneAnnouncements?.length) result = [...result, ...everyoneAnnouncements]
            setAnnouncementsData(result)
        }

        if (selectedCohortId) {


            fetchAndSetAnnouncementsData()


        }

    }, [selectedCohortId]);


    /**
     * @description - Filters the announcements by start and end dates
     */
    useEffect(() => {

        if (filteredStartDate) {
            const momentDate = filteredStartDate
            setFilteredAnnouncements(announcementsList.filter(a => moment(a.post_schedule).isAfter(momentDate)))
        }
        if (filteredEndDate) {
            const newEnd = filteredEndDate.add(1, 'days')
            setFilteredAnnouncements(announcementsList.filter(a => moment(a.post_schedule).isBefore(newEnd)))
        }

    }, [filteredStartDate, filteredEndDate])

    /**
     * @description - Whenever the sort direction or filtered annoucements are changed, this side effect is triggered
     */
    useEffect(() => {
        if (sortDirection.length > 0 && filteredAnnouncements.length > 0) {
            let sortedAnnouncements = sortAnnouncements([...filteredAnnouncements], sortDirection);
            setFilteredAnnouncements([...sortedAnnouncements]);
        }
    }, [sortDirection]);

    //** Triggered whenever filtered is changed */
    useEffect(() => {
        localStorage.setItem("announcementfilters", JSON.stringify(filters));

        //Here we are taking data from the announcementsList, which is list of unfiltered items
        let filteredData = filterAnnouncements([...announcementsList]);

        //sort announcements 
        let sortedAnnouncements = sortAnnouncements(filteredData, sortDirection);

        setFilteredAnnouncements([...sortedAnnouncements]);

    }, [filters]);

    /** UserEffect to clear the messges */
    useEffect(() => {
        if (!submissionSuccessfull) {
            setSubmissionMessage("Your changes were saved successfully")
        }
    }, [submissionSuccessfull])



    //** Sets the annoucement data on cohort id changes and also inital mount */
    const setAnnouncementsData = (dataset) => {
        try {
            //1. set the annoucement data before being filtered
            setAnnouncementList(dataset);

            //2. Filter the announcement data 
            let filteredData = filterAnnouncements([...dataset]);

            //3. sort the announcements 
            let sortedAnnouncements = sortAnnouncements(filteredData, sortDirection);

            //4. set the filtered state variable 
            setFilteredAnnouncements(sortedAnnouncements);

            setIsLoadging(false);//setting loader to false. 

        } catch (error) {
            console.error(error);
        }
    }

    const filterAnnouncements = (dataset) => {

        let filteredData = dataset.filter(announcement => {
            for (const [key, value] of Object.entries(filters)) {
                if (!announcement.post_as) {
                    return false
                }
                if (announcement.post_as === key && value) {
                    return true
                }
            }
            return false
        });

        return filteredData
    }


    /**
     * Remove announcement on deletion 
     */
    const removeAnnouncement = (announcementId) => {
        try {
            let d = [...filteredAnnouncements];
            let indx = d.findIndex(i => i.id === announcementId);

            d.splice(indx, 1);
            setFilteredAnnouncements(d);

            //remove ACPDataStore
            updateCohortData({
                key: "announcement",
                remove: true,
                itemId: announcementId
            });

        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Remove announcement on deletion 
     */
    const updateAnnouncement = (announcementId, dataset) => {
        try {
            let d = [...filteredAnnouncements];
            let indx = d.findIndex(i => i.id === announcementId);

            d[indx] = Object.assign(d[indx], dataset);

            setFilteredAnnouncements(d);

            //update ACPDataStore
            updateCohortData({
                key: "announcements",
                remove: false,
                dataset: d[indx],
                itemId: announcementId
            });

            setSubmissionSuccessfull(true);

        } catch (error) {
            console.error(error)
        }
    }

    const addNewProgramAnnouncement = async (announcements) => {
        try {

            let d = [...announcementsList];
            let dd = [...filteredAnnouncements];

            let filterByCohortId = []

            for await (let announcement of announcements) {

                d.push(announcement)

                //If created announcement is part of current filter 
                //Add the annoucement in the filtered a  nn bbjhbjjbbbjbj   nnouncement
                if (checkIfAnnouncementIsPartOfFilter(announcement)) {
                    if (!filterByCohortId.includes(announcement.cohort_id)) {
                        filterByCohortId.push(announcement.cohort_id)
                    }
                    dd.push(announcement);
                    dd = sortAnnouncements(dd, sortDirection);

                    setFilteredAnnouncements(dd);
                }

                setAnnouncementList(d);

                updateCohortData({
                    key: "announcements",
                    remove: false,
                    dataset: announcement,
                    isNew: true,
                    itemId: announcement.id
                });
            }

            if (!filterByCohortId.length > 0) {
                setSubmissionMessage("The created announcement is not part of filter, but your announcements has been saved successfully. ");
            }

            setSubmissionSuccessfull(true);

        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description - Add newly added announcement. The announcement is added to the existing filtere annoucement
     * Event though the new announcement is not part of filter still show up in the list to let user know it was saved
     * @param {*} dataset 
     * @param {*} direction 
     * @returns 
     */
    const addNewAnnouncement = async (dataset) => {
        try {

            let d = [...announcementsList];
            let dd = [...filteredAnnouncements];

            d.push(dataset);

            //If created announcement is part of current filter 
            //Add the annoucement in the filtered announcement
            if (checkIfAnnouncementIsPartOfFilter(dataset)) {
                dd.push(dataset);
                dd = sortAnnouncements(dd, sortDirection);

                setFilteredAnnouncements(dd);

            } else {
                setSubmissionMessage("The created announcement is not part of filter, but your announcements has been saved successfully. ");
            }

            //Add to overall announcement List
            setAnnouncementList(d);

            //update ACPDataStore
            updateCohortData({
                key: "announcements",
                remove: false,
                dataset: dataset,
                isNew: true,
                itemId: dataset.id
            });

            setSubmissionSuccessfull(true);

        } catch (error) {
            console.error(error);

        }

    }

    //Check if announcement added is part of current selections 
    //2. If cohort_id is one of the filtered cohorts, check if announcements falls in other selected filters for author
    const checkIfAnnouncementIsPartOfFilter = (announcement) => {
        try {
            let cohortId = announcement.cohort_id;
            let postAsfilter = Object.keys(filters).filter(v => filters[v]);

            if (cohortId === 1 && postAsfilter.includes('kenzie')) return true
            if (selectedCohortId === cohortId && postAsfilter.includes(announcement.post_as)) {

                return true
            }

            return false;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description - Sorts annoucements
     * @param {*} announcements 
     * @param {*} direction 
     * @returns 
     */
    const sortAnnouncements = (dataset, direction) => {
        dataset.sort((a, b) => {
            return direction === "oldest" ? new Date(a.post_schedule) - new Date(b.post_schedule) : new Date(b.post_schedule) - new Date(a.post_schedule);
        });

        return dataset;
    };

    return (
        <ACPAnnouncementContext.Provider value={{
            announcementsList,
            filteredAnnouncements,
            setFilteredAnnouncements,
            removeAnnouncement,
            updateAnnouncement,
            addNewAnnouncement,
            addNewProgramAnnouncement,
            sortDirection,
            setSortDirection,
            setFilters,
            showCreateAnnouncementModal,
            setShowCreateAnnouncementModal,
            setSubmissionSuccessfull,
            setSubmissionError,
            isLoading,
            setFilteredStartDate,
            setFilteredEndDate,
            filteredStartDate,
            filteredEndDate,
            filters,
            initialFilters
        }}>
            {children}

            {/* Snackbars for the forms */}
            {
                submissionSuccessfull ?
                    <SuccessSnackBar
                        message={submissionMessage}
                        handleOnClose={() => { setSubmissionSuccessfull(false) }}
                    /> :
                    null
            }
            {
                submissionError ?
                    <ErrorSnackBar
                        message={"There was an error in saving announcement"}
                        handleOnClose={() => { setSubmissionError(false) }}
                    /> :
                    null
            }
        </ACPAnnouncementContext.Provider>
    )

}