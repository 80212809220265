import styled from "@emotion/styled";
import InventoryIcon from '@mui/icons-material/Inventory';
import LanguageIcon from '@mui/icons-material/Language';
export default function VisibilityText({ video = {}, style, isModal, }) {
    const isArchived = video?.archived ? true : false
    const isCurated = Boolean(video.created_by || video.updates)

    return (
        <Wrapper style={style}>
            {!isArchived ?
                <>
                    <StyledLanguageIcon />
                    <PublicText >Public {!isCurated && ' - '}</PublicText>
                </>
                :
                <>
                    <StyledInventoryIcon />
                    <ArchivedText >Archived {!isCurated && ' - '}</ArchivedText>
                </>

            }


            {!isCurated && <UncuratedText>Uncurated</UncuratedText>}



        </Wrapper>
    )
}

const Wrapper = styled.div`
display: flex;
/* justify-content: center; */
align-items: center;
`
const PublicText = styled.span`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #009158;
margin-left: 10px;
`

const ArchivedText = styled.span`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #363636;
margin-left: 10px;

`
const UncuratedText = styled.span`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #C2573F;
margin-left: 5px;
`
const StyledLanguageIcon = styled(LanguageIcon)`
    color: #009158;
    font-size: 20px;
    margin-bottom: 1px;
`
const StyledInventoryIcon = styled(InventoryIcon)`
    color: #363636;
    font-size: 20px;
    margin-bottom: 1px;

`