import { Button, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Card } from '../../components';

import moment from 'moment';

import Skeleton from '@mui/material/Skeleton';
import { LearnersDataContext } from '../../context';

const launchpadCanvasDomain = "https://kenzie.instructure.com"

const useStyles = makeStyles(theme => ({
     contentWrapper: {
          alignItems: "center",
     },
     header: {
          background: theme.MyKenzie.cardColors.secondaryHighlight,
          padding: "20px 23px 0px 23px",
          marginTop: "0px",
          marginBottom: "1px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
     },
     content: {
          display: "flex",
          justifyContent: "space-between",
          minHeight: "111px",
          [theme.breakpoints.down("sm")]: {
               minHeight: "177px"
          }
     },
     courseTitle: {
          ...theme.elements.title,
          margin: 0,
          marginBottom: '13px'
     },
     topRow: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          ...theme.elements.subtitle,
          marginBottom: '13px',
     },
     midRow: {
          ...theme.elements.bodyText,
          marginBottom: "5px",
          fontFamily: "Raleway"
     },
     button: {
          ...theme.elements.darkBlueButton,
          marginTop: "20px !important",
          [theme.breakpoints.down("sm")]: {
               width: "100%",
               marginBottom: "42px !important",
               marginTop: '0',
          },
     },
     selectorWrap: {
          display: "flex",
     },
     heroImage: {
          height: "196px",
          width: '100%',
          // Leaving below for future course backdrop ticket
          // backgroundImage: `url(${'https://mykenzie-api-191117102745-uploads-production.s3.us-east-2.amazonaws.com/uploads/hero-images/rocket_header.png'})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
     },
     heroImageSkeleton: {
          height: "196px",
          width: '100%',
          background: theme.colors.lilac
     }
}));

const SkeletonMyCouseLaunchpad = () => {
     const classes = useStyles()
     const theme = useTheme()
     const matches = useMediaQuery(theme.breakpoints.down("sm"))

     return (
          <div data-testid="skeleton">
               <div className={classes.heroImageSkeleton}>
                    <Skeleton height={196} width={"100%"} varient="rectangular"  ></Skeleton>
               </div>
               <div className={classes.header}>
                    <div className={classes.content} >
                         <div>
                              <div className={classes.courseTitle}>
                                   <Skeleton height={25} width={250}> </Skeleton>
                              </div>
                              <div className={classes.topRow}>
                                   <Skeleton height={23} width={200} className={classes.topRow}> </Skeleton>
                              </div>
                              <Skeleton height={17} width={120} className={classes.midRow} > </Skeleton>
                         </div>
                         {!matches ?
                              <Button variant="contained" classes={{ root: classes.button }} disabled>Launch Course</Button>
                              : ""}
                    </div>
                    {matches ?
                         <Button variant="contained" classes={{ root: classes.button }} disabled>Launch Course</Button>
                         : ""}

               </div>
          </div>)
}

const MyCourseLaunchpad = () => {
     const classes = useStyles();
     const theme = useTheme()
     const matches = useMediaQuery(theme.breakpoints.down("sm"));

     const [courseData, setCourseData] = useState(null);
     const [loading, setLoading] = useState(false)
     const { userCourse, fetchLaunchpadData, checkDashboardDataState } = React.useContext(LearnersDataContext);

     useEffect(() => {

          setLoading(true)
          if (userCourse && userCourse?.course_id) {
               setCourseData(fetchLaunchpadData());


          } else {
               setCourseData({});
          }
          if (courseData !== null) {
               checkDashboardDataState("courseLaunchPadData");
               setLoading(false)
          }

     }, [userCourse, courseData]);

     /**
     * This side effect tells the myKenzie main loader that mycourse panel data is loaded
     */
     // useEffect(() => {
     //      if (courseData !== null) {
     //           setLoading(false);

     //           checkDashboardDataState("courseLaunchPadData");
     //      }
     // }, [courseData]);

     let calculateDateDiff = (startdate) => {
          let startDate = moment(startdate);
          let now = moment();

          let diff = now.diff(startDate, 'days');

          return diff;

     }

     return (
          <Card title="My Course" classes={{ content: classes.contentWrapper }} theme="secondary">
               {
                    !loading && courseData && Object.keys(courseData).length === 0 ?
                         <div>There is no launchpad course</div> :
                         !loading && courseData && Object.keys(courseData).length > 0 ?
                              <>
                                   <div className={classes.heroImage} style={{ backgroundImage: `url(${courseData.hero_image_url})` }}></div>
                                   <div className={classes.header}>
                                        <div className={classes.content}>
                                             <div>
                                                  <div className={classes.courseTitle}>
                                                       {courseData.coursename} Launchpad
                                                  </div>
                                                  <div className={classes.topRow}>
                                                       {
                                                            calculateDateDiff(courseData.startdate) >= 0 ?//start date already passed
                                                                 <span>Your class already started on {moment(courseData.startdate).format('MMMM DD, YYYY')}</span> :
                                                                 <span>Get prepared before class starts on {moment(courseData.startdate).format('MMMM DD')}!</span>
                                                       }
                                                  </div>
                                                  <div className={classes.midRow}>
                                                       {
                                                            calculateDateDiff(courseData.startdate) >= 0 ?//start date already passed
                                                                 <span>Class started {Math.abs(calculateDateDiff(courseData.startdate))} days ago.</span> :
                                                                 <span>You have {Math.abs(calculateDateDiff(courseData.startdate))} days remaining.</span>
                                                       }

                                                  </div>
                                             </div>
                                             {!matches ?
                                                  <a href={`${launchpadCanvasDomain}/courses/${userCourse.course_id}`} style={{ textDecoration: "none" }} >
                                                       <Button variant="contained" className={classes.button} >Launch Course </Button>
                                                  </a>
                                                  : ""}
                                        </div>
                                        {matches ?
                                             <a href={`${launchpadCanvasDomain}/courses/${userCourse.course_id}`} style={{ textDecoration: "none" }}>
                                                  <Button variant="contained" className={classes.button}>Launch Course </Button>
                                             </a>
                                             : ""}
                                   </div>
                              </>
                              : <SkeletonMyCouseLaunchpad />
               }
          </Card>)
}

export default MyCourseLaunchpad