import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';

import moment from 'moment';

import { Formik } from 'formik';

import { useStyles } from './styles';

import { AppDataContext } from '../../../context';
import AnnouncemetEntryFields from './AnnouncemetEntryFields';
import PreviewModal from "./PreviewModal";

import { getAnnouncements, postAnnouncement } from "../../../api";
import { ACPAnnouncementContext } from '../../context';

const boxStyle = {
    position: 'absolute',
    overflowY: "auto",
    maxHeight: "85vh",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
}

//Default Announcement fields
const ANNOUNCEMENT_FIELDS = {
    "body": null,
    "post_as": null,
    "audience": null,
    "author": null,
    "post_schedule": new Date(),
    "post_expiration": moment().add(1, 'days').toDate()
}

const CreateNewAnnouncementForm = ({
    modalOpen,
    handleCloseCallback
}) => {

    const classes = useStyles();

    const [previewMode, setPreviewMode] = useState(false);
    const { userData } = useContext(AppDataContext);

    const {
        addNewAnnouncement,
        addNewProgramAnnouncement,
        setSubmissionError
    } = useContext(ACPAnnouncementContext);

    const handleOnClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleCloseCallback();
        }
    }

    const handleSubmission = async (values, setIsSubmitting) => {
        try {

            values.post_schedule = values.post_schedule.toISOString();
            values.post_expiration = values.post_expiration.toISOString();

            let response = await postAnnouncement(values);
            
            //Get Announcement Information from server again 
            //in the format that is required to display on the screen
            let newAnnouncements; 
            
            if(response.cohort_id && response.cohort_id !== null) {
                newAnnouncements = await getAnnouncements({
                    "id":response.id,
                    "cohort_id": response.cohort_id, 
                    'expand': 'cohorts,countusers,learnersOnly',
                });

                addNewAnnouncement(newAnnouncements[0]);

            } else {

                // If fetching announcements via program_id, an array will be returned. Program ID is the same for each item. Use first item index.
                newAnnouncements = await getAnnouncements({
                    "program_id": response[0]?.program_id,
                    'expand': 'cohorts,countusers,learnersOnly', 
                })

                addNewProgramAnnouncement(newAnnouncements)
            }

            handleOnClose();

        } catch (error) {
            console.error(error);
            setIsSubmitting(false);
            setSubmissionError(true);
        }
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleOnClose}
        >
            <Box sx={boxStyle}>
                <Formik
                    initialValues={Object.assign({ ...ANNOUNCEMENT_FIELDS }, {
                        author: userData.username
                    })}
                    onSubmit={(values, { setSubmitting }) => { handleSubmission(values, setSubmitting) }}>
                    {({ handleSubmit, values, isSubmitting }) => (
                        <form id="create-new-announcement-form-container" >

                            {
                                !previewMode ?
                                    <div id="create-new-announcement-entry-container"
                                        style={{
                                            display: previewMode ? 'none' : "block"
                                        }}>
                                        <div className={classes.formHeaderContainer}>
                                            <div className={classes.formHeader} id="create-announcement-modal-title">
                                                Create New Announcement
                                            </div>   
                                            <div className={classes.closeIcon}>
                                                <IconButton onClick={() => { handleOnClose() }}>
                                                    <CloseIcon sx={{color: "#454545", fontSize: "20px"}}/>
                                                </IconButton>
                                            </div>
                                        </div>
                                        <div className={classes.formHeaderSubtext}>
                                            Create a new announcement that will appear on the MyKenzie dashboard. All fields are required. <span style={{fontWeight: 700}}>Notice</span>: Copy that extends past the first two lines will be truncated. 
                                        </div>

                                        <AnnouncemetEntryFields />

                                    </div> :
                                    <div id="create-new-preview-container"
                                        style={{
                                            display: previewMode ? 'block' : "none"
                                        }}>
                                        <Stack spacing={3}>
                                            <div className={classes.formHeaderContainer}>
                                                <Typography id="create-announcement-preview-title" variant="h6" component="h2">
                                                    Announcement Preview
                                                </Typography>
                                                <div className={classes.closeIcon}>
                                                    <IconButton onClick={() => { handleOnClose() }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <PreviewModal values={values} />
                                        </Stack>
                                    </div>
                            }

                            {/* Buttons are updates per the mode  */}
                            <div className={classes.formFooter}>
                                {
                                    !previewMode ?
                                        <>
                                            <div>
                                                <Button
                                                    onClick={() => { handleOnClose() }}
                                                    variant="text"
                                                    id="create-announcement-cancel-button"
                                                    className={classes.textButton}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className={classes.submitButton}>
                                                <Button
                                                    id="create-announcement-preview-button"
                                                    onClick={() => { setPreviewMode(true) }}
                                                    data-testid="create-announcement-preview-button"
                                                    disabled={!(values.body && values.post_as && values.audience)}
                                                > Preview </Button>
                                            </div>
                                        </> :
                                        <>
                                            <div>
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={() => { setPreviewMode(false) }}
                                                    variant="text"
                                                    id="create-announcement-edit-post-button"
                                                    className={classes.textButton}>
                                                    Edit Post
                                                </Button>
                                            </div>
                                            <div className={classes.submitButton} >
                                                <Button
                                                    disabled={isSubmitting}
                                                    onClick={() => { handleSubmit() }}
                                                    id="create-announcement-schedule-button"
                                                    data-testid="create-announcement-schedule-button"
                                                >
                                                    {
                                                        isSubmitting ?
                                                            <CircularProgress color="inherit" size={18} /> :
                                                            <span>Schedule</span>
                                                    }
                                                </Button>
                                            </div>
                                        </>
                                }

                            </div>

                        </form>
                    )}
                </Formik>

            </Box>

        </Modal>

    )

}


export default CreateNewAnnouncementForm;

