import { useEffect, useState } from "react";
import { getCohortConnectionData } from "../../api";

let cohortConnectionCached = null;

const TIME_INTERVAL = 30000;

/**
 * @description Get the webTools per the cohortId of the user and store the information in ref the variable
 * @returns WebTools Array
 */
export const useCohortConnectionDataHook = (cohortId = null, defaultDataset = null) => {

    const [dataset, setDataset] = useState(cohortConnectionCached);

    //check the values and get information 
    //the useEffect will check ig the time is already running if yes setTimer will not be initialized.
    useEffect(() => {

        let intervalId = null;

        if (cohortId !== null && defaultDataset == null) {

            const fetchData = async () => {
                try {
                    let data = await getCohortConnectionData(cohortId);
                    cohortConnectionCached = data;
                    setDataset(data);
                } catch (error) {
                    console.error(error);
                }
            };

            if (cohortConnectionCached == null) fetchData();//Grabs the data on mount

            intervalId = setInterval(() => {
                if (cohortId !== null) {
                    fetchData();
                }
            }, TIME_INTERVAL);

        } else {
            cohortConnectionCached = defaultDataset;
            setDataset(cohortConnectionCached);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        }

    }, [cohortId, defaultDataset]);

    return dataset;
}