import React from 'react';
import { useStyles } from './styles';

import {getRequestStatusMapping} from './helpers';
import moment from 'moment';

export default function EachRequestStatus({
    request
}){

    const classes=useStyles();
   
    return(
        <div className='text-normal open-sans'>
            <span>{getRequestStatusMapping(request)}</span>
            <span> 
                {
                    request.status==="awaiting_review" && request.created_at?
                    <><span> | </span><span className={classes.redText}>{moment(request.created_at).fromNow()}</span></>:
                    request.updated_at?
                    <><span> | </span><span className={classes.redText}>{moment(request.updated_at).fromNow()}</span></>:
                    null
                }
            </span>
        </div>
    )
}