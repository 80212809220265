import { makeStyles } from "@mui/styles";

const skeletonStyles = makeStyles((theme) => ({
    tabContainerHeaderSkel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 12px 0px 0px",
        [theme.breakpoints.down('md')]: {
            justifyContent: "center"
        }
    },
    tabHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "14px"
    },
    recordingDatePanel: {
        paddingTop: "45px",
        paddingRight: "13px",
        [theme.breakpoints.down('md')]: {
            paddingTop: "55px",
        }
    }
}))

export default skeletonStyles;