import React from "react"
import {makeStyles, styled} from "@mui/styles";
import { Formik } from 'formik';
import { Button } from "@mui/material";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";

import Welcome from "./Welcome"
import Identity from "./Identity"
import Picture from "./Picture"
import Location from "./Location"
import Languages from "./Languages"
import Experience from "./Experience"
import Personal from "./Personal"
import Focus from "./Focus"
import WorkSchedule from "./WorkSchedule"
import SocialMedia from "./SocialMedia"
import Wrap from "./Wrap"

import backgroundImage from "./assets/wave_bg_green.png"
import {ReactComponent as MyKenzieLogo} from "../../../images/my-kenzie-logo.svg"

import {Navigation} from "./components"

import { updateUser } from "../../../api/userManagement";
import { AppDataContext } from "../../../context";
import { ErrorContext } from "./context";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100vh',
        width: "100vw",
        display: "grid",
        gridTemplateRows: "200px auto 150px",
        [theme.breakpoints.down("500")]: {
            gridTemplateRows: "150px auto 100px",
        },
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        zIndex: "-2",
        overflowX: "hidden"
    },
    header: {
        gridRow: "1 / span 1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    content: {
        gridRow: "2 / span 1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
        "& h1,h3,p": {
            textAlign: "center"
        },
        "& p": {
            fontFamily: 'Nunito',
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '42px',
            [theme.breakpoints.down("800")]: {
                fontSize: '25px',
                lineHeight: '37px',
            },
            letterSpacing: '0em',
            textAlign: 'center',
            [theme.breakpoints.down("500")]: {
                textAlign: "left",
                width: "100%"
            },
            margin: "0px 0px 30px",
            color: '#414141'
        }
    },
    contentWrap: {
        width: "80vw",
        [theme.breakpoints.down("500")]: {
            width: "87vw"
        },
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    footer: {
        gridRow: "3 / span 1",
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    button: {
        "& .MuiButton-contained": {
            color: "red"
        }
    },
    logoWrap: {
        marginTop: "60px",
        [theme.breakpoints.down("500")]: {
            marginTop: "30px"
        },
        width: "150px",
        height: "52px"
    },
    smallButton: {
        width: "317px !important",
        [theme.breakpoints.down("600")]: {
            width: "223px !important"
        }
    },
    disabledButton: {
        background: "rgba(0, 0, 0, 0.12) !important"
    },
    regularButton: {
        background: "#14606B !important"
    }
}))

const ContinueButton = styled(Button)((props) => ({
    width: "430px",
    height: "42px",
    fontSize: "20px !important",
    borderRadius: "50px !important",
    [props.theme.breakpoints.down("600")]: {
        width: "348px",
        height: "42px",
        fontSize: "20px !important",
        borderRadius: "50px !important",
    },
    fontFamily: "Nunito !important",
    fontWeight: "600 !important",
    lineHeight: "27px !important",
    letterSpacing: "0em !important",
    textTransform: "none !important",
    textAlign: "center !important",
}))

const GoBackButton = styled(Button)(() => ({
    height: '42px',
    fontFamily: 'Nunito !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '20px !important',
    lineHeight: '27px !important',
    color: '#14606B !important',
    textTransform: 'none !important',
    marginRight: "11px !important"
}))

const steps = [
    {
        values: [],
        component: <Welcome />
    },
    {
        values: [
            "preferred_name",
            "user_name_pronunciation",
            "user_preferred_pronouns",
        ],
        component: <Identity />
    }, 
    {
        values: [],
        component: <Picture />
    }, 
    {
        values: [
            "user_location",
            "user_timezone",
            "user_show_location",
            "user_show_timezone"
        ],
        component: <Location />
    }, 
    {
        values: [
            "user_primary_spoken_language",
            "user_additional_spoken_languages"
        ],
        component: <Languages />
    }, 
    {
        values: [
            "user_programming_experience_level",
            "user_programming_languages"
        ],
        component: <Experience />
    }, 
    {
        values: [
            "user_dream_job",
            "user_free_time",
            "user_fun_fact"
        ],
        component: <Personal />
    }, 
    {
        values: [
            "user_focus_time"
        ],
        component: <Focus />
    },
    {
        values: [
            "user_work_schedule"
        ],
        component: <WorkSchedule/>
    },
    {
        values: [
            "user_social_media_links"
        ],
        component: <SocialMedia />
    },
    {
        values: [],
        component: <Wrap />
    }
]

const Onboarding = (props) => {
    const classes = useStyles()
    const [index, setIndex] = React.useState(0)
    const [isValid, setIsValid] = React.useState(true)
    const {userData, fetchAndSetUserData} = React.useContext(AppDataContext)

    let initialValues = {...userData}
    Object.keys(initialValues).forEach(key => {
        if (initialValues[key] === undefined || JSON.stringify(initialValues[key])  === "{}") {
            delete initialValues[key];
        }
    })

    if(!initialValues["preferred_name"]) {
        initialValues["preferred_name"] = initialValues["first_name"]
    }
    if(!initialValues["user_programming_experience_level"]){
        initialValues["user_programming_experience_level"] = "Beginner"
    }

    const submitForm = (values) => {
        if(index < steps.length - 1) {
            let updates = {}
            if(steps[index].values && steps[index].values.length > 0) {
                for(let fieldName of steps[index].values) {
                    if(values[fieldName] !== undefined && values[fieldName] !== null) {
                        updates[fieldName] = values[fieldName]
                    }
                }
                if (Object.keys(updates).length > 0) {
                    updateUser(updates, userData.username)
                }
            }
    
            setIndex(index + 1)
        } else {
            updateUser({"first_login": false}, userData.username)
            fetchAndSetUserData()
            props.setIsOnboarding(false)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Formik
                initialValues={initialValues}
            >
                {({
                    values,
                }) => (
                    <ErrorContext.Provider
                        value={{isValid, setIsValid}}
                    >
                        <div className={classes.wrapper}>
                            <div className={classes.header}>
                                <div className={classes.logoWrap}>
                                    <MyKenzieLogo />
                                </div>
                                <Navigation index={index-1}/>
                            </div>
                            <div className={classes.content}>
                            {/*  Hidden inputs to prevent password managers from picking up fields */}
                            <input name="disable-pwd-mgr-1" type="password" id="disable-pwd-mgr-1" style={{display: "none"}} value="disable-pwd-mgr-1" />
                            <input name="disable-pwd-mgr-2" type="password" id="disable-pwd-mgr-2" style={{display: "none"}} value="disable-pwd-mgr-2" />
                            <input name="disable-pwd-mgr-3" type="password" id="disable-pwd-mgr-3" style={{display: "none"}} value="disable-pwd-mgr-3" />
                                <div className={classes.contentWrap}>
                                    {steps[index].component}
                                </div>
                            </div>
                            <div className={classes.footer}>
                                {index > 1 && <GoBackButton onClick={() => setIndex(index-1)}>Go Back</GoBackButton>}
                                <ContinueButton className={`${index > 1 ? classes.smallButton : null} ${!isValid ? classes.disabledButton : classes.regularButton}`} disabled={!isValid} variant="contained" onClick={() => submitForm(values)}>{index < steps.length - 1 ? "Continue" : "Go To MyKenzie"}</ContinueButton>
                            </div>
                        </div>
                    </ErrorContext.Provider>
                )}
            </Formik>
        </ThemeProvider>
    )
}

export default Onboarding