import { useContext } from "react";
import { ACPSupportRequestsContext } from "../../../context";
import { Card } from "@mui/material";
import ClearHistoryButton from "./ClearHistoryButton";
import { useStyles } from "./styles";

const SearchHistoryMenu = ({ children }) => {
    const classes = useStyles();
    const { searchTerms, searchHistory } = useContext(ACPSupportRequestsContext);
    return (
        <Card className={classes.historyMenu}>
            {children}
            {/* Display clear history button when user has searchHistory and is not searching */}
            {searchHistory.length > searchTerms.length && <ClearHistoryButton />}
        </Card>
    )
};

export default SearchHistoryMenu;