import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Drawer, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { submitRequestSupport } from '../../../../api/index';
import RenderRequestQuestions from "./RenderRequestQuestion";
import useStyles from './styles';

import { AppDataContext } from "../../../../context";
import ErrorIcon from '../../../../icons/errorIcon';
import SuccessIcon from '../../../../icons/successIcon';
import X from '../../../../icons/x';
import { LearnersDataContext } from "../../../context";
import SupportMenu from "./RequestSupportMenu";
import { useHistory } from "react-router-dom"

const SelectedRequestOptionTitle = (options) => {
    switch (options) {
        case "assessment":
            return <p>Stuck on an&nbsp;<b>assessment</b></p>
        case "activity":
            return <p>Stuck on an&nbsp;<b>activity</b></p>
        case "lesson":
            return <p>Don’t understand the&nbsp;<b>lesson</b></p>
        case "grades":
            return <p>It's about my&nbsp;<b>grades</b></p>
        case "personal":
            return <p>It’s&nbsp;<b>personal</b></p>
        case "other":
            return <p>It's&nbsp;<b>something else</b></p>
        default:
            return <p>It's&nbsp;<b>something else</b></p>

    }
}

const INPUTS = {
    activity: {},
    assessment: {},
    lesson: {},
    grades: {},
    personal: {},
    other: {}
}

const RequestSupportForm = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();
    const { userData } = useContext(AppDataContext);
    const { userCourse, helpOpen, setHelpOpen } = useContext(LearnersDataContext);
    const [questionType, setQuestionType] = useState("");
    const [inputs, setInputs] = useState({ ...INPUTS });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isValid, setisValidState] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const [renderQuestions, setRenderQuestions] = useState(false);

    useEffect(() => {
        //Cleanup on unmount
        return (() => {
            setQuestionType("");
            setInputs({ ...INPUTS });
            setRenderQuestions(false);
        });
    }, []);

    useEffect(() => {
        if (questionType.length > 0) {
            setRenderQuestions(true);
        }
    }, [questionType]);

    useEffect(() => {
        handleSubmitBtnState();
    }, [inputs]);

    useEffect(() => {
        if (!helpOpen) {
            setQuestionType('')
        }
    }, [helpOpen]);

    const submitQuestion = async () => {
        setIsSubmitting(true);
        setErrorMessage("");
        setSuccessMessage("");
        setIsSent(false);

        const questionTypeDict = {
            "assessment": 1,
            "activity": 2,
            "lesson": 3,
            "personal": 4,
            "grades": 5,
            "other": 6
        };

        let description = "";
        for (const prop in inputs[questionType]) {
            description += `&${prop}=${inputs[questionType][prop]}`;
        }
        description = description.replaceAll("&amp;", "&")
        const email = user.email;

        let requestBody = {
            "request_type_id": questionTypeDict[questionType],
            "source": "my_kenzie",
            "lms_course_id": userCourse?.course_id ? userCourse.course_id : null,
            "cohort_id": userCourse?.cohort_id ? userCourse.cohort_id : null,
            "user_lms_id": userData["user_lms_id"],
            "user_slack_id": userData["user_slack_id"],
            "description": description,
            "email": email
        };

        try {
            await submitRequestSupport(requestBody);
            setSuccessMessage("We've recieved your request.")
            setIsSent(true);
        } catch (error) {
            console.error(error);
            setIsSent(true);
            setErrorMessage("Uh-oh, something went wrong.")
        } finally {
            handleSubmitBtnState();
        }
    }

    const handleSubmitBtnState = async () => {
        try {
            var isValid = false;
            if (questionType === "assessment") {
                if (!inputs["assessment"].challenge || !inputs["assessment"].solution) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            } else if (questionType === "activity") {
                if (!inputs["activity"].challenge || !inputs["activity"].solution) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            } else if (questionType === "lesson") {
                if (!inputs["lesson"].struggling) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            } else if (questionType === "grades") {
                if (!inputs["grades"].grades) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            } else if (questionType === "personal") {
                if (!inputs["personal"].personal) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            } else if (questionType === "other") {
                if (!inputs["other"].other) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            }
            setisValidState(isValid);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = event => {
        // ;
        setInputs(inputs => ({
            ...inputs,
            [questionType]: {
                ...inputs[questionType],
                [event.target.name]: event.target.value === "<p><br></p>" ? null : event.target.value
            }
        }));
    };

    const handleClose = (e) => {
        history.push("/")
        setHelpOpen(false)
    }

    return (
        <Drawer open={helpOpen} anchor="right" onClose={handleClose} classes={{ paper: classes.overflowHidden }}>
            <div className={classes.drawerContainer}>
                <Box className={classes.drawerHeader}>
                    <p>Request Support</p>
                    <p className={classes.circleX} onClick={handleClose}>
                        <span className={classes.X}><X /></span>
                    </p>
                </Box>
                <Box className={classes.drawerSubHeader}>
                    {
                        questionType === "" ?
                            <p>Need some additional help? Choose an option below to submit a request to your instructional team. We will respond to you in Slack as soon as possible.</p> :
                            SelectedRequestOptionTitle(questionType)
                    }
                </Box>
                <Box className={classes.requestContent}>
                    {
                        questionType === "" ?
                            <SupportMenu
                                setQuestionType={setQuestionType} /> :
                            !isSubmitting && renderQuestions ?
                                <Box component="form">
                                    <RenderRequestQuestions
                                        inputs={inputs}
                                        request={questionType}
                                        handleChange={handleChange}
                                    />
                                    {/* <Button className={classes.attachImageButton}>{capitalize("attach images")}</Button> */}
                                    <div className={classes.formActions}>
                                        <Button className={classes.cancelBtn} onClick={handleClose}>Cancel</Button>
                                        <Button className={classes.submitBtn} disabled={isSubmitting || !isValid} onClick={submitQuestion}>Submit</Button>
                                    </div>
                                </Box> :
                                isSent === false ?
                                    <div className={classes.loading}>
                                        <div className={classes.loadingSkeleton}>
                                            <Skeleton variant="circular" width={15} height={15} sx={{ backgroundColor: 'rgba(196, 196, 196, 0.2);' }} />
                                            <Skeleton variant="circular" width={15} height={15} sx={{ backgroundColor: 'rgba(196, 196, 196, 0.4);' }} />
                                            <Skeleton variant="circular" width={15} height={15} sx={{ backgroundColor: 'rgba(196, 196, 196, 0.6);' }} />
                                            <Skeleton variant="circular" width={15} height={15} sx={{ backgroundColor: 'background: rgba(196, 196, 196, 0.8);' }} />
                                            <Skeleton variant="circular" width={15} height={15} sx={{ backgroundColor: 'rgba(196, 196, 196, 1);' }} />
                                        </div>
                                        <p>Sending your request...</p>
                                    </div> :
                                    successMessage !== "" ?
                                        <div className={classes.completionMessage}>
                                            <SuccessIcon />
                                            <p className={classes.mainMessage}>{successMessage}</p>
                                            <p className={classes.followUpMessage}>A member of your support team will respond to you on Slack as soon as possible.</p>
                                            <Button className={classes.doneBtn} variant="text" onClick={handleClose}>Done</Button>
                                        </div>
                                        :
                                        <div className={classes.completionMessage}>
                                            <ErrorIcon />
                                            <p className={classes.mainMessage}>{errorMessage}</p>
                                            <p className={classes.followUpMessage}>Sorry about that! Please retry your submission.</p>
                                            <div className={classes.errorBtns}>
                                                <Button className={classes.goBackBtn} variant="text" onClick={() => setIsSubmitting(false)}>Go Back</Button>
                                                <Button className={classes.retryBtn} variant="text" onClick={submitQuestion}>Retry</Button>
                                            </div>
                                        </div>
                    }
                </Box>
            </div>
        </Drawer>
    )
}

export default RequestSupportForm;