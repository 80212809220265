import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    profile: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        height: "67px",
        width: "100%",
        borderRadius: "8px",
        marginLeft: "12px",
        paddingLeft: "13px",
        transition: "box-shadow 550ms",
        '&:hover': {
            position: "relative",
            zIndex: 0,
            cursor: "pointer",
            boxShadow: "0px 1px 12px rgba(165,165,165, .70)",
        },
    },
    '@global': {
        'a': {
            textDecoration: "none"
        }
    },
    Image: {
        position: "relative",
        left: "50%",
        top: "50%",
        zIndex: 0,
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
    },
    profileImage: {
        // boxSizing: "border-box",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        border: `2.5px solid ${theme.MyKenzie.cardColors.secondary}`,
    },
    profileContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "160px",
    },
    namePtag: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "0px 0px 0px 15px",
        fontSize: '14px',
        height: "20px",
        lineHeight: "16px",
        ...theme.MyKenzie.text.contentTitle
    },
    positionPtag: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        margin: "0px 0px 0px 15px",
        height: "20px",
        fontSize: "13px",
        lineHeight: "16px",
        ...theme.MyKenzie.text.contentSubtitle,
        fontWeight: 400
    },
    button: {
        boxSizing: "border-box",
        margin: "20px 0px 20px 23px",
        paddingRight: "23px",
        width: "100%"
    },
    requestSupport: {
        width: '100%',
        height: "35px",
        marginBottom: "10px",
        border: `2.5px solid ${theme.MyKenzie.textColors.lightButton} !important`,
        backgroundColor: `${theme.MyKenzie.actionColors.lightButton} !important`,
        borderRadius: "50px !important",
        color: `${theme.MyKenzie.textColors.lightButton} !important`,
        font: "12px Raleway, arial, sans-serif !important",
        fontWeight: `${700} !important`,
        textTransform: "none !important",
        boxShadow: "1px 3px 6px rgb(180,180,180) !important",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "301px"
        }
    },
    skeletonRequestSupport: {
        width: "90% !important",
        maxWidth: "279px !important",
        height: "35px !important",
        borderRadius: "50px !important",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "301px !important"
        }
    },
    skeletonMargin: {
        marginLeft: 15
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        paddingRight: "25px",
        paddingTop: '30px',
        backgroundColor: "#FFFFFF",
        borderRadius: "8px 8px 8px 8px",
    },
    "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
        overflowX: "hidden"
    },
    drawerContainer: {
        width: "512px",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100vw",
        },
    },
    drawerHeader: {
        position: "absolute",
        top: "60px",
        left: "39px",
        width: "430px",
        lineHeight: "14px",
        height: "14px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Raleway, arial, sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "80vw"
        }
    },
    circleX: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid rgba(104, 104, 104, 1)",
        borderRadius: "50%",
        height: "15px",
        width: "15px",
        "&:hover": {
            backgroundColor: "rgb(240,240,240);",
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            height: "13px",
            width: "13px"
        }
    },
    X: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    drawerSubHeader: {
        position: "absolute",
        width: "423px",
        height: "29px",
        top: "90px",
        left: "39px",
        fontSize: "12px",
        fontWeight: 500,
        fontFamily: "Raleway, arial, sans-serif  !important",
        lineHeight: "170%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "74vw",
            marginTop: "-15px"
        }
    },
    requestContent: {
        position: "absolute",
        left: "39px",
        top: "145px",
        [theme.breakpoints.down('sm')]: {
            top: "135px"
        },
        [theme.breakpoints.down("399")]: {
            top: "160px"
        }
    },
    option: {
        marginTop: "35px  !important",
        width: "100%",
        fontSize: "1em  !important",
        fontWeight: "500  !important",
        fontFamily: theme.fontFamily,
        lineHeight: "12px  !important",
        height: "34px  !important",
        backgroundColor: "rgb(255,255,255)  !important",
        '&:hover': {
            backgroundColor: "rgb(234, 245, 255, 1)  !important",
            width: "420px  !important",
            cursor: "pointer  !important"
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "79vw  !important"
        }
    },
    formContainer: {
        // marginBottom: "30px"
    },
    textField: {
        width: "430px !important",
        boxSizing: "border-box",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px",
            border: "1px solid black !important",
            fontSize: "17px !important",
            [theme.breakpoints.down('sm')]: {
                fontSize: "12px !important"
            }
        },
        "& .Mui-focused .MuiOutlinedInput-root": {
            fontSize: "20px !important",
            fontFamily: "Raleway, arial, sans-serif !important",
            fontWeight: "500 !important"
        },
        "& .Mui-focused": {
            color: "rgb(0,0,0) !important"
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "79vw !important",
        }
    },
    attachImageButton: {
        position: "absolute",
        top: "475px",
        border: "2.5px solid rgba(12,113,207,1);",
        borderRadius: "50px",
        width: "137px",
        height: "36px",
        fontSize: "12px",
        fontFamily: "Raleway, arial, sans-serif",
        fontWeight: 600,
        textTransform: "none"
    },
    formActions: {
        width: "435px !important",
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "11px !important",
        fontFamily: "Raleway, arial, sans-serif !important",
        fontWeight: "600 !important",
        lineHeight: "14px !important",
        [theme.breakpoints.down('sm')]: {
            width: "435px !important",
            maxWidth: "80vw !important",
            justifyContent: "flex-end",
        }
    },
    labelRoot: {
        fontSize: "16px !important",
        fontWeight: "500 !important",
        fontFamily: "Raleway, arial, sans-serif  !important",
        letterSpacing: "0px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px  !important",
        }
    },
    cancelBtn: {
        backgroundColor: "transparent !important",
        border: "none !important",
        textTransform: "none !important",
        color: "rgba(0,0,0) !important",
        fontWeight: "600 !important",
    },
    submitBtn: {
        backgroundColor: "transparent",
        border: "none !important",
        color: "rgba(12, 113, 207, 1) !important",
        textTransform: "none !important",
        fontWeight: "bold !important",
        transition: "color 2s !important",
        '&:hover': {
            backgroundColor: "transparent !important",
        },
        '&:disabled': {
            color: "rgba(12, 113, 207, 1) !important",
            opacity: "0.5 !important",
            transition: "color 2s !important"
        }
    },
    // LOADER
    loading: {
        position: "absolute",
        top: "270px",
        left: "15px",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Raleway, arial, sans-serif",
        fontWeight: 600,
        fontSize: "18px",
        [theme.breakpoints.down("sm")]: {
            width: "72vw"
        }
    },
    loadingSkeleton: {
        width: "30%",
        display: "flex",
        justifyContent: "space-around",
    },
    // LOADER
    completionMessage: {
        position: "absolute",
        top: "25vh",
        left: "15px",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Raleway, arial, sans-serif",
        [theme.breakpoints.down("sm")]: {
            width: "80vw",
            left: "-8px"
        }
    },
    followUpMessage: {
        fontWeight: 200,
        fontSize: "14px",
        textAlign: "center",
        marginTop: "-5px"
    },
    mainMessage: {
        fontSize: "22px",
        fontWeight: 600,
        marginTop: "30px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px"
        }
    },
    errorBtns: {
        marginTop: "30px"
    },
    goBackBtn: {
        fontSize: "12px",
        fontWeight: 500,
        fontFamily: "Raleway, arial, sans-serif",
        marginRight: "30px",
        textTransform: "none"
    },
    retryBtn: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Raleway, arial, sans-serif",
        color: "rgba(12, 113, 207, 1);",
        textTransform: "none"
    },
    doneBtn: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Raleway, arial, sans-serif",
        color: "rgba(12, 113, 207, 1);",
        textTransform: "none"
    },
    overflowHidden: {
        overflowX: "hidden !important"
    }
}));

export default useStyles;