import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function MenuCheckbox({
  value = "",
  name,
  label,
  handleOnChange,
  isChecked = false,
  isDisabled = false,
  additionalSx = {},
  color = 'green'
}) {
  let checkboxColorStyles = getCheckboxStylesByColor(color)



  return (
    <FormGroup sx={{ ...additionalSx }}>
      <FormControlLabel
        onChange={handleOnChange}
        checked={isChecked}
        disabled={isDisabled}
        name={name}
        value={value}
        control={<Checkbox
          name={name}
          sx={checkboxColorStyles}
          size="small" />
        }
        label={label} />
    </FormGroup>
  );
}
const getCheckboxStylesByColor = (color) => {
  switch (color) {
    case 'white':
      return {

        color: '#FFF',
        '&.Mui-checked': {
          color: '#FFF',
        },
      }

    default:
      return {

        color: '#E9E9E9',
        '&.Mui-checked': {
          color: '#ACFFE1',
        },
      }

  }
}

