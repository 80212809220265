export function sortVideos(videos) {
    return videos.sort((a, b) => {
        const aDate = new Date(a.video_details.recording_start)
        const bDate = new Date(b.video_details.recording_start)

        if (aDate > bDate) return -1
        if (aDate < bDate) return 1
        return 0
    })
}

export function sortRecentVideos(recentVideos) {
    return recentVideos.sort((a, b) => {
        const aDate = new Date(a.date_last_watched)
        const bDate = new Date(b.date_last_watched)

        if (aDate > bDate) return -1
        if (aDate < bDate) return 1
        return 0
    })
}