import axios from 'axios';
import { getApiBase, HEADERS } from './helpers';

/**
 * @description Gets the user per the values passed. Please see API documentation in readme file on how you structure the url
 * @param {object} 
 * @returns users
 */
let getUsers = async (values) => {
    try {
        let { data } = await axios.get(`${getApiBase()}/v1/users`, {
            params: values,
            headers: HEADERS
        });

        return data?.message?.users ? data.message.users : [];

    } catch (error) {
        console.error(error);
    }
}

/**
 * @description - Update user information
 * @param {*} values 
 * @param {*} username 
 * @returns response object
 */
const updateUser = async (values, username) => {
    try {
        let response = await axios.put(`${getApiBase()}/user/${username}`, values, {
            headers: HEADERS
        })

        return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

export {
    getApiBase,
    getUsers,
    updateUser
};

