import React, { useContext, useEffect, useRef, useState } from 'react';

import HistoryIcon from '@mui/icons-material/History';
import { createUserSearch } from '../../../api';
import { AppDataContext } from '../../../context';
import { ReactComponent as MagnifyingGlass } from '../../../icons/magnifier.svg';
import useStyles from './styles';

import { v4 as uuid4 } from 'uuid';
import { hideOffFocusEventHandler } from '../../../helpers/utils';

const FILTERED_COLS=[
    'first_name', 
    'last_name',
    'preferred_name',
    'username',
    'email',
    'secondary_email',
    'user_preferred_pronouns',
    'user_name_pronunciation',
    'user_social_media_links',
    'user_location',
    'user_timezone',
    'user_primary_spoken_language',
    'user_additional_spoken_languages',
    'user_additional_spoken_langu',//Postgres truncates the columns name to just first 31 characters. So adding truncated col name in addition to above key
    'user_goals',
    'user_programming_experience_level',
    "user_programming_experience_",//Postgres trcuncates the columns name to just first 31 characters. So adding truncated col name in addition to above key
    'user_programming_languages',
    'user_dream_job',
    'user_title',
    'user_intro_interest',
    'user_focus_time',
    'user_work_schedule'
];

//The list of props that requires to be excluded from jsonb objects from the search criteria
const EXCLUDE_OBJECT_PROPS= {
    'user_location':['place_id','lat','lng'],
    'user_social_media_links':['id']
}

//This logic excludes the props from the object and returens 
const excludeObjectProps=(key,value)=>{
    //Check if the passed value is array 
    //Check if passed values is object 
    let excludedProps=EXCLUDE_OBJECT_PROPS[key];

    const eachObject=(val)=>{
        let obj={};
        Object.keys(val).forEach(key=>{
            if(!excludedProps.includes(key)){
                obj[key]=val[key]; 
            }
        });
        return obj;
    }

    if(Array.isArray(value)){
        let d= value.map(v=>{
            return eachObject(v);
        });
        return d;

    }else if(typeof value === 'object'){
        return eachObject(value);
    }
}

const SearchCohortUsers =({
    profileCards,
    setCohortUserSearchTerm,
    setCohortUserSearchResults
}) => {

    const classes = useStyles();

    const { userData }=useContext(AppDataContext);

    let searchInputRef=useRef(null);

    const [searchBoxFocus, setSearchBoxFocus] = useState(false);
    const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem("search_history")) || []);

    //Add Event Handler to hide the SearchBox on Off focus
    useEffect(()=>{
        if(searchInputRef.current!==null){
            hideOffFocusEventHandler(document.getElementById('cohort-user-search-box'),function(){
                setSearchBoxFocus(false);
            });
        }
    },[])

    //Creates the search value 
    //Logic includes include and excluding the values to be searched
    useEffect(()=>{
        //massage data to create a search_value
        for (let card of profileCards) {
                    
            let userDataset = card.user_email_mk_user;

            let searchVal="";

            for (let [key, value] of Object.entries(userDataset)) {
                //check if the Key exists in the `EXCLUDE_OBJECT_PROPS`. 
                //If no, stringify the whole object
                //If yes, stringify only the props which are NOT included in EXCLUDE_OBJECT_PROPS
                if(FILTERED_COLS.includes(key) && value!==null && !Object.keys(EXCLUDE_OBJECT_PROPS).includes(key)){
                    searchVal+=JSON.stringify(value);

                }else if(FILTERED_COLS.includes(key) && value!==null && Object.keys(EXCLUDE_OBJECT_PROPS).includes(key)){
                    searchVal+=JSON.stringify(excludeObjectProps(key,value))
                }
            }

            card.search_value=searchVal;
        }
         
    },[profileCards]);

    useEffect(() => {
        let searchBox = document.getElementById("search-box");
        if(searchBox) {
            if(searchBoxFocus === true) {
                searchBox.style.borderBottomRightRadius = "0px"
                searchBox.style.borderBottomLeftRadius = "0px"
                searchBox.style.borderTopRightRadius = "10px"
                searchBox.style.borderTopLeftRadius = "10px"
            } else {
                searchBox.style.borderBottomRightRadius = "10px"
                searchBox.style.borderBottomLeftRadius = "10px"
            }
        }
    }, [searchBoxFocus])

    /** Search Users - Triggers when user hits enter on the search btn within the field */
    const searchUsersOnEnter = (event) => {

        let val=event.target.value;
        /** 
         * If user enteres enter and length greater than zero, i.e. something is entered
         */
        if(event.key==="Enter"){
            searchCohortUsers(val);
        }
    }

    const checkIfProfileCardExists = (profileCards, searchResultsArray, regEx)=> {
        return profileCards.filter(i => i.search_value.match(regEx) !== null).reduce((acc, ci) => {
            if (!searchResultsArray.find(i => i.id === ci.id)) {
                acc.push(ci);
            }
            return acc;
        }, []);
    }
    /** Search user in cohort */
    const searchCohortUsers = (val) => {
        clearResults()
        if (val.length > 0) {

            let searchTermArray = val.split(" ");

            let searchResultsArray = [];
            let currentWordSearch=0;

            //1. Search entire string. Perform "and" search. This is done using word boundries in regex
            //2. Perform "Or" Search. Only if there is nothing returend in And Search
            //If there is searchResults gathered, exit the while loop
            while(currentWordSearch<=1){

                let regEx=null;

                if(currentWordSearch===0){//The first search word would be entire search entered by the user

                    searchResultsArray=profileCards.reduce((acc,ci)=>{
                        let matchCount=0;
                        for(let term of searchTermArray){
                            let regEx=new RegExp('\\b'+term+'\\b',"ig");
                            if(ci.search_value.search(regEx)!==-1){
                                matchCount++;
                            }
                        }
                        if(matchCount===searchTermArray.length){
                            acc.push(ci);
                        }
                        return acc;
                    },[]);
                                    
                }else{
                    //do or search 
                    regEx=new RegExp(searchTermArray.join("|"),'ig');

                    let orResults = checkIfProfileCardExists(profileCards, searchResultsArray, regEx)
                    
                    //Here we look inf the profile card already exists, if yes dont add to the array
                    searchResultsArray=searchResultsArray.concat(orResults);
                }



                currentWordSearch++;

            }

            //** Insert values in localStorage & search History, if not already exists */
            storeInSearchHistory(val);

            setCohortUserSearchTerm(val);
            setCohortUserSearchResults(searchResultsArray);

            //Reseting search box
            searchInputRef.current.value="";
            searchInputRef.current.blur();

            setSearchBoxFocus(false);

        } else {
            clearResults();//Reset Search
        }

    }

    const clearResults = () => {
        setCohortUserSearchResults(null);
        searchInputRef.current.value="";
        searchInputRef.current.blur();
    }

    /** Stores the value in the localstorage and alos in the database */
    /** The history is stored ony if its not searched before by the smae person */
    const storeInSearchHistory=(val)=>{
        let historyItems=[...searchHistory];

        if(!historyItems.includes(val)){

            historyItems.push(val);
            localStorage.setItem("search_history", JSON.stringify(historyItems));
            setSearchHistory(historyItems);

        /*Adding search in the database */
            createUserSearch({
                user_email: userData.email,
                search_value: val
            })

        } else {
            /** If search results exists, pushing search term in the end */
            let searchTermIndex = historyItems.indexOf(val);
            historyItems.splice(searchTermIndex, 1);
            historyItems.push(val);
            setSearchHistory(historyItems);
        }
    } 

    return (
        <div id="cohort-user-search-box" className={classes.searchBox}>
            <div className={classes.searchForm} id="search-box">
                <input 
                    id="search-cohort-users-input" 
                    ref={searchInputRef}
                    onKeyUp={searchUsersOnEnter}
                    className={classes.searchField} 
                    type="search" 
                    placeholder="Search" 
                    spellCheck={false} 
                    autoComplete="off" 
                    onFocus={() => setSearchBoxFocus(true)}/>

                <button id="searchBtn" 
                    className={classes.searchBtn} type="submit" 
                    onClick={()=>{
                        searchCohortUsers(searchInputRef.current.value);
                    }} >
                    <MagnifyingGlass />
                </button>

            </div>
            {
                searchHistory.length > 0 && searchBoxFocus === true &&
                <div id="search-dropdown" className={`${classes.searchDropDown} ${classes.searchHistoryReveal} `}>
                    {
                        // Getting last 5 items in array and revering the array to show last element first
                        searchHistory.slice(-5).reverse().map((history) => (
                            <div key={uuid4()}
                                onClick={()=>{searchCohortUsers(history)}}
                                className={`each-search-history-item ${classes.searchHistoryReveal}`} >
                                <div className='each-search-history-inner-container'>
                                    <HistoryIcon  fontSize='18' style={{marginRight:"8px"}}/>
                                    <div> {history} </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
};

export default SearchCohortUsers;