import { Checkbox, FormControlLabel, FormGroup, TextField, useMediaQuery, useTheme } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaContext } from ".";
import { ReactComponent as CheckIcon } from '../../../icons/bxs_checkbox-checked.svg';
import { ReactComponent as UncheckIcon } from '../../../icons/bxs_checkbox-unchecked.svg';

import { ReactComponent as ShareIcon } from '../../../icons/share_icon_arrow.svg';
import { getPlayTimeFormat } from "../watch/utils";
import useStyles from "./styles";

const ShareVideo = () => {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles();

    const { videoRef } = useContext(MediaContext);
    const shareRef = React.useRef(null)
    const [showModal, setShowModal] = useState(false);
    const [videoTimeStamp, setVideoTimeStamp] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const [urlValue, setUrlValue] = useState(window.location.href);
    const outsideClickRef = useRef(null)
    useOutsideClick(outsideClickRef, () => setShowModal(false))
    useEffect(() => {
        if (!showModal) {
            setLinkCopied(false);
        }
    }, [showModal])


    const handleOnClick = () => {
        if (videoRef) {
            setVideoTimeStamp(videoRef.current.currentTime);
            setShowModal(true);
        }
    }

    const handleOnStartAtChange = (event) => {

        let url = new URL(urlValue);
        let searchParams = new URLSearchParams(url.search);

        if (event.target.checked) {
            searchParams.set('timestamp', event.target.value);
        } else {
            searchParams.delete('timestamp', event.target.value);
        }
        let searchParamString = searchParams.toString();
        setUrlValue(`${url.origin}${url.pathname}${searchParamString.length > 0 ? `?${searchParamString}` : ""}`);
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(urlValue);
        setLinkCopied(true);
    }

    return (
        <>
            <div className="share-button-container pointer" onClick={handleOnClick}>
                <ShareIcon ref={shareRef} style={{ width: isSmallScreen ? '28px' : '20px', }} />
                {!isSmallScreen &&
                    <span className="share-text">Share</span>
                }
            </div>
            {
                showModal &&
                < div style={{ position: 'relative' }} ref={outsideClickRef}>

                    <div style={{ ...modalStyles, bottom: `${shareRef?.current?.scrollHeight}px`, right: `-${shareRef?.current?.scrollWidth}px` }}>


                        <FormGroup>
                            <TextField
                                id="video-share-url"
                                value={urlValue}
                                sx={
                                    textFieldStyles
                                }
                            />
                        </FormGroup>
                        <div style={{ display: 'flex', width: '100%', marginTop: '10px', alignItems: 'center' }}>

                            <FormGroup>
                                <FormControlLabel
                                    sx={{ flex: 2 }}
                                    value={videoTimeStamp}
                                    onChange={handleOnStartAtChange}
                                    control={
                                        <CheckboxWithGreenCheck
                                            icon={<UncheckIcon />}
                                            checkedIcon={<CheckIcon />}
                                        />
                                    }
                                    label={`Start at: ${getPlayTimeFormat(videoTimeStamp)}`} />
                            </FormGroup>
                            {
                                linkCopied ?
                                    <button className={classes.linkCopied}> Link Copied!</button> :
                                    <button onClick={() => { handleCopy() }} href="" className={` pointer ${classes.copyLinkButton}`}> Copy Link</button>
                            }
                        </div>
                    </div>



                </div>
            }

        </>

    );
};

export default ShareVideo;


const modalStyles = {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 99,
    position: 'absolute',
    backgroundColor: '#232627',
    color: '#ffffff',
    padding: '18px 16px',
    borderRadius: '5px',
    width: '288px',
    height: '100px'
}

const textFieldStyles = {
    borderRadius: '5px',
    '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
    },
    '& input':
    {
        color: '#ffffff',
        backgroundColor: '#494C4E',
        borderRadius: '5px',

    }
}
const CheckboxWithGreenCheck = withStyles({
    root: {
        "& .MuiSvgIcon-root": {
            fill: "white",
            "&:hover": {
                backgroundColor: "white"
            }
        },
        "&$checked": {
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
                border: "1px solid #bbbbbb",
                borderRadius: 3
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "green",
                zIndex: -1,
                borderColor: "transparent"
            }
        },
        "&:not($checked) .MuiIconButton-label": {
            position: "relative",
            zIndex: 0,
            border: "1px solid #bbbbbb",
            borderRadius: 3
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: "white",
            zIndex: -1,
            borderColor: "transparent"
        }
    },
    checked: {}
})(Checkbox);




/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useOutsideClick(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

