import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
      display: "grid",
      gridTemplateRows: 'auto auto',
      gridTemplateColumns: 'auto auto',
      margin: '0px',
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }
    },
    announcments: {
      gridColumn: "1 / 3",
      gridRow: "2 / 3",
      paddingTop: "23px"
    },
    contentLeft: {
      gridColumn: "1 / 2",
      gridRow: "3 / 4",
      paddingRight: "10px",
      width: "28vw",
      maxWidth: "339px",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        maxWidth: "781px",
        paddingRight: 0
      },
    },
    contentRight: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      paddingLeft: '10px',
      width: "64vw",
      maxWidth: "781px",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        maxWidth: "781px",
        paddingLeft: 0
      }
    },
    wrapper: {
      display: "flex",
      // width: "100vw",
      justifyContent: "center",
    },
  }));

  export default useStyles;