import React from 'react'
 export default function MultiplyFilled() {
 return (
 <>
 {/* <svg width="20" height="20" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" dataReactroot="">
<path fill="black" d="M22.7071 1.29289C23.0976 1.68342 23.0976 2.31658 22.7071 2.70711L2.70711 22.7071C2.31658 23.0976 1.68342 23.0976 1.29289 22.7071C0.902369 22.3166 0.902369 21.6834 1.29289 21.2929L21.2929 1.29289C21.6834 0.902369 22.3166 0.902369 22.7071 1.29289Z" clipRule="evenodd" fillRule="evenodd" undefined="1"></path>
<path fill="black" d="M1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L22.7071 21.2929C23.0976 21.6834 23.0976 22.3166 22.7071 22.7071C22.3166 23.0976 21.6834 23.0976 21.2929 22.7071L1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289Z" clipRule="evenodd" fillRule="evenodd" undefined="1"></path>
</svg> */}

<svg width="9" height="9" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0416 2.07458L10.9254 0.958328L6.49998 5.38375L2.07456 0.958328L0.958313 2.07458L5.38373 6.5L0.958313 10.9254L2.07456 12.0417L6.49998 7.61624L10.9254 12.0417L12.0416 10.9254L7.61623 6.5L12.0416 2.07458Z" fill="rgba(104, 104, 104, 1)" stroke="rgba(104, 104, 104, 1)" />
</svg>

</>
)}