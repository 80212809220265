import React, { useContext } from 'react';
import useStyles from './styles';

import { UserProfileContext } from './UserProfileContext';
import {v4 as uuid4} from 'uuid';

const WORK_SCHEDULES= require('../../sharedComponents/editProfileFields/workSchedule/workSchedules.json')

const UserWorkSchedule = () => {
    const classes = useStyles();

    const { profileData } = useContext(UserProfileContext);

    return (
        <>
            {
                profileData && profileData.user_work_schedule && Object.keys(profileData.user_work_schedule).length > 0 ?
                    <div id="user-profile-work-schedule-container" 
                        className={`${classes.kenzieFocusTimeContainer} ${classes.sectionMargins}`}>
                        <p className={classes.subHeader}>Work Schedule</p>
                        <div className={`open-sans ${classes.pillGroup}`}>
                            {
                                profileData.user_work_schedule.length>0 ?
                                profileData.user_work_schedule.map((schedule) => {
                                    let label=WORK_SCHEDULES.find(i=>i.id===schedule).label;
                                    return <div key={uuid4()} className={classes.pills}>
                                        {label}
                                    </div>
                                }):
                                null
                            }
                        </div>
                    </div> :
                    null
            }
        </>

    )
}

export default UserWorkSchedule;

 