import { ROLE_MAPPINGS } from '../../../constants'

export function trimAddMembersFormData(forms) {
    for (let data of forms.addMembers) {

        for (let key in data) {
            if (typeof data[key] === 'string') data[key] = data[key].trim()
        }

        if (data.user_lms_id === "") data.user_lms_id = null
    }
    return forms
}

export function trimFormData(data) {
    for (let key in data) {
        if (typeof data[key] === 'string') data[key] = data[key].trim()
    }
    if (data.user_lms_id === "") data.user_lms_id = null
    return data
}

function mapApiKeysToMemberProps(member) {
    const { user_email_mk_user, user_role_id, id } = member
    const result = {
        ...member,
        ...user_email_mk_user,
        courseRowId: id,
        roleName: ROLE_MAPPINGS[user_role_id]
    }
    return result
}

export function removeDuplicateCohortMembers(members) {
    let emails = []
    let result = []
    members = members.sort((a, b) => {
        if (a.active && !b.active) return -1
        if (!a.active && b.active) return 1
        return 0
    })

    for (let member of members) {

        if (!emails.includes(member.user_email)) {
            emails.push(member.user_email)
            result.push(member)
        }
    }
    return result
}
export function mapCohortMembersForMemberManagementPage(members) {

    let result = removeDuplicateCohortMembers(members).map(member => (
        mapApiKeysToMemberProps(member)
    ))

    result = result.sort((a, b) => {
        if (a?.last_name && b?.last_name) {
            return a?.last_name?.localeCompare(b?.last_name)
        }
        else {
            return 1
        }
    })

    result = result.sort((x, y) => {
        if (x.active && !y.active) return -1
        if (y.active && !x.active) return 1
        return 0
    })

    return result
}

export function determineTablesToUpdate(member, formData) {
    const mkUserFields = ['email', 'first_name', 'last_name', 'user_lms_id']
    const mkUserCourseFields = ['active', 'is_visible', 'user_role_id']
    let needToUpdate = { mkUserFields: [], mkUserCourseFields: [] }
    for (let key of Object.keys(formData)) {
        if (formData[key] !== member[key]) {
            if (mkUserCourseFields.includes(key)) {
                needToUpdate.mkUserCourseFields.push(key)
            }
            if (mkUserFields.includes(key)) {
                needToUpdate.mkUserFields.push(key)
            }
        }
    }

    return needToUpdate

}
export function formatFormDataTypes(formData) {
    return {
        ...formData,
        active: formData.active === 'true' ? true : false,
        user_lms_id: formData.user_lms_id ? Number(formData.user_lms_id) : null,
        user_role_id: formData.user_role_id ? Number(formData.user_role_id) : null,
        is_visible: typeof formData.is_visible === 'boolean' ? formData.is_visible : formData.is_visible === 'false' ? false : true,


    }
}