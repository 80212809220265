// import TabPanel from '@mui/lab/TabPanel';
// import Tabs from '@mui/material/Tabs';
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../../context";
import RecordingCalendar from "./calendar/RecordingCalendar";

import { getUserVideoViews } from "../../../api";
import { ReactComponent as ArrowLeftIcon } from '../../../icons/ArrowLeftIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../icons/ArrowRightIcon.svg';
import { LearnersDataContext } from "../../context";
import { sortRecentVideos, sortVideos } from "./helpers";
import WatchPageSkeleton from "./WatchPageSkeleton";
import useStyles from "./styles";
import VideoCarousel from "./VideoCarousel";

const WatchPage = () => {

    const classes = useStyles();


    const { setIsAllComponentsLoaded, userData } = useContext(AppDataContext);
    const { fetchCohortVideos, fetchGoogleCalendarIdsByCohortId, userCohorts } = useContext(LearnersDataContext);
    const [videos, setVideos] = useState([]);
    const [recentlyViewedVideos, setRecentlyViewedVideos] = useState([])
    const [recentVideos, setRecentVideos] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    // const [currentTab, setCurrentTab] = useState(0)

    const controller = new AbortController()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            //Videos in videos table are tied to cohorts through google_calendar_ids

            //first, get all Google Calendar Ids the user has access to by their cohort
            const googleCalInfo = await fetchGoogleCalendarIdsByCohortId(controller.signal)

            if (!googleCalInfo || !googleCalInfo.length) {
                //if no google cal info found for user
                setVideos([])
                setIsLoading(false)
                return;
            }
            //If Google Cal Info is found: 

            //Get all videos for each google_cal_id
            const ids = googleCalInfo.map(a => a.google_calendar_id).join(',')
            const cohortVideos = await fetchCohortVideos(true, { google_calendar_ids: ids, deleted: false, archived: false }, controller.signal)

            //sort videos by date
            const sortedVideos = sortVideos(cohortVideos)

            setVideos(sortedVideos)
            setRecentVideos(sortedVideos.slice(0, 8))

            let userVideoViews;

            try {
                userVideoViews = await getUserVideoViews(userData.email)
            } catch (error) {
                setIsLoading(false)
            }

            if (!userVideoViews || !userVideoViews.length === 0) {
                setIsLoading(false)
                return;
            }

            let recentVideoViews = [];

            sortedVideos.forEach((video) => {
                let reversedVideoViews = [...userVideoViews.message.response].reverse()
                let recentVideo = reversedVideoViews.find((view) => view.video_id === video.id)

                if (recentVideo) {
                    recentVideoViews.push({ ...video, date_last_watched: recentVideo.created_date });
                }
            })


            const sortedRecentVideoViews = sortRecentVideos(recentVideoViews)

            setRecentlyViewedVideos(sortedRecentVideoViews)
            setIsAllComponentsLoaded(true)

            setIsLoading(false)
        }

        fetchData()

    }, [userCohorts]);

    useEffect(() => {
        //makes sure null response from videos doesnt break the page
        //might not be necessary anymore
        if (videos !== null && videos.length && recentlyViewedVideos !== null && recentlyViewedVideos.length) {
            setIsLoading(false);
            setIsAllComponentsLoaded(true);
        }
    }, [videos]);


    useEffect(() => {
        //cleanup fetch calls on unmount
        return () => {
            controller.abort()

            setIsLoading(false)

        }
    }, [])

    useEffect(() => {
        let customArrowIdList = [
            "recently-added-videos",
            "recently-viewed-videos"
        ]

        const checkArrowAvailability = (customArrowId) => {
            let customRightArrow = document.getElementById(`${customArrowId}-right-arrow`)
            let customLeftArrow = document.getElementById(`${customArrowId}-left-arrow`)

            if (recentVideos.length < 5 && customArrowId === "recently-added-videos") {
                if (customRightArrow) {
                    customRightArrow.style.opacity = .4
                }
                if (customLeftArrow) {
                    customLeftArrow.style.opacity = .4
                }
            }

            if (recentlyViewedVideos.length < 5 && customArrowId === "recently-viewed-videos") {
                if (customRightArrow) {
                    customRightArrow.style.opacity = .4
                }
                if (customLeftArrow) {
                    customLeftArrow.style.opacity = .4
                }
            }
        }
        if (!isLoading) {
            customArrowIdList.forEach((arrow) => checkArrowAvailability(arrow))
        }

    }, [isLoading])

    const CustomArrowLeft = ({ id }) => {
        return (
            <div className={classes.customArrowLeft} onClick={() => leftArrowLogic(id)} id={`${id}-left-arrow`}>
                <ArrowLeftIcon onClick={() => leftArrowLogic(id)} />
            </div>
        )
    }

    const CustomArrowRight = ({ id }) => {
        return (
            <div className={classes.customArrowRight} onClick={() => rightArrowLogic(id)} id={`${id}-right-arrow`}>
                <ArrowRightIcon onClick={() => rightArrowLogic(id)} />
            </div>
        )
    }

    const leftArrowLogic = async (id) => {
        let leftArrowCarousel = document.getElementById(id).querySelector(".react-multiple-carousel__arrow--left");

        if (leftArrowCarousel) {
            await leftArrowCarousel.click();
            let rightArrowCarousel = document.getElementById(id).querySelector(".react-multiple-carousel__arrow--right");
            let customRightArrow = document.getElementById(`${id}-right-arrow`)
            let customLeftArrow = document.getElementById(`${id}-left-arrow`)

            if (rightArrowCarousel) {
                customRightArrow.style.opacity = 1
            } else {
                customRightArrow.style.opacity = .4
            }

            if (leftArrowCarousel) {
                customLeftArrow.style.opacity = 1
                if (document.getElementById(id).querySelector(".react-multiple-carousel__arrow--left")) {
                    customLeftArrow.style.opacity = 1
                } else {
                    customLeftArrow.style.opacity = .4
                }
            } else {
                customLeftArrow.style.opacity = .4
            }
        }
    }

    const rightArrowLogic = async (id) => {
        let rightArrowCarousel = document.getElementById(id).querySelector(".react-multiple-carousel__arrow--right");

        if (rightArrowCarousel) {
            await rightArrowCarousel.click();
            let leftArrowCarousel = document.getElementById(id).querySelector(".react-multiple-carousel__arrow--left");
            let customLeftArrow = document.getElementById(`${id}-left-arrow`)
            let customRightArrow = document.getElementById(`${id}-right-arrow`)

            if (leftArrowCarousel) {
                customLeftArrow.style.opacity = 1
                if (document.getElementById(id).querySelector(".react-multiple-carousel__arrow--right")) {
                    customRightArrow.style.opacity = 1
                } else {
                    customRightArrow.style.opacity = .4
                }
            } else {
                customLeftArrow.style.opacity = .4
            }
        }
    }

    //Recent Videos Tab
    const RecentVideoComponent = () => (
        <div className={classes.tabContainer} id="recently-added-videos">
            <div className={classes.tabContainerHeader}>
                <p >Recently Added</p>
                <div className={classes.tabScrollArrows} id='recently-added-video-arrows'>
                    <CustomArrowLeft id={"recently-added-videos"} />
                    <CustomArrowRight id={"recently-added-videos"} />
                </div>
            </div>
            <VideoCarousel videos={recentVideos} />
        </div>
    )

    //Recently Viewed Tab
    const RecentlyViewedVideoComponent = () => (
        <div className={classes.tabContainer} id="recently-viewed-videos" style={{ marginTop: "-16px" }}>
            <br />
            <div className={classes.tabContainerHeader}>
                <p>Recently Viewed</p>
                <div className={classes.tabScrollArrows}>
                    <CustomArrowLeft id={"recently-viewed-videos"} />
                    <CustomArrowRight id={"recently-viewed-videos"} />
                </div>
            </div>
            <VideoCarousel videos={recentlyViewedVideos} />
        </div>
    )

    if (isLoading)
        return (
            // <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh", position: "relative", top: "0px" }}>
            //     <CircularProgress />
            // </div>
            <WatchPageSkeleton />
        )
    return (

        <main className={classes.pageRootContainer}>
            <div className='content'>
                {
                    recentVideos && recentVideos.length > 0 &&
                    <RecentVideoComponent />
                }

                {
                    recentlyViewedVideos && recentlyViewedVideos.length > 0 &&
                    <RecentlyViewedVideoComponent />
                }
                <br />
                <RecordingCalendar recordings={videos.map(video => {
                    return { ...video, recordingDate: moment(new Date(video.video_details.recording_start)).format('MMDDYY') }
                })} />


            </div>
        </main >


    );
};

export default WatchPage;