import { Checkbox, FormControlLabel } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useFormikContext } from "formik"
import React from "react"
import useEditProfileStyles from "../../../sharedComponents/editProfileFields/styles/editProfileStyles"
import { ReactComponent as CheckedComponent } from "./assets/checkbox_filled.svg"
import { ReactComponent as CheckboxComponent } from "./assets/checkbox_outline.svg"
import { PlacesSearchField, TimeZone } from "./components"

const useStyles = makeStyles((theme) => ({
    paragraphText: {
        ...theme.paragraphFont,
        color: '#414141',
        textAlign: "center",
        marginBottom: "30px",
        [theme.breakpoints.down("500")]: {
            textAlign: "left",
            width: "100%"
        }
    },
    text: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px',
        marginTop: "7px",
        "& .MuiTypography-root": {
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '22px',
        }
    }
}))

const Location = ({ isEditPage }) => {
    const editProfileClasses = useEditProfileStyles()
    const {
        values,
        setFieldValue
    } = useFormikContext()
    const handleCheckboxChange = (e) => {

        if (e.target.name === "user_show_location") {
            if (!values.user_show_location || !values.user_show_timezone) {
                setFieldValue("user_show_location", true)
                setFieldValue("user_show_timezone", true)
            } else {
                setFieldValue("user_show_location", false)
                setFieldValue("user_show_timezone", false)
            }
        } else if (e.target.name === "user_show_timezone") {
            if (values.user_show_location) {
                setFieldValue("user_show_location", false)
            } else {
                setFieldValue("user_show_timezone", !values.user_show_timezone)
            }
        }
    }
    const classes = useStyles()
    return (
        <>
            <div style={{ marginTop: '1em' }} className={isEditPage ? editProfileClasses.locationText : classes.paragraphText}><span style={{ marginRight: '5px' }}>I'm located in </span><PlacesSearchField isEditPage controlId={"user_location"} controlName={"user_location"} /></div>
            <div style={{ marginTop: '1em', marginBottom: '1em' }} className={isEditPage ? editProfileClasses.locationText : classes.paragraphText}><span style={{ marginRight: '5px' }}>My timezone is</span> <TimeZone
                controlId={"user_timezone"}
                controlName={"user_timezone"}
                location={values.user_location}
                locationFieldControlId={"user_location"}

            /></div>
            {!isEditPage && <span className={classes.text}>Display on public profile:</span>}
            <span className={isEditPage ? editProfileClasses.radioContainer : ""}>
                <FormControlLabel
                    className={classes.text}
                    control={
                        <Checkbox checked={values?.user_show_location && values?.user_show_timezone} onChange={handleCheckboxChange} name="user_show_location" checkedIcon={<CheckedComponent />} icon={<CheckboxComponent />} />
                    }
                    label={isEditPage ? 'Show Location & Timezone' : 'Location & Timezone'}
                />
                <FormControlLabel
                    className={classes.text}
                    control={
                        <Checkbox checked={!values?.user_show_location && values?.user_show_timezone} onChange={handleCheckboxChange} name="user_show_timezone" checkedIcon={<CheckedComponent />} icon={<CheckboxComponent />} />
                    }
                    label={isEditPage ? "Show Timezone only" : "Timezone only"}
                />
            </span>
        </>
    )
}

export default Location