import React from 'react';
import useStyles from './styles';
import skeletonStyles from './SkeletonStyles';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';

const WatchPageSkeleton = () => {
    const classes = useStyles();
    const skeletonClasses = skeletonStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={classes.pageRootContainer}>
            <div className='content'>
                <div className={classes.tabContainer}>
                    <div className={skeletonClasses.tabHeader}>
                        <p><Skeleton variant="text" width={150} height={30} /></p>
                        <div className={classes.tabScrollArrows}>
                            <Skeleton variant="circular" width={24} height={24} sx={{borderRadius: "100%"}} />
                            <Skeleton variant="circular" width={24} height={24} sx={{borderRadius: "100%"}} />
                        </div>
                    </div>
                    <div className={skeletonClasses.tabContainerHeaderSkel}>
                        {
                            !isSmallScreen ?
                            Array.from(new Array(4)).map(() => (
                                <Skeleton variant="rectangular" width={280} height={340} sx={{borderRadius: "8px", marginRight: "20px"}}/>
                            ))
                            :
                            !isSmallerScreen ?
                            Array.from(new Array(2)).map(() => (
                                <Skeleton variant="rectangular" width="47%" height={340} sx={{borderRadius: "8px", marginRight: "20px"}}/>
                            ))
                            :
                            Array.from(new Array(1)).map(() => (
                                <Skeleton variant="rectangular" width={340} height={340} sx={{borderRadius: "8px"}}/>
                            ))
                        }
                    </div>
                    <br/>
                    <div className={skeletonClasses.tabHeader}>
                        <p><Skeleton variant="text" width={150} height={30} /></p>
                        <div className={classes.tabScrollArrows}>
                            <Skeleton variant="circular" width={24} height={24} sx={{borderRadius: "50%"}} />
                            <Skeleton variant="circular" width={24} height={24} sx={{borderRadius: "50%"}} />
                        </div>
                    </div>
                    <div className={skeletonClasses.tabContainerHeaderSkel}>
                        {
                            !isSmallScreen ?
                            Array.from(new Array(4)).map(() => (
                                <Skeleton variant="rectangular" width={280} height={340} sx={{borderRadius: "8px", marginRight: "20px"}}/>
                            ))
                            :
                            !isSmallerScreen ?
                            Array.from(new Array(2)).map(() => (
                                <Skeleton variant="rectangular" width="48%" height={340} sx={{borderRadius: "8px", marginRight: "20px"}}/>
                            ))
                            :
                            Array.from(new Array(1)).map(() => (
                                <Skeleton variant="rectangular" width={340} height={340} sx={{borderRadius: "8px"}}/>
                            ))
                        }
                    </div>
                    <div className={skeletonClasses.recordingDatePanel}>
                        {
                            !isSmallScreen ?
                            <Skeleton variant="rectangular" width="100%" height={405} />
                            :
                            <Skeleton variant="rectangular" width="100%" height={700} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default WatchPageSkeleton;