import React from 'react';

import { useStyles } from './styles';
import CountDownTimer from './CountDownTimer';

export default function EachEventHeader({
    eventType=null,
    eventState=null,
    eventItem=null
}) {

    const classes = useStyles();
    
    return (
        <div className={`mt-1 ${classes.eventHeaderContainer}`}>
            <div className={`event-type ${classes[`${eventType.id}Border`]} ${classes[`${eventType.id}BgColor`]}`}>{eventType.label}</div>
            <div className='event-state'>
                {
                    eventState && eventState==="is_live"?
                        <div data-eventstate={eventState}>Happening Now</div>:
                    eventState && eventState==="is_passed"?
                        <div data-eventstate={eventState} className={`${classes[`${eventType.id}Color`]}`}>Session has ended</div>:
                    eventState && eventState==="is_join_early"?
                        <div data-eventstate={eventState} className={`${classes[`${eventType.id}Color`]}`}>Starts {<CountDownTimer eventItem={eventItem} />}</div>:
                    eventState && eventState==="is_future"?
                        <div data-eventstate={eventState} className={`${classes[`${eventType.id}Color`]}`}>Starts {<CountDownTimer eventItem={eventItem} />}</div>:
                    null
                }
            </div>
        </div>
    )
}