import {
    FormControl, FormControlLabel,
    FormLabel, Radio, MenuItem,
    RadioGroup, Select, TextField, Typography, ListSubheader
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { DateTimePicker } from "@mui/x-date-pickers";

import { useFormikContext } from 'formik';

import { AppDataContext } from '../../../context';
import { ACPDataContext } from '../../context';
import { groupUserCohortsByPrograms } from '../baseLayout/helpers';

import { useStyles } from './styles';

import AnnouncementRichText from './AnnouncementRichText';

const AnnouncemetEntryFields = ({editView}) => {

    const classes = useStyles();

    let {
        values, 
        setFieldValue,
        handleChange,
    }=useFormikContext();

    const { userRole } = useContext(AppDataContext);
    const { userCourses } = useContext(ACPDataContext);
    const [cohortsByProgram, setCohortsByProgram] = useState([]);
    const [showSchedulePostField, setShowSchedulePostField] = useState({
        radio_value: null,
        show_schedule_field: false,
    })

    useEffect(() => {
        if(userCourses) {
            setCohortsByProgram(groupUserCohortsByPrograms(userCourses))
        }
    }, [])
    
    const abbreviateProgram = (program) => {
        if((/cybersecurity/i).test(program)) return "CS"
        if((/ux design/i).test(program)) return "UXD"
        if((/test/i).test(program)) return "Test"
        let eachWord = program.split(" ")
        let letters = [];
        let regex = /program/i

        for(let word of eachWord) {
            if(regex.test(word)) {
                continue;
            }
            letters.push(word.charAt(0))
        }
        return letters.join("")
    }
    
    const checkProgramTitle = (program) => {
        let regex = /\bprogram\b/i
        if(regex.test(program)) {
            return program.replace(regex, "")
        }
        return program
    }
    
    const renderPrograms = (program) => {
        let programClone = structuredClone(program)
        let dynamicMenuItem = programClone.cohorts.find((cohort) => cohort.cohort_name.includes("All"))

        if(!dynamicMenuItem) {
            for(let i=0; i<1; i++) {
                // program id will trigger api to post announcements to all cohorts in specific program
                programClone.cohorts.unshift({cohort_name: `All ${abbreviateProgram(programClone.program_name)} cohorts`})
            }
        }
        
        const checkAllCohortsOption = (cohort) => {
            if(cohort.cohort_name.includes("All")) {
                return true
            }
        }
        
        const cohorts = programClone.cohorts.map((cohort) => (
            
            userRole?.acpPermissions.functions?.annoucementsCanSendAnnoucementsToEveryone ?
            
            <MenuItem key={uuid4()} value={checkAllCohortsOption(cohort) ? `program-${programClone.id}` : cohort.id}>{cohort.cohort_name}</MenuItem>
            :
            !checkAllCohortsOption(cohort) &&
            <MenuItem key={uuid4()} disabled>{cohort.cohort_name}</MenuItem>
            
            ));
            return [
                <ListSubheader sx={{
                    color: "#1D3969",
                    fontSize: "13px",
                    fontWeight: 600,
                    fontFamily: "Nunito",
                }}>
                {checkProgramTitle(programClone.program_name)}
            </ListSubheader>, 
            cohorts
        ]
    }

    //Future implementation to validate schedule and expiration date
    //const [inValidDates,setInValidDates]=useState(false);

    const setPostTime = (val, name) => {
        setFieldValue(name, val);
        let d = moment(val);
        setFieldValue("post_expiration", d.add(1, 'day').toDate());
    }

    const handlePostScheduleRadio = (e) => {
        
        let val;

        if(e.target.value === "now") {
            if(showSchedulePostField !== false) {
                setShowSchedulePostField({
                    show_schedule_field: false,
                    radio_value: e.target.value
                })
            }
            // Set scheduled post time to the current date-time and set field value
            val = moment.utc(
                moment.utc().valueOf()
            ).toDate()

            setPostTime(val, "post_schedule")

        } else {
            setShowSchedulePostField({
                show_schedule_field: true,
                radio_value: e.target.value
            })
        }
    }

    const handleCohortSelect = (e) => {
        if(typeof e.target.value === "string") {
            // regexNumber will match the beginning and end of consecutive numbers
            let regexAnyNumber = /\d/
            let program = e.target.value
            let program_id = parseInt(program.match(regexAnyNumber)[0])
            
            setFieldValue("program_id", program_id);
            setFieldValue("cohort_id", null);
            setFieldValue("audience", program_id);

            return
        }
        setFieldValue("cohort_id", e.target.value);
        setFieldValue("audience", e.target.value);
    }
    
    return (
        <>
            <div className={classes.formControlSection}>
                <AnnouncementRichText 
                    name="body"
                    id={uuid4()}
                    placeholderText='We are announcing ...'
                />
            </div>
            <div className={classes.formControlSection}>
                <FormControl>
                    <FormLabel id="create-announcement-author-label" sx={{'&.Mui-focused': {color: "#1D3969"}, color: "#1D3969", fontSize: "14px", fontWeight: 500, fontFamily: "Nunito" }}>Post as:</FormLabel>
                    <RadioGroup
                        row
                        id="create-announcement-group-radios-label"
                        data-testid="create-announcement-group-radios-label"
                        aria-labelledby="create-announcement-group-radios-label"
                        name="post_as"
                        value={values.post_as?values.post_as:""}
                        onChange={(e) => handleChange(e)}
                        sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 18,
                              color: "#1D3969"
                            },
                        }}
                    >
                        <FormControlLabel
                            value="myself"
                            id='create-announcement-myself-radiobutton'
                            control={<Radio />}
                            label={<Typography color="#414141" fontFamily="Nunito" fontWeight="500" marginLeft="-3px" >Myself</Typography>}
                        />
                        <FormControlLabel 
                            value="kenzie"
                            id='create-announcement-kenzie-radiobutton'
                            control={<Radio />}
                            label={<Typography color="#414141" fontFamily="Nunito" fontWeight="500" marginLeft="-3px">Kenzie Academy</Typography>} 
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {
                !editView &&
                
                <div className={classes.formControlSection} id="announcement-author-label">
                    <FormControl>
                        <FormLabel id="create-announcement-audience-label" sx={{'&.Mui-focused': {color: "#1D3969"}, color: "#1D3969", fontSize: "14px", fontWeight: 500, fontFamily: "Nunito" }}>Audience</FormLabel>
                        <Select
                            sx={{
                                height: "40px",
                                width: "315px",
                                marginTop: "6px"
                            }}
                            id="create-announcement-audience-label"
                            defaultValue={
                                values.program_id ? 
                                values.program_id 
                                :
                                values.cohort_id ? 
                                values.cohort_id : 0
                            }
                            onChange={(e) => {handleCohortSelect(e)}}
                        >
                            <MenuItem value={0} disabled>Select one...</MenuItem>
                            
                                <ListSubheader sx={{
                                color: "#1D3969",
                                fontSize: "13px",
                                fontWeight: 600,
                                fontFamily: "Nunito",
                                }}>
                                    Kenzie Academy
                                </ListSubheader>
                                <MenuItem value={'program-8'}>All cohorts across all programs</MenuItem>
                            {
                                userCourses && cohortsByProgram.length > 0 ?

                                cohortsByProgram.map((program) => (
                                    renderPrograms(program)
                                ))
                                :
                                null
                            }
                        </Select>
                    </FormControl>
                </div>
            }
            <div className={classes.formControlSection} id="when_to_post_radio">
                <FormControl>
                    <FormLabel id="schedule-post-label" sx={{'&.Mui-focused': {color: "#1D3969"}, color: "#1D3969", fontSize: "14px", fontWeight: 500, fontFamily: "Nunito" }}>When to post:</FormLabel>
                    <RadioGroup
                        row
                        id="schedule-post-group-radios-label"
                        data-testid="schedule-post-group-radios-label"
                        aria-labelledby="schedule-post-group-radios-label"
                        name="post_schedule"
                        defaultValue="now"
                        value={
                            showSchedulePostField.radio_value ? 
                            showSchedulePostField.radio_value
                            :
                            "now"
                        } 
                        onChange={(e) => {handlePostScheduleRadio(e)}}
                        sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 18,
                              color: "#1D3969"
                            },
                        }}
                    >
                        <FormControlLabel
                            value="now"
                            id='schedule-post-now-radiobutton'
                            control={<Radio />}
                            label={<Typography color="#414141" fontFamily="Nunito" fontWeight="500" marginLeft="-3px" >Now</Typography>}
                        />
                        <FormControlLabel 
                            value="later"
                            id='schedule-post-later-radiobutton'
                            control={<Radio />}
                            label={<Typography color="#414141" fontFamily="Nunito" fontWeight="500" marginLeft="-3px">Later</Typography>} 
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {
                showSchedulePostField.show_schedule_field &&

                <div className={classes.formControlSection} id="schedule_post">
                    <FormLabel sx={{'&.Mui-focused': {color: "#1D3969"}, color: "#1D3969", fontSize: "14px", fontWeight: 500, fontFamily: "Nunito", marginBottom: "6px" }}>Schedule Post</FormLabel>
                    <DateTimePicker
                        name="post_schedule"
                        inputVariant="outlined"
                        value={values.post_schedule ? values.post_schedule : ""}
                        onChange={(value) => {
                            setPostTime(value, "post_schedule");
                        }}
                        onBlur={(value) => {
                            setPostTime(value, "post_schedule")
                        }}
                        renderInput={(params) => <TextField {...params} sx={{'& .MuiInputBase-input': {
                            height: "8px"
                        }}}/>}
                        minDateTime={new Date()}
                    />
                </div>
            } 
            <div className={classes.formControlSection} id="post_expiration">
                <FormLabel sx={{'&.Mui-focused': {color: "#1D3969"}, color: "#1D3969", fontSize: "14px", fontWeight: 500, fontFamily: "Nunito", marginBottom: "6px" }}>Post Expiration</FormLabel>
                <DateTimePicker
                    name="post_expiration"
                    inputVariant="outlined"
                    value={values.post_expiration ? values.post_expiration : ""}
                    onChange={(value) => {
                        setFieldValue("post_expiration", value)
                    }}
                    onBlur={(value) => {
                        setFieldValue("post_expiration", value)
                    }}
                    minDateTime={values.post_schedule}
                    renderInput={(params) => <TextField {...params} sx={{'& .MuiInputBase-input': {
                        height: "8px"
                    }}}/>}
                />
            </div>

            <div style={{
                color: "#414141",
                fontSize: "12px",
                marginTop: "-16px",
                fontFamily: "Nunito"
            }}>
                Selections will reflect your current timezone.
            </div>
        </>

    )

}


export default AnnouncemetEntryFields;

