import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    /**
     * Learner/Staff Page Styles
     * These styles apply to the entire 'learnerStaffDirectory' page
     */
    directoryWrapper: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        marginBottom: "90px",
        minHeight:"100vh"
    },
    directoryStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "28px",
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column-reverse"
        }
     },
    filterSearch: {
        display: "flex",
        alignItems: "center"
    },
    filters: {
        display: "flex"
    },
    mkHeader: {
        marginBottom: "53px",
        // This MUI style already has a position: "relative" property and can be moved in all directions
        '& .MuiAvatar-root': {
            right: "-18px"
        }
    },
    directoryCohort: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Nunito",
        '& #cohortTitle': {
            fontWeight: 500
        },
        '& #cohortQuantity': {
            fontSize: "12px",
            fontWeight: 500,
            color: "#515151"
        },
        '& #search-queries': {
            fontWeight: 500
        },
        '& #search-quantity': {
            fontSize: "12px",
            fontWeight: 500,
            color: "#515151"
        },
        '& #clear-results': {
            fontSize: "12px",
            fontWeight: 500,
            color: "#515151",
            textDecoration: "underline",
            marginLeft: "20px",
            '&:hover': {
                cursor: "pointer"
            }
        },
    },
    onlineFilterBtn: {
        backgroundColor: "white",
        boxSizing: "border-box",
        border: "1px solid #9B9B9B",
        width: "125px",
        height: "32px",
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Nunito",
        color: "#5B5B5B",
        transition: "box-shadow 300ms",
        boxShadow: "3px 4px 8px rgb(190 190 190)",
        '&:hover': {
            cursor: "pointer",
        },
    },
    onlineFilterBtnActive: {
        border: "2.5px solid #1D3969",
        backgroundColor: "#E4EEFF",
        fontWeight: 'bolder'
    },
    starredBtn: {
        backgroundColor: "white",
        boxSizing: "border-box",
        border: "1px solid #9B9B9B",
        width: "101px",
        height: "32px",
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Nunito",
        marginLeft: "15px",
        color: "#5B5B5B",
        transition: "box-shadow 300ms",
        boxShadow: "3px 4px 8px rgb(190 190 190)",
        '&:hover': {
            cursor: "pointer",
        },
    },
    starredIcon: {
        opacity: .5,
        marginRight: "5px",
    },
    activeStarIcon: {
        fill: "#FFDE69",
        opacity: 1
    },
    starFilterAnimation: {
        animationName: "$starFilterAnimation",
        animationDuration: "500ms",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationTimingFunction: "ease-out"
    },
    '@keyframes starFilterAnimation': {
        from: {
            transform: "rotate(0deg)",
            opacity: .5,
        },
        to: {
            transform: "rotate(360deg)",
            opacity: 1,
            fill: "#FFDE69",
        }
    },
    directoryCardGallery: {
        position: "relative",
        display: "grid",
        flexWrap: "wrap",
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(5, 20% [col-start])',
        },
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(4, 25% [col-start])',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(3, 33% [col-start])'
        },
        [theme.breakpoints.down('640')]: {
            gridTemplateColumns: 'repeat(2, 50% [col-start])'
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 50% [col-start])'
        },
        '& .eachCardContainer': {
            backgroundColor: "white",
            position: "relative",
            overflow: "hidden",
            transition: "box-shadow 320ms",
            [theme.breakpoints.up('sm')]: {
                marginRight: "20px",
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: "10px",
            },
            marginBottom: "25px",
            borderRadius: "6px",
            height: "300px",
            // width: "205px",
            textAlign: "center",
            '&:hover': {
                cursor: "pointer",
                boxShadow: "6px 4px 6px rgb(180,180,180)",
            },
            '& a': {
                color: "#000000",
                textDecoration: "none"
            }
        },
        //Not in use keepin it for reference:
        // '& .eachCardContainer:nth-child(2n)':{
        //    [theme.breakpoints.down('sm')]:{
        //       marginRight:"0px !important",
        //    }
        // },
        '& .eachCardContainer[data-usertype="staff"]': {
            border: "1.5px solid #1D3969",
            minWidth: "18.5%",
        },
        '& .eachCardContainer[data-usertype="learner"]': {
            border: "1.5px solid #9B9B9B",
            minWidth: "18.5%",
        },
    },
    updateIndicator: {
        position: "absolute",
        left: "-39px",
        top: "26px",
        transform: "rotate(-45deg)",
        fontFamily: "open sans",
        height: "20px",
        backgroundColor: "#C2573F",
        width: "150px",
        color: "white",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: "600",
        textTransform: "uppercase",
        [theme.breakpoints.down('sm')]: {
            top: "20px",
            left: "-45px"

        },
    },
    cardContentUpdateIndicator: {
        border: "1.5px solid #C2573F !important"
    },
    onlineFilterAnimation: {
        animationName: "$onlineFilterAnimation",
        animationDuration: "100ms",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    '@keyframes onlineFilterAnimation': {
        from: {
            backgroundColor: "000000"
        },
        to: {
            border: "2.5px solid #1D3969",
            backgroundColor: "#E4EEFF"
        }
    },
    filterAnimationOn: {
        animationName: "$filterAnimationOn",
        animationDuration: "100ms",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    '@keyframes filterAnimationOn': {
        from: {
            backgroundColor: "000000"
        },
        to: {
            border: "2.5px solid #1D3969",
            backgroundColor: "#E4EEFF"
        }
    },
    filterAnimationOff: {
        animationName: "$filterAnimationOff",
        animationDuration: "100ms",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    '@keyframes filterAnimationOff': {
        from: {
            border: "2.5px solid #1D3969",
            backgroundColor: "#E4EEFF"
        },
        to: {
            backgroundColor: "000000"
        }
    },

    /**
     * STAFF CARD STYLES
     */
    staffCardImageBorder: {
        width: "96px",
        height: "96px",
        border: "2.8px solid #1D3969",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(375)]: {
            width: "22vw",
            height: "22vw"
        }
    },
    staffCardName: {
        fontSize: "16px",
        fontFamily: "Raleway, arial, sans-serif",
        fontWeight: 700,
        color: "#1D3969",
        marginBottom: "-14px",
        [theme.breakpoints.down(472)]: {
            fontSize: "12px"
        }
    },
    staffCardTitle: {
        position: "absolute",
        bottom: "0px",
        color: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: 550,
        fontFamily: "Raleway, arial, sans-serif",
        height: "24px",
        width: "100%",
        background: "#1D3969",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
    },

    /**
     * LEARNER CARD STYLES
     */
    learnerCardImageBorder: {
        width: "96px",
        height: "96px",
        border: "2.8px solid #63C29D",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(375)]: {
            width: "22vw",
            height: "22vw"
        }
    },
    learnerCardName: {
        fontSize: "16px",
        fontFamily: "Raleway, arial, sans-serif",
        fontWeight: 700,
        color: "#14606B",
        marginBottom: "-14px",
        [theme.breakpoints.down(472)]: {
            fontSize: "12px"
        }
    },

    /**
     * SearchField
     */
    searchBox: {
        position: "relative",
        marginRight:"20px",
        marginLeft: "15px",
        [theme.breakpoints.down('sm')]: {
            marginRight:"10px",
            marginBottom:"25px"
        }
    },
    searchForm: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        border: "1px solid #9B9B9B",
        borderRadius: "10px",
        height: "36px",
        width: "326px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "7px",
        '&:focus-within': {
            outline: "none",
            border: "1px solid #63C29D",
            borderBottomLeftRadius:"10px",
            borderBottomRightRadius:"10px",
        }
    },
    searchField: {
        height: "100%",
        width: "100%",
        border: "none",
        fontSize: "12px",
        fontWeight: 500,
        marginLeft: "20px",
        '&::placeholder': {
            fontFamily: "Raleway, arial, sans-serif",
            fontStyle: "italic",
            fontSize: "14px"
        },
        '&:focus-within': {
            outline: "none"
        }
    },
    searchBtn: {
        display: "flex",
        alignItems: "center",
        background: "transparent",
        border: "none",
        '& svg': {
            borderRadius: "50%",
            transition: "transform 150ms, background-color 300ms",
            '&:hover': {
                transform: "scale(1.1)",
                cursor: "pointer"
            }
        }
    },
    searchDropDown: {
        position:"absolute",
        backgroundColor:"white",
        border: "1px solid  #63C29D",
        borderTop:"0px",
        borderBottomLeftRadius:"10px",
        borderBottomRightRadius:"10px",
        zIndex:1,
        top:"33px",
        right:"0px",
        left:"0px",
        height: "auto",
        overflowY:"auto",
        fontSize: "14px",
        fontWeight: 400,
        marginBottom:"10px",
        transition: "height 1s ease-in",
        '& .each-search-history-item':{
            padding:"10px 0px",
            '&:hover': {
                backgroundColor:"whitesmoke",
                cursor:"pointer"
            },
            '& .each-search-history-inner-container':{
                padding:"0px 15px",
                display: "flex",
                alignItems: "center"
            }
        }
    },
    searchHistoryReveal: {
        animationName: "$searchHistoryReveal",
        animationDuration: "200ms",
        animationIterationCount: 1,
        animationFillMode: "forwards"
    },
    '@keyframes searchHistoryReveal': {
        from: {   
            transform: "scale(0)",
            webKitTransform:"scale(0)",
            opacity: 0
        },
        to: {
            transform: "scale(1)",
            webKitTransform:"scale(1)",
            opacity: 1
        }
    },
    /**
     * SHARED CARD STYLES
     */
    cardContent: {
        padding: "31px 10px 0px 10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(375)]: {
            height: "28vh"
        }
    },
    cardImage: {
        width: "88px",
        height: "88px",
        borderRadius: "50%",
        [theme.breakpoints.down(375)]: {
            width: "20vw",
            height: "20vw"
        }
    },
    image: {
        borderRadius: "50%",
        height: "88px",
        width: "88px",
        [theme.breakpoints.down(375)]: {
            width: "20vw",
            height: "20vw"
        }
    },
    cardLocation: {
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Raleway, arial, sans-serif",
        marginBottom: "0px",
        [theme.breakpoints.down(375)]: {
            fontSize: "11px"
        }
    },
    noCardLocation: {
        visibility: "hidden",
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Raleway, arial, sans-serif",
        marginBottom: "0px",
        [theme.breakpoints.down(375)]: {
            fontSize: "11px"
        }
    },
    cardTimezone: {
        fontSize: "12px",
        fontWeight: 500,
        fontFamily: "Raleway, arial, sans-serif",
        marginBottom: "-10px",
        marginTop: "-2px",
        [theme.breakpoints.down(375)]: {
            fontSize: "11px"
        }
    },
    cardOnlineStatus: {
        fontSize: "12px",
        fontWeight: 550,
        fontFamily: "Open Sans, arial, sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "33px",
        letterSpacing: ".5px",
        [theme.breakpoints.down(375)]: {
            fontSize: "11px"
        }
    },
    onlineStatusIcon: {
        marginRight: "4px"
    },
    offlineStatusIcon: {
        height: "6.2px",
        width: "6.2px",
        borderRadius: "50%",
        border: "1px solid rgb(140, 140, 140)",
        marginRight: "4px",
    },

    /**
     * Favorite Card Styles
     */

    cardStar: {
       position:"absolute",
       top:"15px",
       right:"15px",
    },
    cardStarAnimationOn: {
        '& svg': {
            animationName: "$cardStarAnimationOn",
            animationDuration: "300ms",
            animationIterationCount: 1,
            animationFillMode: "forwards"
        }
    },
    '@keyframes cardStarAnimationOn': {
        from: {
            transform: "scale(.3)",
            opacity: .5,
            fill: "000000"
        },
        to: {
            transform: "scale(1)",
            opacity: 1,
            fill: "#FFD645"
        }
    },
    cardStarAnimationOff: {
        '& svg': {
            animationName: "$cardStarAnimationOff",
            animationDuration: "300ms",
            animationIterationCount: 1,
            animationFillMode: "forwards"
        }
    },
    '@keyframes cardStarAnimationOff': {
        from:{
            transform: "scale(.3)",
            fill: "#FFD645"
        },
        to: {
            transform: "scale(1)",
            opacity: .5,
            fill: "000000"
        }
    },

    kBotContainer:{
        marginTop:"20px",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            top:"50%",
        }
    },
    kBotLives:{
        width:"300px",
        height:"auto"
    },
    noResultsText: {
        fontFamily: "Raleway, arial, sans-serif !important",
        '& #no-results-header': {
            fontSize: "24px",
            color: theme.colors.deepAqua
        },
        '& #no-results-message': {
            fontSize: "16px"
        }
    },
    '@global': {
        /**
         * Learner card data attributes
         */

        // Unfavorite cards will not show with favorite filter ON
        '.eachCardContainer[data-usertype="learner"][data-isfavoritescreen="true"][data-isfavorite="false"]': {
            display: "none"
        },
        // offline cards will not show with online filter ON
        '.eachCardContainer[data-usertype="learner"][data-isonlinescreen="true"][data-isonline="false"]': {
            display: "none"
        },
        // offline and unfavorite cards will not show if BOTH filters are ON
        '.eachCardContainer[data-usertype="learner"][data-isonlinescreen="true"][data-isfavoritescreen="true"][data-isonline="false"][data-isfavorite="false"]': {
            display: "none"
        },
        '.eachCardContainer[data-usertype="learner"][data-issearchscreen="true"] [data-issearched="false"]': {
            display: "none"
        },

        /**
         * Staff card data attributes 
         */
        
        // Unfavorite cards will not show with favorite filter ON
        '.eachCardContainer[data-usertype="staff"][data-isfavoritescreen="true"][data-isfavorite="false"]': {
            display: "none"
        },
        // offline cards will not show with online filter ON
        '.eachCardContainer[data-usertype="staff"][data-isonlinescreen="true"][data-isonline="false"]': {
            display: "none"
        },
        // offline and unfavorite cards will not show if BOTH filters are ON
        '.eachCardContainer[data-usertype="staff"][data-isonlinescreen="true"][data-isfavoritescreen="true"][data-isonline="false"][data-isfavorite="false"]': {
            display: "none"
        },
        '.eachCardContainer[data-usertype="staff"][data-issearchscreen="true"][data-issearched="false"]': {
            display: "none"
        },
        '.clearResults': {
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "open sans",
            color: "#515151",
            textDecoration: "underline",
            marginLeft: "0px",
            '&:hover': {
                cursor: "pointer"
            }
        }
    },
}))

export default useStyles;

