import { Grid } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useFormikContext } from "formik"
import React from "react"
import useEditProfileStyles from "../../../sharedComponents/editProfileFields/styles/editProfileStyles"
import EditProfileExperienceCheckbox from "../learnerEditProfile/EditProfileExperienceCheckbox"
import { BooleanSelect, CustomSlider as Slider, ProgrammingLanguagesField } from "./components"
const useStyles = makeStyles((theme) => ({
    sliderWrap: {
        width: "50%",
        [theme.breakpoints.down("1000")]: {
            width: "80%"
        },
        [theme.breakpoints.down("500")]: {
            width: "100%"
        }
    },
    experience: {
        color: "#14606B"
    }
}))

const Experience = ({ isEditProfilePage }) => {
    const {
        values,
        setFieldValue
    } = useFormikContext()
    const classes = useStyles()
    const editProfileStyles = useEditProfileStyles()
    const [shouldShowTouched, setShouldShowTouched] = React.useState(null)
    const handleShouldShow = (e) => {
        if (!shouldShowTouched) {
            setShouldShowTouched(true)
        }
        if (e.target.value === false) {
            setFieldValue("user_programming_experience_level", "Do not prefer")
        } else if (e.target.value === true && values.user_programming_experience_level === "Do not prefer") {
            setFieldValue("user_programming_experience_level", "Beginner")
        }
    }

    const getValue = () => {
        return shouldShowTouched === null ?
            shouldShowTouched
            :
            values.user_programming_experience_level !== "Do not prefer"
    }
    return (
        <Grid container direction="column" spacing={0} rowSpacing={5} wrap="nowrap">
            <Grid item xs={5} style={{ display: "flex", flexDirection: "column", alignItems: isEditProfilePage ? "flex-start" : "center" }}>
                <p style={isEditProfilePage ? { marginTop: '20px' } : {}} className={isEditProfilePage ? editProfileStyles.fieldLabelText : ""}>{isEditProfilePage ? 'Programming Experience Level:' : 'My level of experience is:'} <span style={isEditProfilePage ? { fontWeight: 700 } : {}} className={classes.experience}>{values.user_programming_experience_level === "Do not prefer" ? "(not displayed)" : values.user_programming_experience_level}</span></p>
                <div className={classes.sliderWrap}>
                    <Slider />
                </div>
                {isEditProfilePage ? <EditProfileExperienceCheckbox values={values} handleSetFieldValue={setFieldValue} />
                    :
                    <span style={{marginTop: "20px"}}>
                        <BooleanSelect value={getValue()} onChange={handleShouldShow}>Are you comfortable sharing this with your classmates?</BooleanSelect>
                    </span>
                }
            </Grid>
            <Grid item xs={5} style={{ display: "flex", flexDirection: "column", alignItems: isEditProfilePage ? 'flex-start' : "center" }}>
                <p style={{ marginBottom: '4px' }} className={isEditProfilePage ? editProfileStyles.fieldLabelText : ""}>{isEditProfilePage ? "Programming Languages Known" : "I know the following programming languages:"}</p>
                <div style={{ width: "100%", maxWidth: "730px", marginTop: "10px" }}>
                    <ProgrammingLanguagesField controlId="user_programming_languages" controlName="user_programming_languages" isEditProfilePage={isEditProfilePage} />
                </div>
            </Grid>
        </Grid>
    )
}
export default Experience