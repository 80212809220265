import { MenuItem, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getUsers } from '../../../../api/userManagement';
import { hideOffFocusEventHandler } from '../../../../helpers/utils';
import { excludeInstructorsFromSearch } from './helpers';
import { useStyles } from "./styles";

export const UserSearchField = ({
    onUserSelection = function () { },
    cohortId,
    courseId
}) => {

    const classes = useStyles();


    const containerRef = useRef(null);
    const inputFieldRef = useRef(null);

    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [hideSearchResultsBox, setHideSearchResultsBox] = useState(true);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    };

    useEffect(() => {
        if (containerRef !== null) {
            hideOffFocusEventHandler(containerRef.current, () => {
                setHideSearchResultsBox(true);
            })
        }
    }, [])

    useEffect(() => {
        if (hideSearchResultsBox) {
            inputFieldRef.current.value = "";
        }
    }, [hideSearchResultsBox]);

    useEffect(() => {
        setHideSearchResultsBox(true);
        inputFieldRef.current.value = "";
    }, [selectedUsers])

    const handleSearchInput = async (event) => {

        try {
            if (event.target.value.length === 0) {
                setSearchResults([]);

            } else {
                setIsSearching(true);
                setHideSearchResultsBox(false);

                let response = await getUsers({
                    username: `*${event.target.value}*`,
                    expand: "cohorts",
                    attributes: "username,email,first_name,last_name,user_image_url",
                    limit: 100
                })

                //Exclude users 
                //See logic explanation in excludeInstructorsFromSearch
                response = excludeInstructorsFromSearch({
                    dataset: response,
                    cohortId: cohortId,
                    selectedUsers: selectedUsers
                });

                //;
                setSearchResults(response);
                setIsSearching(false);
            }

        } catch (error) {
            console.error(error)
        }

    };

    const onClickHandler = async (staff) => {
        //;
        let d = [...selectedUsers];

        let userCourseObject = {
            "user_email": staff.username,
            "active": true,
            "cohort_id": cohortId,
            "course_id": courseId,
            "user_role_id": 0,
            "user_email_mk_user": {
                "email": staff.username,
                "first_name": staff.first_name,
                "last_name": staff.last_name,
                "user_image_url": staff.user_image_url,
                "username": staff.username
            }
        };

        d.push(userCourseObject);

        setSelectedUsers(d);
        setSearchResults([]);//empty search results 
        setHideSearchResultsBox(true);//Hide the searhc results box 

        onUserSelection(userCourseObject, true);//send data to parent component

    }

    return (
        <div className={classes.searchInputBlock} ref={containerRef}>
            <div>
                <input className={classes.searchInput}
                    ref={inputFieldRef}
                    onFocus={() => { setHideSearchResultsBox(false) }}
                    type="text"
                    placeholder='Search and add staff by email address'
                    onInput={debounce(handleSearchInput, 300)} />
            </div>

            {
                !hideSearchResultsBox ?
                    <div className={classes.searchedItems}>
                        {
                            isSearching ?
                                <div >
                                    <div>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </div>
                                </div> :
                                !isSearching && searchResults.length > 0 ?
                                    <>
                                        {
                                            searchResults.map(item => {
                                                return <div key={item.username}
                                                    style={{
                                                        marginBottom: "10px"
                                                    }}>
                                                    <MenuItem
                                                        className={classes.searchedItem}
                                                        onClick={() => onClickHandler(item)}>
                                                        <div style={{
                                                            fontWeight: "bold"
                                                        }}>{item.first_name} {item.last_name}</div>
                                                        <div>{item.username}</div>
                                                    </MenuItem>
                                                </div>
                                            })
                                        }
                                    </>
                                    :
                                    !isSearching && searchResults.length === 0 ?
                                        <div className={classes.searchedItem_noresult}>No results to display. Search user with emailid</div> :
                                        null
                        }
                    </div> :
                    null
            }

        </div>
    )
}
