import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid4 } from 'uuid';

import { MediaContext } from "../../index";

import { getTotalSecondsFromTimeStamp } from "../../../watch/utils";

const MediaMarkers = ({
    videoRef
}) => {

    const { videoMarkers } = useContext(MediaContext);

    const [duration, setDuration] = useState(videoRef?.current?.duration || 0);//get sthe total duration of the video
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        let data = videoMarkers.map(marker => {
            return Object.assign(marker, {
                totalSeconds: getTotalSecondsFromTimeStamp(marker.start)
            });
        })
        setMarkers(data);
    }, [videoMarkers]);

    useEffect(() => {

        let controller=new AbortController();

        if (videoRef?.current) {

            videoRef.current.addEventListener('loadedmetadata', function () {
                setDuration(videoRef.current?.duration);
            },{signal:controller.signal});
            
        }

        return(()=>{
            controller.abort();
        })

    }, [videoRef]);

    /** Handle on marker click */
    const handleOnMarkerClick = (marker) => {
        if (marker.totalSeconds) {
            videoRef.current.currentTime = marker.totalSeconds;
        }
    }

    return (
        <div className="video-marker-container">
            {
                markers.length > 0 ?
                    markers.map((marker, indx) => {
                        return <div className="video-marker" key={uuid4()}
                            onClick={() => { handleOnMarkerClick(marker) }}
                            style={{ left: `${(marker.totalSeconds / duration) * 100}%` }}>
                            <div className="video-marker-hover-popup">
                                <div dangerouslySetInnerHTML={{ __html: marker.node }}></div>
                            </div>
                        </div>
                    }) :
                    null
            }
        </div>
    );
};

export default MediaMarkers;
