// useDocumentTitle.js https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
import { useEffect, useRef } from 'react';



function useDocumentTitle(prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = "Academic Control Panel";
  }, []);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle