import React from 'react';
import { makeStyles } from '@mui/styles';
import useStyles from './styles';

import AnnouncementSkeleton from './AnnouncementSkeleton';
import MyCoursePanelSkeleton from './myCoursesPanel/MyCoursePanelSkeleton';
import EventPanelSkeleton from './events/EvenetPanelSkeleton';
import WebToolPanelSkeleton from './webTools/WebToolPanelSkeleton';
import ConnectPanelSkeleton from './connectPanel/ConnectPanelSkeleton';
import SupportTeamPanelSkeleton from './supportTeams/SupportTeamSkeleton';

const skeletonStyles = makeStyles((theme) => ({
    announcements: {
        gridColumn: "1 / 3",
        gridRow: "2 / 3",
        paddingTop: "23px"
    },
    contentLeft: {
        gridColumn: "1 / 2",
        gridRow: "3 / 4",
        paddingRight: "10px",
        width: "28vw",
        maxWidth: "339px",
        [theme.breakpoints.down("sm")]: {
            width: "90vw",
            maxWidth: "781px",
            paddingRight: 0
        },
    },
    contentRight: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        paddingLeft: '10px',
        width: "64vw",
        maxWidth: "781px",
        [theme.breakpoints.down("sm")]: {
            width: "90vw",
            maxWidth: "781px",
            paddingLeft: 0
        }
    }
}));

const DashboardSkeleton = () => {
    
    const skeletonClass = skeletonStyles();
    const classes = useStyles();

    return (
        <>
            <div className={classes.announcments}>
                <AnnouncementSkeleton/>
            </div>
            <div className={skeletonClass.contentLeft}>
                <WebToolPanelSkeleton/>
                <ConnectPanelSkeleton/>
                <SupportTeamPanelSkeleton/>
            </div>
            <div className={skeletonClass.contentRight}>
                <MyCoursePanelSkeleton/>
                <EventPanelSkeleton/>
            </div>
        </>
    
    )
}

export default DashboardSkeleton;