import React, { useContext, useEffect, useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { MediaContext } from '..';
import SearchVideo from "../SearchVideo";
import ChatPanel from "./ChatPanel";
import TabDropDown from "./TabDropDown";
import TranscriptPanel from "./TranscriptPanel";
import useVideoMetaStyles from "./videoMetaStyles";
let TABS = [
    {
        label: "Audio Transcript",
        id: "transcript"
    },
    {
        label: "Chat History",
        id: "chat"
    },

]

const VideoMetaPanel = ({
    videoRef
}) => {

    const [currentTab, setCurrentTab] = useState(TABS[0]);


    const classes = useVideoMetaStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { hasChatHistory, transcriptAvailable } = useContext(MediaContext);
    useEffect(() => {
        if (!transcriptAvailable) {

            TABS = ([{
                label: "Chat History",
                id: "chat"
            }])

            setCurrentTab(TABS[0])
        }
        else {
            TABS = [{
                label: "Audio Transcript",
                id: "transcript"
            },
            {
                label: "Chat History",
                id: "chat"
            }]
            setCurrentTab(TABS[0])
        }
    }, [transcriptAvailable])

    return (
        <>
            {
                isSmallScreen && <div id="video-search-container">
                    <SearchVideo />
                </div>
            }


            <div className={classes.videoMetaPanel}>
                {(hasChatHistory || transcriptAvailable) &&
                    <TabDropDown
                        options={TABS}
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}

                    />
                }

                <TranscriptPanel
                    videoRef={videoRef}
                    showTab={currentTab.id === "transcript"}
                />



                <ChatPanel
                    videoRef={videoRef}
                    showTab={currentTab.id === "chat"}
                />

            </div>

        </>

    );
};

export default VideoMetaPanel;
