import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    topRow: {
        padding: "15px 0 15px 25px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        color: "inherit",
        backgroundColor: "#E0FBFF",
        borderRadius: "4px",
    },
    row: {
        padding: "5px 0 5px 25px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        color: "#5B5B5B",
    },
    counts: {
        marginRight: "15px",
        color: theme.colors.onyx,
        fontFamily: "Open Sans",
        textAlign: "right"
    },
    logo: {
        height: "15px",
        width: "15px",
        marginRight: "10px"
    },
    showcase: {
        padding: "0 12px"
    },
    subtitle: {
        ...theme.elements.subtitle,
        paddingLeft: "15px",
        margin: "20px 0 10px 0",
        fontWeight: "600",
        color: theme.colors.deepAqua
    },
    link: {
        color: "inherit",
        textDecoration: "none"
    },
    Image: {
        position: "relative",
        zIndex: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "auto",
        width: "35px",
        borderRadius: "50%"
    },
    profile: {
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        paddingLeft: "15px",
        height: "54px",
        width: "100%",
        borderRadius: "4px",
        '&:hover': {
            position: "relative",
            zIndex: 0,
            backgroundColor: "#E0FBFF",
            cursor: "pointer"
        },
    },
    profileImage: {
        boxSizing: "border-box",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        border: `2.5px solid #63C29D`,
    },
    profileContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "200px",
    },
    namePtag: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "0px 0px 0px 15px",
        fontSize: '16px',
        height: "20px",
        lineHeight: "16px",
        ...theme.MyKenzie.text.contentTitle
    },
    timezonePtag: {
        width: "100%",
        margin: "0px 0px 0px 15px",
        height: "20px",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: "300",
        ...theme.MyKenzie.text.contentSubtitle

    },
}));
export default useStyles;
